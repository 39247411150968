<template>
    <general-network
            network="facebook">
            <v-icon class="app-bar-social-icon" size="18">fa fa-facebook</v-icon>
    </general-network>
</template>

<script>
    import GeneralNetwork from "./general-network";
    export default {
        name: "facebook",
        components: {GeneralNetwork},
    }
</script>

<style scoped>

</style>
