<template>
    <general-network
            network="linkedin">
        <v-icon class="app-bar-social-icon" size="18">fa fa-linkedin</v-icon>
    </general-network>
</template>

<script>
    import GeneralNetwork from "./general-network";
    export default {
        name: "instagram",
        components: {GeneralNetwork},
    }
</script>

<style scoped>

</style>
