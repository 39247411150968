<template>
    <v-row align="center" no-gutters>
            <v-col class="d-sm-none d-md-none" cols="12" order="1">
                <v-img :src="mobileImg" eager contain></v-img>
            </v-col>
            <v-col class="d-none d-sm-flex d-md-flex pr-md-12" cols="6" order="1" sm="5" md="6">
                <v-img contain :src="boyImg" eager></v-img>
            </v-col>
            <v-col cols="12" order="3" order-sm="2" sm="4" order-md="2" md="3">
                <v-row no-gutters justify="end" class="mt-12 mt-sm-0 mt-md-0">
                    <v-col cols="1" class="d-sm-none d-md-none"></v-col>
                    <v-col cols="8" sm="12" md="12" class="pl-sm-7 pl-md-0">
                        <card-component class="text-container">
                            <template #ctitle>
                                <p class="mytitle">
                                    Comprar Series, <br>
                                    películas y novelas<br>
                                    en Cuba:  Más allá del <br>
                                    paquete semanal</p>
                            </template>
                            <template #ctext>
                                <p class="card-text pr-7 pr-sm-0 pr-md-0">
                                        En Cuba existen negocios en los que se comercializan series, películas, novelas, videojuegos, aplicaciones móviles… Ya sea en pequeños locales o casas es una actividad expandida por toda la isla. Aunque gran parte de los contenidos provienen del paquete semanal, puede decirse que es un negocio con sus propias lógicas de funcionamiento y sostenibilidad.
                                </p>
                            </template>
                            <template #caction>
                                <router-link :to="{name: 'text2'}" style="text-decoration: none">
                                    leer más
                                </router-link>
                            </template>
                        </card-component>
                    </v-col>
                    <v-col cols="2" class="d-sm-none d-md-none pa-6 pa-sm-12 pr-0">
                        <v-img contain max-height="500" eager :src="trazadoImg"></v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="d-none d-sm-flex d-md-flex" cols="6" order="2" order-sm="3" sm="3" order-md="3" md="3">
                <v-img contain eager :src="handsImg"></v-img>
            </v-col>
        </v-row>
</template>

<script>
    import CardComponent from "../../core/CardComponent";

    export default {
        name: "TextCardTwo",
        components: {CardComponent},
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
            }
        },
        computed: {
            mobileImg() {
                return this.supportsWebp ? require(`@/assets/home/mobile/text2.webp`): require(`@/assets/home/mobile/text2.png`)
            },
            boyImg(){
                return this.supportsWebp ? require(`@/assets/home/text21.webp`): require(`@/assets/home/text21.png`)
            },
            handsImg(){
                return this.supportsWebp ? require(`@/assets/home/text22.webp`): require(`@/assets/home/text22.png`)
            },
            trazadoImg() {
                return this.supportsWebp ?require(`@/assets/home/mobile/trazado2.webp`): require(`@/assets/home/mobile/trazado2.png`)
            }
        }
    }
</script>

<style scoped>
    .top-divider {
        width: 50%;
        align-self: flex-start;
        margin-left: 25%;
    }

    .text-container {
        margin-top: 20%;
    }

    @media screen and (max-width: 600px) {
        .main-row-container {
            margin-top: 15%;
        }

        .top-divider {
            width: 50%;
            align-self: center;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media screen and (max-width: 400px) {

        .text-container {
            margin-top: 0;
        }
    }
</style>
