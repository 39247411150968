<template>
    <div style="display: flex; flex-direction: row; justify-content: space-around; align-content: center; align-items: center">
        <point></point>
        <point style="margin-left: 5pt"></point>
        <point style="margin-left: 5pt"></point>
        <point style="margin-left: 5pt"></point>
        <point style="margin-left: 5pt"></point>
    </div>
</template>

<script>
    import Point from "./Point";
    export default {
        name: "HorizontalPoints",
        components: {Point}
    }
</script>

<style scoped>

</style>
