<template>
    <div>
        <slot name="title"></slot>
        <v-tabs class="mb-5" background-color="transparent" color="#0062d5" :centered="centered">
            <template v-for="mode in modes">
                <v-tab :key="mode" @click="changeMode(mode)">{{mode}}</v-tab>
            </template>
        </v-tabs>
        <div v-if="isTotalMode">
            <p class="chart-subtitle mb-2">Cantidad de archivos total de los 246 paquetes</p>
            <v-chart  :options="treemap" style="width: 100%;" autoresize></v-chart>
        </div>
        <div v-else>
            <p class="chart-subtitle">Cantidad de archivos promedio de un paquete en la semana</p>
            <v-chart  :options="treemap2" style="width: 100%;" autoresize></v-chart>
        </div>
    </div>

</template>

<script>
    import ECharts from "vue-echarts";
    import "echarts/lib/chart/treemap";
    import "echarts/lib/component/dataZoom"

    export default {
        name: "TreeMapChart",
        components: {"v-chart": ECharts},
        props: ['centered'],
        data() {
            return {
                modes: ['Total', 'En un paquete'],
                selected: "Total",
                treemap: {
                    ...this.createSeries('Total')
                },
                treemap2: {
                    ...this.createSeries('Promedio')
                }
            }
        },
        computed: {
            isTotalMode() {
                return this.selected === 'Total'
            }
        },
        methods: {
            changeMode(mode) {
                this.selected=mode;
            },
            getData(mode) {
                const treemapsdata = require("@/data/treemap.json");
                const treemapsdata2 = require("@/data/treemap2.json");
                return mode === 'Total' ? treemapsdata : treemapsdata2;
            },
            getLevelOption() {
                return [
                    {
                        itemStyle: {
                            borderColor: '#000', // borde entre categorías padres
                            borderWidth: 0,
                            gapWidth: 1
                        },
                        upperLabel: {
                            show: false
                        }
                    },
                    {
                        itemStyle: {
                            borderColor: '#000', // franja debajo de los nombres de las categorías
                            borderWidth: 5,
                            gapWidth: 1
                        },
                        emphasis: { // color cuando se hace hover sobre una categoria
                            itemStyle: {
                                borderColor: '#0005' // no poner ninguno
                            }
                        }
                    },
                    {
                        colorSaturation: [0.35, 0.5],
                        itemStyle: {
                            borderWidth: 0.5,
                            gapWidth: 1,
                            borderColorSaturation: 0.6
                        }
                    }
                ];
            },
            createSeries(mode) {
                return {
                    // title: {
                    //     text: "Tipos de contenidos",
                    //     left: "center",
                    // },
                    series: {
                        name: "Tipos de contenidos",
                        type: "treemap",
                        label: {
                            normal: {
                                formatter:  function (params) {
                                    return `${params.name} \n ${params.value.toLocaleString('fr-FR', {})}`;
                                },
                            }
                        },
                        upperLabel: {
                            show: true,
                            height: 30
                        },
                        itemStyle: {
                            borderColor: '#fff'
                        },
                        levels: this.getLevelOption(),
                        data: this.getData(mode),
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 20,
                        roam: 'move'
                    },
                    color: [
                        '#0062d5',
                        '#ff633e',
                        '#ba1e31',
                        '#00d2da',
                        '#b84663',
                        '#f5c781'
                    ],
                    grid: {
                        width: '100%',
                        height: '100%',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        top: 0,
                        show: false
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .chart-subtitle {
        color: white;
        font-size: 9pt;
        font-family: Lato-Regular;
        text-align: center;
        text-transform: uppercase;
    }

    @media screen and (max-width: 960px) {
        .chart-subtitle {
            font-size: 8pt;
        }
    }
</style>
