<template>
  <div>
    <chart-title>Lista de contenidos más comunes del paquete (2017-2020)</chart-title>
    <v-tabs background-color="transparent" color="#0062d5">
      <template v-for="link in links">
        <v-tab :key="link.data" @click="selected=link.data">{{ link.name }}</v-tab>
      </template>
    </v-tabs>
    <v-simple-table style="background-color: transparent!important;" dark>
      <template v-slot:default>
        <thead>
        <tr>
          <th v-for="item in fields" :key="item.text" class="mheader-name">
            {{item.text}}
          </th>
        </tr>
        </thead>
        <tbody style="overflow: hidden; width: 100%">
        <tr
                v-for="item in dataManager.data"
                :key="item.name"
        >
          <td>{{ item.top }}</td>
          <td class="mfile-name">{{ item.name }}</td>
          <td>{{ item.cantidad }}</td>

        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
// import Vuetable from "vuetable-2/src/components/Vuetable";

import ChartTitle from "./ChartTitle";
export default {
  components: {
    ChartTitle
    // Vuetable,
  },
  data() {
    return {
      css: {
        table: {
          tableWrapper: '',
          tableHeaderClass: '',
          tableBodyClass: '',
          tableClass: 'top-table',
          loadingClass: 'loading',
          ascendingIcon: 'blue chevron up icon',
          descendingIcon: 'blue chevron down icon',
          ascendingClass: 'sorted-asc',
          descendingClass: 'sorted-desc',
          sortableIcon: 'grey sort icon',
          handleIcon: 'grey sidebar icon',
        },
      },
      tops: {
        apks: require("@/data/top/apks.json"),
        games: require("@/data/top/games.json"),
        "musica-inter": require("@/data/top/musica-inter.json"),
        "musica-nacional": require("@/data/top/musica-nacional.json"),
        pelis: require("@/data/top/pelis.json"),
        series: require("@/data/top/series.json"),
        "videos-musicales": require("@/data/top/videos-musicales.json"),
        youtubers: require("@/data/top/youtubers.json"),
      },
      selected: "musica-nacional",
      links: [
          {name:"Música nacional", data: "musica-nacional"},
          {name:"Música internacional", data: "musica-inter"},
          {name:"APKs", data: "apks"},
          {name:"Juegos", data: "games"},
          {name:"Películas", data: "pelis"},
          {name:"Series", data: "series"},
          {name:"Videos musicales", data: "videos-musicales"},
          {name:"Youtubers", data: "youtubers"}
      ],
      fields: [ {text:'Top', value: "top"},{text:'Nombre', value: "name"},{ text:'Repeticiones', value: "cantidad" } ],
      headers: [
        {
          text: 'Dessert (100g serving)',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Calories', value: 'calories' },
        { text: 'Fat (g)', value: 'fat' },
        { text: 'Carbs (g)', value: 'carbs' },
        { text: 'Protein (g)', value: 'protein' },
        { text: 'Iron (%)', value: 'iron' },
      ],
    };
  },
    watch: {
    selected() {
      this.$refs.vuetable.refresh();
    }
  },
  computed: {
      dataManager() {
      if (this.tops[this.selected].length < 1) return;
      console.log(this.tops[this.selected].map((el, ind) => ({name:el.name, cantidad: el.cantidad, top: ind+1})).slice(0,10));
      return {
        data: this.tops[this.selected].map((el, ind) => ({name:el.name, cantidad: el.cantidad, top: ind+1})).slice(0,10)
      };
    },
  },
};
</script>

<style>
  .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
    color: white !important;
    font-family: Lato-Bold;
  }

  .v-tabs theme--light.v-icon{
    color: #3363d0;
  }
  td, th {
    overflow-wrap: break-word;
    font-family: Lato-Regular;
  }
 td.mfile-name:nth-child(even), th.mheader-name:nth-child(even){
    width: 60%;
  }

  tr {
    width: available;
  }

  table {
    table-layout: fixed;
    width: 100%;
    color: white;
  }

  .v-slide-group__next i{
    color: #3363d0 !important;

  }

  .v-slide-group__prev i{
    color: #3363d0 !important;
  }

  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #3363d0 !important;
  }
</style>
