<template>
    <v-row justify="end" class="ma-0">
        <v-col cols="11" sm="6" md="6" class="pr-md-10 text-block-container">
            <slot name="text">
                <p class="text-justify section-title">
                    Lorem ipsum
                </p>
                <p class="text-justify">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </slot>
        </v-col>
        <v-col cols="12" sm="4" md="4" class="d-none d-sm-flex d-md-flex mt-1 pr-0"
               style="flex-direction: column; height: max-content;">
            <navigation-link v-for="(link, index) in links"  :key="index" :pathname="link.path" :name="link.name"
                             :class="{'mt-5': index !== 0}"/>
        </v-col>
    </v-row>
</template>

<script>
    import NavigationLink from "./NavigationLink";

    export default {
        name: "TextBlock",
        components: {NavigationLink},
        props: ['links']
    }
</script>

<style scoped>


    .text-block-container {
        margin-right: 2%;
    }

    @media screen and (max-width: 960px) {
        .text-block-container {
            margin-right: 4%;
        }
    }
</style>
