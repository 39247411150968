<template>
    <span style="font-size: 20pt; line-height: 20pt; color: var(--secondary-color2); font-family: Lato-Black">.</span>
</template>

<script>
    export default {
        name: "Point"
    }
</script>

<style scoped>

</style>
