<template>
    <general-text :banner-image-src-md="bannerImageSrcMd"
                  :banner-image-src-sm="bannerImageSrcSm"
                  lazy-src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAASCAYAAACuLnWgAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAAAB3RJTUUH5AoTBBMLuCkjIgAAA/9JREFUOMvdlEtslGUUhp/vn/nn8s8MMw7tlI6FFhiQS8GaQislREgVaY0BMdHEGF1IYjSkGOMGghg1MWA0amKC4g0WSAhRQzBFCSrULkojlyoMyK33lrYDw9xn/tvnAhYYJbrAje/mnM37vptzHqFp/iHgbiDNndckYNh5c+GWeacVUP6j4D/pf1wiBJg25E0bCYh/ESKEoKgbGKaJIm44FEXBsizy+SzOvzgkSNtGFSBv0yKEQlEvYRklEA5A4NM82JaZyeYyNhAEqAiFWLmsEYequjYihFsp5sAwyBmCLXWVfLu0is/Pj5HIF3AZOoqhI5DYThf5Qp7aaIS21a3gdOKWFp+88CyPNjXmL46M76ueUlFZFgoGHm9qIJUrjDhU1bVRmAW3WV5LKRRDpntZMNlP7ZzZHM166LemIcsXUJy8BEU1ULJDGJbNrGglH61/jqDm5fzoeN9g4ur2bKEwWl0Wzkvb+nhhzTR5YXQseqD7xBahedxpiqVA/qkOhMuH3FnPvCll7N24jnWHChyreIfl+h4aAzrbTg7g7noDvH6wTRbPrGHg6nUGxq7sB9YAbH5ybXcikxn6/njPey2L7tsa8nlHnCAQgLBKyFQRgkFqHl7FWzs+pTu7CKJO6gs/sSUYZ5u5nBLg0/PkLJuG2HR2tD3E5p17moevpzY43J5Ux29ntseHRn5trqttm1Yejp663H9E4eY1iPwYXE9Q9WALY0tW8NVgAilNUCFb1kRyYgIqp0LzVqQWBuDAqTivfvk1Z4ev+F9vnPX+2y1LX7qUTP8SCQWrgpqmvrZ73+ITl3oPOlSX+2VhGJoxvRUqG6mf0Ut1IcmIeRf1rQ0ofWf5YUaAnvaDTIQj6Gs3wXiBwMDPJNIZTvf3k0inyRWK5DLpKaczRlyVZvy74z0f1s2oaV02f87TDlV1PyMMPWJMmgrCienIM8/rQz/ajlE/n8WpDKlrvZyMrUSfPR1SF7Cjc7Ajw4hzvTiATU+sIVZbx2fdZ/BjdpQMsyfk881fvmDe+s7477scqupqUpxiISNdWNJF2jEbdfAQLdVhkhVTSa5qoFh7L4kVC6FzCHxZ6EtiL3kR64Eq5JEf984Nhw6rbvfouYHBd6VldrqczlhA84bjA0PtZweHD6Np/tWa5pea6pCucEyGonOlz8P4Iw33z23b9UU5aT3GWDLGqdMxOuMxEokYz78SI/LmPRTlTDasu+WhHX9LBCewH8Q3wuV5zJXpo3TNooAo7+ju0i9ODk/Q1DyBlODyQqUCPtcN9thJGAehCFTA4fUhhCCfz96OXXKtlOIDW/XoQvPh8/l3l1B6Lx9sx3msCyIVYFlQMKFk3tgVwAZ04x/Z9gdyf671TSu/4AAAAABJRU5ErkJggg=="
                    :links="links">
        <template #title>{{title}}</template>
        <template #author>{{author}}</template>
        <template #body>
            <text-block :links="links">
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(2, 13)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>
            <v-row class="ma-0">
                <v-col cols="12" class="pa-0">
                    <m-image :src-md="joyskitMd"
                             :src-sm="joyskitSm"
                             :src-lg="joyskitLg"></m-image>
                </v-col>
            </v-row>
            <text-block>
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(13)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">

                    </p>
                </template>
            </text-block>
            <v-row class="ma-0" justify="end">
                <v-col cols="11" sm="8" md="8" class="text-block-container">
                    <m-image :src-lg="infographyLg"
                             :src-md="infographyMd"
                             :src-sm="infographySm" width="100%"></m-image>
                </v-col>
                <v-col cols="1" md="2"></v-col>
            </v-row>
        </template>
    </general-text>
</template>

<script>
    import GeneralText from "../components/GeneralText";
    import TextBlock from "../components/texts/TextBlock";
    import {get_text} from "../utils";
    import MImage from "../components/core/MImage";

    export default {
        name: "Text2",
        metaInfo: {
            // Children can override the title.
            title: 'EL PAQUETE SEMANAL',
            titleTemplate: '%s ← COMPRAR SERIES, PELÍCULAS Y NOVELAS EN CUBA: MÁS ALLÁ DEL PAQUETE SEMANAL',
            // Define meta tags here.
            meta: [
                {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
                {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                {
                    name: 'description',
                    content: 'En Cuba existen negocios en los que se comercializan series, películas, novelas, videojuegos, aplicaciones móviles… Ya sea en pequeños locales o casas es una actividad expandida por toda la isla. Aunque gran parte de los contenidos provienen del paquete semanal, puede decirse que es un negocio con sus propias lógicas de funcionamiento y sostenibilidad.'
                },
                {name: 'tags', content: 'paquetesemanal, paquetesemanalcuba, paquetecuba'},
                {name: 'author', content: 'Datalis <info@datalis.dev>'},
                // OpenGraph data (Most widely used)
                {property: 'og:title', content: 'COMPRAR SERIES, PELÍCULAS Y NOVELAS EN CUBA: MÁS ALLÁ DEL PAQUETE SEMANAL'},
                {property: 'og:site_name', content: 'EL PAQUETE SEMANAL'},
                // The list of types is available here: http://ogp.me/#types
                {property: 'og:type', content: 'website'},
                // Should the the same as your canonical link, see below.
                {property: 'og:url', content: 'https://paquetesemanal.eltoque.com/text2'},
                {property: 'og:image', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'},
                // Often the same as your meta description, but not always.
                {property: 'og:description', content: 'En Cuba existen negocios en los que se comercializan series, películas, novelas, videojuegos, aplicaciones móviles… Ya sea en pequeños locales o casas es una actividad expandida por toda la isla. Aunque gran parte de los contenidos provienen del paquete semanal, puede decirse que es un negocio con sus propias lógicas de funcionamiento y sostenibilidad.'},

                // Twitter card
                {name: 'twitter:card', content: 'summary'},
                {name: 'twitter:site', content: 'https://paquetesemanal.eltoque.com/text2'},
                {name: 'twitter:title', content: 'COMPRAR SERIES, PELÍCULAS Y NOVELAS EN CUBA: MÁS ALLÁ DEL PAQUETE SEMANAL'},
                {name: 'twitter:description', content: 'En Cuba existen negocios en los que se comercializan series, películas, novelas, videojuegos, aplicaciones móviles… Ya sea en pequeños locales o casas es una actividad expandida por toda la isla. Aunque gran parte de los contenidos provienen del paquete semanal, puede decirse que es un negocio con sus propias lógicas de funcionamiento y sostenibilidad.'},
                // Your twitter handle, if you have one.
                {name: 'twitter:creator', content: '@eltoquecom'},
                {name: 'twitter:image:src', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'},

                // Google / Schema.org markup:
                {itemprop: 'name', content: 'COMPRAR SERIES, PELÍCULAS Y NOVELAS EN CUBA: MÁS ALLÁ DEL PAQUETE SEMANAL'},
                {itemprop: 'description', content: 'En Cuba existen negocios en los que se comercializan series, películas, novelas, videojuegos, aplicaciones móviles… Ya sea en pequeños locales o casas es una actividad expandida por toda la isla. Aunque gran parte de los contenidos provienen del paquete semanal, puede decirse que es un negocio con sus propias lógicas de funcionamiento y sostenibilidad.'},
                {itemprop: 'image', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'}
            ]
        },
        components: {MImage, TextBlock, GeneralText},
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
                text: require('@/data/text2.json'),
                links: [
                    {
                        path: 'text1',
                        name: 'La red offline que conecta toda Cuba',
                    },
                    {
                        path: 'text3',
                        name: 'Santiago de Cuba: la ciudad clandestina del disco duro',
                    },
                    {
                        path: 'text4',
                        name: 'El paquete semanal llega a cada poblado cubano en camión, guagua o lo que aparezca',
                    },
                    {
                        path: 'text5',
                        name: '¿QUÉ TRAE EL PAQUETE SEMANAL?',
                    },
                ],
            }
        },
        computed: {
            title: function () {
                return this.text.doc[0][0].text
            },
            author: function () {
                return this.text.doc[1][0].text
            },
            bannerImageSrcMd() {
                return this.supportsWebp ? require('@/assets/text2/banner-lg.webp'): require('@/assets/text2/banner-lg.png')
            },
            bannerImageSrcSm() {
                return this.supportsWebp ? require('@/assets/text2/banner-sm.webp'): require('@/assets/text2/banner-sm.png')
            },
            joyskitMd() {
                return this.supportsWebp ? require('@/assets/text2/joyskit-md.webp') : require('@/assets/text2/joyskit-md.png')
            },
            joyskitSm() {
                return this.supportsWebp ? require('@/assets/text2/joyskit-sm.webp'): require('@/assets/text2/joyskit-sm.png')
            },
            joyskitLg() {
                return this.supportsWebp ? require('@/assets/text2/joyskit-lg.webp'): require('@/assets/text2/joyskit-lg.png')
            },
            infographyLg() {
                return this.supportsWebp ? require('@/assets/text2/infography-lg.webp'): require('@/assets/text2/infography-lg.png')
            },
            infographyMd() {
                return this.supportsWebp ? require('@/assets/text2/infography-md.webp'): require('@/assets/text2/infography-md.png')
            },
            infographySm() {
                return this.supportsWebp ? require('@/assets/text2/infography-sm.webp'): require('@/assets/text2/infography-sm.png')
            }
        },
        methods: {
            get_text
        }
    }
</script>

<style scoped>

</style>
