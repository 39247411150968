<template>
    <v-container fluid class="pa-0">
        <v-row  justify="start" no-gutters >
            <v-col cols="3" class="eltoque-link-container">
                <v-img class="logo-container" contain eager src="@/assets/home/tazadoAppBar.png" height="max-content">
                    <a href="https://eltoque.com/" target="_blank" style="text-decoration: none; width: fit-content">
                        <v-img class="left-logo" contain eager src="@/assets/logos/eltoque.png" width="40%"></v-img>
                    </a>
                </v-img>
            </v-col>
        </v-row>
        <v-row justify="end">
            <v-col cols="10">
                <header-col></header-col>
            </v-col>
            <v-col cols="1" class="social-share-container pl-4 pl-md-8">
                <slot name="right-col-content">
                    <share-social-network></share-social-network>
                </slot>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ShareSocialNetwork from "../ShareSocialNetwork";
    import HeaderCol from "./HeaderCol";
    export default {
        name: "HomeDeskAppBar",
        components: {ShareSocialNetwork, HeaderCol},
    }
</script>

<style scoped>
    .social-share-container {
        margin-top: 70pt;
    }

    .eltoque-link-container {
        padding-right: 5% !important;
    }

    .logo-container {
        align-items: center;

    }
    .left-logo {
        margin-left: 15%;
        margin-right: 45%;
        margin-bottom: 12%;
    }
</style>
