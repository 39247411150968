import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import vuetify from './plugins/vuetify';
import Meta from 'vue-meta';
import VueAnalytics from 'vue-analytics'
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing)
// Vue.use(VueSocialSharing, Vue.use(VueSocialSharing, {
//   networks: {
//     fakeblock: 'https://instagram.com/share?url=@url&title=@title'
//   }
// }));
Vue.use(VueAnalytics, {
  id: 'UA-54853009-1',
  autoTracking: {
    screenview: true
  }
});
Vue.use(Meta);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
