<template>
    <div style="display: flex; flex-direction: column;">
        <point></point>
        <point></point>
        <point></point>
        <point></point>
        <point></point>
    </div>
</template>

<script>
    import Point from "./Point";

    export default {
        name: "VerticalPoints",
        props: ['width'],
        components: {Point}
    }
</script>

<style scoped>

</style>
