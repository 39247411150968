/**
 *
 * @param week ww-yyyy
 */
export function splitWeekYear(week){
    return week.split('-')
}

export function getRangeOfWeek(week) {
    try {
        const wy = splitWeekYear(week)
        const w  = parseInt(wy[0])
        const y  = parseInt(wy[1])
        return getDateOfISOWeek(w, y);
    } catch (e) {
        // pass
    }

}

export function getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart.toLocaleString("es-ES", {dateStyle: "short"});
}


console.log(getRangeOfWeek(0, 2021))
