<template>
    <v-row no-gutters style="width: inherit">
        <v-col cols="8" style="height: max-content">
            <div>
                <p class="mytitle mb-0" style="color: var(--secondary-color2);" v-html="name">

                </p>

                <p class="mytitle text-right mt-2"
                   style="margin-left: auto; width: 100%; text-transform: none; color: var(--secondary-color);">
                    <router-link :to="{name: pathname}" style="text-decoration: none">
                        leer
                        más
                    </router-link>
                </p>
            </div>
        </v-col>
        <v-col cols="12" class="pr-0" style="height: max-content">
            <divider></divider>
        </v-col>

    </v-row>
</template>

<script>
    import Divider from "../divider";

    export default {
        name: "navigation-link",
        components: {Divider},
        props: ['pathname', 'name']
    }
</script>

<style scoped>

</style>
