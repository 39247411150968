<template>
    <div>
        <chart-title>CANTIDAD DE CONTENIDO DE CADA PAQUETE POR SEMANA</chart-title>
        <v-chart :options="sizes" style="width: 100%;" autoresize/>
    </div>
</template>

<script>
    import ECharts from "vue-echarts";
    import "echarts/lib/chart/bar";
    import "echarts/lib/chart/scatter";
    import "echarts/lib/component/title"
    import "echarts/lib/component/legend"
    import "echarts/lib/component/dataZoom"
    import ChartTitle from "./ChartTitle";
    import {getRangeOfWeek} from "../../utils/date";

    export default {
        name: "SizesChart",
        components: {ChartTitle, "v-chart": ECharts},
        data() {
            const sizesdata = require("@/data/sizes2.json");
            const idx2Show = [0, 1, 53, 104]

            return {
                sizes: {
                    legend: {
                        data: ['omega', 'deltavision', 'odisea', 'crazyboy', 'sin categoria'],
                        textStyle: {
                            color: 'white'
                        },
                    },
                    tooltip: {
                        trigger: "axis",
                        position: "top",
                        formatter: function (params) {
                            const week = sizesdata.xAxis[0].data2[params[0].dataIndex]
                            console.log(week)
                            console.log('valor', params)

                            let series = []
                            for (const p of params) {
                                if (p.value) {
                                    series.push({name: p.seriesName, color: p.color, value: p.value})
                                }
                            }

                            const color_template = (color) =>
                                `<div style="border-radius: 50%; background-color: ${color}; width: 8px; height: 8px"> </div>`

                            const series_template = (serie) =>
                                `<div style="display: flex; flex-direction: row; align-items: center">
                                    ${color_template(serie.color)}
                                    <span style="width: max-content; margin-left: 2px">${serie.name}: ${serie.value}</span>
                                 </div>`
                            return `<div>
                                        <p>semana ${getRangeOfWeek(week)}</p>
                                        ${series.map(s => series_template(s)).join('')}
                                    <div>`
                        },
                    },
                    dataZoom: {
                      type: 'inside'
                    },
                    // title: {
                    //     // show: true,
                    //     text: "CANTIDAD DE CONTENIDO DE CADA PAQUETE POR SEMANA",
                    //     left: "center",
                    //     top: "top",
                    //     textStyle: {
                    //         color: 'white',
                    //         fontSize: 20,
                    //         fontFamily: 'Lato-Thin'
                    //     },
                    //     subtextStyle: {
                    //         fontSize: 20
                    //     }
                    // },
                    toolbox: {
                        show: true,
                        feature: {
                            dataView: { show: true, readOnly: false },
                            magicType: { show: true, type: ["line", "bar"] },
                            restore: { show: true },
                            saveAsImage: { show: true },
                        },
                    },
                    calculable: true,
                    ...sizesdata,
                    xAxis: [
                        {
                            ...sizesdata.xAxis[0]   ,
                            axisLabel: {
                                show: true,
                                interval: (index, val) => {
                                    console.log(val)
                                    return idx2Show.includes(index)
                                }
                            },
                        }

                    ],
                    grid: {
                        width: '95%%',
                        // height: '100%',
                        left: 40,
                        right: 150,
                        bottom: 40,
                        top: 80,
                        show: true,
                    },
                    color: [
                        '#0062d5',
                        '#ff633e',
                        '#ba1e31',
                        '#00d2da',
                        '#b84663',
                        '#f5c781'
                    ],
                    textStyle: {
                        color: 'white'
                    },
                }
            }
        }
    }
</script>

<style scoped>

</style>
