var store = {
    state: {
        supportsWebp: true,
        showFloatingButton: false,
        isJSFileExplorerAttached: false,
    },
    setSupportsWebpAction(newValue) {
        this.state.supportsWebp = newValue
    },
    setJSAttached(newValue) {
        this.state.isJSFileExplorerAttached = newValue
    }
};

export default store;
