<template>
    <div>
        <div :class="textAlign">
            <slot name="ctitle"></slot>
        </div>
        <div :class="textAlign">
            <slot name="ctext"></slot>
        </div>
        <div :class="['c-action', textAlign]">
            <slot name="caction">leer más</slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CardComponent",
        props: {
            isEven: Boolean,
            lefTImageSrc: String,
            rightImageSrc: String,
        },
        data() {
            return {
                containerClassObj:
                    {
                        'c-container': true,
                        'd-lg-flex': true,
                        'd-xl-flex': true,
                        'd-md-flex': true,
                        'flex-lg-row-reverse': this.isEven,
                        'flex-md-row-reverse': this.isEven,
                        'flex-xl-row-reverse': this.isEven
                    },
            }
        },
        computed: {
            textAlign () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 'text-left';
                    default: return this.isEven ? 'text-right': 'text-left'
                }
            },
        },
    }
</script>

<style scoped>
    .c-action {
        color: var(--secondary-color);
        font-size: 14pt;
        font-family: Lato-Black, monospace;
        letter-spacing: 1px;
    }
</style>
