<template>
    <v-img contain eager :src="footerSrc" width="100%" height="max-content"
           style="display: flex; flex-direction: row; align-items: center">
        <v-row justify="end">
            <v-col cols="7" md="6" style="display: flex; flex-direction: column; align-items: center; justify-content: flex-end; padding-right: 10%">
                <metodology-btn></metodology-btn>
            </v-col>
            <v-col cols="4" md="5" class="text-right mr-12">
                <credits></credits>
            </v-col>
        </v-row>
    </v-img>
</template>

<script>
    import Credits from "./footer/Credits";
    import MetodologyBtn from "./footer/MetodologyBtn";
    export default {
        name: "DeskFooter",
        components: {MetodologyBtn, Credits},
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
            }
        },
        computed: {
            footerSrc() {
                return this.supportsWebp ? require('@/assets/footer.webp'): require('@/assets/footer.png')
            },
        }
    }
</script>

<style scoped>

</style>
