<template>
    <v-img contain eager :src="imageSrc" :lazy-src="lazySrc" :max-height="maxHeight" width="100%"> </v-img>
</template>

<script>
    export default {
        name: "MImage",
        props: ['srcSm', 'srcMd', 'srcLg', 'lazySrc', 'width', 'maxHeight'],
        computed: {
            imageSrc() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        console.log(this.srcSm);
                        return this.srcSm;
                    case 'sm':
                        console.log(this.$vuetify.breakpoint);
                        return this.srcMd;
                    default:
                        return this.srcLg;
                }
            },
        }
    }
</script>

<style scoped>

</style>
