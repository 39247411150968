<template>
    <general-network
            network="twitter">
        <v-icon class="app-bar-social-icon" size="18">fa fa-twitter</v-icon>
    </general-network>
</template>

<script>
    import GeneralNetwork from "./general-network";
    export default {
        name: "twitter",
        components: {GeneralNetwork},
    }
</script>

<style scoped>

</style>
