<template>
    <v-divider role="presentation" class="divider" :style="[{width: width ? width : '100%'}]"></v-divider>
</template>

<script>
    export default {
        name: "divider",
        props:['width']
    }
</script>

<style scoped>
    .divider {
        background-color: var(--primary-color);
    }
</style>
