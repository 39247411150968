<template>
    <home-mobile-app-bar>
        <v-row justify="end" class="pa-0 ma-0">
        <v-col cols="7" md="10" class="pa-0">
            <header-col></header-col>
        </v-col>
        <v-col cols="2" class="pr-0">
            <router-link to="/" style="text-decoration: none; width: fit-content">
                <div class="d-flex justify-center">
                    <v-img contain eager :src="require('@/assets/HOME.png')">
                    </v-img>
                </div>
            </router-link>
        </v-col>
        <v-col cols="2" class="pr-0">
            <a href="https://eltoque.com/" target="_blank" style="text-decoration: none; width: min-content">
            <v-img contain eager src="@/assets/logos/elToqueShort.png"
                   max-width='50%'></v-img>
            </a>
        </v-col>
        </v-row>
    </home-mobile-app-bar>
</template>

<script>
    import HomeMobileAppBar from "./HomeMobileAppBar";
    import HeaderCol from "./HeaderCol";
    export default {
        name: "TextMobileAppBar",
        components: {HeaderCol, HomeMobileAppBar}
    }
</script>

<style scoped>

</style>
