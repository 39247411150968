/* eslint-disable */
'use strict';

/* --- Snap2HTML Code --- */

var dirs = [];	// contains all directories
var i = 0;		// counter for initializing dirs

dirs[i++] = [
    "G:\\((14-09-2020))*0*9/13/2020 9:56:15 PM",
    0,
    "1*18*19*23*40*72*100*137*149*196*198*235*252*267*269*294*329*390*412*588*593*998*1013*1046*1144*1150*1311*1317*1336*1343*1458*1470*1513"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion*0*9/13/2020 9:31:05 PM",
    0,
    "2*3*4*5*6*7*8*9*10*11*12*13*14*15*16*17"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\! Exatlon EUA El Gran Regreso [2020] [720p] - [Temp 1] [Cap-46] [MP4] [653,06 Mb]*0*9/13/2020 11:32:42 PM",
    "! Exatlon EUA El Gran Regreso [2020] [720p] - 1x46.mp4*684780814*9/7/2020 8:28:12 PM",
    684780814,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\! Exatlon EUA El Gran Regreso [2020] [720p] - [Temp 1] [Cap-47] [MP4] [1,18 Gb]*0*9/13/2020 9:31:13 PM",
    "! Exatlon EUA El Gran Regreso [2020] [720p] - 1x47.mp4*1262247151*9/7/2020 11:00:35 PM",
    1262247151,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\! Exatlon EUA El Gran Regreso [2020] [720p] - [Temp 1] [Cap-48] [MPG] [812,17 Mb]*0*9/13/2020 9:02:15 PM",
    "! Exatlon EUA El Gran Regreso [2020] [720p] - 1x48.mpg*851554304*9/10/2020 12:17:01 AM",
    851554304,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\! Exatlon EUA El Gran Regreso [2020] [720p] - [Temp 1] [Cap-49] [MPG] [753,09 Mb]*0*9/13/2020 8:37:43 PM",
    "! Exatlon EUA El Gran Regreso [2020] [720p] - 1x49.mpg*790220800*9/10/2020 3:23:25 PM",
    790220800,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\! Exatlon Mexico [720p] - [Temp 4] [Cap-06] [MPG] [1,36 Gb]*0*9/13/2020 8:34:54 PM",
    "! Exatlon Mexico [720p] - 4x06.mpg*1461178368*9/8/2020 7:21:47 PM",
    1461178368,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\! Exatlon Mexico [720p] - [Temp 4] [Cap-07] [MPG] [1,30 Gb]*0*9/13/2020 8:37:27 PM",
    "! Exatlon Mexico [720p] - 4x07.mpg*1397770240*9/10/2020 12:50:32 AM",
    1397770240,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\! Exatlon Mexico [720p] - [Temp 4] [Cap-08] [MPG] [1,35 Gb]*0*9/13/2020 8:34:30 PM",
    "! Exatlon Mexico [720p] - 4x08.mpg*1452623872*9/10/2020 3:08:11 PM",
    1452623872,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\! Exatlon Mexico [720p] - [Temp 4] [Cap-09] [MPG] [771 Mb]*0*9/13/2020 8:36:04 PM",
    "! Exatlon Mexico 4 Cap9.mp4*777665843*9/11/2020 3:56:59 AM",
    777665843,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\! Exatlon Mexico [720p] - [Temp 4] [Cap-10 [MPG] [2.19Gb]*0*9/13/2020 8:35:34 PM",
    "! Exatlon Mexico 4 10.mp4*2352342653*9/12/2020 1:57:35 AM",
    2352342653,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\! La Voz España 7x01*0*9/13/2020 8:38:31 PM",
    "! La Voz España 7x01.ts*764106448*9/11/2020 9:46:50 PM",
    764106448,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\Acapulco Shore, Carnaval TLV. Cap. 13, ESTRENO [09-09-20] [535,25 Mb]*0*9/13/2020 8:38:14 PM",
    "Acapulco Shore, Carnaval TLV. Cap. 13, ESTRENO [09-09-20].mpg*561074176*9/10/2020 2:21:56 PM",
    561074176,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\Acapulco Shore - [Temp 7] [Cap-14 [MP4] [505,02 Mb]*0*9/13/2020 8:36:18 PM",
    "Acapulco Shore [10-09-20].mp4*5031719095*9/10/2020 10:19:15 AM",
    5031719095,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\Acapulco Shore - [Temp 7] [Cap-15] [MP4] [505,02 Mb]*0*9/13/2020 8:38:24 PM",
    "Acapulco Shore - 7x15.mp4*529551620*9/9/2020 2:35:24 AM",
    529551620,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\Idol Kids España [720p] [Completo] - [Temp 1] [Cap-01] [MPG] [1,18 Gb]*0*9/13/2020 8:58:41 PM",
    "Idol Kids España [720p] [Completo] - 1x01.mpg*1275205632*9/10/2020 3:15:08 PM",
    1275205632,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\Quien Es La Mascara [2020] - [Temp 1] [Cap-06] [MPG] [903,84 Mb]*0*9/13/2020 9:03:30 PM",
    "Quien Es La Mascara [2020] - 1x06.mpg*947740145*8/30/2020 5:23:16 PM",
    947740145,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Concursos de Participacion\\Quien es la mascara - [Temp 1] [Cap-6] [MP4] [540,32 Mb]*0*9/13/2020 9:03:39 PM",
    "Quien es la mascara - 1x6.mp4*566568495*9/7/2020 9:17:42 PM",
    566568495,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!Sitios de Anuncios Clasificados*0*9/13/2020 10:27:52 PM",
    "Revolico [11-09-20].iso*1157967872*9/11/2020 11:27:00 AM",
    1157967872,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!TV de Cuba*0*9/13/2020 9:04:14 PM",
    0,
    "20*21*22"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!TV de Cuba\\El Rostro de los dias*0*9/13/2020 10:28:11 PM",
    "El Rostro De Los Días - Cap 75.mp4*432264612*9/1/2020 11:40:33 PM",
    "El Rostro De Los Días - Cap 77.mp4*877822507*9/5/2020 5:50:08 PM",
    "El Rostro De Los Dias Cap 76.mp4*569003884*9/2/2020 10:54:58 PM",
    "EL ROSTRO DE LOS DIAS Cap. 78 - NOVELA CUBANA.mp4*320678759*9/7/2020 6:13:56 PM",
    "EL ROSTRO DE LOS DIAS Cap. 79.mp4*344558195*9/9/2020 5:04:23 PM",
    2544327957,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!TV de Cuba\\La otra guerra (L.C.B) 2da Temporada*0*9/13/2020 11:21:21 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 1.mp4*307054354*9/8/2020 1:40:27 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 10 (Fin 1ra Parte).mp4*252795160*9/8/2020 3:55:40 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 11 (Continuacion).mp4*286357768*9/8/2020 8:26:39 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 12.mp4*243837865*9/8/2020 8:38:19 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 13.mp4*174853339*9/8/2020 8:46:26 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 14.mp4*267729222*9/8/2020 8:58:47 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 15.mp4*146240606*9/8/2020 9:06:05 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 16.mp4*143056047*9/8/2020 9:13:09 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 17.mp4*132079407*9/8/2020 9:19:51 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 2.mp4*323028554*9/8/2020 1:57:13 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 3.mp4*331197133*9/8/2020 2:17:58 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 4.mp4*234407901*9/8/2020 2:33:36 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 5.mkv*217431157*9/8/2020 2:48:56 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 6.mp4*233347556*9/8/2020 3:03:03 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 7.mp4*224721882*9/8/2020 3:14:20 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 8.mp4*308467293*9/8/2020 3:35:53 PM",
    "La otra guerra (L.C.B) 2da Temporada - Capítulo 9.mp4*134715887*9/8/2020 3:43:09 PM",
    3961321131,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\!TV de Cuba\\Vivir del Cuento*0*9/13/2020 9:04:25 PM",
    "VIVIR DEL CUENTO - Teleconsulta (31 Agosto 2020).mp4*147887658*9/6/2020 7:03:32 PM",
    147887658,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]*0*9/13/2020 9:33:28 PM",
    0,
    "24*27*29*31*32*34*35*36*37"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Avast*0*9/13/2020 9:04:02 PM",
    0,
    "25*26"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Avast\\Vpsupd12 [06-09-20] [179,58 Mb]*0*9/13/2020 9:04:02 PM",
    "Vpsupd12 [06-09-20].exe*188301544*9/6/2020 7:24:25 PM",
    188301544,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Avast\\Vpsupd2014-2016 [06-09-20] [111,21 Mb]*0*9/13/2020 9:03:56 PM",
    "Vpsupd2014-2016 [06-09-20].exe*116617200*9/6/2020 7:23:49 PM",
    116617200,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Avira*0*9/13/2020 9:04:18 PM",
    0,
    "28"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Avira\\Vdf_fusebundle-Avira2010 [06-09-20] [81,96 Mb]*0*9/13/2020 9:04:51 PM",
    "Vdf_fusebundle-Avira2010 [06-09-20].zip*85937408*9/6/2020 7:24:44 PM",
    85937408,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Kaspersky*0*9/13/2020 9:04:25 PM",
    0,
    "30"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Kaspersky\\Updates.Kav.Endpointsecurity [06-09-20] [1,89 Gb]*0*9/13/2020 9:04:27 PM",
    "Updates.Kav.Endpointsecurity [06-09-20].zip*2034223614*9/6/2020 7:32:36 PM",
    2034223614,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Nod 32 .X32.X64- [06-09-20] [136,84 Mb]*0*9/13/2020 9:04:43 PM",
    "Eset_smart_security.X32.X64- [06-09-20].zip*143487165*9/6/2020 7:35:06 PM",
    143487165,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Segurmatica*0*9/13/2020 9:03:49 PM",
    0,
    "33"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Segurmatica\\Update-Segurmatica [06-09-20] [61,66 Mb]*0*9/13/2020 9:03:49 PM",
    "Update-Segurmatica [06-09-20].zip*64654611*9/6/2020 7:35:31 PM",
    64654611,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\V3_4083dat [06-09-20] [269,34 Mb]*0*9/13/2020 9:04:10 PM",
    "V3_4083dat [06-09-20].exe*282423296*9/6/2020 7:33:31 PM",
    282423296,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Weekly2015-2016x32 [06-09-20] [252,26 Mb]*0*9/13/2020 10:34:20 PM",
    "Weekly2015-2016x32 [06-09-20].exe*264510112*9/6/2020 7:25:32 PM",
    264510112,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Weekly2015-2016x64 [06-09-20] [253,59 Mb]*0*9/13/2020 9:34:30 PM",
    "Weekly2015-2016x64 [06-09-20].exe*265911232*9/6/2020 7:27:22 PM",
    265911232,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Windows Defender*0*9/13/2020 9:35:19 PM",
    0,
    "38*39"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Windows Defender\\Mwd-Win10-Mpam-Fex64 [06-09-20] [100,96 Mb]*0*9/13/2020 9:34:03 PM",
    "Mwd-Win10-Mpam-Fex64 [06-09-20].exe*105858992*9/6/2020 7:34:05 PM",
    105858992,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Actualizaciones Antivirus[Avira-Kav-Nod 32-Avast]\\Windows Defender\\Mwd-Win7-Mpas-Fe [06-09-20] [56,76 Mb]*0*9/13/2020 11:15:03 PM",
    "Mwd-Win7-Mpas-Fe [06-09-20].exe*59516864*9/6/2020 7:34:30 PM",
    59516864,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados*0*9/13/2020 9:33:14 PM",
    0,
    "41*48*54*57*58*69"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\[Sagas] [Barbie]*0*9/13/2020 9:31:03 PM",
    0,
    "42*43*44*45*46*47"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\[Sagas] [Barbie]\\Barbie - Pulgarcita*0*9/13/2020 11:07:09 PM",
    "Barbie - Pulgarcita.avi*1471737856*3/3/2012 5:29:02 PM",
    1471737856,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\[Sagas] [Barbie]\\Barbie - Superheroína del videojuego (2017)*0*9/13/2020 9:01:30 PM",
    "Barbie - Superheroína del videojuego (2017).jpg*308129*1/18/2017 9:53:24 PM",
    "Barbie - Superheroína del videojuego (2017).mp4*406736251*1/18/2017 10:14:50 PM",
    407044380,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\[Sagas] [Barbie]\\Barbie - The Secret Door*0*9/13/2020 11:15:36 PM",
    "Barbie - The Secret Door.avi*888285704*8/24/2014 5:37:32 AM",
    888285704,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\[Sagas] [Barbie]\\Barbie and Her Sisters in a Pony Tale*0*9/13/2020 9:02:04 PM",
    "BARBIE Y SUS HERMANAS EN UNA AVENTURA DE CABALLO.jpg*149702*10/28/2013 11:37:30 AM",
    "Barbie.Poni.Tale.R1.Dual.Latino.LEGiON.Title18.avi*848140928*10/17/2013 10:01:04 PM",
    848290630,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\[Sagas] [Barbie]\\Barbie and The Secret Door 2014*0*9/13/2020 9:31:34 PM",
    "Barbie y la puerta secreta (2014).jpg*89210*11/2/2015 10:42:59 PM",
    "Barbie y la puerta secreta (2014).mp4*619196920*11/1/2015 11:00:35 PM",
    619286130,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\[Sagas] [Barbie]\\Barbie Aventura en el Espacio*0*9/13/2020 11:15:10 PM",
    "Barbie Aventura en el Espacio.jpg*44099*4/29/2018 1:30:39 AM",
    "Barbie Aventura en el Espacio.mp4*887653683*4/29/2018 1:49:32 AM",
    887697782,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\[Sagas] [Bratz]*0*9/13/2020 9:37:17 PM",
    0,
    "49*50*51*52*53"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\[Sagas] [Bratz]\\Bratz Babyz Movie*0*9/13/2020 9:37:17 PM",
    "Bratz babyz movie [film].mpg*716603392*5/25/2015 12:58:55 PM",
    "Bratz Babyz Movie.jpg*176041*4/1/2009 9:03:56 AM",
    716779433,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\[Sagas] [Bratz]\\Bratz Babyz salvan la Navidad*0*9/13/2020 9:16:39 PM",
    "Bratz Babyz salvan la Navidad.avi*749166592*12/28/2009 9:09:42 AM",
    749166592,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\[Sagas] [Bratz]\\Bratz Genie Magic*0*9/13/2020 9:16:47 PM",
    "Bratz genie magic.avi*1298597888*3/3/2012 8:21:50 PM",
    "Bratz_Genie_Magic-Caratula.jpg*459705*4/1/2009 8:53:10 AM",
    1299057593,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\[Sagas] [Bratz]\\Bratz Girl Really Rock*0*9/13/2020 10:20:32 PM",
    "Bratz Girl Really Rock.avi*1356728320*3/3/2012 8:57:42 PM",
    "Bratz_Girlz_Really_Rock_R1-[Front]-[www[1].FreeCovers.net].jpg*242484*9/5/2008 7:44:06 PM",
    1356970804,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\[Sagas] [Bratz]\\Bratz Good Vibes (2013)*0*9/13/2020 9:16:46 PM",
    "Bratz (Good Vibes) (2013).jpg*280397*11/4/2018 5:28:14 AM",
    "Bratz good vibes 2013.mp4*149682325*10/31/2018 8:08:01 AM",
    149962722,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Animados Estrenos [HD]*0*9/13/2020 9:33:14 PM",
    0,
    "55"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Animados Estrenos [HD]\\Barbie Princess Adventure [2020] [1080p] [Latino Dual]*0*9/13/2020 10:30:30 PM",
    "Barbie Princess Adventure [2020] [1080p] [Latino Dual].jpg*619288*9/7/2020 5:39:38 PM",
    "Barbie Princess Adventure [2020] [1080p] [Latino Dual].mkv*1691589082*9/7/2020 3:01:42 PM",
    1692208370,
    "56"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Animados Estrenos [HD]\\Barbie Princess Adventure [2020] [1080p] [Latino Dual]\\Subtitulos*0*9/13/2020 9:34:47 PM",
    "ENG.srt*111792*9/7/2020 2:59:33 PM",
    111792,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Animados Variados*0*9/13/2020 11:33:54 PM",
    "44 Gatos [08-09-20].mpg*195561472*9/9/2020 2:44:50 PM",
    "44 Gatos 1 [07-09-20].mpg*100194304*9/9/2020 2:42:40 PM",
    "44 Gatos 2 [07-09-20].mpg*104308736*9/9/2020 2:42:44 PM",
    "44 Gatos 2 [08-09-20].mpg*100050944*9/9/2020 2:42:36 PM",
    "44 Gatos 2 [09-09-20].mpg*105244672*9/10/2020 12:53:08 PM",
    "Abby [08-09-20].mpg*161411072*9/9/2020 2:44:39 PM",
    "Abby Hatched [08-09-20].mpg*213739520*9/9/2020 2:45:10 PM",
    "Alvin y las ardillas [07-09-20].mpg*108011520*9/9/2020 2:42:49 PM",
    "Alvin y las ardillasd [08-09-20].mpg*146657280*9/9/2020 2:43:47 PM",
    "Barbie [07-09-20].mpg*106323968*9/9/2020 2:45:13 PM",
    "Ben 10 [09-09-20].mpg*53762048*9/10/2020 12:51:53 PM",
    "Blaze [07-09-20].mpg*153456640*9/8/2020 4:45:52 PM",
    "Blaze [09-09-20].mpg*149153792*9/10/2020 1:08:34 PM",
    "Blaze Machine [08-09-20].mpg*417011712*9/9/2020 2:52:04 PM",
    "Blaze2 [07-09-20].mpg*166316032*9/8/2020 4:46:16 PM",
    "Blaze2 [09-09-20].mpg*154554368*9/10/2020 1:08:56 PM",
    "Blazer [07-09-20].mpg*170244096*9/9/2020 2:47:25 PM",
    "Blue [07-09-20].mpg*105347072*9/8/2020 4:45:10 PM",
    "Blue [11-09-20].mpg*457986048*9/10/2020 2:00:36 AM",
    "Bob Esponja [07-09-20].mpg*17696768*9/9/2020 2:44:08 PM",
    "Bob Esponja 1 [09-09-20].mpg*160655360*9/10/2020 12:54:48 PM",
    "Bob Esponja 2 [09-09-20].mpg*172761088*9/10/2020 12:54:29 PM",
    "Bob Esponja 3 [09-09-20].mpg*133267456*9/10/2020 12:53:53 PM",
    "Bonie Bears [07-09-20].mpg*157362176*9/9/2020 2:47:51 PM",
    "Bonnies [09-09-20].mpg*155553792*9/10/2020 12:54:41 PM",
    "Bornies bears [08-09-20].mpg*155498496*9/9/2020 2:47:27 PM",
    "Boterrbeans Cafe [08-09-20].mpg*207314944*9/9/2020 2:49:03 PM",
    "Bubble [08-09-20].mpg*159633408*9/9/2020 2:48:51 PM",
    "Bubble Guppies [08-09-20].mpg*158775296*9/9/2020 2:49:07 PM",
    "Bubble Guppies [09-09-20].mpg*154658816*9/10/2020 1:09:03 PM",
    "Bubble Guppies 1 [09-09-20].mpg*150329344*9/10/2020 12:55:56 PM",
    "Bubble Guppies 2 [09-09-20].mpg*159830016*9/10/2020 12:57:56 PM",
    "Corn y Peg [09-09-20].mpg*154882048*9/10/2020 1:09:03 PM",
    "Cup Cake [07-09-20].mpg*49260544*9/8/2020 4:43:08 PM",
    "Cup KAke [08-09-20].mpg*77223936*9/9/2020 2:47:32 PM",
    "Doki [07-09-20].mpg*73132032*9/9/2020 2:48:56 PM",
    "Dora la exploradora [08-09-20].mpg*162836480*9/9/2020 2:51:44 PM",
    "Dora la exploradora2 [08-09-20].mpg*175685632*9/9/2020 2:52:00 PM",
    "Dora la exploradora3 [08-09-20].mpg*153780224*9/9/2020 2:51:46 PM",
    "El increible mundo de Gumball [08-09-20].mpg*273848320*9/9/2020 2:55:15 PM",
    "El increible mundo de Gumball 2 [08-09-20].mpg*379392000*9/9/2020 2:57:35 PM",
    "El increible mundo de Gumball 3 [08-09-20].mpg*325388288*9/9/2020 2:56:14 PM",
    "El mundo de Craig [07-09-20].mpg*73484288*9/9/2020 2:50:51 PM",
    "El mundo de Craig [08-09-20].mpg*409714688*9/9/2020 3:00:42 PM",
    "El mundo de Luna [08-09-20].mpg*68392960*9/9/2020 2:55:07 PM",
    "El Mundo de Luna 2 [07-09-20].mpg*78563328*9/9/2020 2:53:33 PM",
    "El Mundo de Luna 4 [07-09-20].mpg*76822528*9/9/2020 2:53:41 PM",
    "El mundo de Luna 5 [07-09-20].mpg*76316672*9/9/2020 2:55:13 PM",
    "El show de Peabody [11-09-20].mpg*757878784*9/10/2020 11:53:31 PM",
    "Enredados ota vez [11-09-20].mpg*697206784*9/11/2020 12:45:22 AM",
    "Escandalosos [07-09-20].mpg*139988992*9/9/2020 2:57:12 PM",
    "Escandalosos [08-09-20].mpg*181508096*9/9/2020 2:57:51 PM",
    "Escandalosos [09-09-20].mpg*110952448*9/10/2020 12:57:22 PM",
    "Esme y Roy [08-09-20].mpg*17246208*9/9/2020 2:55:34 PM",
    "Esme y Roy [09-09-20].mpg*163993600*9/10/2020 12:58:57 PM",
    "Franckie [08-09-20].mpg*179191808*9/9/2020 2:59:44 PM",
    "Franckie 1 [09-09-20].mpg*84062208*9/10/2020 12:57:03 PM",
    "Franckie 2 [08-09-20].mpg*165693440*9/9/2020 2:59:14 PM",
    "Franckie 2 [09-09-20].mpg*172255232*9/10/2020 12:59:54 PM",
    "Franckie 3 [09-09-20].mpg*175181824*9/10/2020 1:00:43 PM",
    "Gravity Falls [08-09-20].mpg*153473024*9/9/2020 3:01:32 PM",
    "Gumball 1 [07-09-20].mpg*89839616*9/9/2020 2:59:07 PM",
    "Gumball 2 [07-09-20].mpg*73490432*9/9/2020 2:58:58 PM",
    "Gumball 3 [07-09-20].mpg*99602432*9/9/2020 2:59:29 PM",
    "Gusanos [08-09-20].mpg*82948096*9/9/2020 3:00:32 PM",
    "Heroes en pijamas [11-09-20].mpg*385478656*9/10/2020 1:54:02 AM",
    "Heroes en pijamas2 [11-09-20].mpg*386201600*9/10/2020 1:59:49 AM",
    "Hotel transylvania [11-09-20].mpg*354574336*9/11/2020 12:43:09 AM",
    "Jovenes titanes [08-09-20].mpg*445513728*9/9/2020 3:09:59 PM",
    "Jovenes Titanes [09-09-20].mpg*72495104*9/10/2020 12:57:46 PM",
    "Jovenes Titanes 2 [07-09-20].mpg*56102912*9/9/2020 3:00:12 PM",
    "Kid e Cats [07-09-20].mpg*66433024*9/8/2020 4:43:37 PM",
    "Kid e Cats2 [07-09-20].mpg*34269184*9/8/2020 4:42:51 PM",
    "Kid e Cats3 [07-09-20].mpg*68628480*9/8/2020 4:43:53 PM",
    "Kong la pelis [08-09-20].mpg*559706112*9/9/2020 3:13:00 PM",
    "Kun Fu Panda [07-09-20].mpg*124010496*9/9/2020 3:03:11 PM",
    "Kun Fu Panda [08-09-20].mpg*194447360*9/9/2020 3:05:08 PM",
    "LA casa Buo [11-09-20].mpg*679241728*9/11/2020 12:49:58 AM",
    "La casa de Mickey MAuse [11-09-20].mpg*746711040*9/10/2020 1:57:16 AM",
    "La guerra de las galaxia [08-09-20].mpg*152705024*9/9/2020 3:05:54 PM",
    "La guerra de las Galaxias [07-09-20].mpg*149491712*9/8/2020 4:46:17 PM",
    "La magia de Sadie [08-09-20].mpg*77641728*9/9/2020 3:04:24 PM",
    "La magia de Sadie 1 [09-09-20].mpg*35727360*9/10/2020 12:57:00 PM",
    "La magia de Sadie 2 [07-09-20].mpg*76525568*9/9/2020 3:02:33 PM",
    "La magia de Sadie 5 [07-09-20].mpg*77832192*9/9/2020 3:04:14 PM",
    "Las Aventura de Roky [08-09-20].mpg*142417920*9/9/2020 3:06:53 PM",
    "Las aventuras de Rocky 1 [09-09-20].mpg*150362112*9/10/2020 1:00:50 PM",
    "Las aventuras de Rocky 2 [09-09-20].mpg*146712576*9/10/2020 1:01:01 PM",
    "Las chicas superpoderosas [09-09-20].mpg*77201408*9/10/2020 1:07:59 PM",
    "LAs chicas superpoderosas [11-09-20].mpg*366333952*9/9/2020 9:59:16 PM",
    "Las chicas superpoderosas_New1 [11-09-20].mpg*311451648*9/10/2020 11:50:59 PM",
    "Las chicas superpoderosas2 [09-09-20].mpg*77395968*9/10/2020 1:07:55 PM",
    "LAs chicas superpoderosas2 [11-09-20].mpg*360218624*9/9/2020 9:59:46 PM",
    "Las chicas superpoderosas2_New1 [11-09-20].mpg*314044416*9/10/2020 11:51:26 PM",
    "Las chicas superpoderosas3 [09-09-20].mpg*77264896*9/10/2020 1:10:40 PM",
    "LAs chicas superpoderosas3 [11-09-20].mpg*343531520*9/9/2020 10:00:11 PM",
    "Las chicas superpoderosas3_New1 [11-09-20].mpg*356175872*9/10/2020 11:51:54 PM",
    "Las chicas superpoderosas4 [09-09-20].mpg*77191168*9/10/2020 1:10:44 PM",
    "LAs chicas superpoderosas4 [11-09-20].mpg*412047360*9/9/2020 10:00:43 PM",
    "Las chicas superpoderosas4_New1 [11-09-20].mpg*287375360*9/10/2020 11:52:26 PM",
    "LAs chicas superpoderosas5 [10-09-20].mpg*656717824*9/9/2020 10:01:38 PM",
    "Las heroinas del Arcoiis [07-09-20].mpg*159225856*9/8/2020 4:47:00 PM",
    "Las Pistas de Blu [08-09-20].mpg*250648576*9/9/2020 3:10:05 PM",
    "LAs pistas de Blu [10-09-20].mpg*698707968*9/9/2020 2:39:26 AM",
    "LAs pistas de Blue [09-09-20].mpg*157097984*9/10/2020 1:13:12 PM",
    "Los peque [08-09-20].mpg*48201728*9/9/2020 3:06:40 PM",
    "Los peque [09-09-20].mpg*49283072*9/10/2020 12:59:05 PM",
    "Los peque 3 [07-09-20].mpg*50583552*9/9/2020 3:06:25 PM",
    "Los vecinos Green [07-09-20].mpg*154470400*9/8/2020 4:46:24 PM",
    "Los vecinos Green [08-09-20].mpg*157409280*9/9/2020 3:10:35 PM",
    "Los vecinos Green [11-09-20].mpg*676958208*9/11/2020 12:43:53 AM",
    "Manzana y cebollin [08-09-20].mpg*119660544*9/9/2020 4:06:09 PM",
    "Manzana y cebollin [09-09-20].mpg*110694400*9/10/2020 1:00:40 PM",
    "Masha y el oso [07-09-20].mpg*228577280*9/8/2020 4:48:39 PM",
    "Mi perro Pat [09-09-20].mpg*50235392*9/10/2020 1:00:13 PM",
    "Mi perro Pat 1 [07-09-20].mpg*50235392*9/9/2020 3:07:56 PM",
    "Mickey [08-09-20].mpg*75892736*9/9/2020 3:10:26 PM",
    "Mickey Aventuras [10-09-20].mpg*408270848*9/10/2020 2:01:05 AM",
    "Mickey Mause [07-09-20].mpg*76556288*9/8/2020 4:45:32 PM",
    "Mickey sobre ruedas [07-09-20].mpg*83017728*9/8/2020 4:47:29 PM",
    "Mickey Sobre ruedas [10-09-20].mpg*372236288*9/10/2020 1:58:40 AM",
    "Mickey2 [08-09-20].mpg*76220416*9/9/2020 3:10:35 PM",
    "Mighty [09-09-20].mpg*53411840*9/10/2020 1:10:25 PM",
    "Mighty Mike [10-09-20].mpg*152141824*9/9/2020 10:02:08 PM",
    "Mightyu [11-09-20].mpg*156246016*9/10/2020 11:53:56 PM",
    "Mini power rockets [08-09-20].mpg*32952320*9/9/2020 3:10:46 PM",
    "Mini power rockets 2 [08-09-20].mpg*69052416*9/9/2020 3:10:14 PM",
    "Monica Joven [07-09-20].mpg*94167040*9/9/2020 3:12:07 PM",
    "Ms Trance 2 [07-09-20].mpg*32133120*9/9/2020 3:11:01 PM",
    "Muppet Baby [07-09-20].mpg*84377600*9/8/2020 4:47:55 PM",
    "Ninja [08-09-20].mpg*77324288*9/9/2020 3:13:17 PM",
    "Ollie pocket [08-09-20].mpg*172492800*9/9/2020 3:14:18 PM",
    "Ollie Pockets [09-09-20].mpg*180185088*9/10/2020 1:04:36 PM",
    "Ositos del Cariño [07-09-20].mpg*185413632*9/9/2020 3:14:44 PM",
    "Osos Cariñosos [08-09-20].mpg*214360064*9/9/2020 3:15:34 PM",
    "Padrinos mágicos [07-09-20].mpg*170213376*9/9/2020 3:15:19 PM",
    "Padrinos Magicos 1 [09-09-20].mpg*164898816*9/10/2020 1:04:30 PM",
    "Padrinos Magicos 2 [09-09-20].mpg*177209344*9/10/2020 1:04:37 PM",
    "Pantera Negra [07-09-20].mpg*151996416*9/8/2020 4:50:05 PM",
    "Parientes magicos [08-09-20].mpg*134529024*9/9/2020 3:15:01 PM",
    "Peadbody [09-09-20].mpg*158998528*9/10/2020 1:18:51 PM",
    "Pepino [08-09-20].mpg*77285376*9/9/2020 3:15:31 PM",
    "peppa [08-09-20].mpg*69058560*9/9/2020 3:16:21 PM",
    "Peppa 2 [08-09-20].mpg*188227584*9/9/2020 3:17:39 PM",
    "Peppa 3 [08-09-20].mpg*218525696*9/9/2020 3:19:05 PM",
    "Peppa Pig 1 [07-09-20].mpg*66824192*9/9/2020 3:16:34 PM",
    "Peppa Pig 2 [09-09-20].mpg*100577280*9/10/2020 1:03:34 PM",
    "Peppa Pig 3 [07-09-20].mpg*74379264*9/9/2020 3:17:11 PM",
    "Peppa Pig 4 [09-09-20].mpg*57980928*9/10/2020 1:02:27 PM",
    "Peppa Pig 5 [07-09-20].mpg*68530176*9/9/2020 3:17:30 PM",
    "Peppa Pig 8 [07-09-20].mpg*66891776*9/9/2020 3:18:23 PM",
    "Peppa Pig 9 [07-09-20].mpg*163837952*9/9/2020 3:21:10 PM",
    "Pescesuelo [08-09-20].mpg*80830464*9/9/2020 3:20:06 PM",
    "Phineas y Ferb [08-09-20].mpg*77246464*9/9/2020 3:19:48 PM",
    "Phines y Ferb [07-09-20].mpg*84400128*9/8/2020 4:48:14 PM",
    "Pinguinos de Madagascar [08-09-20].mpg*177586176*9/9/2020 3:21:57 PM",
    "Pinguinos de Madagascar [09-09-20].mpg*160610304*9/10/2020 1:05:20 PM",
    "Pow Patrol [08-09-20].mpg*189925376*9/9/2020 3:23:50 PM",
    "Pow Patrol 2 [07-09-20].mpg*76173312*9/9/2020 3:19:44 PM",
    "Pow Patrol 2 [08-09-20].mpg*417734656*9/9/2020 3:28:11 PM",
    "Power Players [07-09-20].mpg*60268544*9/9/2020 3:20:53 PM",
    "Power Rockers 1 [07-09-20].mpg*69130240*9/9/2020 3:21:24 PM",
    "Power Rockers 2 [07-09-20].mpg*34111488*9/9/2020 3:20:59 PM",
    "Power Rockers 5 [07-09-20].mpg*69156864*9/9/2020 3:22:22 PM",
    "Power Rockers 6 [07-09-20].mpg*67342336*9/9/2020 3:22:33 PM",
    "Power Rockers 7 [07-09-20].mpg*67557376*9/9/2020 3:22:47 PM",
    "Power Rockers 8 [07-09-20].mpg*32444416*9/9/2020 3:22:50 PM",
    "Puppy Dog [10-09-20].mpg*630165504*9/9/2020 1:16:11 AM",
    "Puppy Dog_New1 [10-09-20].mpg*396640256*9/10/2020 1:59:14 AM",
    "Quedate en casa [07-09-20].mpg*93829120*9/9/2020 3:24:29 PM",
    "Rainmbow ruby [08-09-20].mpg*187860992*9/9/2020 3:26:40 PM",
    "Ranger Rainmbows [08-09-20].mpg*210948096*9/9/2020 3:27:17 PM",
    "Ricky Zoom [07-09-20].mpg*96569344*9/9/2020 3:26:07 PM",
    "Ricky Zoom [08-09-20].mpg*176687104*9/9/2020 3:26:42 PM",
    "Rocky [07-09-20].mpg*169730048*9/9/2020 3:29:06 PM",
    "Rusty Rivers [08-09-20].mpg*161964032*9/9/2020 3:28:29 PM",
    "Spiderman [07-09-20].mpg*150839296*9/8/2020 4:49:52 PM",
    "Spiderman [08-09-20].mpg*162453504*9/9/2020 3:31:01 PM",
    "Sunjay y craig [08-09-20].mpg*138412032*9/9/2020 3:30:28 PM",
    "Super heroinas [08-09-20].mpg*187475968*9/9/2020 3:30:51 PM",
    "Super Monstruos [07-09-20].mpg*76240896*9/9/2020 3:29:27 PM",
    "Tania la taina [08-09-20].mpg*77367296*9/9/2020 3:30:03 PM",
    "Tania la taina2 [08-09-20].mpg*77398016*9/9/2020 3:30:24 PM",
    "Tio Grandpa [10-09-20].mpg*344565760*9/9/2020 9:56:08 PM",
    "Tio Grandpa2 [10-09-20].mpg*291715072*9/9/2020 9:56:40 PM",
    "Tio Grandpa3 [10-09-20].mpg*378916864*9/9/2020 9:57:20 PM",
    "Tio Grandpa4 [10-09-20].mpg*333066240*9/9/2020 9:57:44 PM",
    "Tio Grandpa5 [10-09-20].mpg*224909312*9/9/2020 9:58:15 PM",
    "Tio Grandpas [07-09-20].mpg*77787136*9/8/2020 4:48:07 PM",
    "Tio Grandpas2 [07-09-20].mpg*158375936*9/8/2020 4:51:08 PM",
    "Tio Grandpas3 [07-09-20].mpg*78714880*9/8/2020 4:49:28 PM",
    "Tom y Jerry [07-09-20].mpg*102526976*9/8/2020 4:50:28 PM",
    "Tom y Jerry [10-09-20].mpg*211656704*9/9/2020 2:40:31 AM",
    "Tom y Jerry_New1 [10-09-20].mpg*176099328*9/9/2020 10:02:35 PM",
    "Tom y Jerry2 [10-09-20].mpg*226828288*9/9/2020 2:41:01 AM",
    "Tom y Jerry2_New1 [10-09-20].mpg*194637824*9/9/2020 10:03:03 PM",
    "Tom y Jerry3 [10-09-20].mpg*332929024*9/9/2020 2:41:36 AM",
    "Tom y Jerry3 [11-09-20].mpg*161339392*9/9/2020 10:03:33 PM",
    "Tom y Jerry4 [10-09-20].mpg*220239872*9/9/2020 2:43:08 AM",
    "Tom y Jerry4 [11-09-20].mpg*186066944*9/9/2020 10:04:37 PM",
    "Top Wing [07-09-20].mpg*154789888*9/8/2020 4:52:48 PM",
    "Tortugas ninjas [09-09-20].mpg*163747840*9/10/2020 1:08:04 PM",
    "Tots [07-09-20].mpg*77674496*9/8/2020 4:50:33 PM",
    "TOTS [11-09-20].mpg*368852992*9/10/2020 2:01:32 AM",
    "Unikity [09-09-20].mpg*77355008*9/10/2020 1:12:02 PM",
    "Unikity [10-09-20].mpg*345544704*9/9/2020 2:43:34 AM",
    "Unikity [11-09-20].mpg*387403776*9/9/2020 10:05:03 PM",
    "Vampirina [11-09-20].mpg*745861120*9/10/2020 1:53:26 AM",
    "Victor y Valentino [07-09-20].mpg*159457280*9/9/2020 3:33:06 PM",
    "Victor y Valentino [08-09-20].mpg*186906624*9/9/2020 3:34:23 PM",
    "Victor y Valentino [09-09-20].mpg*173391872*9/10/2020 1:08:28 PM",
    "Victor y Valentino 2 [08-09-20].mpg*174143488*9/9/2020 3:33:05 PM",
    "Yokay [08-09-20].mpg*144738304*9/9/2020 3:35:12 PM",
    39153838080,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Series Animadas*0*9/13/2020 10:13:03 PM",
    0,
    "59*60*61*62*63*64*65*66*67*68"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Series Animadas\\Cake 2019 - [Temp 3] [Cap-09] [AVI] [201,47 Mb]*0*9/13/2020 10:13:00 PM",
    "Cake 2019 - 3x09.avi*211257626*9/6/2020 3:59:05 PM",
    211257626,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Series Animadas\\Cake 2019 - [Temp 3] [Cap-10] [MP4] [211,98 Mb]*0*9/13/2020 10:11:59 PM",
    "Cake 2019 - 3x10.mp4*222276227*9/6/2020 5:27:47 PM",
    222276227,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Series Animadas\\Memorias de idhun - [Temp 1] [Cap-01] [MP4] [189,71 Mb]*0*9/13/2020 10:12:30 PM",
    "Memorias de idhun - 1x01.mp4*198920303*9/10/2020 4:21:11 PM",
    198920303,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Series Animadas\\Memorias de idhun - [Temp 1] [Cap-02] [MP4] [176,89 Mb]*0*9/13/2020 10:12:52 PM",
    "Memorias de idhun - 1x02.mp4*185487281*9/10/2020 3:13:10 AM",
    185487281,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Series Animadas\\Memorias de idhun - [Temp 1] [Cap-03] [MP4] [176,64 Mb]*0*9/13/2020 10:12:35 PM",
    "Memorias de idhun - 1x03.mp4*185224040*9/10/2020 3:15:01 AM",
    185224040,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Series Animadas\\Memorias de idhun - [Temp 1] [Cap-04] [MP4] [179,86 Mb]*0*9/13/2020 10:11:28 PM",
    "Memorias de idhun - 1x04.mp4*188594876*9/10/2020 3:18:34 AM",
    188594876,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Series Animadas\\Memorias de idhun - [Temp 1] [Cap-05] [MP4] [175,61 Mb]*0*9/13/2020 10:11:36 PM",
    "Memorias de idhun - 1x05.mp4*184144971*9/10/2020 3:21:36 AM",
    184144971,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Series Animadas\\Monty - [Temp 1] [Cap-11] [MP4] [133,12 Mb]*0*9/13/2020 10:13:07 PM",
    "Monty - 1x11.mp4*139581540*9/6/2020 10:07:12 PM",
    139581540,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Series Animadas\\Monty - [Temp 1] [Cap-12] [MP4] [131,14 Mb]*0*9/13/2020 11:25:18 PM",
    "Monty - 1x12.mp4*137505715*9/6/2020 10:01:05 PM",
    137505715,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Series Animadas\\Monty - [Temp 1] [Cap-13] [MP4] [133,29 Mb]*0*9/13/2020 10:12:55 PM",
    "Monty - 1x13.mp4*139759769*9/6/2020 9:55:50 PM",
    139759769,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Series Animadas [Finalizadas]*0*9/13/2020 10:11:23 PM",
    0,
    "70*71"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Series Animadas [Finalizadas]\\Circuito De Cortos (Temporada 1) [14 Cap.] FDT*0*9/13/2020 11:30:51 PM",
    "Circuito De Cortos 1x01 [Esp].mkv*210921332*8/30/2020 9:23:04 PM",
    "Circuito De Cortos 1x02 [Esp].mkv*226268073*8/30/2020 9:22:44 PM",
    "Circuito De Cortos 1x03 [Esp].mkv*203183656*8/30/2020 9:22:17 PM",
    "Circuito De Cortos 1x04 [Esp].mkv*244714313*8/30/2020 9:20:35 PM",
    "Circuito De Cortos 1x05 [Esp].mkv*351207001*8/30/2020 9:21:05 PM",
    "Circuito De Cortos 1x06 [Esp].mkv*265510144*8/30/2020 9:18:23 PM",
    "Circuito De Cortos 1x07 [Esp].mkv*267636901*8/30/2020 9:18:10 PM",
    "Circuito De Cortos 1x08 [Esp].mkv*218394223*8/30/2020 9:17:27 PM",
    "Circuito De Cortos 1x09 [Esp].mkv*229630586*8/30/2020 9:16:22 PM",
    "Circuito De Cortos 1x10 [Esp].mkv*260282498*8/30/2020 9:15:21 PM",
    "Circuito De Cortos 1x11 [Esp].mkv*283840041*8/30/2020 9:14:22 PM",
    "Circuito De Cortos 1x12 [Esp].mkv*209968770*8/30/2020 9:12:54 PM",
    "Circuito De Cortos 1x13 [Esp].mkv*305248553*8/30/2020 9:12:18 PM",
    "Circuito De Cortos 1x14 [Esp].mkv*236453036*8/30/2020 9:12:00 PM",
    "Circuito-De-Cortos.jpg*116231*8/30/2020 9:11:02 PM",
    3513375358,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Animados\\Series Animadas [Finalizadas]\\Family Guy - [Temp 16] [Caps.20] [1080p] [Dual Audio]*0*9/13/2020 11:23:20 PM",
    "S16E01.mkv*271366331*1/16/2019 2:44:11 PM",
    "S16E02.mkv*282733606*1/16/2019 2:41:41 PM",
    "S16E03.mkv*291506921*1/16/2019 2:47:14 PM",
    "S16E04.mkv*260905888*1/16/2019 2:49:35 PM",
    "S16E05.mkv*471662244*1/16/2019 4:56:53 PM",
    "S16E06.mkv*338273688*1/16/2019 4:59:56 PM",
    "S16E07.mkv*269408142*1/16/2019 5:03:55 PM",
    "S16E08.mkv*241298287*1/16/2019 5:30:09 PM",
    "S16E09.mkv*426293196*1/16/2019 5:32:11 PM",
    "S16E10.mkv*318098085*1/16/2019 5:34:33 PM",
    "S16E11.mkv*369517842*1/16/2019 11:59:13 PM",
    "S16E12.mkv*333331341*1/17/2019 12:49:52 AM",
    "S16E13.mkv*445262184*1/17/2019 9:40:49 AM",
    "S16E14.mkv*379058392*1/17/2019 11:43:09 AM",
    "S16E15.mkv*331639460*1/17/2019 11:19:37 AM",
    "S16E16.mkv*312039728*1/17/2019 11:24:11 AM",
    "S16E17.mkv*339658994*1/17/2019 11:58:26 AM",
    "S16E18.mkv*370183490*1/17/2019 12:03:37 PM",
    "S16E19.mkv*262038028*1/17/2019 12:07:46 PM",
    "S16E20.mkv*147267558*1/17/2019 2:58:47 PM",
    6461543405,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles*0*9/13/2020 8:48:04 PM",
    0,
    "73"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]*0*9/13/2020 9:57:18 PM",
    0,
    "74*75*76*77*78*79*80*81*82*83*84*85*86*87*88*89*90*91*92*93*96*97*98*99"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Audiomack  Download New Music v5.4.2 [Unlocked] [Mod] [SAP] [23,35 Mb]*0*9/13/2020 9:16:45 PM",
    "Audiomack  Download New Music v5.4.2 [Unlocked] [Mod] [SAP].apk*24483460*6/7/2020 1:04:02 PM",
    24483460,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Color Lines  Icon Pack v2.5 [22,6 Mb]*0*9/13/2020 9:16:46 PM",
    "Color Lines  Icon Pack v2.5.apk*23701453*6/7/2020 12:57:42 PM",
    23701453,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Delux  Round Icon pack v1.3.2 [21,93 Mb]*0*9/13/2020 10:20:32 PM",
    "Delux  Round Icon pack v1.3.2.apk*22990950*6/7/2020 12:54:13 PM",
    22990950,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Flat Dark Evo  Icon Pack v3.6 [22,89 Mb]*0*9/13/2020 9:16:45 PM",
    "Flat Dark Evo  Icon Pack v3.6.apk*24003035*6/7/2020 12:53:50 PM",
    24003035,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Flat Evo  Icon Pack v4.1 [24,3 Mb]*0*9/13/2020 9:16:57 PM",
    "Flat Evo  Icon Pack v4.1.apk*25481874*6/7/2020 12:55:58 PM",
    25481874,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Flat TearDrop  Icon Pack v2.5 [24,39 Mb]*0*9/13/2020 9:16:46 PM",
    "Flat TearDrop  Icon Pack v2.5.apk*25573471*6/7/2020 12:53:47 PM",
    25573471,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Hera Icon Pack  Circle Icons v2.8 [24,29 Mb]*0*9/13/2020 9:16:57 PM",
    "Hera Icon Pack  Circle Icons v2.8.apk*25468368*6/7/2020 12:40:40 PM",
    25468368,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\iPear 13  Round Icon Pack v1.0.7 [22,27 Mb]*0*9/13/2020 9:16:46 PM",
    "iPear 13  Round Icon Pack v1.0.7.apk*23353811*6/7/2020 12:55:32 PM",
    23353811,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Learn English Sentence Master Pro v1.6 [Paid] [22,12 Mb]*0*9/13/2020 9:16:46 PM",
    "Learn English Sentence Master Pro v1.6 [Paid].apk*23189951*6/7/2020 12:37:28 PM",
    23189951,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Muslim Pro  Prayer Times, Azan, Quran &amp; Qibla v11.0.1 [Premium] ARMv7 [23,34 Mb]*0*9/13/2020 9:57:09 PM",
    "Muslim Pro  Prayer Times, Azan, Quran &amp; Qibla v11.0.1 [Premium] ARMv7.apk*24472744*6/7/2020 12:55:32 PM",
    24472744,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Muslim Pro  Prayer Times, Azan, Quran &amp; Qibla v11.0.1 [Premium] ARMv8 [23,36 Mb]*0*9/13/2020 11:34:04 PM",
    "Muslim Pro  Prayer Times, Azan, Quran &amp; Qibla v11.0.1 [Premium] ARMv8.apk*24493222*6/7/2020 1:01:57 PM",
    24493222,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Myway [63,79 Mb]*0*9/13/2020 10:41:02 PM",
    "Myway App.apk*5603633*7/8/2020 7:41:06 AM",
    "MYWAY spot VARIANTE 02.mp4*61285193*7/8/2020 7:32:26 AM",
    66888826,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Nova Icon Pack  Rounded Square Icons v2.8 [22,98 Mb]*0*9/13/2020 9:56:58 PM",
    "Nova Icon Pack  Rounded Square Icons v2.8.apk*24093160*6/7/2020 12:50:16 PM",
    24093160,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Otaku.Va [8,68 Mb]*0*9/13/2020 10:24:14 PM",
    "Otaku.Va.apk*9101546*8/9/2019 7:56:24 AM",
    9101546,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Overdrop Weather  Animated Forecast &amp; Widgets v1.5.6.1 [Final] [Pro] [21,88 Mb]*0*9/13/2020 9:57:17 PM",
    "Overdrop Weather  Animated Forecast &amp; Widgets v1.5.6.1 [Final] [Pro].apk*22945731*6/7/2020 1:00:16 PM",
    22945731,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Podcast Addict v2020.7.1 build 20154 [Donate] [Mod] [AOSP] [23,86 Mb]*0*9/13/2020 9:57:17 PM",
    "Podcast Addict v2020.7.1 build 20154 [Donate] [Mod] [AOSP].apk*25021731*6/7/2020 1:00:40 PM",
    25021731,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Podcast Addict v2020.8 build 20179 [Donate] [Mod] [AOSP] [24,07 Mb]*0*9/13/2020 10:24:18 PM",
    "Podcast Addict v2020.8 build 20179 [Donate] [Mod] [AOSP].apk*25236041*6/7/2020 12:50:18 PM",
    25236041,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Psiphon Pro  The Internet Freedom VPN v272 [Subscribed] [Mod] [AOSP] [22,17 Mb]*0*9/13/2020 9:57:09 PM",
    "Psiphon Pro  The Internet Freedom VPN v272 [Subscribed] [Mod] [AOSP].apk*23250112*6/7/2020 1:02:22 PM",
    23250112,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\The Flight Tracker v2.6.2 [Paid] [24,25 Mb]*0*9/13/2020 9:57:17 PM",
    "The Flight Tracker v2.6.2 [Paid].apk*25432785*6/7/2020 1:00:40 PM",
    25432785,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Timbirichi [Android][1.0.7] + Base Datos [timbirichi [01 Septiembre 2020] ] [244 MB] [1,19 Gb]*0*9/13/2020 9:57:14 PM",
    "APLICACION TIMBIRICHI.png*53714*3/8/2019 3:46:27 PM",
    "IMAGENES PLAY ANUNCIO.png*373806*3/8/2019 3:32:51 PM",
    "IMAGENES PLAY ANUNCIOS GUARDADOS.png*452073*3/8/2019 3:36:03 PM",
    "IMAGENES PLAY CATEGORIAS.png*221585*3/8/2019 3:42:35 PM",
    "IMAGENES PLAY CONTACTO.png*358973*3/8/2019 3:38:30 PM",
    "IMAGENES PLAY FILTROS.png*275376*3/8/2019 3:28:20 PM",
    "PLAY IMG BUSCAR BASE DATOS.png*142813*3/8/2019 4:49:13 AM",
    "PLAY IMG SELEC DB.png*292063*3/8/2019 4:46:01 AM",
    2170403,
    "94*95"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Timbirichi [Android][1.0.7] + Base Datos [timbirichi [01 Septiembre 2020] ] [244 MB] [1,19 Gb]\\[ Base Datos ] [1,18 Gb]*0*9/13/2020 10:24:15 PM",
    "desktop.ini*62*1/14/2020 1:13:57 PM",
    "--INFORMACION!!!!!!.txt*242*1/7/2020 2:33:34 PM",
    "timbirichi [01 Septiembre 2020] .db*250432512*9/1/2020 12:48:24 PM",
    "timbirichi [04 Agosto 2020] .db*261139456*8/4/2020 1:23:11 PM",
    "timbirichi [11 Agosto 2020] .db*256696320*8/11/2020 2:25:35 PM",
    "timbirichi [18 Agosto 2020] .db*251356160*8/18/2020 2:06:49 PM",
    "timbirichi [26 Agosto 2020] .db*251460608*8/26/2020 2:56:07 PM",
    1271085360,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\Timbirichi [Android][1.0.7] + Base Datos [timbirichi [01 Septiembre 2020] ] [244 MB] [1,19 Gb]\\Aplicacion  [1.0.7] [7,11 Mb]*0*9/13/2020 11:24:59 PM",
    "Timbirichi107.apk*6864860*3/14/2019 12:10:01 AM",
    "TIMBIRICHIPK_1.jpg*47664*4/30/2018 8:49:23 PM",
    "TIMBIRICHIPK_2.jpg*111002*4/30/2018 8:50:00 PM",
    "TIMBIRICHIPK_3.jpg*148456*4/30/2018 8:50:20 PM",
    "TIMBIRICHIPK_4.jpg*94120*4/30/2018 8:50:37 PM",
    "TIMBIRICHIPK_5.jpg*189853*4/30/2018 8:53:27 PM",
    7455955,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\tinyCam PRO  Swiss knife to monitor IP cam v14.3.2 [Final] [Paid] ARMv7 [23,39 Mb]*0*9/13/2020 9:56:59 PM",
    "tinyCam PRO  Swiss knife to monitor IP cam v14.3.2 [Final] [Paid] ARMv7.apk*24527986*6/7/2020 12:52:55 PM",
    24527986,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\tinyCam PRO  Swiss knife to monitor IP cam v14.3.3 [Final] [Paid] ARMv7 [23,49 Mb]*0*9/13/2020 10:24:15 PM",
    "tinyCam PRO  Swiss knife to monitor IP cam v14.3.3 [Final] [Paid] ARMv7.apk*24630210*6/7/2020 12:58:06 PM",
    24630210,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\YoWindow Weather v2.19.11 [Paid] [21,67 Mb]*0*9/13/2020 8:48:30 PM",
    "YoWindow Weather v2.19.11 [Paid].apk*22724266*6/7/2020 12:42:16 PM",
    22724266,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones Moviles\\Aplicaciones Android [Libre de pago]\\YoWindow Weather v2.19.9 [Paid] [21,7 Mb]*0*9/13/2020 8:48:04 PM",
    "YoWindow Weather v2.19.9 [Paid].apk*22748878*6/7/2020 1:01:31 PM",
    22748878,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC*0*9/13/2020 9:36:39 PM",
    0,
    "101*102*103*104*105*106*107*108*109*110*111*112*113*114*115*116*117*118*119*120*121*122*123*124*125*126*127*128*129*130*131*132*133*134*135*136"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Abelssoft BankingBrowser 2020 v2.6.8 [115,73 Mb]*0*9/13/2020 8:49:32 PM",
    "Abelssoft BankingBrowser 2020 v2.6.8.zip*121350753*6/26/2020 3:25:41 PM",
    121350753,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Adobe_Premiere_Pro_2020_v14.3.1.45_(x64)_Multilingual-P2P [1,53 Gb]*0*9/13/2020 8:48:39 PM",
    "Adobe_Premiere_Pro_2020_v14.3.1.45_(x64)_Multilingual-P2P.rar*1642135899*7/18/2020 9:25:12 PM",
    1642135899,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Affinity.Designer.1.8.4.681.Beta [483,12 Mb]*0*9/13/2020 8:49:01 PM",
    "Affinity.Designer.1.8.4.681.Beta.rar*506585480*7/18/2020 9:56:45 PM",
    506585480,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Apowersoft Video Converter Studio Versión 4.8.4 Full Español [47,22 Mb]*0*9/13/2020 8:49:47 PM",
    "Apowersoft Video Converter Studio Versión 4.8.4 Full Español.rar*49509195*7/4/2020 9:14:03 PM",
    49509195,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Apowersoft Video Converter Studio Versión 4.8.4 Portable [60,19 Mb]*0*9/13/2020 8:49:31 PM",
    "Apowersoft Video Converter Studio Versión 4.8.4 Portable.rar*63111233*7/4/2020 9:16:46 PM",
    63111233,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] App.Builder.2020.88 [118,57 Mb]*0*9/13/2020 8:49:29 PM",
    "App.Builder.2020.88.rar*124333849*6/13/2020 8:59:37 PM",
    124333849,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] App.Builder.2020.91 [118,52 Mb]*0*9/13/2020 8:49:48 PM",
    "App.Builder.2020.91.rar*124274226*6/24/2020 9:04:57 AM",
    124274226,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Ashampoo.Soundstage.Pro.1.0.3 [108,7 Mb]*0*9/13/2020 8:49:23 PM",
    "Ashampoo.Soundstage.Pro.1.0.3.rar*113975526*6/24/2020 9:03:15 AM",
    113975526,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Ashampoo.WinOptimizer.18.00.16 [19,33 Mb]*0*9/13/2020 8:49:17 PM",
    "Ashampoo.WinOptimizer.18.00.16.rar*20268149*6/13/2020 9:10:20 PM",
    20268149,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Avira Antivirus Pro 15.0.2006.1902 [158,48 Mb]*0*9/13/2020 8:48:33 PM",
    "Avira Antivirus Pro 15.0.2006.1902.zip*166179025*6/24/2020 7:36:45 PM",
    166179025,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] CCleaner.5.68.7820 [71,37 Mb]*0*9/13/2020 8:48:10 PM",
    "CCleaner.5.68.7820.rar*74838227*6/24/2020 8:58:38 AM",
    74838227,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] ContentGrabberPremium2 [133 Mb]*0*9/13/2020 8:49:56 PM",
    "ContentGrabberPremium2.rar*139462075*7/4/2020 7:59:39 PM",
    139462075,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Craft.Edge.Sure.Cuts.A.Lot.Pro.5.038 [141,52 Mb]*0*9/13/2020 8:50:03 PM",
    "Craft.Edge.Sure.Cuts.A.Lot.Pro.5.038.rar*148390911*6/24/2020 8:56:53 AM",
    148390911,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] DiskInternals_Partition_Recovery_8.0.3.0 [59,54 Mb]*0*9/13/2020 8:49:12 PM",
    "DiskInternals_Partition_Recovery_8.0.3.0.rar*62431914*7/4/2020 7:55:00 PM",
    62431914,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] dMCR17.1 [59,9 Mb]*0*9/13/2020 8:49:44 PM",
    "dMCR17.1.rar*62810794*7/4/2020 8:06:21 PM",
    62810794,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] EmEditor.19.9.3 [54,8 Mb]*0*9/13/2020 8:48:33 PM",
    "EmEditor.19.9.3.rar*57459697*6/26/2020 9:46:47 AM",
    57459697,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] EmEditor.19.9.4 [54,81 Mb]*0*9/13/2020 8:49:49 PM",
    "EmEditor.19.9.4.rar*57469626*7/4/2020 7:57:46 PM",
    57469626,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Flamman-Wpopr1911209431 [152,23 Mb]*0*9/13/2020 8:58:28 PM",
    "Flamman-Wpopr1911209431.rar*159624648*6/25/2020 2:44:59 PM",
    159624648,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Foxit.Quick.PDF.Library.v18.11.WinAll.Regged-FALLEN [176,3 Mb]*0*9/13/2020 8:58:26 PM",
    "Foxit.Quick.PDF.Library.v18.11.WinAll.Regged-FALLEN.rar*184867407*7/4/2020 7:57:06 PM",
    184867407,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] GoodSync.Enterprise.10.12.4.4 [46,97 Mb]*0*9/13/2020 8:58:28 PM",
    "GoodSync.Enterprise.10.12.4.4.rar*49247428*6/24/2020 8:58:22 AM",
    49247428,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Helium.Music.Manager.14.7.Build.16429.Premium [25,9 Mb]*0*9/13/2020 8:58:28 PM",
    "Helium.Music.Manager.14.7.Build.16429.Premium.rar*27155443*6/13/2020 8:55:35 PM",
    27155443,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Helium.Music.Manager.14.7.Build.16433.Premium [25,92 Mb]*0*9/13/2020 8:58:26 PM",
    "Helium.Music.Manager.14.7.Build.16433.Premium.rar*27177472*6/24/2020 8:59:56 AM",
    27177472,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] inPhoto_Capture_PS_4.18.19_Multilingual [50,11 Mb]*0*9/13/2020 8:58:26 PM",
    "inPhoto_Capture_PS_4.18.19_Multilingual.rar*52541445*7/18/2020 9:27:15 PM",
    52541445,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Internet.Download.Manager.6.37.16 [11,14 Mb]*0*9/13/2020 9:36:40 PM",
    "Internet.Download.Manager.6.37.16.rar*11679959*6/26/2020 9:47:11 AM",
    11679959,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Luminar_4.3.0.6175 [634,12 Mb]*0*9/13/2020 11:28:35 PM",
    "Luminar_4.3.0.6175.rar*664919870*7/18/2020 10:01:12 PM",
    664919870,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] MIRON v6.46. Para los Negocios que copian [15,18 Mb]*0*9/13/2020 9:37:01 PM",
    "MIRON v6.46. Para los Negocios que copian.zip*15918427*4/28/2020 12:01:52 PM",
    15918427,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] PC.Cleaner.Platinum.7.2.0.0 [7,09 Mb]*0*9/13/2020 9:36:20 PM",
    "PC.Cleaner.Platinum.7.2.0.0.rar*7434157*6/26/2020 9:45:19 AM",
    7434157,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Photo_Supreme_5 [107,45 Mb]*0*9/13/2020 9:36:39 PM",
    "Photo_Supreme_5.rar*112667318*7/18/2020 9:55:46 PM",
    112667318,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] PowerISO.7.7 [8,46 Mb]*0*9/13/2020 9:36:38 PM",
    "PowerISO.7.7.rar*8871468*6/24/2020 8:59:15 AM",
    8871468,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] RescuePRO.Deluxe.7.0.0.5 [14,04 Mb]*0*9/13/2020 11:17:35 PM",
    "RescuePRO.Deluxe.7.0.0.5.rar*14724950*7/4/2020 8:04:13 PM",
    14724950,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] RescuePRO.SSD.7.0.0.5 [7,93 Mb]*0*9/13/2020 9:37:02 PM",
    "RescuePRO.SSD.7.0.0.5.rar*8316908*7/4/2020 8:04:23 PM",
    8316908,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Titan.FTP.Server.Enterprise.2019.Build.3595 [283,66 Mb]*0*9/13/2020 9:36:33 PM",
    "Titan.FTP.Server.Enterprise.2019.Build.3595.rar*297441531*7/4/2020 8:09:27 PM",
    297441531,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] VisualCron_Pro_9.3.0_Build_24593 [265,58 Mb]*0*9/13/2020 9:36:12 PM",
    "VisualCron_Pro_9.3.0_Build_24593.rar*278480320*6/24/2020 8:56:43 AM",
    278480320,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Windows_Movie_Maker_2020_v8.0.7.5 [313,9 Mb]*0*9/13/2020 9:36:30 PM",
    "Windows_Movie_Maker_2020_v8.0.7.5.rar*329148396*6/13/2020 9:09:41 PM",
    329148396,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Wise Care 365 Pro Versión 5.5.5.550 Final Full Español [11,36 Mb]*0*9/13/2020 9:37:01 PM",
    "Wise Care 365 Pro Versión 5.5.5.550 Final Full Español.rar*11913174*7/4/2020 9:26:07 PM",
    11913174,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Aplicaciones PC\\[Aplicaciones PC] Wondershare.Filmora.9.5.1.5f [297,77 Mb]*0*9/13/2020 9:36:36 PM",
    "Wondershare.Filmora.9.5.1.5f.rar*312236495*7/4/2020 8:03:59 PM",
    312236495,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Deportes*0*9/13/2020 9:16:26 PM",
    0,
    "138*139*140*142*144*146*147*148"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Deportes\\BOXEO*0*9/13/2020 11:22:00 PM",
    "Brandon Perez Vs Ruben Orozco [05-09-20].mpg*210432000*9/10/2020 12:06:24 AM",
    "Yordenis Ugas vs. Abel Ramos &amp; Undercard 06.09.2020.mpg*1476481024*9/10/2020 5:18:55 PM",
    1686913024,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Deportes\\FORMULA 1*0*9/13/2020 11:31:16 PM",
    "Formula 1 2020 R08 Italia Fp2 [Audio Español] [07-09-20].mp4*831945703*9/4/2020 11:16:00 PM",
    "Formula 1 2020 R08 Italia Qf [Audio Español] [07-09-20].mp4*591730476*9/6/2020 10:02:55 PM",
    "Formula 1 2020 R08 Italy Fp1 [Audio Español] [07-09-20].mp4*767078925*9/4/2020 11:10:44 PM",
    "Formula 1 2020 R08 Italy Fp3 [Audio Español] [07-09-20].mp4*600185330*9/6/2020 9:59:19 PM",
    "Formula 1 Gran Premio Monza, Italia, Carrera [07-09-20].mpg*1040433152*9/9/2020 12:23:04 AM",
    "Formula 2 2020 - R08 - Italia Race One [Audio Español] [06-09-20].mp4*592378711*9/6/2020 10:06:43 PM",
    "Formula 2 2020 - R08 - Italy Qf [Audio Español] [06-09-20].mp4*316723468*9/6/2020 9:53:11 PM",
    "Formula 2 2020 R08 Italy Fp [07-09-2020].mp4*365112215*9/4/2020 11:25:35 PM",
    "Formula 3 2020 - R08 - Italia Carrera 2 Motor [Audio Español] [06-09-20].mp4*109281275*9/6/2020 10:10:48 PM",
    "Formula 3 2020 - R08 - Italy Race One [Audio Español] [06-09-20].mp4*501458460*9/6/2020 9:56:02 PM",
    "Formula 3 2020 R08 Italia Qf [Audio Español] [07-09-20].mp4*307660390*9/4/2020 11:12:12 PM",
    6023988105,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Deportes\\FUTBOL*0*9/13/2020 9:10:10 PM",
    0,
    "141"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Deportes\\FUTBOL\\[UEFA NATIONS LEAGUE]*0*9/13/2020 11:27:41 PM",
    "Belgica vs Islandia 1 [Esp] [10-09-20].ts*1397897876*9/10/2020 12:18:00 AM",
    "Belgica vs Islandia 2 [Esp] [10-09-20].ts*1251754196*9/10/2020 12:22:12 AM",
    "España Vs Ucrania 1 [06-09-20].mpg*548378624*9/10/2020 12:53:16 AM",
    "España Vs Ucrania 2 [06-09-20].mpg*547053568*9/10/2020 12:53:25 AM",
    "Sweden vs France 1 [Esp] [06-09-20].mpg*574648320*9/8/2020 8:18:46 PM",
    "Sweden vs France 2 [Esp] [06-09-20].mkv.mpg*547772416*9/8/2020 8:18:23 PM",
    "Switzerland vs Germany 1 [06-09-20].mpg*513476608*9/8/2020 7:59:24 PM",
    "Switzerland vs Germany 2 [06-09-20].mpg*525058048*9/8/2020 8:09:09 PM",
    5906039656,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Deportes\\MLB*0*9/13/2020 11:07:28 PM",
    "Boston Vs Atlanta. [03-09-20].mpg*1452591104*9/8/2020 8:24:29 PM",
    "Boston Vs Toronto [06-09-20].Mp4*1040165977*9/7/2020 12:14:07 AM",
    "Cleveland Minnesota [04-09-2020].mp4*822512101*8/26/2020 9:23:22 PM",
    "Detroit Vs Chi Cubs [04-09-2020].mp4*837847950*8/25/2020 8:03:01 PM",
    "Kansas City – Cleveland [02-09-20].mp4*841811028*9/2/2020 11:18:11 PM",
    "La Dodgers Vs Colorado [06-09-20].Mp4*1317967712*9/6/2020 11:59:08 PM",
    "Ny Yankees – Tampa Bay [02-09-20].mpg*1307338752*9/8/2020 8:23:24 PM",
    "San Francisco Vs La Dodgers [04-09-2020].mp4*607348853*8/28/2020 10:52:45 PM",
    8227583477,
    "143"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Deportes\\MLB\\MLB [HIGLIGTHTS]*0*9/13/2020 11:25:25 PM",
    "Austin Riley Max Fried Lead Braves To Win  Braves-Red Sox Game Highlights 83120.mp4*57714288*9/2/2020 12:37:03 AM",
    "Balanced Offense Leads Phillies To 8-6 Win  Nationals-Phillies Game Highlights 83120.mp4*113479060*9/2/2020 12:36:43 AM",
    "Bubba Starling Royals Rally By Indians  Indians-Royals Game Highlights 83120.mp4*108798859*9/2/2020 12:36:06 AM",
    "Chelcee Grimes Takes The Boston Attitude Test - Bases Covered Virtual Road Trip Ep 6.mp4*147308823*9/3/2020 9:45:10 PM",
    "Chris Taylor Leads The Dodgers To A 6-3 Win  D-Backs Vs Dodgers Highlights 9120.mp4*70705657*9/2/2020 10:58:33 PM",
    "Fernando Tatis Jr. Drives In Two In 6-0 Win  Padres-Rockies Game Highlights 83120.mp4*73712413*9/2/2020 12:34:32 AM",
    "Fernando Tatis Jr's Insane First Month Of The Season (Nl Player Of The Month).mp4*75384499*9/3/2020 9:45:44 PM",
    "Glasnow Choi Lead Rays Over Yankees  Rays-Yankees Game Highlights 83120.mp4*74928052*9/2/2020 12:34:16 AM",
    "Luis Robert Leads Comeback For White Sox  White Sox-Twins Game Highlights 83120.mp4*76728795*9/2/2020 12:33:52 AM",
    "Mlb Remembers Hall Of Fame Pitcher Tom Seaver.mp4*133889234*9/3/2020 9:46:07 PM",
    "Monster Home Runs Of August! The Longest Hrs From Mlb's Sluggers (Juan Soto, Bryce Harper And More!).mp4*86630853*9/3/2020 9:45:30 PM",
    "Mookie Betts Ties Game With Clutch 9th Inning Hr, And Dodgers Walk Off In Extras Vs. D-Backs!.mp4*135422269*9/3/2020 9:44:27 PM",
    "Nationals' Trea Turner Rips Off Crazy Fast Inside-The-Park Home Run Vs. Phillies!.mp4*43946952*9/3/2020 9:40:00 PM",
    "Orlando Arcia Lifts Brewers To 6-5 Win  Pirates-Brewers Game Highlights 83120.mp4*79902358*9/2/2020 12:33:36 AM",
    "Pete Alonso Caps Off Comeback Vs Yankees With Walk-Off Homer!.mp4*123888948*9/3/2020 9:46:03 PM",
    "Renato Núñez Anthony Santander Homer In Win  Mets-Orioles Game Highlights 9120.mp4*88269687*9/2/2020 10:58:07 PM",
    1490710747,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Deportes\\NBA*0*9/13/2020 9:31:39 PM",
    "Houston Rockets at Los Angeles Lakers .04.09.2020.720р.25fps.mpg*1053732864*9/10/2020 5:16:27 PM",
    "LA Clippers at Denver Nuggets_1q.mkv*844638126*9/10/2020 6:35:49 PM",
    "LA Clippers at Denver Nuggets_2q.mkv*1026667548*9/10/2020 6:44:09 PM",
    "LA Clippers at Denver Nuggets_3q.mkv*949003638*9/10/2020 6:51:52 PM",
    "LA Clippers at Denver Nuggets_4q.mkv*1307031718*9/10/2020 7:02:32 PM",
    "Toronto Raptors at Boston Celtics 09.09.20.mkv*5900152841*9/10/2020 8:49:48 PM",
    11081226735,
    "145"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Deportes\\NBA\\NBA [HIGLIGTHTS]*0*9/13/2020 10:29:00 PM",
    "Houston Vs Oklahoma. Highlights (Baloncesto. Nba) [03-09-20].mp4*198633976*9/3/2020 10:02:11 PM",
    "Toronto Vs Boston. Highlights (Baloncesto. Nba) [01-09-20].mp4*155287559*9/2/2020 12:39:13 AM",
    353921535,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Deportes\\NOTICIEROS*0*9/13/2020 10:38:51 PM",
    "CD, Contacto Deportivo [10-09-20].mpg*430587611*9/10/2020 4:05:54 PM",
    "CD. Contacto Deportivo [08-09-20].mpg*396578336*9/8/2020 3:16:52 PM",
    "CD. Contacto Deportivo [09-09-20].mpg*491819863*9/9/2020 3:33:46 PM",
    "Central FOX [08-09-20].mpg*199583744*9/10/2020 12:16:16 AM",
    "Central FOX [09-09-20].mpg*199704576*9/10/2020 4:57:20 PM",
    "Central Fox [11-09-20].mp4*5114085690*9/11/2020 10:15:51 AM",
    "Central FOX [11-09-20].mpg*123399296*9/11/2020 2:45:24 PM",
    "DEPORTES CUATRO - Lunes 7 de Septiembre 2020.mp4*105530640*9/7/2020 6:19:43 PM",
    "DEPORTES CUATRO Martes 8 de Septiembre 2020. MORATA quiere marchar del ATLETI a la JUVENTUS..mp4*106019187*9/8/2020 5:49:45 PM",
    "DEPORTES CUATRO Miércoles 09-09-2020. Morata por ¿Cavani o Raúl de Tomás- - Smiling MESSI sonríe..mp4*177105051*9/9/2020 5:28:29 PM",
    "DEPORTES CUATRO VUELVE MESSI Lunes 07 de Septiembre 2020 EL CHIRINGUITO DE JUGONES LA SEXTA JUGONES.mp4*222091113*9/6/2020 2:22:55 PM",
    "EL CHIRINGUITO DE JUGONES - Jueves 10 de Septiembre 2020 _ COMPLETO.mp4*1203791897*9/11/2020 12:24:19 AM",
    "EL CHIRINGUITO DE JUGONES - Lunes 7 de Septiembre 2020 _ COMPLETO - YouTube.mkv*812899312*9/7/2020 7:02:41 PM",
    "EL CHIRINGUITO DE JUGONES - Martes 8 de Septiembre 2020 _ COMPLETO.mp4*1400658665*9/8/2020 8:07:38 PM",
    "EL CHIRINGUITO DE JUGONES - Miercoles 9 de Septiembre 2020 _ COMPLETO.mp4*1172335943*9/9/2020 7:40:55 PM",
    "ESPN Knock out [08-09-20].mpg*546738176*9/10/2020 12:41:07 AM",
    "Fútbol Picante [09-09-20].mpg*530929664*9/10/2020 5:04:54 PM",
    "Futbol Picante [10-09-20].mp4*4780198095*9/10/2020 10:38:05 AM",
    "JUGONES LA SEXTA MARTES 8 DE SEPTIEMBRE 2020 COMPLETO - EL CHIRINGUITO DE JUGONES.mp4*96188258*9/8/2020 8:12:37 PM",
    "Jugones LaSexta - MIE9 SEP20 COMPLETO - EL CHIRINGUITO DE JUGONES.mp4*18713777*9/9/2020 7:43:09 PM",
    "Knock Out [1080p] [09-09-20].mp4*5178224958*9/9/2020 10:16:14 AM",
    "L4. Línea de Cuatro [08-09-20].mpg*370096009*9/8/2020 3:16:36 PM",
    "NBA Acción [07-09-20].mpg*242380800*9/8/2020 7:25:23 PM",
    "SC. SportsCenter [07-09-20].mpg*485771264*9/8/2020 7:22:55 PM",
    "SC. SportsCenter [08-09-20].mpg*465074176*9/10/2020 12:39:20 AM",
    "SC. SportsCenter [09-09-20].mpg*482332672*9/10/2020 5:05:24 PM",
    "UEFA Europa League, Magazine [07-09-20].mpg*599111680*9/8/2020 7:27:57 PM",
    "🔴⚪ DEPORTES 7 · Martes, 8 de Septiembre de 2020 ⚽🏀.mp4*260634001*9/8/2020 6:02:07 PM",
    26212584454,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Deportes\\TENIS*0*9/13/2020 8:33:16 PM",
    "2020 Us Open Round 02 Osaka Giorgi [07-09-20].mpg*720670720*9/8/2020 10:29:02 PM",
    720670720,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Deportes\\UFC*0*9/13/2020 8:37:49 PM",
    "UFC.Fight.Night.176.Prelims.WEB-DL.H264-FBB.mp4*892022798*9/6/2020 11:58:33 PM",
    "UFC.Fight.Night.176.WEB-DL.H264-FBB.mp4*2311967974*9/6/2020 9:15:53 PM",
    3203990772,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3*0*9/13/2020 9:13:39 PM",
    0,
    "150*154*158*165*171*178*182*189*193"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\[Por Genero] -[THE BEST MUSIC] vol.3*0*9/13/2020 9:53:10 PM",
    0,
    "151*152*153"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\[Por Genero] -[THE BEST MUSIC] vol.3\\Hit Parade 1984*0*9/13/2020 10:33:40 PM",
    "Track  1.mp3*4435753*9/9/2020 2:16:00 PM",
    "Track  2.mp3*5363528*9/9/2020 2:16:01 PM",
    "Track  3.mp3*5248364*9/9/2020 2:16:02 PM",
    "Track  4.mp3*6053844*9/9/2020 2:16:03 PM",
    "Track  5.mp3*5516048*9/9/2020 2:16:04 PM",
    "Track  6.mp3*5866820*9/9/2020 2:16:06 PM",
    "Track  7.mp3*4702345*9/9/2020 2:16:06 PM",
    "Track  8.mp3*5140717*9/9/2020 2:16:08 PM",
    "Track  9.mp3*3910111*9/9/2020 2:16:08 PM",
    "Track 10.mp3*4593856*9/9/2020 2:16:09 PM",
    "Track 11.mp3*3995037*9/9/2020 2:16:10 PM",
    "Track 12.mp3*4742457*9/9/2020 2:16:10 PM",
    59568880,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\[Por Genero] -[THE BEST MUSIC] vol.3\\Hit Parade 1985*0*9/13/2020 10:33:40 PM",
    "Track  1.mp3*5955905*9/9/2020 2:16:00 PM",
    "Track  2.mp3*5367147*9/9/2020 2:16:01 PM",
    "Track  3.mp3*5459012*9/9/2020 2:16:03 PM",
    "Track  4.mp3*6262249*9/9/2020 2:16:03 PM",
    "Track  5.mp3*5969550*9/9/2020 2:16:04 PM",
    "Track  6.mp3*6264496*9/9/2020 2:16:06 PM",
    "Track  7.mp3*7774784*9/9/2020 2:16:07 PM",
    "Track  8.mp3*5227353*9/9/2020 2:16:08 PM",
    "Track  9.mp3*5583506*9/9/2020 2:16:09 PM",
    "Track 10.mp3*4313742*9/9/2020 2:16:09 PM",
    "Track 11.mp3*6077431*9/9/2020 2:16:10 PM",
    "Track 12.mp3*5143808*9/9/2020 2:16:11 PM",
    69398983,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\[Por Genero] -[THE BEST MUSIC] vol.3\\Hit Parade 1986*0*9/13/2020 10:33:40 PM",
    "Track  1.mp3*4138848*9/9/2020 2:16:00 PM",
    "Track  2.mp3*6732307*9/9/2020 2:16:02 PM",
    "Track  3.mp3*4957338*9/9/2020 2:16:03 PM",
    "Track  4.mp3*5740421*9/9/2020 2:16:04 PM",
    "Track  5.mp3*5219492*9/9/2020 2:16:05 PM",
    "Track  6.mp3*5517807*9/9/2020 2:16:06 PM",
    "Track  7.mp3*5296254*9/9/2020 2:16:07 PM",
    "Track  8.mp3*5370371*9/9/2020 2:16:08 PM",
    "Track  9.mp3*4103228*9/9/2020 2:16:09 PM",
    "Track 10.mp3*8037461*9/9/2020 2:16:10 PM",
    "Track 11.mp3*3905564*9/9/2020 2:16:10 PM",
    "Track 12.mp3*6622229*9/9/2020 2:16:12 PM",
    65641320,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Bacilos*0*9/13/2020 8:33:34 PM",
    0,
    "155*156*157"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Bacilos\\Bacilos - Caraluna 2002*0*9/13/2020 8:37:37 PM",
    "01 - Mi primer millón..mp3*5789412*9/9/2020 2:15:09 PM",
    "02 - Caraluna..mp3*6496599*9/9/2020 2:15:11 PM",
    "03 - Solo con un segundo..mp3*5832671*9/9/2020 2:15:15 PM",
    "04 - Viejo..mp3*7478385*9/9/2020 2:15:19 PM",
    "05 - Odio el silencio..mp3*5481585*9/9/2020 2:15:24 PM",
    "06 - Nada Especial..mp3*5063417*9/9/2020 2:15:27 PM",
    "07 - Barcelona..mp3*6513526*9/9/2020 2:15:31 PM",
    "08 - El edificio..mp3*6084700*9/9/2020 2:15:33 PM",
    "09 - Nada..mp3*6879032*9/9/2020 2:15:37 PM",
    "10 - Elena..mp3*4913579*9/9/2020 2:15:39 PM",
    "11 - Buena..mp3*5517948*9/9/2020 2:15:43 PM",
    66050854,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Bacilos\\Bacilos - donde nos quedamos {2018} {pop} {HD Studios}*0*9/13/2020 8:38:29 PM",
    "01 perderme contigo {HD Studios}.mp3*9904010*9/9/2020 2:15:10 PM",
    "02 por hacerme el bueno {HD Studios}.mp3*10147471*9/9/2020 2:15:13 PM",
    "03 adicto a ti {HD Studios}.mp3*8847618*9/9/2020 2:15:15 PM",
    "04 negra negra negra {HD Studios}.mp3*9023161*9/9/2020 2:15:20 PM",
    "05 pequeñas mentiras {HD Studios}.mp3*9378426*9/9/2020 2:15:25 PM",
    "06 solo {HD Studios}.mp3*7863324*9/9/2020 2:15:29 PM",
    "07 la playa {HD Studios}.mp3*8637594*9/9/2020 2:15:32 PM",
    "08 vete pa' la m {HD Studios}.mp3*7299079*9/9/2020 2:15:35 PM",
    "09 la última bala {HD Studios}.mp3*8392043*9/9/2020 2:15:38 PM",
    "10 locos {HD Studios}.mp3*8674165*9/9/2020 2:15:41 PM",
    88166891,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Bacilos\\Bacilos - Sinverguenza 2004*0*9/13/2020 10:05:36 PM",
    "01-Sangre americana.mp3*4635636*9/9/2020 2:15:11 PM",
    "02-El algun recuerdo.mp3*4960077*9/9/2020 2:15:14 PM",
    "03-Pasos de gigante.mp3*4773040*9/9/2020 2:15:16 PM",
    "04-Porque brillamos.mp3*4633024*9/9/2020 2:15:21 PM",
    "05-La mexicana.mp3*4174313*9/9/2020 2:15:25 PM",
    "06-En los 70.mp3*4965301*9/9/2020 2:15:30 PM",
    "07-Miro la luna y pienso en ti.mp3*5246901*9/9/2020 2:15:33 PM",
    "08-Colores colores.mp3*5137187*9/9/2020 2:15:36 PM",
    "09-Guerras perdidas.mp3*5545219*9/9/2020 2:15:39 PM",
    "10-Feliz conmigo.mp3*3868681*9/9/2020 2:15:42 PM",
    "11-La olla.mp3*5144501*9/9/2020 2:15:45 PM",
    "12-Un regalo.mp3*2801840*9/9/2020 2:15:48 PM",
    55885720,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Faith Hill*0*9/13/2020 10:05:35 PM",
    0,
    "159*160*161*162*163*164"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Faith Hill\\Faith Hill*0*9/13/2020 10:05:37 PM",
    "Aretha Franklin - Ain't No Mountain High Enough....Mariah Carey, Diana Ross, Faith Hill, Donna Summer, Destiny's Child.mp3*4970767*9/9/2020 2:15:52 PM",
    "Country - Faith Hill - If My Heart Heart had Wings .mp3*4352587*9/9/2020 2:15:52 PM",
    "Country - Faith Hill - Take Another Little Piece Of My Heart.mp3*3918053*9/9/2020 2:15:52 PM",
    "Faith Hill - Amazing Grace.mp3*3973815*9/9/2020 2:15:52 PM",
    "Faith Hill - Breathe(1).mp3*4064494*9/9/2020 2:15:53 PM",
    "Faith Hill - Breathe.mp3*4064494*9/9/2020 2:15:52 PM",
    "Faith Hill - Cry.mp3*5431430*9/9/2020 2:15:53 PM",
    "Faith Hill - Daddy's Little Girl.mp3*3768492*9/9/2020 2:15:53 PM",
    "Faith Hill - It Matters To Me.mp3*3202244*9/9/2020 2:15:54 PM",
    "Faith Hill - Pearl Harbor Soundtrack - There You'll Be - Country.mp3*3604695*9/9/2020 2:15:54 PM",
    "Faith Hill - She's A Wild One.mp3*4051287*9/9/2020 2:15:54 PM",
    "Faith Hill - Take Me As I Am.mp3*3210603*9/9/2020 2:15:54 PM",
    "Faith Hill - The Secret Of Life.mp3*4162296*9/9/2020 2:15:54 PM",
    "Faith Hill - The Way You Love Me(1).mp3*3004132*9/9/2020 2:15:55 PM",
    "Faith Hill - The Way You Love Me.mp3*4530519*9/9/2020 2:15:55 PM",
    "Faith Hill - This Kiss.mp3*3217899*9/9/2020 2:15:55 PM",
    "Faith Hill &amp; Tim McGraw - Butterfly Kisses  Daddy's Little Girl!.mp3*5073428*9/9/2020 2:15:56 PM",
    "Faith Hill &amp; Tim McGraw - Just To Hear You Say That You Love Me - Country - 90's.mp3*4339511*9/9/2020 2:15:56 PM",
    "Faith Hill &amp; Tim Mcgraw--Lets Make Love.mp3*4076615*9/9/2020 2:15:56 PM",
    "Faith Hill &amp; Vince Gill - Let Me Let Go.mp3*5343029*9/9/2020 2:15:56 PM",
    "Puff Daddy &amp; Faith Hill &amp; 112 - I'll Be Missing You.mp3*5008664*9/9/2020 2:15:59 PM",
    "Tim Mcgraw &amp; Faith Hill - Just to See You Smile.mp3*3453925*9/9/2020 2:15:59 PM",
    "Tim Mcgraw and Faith Hill - It's Your Love(1).mp3*3650714*9/9/2020 2:16:00 PM",
    "Tim Mcgraw and Faith Hill - It's Your Love.mp3*3650714*9/9/2020 2:15:59 PM",
    98124407,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Faith Hill\\Faith Hill_2005, Fireflies*0*9/13/2020 11:34:01 PM",
    "01 - Faith Hill - Fireflies - Sunshine and Summertime.mp3*4924236*9/9/2020 2:15:08 PM",
    "02 - Faith Hill - Fireflies - Mississippi Girl.mp3*5536755*9/9/2020 2:15:12 PM",
    "03 - Faith Hill - Fireflies - Dearly Beloved.mp3*3427106*9/9/2020 2:15:14 PM",
    "04 - Faith Hill - Fireflies - I Ain't Gonna Take It Anymore.mp3*5732987*9/9/2020 2:15:17 PM",
    "05 - Faith Hill - Fireflies - Stealing Kisses.mp3*6306636*9/9/2020 2:15:23 PM",
    "06 - Faith Hill - Fireflies - Fireflies.mp3*6419485*9/9/2020 2:15:26 PM",
    "07 - Faith Hill - Fireflies - Like We Never Loved At All.mp3*6300367*9/9/2020 2:15:31 PM",
    "08 - Faith Hill - Fireflies - I Want You.mp3*5838940*9/9/2020 2:15:34 PM",
    "09 - Faith Hill - Fireflies - The Lucky One.mp3*5220151*9/9/2020 2:15:37 PM",
    "10 - Faith Hill - Fireflies - If You Ask.mp3*6135482*9/9/2020 2:15:39 PM",
    "11 - Faith Hill - Fireflies - We've Got Nothing but Love to Prove.mp3*6548634*9/9/2020 2:15:43 PM",
    "12 - Faith Hill - Fireflies - You Stay with Me.mp3*6311025*9/9/2020 2:15:45 PM",
    "13 - Faith Hill - Fireflies - Wish for You.mp3*4992572*9/9/2020 2:15:48 PM",
    "14 - Faith Hill - Fireflies - Paris.mp3*7695305*9/9/2020 2:15:50 PM",
    81389681,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Faith Hill\\Faith Hill 1*0*9/13/2020 11:24:55 PM",
    "01 - Track  1.mp3*6713937*9/9/2020 2:15:09 PM",
    "02 - Track  2.mp3*4282982*9/9/2020 2:15:12 PM",
    "03 - Track  3.mp3*4765203*9/9/2020 2:15:15 PM",
    "04 - Track  4.mp3*5017023*9/9/2020 2:15:18 PM",
    "05 - Track  5.mp3*5084941*9/9/2020 2:15:25 PM",
    "06 - Track  6.mp3*4577121*9/9/2020 2:15:28 PM",
    "07 - Track  7.mp3*3787178*9/9/2020 2:15:32 PM",
    "08 - Track  8.mp3*4894770*9/9/2020 2:15:34 PM",
    "09 - Track  9.mp3*4333660*9/9/2020 2:15:37 PM",
    "10 - Track 10.mp3*4360305*9/9/2020 2:15:40 PM",
    "11 - Track 11.mp3*5492452*9/9/2020 2:15:44 PM",
    "12 - Track 12.mp3*5133007*9/9/2020 2:15:46 PM",
    "13 - Track 13.mp3*5126737*9/9/2020 2:15:48 PM",
    "14 - Track 14.mp3*3961676*9/9/2020 2:15:50 PM",
    "15 - Track 15.mp3*4765725*9/9/2020 2:15:51 PM",
    72296717,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Faith Hill\\Faith Hill - Breathe 1999*0*9/13/2020 10:05:37 PM",
    "01.What´s in it for me.mp3*5355334*9/9/2020 2:15:11 PM",
    "02.I got my baby.mp3*3374075*9/9/2020 2:15:14 PM",
    "03.Love is a sweet thing.mp3*3910099*9/9/2020 2:15:16 PM",
    "04.Breathe.mp3*4146305*9/9/2020 2:15:20 PM",
    "05.Let´s make love ( with Tim McGraw ).mp3*3692899*9/9/2020 2:15:25 PM",
    "06.It will be me .mp3*3686166*9/9/2020 2:15:29 PM",
    "07.The way you love me.mp3*3218569*9/9/2020 2:15:33 PM",
    "08.If I´m not in love.mp3*3411497*9/9/2020 2:15:35 PM",
    "09.Bringing out the elvis.mp3*3238160*9/9/2020 2:15:38 PM",
    "10.If my heart had wings.mp3*3412587*9/9/2020 2:15:42 PM",
    "11.If I should fall behind.mp3*4584102*9/9/2020 2:15:44 PM",
    "12.That´s how love moves.mp3*3958150*9/9/2020 2:15:47 PM",
    "13.There will come a day.mp3*4086317*9/9/2020 2:15:49 PM",
    50074260,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Faith Hill\\Faith Hill - Cry 2002*0*9/13/2020 11:31:16 PM",
    "01.Free.mp3*6731074*9/9/2020 2:15:10 PM",
    "02.Cry.mp3*3862194*9/9/2020 2:15:13 PM",
    "03.One.mp3*7726652*9/9/2020 2:15:16 PM",
    "04.When The Lights Go Down.mp3*5928592*9/9/2020 2:15:21 PM",
    "06.Beautiful.mp3*6812576*9/9/2020 2:15:29 PM",
    "06.Unsaveable.mp3*5601330*9/9/2020 2:15:30 PM",
    "07.Baby You Belong.mp3*6011348*9/9/2020 2:15:33 PM",
    "08.if_youre_gonna_fly_away-rns.mp3*5526724*9/9/2020 2:15:36 PM",
    "09.StrongeR.mp3*6137363*9/9/2020 2:15:39 PM",
    "10.If This is the End.mp3*7137330*9/9/2020 2:15:42 PM",
    "11.This Is Me.mp3*7351116*9/9/2020 2:15:45 PM",
    "12.back_to_you.mp3*6669007*9/9/2020 2:15:47 PM",
    "13.i think i will.mp3*6007586*9/9/2020 2:15:49 PM",
    "14.Youre still Here.mp3*4835211*9/9/2020 2:15:51 PM",
    86338103,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Faith Hill\\Faith Hill - Faith*0*9/13/2020 10:34:04 PM",
    "01 This Kiss.mp3*4724243*9/9/2020 2:15:10 PM",
    "02 You Give Me Love.mp3*5200089*9/9/2020 2:15:13 PM",
    "03 Let Me Let Go.mp3*6400677*9/9/2020 2:15:16 PM",
    "04 Love Ain't Like That.mp3*5560579*9/9/2020 2:15:19 PM",
    "05 Better Days.mp3*5034577*9/9/2020 2:15:25 PM",
    "06 My Wild Frontier.mp3*7181216*9/9/2020 2:15:28 PM",
    "07 The Secret of Life.mp3*6167456*9/9/2020 2:15:33 PM",
    "08 Just to Hear You Say That You Love Me.mp3*6487821*9/9/2020 2:15:35 PM",
    "09 Me.mp3*5550548*9/9/2020 2:15:38 PM",
    "10 I Love You.mp3*7374940*9/9/2020 2:15:41 PM",
    "11 The Hard Way.mp3*5560579*9/9/2020 2:15:44 PM",
    "12 Somebody Stand by Me.mp3*8490264*9/9/2020 2:15:47 PM",
    73732989,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Angeles Negros*0*9/13/2020 9:13:29 PM",
    0,
    "166*167*168*169*170"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Angeles Negros\\Los Angeles Negros - 12 Super exitos 1996*0*9/13/2020 8:24:26 PM",
    "01.mp3*2714095*9/9/2020 2:28:30 PM",
    "02.mp3*2723290*9/9/2020 2:28:30 PM",
    "03.mp3*2916805*9/9/2020 2:28:31 PM",
    "04.mp3*3485648*9/9/2020 2:28:32 PM",
    "05.mp3*3348139*9/9/2020 2:28:33 PM",
    "06.mp3*3980929*9/9/2020 2:28:33 PM",
    "07. Y volvere.mp3*3907786*9/9/2020 2:28:36 PM",
    "08.mp3*2717857*9/9/2020 2:28:36 PM",
    "09.mp3*2711169*9/9/2020 2:28:37 PM",
    "10.mp3*3051807*9/9/2020 2:28:38 PM",
    "11.mp3*3325988*9/9/2020 2:28:39 PM",
    "12.mp3*3320137*9/9/2020 2:28:40 PM",
    "titulos.txt*241*1/21/2000 2:09:08 PM",
    38203891,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Angeles Negros\\Los Angeles Negros - 20 Exitos Originales 1991*0*9/13/2020 11:09:33 PM",
    "01 Y volveré.mp3*3863456*9/9/2020 2:28:30 PM",
    "02 Déjenme si estoy llorando.mp3*2689408*9/9/2020 2:28:31 PM",
    "03 Pasión y vida.mp3*2815214*9/9/2020 2:28:31 PM",
    "04 Debut y despedida.mp3*2802675*9/9/2020 2:28:32 PM",
    "05 Mi niña.mp3*3008311*9/9/2020 2:28:33 PM",
    "06 Murió la flor.mp3*3296703*9/9/2020 2:28:34 PM",
    "07 Vete en silencio.mp3*3220216*9/9/2020 2:28:35 PM",
    "08 A ti.mp3*3634832*9/9/2020 2:28:36 PM",
    "09 Como quisiera decirte.mp3*2676870*9/9/2020 2:28:37 PM",
    "10 Amar y vivir.mp3*3462215*9/9/2020 2:28:38 PM",
    "11 Despacito.mp3*3438809*9/9/2020 2:28:40 PM",
    "12 Serenata sin luna.mp3*3381131*9/9/2020 2:28:41 PM",
    "13 Volverás.mp3*3381549*9/9/2020 2:28:42 PM",
    "14 Esta noche la paso contigo.mp3*2869967*9/9/2020 2:28:43 PM",
    "15 Si las flores pudieran hablar.mp3*3929493*9/9/2020 2:28:43 PM",
    "16 Abrazados.mp3*3324706*9/9/2020 2:28:44 PM",
    "17 Tu enamorado.mp3*2713232*9/9/2020 2:28:45 PM",
    "18 Ayer preguntaron por ti.mp3*3771923*9/9/2020 2:28:45 PM",
    "19 La mujer que tanto amé.mp3*3220634*9/9/2020 2:28:46 PM",
    "20 Cómo es posible.mp3*3125340*9/9/2020 2:28:47 PM",
    "Los AngelesNegros20GrandesExitos.jpg*674964*6/18/1999 1:14:50 AM",
    "Los AngelesNegros20GrandesExitosback.jpg*558072*6/18/1999 1:13:18 AM",
    65859720,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Angeles Negros\\Los Angeles Negros - 21 Black Jack 1996*0*9/13/2020 11:23:46 PM",
    "01 - Déjeme si estoy llorando.mp3*3566619*9/9/2020 2:28:30 PM",
    "02 - Pasión y vida.mp3*3779848*9/9/2020 2:28:31 PM",
    "03 - Murió la flor.mp3*4352297*9/9/2020 2:28:31 PM",
    "04 - Como quisiera decirte.mp3*3779005*9/9/2020 2:28:32 PM",
    "05 - Despacio.mp3*4707563*9/9/2020 2:28:33 PM",
    "06 - Si las flores pudieran hablar.mp3*5434746*9/9/2020 2:28:34 PM",
    "07 - Ayer preguntaron por ti.mp3*4891654*9/9/2020 2:28:34 PM",
    "08 - Tu enamorado.mp3*3854399*9/9/2020 2:28:36 PM",
    "09 - Tres palabras.mp3*3617083*9/9/2020 2:28:37 PM",
    "10 - Esta noche la paso contigo.mp3*3889398*9/9/2020 2:28:38 PM",
    "11 - Serenata sin luna.mp3*4522237*9/9/2020 2:28:40 PM",
    "12 - Debut y despedida.mp3*3598283*9/9/2020 2:28:40 PM",
    "13 - Mi niña.mp3*4146932*9/9/2020 2:28:41 PM",
    "14 - Y volveré.mp3*5590728*9/9/2020 2:28:43 PM",
    "15 - Vete en silencio.mp3*4294252*9/9/2020 2:28:43 PM",
    "16 - Amar y vivir.mp3*4678687*9/9/2020 2:28:44 PM",
    "17 - Volverás.mp3*4700003*9/9/2020 2:28:44 PM",
    "18 - Abrazados.mp3*4683787*9/9/2020 2:28:45 PM",
    "19 - Como es posible.mp3*4197262*9/9/2020 2:28:45 PM",
    "20 - Será varón, será mujer.mp3*3779804*9/9/2020 2:28:47 PM",
    "21 - A ti.mp3*4921567*9/9/2020 2:28:47 PM",
    "los angeles negros 19 black jack.jpg*371172*8/22/2003 10:18:32 AM",
    91357326,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Angeles Negros\\Los Angeles Negros - Memorias Del Alma(2016)(POP)*0*9/13/2020 11:25:48 PM",
    "01 Porque Yo Te Amo (Abdel La Esencia Y Estudios Odisea).mp3*8804777*9/9/2020 2:28:30 PM",
    "02 La Más Bella Herejía (Abdel La Esencia Y Estudios Odisea).mp3*9033610*9/9/2020 2:28:31 PM",
    "03 Todo Pasará (Abdel La Esencia Y Estudios Odisea).mp3*8019014*9/9/2020 2:28:32 PM",
    "04 Por Volverte A Ver (Abdel La Esencia Y Estudios Odisea).mp3*10056565*9/9/2020 2:28:32 PM",
    "05 Algo De Mí (Abdel La Esencia Y Estudios Odisea).mp3*9918639*9/9/2020 2:28:33 PM",
    "06 El Aire Que Respiro (Abdel La Esencia Y Estudios Odisea).mp3*9028385*9/9/2020 2:28:34 PM",
    "07 Mi Diabla (Abdel La Esencia Y Estudios Odisea).mp3*7383716*9/9/2020 2:28:35 PM",
    "08 Cómo Le Digo Que No La Quiero (Abdel La Esencia Y Estudios Odisea).mp3*9115112*9/9/2020 2:28:36 PM",
    "09 Espérame (Abdel La Esencia Y Estudios Odisea).mp3*8481904*9/9/2020 2:28:37 PM",
    "10 Señora, Engáñelo (Abdel La Esencia Y Estudios Odisea).mp3*7770328*9/9/2020 2:28:39 PM",
    "11 Tus Ojos Castaños (Abdel La Esencia Y Estudios Odisea).mp3*10679324*9/9/2020 2:28:40 PM",
    "12 Sin Tí, Vivir Ya No Es Igual (Abdel La Esencia Y Estudios Odisea).mp3*7461039*9/9/2020 2:28:41 PM",
    "Los Angeles Negros - Memorias Del Alma (Album) (2016)(Abdel La Esencia Y Estudios Odisea).jpg*58727*10/4/2016 7:56:08 PM",
    105811140,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Angeles Negros\\Los Angeles Negros- 20 Boleros*0*9/13/2020 9:13:31 PM",
    "01 - Que seas feliz.mp3*2358542*9/9/2020 2:28:30 PM",
    "02 - Delirio.mp3*3144462*9/9/2020 2:28:31 PM",
    "03 - Tu me acostumbrast.mp3*2193604*9/9/2020 2:28:31 PM",
    "04 - La barca.mp3*3448370*9/9/2020 2:28:32 PM",
    "05 - Voy a pagar la luz.mp3*2518724*9/9/2020 2:28:33 PM",
    "06 - Jurame.mp3*2854084*9/9/2020 2:28:34 PM",
    "07 - Contigo.mp3*2337330*9/9/2020 2:28:34 PM",
    "08 - Amor mio.mp3*2626976*9/9/2020 2:28:36 PM",
    "09 - Usted.mp3*2398770*9/9/2020 2:28:37 PM",
    "10 - El reloj.mp3*2149719*9/9/2020 2:28:38 PM",
    "11 - La gloria eres tu.mp3*3097650*9/9/2020 2:28:39 PM",
    "12 - Historia de un amor.mp3*3298062*9/9/2020 2:28:40 PM",
    "13 - Y hablame.mp3*3422770*9/9/2020 2:28:42 PM",
    "14 - Contigo en la distancia.mp3*3337193*9/9/2020 2:28:42 PM",
    "15 - Un minuto de amor.mp3*2504462*9/9/2020 2:28:43 PM",
    "16 - Corazon, corazon.mp3*2386702*9/9/2020 2:28:44 PM",
    "17 - Ansiedad.mp3*2252119*9/9/2020 2:28:44 PM",
    "18 - Obseción.mp3*2327090*9/9/2020 2:28:45 PM",
    "19 - Sigamos pecando.mp3*2886999*9/9/2020 2:28:46 PM",
    "20 - Dios no lo quiera.mp3*2852987*9/9/2020 2:28:46 PM",
    54396615,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Brincos*0*9/13/2020 9:40:30 PM",
    0,
    "172*173*174*175*176*177"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Brincos\\Los Brincos - 30 Exitos De Oro vol.1*0*9/13/2020 11:34:15 PM",
    "01.mp3*2709888*9/9/2020 2:28:30 PM",
    "02.mp3*1982639*9/9/2020 2:28:31 PM",
    "03.mp3*2350443*9/9/2020 2:28:31 PM",
    "04.mp3*2459113*9/9/2020 2:28:32 PM",
    "05.mp3*2498401*9/9/2020 2:28:33 PM",
    "06.mp3*2402688*9/9/2020 2:28:33 PM",
    "07.mp3*2798078*9/9/2020 2:28:34 PM",
    "08.mp3*2750012*9/9/2020 2:28:36 PM",
    "09.mp3*2080024*9/9/2020 2:28:37 PM",
    "10.mp3*2081278*9/9/2020 2:28:38 PM",
    "11.mp3*2464546*9/9/2020 2:28:39 PM",
    "12.mp3*1711384*9/9/2020 2:28:40 PM",
    "13.mp3*2696514*9/9/2020 2:28:41 PM",
    "14.mp3*2384716*9/9/2020 2:28:42 PM",
    "15.mp3*3084380*9/9/2020 2:28:43 PM",
    "los brincos - 30 exitos.jpg*547746*3/5/2002 12:46:50 PM",
    "Los Brincos 30 Exitos front.jpg*210202*12/14/1998 1:35:44 PM",
    37212052,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Brincos\\Los Brincos - 30 Exitos De Oro vol.2*0*9/13/2020 11:20:22 PM",
    "01.mp3*1961741*9/9/2020 2:28:30 PM",
    "02.mp3*2299034*9/9/2020 2:28:31 PM",
    "03.mp3*2120148*9/9/2020 2:28:31 PM",
    "04.mp3*3511116*9/9/2020 2:28:32 PM",
    "05.mp3*2847397*9/9/2020 2:28:33 PM",
    "06.mp3*2018584*9/9/2020 2:28:33 PM",
    "07.mp3*2230907*9/9/2020 2:28:34 PM",
    "08.mp3*2700275*9/9/2020 2:28:36 PM",
    "09.mp3*1892360*9/9/2020 2:28:37 PM",
    "10.mp3*1817127*9/9/2020 2:28:38 PM",
    "11.mp3*2735802*9/9/2020 2:28:39 PM",
    "12.mp3*2629640*9/9/2020 2:28:40 PM",
    "13.mp3*2950215*9/9/2020 2:28:41 PM",
    "14.mp3*2333725*9/9/2020 2:28:42 PM",
    "15.mp3*2554407*9/9/2020 2:28:43 PM",
    "los brincos - 30 exitos.jpg*547746*3/5/2002 12:46:50 PM",
    "Los Brincos 30 Exitos front.jpg*210202*12/14/1998 1:35:44 PM",
    37360426,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Brincos\\Los Brincos - Antología De Exitos*0*9/13/2020 8:24:26 PM",
    "01 Los Brincos - Mejor.mp3*2739563*9/9/2020 2:28:30 PM",
    "02 Los Brincos - Si Quieres Saber Quien Soy.mp3*1995178*9/9/2020 2:28:31 PM",
    "03 Los Brincos - Renacerá.mp3*2364236*9/9/2020 2:28:31 PM",
    "04 Los Brincos - Al Despertar.mp3*3524491*9/9/2020 2:28:32 PM",
    "05 Los Brincos - Nadie Te Quiere Ya.mp3*2315335*9/9/2020 2:28:33 PM",
    "06 Los Brincos - Amiga Mía.mp3*2415645*9/9/2020 2:28:34 PM",
    "07 Los Brincos - Tú Me Dijistes Adiós.mp3*2815632*9/9/2020 2:28:35 PM",
    "08 Los Brincos - Un Sorbito De Champagna.mp3*2768821*9/9/2020 2:28:36 PM",
    "09 Los Brincos - Sola Nunca Más.mp3*2097160*9/9/2020 2:28:37 PM",
    "10 Los Brincos - Oh Mamá.mp3*2736220*9/9/2020 2:28:38 PM",
    "11 Los Brincos - Serás Para Mi.mp3*2712396*9/9/2020 2:28:40 PM",
    "12 Los Brincos - Vive La Realidad.mp3*3075603*9/9/2020 2:28:41 PM",
    "13 Los Brincos - Tú En Mi.mp3*1826741*9/9/2020 2:28:42 PM",
    33387021,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Brincos\\Los Brincos - Contrabando 1968*0*9/13/2020 9:40:30 PM",
    "- Informacion.txt*548*11/9/2001 10:04:38 AM",
    "01-Lola.mp3*2486698*9/9/2020 2:28:30 PM",
    "02-Pequeño Pajaro.mp3*2754192*9/9/2020 2:28:31 PM",
    "03-El Pasaporte.mp3*2563603*9/9/2020 2:28:32 PM",
    "04-Rapidamente.mp3*2019838*9/9/2020 2:28:33 PM",
    "05-The Train.mp3*2478757*9/9/2020 2:28:33 PM",
    "06-Un Mundo Diferente.mp3*2370505*9/9/2020 2:28:34 PM",
    "07-Nadie Te Quiere Ya.mp3*2306140*9/9/2020 2:28:36 PM",
    "08-Contrabando.mp3*2172811*9/9/2020 2:28:37 PM",
    "09-Big Temptation.mp3*2686065*9/9/2020 2:28:38 PM",
    "10-Soy Como Quiero.mp3*2327456*9/9/2020 2:28:39 PM",
    "11-So Good To Dance.mp3*2991175*9/9/2020 2:28:40 PM",
    "12-Nunca Cambiaras.mp3*2408122*9/9/2020 2:28:41 PM",
    "Ananai.mp3*2862861*9/9/2020 2:28:47 PM",
    "Apolo.mp3*2863697*9/9/2020 2:28:47 PM",
    "Disco.m3u*1011*11/9/2001 9:59:28 AM",
    "El Domingo.mp3*2435289*9/9/2020 2:28:47 PM",
    "Gracias Por Tu Amor.mp3*2459113*9/9/2020 2:28:47 PM",
    "La Fuente.mp3*3313839*9/9/2020 2:28:47 PM",
    "Las Alegres Chicas De San Diego.mp3*3254907*9/9/2020 2:28:48 PM",
    "Los Brincos - Contrabando 1968.jpg*384453*9/29/2000 4:08:36 PM",
    "Mas Alegre.mp3*2872892*9/9/2020 2:28:48 PM",
    "Sol En Julio.mp3*2392239*9/9/2020 2:28:48 PM",
    "Story Of Glory.mp3*3656984*9/9/2020 2:28:48 PM",
    "You Know.mp3*2253059*9/9/2020 2:28:50 PM",
    58316254,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Brincos\\Los Brincos - Exitos (1993)*0*9/13/2020 9:40:40 PM",
    "01.Tu me dijiste adios.mp3*3474287*9/9/2020 2:28:30 PM",
    "02.Un sorbito de champagne.mp3*2951527*9/9/2020 2:28:31 PM",
    "03.Mejor.mp3*3372922*9/9/2020 2:28:32 PM",
    "04.Renacera.mp3*2546271*9/9/2020 2:28:33 PM",
    "05.Amiga mia.mp3*2643629*9/9/2020 2:28:33 PM",
    "06.Nadie te quiere ya.mp3*2211412*9/9/2020 2:28:34 PM",
    "07.Tu en mi.mp3*2340415*9/9/2020 2:28:36 PM",
    "08.El pasa porte.mp3*2676236*9/9/2020 2:28:37 PM",
    "09.Lola.mp3*1861611*9/9/2020 2:28:38 PM",
    "10.Borracho.mp3*2837128*9/9/2020 2:28:39 PM",
    "11.A mi con esas.mp3*2620329*9/9/2020 2:28:40 PM",
    "12.Flamenco.mp3*2651460*9/9/2020 2:28:41 PM",
    "Los Brincos (Exitos) 1993 back.JPG*111940*11/7/2002 7:13:36 AM",
    "Los Brincos (Exitos) 1993 front.JPG*73721*11/7/2002 7:12:36 AM",
    32372888,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Brincos\\Los Brincos - Serie de Platino 20 exitos (1997)*0*9/13/2020 8:58:41 PM",
    "01.Flamenco.mp3*2806707*9/9/2020 2:28:30 PM",
    "02.Escomo un sueño.mp3*4726051*9/9/2020 2:28:31 PM",
    "03.Baila la pulga.mp3*1423836*9/9/2020 2:28:32 PM",
    "04.No soy malo.mp3*1710489*9/9/2020 2:28:32 PM",
    "05.Sola.mp3*2599591*9/9/2020 2:28:33 PM",
    "06.Tu me digista adios.mp3*3556026*9/9/2020 2:28:34 PM",
    "07.Borracho.mp3*2799881*9/9/2020 2:28:36 PM",
    "08.Mejor.mp3*3491625*9/9/2020 2:28:37 PM",
    "09.Un sorvito de champan.mp3*3193752*9/9/2020 2:28:38 PM",
    "10.Giulietta.mp3*2479132*9/9/2020 2:28:39 PM",
    "11.A mi con esas.mp3*1776126*9/9/2020 2:28:40 PM",
    "12.Lola.mp3*2110468*9/9/2020 2:28:41 PM",
    "13.El pasaporte.mp3*2720012*9/9/2020 2:28:42 PM",
    "14.Nadie tequiere ya.mp3*2647098*9/9/2020 2:28:43 PM",
    "15.Erase unavez.mp3*3166845*9/9/2020 2:28:44 PM",
    "16.The train.mp3*1849122*9/9/2020 2:28:44 PM",
    "17.Ho mama.mp3*2024193*9/9/2020 2:28:45 PM",
    "18.Vive la realidad.mp3*3755614*9/9/2020 2:28:45 PM",
    "19.Hermano Ismel.mp3*4023728*9/9/2020 2:28:46 PM",
    "20.Esa mujer.mp3*5525529*9/9/2020 2:28:47 PM",
    "Los Brincos (Serie de Platino 20 exitos) 1997 back.JPG*92122*11/7/2002 7:34:30 AM",
    "Los Brincos (Serie de Platino 20 exitos) 1997 front.JPG*48806*11/7/2002 7:34:00 AM",
    58526753,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Bryndys*0*9/13/2020 10:16:54 PM",
    0,
    "179*180*181"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Bryndys\\Los Brindis (Brindis y sus poemas I) '92*0*9/13/2020 8:58:41 PM",
    "01 Sin Razón.mp3*3929829*9/9/2020 2:28:30 PM",
    "01 Te juro que te amo (¿).mp3*5240343*9/9/2020 2:28:30 PM",
    "02 Serás mía para siempre (¿).mp3*5495975*9/9/2020 2:28:31 PM",
    "02 Tonto Corazón.mp3*4011903*9/9/2020 2:28:31 PM",
    "03 Caprichos de Mujer.mp3*3759959*9/9/2020 2:28:31 PM",
    "03 Te he prometido.mp3*4238121*9/9/2020 2:28:32 PM",
    "04 Enojados.mp3*3002896*9/9/2020 2:28:32 PM",
    "04 Todo acabó (¿).mp3*4525615*9/9/2020 2:28:32 PM",
    "05 Busca un nuevo amor (¿).mp3*4960896*9/9/2020 2:28:33 PM",
    "06 Salada ilusión (¿).mp3*5300496*9/9/2020 2:28:34 PM",
    "06 Todavía Te Amo.mp3*4235319*9/9/2020 2:28:34 PM",
    "07 Una Chica Esta Llorando.mp3*3355956*9/9/2020 2:28:35 PM",
    "07 Volveré (¿).mp3*4860858*9/9/2020 2:28:36 PM",
    "08 Otro ocupa mi lugar (¿).mp3*4580211*9/9/2020 2:28:37 PM",
    "09 Borracho de Tristeza.mp3*4862322*9/9/2020 2:28:37 PM",
    "09 Vete ya (¿).mp3*4733043*9/9/2020 2:28:38 PM",
    "10 Te esperaré (¿).mp3*4764632*9/9/2020 2:28:39 PM",
    "11 Siempre Te Recordaré.mp3*3958240*9/9/2020 2:28:40 PM",
    "12 Voy A Pintar Un Corazón.mp3*3950111*9/9/2020 2:28:41 PM",
    83766725,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Bryndys\\Los Bryndis - Poemas*0*9/13/2020 11:31:04 PM",
    "01 Lo nuestro termino.mp3*9281252*9/9/2020 2:28:30 PM",
    "02 Regresa pronto.mp3*7167423*9/9/2020 2:28:31 PM",
    "03 Que mas te da.mp3*7280272*9/9/2020 2:28:32 PM",
    "04 Mentiras.mp3*9021072*9/9/2020 2:28:32 PM",
    "05 Estar enamorado.mp3*6952174*9/9/2020 2:28:33 PM",
    "06 Entre tu y yo.mp3*8961513*9/9/2020 2:28:34 PM",
    "07 cuando volveras.mp3*6769317*9/9/2020 2:28:35 PM",
    "08 Un triste corazon.mp3*8429660*9/9/2020 2:28:37 PM",
    "09 Valor de amor.mp3*7263554*9/9/2020 2:28:38 PM",
    "10 Hoy.mp3*8073350*9/9/2020 2:28:38 PM",
    "11 Te juro que te amo.mp3*8771341*9/9/2020 2:28:40 PM",
    "12 Te vas con el.mp3*9378427*9/9/2020 2:28:41 PM",
    "13 Te he prometido.mp3*6914558*9/9/2020 2:28:42 PM",
    "14 Todo acabo.mp3*7330427*9/9/2020 2:28:43 PM",
    "16 Cuando llega un adios.mp3*8998084*9/9/2020 2:28:44 PM",
    "17 Lo prometo.mp3*8435929*9/9/2020 2:28:45 PM",
    "18 Otra ocupa tu lugar.mp3*7715994*9/9/2020 2:28:45 PM",
    "19 Te esperare.mp3*7278182*9/9/2020 2:28:46 PM",
    "20 Vete ya .mp3*7636582*9/9/2020 2:28:47 PM",
    151659111,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Bryndys\\Los Bryndys - Nuestros Mejores Poemas*0*9/13/2020 11:34:09 PM",
    "01. track1  Lo nuestro termino.mp3*3642646*9/9/2020 2:28:30 PM",
    "02. track2  Regresa pornto.mp3*2797115*9/9/2020 2:28:31 PM",
    "03. track3  Que mas te da.mp3*2842672*9/9/2020 2:28:32 PM",
    "04. track4  Mentiras.mp3*3538992*9/9/2020 2:28:32 PM",
    "05. track5  Estar enamorado.mp3*2711015*9/9/2020 2:28:33 PM",
    "06. track6  Entre tu y yo.mp3*3514751*9/9/2020 2:28:34 PM",
    "07. track7  Cuando volveras.mp3*2637872*9/9/2020 2:28:36 PM",
    "08. track8  Un triste corazon.mp3*3302427*9/9/2020 2:28:37 PM",
    "09. track9  Valor de amor.mp3*2835985*9/9/2020 2:28:38 PM",
    "10. track10 Hoy.mp3*3159485*9/9/2020 2:28:39 PM",
    "11. track11 Te juro que te amo.mp3*3439223*9/9/2020 2:28:40 PM",
    "12. track12 Te vas con el.mp3*3681516*9/9/2020 2:28:41 PM",
    "13. track13 Te he prometido.mp3*2695969*9/9/2020 2:28:42 PM",
    "14. track14 Todo acabo.mp3*2862734*9/9/2020 2:28:43 PM",
    "15. track15 Lo nuestro termino.mp3*3335028*9/9/2020 2:28:44 PM",
    "16. track16 Cuando llega un adios.mp3*3529797*9/9/2020 2:28:44 PM",
    "17. track17 Lo Prometo.mp3*3304517*9/9/2020 2:28:45 PM",
    "18. track18 Otra ocupa tu lugar.mp3*3016543*9/9/2020 2:28:45 PM",
    "18. track18 Otro ocupa mi lugar.mp3*3016543*9/9/2020 2:28:45 PM",
    "19. track19 Vete ya.mp3*2862734*9/9/2020 2:28:46 PM",
    "20. track20 Te esperare.mp3*3083119*9/9/2020 2:28:47 PM",
    65810683,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Mitos*0*9/13/2020 9:30:48 PM",
    0,
    "183*184*185*186*187*188"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Mitos\\Los Mitos -  Grandes  éxitos*0*9/13/2020 11:31:54 PM",
    "01 - Al caminar.mp3*3080200*9/9/2020 2:28:30 PM",
    "02 - Ayudame.mp3*3752279*9/9/2020 2:28:31 PM",
    "03 - Canta cariño.mp3*3279149*9/9/2020 2:28:31 PM",
    "04 - Cantemos así.mp3*2502581*9/9/2020 2:28:32 PM",
    "05 - Ciudad India.mp3*2966097*9/9/2020 2:28:33 PM",
    "06 - Coge mi mano.mp3*2489624*9/9/2020 2:28:34 PM",
    "07 - Cuando vuelvas.mp3*3066407*9/9/2020 2:28:34 PM",
    "08 - Cherie cha la la.mp3*3381549*9/9/2020 2:28:36 PM",
    "09 - Eleonor.mp3*2345010*9/9/2020 2:28:37 PM",
    "10 - Es muy fácil.mp3*3069751*9/9/2020 2:28:38 PM",
    "11 - Este es mi llanto.mp3*3019178*9/9/2020 2:28:39 PM",
    "12 - Hola.mp3*2643851*9/9/2020 2:28:40 PM",
    "13 - La vida es una canción.mp3*2994936*9/9/2020 2:28:41 PM",
    "14 - Lejos de ti.mp3*3150835*9/9/2020 2:28:43 PM",
    "15 - Luces.mp3*3241532*9/9/2020 2:28:43 PM",
    "16 - Me conformo.mp3*3314675*9/9/2020 2:28:44 PM",
    "17 - Me estas volviendo loco.mp3*2836112*9/9/2020 2:28:44 PM",
    "18 - Mirame.mp3*3000788*9/9/2020 2:28:45 PM",
    "19 - Mony, mony.mp3*2624207*9/9/2020 2:28:45 PM",
    "20 - No merezco tu amor.mp3*4036073*9/9/2020 2:28:46 PM",
    "22 - Pussycat.mp3*2764641*9/9/2020 2:28:47 PM",
    "23 - Si te acuerdas de mi.mp3*3550822*9/9/2020 2:28:47 PM",
    "Los Mitos Back.jpg*90388*5/3/2012 6:56:47 PM",
    "Los Mitos Grandes Exitos 3-5-12.jpg*1517241*5/3/2012 6:31:08 PM",
    "Nuevo documento de texto.txt*421*5/3/2012 6:50:14 PM",
    68718347,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Mitos\\Los Mitos - Es muy facil 1996*0*9/13/2020 11:17:24 PM",
    "Los Mitos  Es muy facil.JPG*227096*1/17/2001 3:37:12 PM",
    "Track  1.mp3*3088977*9/9/2020 2:28:48 PM",
    "Track  2.mp3*3028791*9/9/2020 2:28:48 PM",
    "Track  3.mp3*2366326*9/9/2020 2:28:48 PM",
    "Track  4.mp3*3093575*9/9/2020 2:28:48 PM",
    "Track  5.mp3*3032971*9/9/2020 2:28:48 PM",
    "Track  6.mp3*2518045*9/9/2020 2:28:48 PM",
    "Track  7.mp3*2581157*9/9/2020 2:28:48 PM",
    "Track  8.mp3*3267446*9/9/2020 2:28:49 PM",
    "Track  9.mp3*2990339*9/9/2020 2:28:49 PM",
    "Track 10.mp3*3593872*9/9/2020 2:28:49 PM",
    "Track 11.mp3*2629222*9/9/2020 2:28:49 PM",
    "Track 12.mp3*2503834*9/9/2020 2:28:50 PM",
    34921651,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Mitos\\Los Mitos - Todas sus Grabaciones 1968-1975 (cd1) (1998) hq*0*9/13/2020 11:28:32 PM",
    "01 - Cuándo vuelvas.mp3*4578166*9/9/2020 2:28:30 PM",
    "02 - Coge mi mano.mp3*3712991*9/9/2020 2:28:31 PM",
    "03 - Cantemos así (Aleluya).mp3*3733053*9/9/2020 2:28:31 PM",
    "04 - Este es mi llanto.mp3*4507322*9/9/2020 2:28:32 PM",
    "05 - Es muy fácil.mp3*4583182*9/9/2020 2:28:33 PM",
    "06 - Si te acuerdas de mi.mp3*5353063*9/9/2020 2:28:34 PM",
    "07 - Me conformo.mp3*4950568*9/9/2020 2:28:35 PM",
    "08 - Todos lo saben.mp3*4514219*9/9/2020 2:28:36 PM",
    "09 - Suena una guitarra.mp3*5411995*9/9/2020 2:28:37 PM",
    "10 - No sé por qué.mp3*4102320*9/9/2020 2:28:38 PM",
    "11 - Me estás volviendo loco.mp3*4232723*9/9/2020 2:28:39 PM",
    "12 - Lejos de tí.mp3*4704808*9/9/2020 2:28:40 PM",
    "13 - Mírame.mp3*4479737*9/9/2020 2:28:42 PM",
    "14 - Vive la vida.mp3*4215169*9/9/2020 2:28:43 PM",
    63079316,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Mitos\\Los Mitos - Todas sus Grabaciones 1968-1975 (cd2) (1998) hq*0*9/13/2020 8:54:45 PM",
    "01 - Ayúdame.mp3*5606973*9/9/2020 2:28:30 PM",
    "02 - Hola, cómo estás.mp3*3945585*9/9/2020 2:28:31 PM",
    "03 - La vida es una canción.mp3*4471587*9/9/2020 2:28:31 PM",
    "04 - Canta, cariño, canta.mp3*4897278*9/9/2020 2:28:32 PM",
    "05 - Cherie, sha la la.mp3*5050878*9/9/2020 2:28:33 PM",
    "06 - Al caminar.mp3*4598855*9/9/2020 2:28:33 PM",
    "07 - No merezco tu amor.mp3*6032664*9/9/2020 2:28:35 PM",
    "08 - Pussycat, c' est la vie.mp3*4125517*9/9/2020 2:28:36 PM",
    "09 - Tiempo de amar.mp3*5167489*9/9/2020 2:28:37 PM",
    43896826,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Mitos\\Los Mitos - Todas sus Grabaciones 1968-75 vol.1*0*9/13/2020 8:54:45 PM",
    "Los Mitos - Todas sus Grabaciones 1968-75 f.jpg*856070*6/1/1999 1:44:16 PM",
    "Los Mitos - Todas sus Grabaciones 1968-75.jpg*603073*6/1/1999 1:46:26 PM",
    "Track  1.mp3*3692389*9/9/2020 2:28:48 PM",
    "Track  2.mp3*2926442*9/9/2020 2:28:48 PM",
    "Track  3.mp3*3083968*9/9/2020 2:28:48 PM",
    "Track  4.mp3*3673238*9/9/2020 2:28:48 PM",
    "Track  5.mp3*3759045*9/9/2020 2:28:48 PM",
    "Track  6.mp3*4280342*9/9/2020 2:28:48 PM",
    "Track  7.mp3*4172916*9/9/2020 2:28:49 PM",
    "Track  8.mp3*4024110*9/9/2020 2:28:49 PM",
    "Track  9.mp3*4032180*9/9/2020 2:28:49 PM",
    "Track 10.mp3*3431721*9/9/2020 2:28:49 PM",
    "Track 11.mp3*3322737*9/9/2020 2:28:50 PM",
    "Track 12.mp3*3618111*9/9/2020 2:28:50 PM",
    "Track 13.mp3*3817306*9/9/2020 2:28:50 PM",
    "Track 14.mp3*3307061*9/9/2020 2:28:50 PM",
    52600709,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Los Mitos\\Los Mitos - Todas sus Grabaciones 1968-75 vol.2*0*9/13/2020 10:33:45 PM",
    "Track  1.mp3*4858059*9/9/2020 2:28:48 PM",
    "Track  2.mp3*3504450*9/9/2020 2:28:48 PM",
    "Track  3.mp3*3607027*9/9/2020 2:28:48 PM",
    "Track  4.mp3*3495030*9/9/2020 2:28:48 PM",
    "Track  5.mp3*3356428*9/9/2020 2:28:48 PM",
    "Track  6.mp3*3473024*9/9/2020 2:28:48 PM",
    "Track  7.mp3*4440180*9/9/2020 2:28:49 PM",
    "Track  8.mp3*2757265*9/9/2020 2:28:49 PM",
    "Track  9.mp3*4451195*9/9/2020 2:28:49 PM",
    "Track 10.mp3*2941997*9/9/2020 2:28:49 PM",
    "Track 11.mp3*3429234*9/9/2020 2:28:50 PM",
    "Track 12.mp3*3970588*9/9/2020 2:28:50 PM",
    "Track 13.mp3*3999563*9/9/2020 2:28:50 PM",
    "Track 14.mp3*3292004*9/9/2020 2:28:50 PM",
    51576044,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Sex Pistols*0*9/13/2020 9:32:23 PM",
    0,
    "190*191*192"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Sex Pistols\\Sex Pistols - Filthy Lucre*0*9/13/2020 11:24:29 PM",
    "01 - Bodies.mp3*3474754*9/9/2020 2:15:08 PM",
    "02 - Seventeen.mp3*2469383*9/9/2020 2:15:12 PM",
    "03 - New York.mp3*3343398*9/9/2020 2:15:14 PM",
    "04 - No Feelings.mp3*2914738*9/9/2020 2:15:17 PM",
    "05 - Did You No Wrong.mp3*3572763*9/9/2020 2:15:22 PM",
    "06 - God Save The Queen.mp3*3299206*9/9/2020 2:15:27 PM",
    "07 - Liar.mp3*2694983*9/9/2020 2:15:32 PM",
    "08 - Satellite.mp3*3989343*9/9/2020 2:15:34 PM",
    "09 - (I`m Not Your) Steppin` Stone.mp3*2812149*9/9/2020 2:15:36 PM",
    "10 - Holidays In The Sun.mp3*3392615*9/9/2020 2:15:39 PM",
    "11 - Submission.mp3*4550623*9/9/2020 2:15:44 PM",
    "12 - Pretty Vacant.mp3*3455173*9/9/2020 2:15:46 PM",
    "13 - Emi.mp3*4131952*9/9/2020 2:15:48 PM",
    "14 - Problems.mp3*4435534*9/9/2020 2:15:50 PM",
    "15 - Problems (Spedding Demo).mp3*3494793*9/9/2020 2:15:51 PM",
    "16 - Buddies.mp3*3390946*9/9/2020 2:15:51 PM",
    "17 - No Fun.mp3*6890410*9/9/2020 2:15:51 PM",
    62312763,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Sex Pistols\\Sex Pistols - Flogging A Dead Horse*0*9/13/2020 11:25:37 PM",
    "01 - Anarchy In The Uk.mp3*3420150*9/9/2020 2:15:07 PM",
    "02 - I Wanna Be Me.mp3*2979377*9/9/2020 2:15:12 PM",
    "03 - God Save The Queen.mp3*3212067*9/9/2020 2:15:14 PM",
    "04 - Do You No Wrong.mp3*3087794*9/9/2020 2:15:17 PM",
    "05 - Pretty Vacant.mp3*3162445*9/9/2020 2:15:24 PM",
    "06 - No Fun.mp3*6165232*9/9/2020 2:15:28 PM",
    "07 - Holidays In The Sun.mp3*3239575*9/9/2020 2:15:31 PM",
    "08 - No One Is Innocent.mp3*2912236*9/9/2020 2:15:34 PM",
    "09 - My Way.mp3*3918877*9/9/2020 2:15:37 PM",
    "10 - Something Else.mp3*2105345*9/9/2020 2:15:40 PM",
    "11 - Silly Thing.mp3*2785477*9/9/2020 2:15:43 PM",
    "12 - C'mon Everybody.mp3*1878083*9/9/2020 2:15:45 PM",
    "13 - (I'm Not Your) Stepping Stone.mp3*3023162*9/9/2020 2:15:48 PM",
    "14 - Great Rock 'n' Roll Swindle.mp3*4235792*9/9/2020 2:15:50 PM",
    46125612,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Sex Pistols\\Sex Pistols-Never Mind The Bollocks*0*9/13/2020 11:25:37 PM",
    "01 - Holidays in the Sun.mp3*5476672*9/9/2020 2:15:08 PM",
    "02 - Bodies.mp3*5221464*9/9/2020 2:15:11 PM",
    "03 - No Feelings.mp3*5263481*9/9/2020 2:15:14 PM",
    "04 - Liar.mp3*4826501*9/9/2020 2:15:17 PM",
    "05 - Problems.mp3*7645381*9/9/2020 2:15:24 PM",
    "06 - God Save the Queen.mp3*5988780*9/9/2020 2:15:27 PM",
    "07 - Seventeen.mp3*3379077*9/9/2020 2:15:32 PM",
    "08 - Anarchy in the U.K..mp3*6110685*9/9/2020 2:15:33 PM",
    "09 - Sub-Mission.mp3*6812058*9/9/2020 2:15:37 PM",
    "10 - Pretty Vacant.mp3*5601765*9/9/2020 2:15:40 PM",
    "11 - New York.mp3*5068175*9/9/2020 2:15:43 PM",
    "12 - EMI.mp3*5800230*9/9/2020 2:15:45 PM",
    67194269,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Teresita Fernandez*0*9/13/2020 9:13:38 PM",
    0,
    "194*195"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Teresita Fernandez\\Teresita Fernandez -  Mi Gatico Vinagrito*0*9/13/2020 9:32:38 PM",
    "Mi Gatico Vinagrito - 01.mp3*3990262*9/9/2020 2:15:56 PM",
    "Mi Gatico Vinagrito - 02.mp3*2145331*9/9/2020 2:15:56 PM",
    "Mi Gatico Vinagrito - 03.mp3*3462222*9/9/2020 2:15:57 PM",
    "Mi Gatico Vinagrito - 04.mp3*2191394*9/9/2020 2:15:57 PM",
    "Mi Gatico Vinagrito - 05.mp3*2115802*9/9/2020 2:15:57 PM",
    "Mi Gatico Vinagrito - 06.mp3*5166617*9/9/2020 2:15:57 PM",
    "Mi Gatico Vinagrito - 07.mp3*3788231*9/9/2020 2:15:58 PM",
    "Mi Gatico Vinagrito - 08.mp3*3135953*9/9/2020 2:15:58 PM",
    "Mi Gatico Vinagrito - 09.mp3*3542969*9/9/2020 2:15:58 PM",
    "Mi Gatico Vinagrito - 10.mp3*4625479*9/9/2020 2:15:58 PM",
    "Mi Gatico Vinagrito - 11.mp3*1608908*9/9/2020 2:15:58 PM",
    "Mi Gatico Vinagrito - 12.mp3*2809016*9/9/2020 2:15:59 PM",
    "Mi Gatico Vinagrito - 13.mp3*3285013*9/9/2020 2:15:59 PM",
    "Mi Gatico Vinagrito - 14.mp3*5049056*9/9/2020 2:15:59 PM",
    46916253,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Discografias en MP3\\Teresita Fernandez\\Teresita Fernandez - Vamos todos a cantar*0*9/13/2020 9:13:39 PM",
    "Track  1.mp3*3067799*9/9/2020 2:16:01 PM",
    "Track  2.mp3*2146653*9/9/2020 2:16:02 PM",
    "Track  3.mp3*2853256*9/9/2020 2:16:03 PM",
    "Track  4.mp3*2418606*9/9/2020 2:16:04 PM",
    "Track  5.mp3*2300152*9/9/2020 2:16:05 PM",
    "Track  6.mp3*3750746*9/9/2020 2:16:06 PM",
    "Track  7.mp3*3599246*9/9/2020 2:16:07 PM",
    "Track  8.mp3*3006820*9/9/2020 2:16:08 PM",
    "Track  9.mp3*4012401*9/9/2020 2:16:09 PM",
    "Track 10.mp3*3075394*9/9/2020 2:16:10 PM",
    "Track 11.mp3*3135289*9/9/2020 2:16:10 PM",
    33366362,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Documentales*0*9/13/2020 8:55:20 PM",
    0,
    "197"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Documentales\\Documentales Variados*0*9/13/2020 11:34:04 PM",
    "A prueba de todo [07-09-20].mpg*213977088*9/9/2020 3:35:34 PM",
    "A prueba de todo [08-09-20].mpg*357672960*9/9/2020 3:39:40 PM",
    "A puertas abiertas [10-09-20].mpg*840105984*9/9/2020 1:15:07 AM",
    "Alaska [07-09-20].mpg*354805760*9/9/2020 3:39:44 PM",
    "Alaska hombres del Artyico [09-09-20].mpg*424243200*9/10/2020 3:25:11 PM",
    "Alaska hombres del Artyico2 [09-09-20].mpg*425842688*9/10/2020 3:27:38 PM",
    "Alaska hombres del Artyico3 [09-09-20].mpg*430651392*9/10/2020 3:28:50 PM",
    "Alaska hombres primitivos2 [10-09-20].mpg*1252966400*9/9/2020 2:48:13 AM",
    "Alaska la ultima frontera [08-09-20].mpg*354113536*9/9/2020 3:41:54 PM",
    "Alaska la ultima fronteras [07-09-20].mpg*303769600*9/8/2020 4:56:08 PM",
    "Alaska la ultima fronteras2 [07-09-20].mpg*304482304*9/8/2020 4:58:05 PM",
    "Alaska remota [09-09-20].mpg*434149376*9/10/2020 3:31:06 PM",
    "Alerta Aereopuerto 5 [09-09-20].mpg*385521664*9/10/2020 3:34:26 PM",
    "Alerta Aereopuerto 7 [09-09-20].mpg*420960256*9/10/2020 3:38:43 PM",
    "Alerta Aereopuerto 9 [09-09-20].mpg*425570304*9/10/2020 3:41:13 PM",
    "Alerta Aereopuerto Bogotá [07-09-20].mpg*312934400*9/9/2020 3:40:37 PM",
    "Alerta Aereopuerto Lima [07-09-20].mpg*317267968*9/9/2020 3:42:00 PM",
    "Alerta Aereopuerto Madrid 1 [07-09-20].mpg*349368320*9/9/2020 3:43:47 PM",
    "Alerta Aereopuerto Madrid 2 [07-09-20].mpg*301711360*9/9/2020 3:42:52 PM",
    "Alerta Aereopuerto Roma - ESTRENO [07-09-20].mpg*334630912*9/9/2020 3:47:15 PM",
    "Alerta aeropuerto [08-09-20].mpg*365750272*9/9/2020 3:50:15 PM",
    "Alienigenas ancestrales [07-09-20].mpg*377538560*9/9/2020 3:50:21 PM",
    "Alienigenas ancestrales [08-09-20].mpg*352430080*9/9/2020 3:50:39 PM",
    "Alienigenas ancestrales 2 [08-09-20].mpg*385748992*9/9/2020 3:49:16 PM",
    "Alienigenas ancestrales 3 [08-09-20].mpg*284129280*9/9/2020 3:50:45 PM",
    "Amistades insolitas [07-09-20].mpg*296431616*9/8/2020 4:59:54 PM",
    "Amistades insolitas [08-09-20].mpg*296368128*9/9/2020 3:53:08 PM",
    "Animales en riesgo [08-09-20].mpg*358408192*9/9/2020 3:55:38 PM",
    "Asesinatos en familia 1 [07-09-20].mpg*304078848*9/9/2020 3:57:22 PM",
    "Asesinatos en familia 2 [07-09-20].mpg*342007808*9/9/2020 3:58:21 PM",
    "Autos unicos [07-09-20].mpg*327206912*9/8/2020 4:58:47 PM",
    "caceria [08-09-20].mpg*346621952*9/9/2020 3:59:47 PM",
    "Camara testigo [09-09-20].mpg*225650688*9/10/2020 3:39:49 PM",
    "Carreras prohibidas 1 [07-09-20].mpg*334454784*9/9/2020 3:58:32 PM",
    "Carreras prohibidas 2 [07-09-20].mpg*345714688*9/9/2020 3:58:50 PM",
    "Carreras Prohividas [08-09-20].mpg*184240128*9/9/2020 3:57:25 PM",
    "Casos infames 1 [09-09-20].mpg*445601792*9/10/2020 3:44:35 PM",
    "Casos infames 2 [09-09-20].mpg*392802304*9/10/2020 3:47:12 PM",
    "Cazadores de Tesoros [07-09-20].mpg*340563968*9/9/2020 4:04:29 PM",
    "Chimpance [07-09-20].mpg*149606400*9/8/2020 4:54:05 PM",
    "Ciencia de lo Absurdo 1 [09-09-20].mpg*216340480*9/10/2020 3:42:48 PM",
    "Ciencia de lo Absurdo 2 [09-09-20].mpg*219424768*9/10/2020 3:43:18 PM",
    "Con toque Brasileño [07-09-20].mpg*140607488*9/8/2020 4:54:50 PM",
    "Control de carreteras [07-09-20].mpg*315725824*9/9/2020 4:04:41 PM",
    "coyote peterson [07-09-20].mpg*160010240*9/8/2020 4:55:08 PM",
    "Crudo y sin censura [08-09-20].mpg*366897152*9/9/2020 4:07:21 PM",
    "Crudo y sin censura [09-09-20].mpg*120969216*9/10/2020 3:45:17 PM",
    "Crudo y sin sensura [07-09-20].mpg*383168512*9/9/2020 4:07:21 PM",
    "Cuando el mal se disfrasa [08-09-20].mpg*354553856*9/9/2020 4:07:01 PM",
    "Decada remix [08-09-20].mpg*349859840*9/9/2020 4:07:00 PM",
    "Dentro de la fabrica [09-09-20].mpg*197685248*9/10/2020 3:47:10 PM",
    "Desafio sobe fuego [07-09-20].mpg*355870720*9/9/2020 4:08:06 PM",
    "Desafio sobre fuego [08-09-20].mpg*361906176*9/9/2020 4:13:16 PM",
    "Desafio x 2 [08-09-20].mpg*406335488*9/9/2020 4:16:06 PM",
    "Desafio x 2 2 [08-09-20].mpg*357509120*9/9/2020 4:13:22 PM",
    "Desafio X2 [07-09-20].mpg*357339136*9/9/2020 4:16:57 PM",
    "Desaparecidos [07-09-20].mpg*351795200*9/9/2020 4:15:39 PM",
    "Desaparecidos [08-09-20].mpg*300541952*9/9/2020 4:16:44 PM",
    "Desaparecidos [09-09-20].mpg*359380992*9/10/2020 3:53:36 PM",
    "Desaparecidos2 [08-09-20].mpg*301717504*9/9/2020 4:15:56 PM",
    "Destruido en segundos [07-09-20].mpg*156323840*9/9/2020 4:11:52 PM",
    "Destruidos en segundos [08-09-20].mpg*150208512*9/9/2020 4:15:55 PM",
    "Discovery desafio X2 [07-09-20].mpg*412350464*9/9/2020 4:23:26 PM",
    "Dr Jeff [07-09-20].mpg*325539840*9/8/2020 5:01:17 PM",
    "Dr Jeff [08-09-20].mpg*323047424*9/9/2020 4:22:36 PM",
    "Dr Jeff2 [07-09-20].mpg*324143104*9/8/2020 5:02:15 PM",
    "Dr Sandra Lee [09-09-20].mpg*432726016*9/10/2020 3:55:05 PM",
    "Dr Sandra Lee2 [09-09-20].mpg*432795648*9/10/2020 3:57:08 PM",
    "El ABC del cerebro [08-09-20].mpg*205744128*9/9/2020 4:20:41 PM",
    "El camino de los espiritus [08-09-20].mpg*354932736*9/9/2020 4:24:50 PM",
    "El cañon del diablo [07-09-20].mpg*354156544*9/9/2020 4:25:47 PM",
    "El cañon Del Diablo [08-09-20].mpg*349464576*9/9/2020 4:25:44 PM",
    "El inceible Pol [08-09-20].mpg*305811456*9/9/2020 9:10:50 PM",
    "el increible Pol [07-09-20].mpg*313460736*9/8/2020 5:04:54 PM",
    "El increible Pol [10-09-20].mpg*1302450176*9/9/2020 1:25:38 AM",
    "El interrogador [07-09-20].mpg*369752064*9/9/2020 9:13:07 PM",
    "El lado oscuro de la ciencia [08-09-20].mpg*353437696*9/9/2020 9:14:20 PM",
    "El mejor amigo del hombre [07-09-20].mpg*173862912*9/9/2020 9:11:03 PM",
    "El mejor amigo del Hombre [08-09-20].mpg*247238656*9/9/2020 9:13:50 PM",
    "El precio de al historia [08-09-20].mpg*139274240*9/9/2020 9:12:41 PM",
    "El precio de al historia2 [08-09-20].mpg*134699008*9/9/2020 9:12:30 PM",
    "El precio de La Historia [08-09-20].mpg*348952576*9/9/2020 9:19:48 PM",
    "El precio de la historia 1 [07-09-20].mpg*159469568*9/9/2020 9:14:04 PM",
    "El precio de la historia 2 [07-09-20].mpg*175095808*9/9/2020 9:14:43 PM",
    "El rastro [07-09-20].mpg*385239040*9/9/2020 9:20:40 PM",
    "El ultimo de su especie [08-09-20].mpg*298373120*9/9/2020 9:21:34 PM",
    "El ultimo de su especie2 [08-09-20].mpg*318224384*9/9/2020 9:22:58 PM",
    "El zoologico de Bronx [08-09-20].mpg*328617984*9/9/2020 9:21:52 PM",
    "El zoologico de San diego [08-09-20].mpg*326682624*9/9/2020 9:22:08 PM",
    "Emergencia sexuales [07-09-20].mpg*303187968*9/8/2020 5:06:18 PM",
    "Emergencia sexuales [09-09-20].mpg*431108096*9/10/2020 4:02:05 PM",
    "Encierro paranormal [08-09-20].mpg*357412864*9/9/2020 9:23:10 PM",
    "Encierro paranormal 2 [08-09-20].mpg*325316608*9/9/2020 9:23:19 PM",
    "Encuentros paranormales [08-09-20].mpg*357402624*9/9/2020 9:28:06 PM",
    "escuela para perros [10-09-20].mpg*1131784192*9/9/2020 1:18:40 AM",
    "Evan a lo Salvaje [08-09-20].mpg*374276096*9/9/2020 9:30:31 PM",
    "Expedicion a lo desconocido [07-09-20].mpg*295524352*9/8/2020 5:03:37 PM",
    "Expedicion a lo desconocido2 [07-09-20].mpg*257093632*9/8/2020 5:04:44 PM",
    "Expedición Everest [09-09-20].mpg*322072576*9/10/2020 3:56:27 PM",
    "Expediente Paranormal [08-09-20].mpg*294195200*9/9/2020 9:29:27 PM",
    "Expediente Paranormal2 [08-09-20].mpg*294739968*9/9/2020 9:29:42 PM",
    "Expedientes paranormales [08-09-20].mpg*352681984*9/9/2020 9:31:53 PM",
    "FBI en el Siglo XXI [07-09-20].mpg*356272128*9/9/2020 9:31:01 PM",
    "Fiebre de oro [08-09-20].mpg*340412416*9/9/2020 9:30:53 PM",
    "Fiebre del oro [07-09-20].mpg*349585408*9/9/2020 9:31:17 PM",
    "Fiebre del Oro [09-09-20].mpg*417497088*9/10/2020 4:00:08 PM",
    "Fuga casi perfecta [07-09-20].mpg*412954624*9/9/2020 9:37:33 PM",
    "Fuga casi perfecta [08-09-20].mpg*409540608*9/9/2020 9:38:50 PM",
    "Fuga casi perfecta 1 [09-09-20].mpg*301332480*9/10/2020 4:00:45 PM",
    "GAraje Goblin [07-09-20].mpg*327280640*9/8/2020 5:07:19 PM",
    "Gatitos en problemas [07-09-20].mpg*306853888*9/8/2020 5:10:01 PM",
    "Gatitos en Problemas [08-09-20].mpg*313790464*9/9/2020 9:39:39 PM",
    "Gatitos en proplemas [07-09-20].mpg*314566656*9/8/2020 5:12:14 PM",
    "Grandes Felinos [08-09-20].mpg*301920256*9/9/2020 9:41:03 PM",
    "Hermanos a la obra [07-09-20].mpg*302145536*9/8/2020 5:12:15 PM",
    "Hombres del Artico [09-09-20].mpg*430972928*9/10/2020 4:10:24 PM",
    "Iditarod [09-09-20].mpg*436779008*9/10/2020 4:09:57 PM",
    "Indice de maldad [07-09-20].mpg*355897344*9/9/2020 9:40:34 PM",
    "Inexplicable 1 [07-09-20].mpg*387129344*9/9/2020 9:40:06 PM",
    "Inexplicable 1 [09-09-20].mpg*287764480*9/10/2020 4:07:35 PM",
    "Inexplicable 2 [07-09-20].mpg*339814400*9/9/2020 9:39:04 PM",
    "Inexplicable 2 [09-09-20].mpg*447068160*9/10/2020 4:11:08 PM",
    "Instinto asesino [07-09-20].mpg*303654912*9/9/2020 9:38:52 PM",
    "Joyas sobre ruedas [07-09-20].mpg*302555136*9/8/2020 5:12:39 PM",
    "Joyas sobre ruedas_New1 [10-09-20].mpg*715153408*9/10/2020 2:11:43 AM",
    "Joyas sobre ruedas2 [07-09-20].mpg*304060416*9/8/2020 5:12:56 PM",
    "Juegos mentales [08-09-20].mpg*175765504*9/9/2020 9:41:44 PM",
    "Kilos Mortales 1 [07-09-20].mpg*244117504*9/9/2020 9:45:32 PM",
    "Kilos Mortales 2 [07-09-20].mpg*337651712*9/9/2020 9:46:52 PM",
    "La bahia de Bristol [07-09-20].mpg*294952960*9/8/2020 5:14:48 PM",
    "LA casa de mis sueños [10-09-20].mpg*651102208*9/10/2020 11:49:57 AM",
    "LA casa de mis sueños2 [10-09-20].mpg*619585536*9/10/2020 11:50:52 AM",
    "La ciencia de lo absurdo [08-09-20].mpg*166342656*9/9/2020 9:43:33 PM",
    "LA ciencia de lo absurdo [10-09-20].mpg*685705216*9/10/2020 2:02:58 AM",
    "La mafia por dentro [07-09-20].mpg*405917696*9/9/2020 9:50:02 PM",
    "La peor pesadilla [09-09-20].mpg*374226944*9/10/2020 4:18:51 PM",
    "La voz del mal [08-09-20].mpg*356356096*9/9/2020 9:49:18 PM",
    "Llamada de emergencia [07-09-20].mpg*179412992*9/9/2020 9:45:35 PM",
    "Locuras animal [10-09-20].mpg*514037760*9/9/2020 1:17:23 AM",
    "Locuras animales [07-09-20].mpg*278149120*9/8/2020 5:15:53 PM",
    "Los cazaminihogares [07-09-20].mpg*140210176*9/8/2020 5:12:08 PM",
    "Los cazaminihogares2 [07-09-20].mpg*145954816*9/8/2020 5:14:56 PM",
    "Los irwin [09-09-20].mpg*428789760*9/10/2020 4:19:43 PM",
    "Los mas Peligrosos [08-09-20].mpg*296312832*9/9/2020 9:52:37 PM",
    "Los mas raros [07-09-20].mpg*313585664*9/8/2020 5:22:29 PM",
    "Los mas raros [08-09-20].mpg*311951360*9/9/2020 9:55:27 PM",
    "Los mas raros2 [08-09-20].mpg*313354240*9/9/2020 9:58:06 PM",
    "Los msas raros [10-09-20].mpg*1114064896*9/9/2020 1:23:10 AM",
    "LOs tiburones mas temibles [08-09-20].mpg*311771136*9/9/2020 9:56:27 PM",
    "Madrastas malignas [09-09-20].mpg*334491648*9/10/2020 4:19:00 PM",
    "Madres imposibles [08-09-20].mpg*180273152*9/9/2020 9:51:33 PM",
    "MAnsiones en los arboles [09-09-20].mpg*432535552*9/10/2020 4:24:54 PM",
    "Mas alla de la duda [07-09-20].mpg*341389312*9/9/2020 9:58:24 PM",
    "Mascotas SOS [08-09-20].mpg*347969536*9/9/2020 10:00:44 PM",
    "Maxima seguridad [07-09-20].mpg*391940096*9/9/2020 10:01:43 PM",
    "Mayday Informe especial [07-09-20].mpg*333330432*9/9/2020 10:01:12 PM",
    "Mega Aereopuerto Dubai 1 [07-09-20].mpg*396650496*9/9/2020 10:06:08 PM",
    "Mega Aereopuerto Dubai 2 [07-09-20].mpg*355375104*9/9/2020 10:05:59 PM",
    "Metropolis Salvaje [08-09-20].mpg*433420288*9/9/2020 10:08:42 PM",
    "Mexicanico [08-09-20].mpg*301545472*9/9/2020 10:07:29 PM",
    "Mexicanicos [07-09-20].mpg*302966784*9/8/2020 5:21:30 PM",
    "Mi Belleza [09-09-20].mpg*432523264*9/10/2020 4:30:58 PM",
    "Mi historia de supervivencia [07-09-20].mpg*354867200*9/9/2020 10:08:03 PM",
    "Momias al descubierto [07-09-20].mpg*303095808*9/8/2020 5:20:18 PM",
    "Monstruos de la antiguedad [09-09-20].mpg*446814208*9/10/2020 4:29:13 PM",
    "No sabia que estaba embarazada [09-09-20].mpg*226547712*9/10/2020 4:31:21 PM",
    "Nuestro Hogar en Alaska [08-09-20].mpg*304357376*9/9/2020 10:09:34 PM",
    "Nuestro Hogar en Alaska 2 [08-09-20].mpg*317351936*9/9/2020 10:09:50 PM",
    "Nuestro hogar en la Bahamas [07-09-20].mpg*137805824*9/8/2020 5:17:18 PM",
    "Nuestro hogar en la Bahamas2 [07-09-20].mpg*149209088*9/8/2020 5:18:04 PM",
    "Operación frontera [09-09-20].mpg*267773952*9/10/2020 4:33:51 PM",
    "Operacion Vuelo [07-09-20].mpg*302196736*9/8/2020 5:22:40 PM",
    "Operacion Vuelo [09-09-20].mpg*424237056*9/10/2020 4:36:19 PM",
    "Operacion Vuelo2 [07-09-20].mpg*302163968*9/8/2020 5:24:50 PM",
    "OperacionFrontera [08-09-20].mpg*161339392*9/9/2020 10:06:33 PM",
    "OperacionFrontera2 [08-09-20].mpg*162764800*9/9/2020 10:11:09 PM",
    "Paranormal [08-09-20].mpg*235538432*9/9/2020 10:12:32 PM",
    "Peligro en el Aire [07-09-20].mpg*292311040*9/8/2020 5:26:30 PM",
    "Peligro en el Aire [09-09-20].mpg*433223680*9/10/2020 4:40:27 PM",
    "Planeta azul II [08-09-20].mpg*173197312*9/9/2020 10:13:29 PM",
    "Planeta tierra [07-09-20].mpg*302137344*9/8/2020 5:25:20 PM",
    "Popular ciencia [07-09-20].mpg*286853120*9/8/2020 5:25:45 PM",
    "Portales Hacia la muerte [08-09-20].mpg*312043520*9/9/2020 10:16:28 PM",
    "Pueblos embrujados [08-09-20].mpg*397438976*9/9/2020 10:18:45 PM",
    "Puertas al mas alla [08-09-20].mpg*369922048*9/9/2020 10:18:39 PM",
    "Quien da mas [07-09-20].mpg*175093760*9/9/2020 10:14:20 PM",
    "Quien da mas [08-09-20].mpg*359522304*9/9/2020 10:19:30 PM",
    "Rescatando propiedades [07-09-20].mpg*143167488*9/8/2020 5:25:04 PM",
    "Rescatando propiedades [09-09-20].mpg*204933120*9/10/2020 4:39:53 PM",
    "Rescatando propiedades2 [07-09-20].mpg*144605184*9/8/2020 5:26:25 PM",
    "Rescatando propiedades2 [09-09-20].mpg*230258688*9/10/2020 4:42:39 PM",
    "Rescatando propiedades3 [07-09-20].mpg*140636160*9/8/2020 5:27:30 PM",
    "Rescatando propiedades4 [07-09-20].mpg*143130624*9/8/2020 5:27:51 PM",
    "Sabueso [08-09-20].mpg*307036160*9/9/2020 10:22:47 PM",
    "Sala de emergencia [09-09-20].mpg*432420864*9/10/2020 4:49:55 PM",
    "Sala de emergencia2 [09-09-20].mpg*432775168*9/10/2020 4:49:38 PM",
    "Sala de emergencia3 [09-09-20].mpg*431505408*9/10/2020 4:50:14 PM",
    "Sala de emergencia4 [09-09-20].mpg*434182144*9/10/2020 4:53:12 PM",
    "Sala de emergencia5 [09-09-20].mpg*431296512*9/10/2020 4:52:57 PM",
    "Sangra apache [09-09-20].mpg*219871232*9/10/2020 4:48:55 PM",
    "Sangre Apache [09-09-20].mpg*208148480*9/10/2020 4:53:24 PM",
    "Silencio mortal [07-09-20].mpg*296204288*9/9/2020 10:21:50 PM",
    "Sobrevivientes de Alaska [07-09-20].mpg*303523840*9/8/2020 5:38:14 PM",
    "Solo y en Peligro [08-09-20].mpg*326340608*9/9/2020 10:23:30 PM",
    "Solo y en Peligro2 [08-09-20].mpg*324737024*9/9/2020 10:24:25 PM",
    "Supervivencia al desnudo [08-09-20].mpg*302430208*9/9/2020 10:26:06 PM",
    "Supervivencia al desnudo2 [08-09-20].mpg*300048384*9/9/2020 10:29:18 PM",
    "Tierra Brasileña [08-09-20].mpg*159313920*9/9/2020 10:32:11 PM",
    "Tierra Brasileña2 [08-09-20].mpg*156862464*9/9/2020 10:31:44 PM",
    "Tierra Brasileña3 [08-09-20].mpg*156551168*9/9/2020 10:27:54 PM",
    "Tierra Brasileña4 [08-09-20].mpg*158175232*9/9/2020 10:30:24 PM",
    "Traficantes [08-09-20].mpg*371025920*9/9/2020 10:36:33 PM",
    "Veterinario sin fronteras [08-09-20].mpg*329222144*9/9/2020 10:47:04 PM",
    "Victimas del amor [07-09-20].mpg*384974848*9/9/2020 10:42:00 PM",
    "Vivela o vendela [07-09-20].mpg*297598976*9/8/2020 5:36:33 PM",
    "Wild Frank [07-09-20].mpg*302010368*9/8/2020 5:35:18 PM",
    "Yukon Vet [07-09-20].mpg*301037568*9/8/2020 5:38:12 PM",
    "Yukon Vet [08-09-20].mpg*301774848*9/9/2020 10:52:37 PM",
    73458948096,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]*0*9/13/2020 10:10:36 PM",
    0,
    "199*200*201*202*203*204*205*206*207*208*209*210*211*212*213*214*215*216*217*218*219*220*221*222*223*224*225*226*227*228*229*230*231*232*233*234"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\And The Winner Is Love [2020]*0*9/13/2020 10:11:02 PM",
    "And The Winner Is Love [2020] 1x33 [07-09-20].mp4*142894349*9/8/2020 10:51:30 PM",
    "And The Winner Is Love [2020] 1x34 [09-09-20].mp4*140706044*8/31/2020 6:33:01 AM",
    283600393,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\As Long As You Love Me [2020]*0*9/13/2020 10:11:15 PM",
    "As Long As You Love Me [2020] 1x45 [07-09-20].mp4*168579154*9/8/2020 10:51:31 PM",
    "As Long As You Love Me [2020] 1x46 [09-09-20].mp4*158441139*8/2/2020 12:57:58 AM",
    327020293,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Autumn Cicada [2020]*0*9/13/2020 10:10:38 PM",
    "Autumn Cicada [2020] 1x37 [07-09-20].mp4*135134978*9/8/2020 10:51:32 PM",
    "Autumn Cicada [2020] 1x38 [09-09-20].mp4*124742347*8/2/2020 1:28:06 PM",
    259877325,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Beautiful Reborn Flower [2020]*0*9/13/2020 10:43:28 PM",
    "Beautiful Reborn Flower [2020] 1x38 [07-09-20].mp4*162242542*9/8/2020 10:51:35 PM",
    "Beautiful Reborn Flower [2020] 1x39 [09-09-20].mp4*137035611*8/31/2020 7:53:42 AM",
    299278153,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Dr Cutie [2020]*0*9/13/2020 9:47:40 PM",
    "Dr Cutie [2020] 1x02 [07-09-20].mp4*148703896*9/8/2020 10:51:37 PM",
    "Dr Cutie [2020] 1x03 [09-09-20].mp4*151073328*9/3/2020 2:09:46 AM",
    299777224,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Dr Qin Medical Examiner 3 The Survivor [2020]*0*9/13/2020 9:48:03 PM",
    "Dr Qin Medical Examiner3 The Survivor [2020] 1x02 [07-09-20].mp4*143685698*9/8/2020 10:51:39 PM",
    143685698,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Dr Qin Medical Examiner3 The Survivor [2020]*0*9/13/2020 11:19:11 PM",
    "Dr Qin Medical Examiner3 The Survivor [2020] 1x03 [09-09-20].mp4*151658493*9/3/2020 3:09:35 AM",
    151658493,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Ever Night S2 [2020]*0*9/13/2020 11:08:20 PM",
    "Ever Night S2 [2020] 1x02 [07-09-20].mp4*154051965*9/8/2020 10:51:41 PM",
    "Ever Night S2 [2020] 1x03 [09-09-20].mp4*157515695*9/3/2020 7:29:43 AM",
    311567660,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Everyone Wants To Meet You [2020]*0*9/13/2020 9:48:16 PM",
    "Everyone Wants To Meet You [2020] 1x02 [07-09-20].mp4*129676767*9/8/2020 10:51:44 PM",
    "Everyone Wants To Meet You [2020] 1x03 [09-09-20].mp4*119290196*9/3/2020 12:28:22 AM",
    248966963,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Fatal Promise [2020]*0*9/13/2020 11:29:48 PM",
    "Fatal Promise [2020] 1x53 [07-09-20].mp4*95883760*9/8/2020 10:51:45 PM",
    "Fatal Promise [2020] 1x54 [09-09-20].mp4*98657037*8/31/2020 8:37:00 AM",
    194540797,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Forward Forever [2020]*0*9/13/2020 9:48:32 PM",
    "Forward Forever [2020] 1x02 [07-09-20].mp4*167425447*9/8/2020 10:51:48 PM",
    "Forward Forever [2020] 1x03 [09-09-20].mp4*179052852*9/3/2020 8:05:13 AM",
    346478299,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Get Married or Not [2020]*0*9/13/2020 11:33:48 PM",
    "Get Married or Not [2020] 1x10 pendiente [07-09-20].mp4*168967911*9/8/2020 10:51:51 PM",
    "Get Married or Not [2020] 1x13 [09-09-20].mp4*176983571*8/31/2020 9:28:59 AM",
    345951482,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Good bye My Love [2020]*0*9/13/2020 9:48:24 PM",
    "Good bye My Love [2020] 1x02 [07-09-20].mp4*143650272*9/8/2020 10:51:55 PM",
    "Good bye My Love [2020] 1x03 [09-09-20].mp4*129539071*9/3/2020 1:18:01 AM",
    273189343,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Lost Romance [2020]*0*9/13/2020 9:48:34 PM",
    "Lost Romance [2020] 1x09 [07-09-20].mp4*262185666*9/8/2020 10:51:58 PM",
    "Lost Romance [2020] 1x10 [09-09-20].mp4*252012106*9/8/2020 7:45:11 PM",
    514197772,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Love Designer [2020]*0*9/13/2020 9:48:10 PM",
    "Love Designer [2020] 1x35 [07-09-20].mp4*167465048*9/8/2020 10:52:01 PM",
    "Love Designer [2020] 1x36 [09-09-20].mp4*174307804*8/31/2020 7:30:05 PM",
    341772852,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Love in Between [2020]*0*9/13/2020 11:12:43 PM",
    "Love in Between [2020] 1x23 [07-09-20].mp4*332246193*9/8/2020 10:52:06 PM",
    "Love in Between [2020] 1x24 [09-09-20].mp4*258491087*9/6/2020 9:46:15 PM",
    590737280,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Meteor, Butterfly, Sword [2020]*0*9/13/2020 11:19:13 PM",
    "Meteor, Butterfly, Sword [2020] 1x08 [07-09-20].mp4*160764085*9/8/2020 10:52:08 PM",
    "Meteor, Butterfly, Sword [2020] 1x09 [09-09-20].mp4*144012028*8/31/2020 9:05:54 PM",
    304776113,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Mom Has An Affair [2020]*0*9/13/2020 10:23:21 PM",
    "Mom Has An Affair [2020] 1x07 [07-09-20].mp4*103866138*9/8/2020 10:52:10 PM",
    "Mom Has An Affair [2020] 1x08 [09-09-20].mp4*108867359*9/6/2020 11:46:43 PM",
    212733497,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Money Game [2020]*0*9/13/2020 10:23:19 PM",
    "Money Game [2020] 1x02 [07-09-20].mp4*184115577*9/8/2020 10:52:12 PM",
    "Money Game [2020] 1x03 [09-09-20].mp4*172617562*9/3/2020 6:59:58 AM",
    356733139,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Mr Fox and Miss Rose [2020]*0*9/13/2020 11:22:22 PM",
    "Mr Fox and Miss Rose [2020] 1x02 [07-09-20].mp4*171815477*9/8/2020 10:52:14 PM",
    "Mr Fox and Miss Rose [2020] 1x03 [09-09-20].mp4*171891515*9/4/2020 1:11:17 AM",
    343706992,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\My Lonely Planet [2020]*0*9/13/2020 10:32:58 PM",
    "My Lonely Planet [2020] 1x01 [07-09-20].mp4*134524200*9/8/2020 10:52:16 PM",
    "My Lonely Planet [2020] 1x02 [09-09-20].mp4*139887210*9/4/2020 12:01:01 AM",
    274411410,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\My Roommate Is A Detective [2020]*0*9/13/2020 11:29:50 PM",
    "My Roommate Is A Detective [2020] 1x08 [07-09-20].mp4*150275735*9/8/2020 10:52:18 PM",
    "My Roommate Is A Detective [2020] 1x09 [09-09-20].mp4*162970092*9/7/2020 6:50:57 AM",
    313245827,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\My Unfamiliar Family [2020]*0*9/13/2020 9:48:27 PM",
    "My Unfamiliar Family [2020] 1x12 [07-09-20].mp4*174955614*9/8/2020 10:52:21 PM",
    "My Unfamiliar Family [2020] 1x13 [09-09-20].mp4*162767027*9/7/2020 7:48:22 AM",
    337722641,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Once Again [2020]*0*9/13/2020 9:48:18 PM",
    "Once Again [2020] 1x28 [07-09-20].mp4*238532354*9/8/2020 10:52:24 PM",
    "Once Again [2020] 1x29 [09-09-20].mp4*234831061*9/1/2020 6:15:19 AM",
    473363415,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Princess at Large 2 [2020]*0*9/13/2020 9:48:11 PM",
    "Princess at Large 2  1x01 [07-09-20].mp4*127579479*9/8/2020 10:52:26 PM",
    "Princess at Large 2 [2020] 1x02 [09-09-20].mp4*133736734*9/3/2020 10:45:06 PM",
    261316213,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Serenade of Peaceful Joy [2020]*0*9/13/2020 10:23:16 PM",
    "Serenade of Peaceful Joy [2020] 1x35 [07-09-20].mp4*154316875*9/8/2020 10:52:28 PM",
    "Serenade of Peaceful Joy [2020] 1x36 [09-09-20].mp4*126854448*9/1/2020 7:16:49 AM",
    281171323,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Sweet Munchies [2020]*0*9/13/2020 9:48:00 PM",
    "Sweet Munchies [2020] 1x08 [07-09-20].mp4*170358666*9/8/2020 10:52:31 PM",
    "Sweet Munchies [2020] 1x09 [09-09-20].mp4*164586322*9/1/2020 10:38:45 AM",
    334944988,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Sweet Tai Chi [2020]*0*9/13/2020 9:48:06 PM",
    "Sweet Tai Chi [2020] 1x01 [07-09-20].mp4*107946079*9/8/2020 10:52:32 PM",
    "Sweet Tai Chi [2020] 1x02 [09-09-20].mp4*103969493*9/3/2020 11:13:17 PM",
    211915572,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\Team Bulldog- Off-Duty Investigation [2020]*0*9/13/2020 11:22:25 PM",
    "Team Bulldog- Off-Duty Investigation [2020] 1x08 [07-09-20].mp4*158622725*9/8/2020 10:52:35 PM",
    "Team Bulldog- Off-Duty Investigation [2020] 1x09 [09-09-20].mp4*143856379*9/2/2020 12:42:58 AM",
    302479104,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\The Romance Of Tiger And Rose [2020]*0*9/13/2020 9:47:55 PM",
    "The Romance Of Tiger And Rose [2020] 1x05 [07-09-20].mp4*186181125*9/8/2020 10:52:38 PM",
    "The Romance Of Tiger And Rose [2020] 1x06 [09-09-20].mp4*192653501*9/2/2020 1:44:56 AM",
    378834626,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\The Sleuth of Ming Dynasty [2020]*0*9/13/2020 9:47:53 PM",
    "The Sleuth of Ming Dynasty [2020] 1x23 [07-09-20].mp4*121430579*9/8/2020 10:52:40 PM",
    "The Sleuth of Ming Dynasty [2020] 1x24 [09-09-20].mp4*124026587*9/2/2020 3:25:23 AM",
    245457166,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\The Sweet Girl [2020]*0*9/13/2020 9:47:51 PM",
    "The Sweet Girl [2020] 1x15 [07-09-20].mp4*100716106*9/8/2020 10:52:41 PM",
    "The Sweet Girl [2020] 1x16 [09-09-20].mp4*95846226*9/2/2020 4:01:12 AM",
    196562332,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\The Twin Flower Legend [2020]*0*9/13/2020 10:23:11 PM",
    "The Twin Flower Legend [2020] 1x03 [07-09-20].mp4*181793129*9/8/2020 10:52:45 PM",
    "The Twin Flower Legend [2020] 1x04 [09-09-20].mp4*178395175*9/2/2020 9:23:08 AM",
    360188304,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\The Wonder Woman [2020]*0*9/13/2020 10:23:13 PM",
    "The Wonder Woman [2020] 1x03 [07-09-20].mp4*237339906*9/8/2020 10:52:50 PM",
    "The Wonder Woman [2020] 1x04 [09-09-20].mp4*235477149*9/2/2020 10:32:34 AM",
    472817055,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\True Colours [2020]*0*9/13/2020 9:47:45 PM",
    "True Colours [2020] 1x12 [07-09-20].mp4*147137165*9/8/2020 10:52:53 PM",
    "True Colours [2020] 1x13 [09-09-20].mp4*100681199*9/2/2020 1:38:57 PM",
    247818364,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Doramas [TX]\\You Are My Destiny [2020]*0*9/13/2020 9:48:07 PM",
    "You Are My Destiny [2020] 1x14 [07-09-20].mp4*160541759*9/8/2020 10:52:56 PM",
    "You Are My Destiny [2020] 1x15 [09-09-20].mp4*162778780*9/2/2020 3:02:45 PM",
    323320539,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]*0*9/13/2020 9:47:14 PM",
    0,
    "236*237*238*239*240*241*242*243*244*245*246*247*248*249*250*251"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\After We Collided [2020] [403,6 Mb]*0*9/13/2020 9:47:14 PM",
    "After en Mil Pedazos [2020].mp4*423191384*9/7/2020 11:51:46 AM",
    "After en Mil Pedazos.jpg*17202*9/7/2020 10:39:44 AM",
    423208586,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\El Secreto [Atrévete A Soñar] [2020] [1,25 Gb]*0*9/13/2020 9:47:14 PM",
    "El Secreto [Atrévete A Soñar] [2020].mp4*1340974141*9/3/2020 1:40:01 AM",
    "El Secreto [Atrévete A Soñar] [2020].png*330028*9/2/2020 8:37:30 PM",
    "El Secreto [Atrévete A Soñar] [2020].srt*122751*9/2/2020 3:13:24 PM",
    1341426920,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\Girls Of The Sun [2018] [1,29 Gb]*0*9/13/2020 11:30:28 PM",
    "Girls Of The Sun [2018] [1080p].jpg*108469*9/8/2020 3:42:10 PM",
    "Girls Of The Sun [2018] [1080p].mp4*1388228514*9/8/2020 2:42:45 PM",
    "Girls Of The Sun [2018] [1080p].srt*52514*9/5/2020 4:56:42 PM",
    1388389497,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\Inconceivable [2017] [1,18 Gb]*0*9/13/2020 9:47:14 PM",
    "Inconceivable [2017] [720p] [Dual Audio].mp4*1269530850*9/6/2020 3:19:44 PM",
    "Inconceivable [2017] [720p] [Dual Audio].png*293267*9/6/2020 7:02:28 PM",
    "Inconceivable [2017] [720p] [Dual Audio].srt*85742*9/6/2020 3:11:04 PM",
    1269909859,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\La Traicion [2020] [247,59 Mb]*0*9/13/2020 11:22:18 PM",
    "La Traicion (2020).mp4*259614840*9/5/2020 8:06:11 AM",
    259614840,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\Les Enfants d'Isadora [2020] [1080p] [972,49 Mb]*0*9/13/2020 10:32:46 PM",
    "Les Enfants d'Isadora [2020] [1080p].jpg*151485*9/4/2020 12:13:30 PM",
    "Les Enfants d'Isadora [2020] [1080p].mp4*1019556898*9/4/2020 6:42:00 AM",
    "Les Enfants d'Isadora [2020] [1080p].srt*19514*9/6/2020 5:03:51 PM",
    1019727897,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\Los Nuevos Mutantes [2020] [1,33 Gb]*0*9/13/2020 9:47:14 PM",
    "Los Nuevos Mutantes [2020].jpg*28694*9/3/2020 8:46:48 PM",
    "Los Nuevos Mutantes [2020].mkv*1426009478*9/3/2020 8:58:02 PM",
    1426038172,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\Muse  Simulation Theory [2020] [581,35 Mb]*0*9/13/2020 9:46:39 PM",
    "Muse  Simulation Theory [2020].jpg*16685*9/8/2020 9:06:34 PM",
    "Muse  Simulation Theory [2020].mp4*609568192*9/8/2020 10:25:40 PM",
    609584877,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\My Octopus Teacher [2020] [564,48 Mb]*0*9/13/2020 9:47:02 PM",
    "My Octopus Teacher (2020).jpg*13734*9/7/2020 8:57:33 PM",
    "My Octopus Teacher (2020).mp4*591891680*9/7/2020 9:13:56 PM",
    591905414,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\No Soy Tu Mami [2019] [1,26 Gb]*0*9/13/2020 11:29:29 PM",
    "No Soy Tu Mami [2019].mp4*1353013483*9/3/2020 6:44:29 AM",
    "No Soy Tu Mami [2019].png*273018*9/3/2020 8:27:20 PM",
    1353286501,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\Pienso en El Final [2020] [949,11 Mb]*0*9/13/2020 9:46:45 PM",
    "Pienso en El Final [2020].mkv*994699668*9/4/2020 9:50:49 AM",
    "Pienso en El Final [2020].png*382505*9/4/2020 5:19:52 PM",
    "Pienso en El Final [2020].srt*135038*9/4/2020 4:45:54 AM",
    995217211,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\Pledge [2018] [925,53 Mb]*0*9/13/2020 9:46:54 PM",
    "Pledge [2018] [1080p] [Dual Audio].jpg*3145151*9/8/2020 3:43:45 PM",
    "Pledge [2018] [1080p] [Dual Audio].mp4*967270926*9/5/2020 10:34:02 PM",
    "Pledge [2018] [1080p] [Dual Audio].srt*75507*9/3/2020 11:06:21 AM",
    970491584,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\Still Here [2020] [1080p] [Ya Tiene Subs] [1,13 Gb]*0*9/13/2020 11:18:56 PM",
    "Still Here [2020] [1080p] [Ya Tiene Subs].jpg*148454*9/4/2020 12:27:08 PM",
    "Still Here [2020] [1080p] [Ya Tiene Subs].mp4*1207994839*9/4/2020 5:44:00 AM",
    "Still Here [2020] [1080p] [Ya Tiene Subs].srt*102588*9/4/2020 12:51:37 PM",
    1208245881,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\The Argument [2020] [1080p] [972,57 Mb]*0*9/13/2020 10:32:34 PM",
    "The Argument [2020] [1080p].jpg*117990*9/4/2020 12:59:48 PM",
    "The Argument [2020] [1080p].mp4*1019567379*9/4/2020 6:54:00 AM",
    "The Argument [2020] [1080p].srt*126111*9/4/2020 1:14:37 PM",
    1019811480,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\Un Partido Decisivo (2019) [1,12 Gb]*0*9/13/2020 10:14:43 PM",
    "Un Partido Decisivo (2019).mp4*1202936893*9/1/2020 9:47:32 PM",
    "Un Partido Decisivo (2019).png*318716*9/1/2020 11:09:35 PM",
    "Un Partido Decisivo (2019).srt*78403*9/1/2020 6:03:56 PM",
    1203334012,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4]\\Villain [Villano] [2020] [216,18 Mb]*0*9/13/2020 10:36:52 PM",
    "Villain [Villano] [2020].jpg*21950*9/6/2020 5:36:18 PM",
    "Villain [Villano] [2020].mp4*226658064*9/6/2020 8:10:51 PM",
    226680014,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]*0*9/13/2020 10:13:45 PM",
    0,
    "253*254*255*256*257*258*259*260*261*262*263*264*265*266"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]\\[ Drama Romántico ] Inocencia Interumpida*0*9/13/2020 10:14:42 PM",
    "Inocencia Interumpida.jpg*212000*10/29/2005 5:14:06 AM",
    "Inocencia Interumpida_avi_Output_2_ntsc_vcd.mpg*1274572236*9/6/2017 8:29:58 PM",
    1274784236,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]\\[ Drama Romántico ] TENGO GANAS DE TI*0*9/13/2020 10:14:29 PM",
    "Tengo Ganas de Ti.avi*1185500136*9/13/2018 5:11:01 PM",
    "tengo_ganas_de_ti_ver3.jpg*156629*8/15/2012 5:33:14 AM",
    1185656765,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]\\[ Romántico ] Gia*0*9/13/2020 10:14:42 PM",
    "Gia.avi*728334336*12/28/2004 1:04:12 PM",
    "Gia.JPG*29001*10/23/2007 1:47:30 PM",
    "Gia.srt*94518*1/30/2005 2:30:10 PM",
    728457855,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]\\[Belico] Disturbing The Peace (2020) 1080p*0*9/13/2020 10:36:55 PM",
    "Disturbing The Peace - Official Trailer.mp4*12644768*1/17/2020 3:05:25 PM",
    "Disturbing The Peace (2020) 1080p.jpg*124230*1/17/2020 3:03:55 PM",
    "Disturbing The Peace (2020) 1080p.mkv*3730330745*1/17/2020 11:36:05 AM",
    "Disturbing The Peace (2020) 1080p.png*195577*1/17/2020 3:04:45 PM",
    "Disturbing The Peace (2020) 1080p.srt*46490*1/19/2020 1:06:42 AM",
    3743341810,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]\\[Ciencia Ficcion] I Am Number Four (2011)*0*9/13/2020 10:14:55 PM",
    "I Am Number Four - (2011) (720p) (4,16gb).mkv*848703740*6/22/2016 9:34:02 AM",
    "I Am Number Four - (2011) (720p) (4,16gb).srt*64420*5/5/2011 10:59:58 PM",
    "I Am Number Four - Aventuras (1).jpg*1034079*4/6/2012 7:15:48 AM",
    "I Am Number Four - Aventuras (3).jpg*245603*9/28/2011 5:36:54 AM",
    "I Am Number Four (2011).jpg*230500*10/19/2019 3:32:16 AM",
    850278342,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]\\[Cine Clasico]  Star Wars. Episode VI -  Return of the Jedi (1983)*0*9/13/2020 10:13:47 PM",
    "14-02-2013 20-14-50.jpg*70456*12/3/2014 5:04:50 PM",
    "Star Wars. Episode VI –  Return of the Jedi.avi*792565760*12/3/2014 5:05:16 PM",
    "Star Wars. Episode VI –  Return of the Jedi.srt*4524*11/16/2017 2:52:29 AM",
    792640740,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]\\[Comedia] Scary Movie 1 [2000] [720p]*0*9/13/2020 10:35:09 PM",
    "Scary Movie 1 [2000] [720p].jpg*238135*4/17/2012 12:09:20 PM",
    "Scary Movie 1 [2000] [720p].mkv*1600683731*6/3/2011 3:45:17 AM",
    "Scary Movie 1 [2000] [720p].srt*89144*11/10/2010 11:56:58 PM",
    1601011010,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]\\[Deporte] 42 [2013]*0*9/13/2020 11:14:35 PM",
    "42 (2013).mpg*1353439500*7/1/2013 9:00:10 AM",
    "42.jpg*140960*5/4/2013 10:12:36 PM",
    1353580460,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]\\[Documental] Apolo 11 (2020)*0*9/13/2020 10:25:50 PM",
    "Apolo 11 (2020).jpg*78361*1/8/2020 1:18:26 PM",
    "Apolo 11 (2020).mkv*917150306*1/7/2020 9:34:39 AM",
    917228667,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]\\[Drama] The Titan (2018)*0*9/13/2020 10:13:43 PM",
    "The Titan (2018).jpg*27984*5/7/2018 12:57:43 AM",
    "The Titan (2018).mkv*3241649669*5/6/2018 7:06:24 PM",
    3241677653,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]\\[Policiaca] Stuber (2019)*0*9/13/2020 8:31:55 PM",
    "Stuber (2019).jpg*23550*10/2/2019 10:35:49 PM",
    "Stuber (2019).mp4*855228877*10/2/2019 11:01:28 PM",
    855252427,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]\\[Suspenso] Dracula 1 (1979)*0*9/13/2020 8:30:04 PM",
    "Dracula 1 (1979).jpg*10482*2/29/2020 2:05:42 AM",
    "Dracula 1 (1979).mp4*1091001699*2/29/2020 2:22:41 AM",
    1091012181,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]\\[Terror] El Planeta de los Tiburones (2016)*0*9/13/2020 8:31:22 PM",
    "El Planeta de los Tiburones (2016).jpg*316102*11/27/2016 10:20:50 PM",
    "El Planeta de los Tiburones (2016)m.mpg*942467988*11/27/2016 10:32:08 PM",
    942784090,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [AVI-MKV-MP4] Clásicos Recomendados]\\[Thriller] El exorcismo de Anna Ecklund (2017)*0*9/13/2020 8:30:38 PM",
    "El exorcismo de Anna Ecklund (2017).jpg*241144*3/2/2017 3:31:04 PM",
    "El exorcismo de Anna Ecklund (2017).mpg*234510336*3/5/2017 2:46:45 PM",
    234751480,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Clásicos HD]*0*9/13/2020 8:26:42 PM",
    0,
    "268"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Clásicos HD]\\[HD] Hard Target Operación Cacería [1993]*0*9/13/2020 8:26:42 PM",
    "[HD] Hard Target Operación Cacería [1993] .mkv*2325440502*8/25/2020 3:40:22 PM",
    2325440502,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]*0*9/13/2020 8:43:23 PM",
    0,
    "270*272*274*275*276*278*279*280*281*282*283*284*285*286*288*289*290*291*292*293"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\#Alive [#Saraitda] [2020] [1080p] [Latino 5.1 Dual]*0*9/13/2020 8:31:34 PM",
    "#Alive [#Saraitda] [2020] [1080p] [Latino 5.1 Dual].jpg*133500*9/8/2020 5:31:04 PM",
    "#Alive [#Saraitda] [2020] [1080p] [Latino 5.1 Dual].mkv*2293903056*9/8/2020 11:23:18 AM",
    2294036556,
    "271"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\#Alive [#Saraitda] [2020] [1080p] [Latino 5.1 Dual]\\Subtitulos*0*9/13/2020 8:28:49 PM",
    "Forzados.spa.srt*4390*9/8/2020 11:09:45 AM",
    "Subs.spa.srt*42247*9/8/2020 11:00:12 AM",
    46637,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\A Nice Girl Like You [2020] [1080p]*0*9/13/2020 8:28:15 PM",
    "A Nice Girl Like You [2020] [1080p].jpg*2404057*9/9/2020 3:03:51 PM",
    "A Nice Girl Like You [2020] [1080p].mkv*1929694563*9/9/2020 5:16:41 PM",
    1932098620,
    "273"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\A Nice Girl Like You [2020] [1080p]\\Subtitulos*0*9/13/2020 8:30:26 PM",
    "SPA.srt*108824*9/9/2020 4:55:08 PM",
    108824,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\Girls Of The Sun [2018] [1080p] [1,46 Gb]*0*9/13/2020 8:32:32 PM",
    "Girls Of The Sun [2018] [1080p].jpg*108469*9/8/2020 3:42:10 PM",
    "Girls Of The Sun [2018] [1080p].mkv*1570344751*9/8/2020 2:42:45 PM",
    "Girls Of The Sun [2018] [1080p].srt*52514*9/5/2020 4:56:42 PM",
    1570505734,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\Guest House [2020] [1080p] [1,61 Gb]*0*9/13/2020 8:30:40 PM",
    "Guest House [2020] [1080p].jpg*611444*9/7/2020 3:56:52 PM",
    "Guest House [2020] [1080p].mp4*1724702552*9/4/2020 5:35:54 PM",
    "Guest House [2020] [1080p].srt*97254*9/4/2020 5:57:14 AM",
    1725411250,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\Inconceivable [2017] [720p] [Dual Audio] [872,29 Mb]*0*9/13/2020 10:53:08 PM",
    "Inconceivable [2017] [720p] [Dual Audio].mkv*914280725*9/6/2020 3:19:44 PM",
    "Inconceivable [2017] [720p] [Dual Audio].png*293267*9/6/2020 7:02:28 PM",
    914573992,
    "277"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\Inconceivable [2017] [720p] [Dual Audio] [872,29 Mb]\\SUBS*0*9/13/2020 8:29:07 PM",
    "Spa.srt*85742*9/6/2020 3:11:04 PM",
    85742,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\Indigo Valley [2020] [1080p] [1,42 Gb]*0*9/13/2020 8:32:15 PM",
    "Indigo Valley [2020] [1080p].mp4*1521107743*9/8/2020 8:24:40 PM",
    "Indigo Valley [2020] [1080p].srt*36119*9/8/2020 6:57:19 AM",
    1521143862,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\It Feels So Good [2020] [1080p] [BluRay-Rip] [1,81 Gb]*0*9/13/2020 10:57:03 PM",
    "It Feels So Good [2020] [1080p] [BluRay-Rip].mp4*1938312673*9/6/2020 4:56:46 PM",
    "It Feels So Good [2020] [1080p] [BluRay-Rip].srt*76130*9/9/2020 12:19:36 AM",
    1938388803,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\Lassie Come Home [2020] [1080p] [BluRay-Rip] [1,84 Gb]*0*9/13/2020 8:33:03 PM",
    "Lassie Come Home [2020] [1080p] [BluRay-Rip].jpg*496019*9/11/2020 4:58:57 PM",
    "Lassie Come Home [2020] [1080p] [BluRay-Rip].mp4*1973928440*9/10/2020 11:16:27 AM",
    "Lassie Come Home [2020] [1080p] [BluRay-Rip].srt*94736*9/9/2020 2:04:29 PM",
    1974519195,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\My Octopus Teacher [2020] [1080p] [1,63 Gb]*0*9/13/2020 8:32:36 PM",
    "My Octopus Teacher [2020] [1080p].mp4*1752519217*9/7/2020 8:20:38 PM",
    "My Octopus Teacher [2020] [1080p].srt*53444*9/7/2020 5:56:02 AM",
    1752572661,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\No soy tu mami [2019] [1080p] [2,13 Gb]*0*9/13/2020 10:56:14 PM",
    "No soy tu mami [2019] [1080p].jpg*81359*9/8/2020 3:42:35 PM",
    "No soy tu mami [2019] [1080p].mkv*2292284246*9/3/2020 12:44:29 AM",
    2292365605,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\The Babysitter Killer Queen [2020] [1080p] [1,96 Gb]*0*9/13/2020 8:31:02 PM",
    "The Babysitter Killer Queen [2020] [1080p].jpg*5009169*9/11/2020 4:59:46 PM",
    "The Babysitter Killer Queen [2020] [1080p].mp4*2095381880*9/10/2020 7:52:15 PM",
    "The Babysitter Killer Queen [2020] [1080p].srt*99094*9/12/2020 3:16:32 AM",
    "The Babysitter Killer Queen [2020] [1080p].srt.[0].BACKUP*98862*9/10/2020 6:03:15 AM",
    "The Babysitter Killer Queen [2020] [1080p].srt.[1].BACKUP*98978*9/12/2020 3:15:51 AM",
    2100687983,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\The Cleansing Hour [2019] [1080p] [BluRay-Rip] [1,48 Gb]*0*9/13/2020 8:29:56 PM",
    "The Cleansing Hour [2019] [1080p] [BluRay-Rip].mp4*1585633534*8/22/2020 6:21:03 PM",
    "The Cleansing Hour [2019] [1080p] [BluRay-Rip].srt*86512*8/20/2020 3:55:13 AM",
    1585720046,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\The Final Level Escaping Rancala [2019] [1080p] [BluRay-Rip] [1,66 Gb]*0*9/13/2020 8:32:40 PM",
    "The Final Level Escaping Rancala [2019] [1080p] [BluRay-Rip].mp4*1785673371*8/16/2020 12:53:55 PM",
    "The Final Level Escaping Rancala [2019] [1080p] [BluRay-Rip].srt*161050*8/14/2020 5:14:24 PM",
    1785834421,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\The Social Dilemma [2020] [1080p] [Dual Audio]*0*9/13/2020 8:42:55 PM",
    "The Social Dilemma [2020] [1080p] [Dual Audio].jpg*51002*9/9/2020 4:16:33 PM",
    "The Social Dilemma [2020] [1080p] [Dual Audio].mkv*2089785585*9/9/2020 7:49:40 PM",
    2089836587,
    "287"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\The Social Dilemma [2020] [1080p] [Dual Audio]\\Subs*0*9/13/2020 8:42:15 PM",
    "Forzados.spa.srt*13393*9/9/2020 7:41:49 PM",
    "Subs.eng.srt*142766*9/9/2020 7:36:27 PM",
    "Subs.spa.srt*129089*9/9/2020 7:36:27 PM",
    285248,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\Undercover Brother 2 [2019] [1080p] [BluRay-Rip] [1,61 Gb]*0*9/13/2020 8:43:23 PM",
    "Undercover Brother 2 [2019] [1080p] [BluRay-Rip].mp4*1731336835*8/16/2020 12:39:28 PM",
    "Undercover Brother 2 [2019] [1080p] [BluRay-Rip].srt*107185*8/14/2020 6:23:52 AM",
    1731444020,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\V [2020] [1080p] [Subs Pendientes] [2,62 Gb]*0*9/13/2020 8:43:23 PM",
    "V [2020] [1080p] [Subs Pendientes].mkv*2810560450*9/8/2020 4:25:21 AM",
    2810560450,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\Varda by Agnes [2019] [1080p] [BluRay-Rip] [1,87 Gb]*0*9/13/2020 8:42:15 PM",
    "Varda by Agnes [2019] [1080p] [BluRay-Rip].mp4*2009308190*9/6/2020 1:33:04 PM",
    "Varda by Agnes [2019] [1080p] [BluRay-Rip].srt*135452*9/6/2020 4:33:54 AM",
    2009443642,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\When Hitler Stole Pink Rabbit [2019] [1080p] [BluRay-Rip] [2,27 Gb]*0*9/13/2020 8:42:15 PM",
    "When Hitler Stole Pink Rabbit [2019] [1080p] [BluRay-Rip].mp4*2436187593*9/8/2020 8:16:30 AM",
    "When Hitler Stole Pink Rabbit [2019] [1080p] [BluRay-Rip].srt*103967*9/9/2020 12:19:37 AM",
    2436291560,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\Wish Man [2019] [720p] [BluRay-Rip] [1,3 Gb]*0*9/13/2020 8:42:40 PM",
    "Wish Man [2019] [720p] [BluRay-Rip].mp4*1394179153*8/29/2020 6:49:59 AM",
    "Wish Man [2019] [720p] [BluRay-Rip].srt*106303*8/28/2020 3:39:18 PM",
    1394285456,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Estrenos HD]\\You're Bacon Me Crazy [2020] [1080p] [5,66 Gb]*0*9/13/2020 10:02:02 PM",
    "You're Bacon Me Crazy [2020] [1080p].jpg*78153*9/9/2020 3:33:40 PM",
    "You're Bacon Me Crazy [2020] [1080p].mkv*6080259436*9/7/2020 7:12:00 AM",
    "You're Bacon Me Crazy [2020] [1080p].srt*133953*9/9/2020 3:38:04 PM",
    6080471542,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]*0*9/13/2020 10:16:54 PM",
    0,
    "295*302*308*316*322*325"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Actores] [Jackie Chan]*0*9/13/2020 10:00:20 PM",
    0,
    "296*297*298*299*300*301"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Actores] [Jackie Chan]\\Dragones Gemelos [1992]*0*9/13/2020 10:00:11 PM",
    "Dragones Gemelos [1992] [1080p] [Dual Audio].mkv*2922073611*9/9/2019 7:31:29 AM",
    "Dragones Gemelos [1992] [1080p] [Dual Audio].png*76791*3/1/2020 3:09:58 AM",
    2922150402,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Actores] [Jackie Chan]\\Dragons Forever [Jackie Chan]*0*9/13/2020 10:00:51 PM",
    "dragons forever.avi*752039936*10/8/2010 4:21:02 AM",
    "dragons forever.srt*73937*8/19/2003 8:07:38 PM",
    "dragons_forever_1987.jpg*46942*8/15/2011 1:20:50 PM",
    752160815,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Actores] [Jackie Chan]\\Drunken Master 2 (1994) [Jackie Chan]*0*9/13/2020 10:02:02 PM",
    "Drunken Master (1994).avi*2787485696*11/7/2012 5:14:10 PM",
    "Drunken Master (1994).jpg*13541*10/6/2018 9:48:24 PM",
    "Drunken Master (1994).srt*113334*1/27/2010 2:57:20 AM",
    "The.Legend.of.Drunken.Master.1994.720p.BRRip.XviD-SHiRK.nfo*1342*10/24/2012 3:33:25 PM",
    "The.Legend.of.Drunken.Master.1994.720p.BRRip.XviD-SHiRK.srt*74972*10/24/2012 3:31:35 PM",
    2787688885,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Actores] [Jackie Chan]\\El Esmoquin [2002]*0*9/13/2020 10:00:41 PM",
    "El Esmoquin [2002].jpg*29353*8/15/2011 1:25:23 PM",
    "El Esmoquin [2002].mpg*998683224*11/3/2010 1:47:54 AM",
    998712577,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Actores] [Jackie Chan]\\El Invencible Jackie Chan (2005)*0*9/13/2020 11:13:42 PM",
    "- El Invencible Jackie Chan (2005).jpg*3352082*4/1/2019 10:25:12 PM",
    "El Invencible Jackie Chan (2005).mp4*653645217*4/1/2019 10:37:16 PM",
    656997299,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Actores] [Jackie Chan]\\El Misterio Del Dragón (2020)*0*9/13/2020 10:24:19 PM",
    "El Misterio Del Dragón (2020).jpg*26499*1/12/2020 9:19:39 PM",
    "El Misterio del Dragon [2020].mkv*2893036079*5/4/2020 11:17:14 PM",
    2893062578,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Actores] [Leonardo Di Caprio]*0*9/13/2020 10:02:02 PM",
    0,
    "303*304*305*306*307"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Actores] [Leonardo Di Caprio]\\Critters 3 [1991] [889,75 Mb]*0*9/13/2020 10:00:11 PM",
    "Critters 3 [1991].avi*931883844*8/28/2020 4:00:30 AM",
    "Critters 3 [1991].jpg*1087248*8/28/2020 3:58:32 AM",
    932971092,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Actores] [Leonardo Di Caprio]\\El Aviador [2004] [974,92 Mb]*0*9/13/2020 11:13:42 PM",
    "El Aviador [2004].avi*1021841614*8/23/2011 3:03:52 AM",
    "El Aviador [2004].jpg*437189*8/28/2020 3:05:16 AM",
    1022278803,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Actores] [Leonardo Di Caprio]\\El Origen [2010] [799,9 Mb]*0*9/13/2020 10:01:48 PM",
    "El Origen [2010].avi*838320128*7/14/2011 10:49:03 AM",
    "El Origen [2010].jpg*435487*8/28/2020 3:19:06 AM",
    838755615,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Actores] [Leonardo Di Caprio]\\J  Edgar [2011] [707,92 Mb]*0*9/13/2020 10:00:11 PM",
    "J  Edgar [2011].avi*742110870*2/21/2012 4:38:00 PM",
    "J  Edgar [2011].jpg*197956*8/28/2020 4:01:18 AM",
    742308826,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Actores] [Leonardo Di Caprio]\\La Isla Siniestra [2010] [817,63 Mb]*0*9/13/2020 10:01:48 PM",
    "La Isla Siniestra [2010].avi*856975360*5/28/2010 12:45:46 AM",
    "La Isla Siniestra [2010].jpg*376314*8/28/2020 3:22:23 AM",
    857351674,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Genero] [Acción]*0*9/13/2020 10:17:16 PM",
    0,
    "309*310*311*312*313*314*315"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Genero] [Acción]\\Asalto En Paris (2016)*0*9/13/2020 10:00:20 PM",
    "Asalto En Paris (2016).mp4*1319777494*3/17/2019 3:03:12 AM",
    1319777494,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Genero] [Acción]\\Asesinato En 8Mm. (1999)*0*9/13/2020 11:31:30 PM",
    "Asesinato En 8Mm. (1999).jpg*10843*2/14/2019 11:20:09 PM",
    "Asesinato En 8Mm. (1999).mp4*550155137*10/1/2017 4:11:14 PM",
    550165980,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Genero] [Acción]\\Asesino [2004]*0*9/13/2020 8:24:51 PM",
    "Asesino [2004].jpg*52977*1/14/2019 9:57:39 PM",
    "Asesino [2004].mp4*719515312*1/8/2019 6:17:53 PM",
    719568289,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Genero] [Acción]\\Asesinos [Antonio Banderas]*0*9/13/2020 10:45:26 PM",
    "Asesinos [Antonio Banderas].jpg*12652*10/6/2018 10:49:03 PM",
    "Assassins (1995).mkv*4998451601*8/30/2011 2:56:00 PM",
    "Assassins (1995).srt*72875*8/30/2011 3:04:29 PM",
    4998537128,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Genero] [Acción]\\Assassination Games*0*9/13/2020 10:18:06 PM",
    "Assassination.Games.2011.PPVRIP-P2P.mpg*1055010012*4/13/2016 6:30:59 AM",
    "Assassination.Games.2011.PPVRIP-P2P.srt*42931*8/9/2011 3:15:42 AM",
    "folder.jpg*493016*8/8/2011 9:43:08 AM",
    1055545959,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Genero] [Acción]\\Assassination of a High School President*0*9/13/2020 11:23:33 PM",
    "Assassination of a High School President.jpg*919022*11/12/2011 5:49:50 PM",
    "Assassination of a High School President.mp4*750780134*6/6/2018 7:21:38 AM",
    "Assassination of a High School President.srt*93729*1/18/2010 12:41:46 AM",
    751792885,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Genero] [Acción]\\Assassins Run (2012) [Chistian Slater]*0*9/13/2020 10:17:50 PM",
    "Assassins Run.avi*733775872*4/10/2013 10:32:17 PM",
    "Assassins Run.jpg*52943*4/13/2013 3:43:58 PM",
    "Assassins Run.srt*32080*4/15/2013 9:56:14 AM",
    733860895,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Genero] [Supervivencia]*0*9/13/2020 10:17:58 PM",
    0,
    "317*318*319*320*321"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Genero] [Supervivencia]\\7.19, La Hora Del Temblor [2016] [692,99 Mb]*0*9/13/2020 10:17:42 PM",
    "7.19, La Hora Del Temblor [2016].avi*725929472*10/4/2017 9:39:20 PM",
    "7.19, La Hora Del Temblor [2016].jpg*724896*8/27/2020 12:35:49 AM",
    726654368,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Genero] [Supervivencia]\\A La Deriva [2018] [960,61 Mb]*0*9/13/2020 10:18:07 PM",
    "A La Deriva [2018].jpg*315507*8/27/2020 1:48:20 AM",
    "A La Deriva [2018].mp4*1006953472*11/27/2018 1:00:32 AM",
    1007268979,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Genero] [Supervivencia]\\Al Filo Del Peligro [1997] [702,81 Mb]*0*9/13/2020 11:23:33 PM",
    "Al Filo Del Peligro [1997].avi*736532480*12/26/2012 9:21:14 PM",
    "Al Filo Del Peligro [1997].jpg*421714*8/27/2020 1:26:00 AM",
    736954194,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Genero] [Supervivencia]\\Naufrago [2000] [698,25 Mb]*0*9/13/2020 10:17:25 PM",
    "Naufrago [2000].avi*731867136*8/3/2011 6:47:15 PM",
    "Naufrago [2000].jpg*297763*8/27/2020 12:55:01 AM",
    732164899,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Por Genero] [Supervivencia]\\Red de Mentiras  [2008] [701,75 Mb]*0*9/13/2020 10:17:25 PM",
    "Red de Mentiras  [2008].avi*735336448*12/24/2011 7:53:33 AM",
    "Red de Mentiras  [2008].jpg*496560*8/28/2020 4:13:28 AM",
    735833008,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Sagas] [Feast]*0*9/13/2020 10:17:25 PM",
    0,
    "323*324"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Sagas] [Feast]\\Feast I (2006)*0*9/13/2020 11:31:05 PM",
    "Feast I (2006).avi*788563328*10/11/2008 11:21:28 AM",
    "Feast I (2006).jpg*134096*8/10/2011 12:16:10 AM",
    788697424,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Sagas] [Feast]\\Feast II (2008)*0*9/13/2020 10:17:58 PM",
    "Feast II (2008).avi*764563456*10/11/2008 11:00:06 AM",
    "Feast II (2008).jpg*68486*8/10/2011 12:15:58 AM",
    "Feast II (2008).srt*47437*10/4/2008 7:27:34 PM",
    764679379,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Sagas] [Nuestra Pandilla]*0*9/13/2020 10:17:17 PM",
    0,
    "326*327*328"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Sagas] [Nuestra Pandilla]\\Nuestra Pandilla 1 [1993] [701,06 Mb]*0*9/13/2020 10:17:42 PM",
    "Nuestra Pandilla 1 [1993].avi*734656512*8/26/2020 1:15:55 AM",
    "Nuestra Pandilla 1 [1993].jpg*461724*8/26/2020 1:12:47 AM",
    735118236,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Sagas] [Nuestra Pandilla]\\Nuestra Pandilla 2 [2005] [700,45 Mb]*0*9/13/2020 10:17:50 PM",
    "Nuestra Pandilla 2 [2005].avi*734279680*8/26/2020 1:12:32 AM",
    "Nuestra Pandilla 2 [2005].jpg*192926*8/26/2020 1:12:16 AM",
    734472606,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Filmes [Por Sagas,Por Actores o por Genero]\\[Sagas] [Nuestra Pandilla]\\Nuestra Pandilla 3 [2007] [700,61 Mb]*0*9/13/2020 10:26:15 PM",
    "Nuestra Pandilla 3 [2007].avi*734017536*8/26/2020 1:16:08 AM",
    "Nuestra Pandilla 3 [2007].jpg*623402*8/26/2020 1:16:08 AM",
    734640938,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games*0*9/13/2020 9:27:17 PM",
    0,
    "330*348*366*368*373"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]*0*9/13/2020 11:28:50 PM",
    "a-long-way-down-pc-full-1.jpg*78976*8/31/2020 11:21:57 PM",
    "a-long-way-down-pc-full-2.jpg*76554*8/31/2020 11:22:01 PM",
    "a-long-way-down-pc-full-3.jpg*43356*8/31/2020 11:22:03 PM",
    "a-long-way-down-pc-full-portada.jpg*33928*8/31/2020 11:22:06 PM",
    "ALWD.exe*650752*8/27/2020 8:34:27 AM",
    "Instrucciones.txt*1147*8/27/2020 9:21:06 PM",
    "Leeme.txt*48*4/7/2015 1:03:37 PM",
    "steam_appid.txt*6*8/27/2020 8:35:05 AM",
    "UnityCrashHandler64.exe*1460680*8/27/2020 8:34:27 AM",
    "UnityPlayer.dll*23328712*8/27/2020 8:34:27 AM",
    "WinPixEventRuntime.dll*42704*8/27/2020 8:35:05 AM",
    25716863,
    "331*337"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\ALWD_Data*0*9/13/2020 11:31:03 PM",
    "app.info*14*8/27/2020 8:34:28 AM",
    "boot.config*140*8/27/2020 8:34:28 AM",
    "globalgamemanagers*1195552*8/27/2020 12:20:38 PM",
    "globalgamemanagers.assets*403340*8/27/2020 12:20:38 PM",
    "globalgamemanagers.assets.resS*6537960*8/27/2020 8:34:27 AM",
    "level0*346528*8/27/2020 12:20:38 PM",
    "level1*548056*8/27/2020 12:20:38 PM",
    "level10*21244*8/27/2020 12:20:42 PM",
    "level10.resS*131232*8/27/2020 8:34:29 AM",
    "level11*558992*8/27/2020 12:20:38 PM",
    "level11.resS*131232*8/27/2020 12:20:42 PM",
    "level2*626576*8/27/2020 12:20:38 PM",
    "level2.resS*131232*8/27/2020 8:34:29 AM",
    "level3*691960*8/27/2020 12:20:38 PM",
    "level3.resS*131232*8/27/2020 8:34:29 AM",
    "level4*4525368*8/27/2020 12:20:38 PM",
    "level4.resS*131232*8/27/2020 8:34:29 AM",
    "level5*174388*8/27/2020 12:20:42 PM",
    "level6*17264*8/27/2020 12:20:42 PM",
    "level7*156212*8/27/2020 12:20:42 PM",
    "level7.resS*131232*8/27/2020 8:34:29 AM",
    "level8*96232*8/27/2020 12:20:42 PM",
    "level8.resS*131232*8/27/2020 8:34:29 AM",
    "level9*19288*8/27/2020 12:20:42 PM",
    "level9.resS*131232*8/27/2020 8:34:29 AM",
    "resources.assets*101701424*8/27/2020 12:20:38 PM",
    "resources.assets.resS*683305836*8/27/2020 12:20:38 PM",
    "resources.resource*4064*8/27/2020 8:34:47 AM",
    "ScreenSelector.png*89012*8/27/2020 8:34:47 AM",
    "sharedassets0.assets*3873448*8/27/2020 12:20:38 PM",
    "sharedassets0.assets.resS*53508064*8/27/2020 12:20:38 PM",
    "sharedassets1.assets*1706072*8/27/2020 12:20:38 PM",
    "sharedassets1.assets.resS*20960728*8/27/2020 12:20:38 PM",
    "sharedassets10.assets*13180*8/27/2020 12:20:55 PM",
    "sharedassets10.assets.resS*1641608*8/27/2020 12:20:38 PM",
    "sharedassets11.assets*8872*8/27/2020 12:20:53 PM",
    "sharedassets11.assets.resS*49664*8/27/2020 12:20:55 PM",
    "sharedassets2.assets*1329488*8/27/2020 12:20:38 PM",
    "sharedassets2.assets.resS*139191244*8/27/2020 12:20:38 PM",
    "sharedassets2.resource*4258742*8/27/2020 12:20:38 PM",
    "sharedassets3.assets*383040*8/27/2020 12:20:38 PM",
    "sharedassets3.assets.resS*181873452*8/27/2020 8:34:27 AM",
    "sharedassets4.assets*626472*8/27/2020 12:20:38 PM",
    "sharedassets4.assets.resS*34470052*8/27/2020 12:20:38 PM",
    "sharedassets5.assets*38988*8/27/2020 12:20:54 PM",
    "sharedassets5.assets.resS*121274368*8/27/2020 8:34:27 AM",
    "sharedassets6.assets*289972*8/27/2020 12:20:38 PM",
    "sharedassets6.assets.resS*29034496*8/27/2020 8:34:27 AM",
    "sharedassets7.assets*12560*8/27/2020 12:20:55 PM",
    "sharedassets7.assets.resS*723952*8/27/2020 8:34:27 AM",
    "sharedassets8.assets*16148*8/27/2020 12:20:55 PM",
    "sharedassets8.assets.resS*4325376*8/27/2020 8:34:27 AM",
    "sharedassets9.assets*4625*8/27/2020 12:20:55 PM",
    1401683917,
    "332*333*334*335"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\ALWD_Data\\Managed*0*9/13/2020 10:26:03 PM",
    "ALWD.dll*1527296*8/27/2020 12:20:38 PM",
    "ALWD-Types.dll*9216*8/27/2020 12:20:42 PM",
    "BouncyCastle_GameSparks.dll*2032640*8/27/2020 8:34:27 AM",
    "DemiLib.dll*11776*8/27/2020 8:34:30 AM",
    "DOTween.dll*159744*8/27/2020 8:34:28 AM",
    "DOTweenPro.dll*15872*8/27/2020 8:34:30 AM",
    "GameSparks.Api.dll*201728*8/27/2020 8:34:28 AM",
    "GameSparks.dll*92160*8/27/2020 8:34:28 AM",
    "GameSparksRT.dll*58880*8/27/2020 8:34:28 AM",
    "Google.GData.AccessControl.DLL*24576*8/27/2020 8:34:28 AM",
    "Google.GData.Client.dll*212992*8/27/2020 8:34:28 AM",
    "Google.GData.Documents.dll*53248*8/27/2020 8:34:30 AM",
    "Google.GData.Extensions.dll*90112*8/27/2020 8:34:28 AM",
    "Google.GData.Spreadsheets.dll*40960*8/27/2020 8:34:28 AM",
    "Mono.Security.dll*310272*8/27/2020 8:34:27 AM",
    "mscorlib.dll*3901440*8/27/2020 8:34:27 AM",
    "netstandard.dll*84992*8/27/2020 8:34:30 AM",
    "Newtonsoft.Json.dll*459776*8/27/2020 8:34:27 AM",
    "Plugins-AssetIcons.dll*3584*8/27/2020 12:20:42 PM",
    "Plugins-AsyncAwaitUtil.dll*18432*8/27/2020 12:20:42 PM",
    "Plugins-Beebyte-Obfuscator.dll*4096*8/27/2020 12:20:42 PM",
    "Plugins-Coffee-UIParticle.dll*13824*8/27/2020 12:20:42 PM",
    "Plugins-Dotween.dll*48128*8/27/2020 12:20:42 PM",
    "Plugins-DragonBones.dll*169984*8/27/2020 12:20:42 PM",
    "Plugins-FMOD.dll*178176*8/27/2020 12:20:42 PM",
    "Plugins-FMOD-ResonanceAudio.dll*9728*8/27/2020 12:20:42 PM",
    "Plugins-FullSerializer.dll*83456*8/27/2020 12:20:42 PM",
    "Plugins-GameSparks.dll*54272*8/27/2020 12:20:42 PM",
    "Plugins-GSTU.dll*27648*8/27/2020 12:20:42 PM",
    "Plugins-I2.dll*462336*8/27/2020 12:20:38 PM",
    "Plugins-JMOAssets-ToonyColorsPro2.dll*37376*8/27/2020 12:20:42 PM",
    "Plugins-MonsterLove.dll*12800*8/27/2020 12:20:42 PM",
    "Plugins-PixelCrushers.Common.dll*105984*8/27/2020 12:20:42 PM",
    "Plugins-PixelCrushers.Common-Templates.dll*3584*8/27/2020 12:20:42 PM",
    "Plugins-PixelCrushers.Common-Wrappers.dll*9216*8/27/2020 12:20:42 PM",
    "Plugins-PixelCrushers.DialogueSystem.dll*714240*8/27/2020 12:20:38 PM",
    "Plugins-PixelCrushers.DialogueSystem-I2LocSupport.dll*7680*8/27/2020 12:20:42 PM",
    "Plugins-PixelCrushers.Templates.dll*3584*8/27/2020 12:20:42 PM",
    "Plugins-PixelCrushers.Wrappers.dll*24576*8/27/2020 12:20:42 PM",
    "Plugins-SimpleSRT.dll*10752*8/27/2020 12:20:42 PM",
    "Plugins-Sirenix.OdinInspector.CompatibilityLayer.dll*5632*8/27/2020 12:20:42 PM",
    "Plugins-SkyFXPack.dll*7168*8/27/2020 12:20:42 PM",
    "Plugins-SpriteEffects.dll*4096*8/27/2020 12:20:42 PM",
    "Plugins-Steamworks.NET.dll*281088*8/27/2020 12:20:38 PM",
    "Plugins-UniRx.dll*447488*8/27/2020 12:20:38 PM",
    "Plugins-Zenject.dll*413184*8/27/2020 12:20:38 PM",
    "Seenapsis.dll*4608*8/27/2020 12:20:42 PM",
    "Seenapsis-DragonBones.dll*17408*8/27/2020 12:20:43 PM",
    "Seenapsis-UI.dll*51712*8/27/2020 12:20:42 PM",
    "Sirenix.OdinInspector.Attributes.dll*30720*8/27/2020 8:34:30 AM",
    "Sirenix.Serialization.Config.dll*15360*8/27/2020 8:34:29 AM",
    "Sirenix.Serialization.dll*290816*8/27/2020 8:34:27 AM",
    "Sirenix.Utilities.dll*157696*8/27/2020 8:34:28 AM",
    "System.ComponentModel.Composition.dll*247808*8/27/2020 8:34:28 AM",
    "System.Configuration.dll*43008*8/27/2020 8:34:28 AM",
    "System.Core.dll*1057792*8/27/2020 8:34:27 AM",
    "System.Data.dll*1941504*8/27/2020 8:34:27 AM",
    "System.Diagnostics.StackTrace.dll*6656*8/27/2020 8:34:29 AM",
    "System.dll*2131456*8/27/2020 8:34:27 AM",
    "System.Drawing.dll*184320*8/27/2020 8:34:28 AM",
    "System.EnterpriseServices.dll*33280*8/27/2020 8:34:31 AM",
    "System.Globalization.Extensions.dll*6144*8/27/2020 8:34:29 AM",
    "System.IO.Compression.dll*98816*8/27/2020 8:34:29 AM",
    "System.IO.Compression.FileSystem.dll*23040*8/27/2020 8:34:29 AM",
    "System.Net.Http.dll*114688*8/27/2020 8:34:29 AM",
    "System.Numerics.dll*114176*8/27/2020 8:34:29 AM",
    "System.Runtime.Serialization.dll*840704*8/27/2020 8:34:27 AM",
    "System.Runtime.Serialization.Xml.dll*7168*8/27/2020 8:34:31 AM",
    "System.ServiceModel.Internals.dll*218112*8/27/2020 8:34:29 AM",
    "System.Transactions.dll*33280*8/27/2020 8:34:31 AM",
    "System.Xml.dll*2414592*8/27/2020 8:34:27 AM",
    "System.Xml.Linq.dll*119296*8/27/2020 8:34:29 AM",
    "System.Xml.XPath.XDocument.dll*5120*8/27/2020 8:34:31 AM",
    "TinyJSON.dll*22528*8/27/2020 8:34:29 AM",
    "Unity.Analytics.DataPrivacy.dll*7680*8/27/2020 12:20:43 PM",
    "Unity.Postprocessing.Runtime.dll*139264*8/27/2020 12:20:43 PM",
    "Unity.RenderPipelines.Core.Runtime.dll*37376*8/27/2020 12:20:42 PM",
    "Unity.RenderPipelines.Core.ShaderLibrary.dll*3584*8/27/2020 12:20:43 PM",
    "Unity.RenderPipelines.Lightweight.Runtime.dll*68096*8/27/2020 12:20:42 PM",
    "Unity.TextMeshPro.dll*331776*8/27/2020 12:20:38 PM",
    "UnityEngine.AccessibilityModule.dll*11776*8/27/2020 8:34:29 AM",
    "UnityEngine.Advertisements.dll*20992*8/27/2020 8:34:29 AM",
    "UnityEngine.AIModule.dll*42496*8/27/2020 8:34:29 AM",
    "UnityEngine.AnimationModule.dll*137216*8/27/2020 8:34:31 AM",
    "UnityEngine.ARModule.dll*12288*8/27/2020 8:34:29 AM",
    "UnityEngine.AssetBundleModule.dll*20992*8/27/2020 8:34:29 AM",
    "UnityEngine.AudioModule.dll*58368*8/27/2020 8:34:29 AM",
    "UnityEngine.BaselibModule.dll*8192*8/27/2020 8:34:29 AM",
    "UnityEngine.ClothModule.dll*14336*8/27/2020 8:34:29 AM",
    "UnityEngine.ClusterInputModule.dll*9728*8/27/2020 8:34:29 AM",
    "UnityEngine.ClusterRendererModule.dll*8704*8/27/2020 8:34:31 AM",
    "UnityEngine.CoreModule.dll*848384*8/27/2020 8:34:27 AM",
    "UnityEngine.CrashReportingModule.dll*9216*8/27/2020 8:34:31 AM",
    "UnityEngine.DirectorModule.dll*12800*8/27/2020 8:34:29 AM",
    "UnityEngine.dll*72192*8/27/2020 8:34:29 AM",
    "UnityEngine.FileSystemHttpModule.dll*8192*8/27/2020 8:34:31 AM",
    "UnityEngine.GameCenterModule.dll*26112*8/27/2020 8:34:29 AM",
    "UnityEngine.GridModule.dll*13312*8/27/2020 8:34:31 AM",
    "UnityEngine.HotReloadModule.dll*8192*8/27/2020 8:34:29 AM",
    "UnityEngine.ImageConversionModule.dll*9728*8/27/2020 8:34:29 AM",
    "UnityEngine.IMGUIModule.dll*146944*8/27/2020 8:34:31 AM",
    "UnityEngine.InputModule.dll*11264*8/27/2020 8:34:29 AM",
    "UnityEngine.JSONSerializeModule.dll*10240*8/27/2020 8:34:31 AM",
    "UnityEngine.LocalizationModule.dll*9216*8/27/2020 8:34:29 AM",
    "UnityEngine.Networking.dll*260608*8/27/2020 8:34:31 AM",
    "UnityEngine.ParticleSystemModule.dll*127488*8/27/2020 8:34:31 AM",
    "UnityEngine.PerformanceReportingModule.dll*8704*8/27/2020 8:34:29 AM",
    "UnityEngine.Physics2DModule.dll*93184*8/27/2020 8:34:31 AM",
    "UnityEngine.PhysicsModule.dll*84992*8/27/2020 8:34:29 AM",
    "UnityEngine.ProfilerModule.dll*8192*8/27/2020 8:34:31 AM",
    "UnityEngine.ScreenCaptureModule.dll*9216*8/27/2020 8:34:29 AM",
    "UnityEngine.SharedInternalsModule.dll*19456*8/27/2020 8:34:29 AM",
    "UnityEngine.SpatialTracking.dll*11264*8/27/2020 8:34:29 AM",
    "UnityEngine.SpriteMaskModule.dll*9728*8/27/2020 8:34:31 AM",
    "UnityEngine.SpriteShapeModule.dll*9728*8/27/2020 8:34:29 AM",
    "UnityEngine.StreamingModule.dll*8704*8/27/2020 8:34:29 AM",
    "UnityEngine.StyleSheetsModule.dll*16384*8/27/2020 8:34:31 AM",
    "UnityEngine.SubstanceModule.dll*12800*8/27/2020 8:34:29 AM",
    "UnityEngine.TerrainModule.dll*62976*8/27/2020 8:34:29 AM",
    "UnityEngine.TerrainPhysicsModule.dll*8704*8/27/2020 8:34:29 AM",
    "UnityEngine.TextCoreModule.dll*32256*8/27/2020 8:34:30 AM",
    "UnityEngine.TextRenderingModule.dll*26624*8/27/2020 8:34:29 AM",
    "UnityEngine.TilemapModule.dll*24064*8/27/2020 8:34:29 AM",
    "UnityEngine.Timeline.dll*98816*8/27/2020 8:34:31 AM",
    "UnityEngine.TimelineModule.dll*8192*8/27/2020 8:34:29 AM",
    "UnityEngine.TLSModule.dll*8192*8/27/2020 8:34:29 AM",
    "UnityEngine.UI.dll*253952*8/27/2020 8:34:31 AM",
    "UnityEngine.UIElementsModule.dll*351744*8/27/2020 8:34:27 AM",
    "UnityEngine.UIModule.dll*22016*8/27/2020 8:34:29 AM",
    "UnityEngine.UmbraModule.dll*8192*8/27/2020 8:34:29 AM",
    "UnityEngine.UNETModule.dll*77824*8/27/2020 8:34:31 AM",
    "UnityEngine.UnityAnalyticsModule.dll*24064*8/27/2020 8:34:31 AM",
    "UnityEngine.UnityConnectModule.dll*10240*8/27/2020 8:34:31 AM",
    "UnityEngine.UnityTestProtocolModule.dll*8192*8/27/2020 8:34:29 AM",
    "UnityEngine.UnityWebRequestAssetBundleModule.dll*11264*8/27/2020 8:34:31 AM",
    "UnityEngine.UnityWebRequestAudioModule.dll*10752*8/27/2020 8:34:29 AM",
    "UnityEngine.UnityWebRequestModule.dll*41984*8/27/2020 8:34:29 AM",
    "UnityEngine.UnityWebRequestTextureModule.dll*10240*8/27/2020 8:34:31 AM",
    "UnityEngine.UnityWebRequestWWWModule.dll*19968*8/27/2020 8:34:31 AM",
    "UnityEngine.VehiclesModule.dll*11776*8/27/2020 8:34:31 AM",
    "UnityEngine.VFXModule.dll*27136*8/27/2020 8:34:31 AM",
    "UnityEngine.VideoModule.dll*26624*8/27/2020 8:34:31 AM",
    "UnityEngine.VRModule.dll*30720*8/27/2020 8:34:29 AM",
    "UnityEngine.WindModule.dll*9216*8/27/2020 8:34:31 AM",
    "UnityEngine.XRModule.dll*44544*8/27/2020 8:34:32 AM",
    "Zenject-usage.dll*22528*8/27/2020 8:34:32 AM",
    26773504,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\ALWD_Data\\Plugins*0*9/13/2020 9:37:16 PM",
    "fmodstudio.dll*3053056*8/27/2020 12:20:38 PM",
    "fmodstudiol.dll*4023296*8/27/2020 12:20:38 PM",
    "resonanceaudio.dll*860160*8/27/2020 12:20:38 PM",
    "steam_api64.dll*1594368*8/8/2020 7:45:48 PM",
    "steam_api64.dlloff*249120*8/27/2020 8:34:30 AM",
    "steam_appid.txt*6*8/27/2020 8:35:05 AM",
    9780006,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\ALWD_Data\\Resources*0*9/13/2020 9:37:12 PM",
    "unity default resources*3721196*8/27/2020 8:34:27 AM",
    "unity_builtin_extra*350476*8/27/2020 8:34:27 AM",
    4071672,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\ALWD_Data\\StreamingAssets*0*9/13/2020 9:37:16 PM",
    "leaderboard.txt*245*8/27/2020 8:35:04 AM",
    "Master.bank*28561472*8/27/2020 12:20:38 PM",
    "Master.strings.bank*4854*8/27/2020 12:20:58 PM",
    "subscriptions.txt*0*9/11/2020 1:09:44 PM",
    28566571,
    "336"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\ALWD_Data\\StreamingAssets\\Screenshots*0*9/13/2020 9:37:14 PM",
    "feedback_screenshot.jpg*151276*8/27/2020 12:20:59 PM",
    "feedback_screenshot.png*2236363*8/27/2020 12:20:38 PM",
    2387639,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\MonoBleedingEdge*0*9/13/2020 9:38:50 PM",
    0,
    "338*339"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\MonoBleedingEdge\\EmbedRuntime*0*9/13/2020 11:22:04 PM",
    "mono-2.0-bdwgc.dll*4946376*8/27/2020 8:34:27 AM",
    "MonoPosixHelper.dll*780288*8/27/2020 8:34:27 AM",
    5726664,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\MonoBleedingEdge\\etc*0*9/13/2020 9:38:08 PM",
    0,
    "340"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\MonoBleedingEdge\\etc\\mono*0*9/13/2020 9:38:57 PM",
    "browscap.ini*311984*8/27/2020 8:34:27 AM",
    "config*3288*8/27/2020 8:35:05 AM",
    315272,
    "341*343*345*347"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\MonoBleedingEdge\\etc\\mono\\2.0*0*9/13/2020 9:39:19 PM",
    "DefaultWsdlHelpGenerator.aspx*60575*8/27/2020 8:35:04 AM",
    "machine.config*29116*8/27/2020 8:35:04 AM",
    "settings.map*2622*8/27/2020 8:35:04 AM",
    "web.config*11686*8/27/2020 8:35:04 AM",
    103999,
    "342"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\MonoBleedingEdge\\etc\\mono\\2.0\\Browsers*0*9/13/2020 9:38:16 PM",
    "Compat.browser*1605*8/27/2020 8:35:04 AM",
    1605,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\MonoBleedingEdge\\etc\\mono\\4.0*0*9/13/2020 9:39:38 PM",
    "DefaultWsdlHelpGenerator.aspx*60575*8/27/2020 8:35:04 AM",
    "machine.config*33648*8/27/2020 8:35:04 AM",
    "settings.map*2622*8/27/2020 8:35:04 AM",
    "web.config*18848*8/27/2020 8:35:05 AM",
    115693,
    "344"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\MonoBleedingEdge\\etc\\mono\\4.0\\Browsers*0*9/13/2020 9:38:08 PM",
    "Compat.browser*1605*8/27/2020 8:35:04 AM",
    1605,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\MonoBleedingEdge\\etc\\mono\\4.5*0*9/13/2020 9:39:40 PM",
    "DefaultWsdlHelpGenerator.aspx*60575*8/27/2020 8:35:05 AM",
    "machine.config*34106*8/27/2020 8:35:05 AM",
    "settings.map*2622*8/27/2020 8:35:05 AM",
    "web.config*18857*8/27/2020 8:35:05 AM",
    116160,
    "346"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\MonoBleedingEdge\\etc\\mono\\4.5\\Browsers*0*9/13/2020 9:39:16 PM",
    "Compat.browser*1605*8/27/2020 8:35:05 AM",
    1605,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\A Long Way Down [2020] [1,40 Gb]\\MonoBleedingEdge\\etc\\mono\\mconfig*0*9/13/2020 9:39:21 PM",
    "config.xml*25817*8/27/2020 8:35:05 AM",
    25817,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]*0*9/13/2020 9:26:45 PM",
    0,
    "349"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME*0*9/13/2020 10:21:24 PM",
    "BtthBllt-PGME.rar*371874524*8/17/2020 11:35:35 PM",
    371874524,
    "350"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet*0*9/13/2020 9:40:38 PM",
    "Bite the Bullet.exe*650752*8/13/2020 1:15:14 PM",
    "CompucaliTV.url*118*6/22/2020 9:17:49 AM",
    "Instrucciones.txt*1043*8/13/2020 4:07:57 PM",
    "UnityCrashHandler64.exe*1460680*8/13/2020 1:15:14 PM",
    "UnityPlayer.dll*23328712*8/13/2020 1:15:14 PM",
    "WinPixEventRuntime.dll*42704*8/13/2020 1:15:50 PM",
    25484009,
    "351*355"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\Bite the Bullet_Data*0*9/13/2020 10:40:47 PM",
    "app.info*32*8/13/2020 1:15:15 PM",
    "boot.config*116*8/13/2020 1:15:14 PM",
    "data.unity3d*282656939*8/13/2020 1:15:14 PM",
    "resources.resource*90730144*8/13/2020 1:15:14 PM",
    "sharedassets10.resource*28320*8/13/2020 1:15:38 PM",
    "sharedassets11.resource*2339232*8/13/2020 1:15:14 PM",
    "sharedassets12.resource*2734336*8/13/2020 1:15:14 PM",
    "sharedassets16.resource*2110240*8/13/2020 1:15:14 PM",
    "sharedassets17.resource*3564832*8/13/2020 1:15:14 PM",
    "sharedassets2.resource*284704*8/13/2020 1:15:14 PM",
    "sharedassets20.resource*2845920*8/13/2020 1:15:14 PM",
    "sharedassets21.resource*2425216*8/13/2020 1:15:14 PM",
    "sharedassets23.resource*262976*8/13/2020 1:15:14 PM",
    "sharedassets25.resource*3515488*8/13/2020 1:15:14 PM",
    "sharedassets26.resource*2873184*8/13/2020 1:15:14 PM",
    "sharedassets27.resource*1267168*8/13/2020 1:15:14 PM",
    "sharedassets28.resource*488512*8/13/2020 1:15:14 PM",
    "sharedassets30.resource*1078496*8/13/2020 1:15:14 PM",
    "sharedassets31.resource*3403040*8/13/2020 1:15:14 PM",
    "sharedassets35.resource*1818240*8/13/2020 1:15:14 PM",
    "sharedassets36.resource*5088032*8/13/2020 1:15:14 PM",
    "sharedassets38.resource*127136*8/13/2020 1:15:48 PM",
    "sharedassets4.resource*30343680*8/13/2020 1:15:14 PM",
    "sharedassets40.resource*846208*8/13/2020 1:15:14 PM",
    "sharedassets41.resource*2668224*8/13/2020 1:15:14 PM",
    "sharedassets45.resource*2951872*8/13/2020 1:15:14 PM",
    "sharedassets47.resource*15488*8/13/2020 1:15:52 PM",
    "sharedassets50.resource*25888*8/13/2020 1:15:41 PM",
    "sharedassets57.resource*2230784*8/13/2020 1:15:14 PM",
    "sharedassets6.resource*7853824*8/13/2020 1:15:14 PM",
    "sharedassets65.resource*2725376*8/13/2020 1:15:14 PM",
    "sharedassets69.resource*59456*8/13/2020 1:15:47 PM",
    "sharedassets7.resource*10612352*8/13/2020 1:15:14 PM",
    "sharedassets8.resource*589472*8/13/2020 1:15:14 PM",
    "sharedassets9.resource*10450976*8/13/2020 1:15:14 PM",
    481015903,
    "352*353*354"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\Bite the Bullet_Data\\Managed*0*9/13/2020 11:28:54 PM",
    "Assembly-CSharp.dll*3510272*8/13/2020 1:15:14 PM",
    "Assembly-CSharp-firstpass.dll*336384*8/13/2020 1:15:14 PM",
    "ConsoleUtilsImport.dll*30208*8/13/2020 1:15:33 PM",
    "DataPlatformImport.dll*72192*8/13/2020 1:15:33 PM",
    "DOTween.dll*145408*8/13/2020 1:15:33 PM",
    "DOTween43.dll*9728*8/13/2020 1:15:31 PM",
    "DOTween46.dll*20480*8/13/2020 1:15:29 PM",
    "DOTween50.dll*5632*8/13/2020 1:15:36 PM",
    "GalaxyCSharp.dll*393216*8/13/2020 1:15:14 PM",
    "GamepadImport.dll*4608*8/13/2020 1:15:31 PM",
    "JelloPoly2Tri.dll*86528*8/13/2020 1:15:31 PM",
    "Mono.Posix.dll*212480*8/13/2020 1:15:22 PM",
    "Mono.Security.dll*309248*8/13/2020 1:15:14 PM",
    "mscorlib.dll*4069888*8/13/2020 1:15:14 PM",
    "Newtonsoft.Json.dll*488360*8/13/2020 1:15:14 PM",
    "Rewired_Core.dll*2320384*8/13/2020 1:15:14 PM",
    "Rewired_Windows.dll*948224*8/13/2020 1:15:14 PM",
    "StorageImport.dll*30208*8/13/2020 1:15:23 PM",
    "System.Configuration.dll*124928*8/13/2020 1:15:30 PM",
    "System.Core.dll*1046016*8/13/2020 1:15:14 PM",
    "System.Data.dll*2081280*8/13/2020 1:15:14 PM",
    "System.dll*2715136*8/13/2020 1:15:14 PM",
    "System.EnterpriseServices.dll*44544*8/13/2020 1:15:30 PM",
    "System.Numerics.dll*114176*8/13/2020 1:15:23 PM",
    "System.Runtime.Serialization.dll*928256*8/13/2020 1:15:14 PM",
    "System.Security.dll*184320*8/13/2020 1:15:23 PM",
    "System.ServiceModel.Internals.dll*214528*8/13/2020 1:15:24 PM",
    "System.Transactions.dll*34304*8/13/2020 1:15:32 PM",
    "System.Xml.dll*3138048*8/13/2020 1:15:14 PM",
    "System.Xml.Linq.dll*119296*8/13/2020 1:15:36 PM",
    "TextSystemsImport.dll*29696*8/13/2020 1:15:24 PM",
    "Unity.Analytics.DataPrivacy.dll*7680*8/13/2020 1:15:36 PM",
    "Unity.Postprocessing.Runtime.dll*146944*8/13/2020 1:15:36 PM",
    "Unity.TextMeshPro.dll*366080*8/13/2020 1:15:14 PM",
    "UnityEngine.AccessibilityModule.dll*11776*8/13/2020 1:15:32 PM",
    "UnityEngine.AIModule.dll*42496*8/13/2020 1:15:24 PM",
    "UnityEngine.AnimationModule.dll*137216*8/13/2020 1:15:30 PM",
    "UnityEngine.ARModule.dll*12288*8/13/2020 1:15:25 PM",
    "UnityEngine.AssetBundleModule.dll*20992*8/13/2020 1:15:36 PM",
    "UnityEngine.AudioModule.dll*58368*8/13/2020 1:15:24 PM",
    "UnityEngine.BaselibModule.dll*8192*8/13/2020 1:15:24 PM",
    "UnityEngine.ClothModule.dll*14336*8/13/2020 1:15:24 PM",
    "UnityEngine.ClusterInputModule.dll*9728*8/13/2020 1:15:24 PM",
    "UnityEngine.ClusterRendererModule.dll*8704*8/13/2020 1:15:30 PM",
    "UnityEngine.CoreModule.dll*848384*8/13/2020 1:15:14 PM",
    "UnityEngine.CrashReportingModule.dll*9216*8/13/2020 1:15:30 PM",
    "UnityEngine.DirectorModule.dll*12800*8/13/2020 1:15:25 PM",
    "UnityEngine.dll*72192*8/13/2020 1:15:36 PM",
    "UnityEngine.FileSystemHttpModule.dll*8192*8/13/2020 1:15:31 PM",
    "UnityEngine.GameCenterModule.dll*26112*8/13/2020 1:15:37 PM",
    "UnityEngine.GridModule.dll*13312*8/13/2020 1:15:31 PM",
    "UnityEngine.HotReloadModule.dll*8192*8/13/2020 1:15:25 PM",
    "UnityEngine.ImageConversionModule.dll*9728*8/13/2020 1:15:25 PM",
    "UnityEngine.IMGUIModule.dll*146944*8/13/2020 1:15:32 PM",
    "UnityEngine.InputModule.dll*11264*8/13/2020 1:15:37 PM",
    "UnityEngine.JSONSerializeModule.dll*10240*8/13/2020 1:15:31 PM",
    "UnityEngine.LocalizationModule.dll*9216*8/13/2020 1:15:32 PM",
    "UnityEngine.Networking.dll*260608*8/13/2020 1:15:31 PM",
    "UnityEngine.ParticleSystemModule.dll*127488*8/13/2020 1:15:32 PM",
    "UnityEngine.PerformanceReportingModule.dll*8704*8/13/2020 1:15:37 PM",
    "UnityEngine.Physics2DModule.dll*93184*8/13/2020 1:15:32 PM",
    "UnityEngine.PhysicsModule.dll*84992*8/13/2020 1:15:37 PM",
    "UnityEngine.ProfilerModule.dll*8192*8/13/2020 1:15:31 PM",
    "UnityEngine.ScreenCaptureModule.dll*9216*8/13/2020 1:15:25 PM",
    "UnityEngine.SharedInternalsModule.dll*19456*8/13/2020 1:15:37 PM",
    "UnityEngine.SpatialTracking.dll*11264*8/13/2020 1:15:25 PM",
    "UnityEngine.SpriteMaskModule.dll*9728*8/13/2020 1:15:31 PM",
    "UnityEngine.SpriteShapeModule.dll*9728*8/13/2020 1:15:37 PM",
    "UnityEngine.StreamingModule.dll*8704*8/13/2020 1:15:33 PM",
    "UnityEngine.StyleSheetsModule.dll*16384*8/13/2020 1:15:31 PM",
    "UnityEngine.SubstanceModule.dll*12800*8/13/2020 1:15:26 PM",
    "UnityEngine.TerrainModule.dll*62976*8/13/2020 1:15:37 PM",
    "UnityEngine.TerrainPhysicsModule.dll*8704*8/13/2020 1:15:25 PM",
    "UnityEngine.TextCoreModule.dll*32256*8/13/2020 1:15:25 PM",
    "UnityEngine.TextRenderingModule.dll*26624*8/13/2020 1:15:37 PM",
    "UnityEngine.TilemapModule.dll*24064*8/13/2020 1:15:33 PM",
    "UnityEngine.Timeline.dll*98816*8/13/2020 1:15:33 PM",
    "UnityEngine.TimelineModule.dll*8192*8/13/2020 1:15:25 PM",
    "UnityEngine.TLSModule.dll*8192*8/13/2020 1:15:25 PM",
    "UnityEngine.UI.dll*253952*8/13/2020 1:15:31 PM",
    "UnityEngine.UIElementsModule.dll*351744*8/13/2020 1:15:14 PM",
    "UnityEngine.UIModule.dll*22016*8/13/2020 1:15:25 PM",
    "UnityEngine.UmbraModule.dll*8192*8/13/2020 1:15:37 PM",
    "UnityEngine.UNETModule.dll*77824*8/13/2020 1:15:31 PM",
    "UnityEngine.UnityAnalyticsModule.dll*24064*8/13/2020 1:15:39 PM",
    "UnityEngine.UnityConnectModule.dll*10240*8/13/2020 1:15:31 PM",
    "UnityEngine.UnityTestProtocolModule.dll*8192*8/13/2020 1:15:25 PM",
    "UnityEngine.UnityWebRequestAssetBundleModule.dll*11264*8/13/2020 1:15:31 PM",
    "UnityEngine.UnityWebRequestAudioModule.dll*10752*8/13/2020 1:15:37 PM",
    "UnityEngine.UnityWebRequestModule.dll*41984*8/13/2020 1:15:33 PM",
    "UnityEngine.UnityWebRequestTextureModule.dll*10240*8/13/2020 1:15:31 PM",
    "UnityEngine.UnityWebRequestWWWModule.dll*19968*8/13/2020 1:15:31 PM",
    "UnityEngine.VehiclesModule.dll*11776*8/13/2020 1:15:31 PM",
    "UnityEngine.VFXModule.dll*27136*8/13/2020 1:15:31 PM",
    "UnityEngine.VideoModule.dll*26624*8/13/2020 1:15:33 PM",
    "UnityEngine.VRModule.dll*30720*8/13/2020 1:15:37 PM",
    "UnityEngine.WindModule.dll*9216*8/13/2020 1:15:31 PM",
    "UnityEngine.XRModule.dll*44544*8/13/2020 1:15:32 PM",
    "UnityEtx.dll*27136*8/13/2020 1:15:31 PM",
    "UnityPluginLogImport.dll*8192*8/13/2020 1:15:25 PM",
    "UsersImport.dll*41984*8/13/2020 1:15:26 PM",
    "XboxOneCommonImport.dll*28160*8/13/2020 1:15:26 PM",
    27854760,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\Bite the Bullet_Data\\Plugins*0*9/13/2020 9:51:46 PM",
    "Rewired_DirectInput.dll*12800*8/13/2020 1:15:25 PM",
    "steam_api64.dll*1711616*3/15/2018 1:20:03 AM",
    "steam_api64.dlloff*289568*8/13/2020 1:15:14 PM",
    "SteamConfig.ini*1175*8/13/2020 4:06:51 PM",
    "Steamworks.NET.txt*239*8/13/2020 1:15:31 PM",
    2015398,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\Bite the Bullet_Data\\Resources*0*9/13/2020 9:51:43 PM",
    "unity default resources*3721196*8/13/2020 1:15:14 PM",
    3721196,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\MonoBleedingEdge*0*9/13/2020 9:40:31 PM",
    0,
    "356*357"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\MonoBleedingEdge\\EmbedRuntime*0*9/13/2020 9:40:39 PM",
    "mono-2.0-bdwgc.dll*4946376*8/13/2020 1:15:14 PM",
    "MonoPosixHelper.dll*780288*8/13/2020 1:15:14 PM",
    5726664,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\MonoBleedingEdge\\etc*0*9/13/2020 9:26:45 PM",
    0,
    "358"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\MonoBleedingEdge\\etc\\mono*0*9/13/2020 9:27:59 PM",
    "browscap.ini*311984*8/13/2020 1:15:14 PM",
    "config*3288*8/13/2020 1:15:52 PM",
    315272,
    "359*361*363*365"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\MonoBleedingEdge\\etc\\mono\\2.0*0*9/13/2020 9:40:33 PM",
    "DefaultWsdlHelpGenerator.aspx*60575*8/13/2020 1:15:55 PM",
    "machine.config*29116*8/13/2020 1:15:44 PM",
    "settings.map*2622*8/13/2020 1:15:45 PM",
    "web.config*11686*8/13/2020 1:15:45 PM",
    103999,
    "360"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\MonoBleedingEdge\\etc\\mono\\2.0\\Browsers*0*9/13/2020 9:40:32 PM",
    "Compat.browser*1605*8/13/2020 1:15:55 PM",
    1605,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\MonoBleedingEdge\\etc\\mono\\4.0*0*9/13/2020 9:29:05 PM",
    "DefaultWsdlHelpGenerator.aspx*60575*8/13/2020 1:15:55 PM",
    "machine.config*33648*8/13/2020 1:15:49 PM",
    "settings.map*2622*8/13/2020 1:15:45 PM",
    "web.config*18848*8/13/2020 1:15:45 PM",
    115693,
    "362"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\MonoBleedingEdge\\etc\\mono\\4.0\\Browsers*0*9/13/2020 9:29:22 PM",
    "Compat.browser*1605*8/13/2020 1:15:55 PM",
    1605,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\MonoBleedingEdge\\etc\\mono\\4.5*0*9/13/2020 9:29:15 PM",
    "DefaultWsdlHelpGenerator.aspx*60575*8/13/2020 1:15:55 PM",
    "machine.config*34106*8/13/2020 1:15:49 PM",
    "settings.map*2622*8/13/2020 1:15:45 PM",
    "web.config*18857*8/13/2020 1:15:49 PM",
    116160,
    "364"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\MonoBleedingEdge\\etc\\mono\\4.5\\Browsers*0*9/13/2020 9:27:00 PM",
    "Compat.browser*1605*8/13/2020 1:15:55 PM",
    1605,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Bite the Bullet [354,65 Mb]\\BtthBllt-PGME\\Bite the Bullet\\MonoBleedingEdge\\etc\\mono\\mconfig*0*9/13/2020 9:27:11 PM",
    "config.xml*25817*8/13/2020 1:15:53 PM",
    25817,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Gleamlight (2020) PC Full Español [386,78 Mb]*0*9/13/2020 9:29:02 PM",
    "17GLEM-PGM.rar*405566764*8/26/2020 4:11:58 AM",
    "Leeme.txt*48*4/7/2015 1:03:37 PM",
    405566812,
    "367"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Gleamlight (2020) PC Full Español [386,78 Mb]\\Gleamlight-PLAZA*0*9/13/2020 10:36:08 PM",
    "CompucaliTV.url*118*6/22/2020 9:17:49 AM",
    "Gleamlight-PLAZA.iso*487129088*8/21/2020 10:43:47 AM",
    "Instrucciones de Instalación.txt*1323*8/21/2020 11:10:20 AM",
    487130529,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\The Ambassador Fractured Timelines [306,84 Mb]*0*9/13/2020 9:27:17 PM",
    0,
    "369"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\The Ambassador Fractured Timelines [306,84 Mb]\\13TEMBA-PGM*0*9/13/2020 9:29:30 PM",
    "13TEMBA-PGM.rar*321740046*8/26/2020 1:06:36 AM",
    "Leeme.txt*48*4/7/2015 1:03:37 PM",
    321740094,
    "370"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\The Ambassador Fractured Timelines [306,84 Mb]\\13TEMBA-PGM\\The.Ambassador.Fractured.Timelines.Build-5404110*0*9/13/2020 10:42:28 PM",
    "CompucaliTV.url*118*6/22/2020 9:17:49 AM",
    "data.win*525902208*8/14/2020 3:27:36 PM",
    "Instrucciones.txt*1261*12/29/2018 11:13:22 PM",
    "options.ini*154*8/14/2020 3:27:54 PM",
    "steam_api.dll*1256960*3/15/2018 5:22:07 AM",
    "steam_api.dlloff*259360*8/14/2020 3:27:47 PM",
    "SteamConfig.ini*1140*8/20/2020 10:52:06 AM",
    "The Ambassador Fractured Timelines.exe*10334720*8/14/2020 3:27:36 PM",
    537755921,
    "371"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\The Ambassador Fractured Timelines [306,84 Mb]\\13TEMBA-PGM\\The.Ambassador.Fractured.Timelines.Build-5404110\\Profile*0*9/13/2020 9:28:18 PM",
    0,
    "372"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\The Ambassador Fractured Timelines [306,84 Mb]\\13TEMBA-PGM\\The.Ambassador.Fractured.Timelines.Build-5404110\\Profile\\MASTER*0*9/13/2020 9:28:18 PM",
    "SteamUserID.cfg*61*8/20/2020 10:52:11 AM",
    61,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]*0*9/13/2020 9:38:01 PM",
    "Instrucciones.txt*987*8/29/2020 1:58:36 PM",
    "TransportInc.exe*650752*8/28/2020 11:41:47 AM",
    "Transport-INC-2020-PC-imagen-001.jpg*52042*9/1/2020 2:45:53 AM",
    "Transport-INC-2020-PC-imagen-002.jpg*55440*9/1/2020 2:45:56 AM",
    "Transport-INC-2020-PC-imagen-003.jpg*41147*9/1/2020 2:45:58 AM",
    "Transport-INC-2020-PC-portada.jpg*36560*9/1/2020 2:46:00 AM",
    "UnityCrashHandler64.exe*1644488*8/28/2020 11:23:14 AM",
    "UnityPlayer.dll*24345544*8/28/2020 11:23:14 AM",
    26826960,
    "374*385"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\MonoBleedingEdge*0*9/13/2020 9:27:59 PM",
    0,
    "375*376"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\MonoBleedingEdge\\EmbedRuntime*0*9/13/2020 9:28:17 PM",
    "mono-2.0-bdwgc.dll*4953032*8/28/2020 11:23:14 AM",
    "MonoPosixHelper.dll*781312*8/28/2020 11:23:14 AM",
    5734344,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\MonoBleedingEdge\\etc*0*9/13/2020 9:27:59 PM",
    0,
    "377"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\MonoBleedingEdge\\etc\\mono*0*9/13/2020 9:38:05 PM",
    "browscap.ini*311984*8/28/2020 11:23:14 AM",
    "config*3276*8/28/2020 11:23:16 AM",
    315260,
    "378*380*382*384"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\MonoBleedingEdge\\etc\\mono\\2.0*0*9/13/2020 9:38:05 PM",
    "DefaultWsdlHelpGenerator.aspx*60575*8/28/2020 11:23:16 AM",
    "machine.config*29116*8/28/2020 11:23:16 AM",
    "settings.map*2622*8/28/2020 11:23:16 AM",
    "web.config*11686*8/28/2020 11:23:16 AM",
    103999,
    "379"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\MonoBleedingEdge\\etc\\mono\\2.0\\Browsers*0*9/13/2020 9:38:05 PM",
    "Compat.browser*1605*8/28/2020 11:23:15 AM",
    1605,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\MonoBleedingEdge\\etc\\mono\\4.0*0*9/13/2020 9:38:05 PM",
    "DefaultWsdlHelpGenerator.aspx*60575*8/28/2020 11:23:16 AM",
    "machine.config*33648*8/28/2020 11:23:15 AM",
    "settings.map*2622*8/28/2020 11:23:16 AM",
    "web.config*18848*8/28/2020 11:23:16 AM",
    115693,
    "381"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\MonoBleedingEdge\\etc\\mono\\4.0\\Browsers*0*9/13/2020 9:38:01 PM",
    "Compat.browser*1605*8/28/2020 11:23:15 AM",
    1605,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\MonoBleedingEdge\\etc\\mono\\4.5*0*9/13/2020 9:38:05 PM",
    "DefaultWsdlHelpGenerator.aspx*60575*8/28/2020 11:23:16 AM",
    "machine.config*34106*8/28/2020 11:23:16 AM",
    "settings.map*2622*8/28/2020 11:23:16 AM",
    "web.config*18857*8/28/2020 11:23:17 AM",
    116160,
    "383"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\MonoBleedingEdge\\etc\\mono\\4.5\\Browsers*0*9/13/2020 9:38:05 PM",
    "Compat.browser*1605*8/28/2020 11:23:15 AM",
    1605,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\MonoBleedingEdge\\etc\\mono\\mconfig*0*9/13/2020 9:37:41 PM",
    "config.xml*25817*8/28/2020 11:23:16 AM",
    25817,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\TransportInc_Data*0*9/13/2020 11:11:36 PM",
    "app.info*20*8/28/2020 11:23:16 AM",
    "boot.config*123*8/28/2020 11:23:17 AM",
    "globalgamemanagers*66624*8/28/2020 11:23:16 AM",
    "globalgamemanagers.assets*103424*8/28/2020 11:23:16 AM",
    "globalgamemanagers.assets.resS*613128*8/28/2020 11:23:14 AM",
    "level0*5676*8/28/2020 11:23:16 AM",
    "level1*1179136*8/28/2020 11:23:14 AM",
    "level2*575464*8/28/2020 11:23:14 AM",
    "resources.assets*44800*8/28/2020 11:23:20 AM",
    "resources.assets.resS*52224*8/28/2020 11:23:18 AM",
    "resources.resource*100400192*8/28/2020 11:23:14 AM",
    "ScreenSelector.png*136411*8/28/2020 11:23:42 AM",
    "sharedassets0.assets*4940*8/28/2020 11:23:32 AM",
    "sharedassets0.resource*280151*8/28/2020 11:23:14 AM",
    "sharedassets1.assets*1181532*8/28/2020 11:23:14 AM",
    "sharedassets1.assets.resS*299607512*8/28/2020 11:23:14 AM",
    "sharedassets1.resource*3701056*8/28/2020 11:23:14 AM",
    "sharedassets2.assets*1990620*8/28/2020 11:23:14 AM",
    "sharedassets2.assets.resS*189746816*8/28/2020 11:23:14 AM",
    "sharedassets2.resource*4659520*8/28/2020 11:23:14 AM",
    604349369,
    "386*387*388*389"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\TransportInc_Data\\Managed*0*9/13/2020 11:17:48 PM",
    "Adaptos.dll*23040*8/28/2020 11:23:16 AM",
    "Assembly-CSharp.dll*416768*8/28/2020 11:23:14 AM",
    "Assembly-CSharp-firstpass.dll*328192*8/28/2020 11:23:14 AM",
    "DOTween.dll*159744*8/28/2020 11:23:16 AM",
    "GameLogic.dll*232960*8/28/2020 11:23:16 AM",
    "Mono.Security.dll*310272*8/28/2020 11:23:14 AM",
    "mscorlib.dll*3905024*8/28/2020 11:23:14 AM",
    "netstandard.dll*84992*8/28/2020 11:23:16 AM",
    "PlayFab.dll*730112*8/28/2020 11:23:14 AM",
    "System.ComponentModel.Composition.dll*247808*8/28/2020 11:23:16 AM",
    "System.Configuration.dll*43008*8/28/2020 11:23:17 AM",
    "System.Core.dll*1057792*8/28/2020 11:23:14 AM",
    "System.Data.dll*1941504*8/28/2020 11:23:14 AM",
    "System.Diagnostics.StackTrace.dll*6656*8/28/2020 11:23:17 AM",
    "System.dll*2141696*8/28/2020 11:23:14 AM",
    "System.Drawing.dll*184320*8/28/2020 11:23:17 AM",
    "System.EnterpriseServices.dll*33280*8/28/2020 11:23:17 AM",
    "System.Globalization.Extensions.dll*6144*8/28/2020 11:23:17 AM",
    "System.IO.Compression.dll*98816*8/28/2020 11:23:17 AM",
    "System.IO.Compression.FileSystem.dll*23040*8/28/2020 11:23:17 AM",
    "System.Net.Http.dll*114688*8/28/2020 11:23:17 AM",
    "System.Numerics.dll*114176*8/28/2020 11:23:17 AM",
    "System.Runtime.Serialization.dll*840704*8/28/2020 11:23:14 AM",
    "System.Runtime.Serialization.Xml.dll*7168*8/28/2020 11:23:18 AM",
    "System.ServiceModel.Internals.dll*218112*8/28/2020 11:23:18 AM",
    "System.Transactions.dll*33280*8/28/2020 11:23:17 AM",
    "System.Xml.dll*2414592*8/28/2020 11:23:14 AM",
    "System.Xml.Linq.dll*119296*8/28/2020 11:23:17 AM",
    "System.Xml.XPath.XDocument.dll*5120*8/28/2020 11:23:17 AM",
    "Unity.Analytics.DataPrivacy.dll*7680*8/28/2020 11:23:17 AM",
    "Unity.TextMeshPro.dll*331776*8/28/2020 11:23:14 AM",
    "Unity.Timeline.dll*107008*8/28/2020 11:23:18 AM",
    "UnityEngine.AccessibilityModule.dll*11776*8/28/2020 11:23:17 AM",
    "UnityEngine.AccessibilityModule.dll.mdb*1208*8/28/2020 11:23:17 AM",
    "UnityEngine.AIModule.dll*44544*8/28/2020 11:23:17 AM",
    "UnityEngine.AIModule.dll.mdb*10652*8/28/2020 11:23:17 AM",
    "UnityEngine.AndroidJNIModule.dll*56832*8/28/2020 11:23:18 AM",
    "UnityEngine.AndroidJNIModule.dll.mdb*29394*8/28/2020 11:23:17 AM",
    "UnityEngine.AnimationModule.dll*144896*8/28/2020 11:23:18 AM",
    "UnityEngine.AnimationModule.dll.mdb*54457*8/28/2020 11:23:17 AM",
    "UnityEngine.ARModule.dll*12800*8/28/2020 11:23:18 AM",
    "UnityEngine.ARModule.dll.mdb*1835*8/28/2020 11:23:17 AM",
    "UnityEngine.AssetBundleModule.dll*20992*8/28/2020 11:23:17 AM",
    "UnityEngine.AssetBundleModule.dll.mdb*4448*8/28/2020 11:23:17 AM",
    "UnityEngine.AudioModule.dll*55296*8/28/2020 11:23:18 AM",
    "UnityEngine.AudioModule.dll.mdb*13935*8/28/2020 11:23:17 AM",
    "UnityEngine.ClothModule.dll*14848*8/28/2020 11:23:18 AM",
    "UnityEngine.ClothModule.dll.mdb*1765*8/28/2020 11:23:18 AM",
    "UnityEngine.ClusterInputModule.dll*9728*8/28/2020 11:23:17 AM",
    "UnityEngine.ClusterInputModule.dll.mdb*112*8/28/2020 11:23:17 AM",
    "UnityEngine.ClusterRendererModule.dll*8704*8/28/2020 11:23:18 AM",
    "UnityEngine.ClusterRendererModule.dll.mdb*112*8/28/2020 11:23:17 AM",
    "UnityEngine.CoreModule.dll*912896*8/28/2020 11:23:14 AM",
    "UnityEngine.CoreModule.dll.mdb*369147*8/28/2020 11:23:14 AM",
    "UnityEngine.CrashReportingModule.dll*9216*8/28/2020 11:23:18 AM",
    "UnityEngine.CrashReportingModule.dll.mdb*287*8/28/2020 11:23:17 AM",
    "UnityEngine.DirectorModule.dll*12800*8/28/2020 11:23:17 AM",
    "UnityEngine.DirectorModule.dll.mdb*1158*8/28/2020 11:23:18 AM",
    "UnityEngine.dll*81920*8/28/2020 11:23:17 AM",
    "UnityEngine.dll.mdb*485*8/28/2020 11:23:17 AM",
    "UnityEngine.DSPGraphModule.dll*17920*8/28/2020 11:23:18 AM",
    "UnityEngine.DSPGraphModule.dll.mdb*959*8/28/2020 11:23:18 AM",
    "UnityEngine.FileSystemHttpModule.dll*8192*8/28/2020 11:23:17 AM",
    "UnityEngine.FileSystemHttpModule.dll.mdb*112*8/28/2020 11:23:18 AM",
    "UnityEngine.GameCenterModule.dll*26624*8/28/2020 11:23:18 AM",
    "UnityEngine.GameCenterModule.dll.mdb*9830*8/28/2020 11:23:18 AM",
    "UnityEngine.GridModule.dll*13312*8/28/2020 11:23:17 AM",
    "UnityEngine.GridModule.dll.mdb*596*8/28/2020 11:23:17 AM",
    "UnityEngine.HotReloadModule.dll*8192*8/28/2020 11:23:17 AM",
    "UnityEngine.HotReloadModule.dll.mdb*112*8/28/2020 11:23:18 AM",
    "UnityEngine.ImageConversionModule.dll*9728*8/28/2020 11:23:18 AM",
    "UnityEngine.ImageConversionModule.dll.mdb*419*8/28/2020 11:23:18 AM",
    "UnityEngine.IMGUIModule.dll*149504*8/28/2020 11:23:17 AM",
    "UnityEngine.IMGUIModule.dll.mdb*91836*8/28/2020 11:23:18 AM",
    "UnityEngine.InputLegacyModule.dll*25600*8/28/2020 11:23:17 AM",
    "UnityEngine.InputLegacyModule.dll.mdb*6144*8/28/2020 11:23:18 AM",
    "UnityEngine.InputModule.dll*11776*8/28/2020 11:23:18 AM",
    "UnityEngine.InputModule.dll.mdb*1075*8/28/2020 11:23:18 AM",
    "UnityEngine.JSONSerializeModule.dll*10240*8/28/2020 11:23:18 AM",
    "UnityEngine.JSONSerializeModule.dll.mdb*677*8/28/2020 11:23:18 AM",
    "UnityEngine.LocalizationModule.dll*9216*8/28/2020 11:23:18 AM",
    "UnityEngine.LocalizationModule.dll.mdb*308*8/28/2020 11:23:18 AM",
    "UnityEngine.ParticleSystemModule.dll*129536*8/28/2020 11:23:18 AM",
    "UnityEngine.ParticleSystemModule.dll.mdb*21821*8/28/2020 11:23:18 AM",
    "UnityEngine.PerformanceReportingModule.dll*8704*8/28/2020 11:23:18 AM",
    "UnityEngine.PerformanceReportingModule.dll.mdb*112*8/28/2020 11:23:18 AM",
    "UnityEngine.Physics2DModule.dll*106496*8/28/2020 11:23:18 AM",
    "UnityEngine.Physics2DModule.dll.mdb*31886*8/28/2020 11:23:18 AM",
    "UnityEngine.PhysicsModule.dll*88064*8/28/2020 11:23:17 AM",
    "UnityEngine.PhysicsModule.dll.mdb*24491*8/28/2020 11:23:17 AM",
    "UnityEngine.ProfilerModule.dll*8192*8/28/2020 11:23:18 AM",
    "UnityEngine.ProfilerModule.dll.mdb*112*8/28/2020 11:23:18 AM",
    "UnityEngine.Purchasing.dll*27648*8/28/2020 11:23:18 AM",
    "UnityEngine.ScreenCaptureModule.dll*9216*8/28/2020 11:23:18 AM",
    "UnityEngine.ScreenCaptureModule.dll.mdb*577*8/28/2020 11:23:18 AM",
    "UnityEngine.SharedInternalsModule.dll*19456*8/28/2020 11:23:18 AM",
    "UnityEngine.SharedInternalsModule.dll.mdb*6002*8/28/2020 11:23:18 AM",
    "UnityEngine.SpriteMaskModule.dll*9728*8/28/2020 11:23:18 AM",
    "UnityEngine.SpriteMaskModule.dll.mdb*112*8/28/2020 11:23:18 AM",
    "UnityEngine.SpriteShapeModule.dll*12800*8/28/2020 11:23:18 AM",
    "UnityEngine.SpriteShapeModule.dll.mdb*1086*8/28/2020 11:23:18 AM",
    "UnityEngine.StreamingModule.dll*9216*8/28/2020 11:23:18 AM",
    "UnityEngine.StreamingModule.dll.mdb*112*8/28/2020 11:23:18 AM",
    "UnityEngine.SubstanceModule.dll*13312*8/28/2020 11:23:18 AM",
    "UnityEngine.SubstanceModule.dll.mdb*2860*8/28/2020 11:23:18 AM",
    "UnityEngine.TerrainModule.dll*69632*8/28/2020 11:23:18 AM",
    "UnityEngine.TerrainModule.dll.mdb*24353*8/28/2020 11:23:17 AM",
    "UnityEngine.TerrainPhysicsModule.dll*9216*8/28/2020 11:23:18 AM",
    "UnityEngine.TerrainPhysicsModule.dll.mdb*315*8/28/2020 11:23:18 AM",
    "UnityEngine.TextCoreModule.dll*179712*8/28/2020 11:23:18 AM",
    "UnityEngine.TextCoreModule.dll.mdb*93646*8/28/2020 11:23:18 AM",
    "UnityEngine.TextRenderingModule.dll*27136*8/28/2020 11:23:18 AM",
    "UnityEngine.TextRenderingModule.dll.mdb*5540*8/28/2020 11:23:20 AM",
    "UnityEngine.TilemapModule.dll*24576*8/28/2020 11:23:20 AM",
    "UnityEngine.TilemapModule.dll.mdb*6124*8/28/2020 11:23:20 AM",
    "UnityEngine.TLSModule.dll*8192*8/28/2020 11:23:20 AM",
    "UnityEngine.TLSModule.dll.mdb*112*8/28/2020 11:23:18 AM",
    "UnityEngine.UI.dll*224256*8/28/2020 11:23:20 AM",
    "UnityEngine.UIElementsModule.dll*576000*8/28/2020 11:23:14 AM",
    "UnityEngine.UIElementsModule.dll.mdb*368474*8/28/2020 11:23:14 AM",
    "UnityEngine.UIModule.dll*22016*8/28/2020 11:23:18 AM",
    "UnityEngine.UIModule.dll.mdb*4151*8/28/2020 11:23:20 AM",
    "UnityEngine.UmbraModule.dll*8192*8/28/2020 11:23:20 AM",
    "UnityEngine.UmbraModule.dll.mdb*112*8/28/2020 11:23:18 AM",
    "UnityEngine.UNETModule.dll*77312*8/28/2020 11:23:18 AM",
    "UnityEngine.UNETModule.dll.mdb*27757*8/28/2020 11:23:18 AM",
    "UnityEngine.UnityAnalyticsModule.dll*28672*8/28/2020 11:23:20 AM",
    "UnityEngine.UnityAnalyticsModule.dll.mdb*7612*8/28/2020 11:23:20 AM",
    "UnityEngine.UnityConnectModule.dll*10240*8/28/2020 11:23:18 AM",
    "UnityEngine.UnityConnectModule.dll.mdb*339*8/28/2020 11:23:20 AM",
    "UnityEngine.UnityTestProtocolModule.dll*8192*8/28/2020 11:23:20 AM",
    "UnityEngine.UnityTestProtocolModule.dll.mdb*112*8/28/2020 11:23:18 AM",
    "UnityEngine.UnityWebRequestAssetBundleModule.dll*11264*8/28/2020 11:23:18 AM",
    "UnityEngine.UnityWebRequestAssetBundleModule.dll.mdb*1852*8/28/2020 11:23:18 AM",
    "UnityEngine.UnityWebRequestAudioModule.dll*10752*8/28/2020 11:23:18 AM",
    "UnityEngine.UnityWebRequestAudioModule.dll.mdb*1275*8/28/2020 11:23:20 AM",
    "UnityEngine.UnityWebRequestModule.dll*42496*8/28/2020 11:23:20 AM",
    "UnityEngine.UnityWebRequestModule.dll.mdb*21704*8/28/2020 11:23:18 AM",
    "UnityEngine.UnityWebRequestTextureModule.dll*10240*8/28/2020 11:23:18 AM",
    "UnityEngine.UnityWebRequestTextureModule.dll.mdb*1170*8/28/2020 11:23:18 AM",
    "UnityEngine.UnityWebRequestWWWModule.dll*19968*8/28/2020 11:23:18 AM",
    "UnityEngine.UnityWebRequestWWWModule.dll.mdb*5000*8/28/2020 11:23:18 AM",
    "UnityEngine.VehiclesModule.dll*12288*8/28/2020 11:23:20 AM",
    "UnityEngine.VehiclesModule.dll.mdb*996*8/28/2020 11:23:18 AM",
    "UnityEngine.VFXModule.dll*33792*8/28/2020 11:23:20 AM",
    "UnityEngine.VFXModule.dll.mdb*7432*8/28/2020 11:23:18 AM",
    "UnityEngine.VideoModule.dll*28160*8/28/2020 11:23:20 AM",
    "UnityEngine.VideoModule.dll.mdb*3772*8/28/2020 11:23:18 AM",
    "UnityEngine.VRModule.dll*30720*8/28/2020 11:23:20 AM",
    "UnityEngine.VRModule.dll.mdb*7688*8/28/2020 11:23:18 AM",
    "UnityEngine.WindModule.dll*9216*8/28/2020 11:23:18 AM",
    "UnityEngine.WindModule.dll.mdb*112*8/28/2020 11:23:18 AM",
    "UnityEngine.XRModule.dll*78336*8/28/2020 11:23:18 AM",
    "UnityEngine.XRModule.dll.mdb*27468*8/28/2020 11:23:20 AM",
    21286614,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\TransportInc_Data\\Plugins*0*9/13/2020 9:20:03 PM",
    "steam_api64.dll*288032*8/28/2020 11:40:58 AM",
    "steam_appid.txt*6*8/28/2020 11:38:54 AM",
    "Steamworks.NET.txt*239*8/28/2020 11:23:18 AM",
    288277,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\TransportInc_Data\\Resources*0*9/13/2020 9:20:04 PM",
    "unity default resources*3828612*8/28/2020 11:23:14 AM",
    "unity_builtin_extra*325936*8/28/2020 11:23:14 AM",
    4154548,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Games\\Transport INC [2020] [671,43 Mb]\\TransportInc_Data\\StreamingAssets*0*9/13/2020 9:20:09 PM",
    "australia.map*252828*8/28/2020 11:23:55 AM",
    "Backers.txt*5796*8/28/2020 11:23:46 AM",
    "china.map*576063*8/28/2020 11:23:14 AM",
    "europe.map*1043845*8/28/2020 11:23:14 AM",
    "russia.map*660210*8/28/2020 11:23:14 AM",
    "TextDatabase.csv*504862*8/28/2020 11:23:14 AM",
    "us.map*599783*8/28/2020 11:23:14 AM",
    "world.land*34145772*8/28/2020 11:23:14 AM",
    "world.map*2932926*8/28/2020 11:23:14 AM",
    40722085,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor*0*9/13/2020 8:54:14 PM",
    0,
    "391*392*393*394*395*396*397*398*399*400*401*402*403*404*405*406*407*408*409*410*411"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Variado] Tri-line*0*9/13/2020 8:45:58 PM",
    "Otro experto en fugarse de la cárcel.mp4*27646186*9/9/2020 9:44:11 PM",
    27646186,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Variados de humor] Redmau*0*9/13/2020 8:45:56 PM",
    "Compilación de Chistes de Pepito o Jaimito  con las Monjas Parte 2.mp4*113233682*9/6/2020 2:17:07 PM",
    113233682,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] AuronPlay*0*9/13/2020 8:46:05 PM",
    "AMONG US #2 -- SOY UN SUCIO TRAIDOR.mp4*88791529*9/8/2020 5:23:07 AM",
    "ENTRENANDO A LOS ALUMNOS -- GTA V ROLEPLAY #62 - YouTube.mkv*474964145*9/8/2020 6:00:23 AM",
    "SOY INSPECTOR JEFE -- GTA V ROLEPLAY #61.mp4*398473731*9/8/2020 6:31:30 AM",
    "TIKTOK'S DE MORDOR.mp4*47475436*9/7/2020 5:15:16 PM",
    1009704841,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] Caeli*0*9/13/2020 8:46:08 PM",
    "MIS PIES Y NACHOS.mp4*56571665*9/7/2020 5:21:01 PM",
    "NO DUERMO hace 28 días por esta razón....mp4*52206398*9/9/2020 9:48:59 PM",
    108778063,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] Curiosidades con Mike*0*9/13/2020 8:46:08 PM",
    "EL JUGUETE MÁS PROHIBIDO DE 2018- Corta el Cable - Curiosidades con Mike - T4 E23.mp4*55582563*9/8/2020 4:46:14 AM",
    55582563,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] Dalas Review*0*9/13/2020 8:46:11 PM",
    "Auronplay me pide perdón por lo que me hizo en 2013 (ya está todo solucionado).mp4*102372461*9/7/2020 5:38:22 PM",
    "Kunno, el Tik Toker que perdió +1 MILLÓN de Seguidores por hacer ESTO... 😱.mp4*59210671*9/9/2020 9:54:42 PM",
    "Yo era amigo de Windy Girk... pero esto llegó demasiado lejos 😐.mp4*82437631*9/7/2020 5:28:23 PM",
    244020763,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] El Fedelobo*0*9/13/2020 8:46:12 PM",
    "Avengers- Primeros minutos con Fedelobo.mp4*362110493*9/8/2020 5:15:20 AM",
    362110493,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] El Rincón De Giorgio*0*9/13/2020 8:46:18 PM",
    "ANALIZANDO CHICA ESQUIZOFRÉNICA QUE VIVE ALUCINACIONES TERRORÍFICAS CADA DÍA.mp4*175620138*9/7/2020 6:12:18 PM",
    "LO DE MESSI Y EL BARÇA ES UN RIDÍCULO TREMENDO. ESTAS SON LAS RAZONES REALES... - Jordi Wild.mp4*192205464*9/7/2020 5:57:18 PM",
    367825602,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] elrubiusOMG*0*9/13/2020 8:46:20 PM",
    "DOBLE KILL PERFECTA - Among Us (IMPOSTOR).mp4*34375893*9/8/2020 6:36:46 AM",
    "LA MEJOR PARTIDA DE IMPOSTOR - Among Us.mp4*28381580*9/8/2020 6:33:53 AM",
    62757473,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] enchufetv*0*9/13/2020 8:46:21 PM",
    "2 Tipos de Personas.mp4*10083425*9/7/2020 6:13:15 PM",
    "Making Of- 2 Tipos de Personas.mp4*14069850*9/9/2020 9:56:37 PM",
    "PROMO- Dos Tipos de Personas.mp4*1176142*9/7/2020 6:13:23 PM",
    "PROMO- Mortal Glitch - Ep 2- Tu escudero te guiará y protegerá - YouTube.mkv*4128036*9/9/2020 9:55:12 PM",
    29457453,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] Juanpa Zurita*0*9/13/2020 8:46:21 PM",
    "Saltando de un avión de noche - Nadie lo había hecho antes.mp4*31450702*9/9/2020 9:59:16 PM",
    31450702,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] JuegaGerman*0*9/13/2020 8:46:23 PM",
    "Animaciones De Terror QUE NO TE DEJARÁN DORMIR!.mp4*25552889*9/7/2020 6:16:22 PM",
    "NUNCA MAS EN MI VIDA VUELVO A JUGAR ESTE JUEGO!!.mp4*124704658*9/9/2020 10:09:11 PM",
    "OLVIDE QUE HABIA COMPRADO ESTAS ESTUPIDECES !!.mp4*34272825*9/7/2020 6:26:15 PM",
    "SI TE RIES REINICIA EL VIDEO !!!!!!!!! - YouTube.mkv*71656951*9/7/2020 6:23:34 PM",
    256187323,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] Luisito Comunica*0*9/13/2020 8:46:25 PM",
    "Brujas reales hacen rituales en este puente... - Puente del Fraile.mp4*123540124*9/7/2020 6:40:38 PM",
    "Una foto me metió en problemas....mp4*48765505*9/7/2020 6:30:20 PM",
    172305629,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] luisitorey*0*9/13/2020 8:46:26 PM",
    "Mensaje para todos los Luisitoliebers - Por favor ayuda !! - Dios mío aquí estoy esto se acaba-.mp4*17016620*9/7/2020 6:42:07 PM",
    17016620,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] PewDiePie*0*9/13/2020 8:46:33 PM",
    "_ Green Needle __ Brainstorm _ - Which one do you hear-.mp4*43049692*9/9/2020 10:20:51 PM",
    "He has to Pick One as WIFE.mp4*132990957*9/7/2020 6:53:39 PM",
    "I Lie to YouTubers...mp4*99291555*9/9/2020 10:17:03 PM",
    "No. More. Simping.. 🛑🛑🛑 - LWIAY #00133.mp4*99548392*9/7/2020 7:01:55 PM",
    374880596,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] Stratus*0*9/13/2020 8:46:34 PM",
    "EL JUDAS DE AMONG US!! MI PRIMERA VEZ!!! - Stratus.mp4*1518244603*9/7/2020 9:09:31 PM",
    1518244603,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] TheWillyrex*0*9/13/2020 8:47:16 PM",
    "AVENGERS con VEGETTA, ANGEL y FARGAN.mp4*1025178154*9/7/2020 11:09:44 PM",
    "ESTA OPERACIÓN LE SALVO LA VIDA! FARGAN Y WILLY en SURGEON SIMULATOR 2.mp4*82957147*9/9/2020 10:34:44 PM",
    "INCREIBLE PELEA DE ANIMALES! - PLANET ZOO- AUSTRALIA.mp4*77662550*9/9/2020 10:27:43 PM",
    "👑 PRO de FALL GUYS me ENSEÑA A GANAR SIEMPRE!.mp4*172388976*9/7/2020 9:34:02 PM",
    "🔴👑 FALL GUYS - NINGUNA RISA SOLO VICTORIAS!.mp4*1103305000*9/10/2020 12:45:00 AM",
    "😈 TENGO EL PEOR KARMA DE FALL GUYS 👑.mp4*87103243*9/7/2020 9:18:50 PM",
    2548595070,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] VEGETTA777*0*9/13/2020 8:47:30 PM",
    "AMONG US -CAZANDO IMPOSTORES- 🧐.mp4*915324640*9/8/2020 2:17:32 AM",
    "AMONG US -CAZANDO IMPOSTORES- 🧐_2.mp4*915324640*9/8/2020 3:38:18 AM",
    1830649280,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] whatdafaqshow*0*9/13/2020 8:47:37 PM",
    "Broma Japonesa EXTREMA! - YouTube.mkv*29139051*9/8/2020 3:41:01 AM",
    "Esto tiene que parar....mp4*18444322*9/8/2020 3:42:37 AM",
    47583373,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] xFaRgAnx YT*0*9/13/2020 8:54:13 PM",
    "AMONG US - VICTORIA ÉPICA!.mp4*31337922*9/8/2020 4:08:11 AM",
    "BATALLA CONTRA HELICÓPTEROS DE POLICÍA! PARKOUR GTA V.mp4*149988139*9/8/2020 4:21:35 AM",
    "ESTE EQUIPO  DESTROZA - MARVEL'S AVENGERS!! c_ Vegetta, Willy y Angel..mp4*173886204*9/8/2020 4:35:50 AM",
    "OPERAMOS A OSCURAS!! - Surgeon Simulator 2 #2 c_Willy.mp4*124742193*9/8/2020 3:53:23 AM",
    "PÀRKOUR CON LA MOTO-ESPEJOS! PARKOUR GTA V.mp4*117313851*9/8/2020 4:05:34 AM",
    597268309,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Humor\\[Youtubers] YosStoP*0*9/13/2020 8:54:14 PM",
    "ME DESAPEGO DE 21 COSAS.mp4*61610901*9/8/2020 4:41:32 AM",
    61610901,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes*0*9/13/2020 9:29:49 PM",
    0,
    "413*414*415*418*425*438*460*469*472*502*534*560"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\#Got Talent Global*0*9/13/2020 8:54:16 PM",
    "AMAZING STREET DANCERS on World's Got Talent China  Got Talent Global.mp4*40279593*9/6/2020 11:18:41 PM",
    "CREEPY CONTORTIONIST Is Here To Rumble! on America's Got Talent  Got Talent Global.mp4*31649464*9/6/2020 11:19:04 PM",
    "Emotional Song Moves Judges To Tears on America's Got Talent 2020  Got Talent Global.mp4*45812570*9/6/2020 11:19:35 PM",
    "GOLDEN BUZZER Singer Roberta Battaglia At The Quarter Finals on AGT 2020  Got Talent Global.mp4*22867132*8/31/2020 9:40:00 PM",
    "Singer Gets David Foster's Golden Buzzer on Asia's Got Talent  Got Talent Global.mp4*33391219*8/31/2020 9:40:38 PM",
    "STRONG MAN Lifts Up 2 Men on His Shoulders on Got Talent Uruguay 2020  Got Talent Global.mp4*30929852*8/31/2020 9:40:42 PM",
    204929830,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\#The Voice Global*0*9/13/2020 8:54:19 PM",
    "MOST TRENDING Blind Auditions of 2019  The Voice Rewind.mp4*219545178*9/6/2020 11:21:50 PM",
    "TOP 10  BEST BLIND AUDITIONS OF 2018  The Voice Rewind.mp4*143439110*9/6/2020 11:23:19 PM",
    "UNFORGETTABLE Finalist returns in The Voice for THE CROWN   Journey #53.mp4*240218973*9/6/2020 11:25:42 PM",
    603203261,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[BAILES Y EJERCICIOS]*0*9/13/2020 8:54:22 PM",
    0,
    "416*417"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[BAILES Y EJERCICIOS]\\[Musica y Baile] Sing King Karaoke*0*9/13/2020 11:21:14 PM",
    "BTS - Dynamite (Karaoke Version).mp4*6256906*9/2/2020 5:43:20 PM",
    "Camp Rock - Gotta Find You (Karaoke Version).mp4*9160180*9/2/2020 5:43:44 PM",
    "Camp Rock - This Is Me (Karaoke Version).mp4*5736324*9/2/2020 5:44:02 PM",
    "Dan + Shay - I Should Probably Go To Bed (Karaoke Version).mp4*7954832*9/2/2020 5:43:28 PM",
    "Fleetwood Mac - Dreams (Karaoke Version).mp4*9913614*9/2/2020 5:44:44 PM",
    "Juice WRLD &amp; The Weeknd - Smile (Karaoke Version).mp4*8442759*9/2/2020 5:43:48 PM",
    "Lana Del Rey - Happiness Is A Butterfly (Karaoke Version).mp4*10492281*9/2/2020 5:44:38 PM",
    "My Chemical Romance - Welcome To The Black Parade (Karaoke Version).mp4*12103764*9/2/2020 5:44:18 PM",
    "Royal &amp; The Serpent - Overwhelmed (Karaoke Version).mp4*7544159*9/2/2020 5:44:22 PM",
    "Tate McRae - you broke me first (Karaoke Version).mp4*5393902*9/2/2020 5:44:14 PM",
    82998721,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[BAILES Y EJERCICIOS]\\[Musica y Baile] Tuikok Talent*0*9/13/2020 11:15:25 PM",
    "¿Te cuesta COORDINAR BRAZOS cuando bailas- ¡Practica estos ejercicios!.mp4*157482904*9/6/2020 2:59:10 PM",
    "5 maneras de hacer un BÁSICO 2 EN KIZOMBA - Tutorial paso a paso.mp4*139753664*9/6/2020 3:00:31 PM",
    297236568,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CHISMES Y FARANDULA]*0*9/13/2020 10:15:34 PM",
    0,
    "419*420*421*422*423*424"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CHISMES Y FARANDULA]\\[Chisme y farándula] Chicas Cosmo*0*9/13/2020 11:26:59 PM",
    "ACTRIZ DE STRANGER THINGS PREOCUPADA POR LA IMAGEN HIPERSEXUALIZADA DE SUS COMPAÑEROS.mp4*12681330*8/31/2020 7:10:18 PM",
    "ASÍ FUE COMO KYLIE JENNER DEMOSTRÓ QUE YA NO ES AMIGA DE SOFIA RICHIE.mp4*14872955*8/31/2020 7:04:16 PM",
    "BLACKPINK - 'Ice Cream (with Selena Gomez)' MV.mp4*52429029*8/31/2020 7:06:22 PM",
    "BTS (방탄소년단) 'Dynamite' Official MV.mp4*44227916*8/31/2020 7:05:34 PM",
    "CANTANTES que se TOCAN DURANTE las PRESENTACIONES.mp4*42993540*8/31/2020 7:08:12 PM",
    "CHADWICK BOSEMAN PROTAGONISTA DE BLACK PANTHER FALLECE VÍCTIMA DEL CÁNCER.mp4*17361796*8/31/2020 7:02:30 PM",
    "DOVE CAMERON RESPONDE A LOS FANS PREOCUPADOS POR SU EXTREMA DELGADEZ ASEGURA QUE SE ENCUENTRA BIEN (1).mp4*16221184*8/31/2020 7:07:04 PM",
    "DOVE CAMERON RESPONDE A LOS FANS PREOCUPADOS POR SU EXTREMA DELGADEZ ASEGURA QUE SE ENCUENTRA BIEN.mp4*16221184*8/31/2020 7:03:36 PM",
    "ENTREVISTA DONDE CHADWICK BOSEMAN LLORA AL HABLAR DE NIÑOS CON CÁNCER TERMINAL SE VUELVE VIRAL (1).mp4*16419938*8/31/2020 7:08:24 PM",
    "ENTREVISTA DONDE CHADWICK BOSEMAN LLORA AL HABLAR DE NIÑOS CON CÁNCER TERMINAL SE VUELVE VIRAL.mp4*16419938*8/31/2020 7:00:50 PM",
    "ESTA ES LA ESCENA que LILI REINHART se OBLIGÓ a HACER EN LA SERIE RIVERDALE.mp4*16812198*8/31/2020 7:06:42 PM",
    "ESTRELLAS DE MARVEL y FANS se UNEN para RENDIR HOMENAJE y DARLE EL ÚLTIMO ADIÓS a CHADWICK BOSEMAN.mp4*63474861*8/31/2020 7:05:02 PM",
    "ICE CREAM DE SELENA GOMEZ Y BLACKPINK SE VUELVE TENDENCIA Y ROMPE TODOS LOS RÉCORDS.mp4*20994563*8/31/2020 7:07:46 PM",
    "JACOB ELORDI PODRÍA TENER UN ROMANCE CON ACTOR DE 13 REASONS WHY.mp4*14520517*8/31/2020 7:07:38 PM",
    "JEFFREE STAR SE DEFIENDE DE ACUSACIONES DE ROMPE HOGARES Y DICE QUE NUNCA LE HA PAGADO A UN HOMBRE (1).mp4*61699633*8/31/2020 7:05:32 PM",
    "JEFFREE STAR SE DEFIENDE DE ACUSACIONES DE ROMPE HOGARES Y DICE QUE NUNCA LE HA PAGADO A UN HOMBRE.mp4*61699633*8/31/2020 7:03:26 PM",
    "KYLIE JENNER, DE VACACIONES EN PARÍS CON SU POSIBLE NUEVO NOVIO (1).mp4*15790982*8/31/2020 7:05:46 PM",
    "KYLIE JENNER, DE VACACIONES EN PARÍS CON SU POSIBLE NUEVO NOVIO.mp4*15790982*8/31/2020 7:03:10 PM",
    "LA RAZÓN POR LA QUE  KYLIE JENNER LE GRITA A SU MADRE DÉJAME VIVIR  EN EL NUEVO TRAILER DE KUWTK.mp4*14834555*8/31/2020 7:07:08 PM",
    "LIAM PAYNE LE PROPONE MATRIMONIO A SU NOVIA MAYA HENRY CON CARÍSIMA JOYA.mp4*43188309*8/31/2020 7:02:48 PM",
    "LOS MEJORES MOMENTOS de LOS MTV VMAs 2020 de LOS QUE TODO EL MUNDO ESTÁ HABLANDO.mp4*86207055*8/31/2020 7:05:06 PM",
    "Los WARREN POR FIN HABLAN de la DESAPARICIÓN de ANABELLE y MANDAN FUERTE MENSAJE.mp4*27870270*8/31/2020 7:06:16 PM",
    "NO CREERÁS EL SORPRENDENTE REGALO QUE ARIANA GRANDE LE HIZO A SELENA GOMEZ POR MOTIVO DEL ESTRENO.mp4*30703010*8/31/2020 7:03:04 PM",
    "NOAH SCHNAPP ES CANCELADO DESPUÉS DE QUE SURGIERA VIDEO DONDE  PARECE REALIZAR INSULTOS RACIS.mp4*18370506*8/31/2020 7:09:42 PM",
    "Rosalía Es Testigo De La Intensa Pelea Entre Khloe, Kourtney Kardashian Y Kendall Jenner.mp4*17545217*8/31/2020 7:06:08 PM",
    "SCOTT DISICK COMETE INDISCRESIÓN Y CONFIRMA QUE KHLOÉ Y TRISTAN ESTAN NUEVAMENTE JUNTOS (1).mp4*21112385*8/31/2020 7:10:38 PM",
    "SCOTT DISICK COMETE INDISCRESIÓN Y CONFIRMA QUE KHLOÉ Y TRISTAN ESTAN NUEVAMENTE JUNTOS.mp4*21112385*8/31/2020 7:01:46 PM",
    "SEBASTIÁN YATRA ROMPE EL SILENCIO y EXPLICA  por qué  TERMINÓ con TINI STOESSEL.mp4*18583792*8/31/2020 7:09:14 PM",
    "Selena Gomez and Nana cooking.mp4*33644162*8/31/2020 7:10:10 PM",
    "SELENA GOMEZ MUESTRA los ESTRAGOS que EL LUPUS ha CAUSADO en SU CUERPO.mp4*14728788*8/31/2020 7:07:08 PM",
    "SELENA GOMEZ SORPRENDE A SUS FANS AL HACER ALARDE DE SU ABDOMEN TONIFICADO EN NUEVO POSTER DE ICE.mp4*17375664*8/31/2020 7:09:32 PM",
    "SELENA HOMENAJE - Danna Paola, Natti Natasha, Karol G, Ally Brooke, Greeicy - PREMIOS JUVENTUD HD.mp4*233460866*8/31/2020 8:19:50 PM",
    "TIKTOK al BORDE de la MUER.mp4*61077972*8/31/2020 7:10:50 PM",
    "TIKTOKER REVELA CÓMO SE COMPORTAN LOS FAMOSOS EN EL AEROPUERTO Y TE SORPRENDERÁ LO GROSERO QUE SON.mp4*18527818*8/31/2020 7:07:52 PM",
    1198974933,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CHISMES Y FARANDULA]\\[Chisme y farándula] clever tv*0*9/13/2020 10:14:59 PM",
    "Estrella de Black Panther Chadwick Boseman Honrado en los VMAS 2020.mp4*35445898*8/31/2020 6:53:30 PM",
    "Lady Gaga Icónicas Mascaras, Piano de Cerebro y Premios en Los MTV VMAS 2020.mp4*27468936*8/31/2020 6:54:10 PM",
    "Liam Payne Se Casa con Maya Henry!.mp4*29517937*8/31/2020 6:54:34 PM",
    "Maluma Ganó Su Primer MTV VMA ¿Robándoselo a Bad Bunny-.mp4*45930708*8/31/2020 6:53:54 PM",
    "Maluma vs. Neymar- Duelo de Galanes por Natalia Barulich.mp4*77207522*8/31/2020 6:54:34 PM",
    "Selena Gomez Recibe ENORME Arreglo Floral de Ariana Grande.mp4*26612186*8/31/2020 6:53:56 PM",
    "Selena Gomez y Blackpink Estrenan ‘Ice Cream’ Y Rompen Records.mp4*43971792*8/31/2020 6:54:54 PM",
    286154979,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CHISMES Y FARANDULA]\\[Chisme y Farándula] El Alboroto de los famosos*0*9/13/2020 10:15:12 PM",
    "1541125255.mp4*24772432*8/31/2020 8:19:30 PM",
    "Actriz de Televisa en la ruina, sin trabajo, perdió su negocio y su ex no da pensión a su hija.mp4*39812350*8/31/2020 8:23:08 PM",
    "Alejandra Guzmán corre a sus managers y defiende a su hija Frida Sofia.mp4*45020474*8/31/2020 8:18:46 PM",
    "Andrea Legarreta lamentablemente dio positivo a la prueba.mp4*60798535*8/31/2020 8:20:08 PM",
    "Andrea Legarreta perdió el olfato y el gusto, revela como se la esta pasando.mp4*45671120*8/31/2020 8:18:56 PM",
    "Carolina Sandoval rompe en llanto en plena entrevista.mp4*43216130*8/31/2020 8:22:42 PM",
    "Comparten emotiva carta, con la que su familia se despidió de Manuel el loco Valdés.mp4*51873015*8/31/2020 8:19:02 PM",
    "De famoso comediante, a perderlo todo para poder comer.mp4*14487261*8/31/2020 8:23:28 PM",
    "Detienen al esposo de la famosa actriz Grettell Valdez.mp4*43439102*8/31/2020 8:26:30 PM",
    "Famoso periodista de Telemundo lucha para seguir con vida.mp4*23278100*8/31/2020 8:22:42 PM",
    "Galilea Montijo da fuertes declaraciones sobre los famosos de TV Azteca.mp4*25291824*8/31/2020 8:24:16 PM",
    "Guerreros 2020 supera en rating a La Voz e intentan burlarse pero todo se les voltea en Televisa.mp4*20030658*8/31/2020 8:21:58 PM",
    "Laura Bozzo le fue a llorar a ejecutivo de Televisa para que quiten demanda Gabriel e Irina.mp4*40436309*8/31/2020 8:24:26 PM",
    "Livia Brito responde tras descubrir que Ariadne Díaz le quito el trabajo.mp4*29106355*8/31/2020 8:18:06 PM",
    "Lizbeth Rodríguez descubre que Raul Araiza le pone los cuernos a su novia.mp4*65079081*8/31/2020 8:25:52 PM",
    "Mira el vídeo de Fabiola Martinez burIandose de Panini tras confirmar que con ella le fue infieI.mp4*52098202*8/31/2020 8:28:50 PM",
    "Pierde la vida la actriz Cecilia Romo.mp4*56844211*8/31/2020 8:18:18 PM",
    "Pierde la vida Manuel El Loco Valdés a sus 89 años de edad.mp4*26652527*8/31/2020 8:20:08 PM",
    "Publico se sorprende ante el cambio de Pedro Fernandez por excederse con clrugías.mp4*14798900*8/31/2020 8:21:24 PM",
    "Rompe en llanto Marcos Valdés tras contar como partió su papá, Manuel El Loco Valdés.mp4*42753831*8/31/2020 8:20:18 PM",
    "Se revela que dos conductores HOMBRES de Venga la Alegría son NOVIOS.mp4*65495618*8/31/2020 8:23:50 PM",
    "Verónica Castro a Yolanda Andrade  Si dices puras pende... mejor no digas nada.mp4*51398112*8/31/2020 8:24:48 PM",
    "Verónica Castro lamenta la partida de Manuel El Loco Valdés.mp4*46901167*8/31/2020 8:22:58 PM",
    929255314,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CHISMES Y FARANDULA]\\[Chisme y Farándula] Hola TV*0*9/13/2020 10:15:18 PM",
    "Con tres años, Gabriel de Suecia ya cumple con sus compromisos reales  ¡HOLA! TV.mp4*39432324*8/31/2020 8:26:18 PM",
    "Conoce a la princesa Melusine Ruspoli, la royal italiana que ya es una It-Girl  ¡HOLA! TV.mp4*32440395*8/31/2020 8:25:52 PM",
    "Conoce a Nicole Poturalski, la modelo que conquistó a Brad Pitt  ¡HOLA! TV.mp4*31806866*8/31/2020 8:27:28 PM",
    "De Cuba a Hollywood celebramos los 40 años de William Levy  ¡HOLA! TV.mp4*41062214*8/31/2020 8:27:02 PM",
    "El apartamento de Cate Blanchett en Sidney que te gustaría tener  ¡HOLA! TV.mp4*31256642*8/31/2020 8:25:38 PM",
    "James Middleton contó por qué los perros lo ayudaron a luchar contra la depresión  ¡HOLA! TV.mp4*45768296*8/31/2020 8:26:58 PM",
    "La chef Dore Ferriz reveló cómo fue preparar la última cena de Lady Di  ¡HOLA! TV.mp4*34485650*8/31/2020 8:28:00 PM",
    "La presentadora colombiana Andrea Jaramillo Char nos abre las puertas de su hogar  ¡HOLA! TV.mp4*39501587*8/31/2020 8:27:10 PM",
    "Los 50 años de Rania, la reina de Jordania  ¡HOLA! TV.mp4*18201657*8/31/2020 8:26:54 PM",
    "Luis Gerónimo Abreu describe su actuación en la serie “Bolívar” como un gran reto  ¡HOLA! TV.mp4*26710427*8/31/2020 8:24:40 PM",
    "Muere Saoirse, la nieta de Kennedy a los 22 años  ¡HOLA! TV.mp4*25785835*8/31/2020 8:26:32 PM",
    "Patricia Manterola contó cómo transformó su casa en una escuela durante la cuarentena  ¡HOLA! TV.mp4*41280051*8/31/2020 8:25:52 PM",
    407731944,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CHISMES Y FARANDULA]\\[Chisme y farándula] Hollywire*0*9/13/2020 10:15:33 PM",
    "Addison Rae v Bryce Hall Transformation (Then and Now).mp4*31505272*8/31/2020 8:33:02 PM",
    "Annie Leblanc Fans Want Her Back Together With Hayden Summerall!  Hollywire.mp4*47147251*8/31/2020 8:31:12 PM",
    "Bryce hall cheats on Addison with Charli !!.mp4*1592144*8/31/2020 8:33:28 PM",
    "Bryce Halls Spills On His FIRST DATE With Addison Rae!  Hollywire.mp4*78726874*8/31/2020 8:37:02 PM",
    "Charli D'Amelio Crashes Dixie D'Amelio's New Talk Show  Hollywire.mp4*50067691*8/31/2020 8:33:24 PM",
    "CHICKEN GIRLS  Season 7  Official Trailer.mp4*12821891*8/31/2020 8:31:46 PM",
    "Dixie D’Amelio and Noah Beck REACT to DATING rumors! Charli D’Amelio said THIS about them.mp4*21097788*8/31/2020 8:33:56 PM",
    "Dixie D'Amelio Throws SHADE At Chase Hudson  Hollywire.mp4*31463567*8/31/2020 8:30:38 PM",
    "EVERY Hannie Moment Since Their Breakup!.mp4*49203607*8/31/2020 8:33:20 PM",
    "Is Bryce Hall Voting For Donald Trump!  Hollywire.mp4*35002912*8/31/2020 8:29:02 PM",
    "Jake Paul Says He Is QUITTING Youtube!  Hollywire.mp4*88688047*8/31/2020 8:30:06 PM",
    "Jason Derulo Using Tik Tok Stars For Clout!  Hollywire.mp4*68616761*8/31/2020 8:30:40 PM",
    "Johnny Orlando - See You (Official Video).mp4*11977013*8/31/2020 8:32:58 PM",
    "Kelianne Stankus Reveals Jason Derulo's Nickname For Her!!  Hollywire.mp4*41222882*8/31/2020 8:33:00 PM",
    "Kelianne Stankus Says Tayler Holder Is Most Likely To Get His Heart Broken  Hollywire.mp4*26310634*8/31/2020 8:34:44 PM",
    "Moving into The Hype House Dryer.mp4*212768482*8/31/2020 8:41:22 PM",
    "payton - Love Letter (Official Music Video).mp4*11390738*8/31/2020 8:32:54 PM",
    "Proof Tayler Holder Is Dating Charly Jordan!  Hollywire.mp4*32047508*8/31/2020 8:31:00 PM",
    "Selena Gomez Releases 'Ice Cream' With BLACKPINK!!  Hollywire.mp4*82373463*8/31/2020 8:31:14 PM",
    "Selena Gomez SHOCKS Taylor Swift By Doing THIS  Hollywire.mp4*54125949*8/31/2020 8:28:44 PM",
    "The Kid Laroi Reveals Collaboration With Halsey Coming Soon!!  Hollywire.mp4*71564254*8/31/2020 8:32:22 PM",
    "The Sway House &amp; Hype House Fight It Out Over Chase Hudson &amp; Nessa Barrett Drama 7.6.20.mp4*10849074*8/31/2020 8:35:02 PM",
    "Topper Guild Wants To FIGHT Bryce Hall  Hollywire.mp4*90733458*8/31/2020 8:31:04 PM",
    "WHO KNOWS ME BETTER EX vs BFF.mp4*37142147*8/31/2020 8:35:16 PM",
    1198439407,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CHISMES Y FARANDULA]\\[Chisme y farándula] Univision*0*9/13/2020 11:32:06 PM",
    "Andrés García le dice a Raúl qué pasó con su -bombita-- -No la he podido echar a funcionar- - GYF.mp4*32836202*8/31/2020 7:01:18 PM",
    "Andrés García sostiene que la mamá de Luis Miguel está muerta por indicios que dio Luisito Rey - GYF.mp4*47580434*8/31/2020 7:00:44 PM",
    "Así fueron los últimos días de Juan Gabriel y la última vez que fue captado en cámara - GYF.mp4*39300322*8/31/2020 6:59:38 PM",
    "De rey de los corridos a rey de los duetos- El Fantasma sigue activo aunque no haya conciertos - GYF.mp4*33249724*8/31/2020 7:00:52 PM",
    "Gelena se olvida del distanciamiento social al estar cerca de Romeo Santos y Brad Pitt  - GYF.mp4*24559423*8/31/2020 6:59:54 PM",
    "Ivy Queen y Lili Estefan lanzan un llamado a la comunidad a unirse el Padrinotón - GYF.mp4*5536069*8/31/2020 6:59:14 PM",
    "Karina Banda habla de los productos de belleza que obliga a Carlos Ponce a ponerse - GYF.mp4*21828506*8/31/2020 7:00:54 PM",
    "La Rosa de Guadalupe - La infidelidad de una joven que está a punto de casarse se hace viral.mp4*56223947*8/31/2020 6:59:26 PM",
    "La Rosa de Guadalupe - Las mentiras de una mujer afectaron a toda una familia.mp4*41991408*8/31/2020 7:00:22 PM",
    "La Rosa de Guadalupe - Una madre sufre por el robo de su pequeña hija.mp4*50386666*8/31/2020 7:01:12 PM",
    "Zafo-, dice Giovanni Medina y pide que lo encierren en un manicomio si vuelve con Ninel Conde - GYF.mp4*13157894*8/31/2020 7:01:28 PM",
    366650595,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CIENCIA]*0*9/13/2020 9:46:17 PM",
    0,
    "426*427*428*429*430*431*432*433*434*435*436*437"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CIENCIA]\\[Ciencia] Academia Play*0*9/13/2020 9:45:59 PM",
    "La Canción del Pirata 🏴‍☠️ (Poema de José de Espronceda).mp4*18599054*9/10/2020 11:12:50 AM",
    18599054,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CIENCIA]\\[Ciencia] Atraviesa lo desconocido*0*9/13/2020 9:46:00 PM",
    "Científicos y la NASA Descubrieron que la Luna Hace Algo Fuera de lo Común.mp4*26404506*9/10/2020 10:47:10 AM",
    "Los Extraterrestres ya Saben que Existimos y NO son Hostiles- La Hipótesis del Zoológico.mp4*80594973*9/10/2020 10:46:56 AM",
    "Una Colosal e Inesperada Onda Gravitacional Llegó a la Tierra Desconcertando a Científicos.mp4*30775412*9/10/2020 10:47:14 AM",
    137774891,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CIENCIA]\\[Ciencia] Canal Conocimiento*0*9/13/2020 11:32:07 PM",
    "¿Por qué la Fuerza Aérea Colombiana Quiere el Gripen NG-.mp4*27593195*9/10/2020 11:11:52 AM",
    "Así Funciona el Destructor Furtivo más Avanzado del Mundo el USS Zumwalt.mp4*42290064*9/10/2020 11:11:58 AM",
    "Así Funciona el F-35 Lightning II el Proyecto Militar más Caro de Estados Unidos.mp4*68240602*9/10/2020 11:11:40 AM",
    "China tiene la Armada más Grande del Mundo ¿Podrá Derrotar a EEUU-.mp4*43579005*9/10/2020 11:11:48 AM",
    "F-22 Raptor el Mejor y más Avanzado Avion de Combate de Quinta Generacion.mp4*32112580*9/10/2020 11:11:42 AM",
    "SEDENA- Entra en Servicio el Radar de Vigilancia Aérea 100% Mexicano TZINACAN.mp4*23726802*9/10/2020 11:11:52 AM",
    237542248,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CIENCIA]\\[Ciencia] El Robot de Platón*0*9/13/2020 9:46:02 PM",
    "¿Se puede Terraformar un Agujero Negro-.mp4*24858881*9/10/2020 10:47:40 AM",
    "5 Hipótesis sobre el Origen de la Vida en la Tierra.mp4*33197209*9/10/2020 10:47:34 AM",
    58056090,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CIENCIA]\\[Ciencia] Exoplanetas*0*9/13/2020 9:46:04 PM",
    "¡Ya Comenzó! 🌌 Los Halos de Andrómeda y nuestra Galaxia la Vía Láctea ya están colisionando.mp4*12350107*9/10/2020 10:48:06 AM",
    "África se Parte nuevos datos y curioso estudio geocientífico 🤔 ¿Qué hubiera pasado sí….mp4*15645515*9/10/2020 10:48:00 AM",
    "Es la mayor Colisión de Agujeros Negros 😮 y la Onda Gravitacional más masiva detectada.mp4*26542399*9/10/2020 10:48:02 AM",
    "Noticias Ciencia 🚀 SpaceX - Autos de Hidrógeno - Megalodón - Marte - Materia Oscura - Cometa… Y más+.mp4*66942616*9/10/2020 10:47:58 AM",
    121480637,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CIENCIA]\\[Ciencia] GENIAL*0*9/13/2020 11:34:14 PM",
    "¿Qué pasa si tiras al diamante más grande al fondo de la Fosa de las Marianas-.mp4*28251633*9/10/2020 11:10:58 AM",
    "14 Lluvias extrañas que han aparecido en la Tierra.mp4*46409014*9/10/2020 11:10:30 AM",
    "37 Fenómenos naturales más espectaculares que los efectos especiales de películas.mp4*94702868*9/10/2020 11:09:28 AM",
    "3823 ES Secret Uses for Your Car Key Fob.mp4*22666970*9/10/2020 11:08:52 AM",
    "46 Datos aterradores sobre el megalodón y otras criaturas con grandes mandíbulas.mp4*60709615*9/10/2020 11:09:40 AM",
    "60+ Datos impactantes sobre el espacio que podrían asustarte y asombrarte al mismo tiempo.mp4*145230074*9/10/2020 11:09:16 AM",
    "Cosas DURAS Y FUERTES pero naturales EN EL MUNDO.mp4*40494685*9/10/2020 11:10:42 AM",
    "Durante 50 años en este pueblo no hubo ni una sola boda por una razón.mp4*37136237*9/10/2020 11:11:04 AM",
    "Guía para principiantes para ganar dinero en YouTube- consejos para quienes no tienen tiempo.mp4*27585185*9/10/2020 11:10:32 AM",
    "Los teléfonos inteligentes no tienen visión nocturna y aquí está el motivo.mp4*27247119*9/10/2020 11:10:50 AM",
    "Qué pasaría si tuvieras patas de animal.mp4*28274104*9/10/2020 11:11:08 AM",
    "Si el Kraken se encontrara con megalodón, ¿quién ganaría-.mp4*42599979*9/10/2020 11:10:46 AM",
    "Si ves agua burbujeando, aléjate de inmediato.mp4*29318099*9/10/2020 11:10:22 AM",
    "Un viaje al núcleo de la Tierra.mp4*31591082*9/10/2020 11:10:54 AM",
    "Una aldea construida dentro de un volcán activo, ¿por qué-.mp4*40694924*9/10/2020 11:11:10 AM",
    702911588,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CIENCIA]\\[Ciencia] La guia de las Vitaminas*0*9/13/2020 11:22:16 PM",
    "13 cosas que no sabías de las BENZODIACEPINAS (clonazepam, diacepam, etc) pero deberías.mp4*61142664*9/10/2020 11:24:24 AM",
    "25 cosas interesantes de LOS ZURDOS que no podrás creer.mp4*70624460*9/10/2020 11:23:50 AM",
    "7 errores inocentes que ponen EN PELIGRO TUS RIÑONES.mp4*49513950*9/10/2020 11:24:18 AM",
    "7 señales de una VEJIGA ENFERMA QUE NO PUEDES IGNORAR.mp4*55394768*9/10/2020 11:24:30 AM",
    "HOJAS DE MANGO para tu glucosa, piel, cabello, un poderoso REMEDIO NATURAL del que pocos hablan.mp4*75639432*9/10/2020 11:24:22 AM",
    "Los 15 mejores alimentos para BAJAR DE PESO PARA HOMBRES.mp4*83178026*9/10/2020 11:23:36 AM",
    "Millones tienen esta ENFERMEDAD SILENCIOSA y ni siquiera lo saben.mp4*151784012*9/10/2020 11:24:14 AM",
    "Porque si comes 5 dátiles diarios le pasa esto a tu salud.mp4*158239967*9/10/2020 11:24:44 AM",
    705517279,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CIENCIA]\\[Ciencia] NASA en Español*0*9/13/2020 9:46:14 PM",
    "Probando un propulsor de cohete para Artemis- TW@N - 4 de septiembre de 2020.mp4*22740615*9/10/2020 10:48:28 AM",
    22740615,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CIENCIA]\\[Ciencia] News Snakedos*0*9/13/2020 10:32:33 PM",
    "CAPTAN En MÉXICO Extraña ANOMALÍA o Una GIGANTESCA NAVE.mp4*42162926*9/10/2020 10:49:08 AM",
    "En Curso De COLISIÓN Con La TIERRA - ¿INCIDENTE OVNI-.mp4*37805440*9/10/2020 10:48:58 AM",
    "OVNIS Alcanzados Por MISILES Sin AFECTARLES-.mp4*31728223*9/10/2020 10:48:54 AM",
    "PILOTO De NETFLIX Graba Un OVNI INVASIÓN Silenciosa Sigue Apareciendo.mp4*28981491*9/10/2020 10:48:48 AM",
    "PILOTO Informa Sobre HOMBRE VOLANDO a 1000 Metros De Altura.mp4*29533531*9/10/2020 10:49:14 AM",
    "VENUS Muestra ESTRUCTURAS ALIENÍGENAS y NAVES.mp4*30708199*9/10/2020 10:49:10 AM",
    200919810,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CIENCIA]\\[Ciencia] QuantumFracture*0*9/13/2020 9:46:17 PM",
    "Por qué esta Fusión de Agujeros Negros podría Comenzar a Cambiar la Astrofísica.mp4*20624472*9/10/2020 11:12:32 AM",
    "Te Demuestro que 1=0 (de 6 formas distintas).mp4*19359416*9/10/2020 11:12:14 AM",
    39983888,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CIENCIA]\\[Ciencia] Robotitus*0*9/13/2020 11:29:28 PM",
    "La OMS declara a África Libre de la Polio.mp4*27253088*9/10/2020 11:07:56 AM",
    "Vacuna de Oxford en Pausa luego de una Reacción Adversa.mp4*25153732*9/10/2020 11:08:04 AM",
    52406820,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CIENCIA]\\[Ciencia] SpaceX*0*9/13/2020 11:29:29 PM",
    "SAOCOM 1B - Launch and Landing - YouTube.mkv*5184808*9/10/2020 10:49:42 AM",
    "Starlink Mission.mp4*109691979*9/10/2020 10:50:00 AM",
    "Starship SN6 150m Flight Test.mp4*2561721*9/10/2020 10:49:42 AM",
    117438508,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]*0*9/13/2020 9:31:10 PM",
    0,
    "439*440*441*442*443*444*445*446*447*448*449*450*451*452*453*454*455*456*457*458*459"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales]  ¡HOLA! TV*0*9/13/2020 9:30:58 PM",
    "¿A qué se dedica la venezolana Karen Gonçalves, novia de Enzo Zidane- - ¡HOLA! TV.mp4*11073392*9/10/2020 12:15:28 PM",
    "‘La fiesta que no se puede tener’ así define Gilberto Santa Rosa su tema “La Familia” - ¡HOLA! TV.mp4*14893270*9/10/2020 12:15:24 PM",
    "Carolina Victoria presentó su colección “Renacimiento” inspirada en la cuarentena - ¡HOLA! TV.mp4*18365951*9/10/2020 12:15:22 PM",
    "Daniela Ospina revela por qué su marca se llama ‘DanFive’ y habla de su hija Salomé - ¡HOLA! TV.mp4*26765934*9/10/2020 12:14:58 PM",
    "Desde Venecia, Pedro Almodóvar presentó su primer proyecto en inglés con Tilda Swinton - ¡HOLA! TV.mp4*22996899*9/10/2020 12:14:48 PM",
    "Diego Boneta fue reconocido por Cate Blanchett en el Festival de Venecia - ¡HOLA! TV.mp4*22416138*9/10/2020 12:13:40 PM",
    "Estas son las nuevas y misteriosas parejas de Katie Holmes y Zac Efron - ¡HOLA! TV.mp4*16663234*9/10/2020 12:13:46 PM",
    "Estas son las tendencias para seguir disfrutando del estilo en climas cálidos - ¡HOLA! TV.mp4*24494073*9/10/2020 12:14:08 PM",
    "Ester Expósito deslumbra en su debut en el Festival de Venecia edición 77 - ¡HOLA! TV.mp4*13113342*9/10/2020 12:14:44 PM",
    "Estos fueron los vestidos más arriesgados de la Princesa Diana - ¡HOLA! TV.mp4*24336353*9/10/2020 12:15:00 PM",
    "Georgina Rodríguez cumplió un sueño caminando por el ‘Festival de Cine Biennale’ - ¡HOLA! TV.mp4*26536470*9/10/2020 12:14:32 PM",
    "Gustavo Cadile presenta su espectacular apartamento en Nueva York - ¡HOLA! TV.mp4*24111318*9/10/2020 12:15:06 PM",
    "Harry y Meghan Markle cumplen su promesa de pagar la reforma de Frogmore Cottage House - ¡HOLA! TV.mp4*16699093*9/10/2020 12:13:58 PM",
    "Ideas para alejar a los niños de la tecnología y lograr que compartan más en familia - ¡HOLA! TV.mp4*27899987*9/10/2020 12:14:38 PM",
    "Las primeras famosas en llegar al Festival de Cine de Venecia 2020 - ¡HOLA! TV.mp4*25679979*9/10/2020 12:15:10 PM",
    "Los nuevos retos de dirección que enfrenta Hollywood durante la pandemia - ¡HOLA! TV.mp4*13115293*9/10/2020 12:14:24 PM",
    "Máxima de Holanda sorprendió a su vuelta de vacaciones con un look monocromático - ¡HOLA! TV.mp4*29523855*9/10/2020 12:14:26 PM",
    "Meghan y Harry recordaron a Diana plantando sus flores favoritas en un preescolar - ¡HOLA! TV.mp4*15940335*9/10/2020 12:15:12 PM",
    "Prendas imprescindibles de tu armario para sacarle provecho a las existentes - ¡HOLA! TV.mp4*20844334*9/10/2020 12:13:22 PM",
    "Se revelaron cuáles fueron los cuatro platos favoritos de la princesa Diana - ¡HOLA! TV.mp4*19614347*9/10/2020 12:14:44 PM",
    "Una vuelta al cole atípica en todos los aspectos para la princesa Leonor - ¡HOLA! TV.mp4*17865543*9/10/2020 12:13:12 PM",
    432949140,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales]  Butaca Reservada*0*9/13/2020 9:30:46 PM",
    "10 Grandes DIFERENCIAS entre MULÁN (2020) y la Versión Animada (1998).mp4*58101313*9/10/2020 12:23:46 PM",
    "25 Curiosidades que NO sabías de THE WRESTLER - El Luchador (2008).mp4*98050545*9/10/2020 12:24:18 PM",
    156151858,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] Chicas Cosmo*0*9/13/2020 11:32:52 PM",
    "¿KHLOÉ KARDASHIAN EMBARAZADA-.mp4*11719998*9/10/2020 12:17:44 PM",
    "ADIÓS KUWTK, KIM KARDASHIAN SE DESPIDE DEL SHOW QUE LA HICIERA FAMOSA EN EL 2021.mp4*10011223*9/10/2020 12:17:38 PM",
    "ASÍ CELEBRÓ BTS SU SEGUNDA SEMANA EN EL PUESTO NUMERO 1 EN LA LISTA HOT 100.mp4*23454646*9/10/2020 12:17:24 PM",
    "CAMILA CABELLO CONFIRMÓ el TRUENE de SU RELACIÓN con SHAWN MENDES en PUBLICACIÓN.mp4*11520188*9/10/2020 12:19:08 PM",
    "CARA DELEVINGNE ESTÁ SALIENDO CON HALSEY 3 MESES DESPUÉS DE QUE SUS EX ASHLEY Y G-EAZY SON PAREJA.mp4*17029767*9/10/2020 12:18:42 PM",
    "CARDI B CRITICA A LOS HATERS POR PHOTOSHOPEAR SUS IMÁGENES CON LA INTENCIÓN DE RIDICULIZARLA.mp4*8622317*9/10/2020 12:18:26 PM",
    "DESMIENTEN CAMPAÑA PARA HACER VER MAL A SELENA NIEGAN QUE ELLA NO QUISIERA TRABAJAR CON JUSTIN.mp4*11710914*9/10/2020 12:17:54 PM",
    "DOVE CAMERON TOMA POR SORPRESA A SUS FANS EXPLICAN QUÉ PERSONAJES LE GUSTARÍA REALIZAR.mp4*8299184*9/10/2020 12:17:50 PM",
    "EXTRAÑAS IMÁGENES de  RIHANNA con EL ROSTRO GOLPEADO PREOCUPA a LOS FANS por SU BIENESTAR.mp4*26390979*9/10/2020 12:18:40 PM",
    "JACOB ELORDI y KAIA GERBER YA NO ESCONDEN su AMOR PASEANDO de LA MANO en NUEVA YORK - YouTube.mkv*10421041*9/10/2020 12:18:16 PM",
    "JACOB ELORDI y KAIA GERBER YA NO ESCONDEN su AMOR PASEANDO de LA MANO en NUEVA YORK.mp4*11107327*9/10/2020 12:18:04 PM",
    "JUSTIN BIEBER SE DECLARA FAN DE BTS EXPLICA EL ÉXITO DE DYNAMITE Y ARMY ESPERA UNA COLABORACIÓN.mp4*18102366*9/10/2020 12:17:48 PM",
    "KYLIE JENNER ESTA FELIZ CON LA NUEVA RELACIÓN DE KENDALL Y CREE QUE DEVIN ES DIFERENTE A LOS DEMÁS.mp4*9590873*9/10/2020 12:18:32 PM",
    "MILEY CYRUS REVELA que PARA SU PRÓXIMA RELACIÓN QUISIERA un HOMBRE MAYOR.mp4*14665085*9/10/2020 12:18:52 PM",
    "POR FIN BTS PODRÍA SER NOMINADO PARA UN GRAMMY EN EL 2021.mp4*21406438*9/10/2020 12:18:58 PM",
    "SALMA HAYEK DEJA BOQUIABIERTOS a SUS FANS con IMPACTANTES IMÁGENES al CELEBRAR su CUMPLEAÑOS 54.mp4*9942126*9/10/2020 12:19:04 PM",
    "SELENA GOMEZ ASEGURA que LA PRESIONARON para S3XU4LIZ4R su IMAGEN.mp4*32575070*9/10/2020 12:17:36 PM",
    "SELENA GOMEZ ASEGURA QUE sus EX PAREJAS CREEN que ESTA LOCA.mp4*11431394*9/10/2020 12:18:48 PM",
    "SHAKIRA ENLOQUECE a SUS FANS con SENSUALES IMÁGENES en BIKINI.mp4*9757140*9/10/2020 12:17:58 PM",
    "TEORÍA CONSPIRATIVA DICE QUE BRITNEY SPEARS ha HECHO REFERENCIA a LA EXPLOTACIÓN SEX.. que HA VIVIDO.mp4*20108682*9/10/2020 12:19:00 PM",
    "UNA MESERA SE ROBA EL CORAZÓN DE ZAC EFRON Y LOS FANS NO PUEDEN CREERLO.mp4*23111605*9/10/2020 12:17:30 PM",
    320978363,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] CURIOSEANDO*0*9/13/2020 11:28:34 PM",
    "¿Por qué Bella Hadid es la modelo menos respetada- ¿Su amistad con Kendall Jenner la perjudica-.mp4*53162381*9/10/2020 12:23:26 PM",
    "¿Por qué Gigi Hadid es la modelo menos respetada- ¿Kendall Jenner la está perjudicando-.mp4*42987854*9/10/2020 12:23:30 PM",
    "¿Por qué Nick Jonas y Priyanka Chopra son el matrimonio más perfecto de Hollywood-.mp4*55078439*9/10/2020 12:22:48 PM",
    "¿Qué opina Angelina Jolie de Nicole Poturalski, la nueva novia de Brad Pitt que ya está casada-.mp4*35290533*9/10/2020 12:23:14 PM",
    "¿Qué opina Angelina Jolie de Nicole Poturalski, la nueva novia de Brad Pitt que ya está casada-_2.mp4*35290533*9/10/2020 12:23:16 PM",
    "¿Son Melania Trump y Barron Trump felices al lado del Presidente de los Estados Unidos Donald Trump-.mp4*37689290*9/10/2020 12:23:04 PM",
    "Angelina Jolie criando a sus seis hijos sin Brad Pitt es lo más desastroso que has visto jamás.mp4*41152975*9/10/2020 12:23:32 PM",
    "Famosos que robaron el corazón de Selena Gómez después de que Justin Bieber se lo rompiera.mp4*44042348*9/10/2020 12:22:58 PM",
    344694353,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] De Película - By Screen Rant*0*9/13/2020 9:31:28 PM",
    "¿Qué Pasaría Si La Otra Mitad De Los Vengadores Se Hubiera Desvanecido-.mp4*49785318*9/10/2020 11:56:08 AM",
    "10 Actores Que Aceptaron Recortes Salariales Por Un Papel.mp4*42774779*9/10/2020 11:55:52 AM",
    "15 Bloopers Y Momentos Más Lindos De Harry Potter En El Set.mp4*34660232*9/10/2020 11:57:28 AM",
    "15 Cosas Que Ocurrieron Entre Avatar- La Leyenda De Aang Y La Leyenda De Korra.mp4*143178393*9/10/2020 11:54:36 AM",
    "15 Momentos Que No Sabías Fueron Improvisados En Harry Potter.mp4*31399713*9/10/2020 11:55:36 AM",
    "Doctor Strange Podría Convertirse En El Héroe Más Poderoso Del UCM.mp4*34800496*9/10/2020 11:55:24 AM",
    "El Martillo De Thor No Fue Destruido Por Hela.mp4*44172809*9/10/2020 11:55:40 AM",
    "Escenas Eliminadas De Avatar Que Pudieron Cambiarlo Todo.mp4*56467170*9/10/2020 11:55:48 AM",
    "Flash Tiene Poderes Secretos Que No Conocías.mp4*35573684*9/10/2020 11:55:30 AM",
    "Otras 10 Veces En Que Los Simpson Predijeron El Futuro.mp4*45235859*9/10/2020 11:56:00 AM",
    518048453,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] Ella Dice*0*9/13/2020 11:17:26 PM",
    "A Un Mes De TERMINAR Con SCOTT DISICK, SOFIA RICHIE Parece Darse Una OPORTUNIDAD Con JADEN SMITH!.mp4*15004685*9/10/2020 11:49:34 AM",
    "CAMILA MENDES Sorprende A Sus FANS Al Presentar OFICIALMENTE A Su NUEVO NOVIO En INSTAGRAM.mp4*15158524*9/10/2020 11:49:36 AM",
    "Es OFICIAL JACOB ELORDI TERMINO Con ZENDAYA Y Ahora SALE Con KAIA GERBER, Esta Es La PRUEBA!.mp4*14967033*9/10/2020 11:51:04 AM",
    "EXTRAÑO VIDEO De KIM KARDASHIAN “DANDO A LUZ” A KYLIE JENNER Se Vuelve Tendencia En REDES.mp4*17318845*9/10/2020 11:50:56 AM",
    "FENTY BEAUTY De RIHANNA Acusa A RARE BEAUTY La Línea De BELLEZA De SELENA GOMEZ De PLAGIO!!!.mp4*10529825*9/10/2020 11:50:58 AM",
    "JACOB ELORDI Se OLVIDA Del Cumple De ZENDEYA Pero Si Celebra El De Su Nueva Conquista KAIA GERBER!.mp4*11322399*9/10/2020 11:49:48 AM",
    "JUSTIN BIEBER Nombra A SELENA GOMEZ En Un Nuevo VIDEO MUSICAL Con HAILEY BALDWIN!!!.mp4*13809367*9/10/2020 11:49:40 AM",
    "KANYE WEST Asegura Que “HUMILLÓ” A TAYLOR SWIFT En Los MTV VMA 2009 Por Que Fue El Deseo De DIOS.mp4*11764257*9/10/2020 11:51:16 AM",
    "KIM KARDASHIAN Anuncia Que “KEEPING UP WITH THE KARDASHIANS” Llega A Su FIN!!!.mp4*26644724*9/10/2020 11:46:54 AM",
    "La VERDADERA RAZÓN Por La Que “KEEPING UP WITH THE KARDASHIANS” Saldrá Del AIRE.mp4*13828872*9/10/2020 11:46:40 AM",
    "LILI REINHART Consterna A Fans Al ADMITIR Que No Esta FELIZ De Reiniciar GRABACIONES De RIVERDALE.mp4*14758815*9/10/2020 11:51:04 AM",
    "MILLIE BOBBY BROWN Sorprende A Todos Al ADMITIR Que Le Gustaría CASARSE Con FINN WOLFHARD!!!.mp4*11259358*9/10/2020 11:51:24 AM",
    "RIHANNA Conmociona La RED Al Revelar Que Pese Su POLÉMICA PELEA Del 2009 Sigue AMANDO A CHRIS BROWN.mp4*13479099*9/10/2020 11:51:18 AM",
    "SELENA GOMEZ ABORDA Los RUMORES Sobre Su DRÁSTICA PERDIDA De Peso.mp4*17353508*9/10/2020 11:49:30 AM",
    "SELENA GOMEZ Asegura Que Ya No Está Interesada En El AMOR Por que Todos Sus EX PIENSAN Que ESTA LOCA.mp4*13289372*9/10/2020 11:49:14 AM",
    "SELENA GOMEZ Pide A JUSTIN BIEBER Que Deje De ACOSARLA Luego De Polémica Del VIDEOCLIP POPSTAR.mp4*12243813*9/10/2020 11:49:18 AM",
    "ZACK EFRON Encuentra El Amor Fuera De HOLLYWOOD, Una Linda Mesera Le Ha ROBADO El CORAZÓN!!!.mp4*10868217*9/10/2020 11:47:00 AM",
    243600713,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] FilmsPlay*0*9/13/2020 9:03:18 PM",
    "Doblaje Vs Subtítulos- ¿Cuál es la mejor forma de ver contenido-.mp4*34223446*9/10/2020 11:25:36 AM",
    "THE BOYS- El Poder Corrompe al Héroe - Análisis.mp4*29157353*9/10/2020 11:25:34 AM",
    63380799,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] FormulaTV*0*9/13/2020 9:03:23 PM",
    "¿Se ha planteado BELY BASARTE participar en algún talent show-.mp4*60370122*9/10/2020 12:01:14 PM",
    "Acaba el plazo de Eurovisión Junior 2020- los países que participarán.mp4*52807725*9/10/2020 12:01:02 PM",
    "Anabel Pantoja, primera concursante de SOLA_SOLO, ¿sorprenderá- - El diario de Alejandra.mp4*63221378*9/10/2020 12:00:24 PM",
    "Estrenos 4 SEPTIEMBRE Series para el fin de semana en Netflix, Disney+, Amazon Prime Video....mp4*24707320*9/10/2020 12:01:20 PM",
    "La razón por la que ROSARIO PARDO abandonó CUÉNTAME.mp4*60244549*9/10/2020 12:00:32 PM",
    "Los responsables de MEMORIAS DE IDHÚN nos dan las claves del primer anime español de NETFLIX.mp4*43039891*9/10/2020 12:00:10 PM",
    "Soleá representará a España en Eurovisión Junior 2020.mp4*217752565*9/10/2020 12:01:08 PM",
    "THE BOYS temporada 2 REVIEW- ¿Sigue siendo la mejor serie de superhéroes-.mp4*41131838*9/10/2020 12:01:24 PM",
    563275388,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] Fuera de Foco*0*9/13/2020 11:07:27 PM",
    "¡Netflix Gratis! (no es fake), ¿Estafados con Tenet y Jason Momoa en The Witcher- Y MÁS..mp4*59086229*9/10/2020 11:34:42 AM",
    "¡TE REGALO UN HOT TOY! ¿JOHN CENA EN SPIDERMAN- EL PADRINO 3, NETFLIX CANCELA MÁS SERIES y MÁS 😱.mp4*102368196*9/10/2020 11:33:52 AM",
    "CRÍTICA- LOS NUEVOS MUTANTES _ Ahora lo entiendo todo....mp4*28166923*9/10/2020 11:34:14 AM",
    "CRÍTICA- PIENSO EN EL FINAL - IM THINKING OF ENDING THIS - y mi interpretación 🤯.mp4*36118075*9/10/2020 11:34:02 AM",
    "Crítica- SERVANT - La mejor serie de suspenso del año.mp4*27574836*9/10/2020 11:34:36 AM",
    "Platique con elenco de MORTAL GLITCH - serie de Enchufe TV 🤩🔥.mp4*62030499*9/10/2020 11:34:10 AM",
    315344758,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] Grunge Español*0*9/13/2020 9:03:25 PM",
    "Tosh.0 Será Cancelado. Esta es La Razón.mp4*23916251*9/10/2020 11:57:42 AM",
    23916251,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] íconos*0*9/13/2020 11:25:36 PM",
    "Adele se transformó totalmente por una mujer - íconos.mp4*12456069*9/10/2020 12:03:14 PM",
    "Brad Pitt usa a su nueva novia para burlarse de Angelina Jolie - íconos.mp4*12260186*9/10/2020 12:03:06 PM",
    "Chadwick Boseman le hizo una promesa a su esposa.mp4*9552686*9/10/2020 12:02:28 PM",
    "Hermano de Juan Gabriel se queda sin herencia gracias a su sobrino - YouTube.mkv*9023992*9/10/2020 12:02:06 PM",
    "Katy Perry lucha contra sus miedos - YouTube.mkv*10662460*9/10/2020 12:02:22 PM",
    "Kim Basinger y su escandalosa relación con Alec Baldwin - YouTube.mkv*9348966*9/10/2020 12:02:16 PM",
    "Lady Di y su aniversario número 23.mp4*15943100*9/10/2020 12:03:00 PM",
    "Robert Downey Jr. y su cercana relación con Johnny Depp - íconos.mp4*13275923*9/10/2020 12:03:22 PM",
    "Ryan Gosling casi destruyó su propia carrera - íconos.mp4*10963892*9/10/2020 12:02:12 PM",
    "Zac Efron y su nueva novia.mp4*13602280*9/10/2020 12:01:54 PM",
    117089554,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] iLeorju*0*9/13/2020 10:19:17 PM",
    "10 Cosas que sucedieron en los últimos días de Chadwick Boseman.mp4*16668040*9/10/2020 12:10:34 PM",
    "7 FAMOSOS que OCULTARON sus ENFERMEDADES y LUCHARON en SILENCIO.mp4*7227299*9/10/2020 12:10:26 PM",
    "Chris Evans Muy Triste por la Partida Del Actor Chadwick Boseman - MARVEL.mp4*7268925*9/10/2020 12:10:48 PM",
    "Lo último que grabó Chadwick Boseman como Black Panther antes de morir.mp4*8558804*9/10/2020 12:10:54 PM",
    "ULTIMA HORA- China en ALERTA NUEVAMENTE - La NUEVA ENFERMEDAD que CRECE ALARMANTEMENTE.mp4*8506811*9/10/2020 12:10:52 PM",
    "ULTIMA HORA- LA PELICULA ‘The Batman’ suspende su rodaje , ESTA ES LA RAZON.mp4*7151695*9/10/2020 12:10:40 PM",
    55381574,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] Jag Durán*0*9/13/2020 10:27:52 PM",
    "Nueva Serie de GOT tendría a Emily Blunt _ Buscan a Daemon Targaryen.mp4*29813454*9/10/2020 11:35:56 AM",
    29813454,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] Kai47*0*9/13/2020 9:03:26 PM",
    "¿Cómo Atravesó Ella-Laraña la Cota de Mithril- Explicado - YouTube.mkv*10605277*9/10/2020 11:40:44 AM",
    10605277,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] Looper Español*0*9/13/2020 9:03:28 PM",
    "Desgarrador Último Mensaje De Chadwick Boseman Al Productor De Black Panther.mp4*11287650*9/10/2020 12:06:12 PM",
    "El Desgarrador Tributo De Danai Gurira A Chadwick Boseman.mp4*11644273*9/10/2020 12:07:34 PM",
    "El Final De La Primera Temporada De Warrior Nun Explicado.mp4*15244803*9/10/2020 12:07:46 PM",
    "El Final Explicado De Bill &amp; Ted Face The Music.mp4*29047429*9/10/2020 12:07:40 PM",
    "El Proyecto De Chadwick Boseman Que Había Sido Anunciado Recientemente.mp4*8514614*9/10/2020 12:07:44 PM",
    "El UCM Reacciona A La Muerte De Chadwick Boseman.mp4*25666918*9/10/2020 12:06:40 PM",
    "Keanu Reeves Reveló Por Cuánto Tiempo Más Hará De John Wick.mp4*20602532*9/10/2020 12:06:02 PM",
    "La Broma En The Office Que Le Costó $60.000 A NBC.mp4*17778692*9/10/2020 12:07:00 PM",
    "La Verdadera Razón Por La Que Tosh.0 Será Cancelado.mp4*14480724*9/10/2020 12:06:14 PM",
    "Las Mejores Y Peores Secuelas De Películas De Stephen King.mp4*17817801*9/10/2020 12:07:12 PM",
    "Las Mejores Y Peores Secuelas De Películas De Stephen King_2.mp4*17817801*9/10/2020 12:07:30 PM",
    "Muertes De TV Que Ocurrieron Debido A La Muerte Del Actor.mp4*51479354*9/10/2020 12:07:10 PM",
    "Prioridad De Disney Revelada Luego De La Muerte De Boseman.mp4*17463832*9/10/2020 12:06:18 PM",
    258846423,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] Netflix Latinoamérica*0*9/13/2020 11:27:30 PM",
    "Alguien tiene que morir - Anuncio de fecha de estreno - Netflix.mp4*2168147*9/10/2020 12:20:18 PM",
    "Cobra Kai - Lecciones de karate con Johnny Lawrence.mp4*17050952*9/10/2020 12:21:16 PM",
    "Cobra Kai - Ralph Macchio comparte sus anécdotas de grabación favoritas.mp4*16100985*9/10/2020 12:20:48 PM",
    "El Halloween de Hubie - Tráiler oficial - Netflix.mp4*12990488*9/10/2020 12:20:06 PM",
    "José Hernández, el latino que viajó al espacio - Lejos.mp4*32430120*9/10/2020 12:20:00 PM",
    "Lucifer - El origen del diablo - A Fondo.mp4*38590944*9/10/2020 12:20:16 PM",
    "pienso en el final - Lo que debes saber antes de verla.mp4*14302278*9/10/2020 12:21:10 PM",
    "Qué mascota es la que mejor te representa.mp4*21765913*9/10/2020 12:20:58 PM",
    "Ratched - Tráiler final - Netflix.mp4*8929763*9/10/2020 12:20:40 PM",
    "Rebeca - Tráiler oficial - Netflix.mp4*8182214*9/10/2020 12:20:42 PM",
    "Todo empieza con una historia - Netflix.mp4*6840127*9/10/2020 12:20:14 PM",
    179351931,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] Nicki Swift Español*0*9/13/2020 9:30:50 PM",
    "El Análisis Del Lenguaje Corporal De Tiffany Trump En La CNR.mp4*13729303*9/10/2020 11:41:40 AM",
    "Keanu Reeves Confirma Lo Que Los Fans Han Especulado Durante Años.mp4*14288096*9/10/2020 11:41:34 AM",
    "La Actuación De Miley Cyrus En Los VMAs Está Causando Revuelo.mp4*13920096*9/10/2020 11:41:46 AM",
    "La Extraña Revelación Que Sarah Sanders Hizo Sobre Trump.mp4*27023688*9/10/2020 11:41:22 AM",
    "La Reacción De Las Celebridades A La Muerte De Chadwick Boseman.mp4*33418247*9/10/2020 11:44:38 AM",
    "La Última Conversación Entre Chadwick Boseman Y Kobe Bryant Es Desgarradora.mp4*16129006*9/10/2020 11:44:28 AM",
    "La Verdad Sobre Erin Andrews Finalmente Revelada.mp4*38973808*9/10/2020 11:44:38 AM",
    "La Verdad Sobre La Relación De Bradley Cooper Y Jennifer Garner.mp4*11920796*9/10/2020 11:41:30 AM",
    "Los Mejores Y Peores Vestidos En La Alfombra Roja De Los VMA 2020.mp4*24049945*9/10/2020 11:42:04 AM",
    "Por Qué Darius Rucker Y Su Esposa Se Están Separando Luego De 20 Años.mp4*20342156*9/10/2020 11:41:44 AM",
    213795141,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] Para Los Curiosos*0*9/13/2020 11:24:27 PM",
    "¿Jennifer Lopez ELIMINARÁ a Kylie Jenner y Rihanna del MUNDO de los COSMÉTICOS-.mp4*38899886*9/10/2020 12:22:06 PM",
    "Así es Loren Gray y la DESGARRADORA Historia Detrás de Su EXTRAORDINARIO Éxito.mp4*25359140*9/10/2020 12:22:00 PM",
    "Britney Spears y Jamie Lynn- ¿Por Qué  la Hermanita MENOR Se QUEDÓ Con TODA su FORTUNA-.mp4*43231673*9/10/2020 12:22:22 PM",
    "Jacob Elordi, Zendaya y Tommy Dorfman, ¿Un TRIÁNGULO Amoroso De la NUEVA ERA de Hollywood-.mp4*32167982*9/10/2020 12:22:14 PM",
    "Kylie Jenner y Fai Khadra, ¿El Hombre Que Llegó A CURAR el MAL de AMORES de la Multimillonaria-.mp4*33018914*9/10/2020 12:22:24 PM",
    "Kylie Jenner y Stormi, ¿Tantos LUJOS y EXCENTRICIDADES podrían ARRUINAR la Vida de la Pequeñita-.mp4*38517535*9/10/2020 12:21:52 PM",
    "Ralph Macchio- ¿Karate Kid ACABÓ con la Carrera De la ESTRELLA de Cobra Kai-.mp4*31743854*9/10/2020 12:22:20 PM",
    242938984,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] Popcorn News*0*9/13/2020 9:30:57 PM",
    "22 Curiosidades de TORNADO (TWISTER).mp4*27029316*9/10/2020 11:45:12 AM",
    "25 Curiosidades de COBRA KAI.mp4*73360034*9/10/2020 11:45:24 AM",
    "26 Curiosidades de LOS NUEVOS MUTANTES (THE NEW MUTANTS).mp4*32568007*9/10/2020 11:45:08 AM",
    "30 Curiosidades de MULÁN.mp4*39493826*9/10/2020 11:44:58 AM",
    172451183,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] WatchMojo Español*0*9/13/2020 11:28:32 PM",
    "¡Top 10  Personajes ESTÚPIDAMENTE Egoístas en Videojuegos!.mp4*42011262*9/10/2020 11:51:50 AM",
    "¡Top 10 Actores que SALVARON su Carrera con UNA Interpretación!.mp4*40945645*9/10/2020 11:52:14 AM",
    "¡Top 10 Bebés Más ATERRADORES de Películas!.mp4*45078662*9/10/2020 11:53:22 AM",
    "¡Top 10 Cosas Espeluznantes que Suceden BAJO EL MAR!.mp4*48147897*9/10/2020 11:52:06 AM",
    "¡Top 10 de ERRORES de Actores que se QUEDARON en la Película!.mp4*37198345*9/10/2020 11:52:56 AM",
    "¡Top 10 de ERRORES de Actores que se QUEDARON en la Película!_2.mp4*37198345*9/10/2020 11:53:04 AM",
    "¡Top 10 Momentos Más Impactantes de -El Último Baile-!.mp4*58841642*9/10/2020 11:53:36 AM",
    "¡Top 10 Momentos Más Impactantes de SONS OF ANARCHY!.mp4*32649580*9/10/2020 11:52:44 AM",
    "¡Top 10 Películas de CIENCIA FICCIÓN Más Alucinantes que Probablemente NO HAYAS VISTO!.mp4*34546611*9/10/2020 11:52:08 AM",
    "¡Top 10 Películas de FANTASÍA Más ATERRADORAS!.mp4*39622909*9/10/2020 11:53:50 AM",
    "¡Top 10 Películas de TERROR que puedes Ver Sólo UNA VEZ!.mp4*31459108*9/10/2020 11:52:30 AM",
    "¡Top 10 PEORES Cosas que ROGER SMITH Ha Hecho!.mp4*25825934*9/10/2020 11:53:24 AM",
    "¡Top 10 SECRETOS OCULTOS que Hacen que Una Película sea Mejor!.mp4*34871442*9/10/2020 11:51:58 AM",
    "¡Top 20 de Castigos Más BRUTALES Dados a TRAMPOSOS!.mp4*91819429*9/10/2020 11:52:40 AM",
    "¡Top 20 MEJORES Juegos NUEVOS Para PlayStation 5!.mp4*109316577*9/10/2020 11:52:30 AM",
    "¿Podríamos los Humanos Sobrevivir al MonsterVerse-.mp4*25491329*9/10/2020 11:53:28 AM",
    735024717,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[CINE Y SERIES]\\[Cine, Series, Audiovisuales] ZEPfilms*0*9/13/2020 10:21:44 PM",
    "WATCHMEN- El proyecto de Terry Gilliam que jamás vio la luz.mp4*48496345*9/10/2020 11:45:58 AM",
    48496345,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[ECONOMIA Y POLITICA]*0*9/13/2020 9:31:29 PM",
    0,
    "461*462*463*464*465*466*467*468"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[ECONOMIA Y POLITICA]\\[Econimia y Politica] Bendito Poder*0*9/13/2020 9:31:09 PM",
    "Los 10 países MÁS COMUNISTAS e IZQUIERDISTAS de América Latina.mp4*110269873*8/31/2020 7:04:32 PM",
    110269873,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[ECONOMIA Y POLITICA]\\[Econimia y Politica] El Mapa de Sebas*0*9/13/2020 10:21:45 PM",
    "🇲🇽 ⚔️ 🇺🇸 La Intervención ESTADOUNIDENSE en MÉXICO en 11 minutos  Mexican-American War🇺🇸 ⚔️🇲🇽.mp4*50221585*8/31/2020 8:36:10 PM",
    "🇺🇬🇺🇬HISTORIA de UGANDA en 13 minutos  History of Uganda in 13 minutes (resumen)  🇺🇬🇺🇬.mp4*44212272*8/31/2020 8:36:34 PM",
    "🇺🇸🇺🇸La historia de la BANDERA de ESTADOS UNIDOS    USA Flag history (VexilologíaVexillology) 🇺�.mp4*71266491*8/31/2020 8:35:38 PM",
    "🇽🇰🇽🇰HISTORIA de KOSOVO en 14 minutos (resumen)  The History of KOSOVO in 14 minutes🇽🇰🇽🇰.mp4*54499812*8/31/2020 8:36:14 PM",
    220200160,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[ECONOMIA Y POLITICA]\\[Econimia y Politica] HipotesisdePoder*0*9/13/2020 9:31:25 PM",
    "Latinoamérica- ¿Década perdida por el coronavirus-.mp4*43607904*8/31/2020 7:05:10 PM",
    43607904,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[ECONOMIA Y POLITICA]\\[Econimia y Politica] Leander Auditore*0*9/13/2020 9:31:27 PM",
    "El Proyecto para Colonizar a Venezuela con Cosacos Rusos.mp4*52801792*8/31/2020 7:06:00 PM",
    52801792,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[ECONOMIA Y POLITICA]\\[Econimia y Politica] Mikhoffee Time*0*9/13/2020 9:31:27 PM",
    "Reescribiendo Game of Thrones Temporada 8 - Episodio 4.mp4*57098820*8/31/2020 7:06:44 PM",
    57098820,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[ECONOMIA Y POLITICA]\\[Econimia y Politica] Mr  Santos*0*9/13/2020 9:31:29 PM",
    "DOLAR DIGITAL vs YUAN DIGITAL - EL NUEVO MUNDO.mp4*350249860*8/31/2020 7:10:50 PM",
    350249860,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[ECONOMIA Y POLITICA]\\[Econimia y Politica] NSR Nobles Scutarii Rex*0*9/13/2020 11:17:26 PM",
    "Histórico📜  Extracto de Diodoro de Sicilia, Libro V, capítulo 33, 34📚. Los celtíberos⚔.mp4*84465472*8/31/2020 8:45:42 PM",
    "Histórico📜  Extracto de Diodoro de Sicilia, Libro V. Capítulo XXXIII📚. Los galos.mp4*214223011*8/31/2020 8:41:50 PM",
    "🏟Torneo Rome 2 Total War Auge🏛  🛡Batalla Cuartos⚔  vaek vs JoshiTheGreat.mp4*125003012*8/31/2020 8:50:06 PM",
    "🏟Torneo Rome 2 Total War Auge🏛  🛡Batalla Semifinales⚔  DanJ17 vs Vandersoto.mp4*149025952*8/31/2020 8:38:54 PM",
    "🏟Torneo Rome 2 Total War Auge🏛  🛡Batalla Semifinales⚔  Kronos vs JoshiTheGreat.mp4*112335174*8/31/2020 8:44:20 PM",
    685052621,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[ECONOMIA Y POLITICA]\\[Econimia y Politica] Victor Aguilar-Chang*0*9/13/2020 11:11:06 PM",
    "Batalla- Sedán (1870)- el inicio de la guerra.mp4*137199618*8/31/2020 7:09:48 PM",
    137199618,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[EXTRAÑOS Y EXTRAVAGANTES]*0*9/13/2020 9:29:23 PM",
    0,
    "470*471"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[EXTRAÑOS Y EXTRAVAGANTES]\\[Extraños y Extravagantes] Antes De Que Fueran Famosos*0*9/13/2020 9:29:32 PM",
    "Conor McGregor  House Tour  Mansión En Irlanda Y España.mp4*101781621*8/31/2020 10:13:44 PM",
    "JLo y Alex Rodríguez  House Tour  Mansión Nueva de 40 Millones De Dólares.mp4*124978715*8/31/2020 10:14:58 PM",
    "Maluma, Natalia y Neymar  La Historia Completa  Noticias Virales.mp4*34562466*8/31/2020 10:12:16 PM",
    "Marilyn Manson  House Tour  Mansión Embrujada En Hollywood.mp4*39454026*8/31/2020 10:12:10 PM",
    300776828,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[EXTRAÑOS Y EXTRAVAGANTES]\\[Extraños y Extravagantes] Guinness World Records*0*9/13/2020 9:29:15 PM",
    "Awesome August records! - Guinness World Records.mp4*50338589*8/31/2020 10:13:56 PM",
    "Largest elephant toothpaste foam explosion - Guinness World Records.mp4*13296862*8/31/2020 10:12:30 PM",
    "She collects PLASTIC food - Guinness World Records.mp4*10973746*8/31/2020 10:13:16 PM",
    "Tallest Mohawk - Guinness World Records.mp4*25437313*8/31/2020 10:12:14 PM",
    100046510,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]*0*9/13/2020 9:29:03 PM",
    0,
    "473*474*475*476*477*478*479*480*481*482*483*484*485*486*487*488*489*490*491*492*493*494*495*496*497*498*499*500*501"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Alejandro Pérez*0*9/13/2020 9:29:03 PM",
    "OnePlus Nord ¿PEOR o MEJOR que el POCO F2 Pro-.mp4*92954273*9/11/2020 10:59:04 AM",
    92954273,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Appleros*0*9/13/2020 9:28:58 PM",
    "Pixel 4a 📱 el GRAN RIVAL Android del iPhone SE - unboxing español.mp4*91331169*9/11/2020 10:42:36 AM",
    91331169,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Canaltech En Español*0*9/13/2020 10:30:04 PM",
    "Celular básico, medio y avanzado- ¿en que se diferencian- [Explicado].mp4*51881280*9/11/2020 10:41:20 AM",
    "MOTO G8 POWER- 5 motivos para comprar✔️ y 5 para no comprar❌.mp4*38805312*9/11/2020 10:41:28 AM",
    "Moto One Fusion [Análisis_ Review].mp4*59808311*9/11/2020 10:41:36 AM",
    "Moto One Fusion Plus [Review].mp4*40283779*9/11/2020 10:41:38 AM",
    190778682,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Clipset*0*9/13/2020 11:07:58 PM",
    "¡LO TENEMOS! Samsung Galaxy Z FOLD 2 -lo MEJOR y lo PEOR-.mp4*42981045*9/10/2020 12:26:50 PM",
    "LEGO Harry Potter Diagon Alley - Unboxing + Review Set 75978 ESPECTACULAR.mp4*166154189*9/10/2020 12:27:12 PM",
    "MEJORES móviles FOTO NOCTURNA y ESTRELLAS -prueba EXTREMA -.mp4*52771624*9/10/2020 12:26:38 PM",
    "MEJORES móviles FOTO NOCTURNA y ESTRELLAS -prueba EXTREMA -_2.mp4*52771624*9/10/2020 12:26:50 PM",
    "Motorola RAZR 5G -unboxing VIRTUAL- smartphone plegable.mp4*23618667*9/10/2020 12:26:24 PM",
    "Poco X3 NFC -el unboxing de la BESTIA-.mp4*34920200*9/10/2020 12:26:32 PM",
    373217349,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Crítico de la Tecnología*0*9/13/2020 10:36:15 PM",
    "¿Qué pretende NVIDIA con las RTX serie 30- - ¿Debería comprarme una-.mp4*37477071*9/11/2020 10:49:32 AM",
    "RG280M, una mini consola con OpenDingux - RESEÑA en Español.mp4*127336531*9/11/2020 10:49:20 AM",
    164813602,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] El camionero geek*0*9/13/2020 9:28:10 PM",
    "MEJORA la 📸 CÁMARA de tu ORDENADOR por 16€.mp4*31169412*9/10/2020 12:28:48 PM",
    "Review completa Redmi Note 9 en español.mp4*43302014*9/10/2020 12:28:40 PM",
    "Siguiente pregunta #31 ¿qué opino sobre la salida de Messi-.mp4*135503293*9/10/2020 12:29:08 PM",
    209974719,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] El Rincón de China*0*9/13/2020 10:21:28 PM",
    "Chuwi Hi10 X, casi me hace LLORAR esta tablet convertible 2 en 1😅.mp4*34474830*9/11/2020 11:49:26 AM",
    "El Redmi NOTE 10 ya está AQUÍ pero le esperan los Realme 7 y 7 PRO ¡A LUCHAR!.mp4*19266922*9/11/2020 11:49:36 AM",
    "NUNCA he VISTO nada igual en un TELÉFONO, te sorprenderá!! Ulefone Armor 9.mp4*36421163*9/11/2020 11:49:44 AM",
    "Unboxing POCO X3, a este precio puede ser IMBATIBLE con 120Hz.mp4*34910273*9/11/2020 11:49:34 AM",
    "Vaya BAJOS tienen estos AURICULARES INALÁMBRICOS con ANC! Shield PRO.mp4*30043274*9/11/2020 11:49:48 AM",
    155116462,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Fer Zavala*0*9/13/2020 9:27:39 PM",
    "Redmi Note 8 Pro - SUPERWALLPAPER - SUPER FACIL - Descarga y Acticva.mp4*25220465*9/11/2020 11:48:36 AM",
    25220465,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Gingertech*0*9/13/2020 9:27:31 PM",
    "¿ES SUFICIENTE- 🤔🔥 SONY SRS-XB43 🔥 review en español.mp4*54731711*9/11/2020 11:50:08 AM",
    "💥 PROBAMOS LAS LUCES DEL XB43 💥- TEST LUCES SONY SRS-XB43 - LIGHTS MODE.mp4*29758635*9/11/2020 11:50:14 AM",
    84490346,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Happy tech*0*9/13/2020 9:27:27 PM",
    "Haz tu celular 2 veces mas rápido en menos de 1 minuto.mp4*10650759*9/11/2020 10:55:14 AM",
    10650759,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] iSenaCode*0*9/13/2020 11:21:41 PM",
    "La gran sorpresa del Apple Event de Septiembre 2020.mp4*77025708*9/10/2020 12:28:10 PM",
    "La mejor alternativa LOW COST a los AirPods- VESTA Hiditec.mp4*26267452*9/10/2020 12:28:16 PM",
    "La revolución del iPhone 12, el regreso del MacBook 12- y el Apple TV.mp4*23540778*9/10/2020 12:28:18 PM",
    126833938,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] La Manzana Mordida*0*9/13/2020 10:29:48 PM",
    "⚡️ El editor de PDF más poderoso para tu Mac - PDF Expert.mp4*21236462*9/11/2020 10:11:30 AM",
    "¿Por qué Apple es más valiosa que Samsung-.mp4*47936925*9/11/2020 10:11:46 AM",
    "iOS 14 beta 7, estos son sus cambios y novedades.mp4*5763847*9/11/2020 10:11:38 AM",
    "La canción de La Manzana Mordida, así puedes tener la tuya.mp4*12482741*9/11/2020 10:11:36 AM",
    "👎🏻 Esta función de iOS 14 se retrasa hasta 2021.mp4*27082560*9/11/2020 10:11:10 AM",
    "💥 Apple lanza iOS 14 beta 8, ¿la última beta antes de la versión final-.mp4*9826133*9/11/2020 10:11:06 AM",
    "🔥 ¡Ya es OFICIAL! Apple anuncia su evento especial de septiembre 2020.mp4*11760141*9/11/2020 10:11:16 AM",
    "🔥 ¡Ya es OFICIAL! Apple anuncia su evento especial de septiembre 2020_2.mp4*11760141*9/11/2020 10:11:24 AM",
    "😱 iPhone 12, ya llegan… O no.mp4*17005534*9/11/2020 10:11:00 AM",
    164854484,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] LIONTO*0*9/13/2020 9:26:41 PM",
    "Blackview BV6300 PRO- el Mejor CELULAR RESISTENTE y el más LIGERO!!!🔥🔥🔥.mp4*46725204*9/11/2020 11:49:06 AM",
    "Los 7 MEJORES CELULARES con SNAPDRAGON 865 PLUS 2020!!! 🔥🔥🔥.mp4*78415193*9/11/2020 11:48:56 AM",
    125140397,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] LoboTec*0*9/13/2020 9:26:33 PM",
    "Bicho se cura- Pruebas sirven- Rpd TODAS tus Preguntas EN VIVO.mp4*143948957*9/11/2020 10:40:58 AM",
    "Celular Más vendido del Mundo INCREÍBLE.mp4*17203421*9/11/2020 10:41:04 AM",
    161152378,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Los Ilusionistas De La Tecnología*0*9/13/2020 8:47:41 PM",
    "✅ FIX YETI - No puedo monitorear mi voz con el blue yeti (No se escucha mi voz por los audífonos)⁉️.mp4*12935272*9/11/2020 10:55:38 AM",
    "✅ iOS 14 Beta 7 Novedades NUEVOS FONDOS CLAROS Y OSCUROS🔥 - YouTube.mp4*41347057*9/11/2020 10:56:04 AM",
    "✅ SOLUCION - OOBESETTINGS Windows 10 20H2 En VirtualBox Método 2020💯 - YouTube.mp4*8551267*9/11/2020 10:56:06 AM",
    "✅ TUTORIAL - Alternativa Cyd1a Impactor Funcional En Windows _ iOS 14✨.mp4*17277938*9/11/2020 10:55:56 AM",
    "✅TUTORIAL🔥 - Ch3ckn1x Con Soporte Para Odysseyra1n - iOS 13.7 OFICIAL En USB De 50MB J4ilbreak💥.mp4*38442857*9/11/2020 10:55:52 AM",
    "🔥 NOTICIAS - Apple Watch SERIES 6 HOY Y LOS IPHONE 12 CUANDO SALEN A LA VENTA⁉️.mp4*46421502*9/11/2020 10:55:50 AM",
    164975893,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] MARCIANOTECH*0*9/13/2020 8:47:45 PM",
    "#AppleEvent EXPLICADO.mp4*14078925*9/11/2020 10:57:24 AM",
    "APPLE CONFIRMA CON FECHA SU EVENTO!!!!!! #AppleEvent.mp4*14049463*9/11/2020 10:57:22 AM",
    "APPLE NO DESCANSA y  CONTRADEMANDA A FORTNITE EpicGames !!!!!!!!.mp4*13917865*9/11/2020 10:56:32 AM",
    "APPLE NO DESCANSA y  CONTRADEMANDA A FORTNITE EpicGames !!!!!!!!_2.mp4*13917865*9/11/2020 10:57:10 AM",
    "APPLE PIERDE OTRA DEMANDA POR ARROGANCIA!!!!!!!.mp4*40763096*9/11/2020 10:57:56 AM",
    "APPLE RETRASA GUERRA CONTRA FACEBOOK QUITANDO ESTA FUNCION DE iOS 14!!!!!!!!.mp4*12422033*9/11/2020 10:57:40 AM",
    "COMO PAGAR MUCHO MENOS POR NETFLIX!!!!!!!! METODO FUNCIONA AL 100!!!!!!!.mp4*12126943*9/11/2020 10:57:34 AM",
    "ESTE ESTABILIZADOR ES ESPECIAL!!!!!!!.mp4*43840852*9/11/2020 10:57:50 AM",
    "iPhone 11 EL TELEFONO MAS VENDIDO DEL MUNDO PORQUE----.mp4*21815170*9/11/2020 10:58:00 AM",
    "MIREN ESTOS HEADPHONES BARATOS Y MUY PRODUCTIVOS!!!!!!!.mp4*30347872*9/11/2020 10:58:04 AM",
    "NO iPhone 12 PARA ESTE EVENTO APPLE!!!!!!!.mp4*21513606*9/11/2020 10:57:18 AM",
    "NOTE 20 Vs Note 20 Ultra VEREDICTO FINAL - CUAL NO COMPRAR !!!!!!!.mp4*14486133*9/11/2020 10:57:44 AM",
    "SACALE JUGO A GOOGLE PHOTOS EN 2020!!!!!!!! #JUEVAPPS!.mp4*33009201*9/11/2020 10:57:30 AM",
    "WHAATTT TE VA A SORPRENDER EL NUEVO RELOJ DE HUAWEI!!!!!!!!.mp4*16792598*9/11/2020 10:57:56 AM",
    303081622,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] MovilZona*0*9/13/2020 8:47:46 PM",
    "El MEJOR SMARTPHONE BARATO - Realme C11 review 💥.mp4*28717739*9/11/2020 10:12:44 AM",
    "Este smartphone CAMBIA DE COLOR!!!.mp4*19748114*9/11/2020 10:12:44 AM",
    "Moto G9 Plus FILTRADO!!!.mp4*18173369*9/11/2020 10:12:36 AM",
    "Motorola NO SE RINDE!!!.mp4*18448346*9/11/2020 10:12:18 AM",
    "Te estaba ESPERANDO.mp4*22151889*9/11/2020 10:12:26 AM",
    107239457,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Paréntesis.com*0*9/13/2020 8:47:47 PM",
    "Chevrolet Tracker Premier 2021, primeras impresiones.mp4*121786353*9/11/2020 10:42:22 AM",
    "Cómo es ir a un autocinema, una alternativa en la nueva normalidad.mp4*53465859*9/11/2020 10:42:16 AM",
    175252212,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] RedesZone*0*9/13/2020 8:47:48 PM",
    "ACTUALÍZATE a Wi-Fi 6 en tu portátil- Cambia la tarjeta WiFi antigua.mp4*28066542*9/11/2020 10:40:28 AM",
    28066542,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Suprapixel*0*9/13/2020 8:47:49 PM",
    "Cuando la copia es tan buena como el original.mp4*42343658*9/11/2020 10:14:16 AM",
    "El mejor teléfono que no podés comprar. Zenfone 7 Pro review.mp4*63471133*9/11/2020 10:14:22 AM",
    "El teléfono que nos merecemos- Poco X3 NFC.mp4*18497428*9/11/2020 10:14:02 AM",
    "ES OFICIAL- Xbox Series S, precio y fecha de lanzamiento.mp4*20925015*9/11/2020 10:14:12 AM",
    145237234,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] TechZone*0*9/13/2020 8:47:50 PM",
    "8 INVENTOS MÁS INGENIOSOS QUE TE SORPRENDERÁN.mp4*44018002*9/11/2020 10:15:00 AM",
    "MÁQUINAS EXTREMAS EN ACCIÓN. ¡NECESITAS VERLAS!.mp4*45130638*9/11/2020 10:14:48 AM",
    89148640,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Tecnofanatico*0*9/13/2020 8:47:51 PM",
    "Apple SI PRESENTARA el nuevo iPHONE 12 en su EVENTO DEL 15 de SEPTIEMBRE.mp4*17381989*9/11/2020 10:16:38 AM",
    "FUNCIONAN POR MEDIO DE MOVIMIENTOS.mp4*33030433*9/11/2020 10:16:44 AM",
    "LO NUEVO DE LG XBOOM GO con Meridian.mp4*49457995*9/11/2020 10:16:52 AM",
    "POR $220 dólares TIENES ESTA BESTIA DE SMARTPHONE.mp4*39626098*9/11/2020 10:16:52 AM",
    "Surface Duo recibe PATADA de los YOUTUBERS, RESUMEN DE NOTICIAS TECH.mp4*68810402*9/11/2020 10:16:32 AM",
    208306917,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] TecnonautaTV*0*9/13/2020 8:47:52 PM",
    "El Samsung que Votes ¡¡¡GRATIS!!!.mp4*16998795*9/11/2020 10:58:40 AM",
    "iPHONE 12 TIENE PROBLEMAS!!!!!!!.mp4*83265521*9/11/2020 10:58:40 AM",
    100264316,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Tecnosys*0*9/13/2020 8:47:52 PM",
    "Las MEJORES APPS DE PELÍCULAS ANDROID  para VER EN SMART TV.mp4*19325726*9/11/2020 10:17:08 AM",
    "Ojo! ASÍ TE ESPÍAN FACEBOOK - NO MÁS!.mp4*10496316*9/11/2020 10:17:18 AM",
    29822042,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Tecvideos TV*0*9/13/2020 8:47:54 PM",
    "10 Mejores JUEGOS ¡GRATIS! del PLAYSTORE que NO CONOCES (Android 2020).mp4*51127778*9/11/2020 10:18:00 AM",
    "3 Mejores AURICULARES Bluetooth 2020 - Muy BARATOS y BUENOS ¡Inalámbricos! y BUEN SONIDO (Edifier).mp4*60035909*9/11/2020 10:18:06 AM",
    "7 NUEVOS INVENTOS de PS3 que NO CONOCES - Por ¡MENOS 2$! Trucos y Accesorios SECRETOS PlayStation 3.mp4*20773139*9/11/2020 10:17:54 AM",
    "TOP 8 🥇Mejores PORTATILES PC ¡BARATOS y BUENOS! para ESTUDIANTES o TRABAJAR (Calidad-Precio 2020).mp4*57117244*9/11/2020 10:17:46 AM",
    189054070,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] Topes de Gama*0*9/13/2020 8:47:57 PM",
    "Con esto GRABARÁS al SIGUIENTE NIVEL!.mp4*37118524*9/11/2020 10:19:02 AM",
    "El GALAXY S21 se CARGA la familia NOTE!!.mp4*34446633*9/11/2020 10:18:30 AM",
    "Hemos PROBADO el FOLD 2!!! Estas son nuestras impresiones.mp4*34802141*9/11/2020 10:19:06 AM",
    "Los mejores SAMSUNG que PUEDES COMPRAR (2020).mp4*23508201*9/11/2020 10:18:56 AM",
    "Los MÓVILES más INCREÍBLES de la ACTUALIDAD!! Recomendaciones TOP.mp4*104986256*9/11/2020 10:18:54 AM",
    "POCO X3 NFC, PRIMERAS IMPRESIONES.mp4*29719900*9/11/2020 10:18:38 AM",
    "Qué LOCURA!!! Realme 7 PRO.mp4*10945432*9/11/2020 10:18:52 AM",
    275527087,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] TuAppleMundo*0*9/13/2020 8:47:58 PM",
    "+10 APPS para ESTUDIANTES que necesitas en 2020.mp4*18410132*9/11/2020 10:20:04 AM",
    "AMONG US para movil GRATIS, +10 TRUCOS para GANAR SIEMPRE 🔥.mp4*29360822*9/11/2020 10:19:54 AM",
    "Así será el APPLE WATCH SE, el reloj BARATO de Apple.mp4*39033584*9/11/2020 10:19:48 AM",
    "iOS 14 beta 7 esconde SECRETOS que debes SABER ✅.mp4*17886540*9/11/2020 10:19:58 AM",
    "iOS 14 beta 8, la ÚLTIMA beta está aquí 😨.mp4*16653114*9/11/2020 10:19:38 AM",
    "OFICIAL! Fecha iPhone 12, iOS 14 y TODOS los PRODUCTOS.mp4*19513435*9/11/2020 10:19:46 AM",
    140857627,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] TuTecnoMundo*0*9/13/2020 8:24:28 PM",
    "Galaxy NOTE 20 ULTRA vs S20 Ultra, iPhone 11 Pro MAX, Pixel 4A y Nord - TEST DE BATERIA EXTREMO!!.mp4*88100550*9/11/2020 10:59:38 AM",
    "Google PIXEL 4A vs OnePlus NORD!! Comparativa de Calidad Precio 2020..mp4*54352589*9/11/2020 11:00:02 AM",
    "Samsung GALAXY FOLD 2  5G vs GALAXY FOLD - Comparativa PLEGABLE!.mp4*109036562*9/11/2020 10:59:58 AM",
    251489701,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[GADGETS Y TECNOLOGIA]\\[Gadgets y Tecnologia] XatakaTv*0*9/13/2020 8:24:30 PM",
    "¡eMule VIVE, LA DESCARGA SIGUE! Llega eMule 0.60a, la primera nueva versión en 10 años.mp4*5954025*9/11/2020 10:21:34 AM",
    "Analizamos la PULSERA de LIDL que quiere COMPETIR con la MI BAND de XIAOMI 😱.mp4*15418083*9/11/2020 10:20:56 AM",
    "ANDROID 11 YA ESTÁ AQUÍ!- NOVEDADES y DIFERENCIAS con ANDROID 10.mp4*9390781*9/11/2020 10:20:48 AM",
    "CÓMO DESACTIVAR TU CUENTA DE INSTAGRAM TEMPORALMENTE 2020.mp4*7488748*9/11/2020 10:21:20 AM",
    "CONFIRMADO- XBOX SERIES S se une a Xbox Series X - ASÍ es la consola MÁS PEQUEÑA de Microsoft.mp4*7227550*9/11/2020 10:21:00 AM",
    "GALAXY Z FOLD 2- ahora sí Samsung, AHORA SÍ!.mp4*16039506*9/11/2020 10:21:26 AM",
    "La GRAN APUESTA de Intel contra AMD- Intel Core 11ª Generación, GPU Intel Iris Xe.mp4*17737225*9/11/2020 10:21:44 AM",
    "LG ThinQ Home- TODA UNA CASA DOMOTIZADA - IFA 2020.mp4*12256969*9/11/2020 10:21:42 AM",
    "Mi Band 4 vs Mi Band 5- ¿cuál me compro-.mp4*18509124*9/11/2020 10:21:16 AM",
    "NUEVO POCO X3 - EL AZOTE de la GAMA MEDIA.mp4*11067275*9/11/2020 10:21:08 AM",
    "NUEVOS HONOR Magicbook, Honor Watch y Honor Pad 6 - Todas las NOVEDADES de HONOR en 7 MINUTOS.mp4*22573015*9/11/2020 10:21:30 AM",
    "Smartphones en árboles = 💰💰💰💰 . Así aumentan los repartidores de Amazon su beneficio.mp4*11682902*9/11/2020 10:21:52 AM",
    155345203,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]*0*9/13/2020 9:24:45 PM",
    0,
    "503*504*505*506*507*508*509*510*511*512*513*514*515*516*517*518*519*520*521*522*523*524*525*526*527*528*529*530*531*532*533"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] 3DJuegos.com*0*9/13/2020 8:24:32 PM",
    "14 CIUDADES DE VIDEOJUEGO que DEBES VISITAR ANTES DE MORIR.mp4*39847984*9/11/2020 12:29:56 PM",
    "ANÁLISIS de IRON HARVEST, algo más que un HEREDERO de COMPANY OF HEROES ¡Estrategia de la buena!.mp4*36316223*9/11/2020 12:29:46 PM",
    "GAMEPLAY COMENTADO- TONY HAWK'S PRO SKATER 1 and 2- El REGRESO del MÁS GRANDE de los JUEGOS de SKATE.mp4*61844784*9/11/2020 12:29:52 PM",
    "OPINIÓN de ANUNCIOS del SUPER MARIO BROS 35 ANIVERSARIO Analizamos POLÉMICA, AUSENCIAS y SORPRESAS.mp4*48925506*9/11/2020 12:30:00 PM",
    "PREGUNTAS a 3DJUEGOS- ¿Volverá DEAD SPACE- ¿SATURACIÓN de SUPERHÉROES- ¿Y SEGA- XCLOUD y PS5.mp4*68777716*9/11/2020 12:29:42 PM",
    "XBOX SERIES S- Lo que SE SABE y lo que NO - PRECIO, FECHA de lanzamiento, POTENCIA y DISEÑO.mp4*21930807*9/11/2020 12:29:22 PM",
    277643020,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] 3DJuegosMX*0*9/13/2020 8:24:33 PM",
    "Rápido y Jugoso- Resumen de noticias 28 de agosto a 4 de septiembre.mp4*31797201*9/11/2020 12:36:24 PM",
    "Tony Hawk's Pro Skater 1 + 2- ¿Vale la pena-.mp4*45099275*9/11/2020 12:36:18 PM",
    76896476,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] Dilerion Games*0*9/13/2020 8:24:35 PM",
    "¿REMAKE DE PRINCE OF PERSIA-,THE WITCHER 3 EN PS5 Y SERIES X, THE LAST OF US 2 EL MAS COMPLETADO....mp4*74001979*9/11/2020 12:00:42 PM",
    "HABLEMOS DE SUPER MARIO 3D ALL STARS , NINTENDO Y LA PERMISIVIDAD IRRACIONAL..mp4*47771017*9/11/2020 12:00:16 PM",
    "LOS LANZAMIENTOS VIDEOJUEGUILES DE SEPTIEMBRE 2020..mp4*92742972*9/11/2020 12:00:58 PM",
    "NUEVAS GRÁFICAS Y REFLEXIÓN SOBRE LAS CONSOLAS, SUPER MARIO 3D ALL STARS, STARFIELD LEJOS Y  MAS....mp4*75191103*9/11/2020 12:01:04 PM",
    "XBOX SERIES S YA ES OFICIAL Y CON PRECIO DE ESCÁNDALO, ANUNCIADO HYRULE WARRIORS AGE OF CALAMITY.mp4*48250348*9/11/2020 12:00:04 PM",
    337957419,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] El REPLICANTE*0*9/13/2020 8:24:36 PM",
    "SONY YA ESTAS TARDANDO!!  XBOX SERIES X Y SERIES S ANUNCIAN FECHA,PRECIO Y ONLINE ¿FREE- Y LA PS5--.mp4*35276024*9/11/2020 12:46:16 PM",
    "😁MEJORES AURICULARES🎧 ECONÓMICOS💸 PARA PS4 2020🆕LUDOS ULTRA.mp4*53629653*9/11/2020 12:46:30 PM",
    "😁MEJORES AURICULARES🎧 ECONÓMICOS💸 PARA PS4 2020🆕LUDOS ULTRA_2.mp4*53629653*9/11/2020 12:46:48 PM",
    142535330,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] Elgranqenk2*0*9/13/2020 8:24:38 PM",
    "GoW Part II- ¡Artistas imaginan batallas impresionantes! - ¡Libro de Lore en camino!.mp4*28259271*9/11/2020 12:45:48 PM",
    "Mario 35 Aniversario- Ports, NUEVOS juegos y demás ANUNCIOS - CTR ¡sin soporte!.mp4*107740026*9/11/2020 12:45:58 PM",
    "PS2- 12 Juegos EXORBITANTEMENTE altos que DEBES probar.mp4*33812625*9/11/2020 12:45:48 PM",
    "Xbox Series S- ¡Revelado! - Series X- Precios y FECHA de lanzamiento - ¡Y MÁS!.mp4*34699373*9/11/2020 12:45:36 PM",
    "Xbox Series S- Precio y fecha OFICIAL - PS5 de $10000 dólares - ¡Xbox Live gratuito!.mp4*37354447*9/11/2020 12:45:30 PM",
    241865742,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] Eurogamerspain*0*9/13/2020 8:24:40 PM",
    "ANÁLISIS, MARVEL'S AVENGERS - ¿se confirman las sospechas-.mp4*47026688*9/11/2020 12:01:12 PM",
    "ARRANCA la GUERRA DE PRECIOS NEXT GEN, toda la info sobre XBOX SERIES S.mp4*45848518*9/11/2020 12:01:08 PM",
    "EL TEST DE OLIVER Y BENJI - ¿Hasta qué punto se parece CAPTAIN TSUBASA al anime-.mp4*65142833*9/11/2020 12:01:30 PM",
    "LOS VIDEOJUEGOS QUE SALEN EN SEPTIEMBRE DE 2020.mp4*84625348*9/11/2020 12:01:24 PM",
    242643387,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] GameNewsOfficial*0*9/13/2020 8:24:41 PM",
    "DEAD BY DAYLIGHT -Descend Beyond- Trailer (2020).mp4*4702316*9/11/2020 12:03:50 PM",
    "HYRULE WARRIORS 2 - Age of Calamity Trailer (2020) Zelda, Nintendo Switch.mp4*16337565*9/11/2020 12:03:58 PM",
    "MARIO KART LIVE Official Trailer (2020) Nintendo Switch.mp4*10988200*9/11/2020 12:03:58 PM",
    "NEW NINTENDO GAME &amp; WATCH CONSOLE Trailer.mp4*5539461*9/11/2020 12:04:02 PM",
    37567542,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] GameSpot Trailers*0*9/13/2020 10:45:25 PM",
    "Adios - Final Trailer.mp4*10341143*9/11/2020 12:39:38 PM",
    "Dead by Daylight- Descend Beyond - Official Cinematic Teaser Trailer.mp4*2942787*9/11/2020 12:39:46 PM",
    "Dead by Daylight- Descend Beyond - Official Collections Trailer.mp4*5981096*9/11/2020 12:39:40 PM",
    "Game _ Watch_ Super Mario Bros. - Official Announcement Trailer.mp4*4070908*9/11/2020 12:40:16 PM",
    "Hyrule Warriors- Age Of Calamity - Official Announcement Trailer.mp4*23411723*9/11/2020 12:39:58 PM",
    "Inside The Xbox Series S - Trailer.mp4*31658959*9/11/2020 12:39:30 PM",
    "Minecraft Dungeons- Creeping Winter – Official DLC Launch Trailer.mp4*6909468*9/11/2020 12:39:44 PM",
    "Super Mario Bros. 35 - Official Announcement Switch Trailer.mp4*5737211*9/11/2020 12:40:08 PM",
    "Tony Hawk's Pro Skater 1 + 2 - Warehouse IRL Trailer.mp4*4834876*9/11/2020 12:39:58 PM",
    "Xbox Series S - Official World Premiere Price &amp; Release Date Reveal Trailer.mp4*6207702*9/11/2020 12:39:56 PM",
    102095873,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] Japan Gemu*0*9/13/2020 10:18:18 PM",
    "EL ULTIMO ADIÓS AL EDIFICIO MAS IMPORTANTE DE SEGA EN TOKYO AKIHABARA - ARCADES - RECREATIVAS.mp4*68728835*9/11/2020 12:48:32 PM",
    "JUGAR A LA GAMEBOY ADVANCE EN LA TV - - BÚSQUEDA EN JAPÓN DE VIDEOJUEGOS - PERIFÉRICOS RAROS-.mp4*55159222*9/11/2020 12:48:22 PM",
    123888057,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] Jarm*0*9/13/2020 10:26:25 PM",
    "Las terceras partes que más me decepcionaron.mp4*82897482*9/11/2020 12:42:16 PM",
    "Los juegos más BELLOS de SATURN (a nivel artístico).mp4*77832715*9/11/2020 12:42:28 PM",
    160730197,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] JinoGamerHC*0*9/13/2020 9:05:58 PM",
    "APPLE vs EPIC GAMES!! NUEVO CIRCO en la industria de los VIDEOJUEGOS.mp4*22619685*9/11/2020 12:08:52 PM",
    22619685,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] Kahuna Games*0*9/13/2020 9:06:00 PM",
    "Llegan las Enfermeras Reaper a Dark Deception !! Capitulo 4 Torment Therapy.mp4*50925362*9/11/2020 12:09:18 PM",
    "Vuelvo a FNAF 4 mucho despues - Reto- Pasarmelo facilito fácil a la primera.mp4*130540702*9/11/2020 12:10:00 PM",
    "Vuelvo a jugar FNAF Sister Location años despues - Reto- Kahunardo Milos.mp4*170512465*9/11/2020 12:09:50 PM",
    351978529,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] Ken Ultimate*0*9/13/2020 10:28:22 PM",
    "¡Ahora sí! XBOX SERIES S es OFICIAL y PRECIO de SERIES X + Así es MARVEL’S AVENGERS.mp4*63154864*9/11/2020 12:10:32 PM",
    "MICROSOFT habla- Momento precio SERIES X y contenido TGS + HORIZON Forgidden West y su audio 3D.mp4*56742591*9/11/2020 12:10:44 PM",
    "Reflexión- De NINTENDO a XBOX ¿ESTAFA o EXCELENCIA-.mp4*58682217*9/11/2020 12:10:40 PM",
    "XBOX SERIES S tiene la llave de la NUEVA GENERACIÓN + Precuela de ZELDA BOTW.mp4*48337632*9/11/2020 12:10:10 PM",
    226917304,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] La Caverna del Gamer*0*9/13/2020 11:09:45 PM",
    "10 SPEEDRUNS de VIDEOJUEGOS que fueron más RÁPIDOS QUE EL RAYO.mp4*40748082*9/11/2020 12:44:12 PM",
    "10 VIDEOJUEGOS INCREÍBLES que puedes TERMINAR EN UNA TARDE.mp4*37261120*9/11/2020 12:43:56 PM",
    "10 VIDEOJUEGOS que CAMBIARON DE GÉNERO en sus SAGAS.mp4*50599052*9/11/2020 12:44:18 PM",
    "EL VEREDICTO- CAPTAIN TSUBASA- RISE OF THE NEW CHAMPIONS -- ¿Los magos del balón-.mp4*55304645*9/11/2020 12:44:10 PM",
    183912899,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] La Poción Roja*0*9/13/2020 11:16:15 PM",
    "LO BUENO y LO MALO de... Super Mario 3D All-Stars.mp4*57795881*9/11/2020 12:47:38 PM",
    "LOS MEJORES JUEGOS RETRO del Pc [MS-DOS _ Pc Gaming].mp4*43119295*9/11/2020 12:47:18 PM",
    "LOS VIDEOJUEGOS PIRATA de El Chavo Del Ocho.mp4*33642961*9/11/2020 12:47:32 PM",
    134558137,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] levelup.com*0*9/13/2020 10:19:36 PM",
    "BITS- ¿Por qué Nintendo saca muchos Marios- - 35.° aniversario de Super Mario Bros..mp4*12001088*9/11/2020 12:32:10 PM",
    "SPEEDRUN- Resumen de noticias - Semana 36 de 2020.mp4*19623188*9/11/2020 12:31:58 PM",
    "Tony Hawk's Pro Skater 1 + 2 - ¿SÍ o NO-.mp4*50587750*9/11/2020 12:31:56 PM",
    "TOP 5- Los mejores videojuegos de Super Mario Bros..mp4*28627524*9/11/2020 12:32:02 PM",
    "Xbox Series S - Primeros detalles.mp4*9969799*9/11/2020 12:31:44 PM",
    120809349,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] Little Jimmy*0*9/13/2020 9:06:06 PM",
    "MAFIA 3 - Bueno, ¿Quién tiene hambre-.mp4*202069037*9/11/2020 12:11:32 PM",
    202069037,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] MeriStation*0*9/13/2020 10:28:23 PM",
    "Marvel's AVENGERS- ANÁLISIS. ¿El JUEGO ESPERADO-.mp4*39079278*9/11/2020 12:13:46 PM",
    "REGRESOS- Cuando los juegos nos hacen VOLVER a LUGARES MÍTICOS.mp4*47354996*9/11/2020 12:13:38 PM",
    "SEA OF STARS- Jugamos al MAYOR HOMENAJE a los JRPG de 16 BITS.mp4*41219479*9/11/2020 12:13:48 PM",
    127653753,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] N Deluxe*0*9/13/2020 9:06:10 PM",
    "¿Qué es y Qué Historia Contará HYRULE WARRIORS- AGE OF CALAMITY- - Nuevo Zelda de Switch - N Deluxe.mp4*47697385*9/11/2020 12:43:10 PM",
    "Lo BUENO y Lo MALO de Super Mario 3D All Stars - N Deluxe.mp4*37288365*9/11/2020 12:43:32 PM",
    "REACTION Super Mario Direct - Super Mario 3D All Stars - N Deluxe.mp4*48304989*9/11/2020 12:43:34 PM",
    "Todos los POWER UPs de Super Mario del PEOR al MEJOR - Parte 1 - N Deluxe.mp4*75328474*9/11/2020 12:43:28 PM",
    208619213,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] NanospeedGamer*0*9/13/2020 11:09:46 PM",
    "CONSEGUIDO DOWNGRADE PS4 7.55 hasta 6.72 - OJO AL ASTERISCO.mp4*13299612*9/11/2020 12:17:00 PM",
    "NUEVA TIENDA PS3 WIKIStore PSN - EDITION , Para HFW y CFW.mp4*15492424*9/11/2020 12:16:52 PM",
    "Nueva Version del Exploit LEEFUL PS4   Leeful V7 - PRUEBALO.mp4*16243664*9/11/2020 12:17:02 PM",
    "Piénsatelo BIEN antes de COMPARTIR TU CUENTA - PROS y CONTRAS   Playstation CUENTAS compartidas.mp4*35594184*9/11/2020 12:16:54 PM",
    "THEFLOW sigue Trabajando en VITA - TombRaider ps1 SUPER MEJORADO!.mp4*11924451*9/11/2020 12:16:48 PM",
    "Ya puedo ser Youtuber GAMER por que Me regalan un PACK GAMING!.mp4*69566800*9/11/2020 12:16:42 PM",
    162121135,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] Nintendo*0*9/13/2020 9:06:12 PM",
    "AVICII Invector Encore Edition - Launch Trailer - Nintendo Switch.mp4*5820725*9/11/2020 12:41:04 PM",
    "Cadence of Hyrule- Crypt of the NecroDancer Feat. The Legend of Zelda Season Pass - Nintendo Switch.mp4*9658575*9/11/2020 12:40:50 PM",
    "Carto - Release Date Trailer - Nintendo Switch.mp4*3093904*9/11/2020 12:40:56 PM",
    "Hyrule Warriors- Age of Calamity - A story 100 years before The Legend of Zelda- Breath of the Wild.mp4*22085442*9/11/2020 12:41:36 PM",
    "Hyrule Warriors- Age of Calamity - Announcement Trailer - Nintendo Switch.mp4*9637685*9/11/2020 12:41:32 PM",
    "Minecraft Dungeons- Creeping Winter DLC - Nintendo Switch.mp4*4590531*9/11/2020 12:41:24 PM",
    "Paradise Killer - Launch Trailer - Nintendo Switch.mp4*5614031*9/11/2020 12:41:50 PM",
    "Ring of Pain - Announcement Trailer - Nintendo Switch.mp4*4265689*9/11/2020 12:41:06 PM",
    "Spellbreak - Launch Trailer - Nintendo Switch.mp4*13536038*9/11/2020 12:41:42 PM",
    "Spinch - Launch Trailer - Nintendo Switch.mp4*3832782*9/11/2020 12:41:50 PM",
    "UNBOXING! Super Mario 3D All-Stars &amp; more.mp4*45575615*9/11/2020 12:41:42 PM",
    127711017,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] NintenDúo*0*9/13/2020 11:16:16 PM",
    "¡¡ANUNCIADO el ZELDA de 2020!! 😱 Hyrule Warriors- La Era del Cataclismo (Nintendo Switch).mp4*11524298*9/11/2020 12:44:42 PM",
    "15 DETALLES IMPRESCINDIBLES del Nuevo ZELDA 🔥 Hyrule Warriors- Era del Cataclismo (Nintendo Switch).mp4*35650526*9/11/2020 12:44:34 PM",
    "25 Secretos INCREÍBLES 🍄 Super Mario Galaxy 2 (Curiosidades).mp4*61326131*9/11/2020 12:44:56 PM",
    "35 DETALLES IMPRESCINDIBLES del SUPER MARIO DIRECT ⭐️ 35 Aniversario Super Mario (Nintendo Switch).mp4*44507081*9/11/2020 12:44:58 PM",
    "Las MEJORAS de Super Mario 3D ALL-STARS 🤩 ¿PORT o REMASTER- (Nintendo Switch).mp4*15950955*9/11/2020 12:44:46 PM",
    168958991,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] Play4Games*0*9/13/2020 11:09:47 PM",
    "QUANTUM ERROR Gameplay Demo PS5 (New Horror Game 2021) 4K.mp4*43925553*9/11/2020 12:21:10 PM",
    "SCAVENGERS Gameplay Trailer (New Survival Game 2020) 4K.mp4*37927272*9/11/2020 12:21:18 PM",
    81852825,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] PlayStation*0*9/13/2020 11:34:12 PM",
    "13 Sentinels- Aegis Rim - Dreams or Reality Trailer - PS4.mp4*4204558*9/11/2020 12:24:16 PM",
    "Black Legend -  Reveal Trailer - PS4, PS5.mp4*2575301*9/11/2020 12:22:34 PM",
    "CASE- Animatronics - Release Trailer - PS4.mp4*2240245*9/11/2020 12:24:50 PM",
    "Crash Bandicoot 4  – Tawna Gameplay Reveal - PlayStation Underground.mp4*81698679*9/11/2020 12:24:18 PM",
    "Deleveled - Launch Trailer - PS4.mp4*3894846*9/11/2020 12:24:30 PM",
    "Doraemon Story of Seasons - Launch Trailer - PS4.mp4*8829191*9/11/2020 12:25:28 PM",
    "FIFA 21 -  Official Career Mode Trailer - PS4.mp4*11341146*9/11/2020 12:25:46 PM",
    "Hyper Scape - Hack Runner Mode Trailer - PS4.mp4*2102991*9/11/2020 12:23:34 PM",
    "Kingdoms of Amalur- Re-Reckoning - Release Trailer - PS4.mp4*8066917*9/11/2020 12:24:24 PM",
    "Minoria - Launch Trailer - PS4.mp4*5026862*9/11/2020 12:22:28 PM",
    "Mortal Kombat 11 Aftermath -  Final Round - PS Competition Center.mp4*124091611*9/11/2020 12:25:14 PM",
    "Mortal Kombat 11- Aftermath - Character Breakdown- Erron Black - PS Competition Center.mp4*13614124*9/11/2020 12:23:24 PM",
    "Mortal Shell - Accolades Trailer - PS4.mp4*3035299*9/11/2020 12:24:32 PM",
    "NBA 2K21 - -Everything Is Game- Launch Spot - PS4.mp4*6458669*9/11/2020 12:25:24 PM",
    "Necromunda- Underhive Wars - Launch Trailer - PS4.mp4*5652098*9/11/2020 12:24:22 PM",
    "Ride 4 - Next Gen Trailer - PS4, PS5.mp4*6108513*9/11/2020 12:25:20 PM",
    "Swordsman VR - Official Cinematic Trailer - PS VR.mp4*1214783*9/11/2020 12:22:40 PM",
    "The Mooseman - Physical Release Trailer - PS4.mp4*2344669*9/11/2020 12:25:42 PM",
    "The Riftbreaker - Base Building Trailer - PS4.mp4*21440450*9/11/2020 12:24:42 PM",
    "The Sims 4 Star Wars- Journey to Batuu - Official Gameplay Trailer - PS4.mp4*29456304*9/11/2020 12:25:14 PM",
    "Tony Hawk's Pro Skater 1 + 2 - Warehouse Stunt Trailer - PS4.mp4*5056186*9/11/2020 12:25:32 PM",
    "Tony Stewart's All-American Racing - Gameplay Trailer - PS4.mp4*5671408*9/11/2020 12:25:14 PM",
    354124850,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] PolGames*0*9/13/2020 11:21:34 PM",
    "ESTE MODO ES UNA LOCURA 😱  Call of Duty Mobile.mp4*48775112*9/11/2020 12:32:50 PM",
    "Nuevas ARMAS Temporada 10 COD MOBILE 😱 PASE DE BATALLA FILTRADO -Nuevas skins y personajes.mp4*39855019*9/11/2020 12:32:38 PM",
    "PASE DE BATALLA Temporada 10 -Nuevos personajes y armas- 😱 Call of Duty Mobile.mp4*50711690*9/11/2020 12:32:48 PM",
    "TRAILER y FECHA DE SALIDA Temporada 10 😱 Call of Duty Mobile.mp4*34400797*9/11/2020 12:32:28 PM",
    173742618,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] SpineCard*0*9/13/2020 9:24:29 PM",
    "La portatil MAS BARATA de INTERNET-!-!- vista en Wish!!!!.mp4*31079258*9/11/2020 12:47:52 PM",
    31079258,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] TheWizWiki*0*9/13/2020 11:07:51 PM",
    "Esto cambiará la mirada de tu PS4.mp4*9492567*9/11/2020 12:12:16 PM",
    "Jugar PlayStation 3 en PC.mp4*37148547*9/11/2020 12:12:18 PM",
    "La Capturadora de Video Perfecta para tu Consola y tu Bolsillo.mp4*42377400*9/11/2020 12:12:08 PM",
    "Mejora tu PS3 con esta Novedad.mp4*5966699*9/11/2020 12:12:22 PM",
    "No ser BANEADO 😈 nunca en PS3 haciendo esto.mp4*8586534*9/11/2020 12:12:26 PM",
    103571747,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] TuberViejuner*0*9/13/2020 9:24:32 PM",
    "⚙️ REVIVIENDO UN PC RETRO DEL ATAQUE DE UN PARÁSITO CORROSIVO.mp4*39811553*9/11/2020 12:42:46 PM",
    39811553,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] Vandal*0*9/13/2020 11:28:08 PM",
    "10 COSAS que NO TIENEN SENTIDO en los VIDEOJUEGOS.mp4*42713327*9/11/2020 12:31:00 PM",
    "Análisis MARVEL'S AVENGERS (PS4, Xbox One, PC) ¡Vengadores, reuníos! - YouTube.mkv*38644926*9/11/2020 12:31:00 PM",
    "Análisis NBA 2K21 (Xbox One, PS4, PC, Switch ) El ÚLTIMO BAILE de la GENERACIÓN.mp4*60284747*9/11/2020 12:30:34 PM",
    "HYRULE WARRIORS- La ERA del CATACLISMO anunciado para NINTENDO SWITCH (tráiler español).mp4*9364403*9/11/2020 12:30:48 PM",
    "XBOX SERIES S la consola NEXT-GEN ECONÓMICA- A la venta el 10 de NOVIEMBRE por 299 euros.mp4*5756505*9/11/2020 12:30:40 PM",
    156763908,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] Xbox*0*9/13/2020 11:17:05 PM",
    "Dead by Daylight - Descend Beyond - Official Trailer.mp4*2055741*9/11/2020 12:37:46 PM",
    "Get EA Play with Xbox Game Pass Ultimate &amp; Xbox Game Pass for PC this Holiday.mp4*11725745*9/11/2020 12:37:20 PM",
    "Hyper Scape- Limited-Time Game Mode - Hack Runner Trailer - Ubisoft [NA].mp4*3576532*9/11/2020 12:38:08 PM",
    "Inside the Xbox Series S.mp4*36143432*9/11/2020 12:37:38 PM",
    "Minecraft Dungeons- Creeping Winter – Official Launch Trailer.mp4*4633604*9/11/2020 12:38:22 PM",
    "NBA 2K21- -Everything Is Game- Launch Spot.mp4*6382893*9/11/2020 12:38:52 PM",
    "Necromunda- Underhive Wars - Launch Trailer.mp4*8409274*9/11/2020 12:37:50 PM",
    "New Releases, Xbox Game Pass Additions, and More - This Week on Xbox.mp4*59824454*9/11/2020 12:38:48 PM",
    "SMITE - Odyssey- Reckoning Event Overview.mp4*4546584*9/11/2020 12:37:54 PM",
    "Star Trek Online - House Divided Launch Trailer.mp4*4463026*9/11/2020 12:38:22 PM",
    "Tell Me Why Chapter Two.mp4*2335378*9/11/2020 12:38:26 PM",
    "The Sims™ 4 Star Wars™- Journey to Batuu - Official Gameplay Trailer.mp4*16487028*9/11/2020 12:38:36 PM",
    "Tony Hawk - Behind The Scenes Tony Hawk’s™ Pro Skater™ 1 and 2.mp4*5856525*9/11/2020 12:39:00 PM",
    "Tony Hawk Skates the Warehouse from #THPS 1+2 ... In Real Life.mp4*4972413*9/11/2020 12:38:52 PM",
    "Tony Stewart's All-American Racing Gameplay Trailer.mp4*5855902*9/11/2020 12:38:42 PM",
    "Xbox All Access - Your All-Inclusive Pass to Xbox.mp4*6483596*9/11/2020 12:37:30 PM",
    "Xbox Series S - World Premiere Reveal Trailer.mp4*5841894*9/11/2020 12:38:14 PM",
    189594021,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[JUEGOS Y CONSOLAS]\\[Juegos y Consolas] Zico Tops*0*9/13/2020 9:24:45 PM",
    "¿Puedes Vencer Minecraft en Dificultad Pesadilla- (NO lo intentes).mp4*303381966*9/11/2020 12:28:36 PM",
    303381966,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]*0*9/13/2020 9:56:49 PM",
    0,
    "535*536*537*538*539*540*541*542*543*544*545*546*547*548*549*550*551*552*553*554*555*556*557*558*559"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Ale90cb*0*9/13/2020 11:24:14 PM",
    "3 PRODUCTOS PARA ELIMINAR LAS MARCAS DE ACNÉ.mp4*59208845*9/6/2020 2:34:37 PM",
    "ARRÉGLATE CONMIGO PARA UNA CITA! Ad.mp4*69059128*9/6/2020 2:35:15 PM",
    128267973,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] alpha.m*0*9/13/2020 9:25:11 PM",
    "10 EASY Ways To Be BETTER LOOKING! (IN 24 HOURS).mp4*63485133*8/31/2020 10:28:32 PM",
    "10 Min FAT BURNING Morning Routine! (Do This EVERY Day at Home).mp4*71375928*8/31/2020 10:28:52 PM",
    "5 Everyday Things HOLDING YOU BACK!.mp4*51466840*8/31/2020 10:29:12 PM",
    "I Tested WEIRD TikTok Style Hacks... So You DON'T Have to!.mp4*59485660*8/31/2020 10:29:54 PM",
    "I Tried Fasting For 30 Days (SHOCKED at What Happened to My Body).mp4*77974579*8/31/2020 10:30:22 PM",
    323788140,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Aurora Amor por el Maquillaje*0*9/13/2020 9:25:12 PM",
    "PARTE 1 ... review y maquilllaje coleccion WW84  de REVLON- auroramakeup.mp4*81710141*9/6/2020 2:36:10 PM",
    81710141,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Belleza Con Clarissa*0*9/13/2020 9:25:17 PM",
    "ME QUEDO MUY CORTO EL VESTIDO FT AMICLUBWEAR.mp4*52115180*8/31/2020 10:30:34 PM",
    52115180,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Bluman*0*9/13/2020 9:53:50 PM",
    "Man Gets FIRST Haircut in 3 Years - Mens Long to Short Hair Transformation.mp4*43841850*8/31/2020 10:27:40 PM",
    43841850,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Bylaumk*0*9/13/2020 9:53:58 PM",
    "Essence &amp; Catrice TANSation Collection.mp4*276714360*8/31/2020 10:32:58 PM",
    "Maquillaje NEON  Tendencia Verano 2020 (look completo).mp4*167061490*8/31/2020 10:32:44 PM",
    443775850,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Chula*0*9/13/2020 9:54:02 PM",
    "ARREGLATE CONMIGO MAQUILLAJE, OUTFIT CABELLO Y CHISME.mp4*185900199*9/6/2020 2:37:58 PM",
    "CHISME CON LA CHULA ARREGLATE CONMIGO PARA IR A LA OFICINA.mp4*166287650*9/6/2020 2:39:30 PM",
    352187849,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Fashion Channel*0*9/13/2020 11:24:55 PM",
    "7 Minutes of Chaos 🎀📚Illumicrate Unboxing.mp4*132003331*9/2/2020 5:29:26 PM",
    "ANTEPRIMA Spring Summer 2009 Milan - Fashion Channel.mp4*110991963*9/2/2020 5:22:52 PM",
    "ANTON GIULIO GRANDE #4 HC SS 1999 Rome - Fashion Channel.mp4*17471798*9/2/2020 5:20:42 PM",
    "BALMAIN Spring 2020 Paris - Fashion Channel.mp4*289977617*9/2/2020 5:49:54 PM",
    "BARBARA BUI Women's Fall 2011 Paris - Fashion Channel.mp4*61225842*9/2/2020 5:16:30 PM",
    "CELINE The Dancing Kid Homme Highlights Spring 2021 Paris - Fashion Channel.mp4*72110507*9/2/2020 5:23:48 PM",
    "CHRISTOPHE SAUVAT Spring 2015 Lisbon - Fashion Channel.mp4*141285983*9/2/2020 5:19:04 PM",
    "FERNANDO ALBERTO Atelier Spring 2018 AHF Los Angeles - Fashion Channel.mp4*85388889*9/2/2020 5:18:40 PM",
    "JEAN LOUIS SCHERRER #4 HC SS 1999 Paris - Fashion Channel (1).mp4*19887522*9/2/2020 5:17:38 PM",
    "JEAN LOUIS SCHERRER #4 HC SS 1999 Paris - Fashion Channel.mp4*19887522*9/2/2020 5:15:12 PM",
    "KAHINI Spring 2020 LAFW by AHF Los Angeles - Fashion Channel (1).mp4*49744574*9/2/2020 5:16:52 PM",
    "KAHINI Spring 2020 LAFW by AHF Los Angeles - Fashion Channel.mp4*49744574*9/2/2020 5:16:10 PM",
    "KRISTINA TI Spring 2013 Milan - Fashion Channel (1).mp4*61288392*9/2/2020 5:19:36 PM",
    "KRISTINA TI Spring 2013 Milan - Fashion Channel.mp4*61288392*9/2/2020 5:19:24 PM",
    "RAQUEL ZERBIB Swim UpCycle Challenge PARAISO Miami Beach 2020 - Fashion Channel.mp4*27873107*9/2/2020 5:23:40 PM",
    "ROBERTO CAVALLI Spring 1999 Milan - Fashion Channel.mp4*73055331*9/2/2020 5:23:06 PM",
    "ROCCO BAROCCO Spring 1999 Milan - Fashion Channel (1).mp4*170266160*9/2/2020 5:25:22 PM",
    "ROCCO BAROCCO Spring 1999 Milan - Fashion Channel.mp4*170266160*9/2/2020 5:19:54 PM",
    "SACAI Spring 2020 Highlights Paris - Fashion Channel (1).mp4*70205535*9/2/2020 5:22:54 PM",
    "SACAI Spring 2020 Highlights Paris - Fashion Channel.mp4*70205535*9/2/2020 5:19:10 PM",
    "SHROUD FDS THE INNOVATORS Resort 2019 MBFW Australia - Fashion Channel.mp4*30673069*9/2/2020 5:19:58 PM",
    "SITA MURT Spring 2010 Madrid - Fashion Channel.mp4*229154262*9/2/2020 5:22:18 PM",
    "STYLE IT with Girly Hacks! 12 Fashion Hacks and DIY Clothing Ideas by Crafty Panda.mp4*97169019*9/2/2020 5:20:00 PM",
    "THIERRY MUGLER #3 HC SS 1999 Paris - Fashion Channel.mp4*12462591*9/2/2020 5:20:22 PM",
    "VERSACE Spring 2013 Milan - Fashion Channel.mp4*163121858*9/2/2020 5:25:50 PM",
    "YASYA MINOCHKINA Spring Summer 2020 MBFW Moscow - Fashion Channel.mp4*176586097*9/2/2020 5:31:38 PM",
    "YULINKA Spring 2017 Odessa - Fashion Channel.mp4*58635733*9/2/2020 5:16:56 PM",
    "ZELLA MAY FDS THE INNOVATORS Resort 2019 MBFW Australia - Fashion Channel.mp4*32792960*9/2/2020 5:20:30 PM",
    2554764323,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Ideas en 5 minutos CHICAS*0*9/13/2020 10:34:16 PM",
    "14 COSAS DIVERTIDAS QUE NADIE TE DICE SOBRE EL EMBARAZO  MOMENTOS DE CHICAS PARA IDENTIFICARSE.mp4*43422142*9/2/2020 5:34:58 PM",
    "21 COSAS QUE LAS CHICAS NO SABEN SOBRE SU PERÍODO.mp4*53516237*9/2/2020 5:33:50 PM",
    "23 LOCAS IDEAS DE BELLEZA PARA CHICAS VALIENTES.mp4*144679707*9/2/2020 5:29:12 PM",
    "23 TRUCOS DE BELLEZA INCREÍBLES QUE REALMENTE FUNCIONAN.mp4*121873245*9/2/2020 5:32:10 PM",
    "25 TRUCOS DE ROPA QUE CAMBIARÁN TU VIDA.mp4*67442756*9/2/2020 5:33:32 PM",
    "26 IMPRESIONANTES TRUCOS DE ROPA QUE TE AHORRARÁN DINERO.mp4*74084847*9/2/2020 5:33:46 PM",
    "26 TRUCOS DE PELO LARGO QUE DEBES HABER VISTO ANTES.mp4*65700548*9/2/2020 5:31:30 PM",
    "27 TRUCOS DE BELLEZA NATURAL QUE DEBEN SABER.mp4*71050557*9/2/2020 5:28:56 PM",
    "27 TRUCOS PARA ACAMPAR QUE TIENES QUE PROBAR (1).mp4*109785214*9/2/2020 5:34:30 PM",
    "27 TRUCOS PARA ACAMPAR QUE TIENES QUE PROBAR.mp4*109785214*9/2/2020 5:29:50 PM",
    "28 TRUCOS BRILLANTES PARA CHICAS.mp4*139486583*9/2/2020 5:28:42 PM",
    "29 PRÁCTICOS TRUCOS DE VERANO E IDEAS DE BRICOLAJE PAR TU PRÓXIMO DÍA DE PLAYA (1).mp4*78758510*9/2/2020 5:32:36 PM",
    "29 PRÁCTICOS TRUCOS DE VERANO E IDEAS DE BRICOLAJE PAR TU PRÓXIMO DÍA DE PLAYA.mp4*78758510*9/2/2020 5:31:26 PM",
    "29 TRUCOS DE MAQUILLAJE Y BELLEZA INCREÍBLES.mp4*68918503*9/2/2020 5:31:42 PM",
    "30 TRUCOS CON ROPA QUE SALVARÁN VIDAS.mp4*88111264*9/2/2020 5:33:14 PM",
    "55 TRUCOS QUE AÚN FUNCIONAN A PESAR DEL TIEMPO.mp4*71674654*9/2/2020 5:35:22 PM",
    "Ideas de belleza increíbles para chicas.mp4*67808637*9/2/2020 5:34:36 PM",
    "IDEAS LOCAS DE MANICURA II 26 TRUCOS DE BELLEZA QUE TODA CHICA DEBERÍA CONOCER.mp4*64290059*9/2/2020 5:33:16 PM",
    "LOS 30 TRUCOS DE COCINA MÁS RÁPIDOS DE LA HISTORIA.mp4*98279451*9/2/2020 5:26:38 PM",
    "Novia Rica vs Novia Pobre  12 Momentos Graciosos e Incómodos.mp4*95081090*9/2/2020 5:35:48 PM",
    1712507728,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Jackie Hernandez*0*9/13/2020 11:34:01 PM",
    "COMIENZA la REMODELACIÓN de el CUARTO DE LAVADO 😱 - DEMOLICIÓN + BUSCANDO PISOS NUEVOS 🤩.mp4*263548515*9/2/2020 5:41:46 PM",
    "LA CONOCI en una AGENCIA de MODELAJE 💃🏻 DOÑA OLI TRAJO unas RICAS ENCHILADAS 😋VAMOS A 🏠DE TANNIA.mp4*170571700*9/2/2020 5:38:50 PM",
    "LA ENFERMA YA ETSA MEJOR!! 😊INSTRUCCIONES DEL DR 🐶COMPRAMOS LA PUERTA PARA PERROS Y NO FUNCIONO! �.mp4*94002695*9/2/2020 5:38:32 PM",
    "MI HIJA AUBREY y sus IDEAS.... ASÍ QUEDO CON este CAMBIO DE LOOK!! SANTO DIOS 😱.mp4*111259427*9/2/2020 5:38:26 PM",
    "NO TENGO GANAS DE ESCUCHAR ESOS DRAMAS 😒SASCHA FITNESS este PRODUCTO ESTA 😳ESTA NIÑA ME LLORA MUCH.mp4*75735136*9/2/2020 5:37:40 PM",
    "NUNCA JAMAS!! 1 HORA ESPERADO por unos TACOS! 😱LES MUESTRO mi asistente QUIEN me TOMA LAS FOTOS 🙈.mp4*66844715*9/2/2020 5:35:46 PM",
    "SERA POSIBLE UN TRAGALUZ EN MI CASA 🤔 - ARMANDO LA PUERTA para LAS PERITAS 🐶DIGANME SI ESTO SIRVE.mp4*158335694*9/2/2020 5:39:10 PM",
    940297882,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Jeamileth Doll*0*9/13/2020 9:56:46 PM",
    "MAQUILLAJE PARA PRINCIPIANTES PASO A PASO  TECNICA DE ARRASTRE (UNA DE LAS MÁS UTILIZADAS).mp4*282078238*9/6/2020 2:42:20 PM",
    "RESEÑA DE LA NUEVA PALETA DE PAOLA!! 👽  OMG Y ESTOS COLORES 😳.mp4*201955318*9/6/2020 2:44:21 PM",
    "TENDENCIA DE MAQUILLAJE AHUMADO FUEGO 🔥 LOOK BRASILERO.mp4*264004238*9/6/2020 2:47:03 PM",
    748037794,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Joryck*0*9/13/2020 9:56:52 PM",
    "TENDENCIAS PARA OTOÑO 🍁 INVIERNO ❄️  MODA 2020-2021 - - FASHION TRENDS - JORYCK.mp4*190798272*9/2/2020 5:19:04 PM",
    190798272,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Laura Agudelo*0*9/13/2020 11:19:26 PM",
    "Haz ESTO para que te AME con LOCURA - Lau.mp4*42723961*9/2/2020 5:39:56 PM",
    "Haz ESTO para que te EXTRAÑE como LOCO - Lau.mp4*55601110*9/2/2020 5:40:12 PM",
    "Señales de que te están USANDO y no te habías dado CUENTA - Lau.mp4*48152434*9/2/2020 5:40:32 PM",
    146477505,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Lilia Cortés*0*9/13/2020 9:51:12 PM",
    "Mi Rutina de Maquillaje con cubrebocas, larga duración sin transferencia - Lilia Cortés.mp4*108086258*9/2/2020 5:20:50 PM",
    108086258,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Lizy P*0*9/13/2020 10:23:45 PM",
    "COMPRÉ LOS PRODUCTOS MÁS RAROS y CURIOSOS DE ALIEXPRESS 😱 ¿FUNCIONAN.mp4*174803998*9/6/2020 2:48:47 PM",
    "SE ARRANCA LA PIEL! 😨 LAS TÉCNICAS DE MAQUILLAJE MÁS EXTREMAS.mp4*67037552*9/2/2020 5:41:32 PM",
    241841550,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Mariale*0*9/13/2020 11:19:28 PM",
    "DISEÑOS DE UÑAS QUE NO DEBERIAN EXISTIR PARTE 6  Mariale.mp4*73704672*9/2/2020 5:42:20 PM",
    "El Video MAS SATISFACTORIO para ver antes de DORMIR 4  Mariale.mp4*175317025*9/2/2020 5:45:04 PM",
    "TIKTOKS QUE DEBES VER (ANTES DE QUE CIERRE)  Mariale.mp4*50932059*9/2/2020 5:43:06 PM",
    "TRANSFORMACIONES de CUERPO increíbles - ANTES y DESPUES  Mariale.mp4*48628224*9/2/2020 5:43:10 PM",
    348581980,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Mytzi Cervantes*0*9/13/2020 11:13:03 PM",
    "Huda Beauty Look completo con productos nuevos !!! - Mytzi Cervantes.mp4*130600303*9/2/2020 5:22:52 PM",
    130600303,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] NuryJimenez*0*9/13/2020 11:30:13 PM",
    "¡¡MEGA HAUL DE CAJAS MISTERIOSAS!!.mp4*182045537*9/2/2020 5:47:40 PM",
    "Me Pruebo VESTIDOS DE NOVIA muy BARATOS de TIENDA CHINA!! JJsHouse.mp4*54782273*9/2/2020 5:44:00 PM",
    "REGRESE A LA LISTA DE PR!! Lo que no me llega a MEXICO.mp4*120273578*9/2/2020 5:45:18 PM",
    357101388,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Pautips*0*9/13/2020 11:26:20 PM",
    "LES PRESENTO A COOKIE!! - Pautips.mp4*132646249*9/6/2020 2:50:03 PM",
    "YOUTUBERS dibujan mi Maquillaje 2.0 ( Mis pastelitos, Juanpa Zurita,Lau Mejia y La Mafemendez!).mp4*146592721*9/6/2020 2:51:30 PM",
    279238970,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Pretty and Olé*0*9/13/2020 9:51:24 PM",
    "TODO LO QUE SE LLEVA ESTE OTOÑO Moda FW20 - Pretty and Olé.mp4*34997334*9/2/2020 5:25:22 PM",
    34997334,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] RosyMcMichael*0*9/13/2020 9:51:25 PM",
    "ELF- PROBANDO TODO LO NUEVO, SON SUPER ECONOMICOS... PERO SON BUENOS-.mp4*303107424*9/2/2020 5:28:28 PM",
    303107424,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Sandra Cires Art*0*9/13/2020 10:33:22 PM",
    "TRANSFORMACIÓN EXTREMA! 😱 UN SETUP TODO ROSA! 😍 SORPRESA ÉPICA!!! Pink Gaming SetUp 🔥 SandraCiresArt.mp4*139002034*9/6/2020 2:52:58 PM",
    139002034,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Slikhaar TV - Mens hair*0*9/13/2020 10:40:45 PM",
    "5 Trendy Football Players Hairstyles for You to Try on 2020.mp4*109831068*9/2/2020 5:27:30 PM",
    109831068,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Whatthechic*0*9/13/2020 11:24:53 PM",
    "ESTO ES LO QUE VA SUCEDER (DESPUÉS DEL BREAK UP) - What The Chic.mp4*194257483*9/6/2020 2:54:48 PM",
    "FOTOS CREATIVAS EN CASA - SOLO CON CELULAR - What The Chic.mp4*116732176*9/6/2020 2:55:57 PM",
    310989659,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[MODA Y BELLEZA]\\[Moda y Belleza] Yuya*0*9/13/2020 9:51:33 PM",
    "La historia de mi mascotita ¡Te presento a Majo!  ❤️ -Yuya.mp4*173504906*9/6/2020 2:57:42 PM",
    173504906,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]*0*9/13/2020 9:51:47 PM",
    0,
    "561*562*563*564*565*566*567*568*569*570*571*572*573*574*575*576*577*578*579*580*581*582*583*584*585*586*587"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] ASUS México*0*9/13/2020 10:40:47 PM",
    "ASUS ProArt StudioBook Pro.mp4*8595990*9/11/2020 1:02:22 PM",
    "ASUS ZenBook Belleza atemporal, portabilidad sin esfuerzo.mp4*8583493*9/11/2020 1:02:10 PM",
    "ROG Zephyrus Duo GX550.mp4*4952191*9/11/2020 1:02:22 PM",
    "Unboxing de ROG Strix G15 Electro Punk Cherri Edition por Cherrigan.mp4*46303462*9/11/2020 1:02:36 PM",
    "Unboxing ROG Strix G15 Glacier Blue Happy Edition  por Happy Musaraña.mp4*34063628*9/11/2020 1:02:20 PM",
    102498764,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Benchmark*0*9/13/2020 11:19:29 PM",
    "GTX 1080 Ti in 2020 - Is Buying Used Worth It-.mp4*74963519*9/11/2020 12:49:00 PM",
    "i5-10600K vs. Ryzen 7 3700X - Test with RTX 2080 Ti (RTX 3070).mp4*76697175*9/11/2020 12:49:14 PM",
    "Will Ryzen 5 3600X Bottleneck The RTX 2080 Ti (RTX 3070).mp4*54982577*9/11/2020 12:49:18 PM",
    206643271,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Compra Gamer*0*9/13/2020 9:51:39 PM",
    "CG RESPONDE __ #CompraGamerTV.mp4*143282414*9/11/2020 1:03:10 PM",
    143282414,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Droga Digital*0*9/13/2020 11:30:15 PM",
    "¿Audífonos premium pero en CLON- ¿Quién le copió a quien- -Trust Wayman - Droga Digital.mp4*46946507*9/11/2020 12:50:38 PM",
    "MODDING RAPIDO con RGB (MAS FPS) para CONTROL de PC con este KIT de NINTENDO SWITCH! - Droga Digital.mp4*60167710*9/11/2020 12:50:28 PM",
    "Motherboards LISTAS para RTX 3000 en REBAJA con el Dr Descuentos - Droga Digital.mp4*15940059*9/11/2020 12:50:24 PM",
    "RTX 3070ti y 3090 SUPER- AMD lanza ¿Bicicletas- Apple ¿fabricará tarjetas Gráficas- - Droga Digital.mp4*100792674*9/11/2020 12:50:10 PM",
    "USOS para la inteligencia ARTIFICIAL de TU CPU! Explicado por el Tio Dell Workstation -Droga Digital.mp4*55827370*9/11/2020 12:49:54 PM",
    279674320,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] El Rincón de Varo*0*9/13/2020 9:51:43 PM",
    "SUBIRAN los PRECIOS de RTX 3090_3080_3070!-😥y TIGER LIKE MEJOR que RYZEN 4000!- - Noticias PC.mp4*44357327*9/11/2020 12:51:18 PM",
    44357327,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Guille Ghemi*0*9/13/2020 11:22:44 PM",
    "Smartwatch- ¿REALMENTE valen la pena- - Huawei GT2e.mp4*40691043*9/11/2020 1:05:46 PM",
    "Ya dejen de ser tan Fanboys. (AMD, Nvidia, Intel, Etc).mp4*38225929*9/11/2020 1:05:38 PM",
    78916972,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Hardware 360º*0*9/13/2020 11:22:45 PM",
    "RTX 3000 ¿De verdad son tan potentes o es puro marketing- - Ampere a fondo.mp4*71721855*9/11/2020 12:51:54 PM",
    "Tiger Lake- El resurgir de intel ¿Cuando podrás comprar las RTX 3000- - Noticias de la semana 149.mp4*168597378*9/11/2020 12:51:48 PM",
    240319233,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] HardwareCanucks*0*9/13/2020 11:30:16 PM",
    "An ALMOST Perfect Ryzen Ultrabook - ASUS ZenBook 14 Review.mp4*30684187*9/11/2020 12:52:24 PM",
    "OnePlus 8 Pro - A Long Term User Review.mp4*41439964*9/11/2020 12:52:18 PM",
    "These Custom RTX 3080, RTX 3070, RTX 3090 Cards Look AMAZING!.mp4*53294463*9/11/2020 12:52:32 PM",
    125418614,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] LinusTechTips*0*9/13/2020 11:19:31 PM",
    "I can't believe how much I paid for this....mp4*46319660*9/11/2020 1:01:38 PM",
    "I should have hired a professional....mp4*37327288*9/11/2020 1:01:34 PM",
    "Nvidia gave us their internal tools… What could go wrong-.mp4*49796470*9/11/2020 1:01:28 PM",
    "The WORST iMac to buy.mp4*23698133*9/11/2020 1:01:18 PM",
    "We fixed Windows 10 - Microsoft will HATE this!.mp4*48681261*9/11/2020 1:01:48 PM",
    205822812,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Michael Quesada*0*9/13/2020 9:24:17 PM",
    "NVIDIA rompió el mercado! ¿Qué pasará con AMD y con precios de otras GPUs- Sillón del Pensamiento.mp4*63034533*9/11/2020 12:52:46 PM",
    63034533,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Nate Gentile*0*9/13/2020 9:24:18 PM",
    "¡NVIDIA Me envió un PROTOTIPO SECRETO!.mp4*112188754*9/11/2020 3:24:26 PM",
    112188754,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Optimum Tech*0*9/13/2020 9:24:20 PM",
    "RTX 3080 -Flow Through- Cooler Explained.mp4*14010433*9/11/2020 1:05:12 PM",
    "SFF Personal Rig Upgrades + RTX 3090 Plans.mp4*21617279*9/11/2020 1:05:04 PM",
    35627712,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] OverCluster*0*9/13/2020 9:24:21 PM",
    "A TIRAR a la BASURA las RTX 2000 - Morcilla de Noticias.mp4*76007112*9/11/2020 12:53:38 PM",
    "Es REALMENTE un TODO TERRENO - Corsair Nightsword RGB.mp4*45061362*9/11/2020 12:53:42 PM",
    "🔋 HASTA te CARGAN el CELULAR - PAMU Slide Plus + Mini.mp4*40530665*9/11/2020 12:53:24 PM",
    161599139,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Paulshardware*0*9/13/2020 9:24:22 PM",
    "Lola's Computer is SLOW.mp4*86381632*9/11/2020 12:59:16 PM",
    86381632,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Proto HW &amp; Tec*0*9/13/2020 9:24:28 PM",
    "AMD EN PELIGRO Nuevos procesadores INTEL 11va Generación..mp4*29330670*9/11/2020 12:55:22 PM",
    "El teclado de 60% más BARATO es MECÁNICO e INALAMBRICO.mp4*53965938*9/11/2020 12:55:10 PM",
    "El último ensamble económico con core i3 9100f.mp4*89443247*9/11/2020 12:54:46 PM",
    "La más costosa y rápida laptop del mercado..mp4*53559640*9/11/2020 12:55:18 PM",
    "Laptop MAC vs Laptop Windows ¿Cuál es mejor-.mp4*63874133*9/11/2020 12:55:02 PM",
    "Muy ECONOMICO, ¿pero PELIGROSO- - Drevo Falcon.mp4*58055302*9/11/2020 12:55:08 PM",
    348228930,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] RinconStiff*0*9/13/2020 9:24:29 PM",
    "🔥La mejor grafica de entrada-- RX 5500xt vs Gtx1650 vs Gtx 1650super 😱😱🔥🔥🔥.mp4*53254055*9/11/2020 1:06:02 PM",
    53254055,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Sara's Tech*0*9/13/2020 9:24:30 PM",
    "¿Cuánto tiempo tardo ARMANDO UNA PC GAMER- 🔥⏰.mp4*57471117*9/11/2020 12:58:34 PM",
    57471117,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Sfdx Show*0*9/13/2020 11:21:35 PM",
    "RTX 3000 Geniales PERO!.mp4*21503488*9/11/2020 12:55:36 PM",
    21503488,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Spartan Geek*0*9/13/2020 9:24:32 PM",
    "Armamos una PC Gamer -Monstruo- y te Decimos Paso a Paso Como escoger sus Componentes.mp4*41703597*9/11/2020 1:00:54 PM",
    41703597,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] TechnoReviews - Hardware &amp; Periféricos*0*9/13/2020 10:39:50 PM",
    "El PRESUPUESTO PC PERFECTO para COMPRAR YA! ✅ SEPTIEMBRE 2020 😱.mp4*56770493*9/11/2020 12:56:18 PM",
    "Los HEADSET TRUST que DEBERÍAS probar 😱.mp4*21179931*9/11/2020 12:56:02 PM",
    "REACCIONANDO A SUS SETUPS 😨 -MEJORA TU SETUP- 4K EDITION 🔥.mp4*39132310*9/11/2020 12:56:12 PM",
    117082734,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Testing Games*0*9/13/2020 11:17:04 PM",
    "Core i5 10600K vs Core i7 9700K Test in 10 Games.mp4*89022611*9/11/2020 1:04:30 PM",
    "GTX 1080 Ti vs RX 5700 XT in 4K.mp4*77093280*9/11/2020 1:04:50 PM",
    "Ryzen 7 4700G VEGA 8 vs GT 1030 Test in 7 Games.mp4*77510982*9/11/2020 1:04:46 PM",
    243626873,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Toro Tocho Reviews*0*9/13/2020 9:24:35 PM",
    "Le monto este PC a un SUSCRIPTOR para que VUELE en FLIGHT SIMULATOR!✈️✈️✈️.mp4*27106944*9/11/2020 12:57:16 PM",
    27106944,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Tortilla Squad*0*9/13/2020 9:24:38 PM",
    "¡La nueva PC de la NASA para edición de Gabbhy; setup perfecto para Premiere Pro!.mp4*37945021*9/11/2020 1:03:42 PM",
    "¿Tiene AMD alguna oportunidad con las batallas que vienen-.mp4*49051458*9/11/2020 1:03:48 PM",
    "Consejos para no perder la calma ante la llegada de la serie 30 de Nvidia.mp4*25713843*9/11/2020 1:03:34 PM",
    "Xbox Series S por 300 dólares... ¿Que hará Sony-.mp4*38116853*9/11/2020 1:03:32 PM",
    150827175,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Unboxme*0*9/13/2020 9:24:38 PM",
    "Lo que no te han contado de la RTX 3070, RTX 3080 y RTX 3090.mp4*20895589*9/11/2020 12:57:42 PM",
    20895589,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Xtian C*0*9/13/2020 11:24:11 PM",
    "($1000) Php 55K AORUS Z490 Gaming PC Time Lapse Build Core i5 10400 + Gigabyte OC 6G GTX 1660 Super.mp4*23537875*9/11/2020 1:00:08 PM",
    "VLOG- 35K PC Build Challenge Part 1- TIPS kung PAANO mamili sa Gilmore #QuarantineEdition.mp4*194498216*9/11/2020 1:00:04 PM",
    218036091,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] YANPOL*0*9/13/2020 9:24:40 PM",
    "Cosas que NO DEBES OLVIDAR al COMPRAR CASE GAMER.mp4*36605908*9/11/2020 12:58:08 PM",
    36605908,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Interesantes\\[PC HARDWARE]\\[PC Hardware] Zona Hadware*0*9/13/2020 9:24:41 PM",
    "DEMASIADO BUENO POR SOLO $45...BLITZWOLF BW-KB1 TECLADO GAMER MECÁNICO BARATO!!!.mp4*59380521*9/11/2020 1:06:34 PM",
    59380521,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Miniseries*0*9/13/2020 9:25:12 PM",
    0,
    "589*590*591*592"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Miniseries\\Cilla (Miniserie) [3 Cap.] FDT*0*9/13/2020 11:21:36 PM",
    "Cilla 1x01.mp4*346815019*8/29/2020 9:01:16 AM",
    "Cilla 1x02.mp4*357841382*8/29/2020 9:15:00 AM",
    "Cilla 1x03.mp4*359616565*8/29/2020 9:18:09 AM",
    "Cilla S01.jpg*51212*8/29/2020 8:49:40 AM",
    1064324178,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Miniseries\\David Coperfield (Miniserie) [2 Cap.] FDT*0*9/13/2020 11:24:16 PM",
    "DAVID COPERFIELD CD 1.mpg*830432696*6/21/2020 9:03:56 AM",
    "DAVID COPERFIELD CD 2.mpg*830572136*6/21/2020 9:03:56 AM",
    "David Coperfield.jpg*8426*6/21/2020 9:03:56 AM",
    1661013258,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Miniseries\\David Nolande (Miniserie) [3 Cap.] FDT*0*9/13/2020 9:25:12 PM",
    "David Nolande - 1x01.AVI*501657036*6/21/2020 9:03:56 AM",
    "David Nolande - 1x02.AVI*566867576*6/21/2020 9:03:56 AM",
    "David Nolande - 1x03.AVI*539056460*6/21/2020 9:03:56 AM",
    "DAVID NOLANDE.jpg*35375*6/21/2020 9:03:56 AM",
    1607616447,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Miniseries\\No Easy Days (Miniserie) [8 Cap.] FDT*0*9/13/2020 9:39:40 PM",
    "No Easy Days - 1x01.mp4*247209417*12/7/2018 10:44:24 PM",
    "No Easy Days - 1x02.mp4*225804753*12/7/2018 10:42:00 PM",
    "No Easy Days - 1x03.mp4*240204999*12/7/2018 10:43:45 PM",
    "No Easy Days - 1x04.mp4*234566622*12/7/2018 10:44:40 PM",
    "No Easy Days - 1x05.mp4*202925197*12/7/2018 10:53:49 PM",
    "No Easy Days - 1x06.mp4*187228394*12/7/2018 10:40:44 PM",
    "No Easy Days - 1x07.mp4*191288986*12/7/2018 10:40:13 PM",
    "No Easy Days - 1x08.mp4*185767878*12/7/2018 10:41:15 PM",
    "no_easy_days.jpg*21727*8/4/2019 12:28:23 PM",
    1715017973,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]*0*9/13/2020 8:43:46 PM",
    0,
    "594*741"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]*0*9/13/2020 10:18:06 PM",
    0,
    "595*596*597*598*599*600*601*602*603*604*605*606*607*608*609*610*611*612*613*614*615*616*617*618*619*620*621*622*623*624*625*626*627*628*629*630*631*632*633*634*635*636*637*638*639*640*641*642*643*644*645*646*647*648*649*650*651*652*653*654*655*656*657*658*659*660*661*662*663*664*665*666*667*668*669*670*671*672*673*674*675*676*677*678*679*680*681*682*683*684*685*686*687*688*689*690*691*692*693*694*695*696*697*698*699*700*701*702*703*704*705*706*707*708*709*710*711*712*713*714*715*716*717*718*719*720*721*722*723*724*725*726*727*728*729*730*731*732*733*734*735*736*737*738*739*740"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\- Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 9:39:47 PM",
    "01 - A mi manera no(Prod By Dj Rodo)Blessed Music.mp3*11201854*9/9/2020 10:21:27 PM",
    "02 - Conectate(Prod By Dj Rodo)Blessed Music.mp3*9189396*9/9/2020 10:22:20 PM",
    "03 - Ven Pá-K(Prod By Dj Rodo)Blessed Music.mp3*9974910*9/9/2020 10:23:24 PM",
    "04 - No Puedo Negarte (Prod byRey Diaz).mp3*10966710*9/9/2020 10:24:05 PM",
    "05 - Mas que navidad(Prod By Dj Rodo)Blessed Music.mp3*12430668*9/9/2020 10:24:48 PM",
    "06 -Tatuaje (Prod byRey Diaz).mp3*11756466*9/9/2020 10:25:35 PM",
    "07 - Padre(Prod By Dj Rodo)Blessed Music.mp3*10048300*9/9/2020 10:25:55 PM",
    "08- Gozate(Prod By Dj Rodo)Blessed Music.mp3*8598918*9/9/2020 10:26:31 PM",
    "09 - Mi tiempo (Prod byRey Diaz).mp3*12983718*9/9/2020 10:26:46 PM",
    "10 - Vida  (Prod byRey Diaz).mp3*9839191*9/9/2020 10:27:14 PM",
    "11 - 2ble Uncion (Prod byRey Diaz).mp3*11000947*9/9/2020 10:27:31 PM",
    "12- No moriré(Prod By Dj Rodo)Blessed Music.mp3*13097313*9/9/2020 10:27:52 PM",
    "13 - 2N1 Intro(Prod By Dj Rodo)Blessed Music.mp3*6048418*9/9/2020 10:27:58 PM",
    137136809,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\a Eh - Wampi(2020)()*0*9/13/2020 9:39:45 PM",
    "4-Wampi X Manu Manu - La Cosita.mp3*8654727*9/9/2020 10:24:36 PM",
    8654727,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\al &amp; Armin - Demo(2020)()*0*9/13/2020 11:26:41 PM",
    "Vidal &amp; Armin --Diganle(Big Noise Studio).mp3*9260670*9/9/2020 10:31:17 PM",
    "Vidal &amp; Armin Ft Masorra--Ekelecua(Big Noise Studio ).mp3*8567341*9/9/2020 10:31:18 PM",
    "Vidal &amp; Armin-- Se Acaba el Amor (BigNoise).mp3*7727488*9/9/2020 10:31:18 PM",
    "Vidal &amp; Armin-- Vacilòn(Big Noise Studio).mp3*7954743*9/9/2020 10:31:19 PM",
    "Vidal &amp; Armin- Yo te Amo(Big Noise).mp3*7829888*9/9/2020 10:31:19 PM",
    "Vidal &amp; Armin-Dando Like(Corasound Music).mp3*3070662*9/9/2020 10:31:19 PM",
    "Vidal &amp; Armin--Díganle(Big Noise Studio).mp3*7657203*9/9/2020 10:31:19 PM",
    "Vidal &amp; Armin-La Mulata(Big Noise Studio).mp3*8014835*9/9/2020 10:31:20 PM",
    "Vidal &amp; Armin--Motivos(Recvolution studio).mp3*8083946*9/9/2020 10:31:20 PM",
    "Vidal &amp; Armin-Moviendo el cuerpo(BIG Noise Studio).mp3*6708713*9/9/2020 10:31:20 PM",
    74875489,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\an(2020)()*0*9/13/2020 11:20:41 PM",
    "1585627200TLC DAYAN(YA YO)LION MUSIC .mp3*2915415*9/9/2020 10:29:04 PM",
    "1585627200TLCDAYANLACARADESCECHABLEPRODBYYAMILKOM.mp3*2651991*9/9/2020 10:29:04 PM",
    "1585627200TLCDAYANLADRONAPRODBYYAMILKOMLIONMUSIC.mp3*3070935*9/9/2020 10:29:05 PM",
    "1585627200TLCDAYANXYAMILKOMKUCHIMBANLIONMUSIC.mp3*3026775*9/9/2020 10:29:05 PM",
    "1585886400HARRYSONXTLCDAYANXRIGELPROFESECARGARON2020MASTER.mp3*2891282*9/9/2020 10:29:05 PM",
    "1586577600TLCDayanxTikkoxRigelElProfeMalaCostumbre.mp3*2848650*9/9/2020 10:29:06 PM",
    "1586836800TLCDayanxTikkoxRigelElProfeMalaCostumbrewwwseponeweno.mp3*2848650*9/9/2020 10:29:07 PM",
    "1590984000TLCDAYANELBUENOYELMALOwwwseponewenonatcu.mp3*1923159*9/9/2020 10:29:08 PM",
    "1590984000TLCDAYANXLADIOSAXJOAKO369MeVoyPaLaCallewww.mp3*2038750*9/9/2020 10:29:08 PM",
    "1591156800RIGELXTLCDAYANOJOCONESOwwwseponewenonatcu.mp3*2500491*9/9/2020 10:29:09 PM",
    "TLC DAYAN X LA DIOSA X JOAKO369 - Me Voy Pa La Calle .mp3*2703200*9/9/2020 10:31:13 PM",
    29419298,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\anluis(2020)()*0*9/13/2020 11:32:15 PM",
    "+LOBO MALO &amp; YOHANLUIS - EL CHULO (PROD. BY MAGIC MUSIC).mp3*7088126*9/9/2020 10:21:23 PM",
    "A VER COMO TE EXPLICO - POPY (Feat. CRAWEL  YOHANLUIS(720PHD).mp3*3744813*9/9/2020 10:29:16 PM",
    "Dime YohanLuis  YeiYei Prod by MrRey La LLaveRecords.mp3*8267871*9/9/2020 10:29:39 PM",
    "LA CUERDA - POPY la moda(Feat. YOHANLUI) (Audio(720PHD).mp3*7142679*9/9/2020 10:30:31 PM",
    "La distancia.Mauro Misterio X Yohanluis(720PHD).mp3*8941323*9/9/2020 10:30:31 PM",
    "mejor solo.mp3*7390445*9/9/2020 10:30:43 PM",
    "YOHANLUIS - AKELLA VEZ (PROD. BY DJ GOMEZ GOMEKO).mp3*6597543*9/9/2020 10:31:26 PM",
    "YOHANLUIS POPY X MODA  la patipolvo.mp3*6833559*9/9/2020 10:31:27 PM",
    "Yohanluis Readysalvi. Se mira y no se toca.mp3*8466605*9/9/2020 10:31:27 PM",
    "YohanLuis. Voy a Seguir (audioficial).mp3*6849965*9/9/2020 10:31:28 PM",
    71322929,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ano Vol 1 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 10:12:30 PM",
    "001 - STH - Culpable(Prod By Dj Rodo)Blessed Music.mp3*8593747*9/9/2020 10:21:24 PM",
    "002 - John Ramy - Explicame(Prod By Dj Rodo)Blessed Music).mp3*8438057*9/9/2020 10:22:17 PM",
    "003 - Los Likes - Tu Fantasía (Daniel)(Prod By Dj Rodo)Blessed Music.mp3*9168441*9/9/2020 10:23:08 PM",
    "004 - Los Likes - Yo Me Enamoro (Daniel)(Prod By Dj Rodo)Blessed Music.mp3*7928147*9/9/2020 10:23:53 PM",
    "005 - Mr Leo - Como Olvidarte(Prod By Dj Rodo)Blessed Music.mp3*7843510*9/9/2020 10:24:38 PM",
    "006 - John Ramy - Quedate Conmigo(Prod By Dj Rodo)Blessed Music.mp3*7184180*9/9/2020 10:25:18 PM",
    "007 - Los Likes - Tu y Yo(Daniel)(Prod By Dj Rodo)Blessed Music.mp3*8865420*9/9/2020 10:25:50 PM",
    "008 - Otto &amp; Yari - Olvidala(Prod By Dj rodo)Blessed Music.mp3*7847690*9/9/2020 10:26:19 PM",
    "009 - Mr Leo - Loco  Tu Amor(Prod By Dj Rodo)Blessed Music.mp3*8460000*9/9/2020 10:26:43 PM",
    "010 - Leskay &amp; El Auténtico - Saldré A Buscarte(Prod By Dj Rodo)Blessed Music.mp3*7537355*9/9/2020 10:27:06 PM",
    "Eduar El Real - Me llamó(Prod By Dj Rodo)Blessed Music.mp3*10500648*9/9/2020 10:29:41 PM",
    "Eduar El Real - Mueres  Mi(Prod By Dj Rodo)Blessed Music.mp3*7487162*9/9/2020 10:29:41 PM",
    99854357,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ano Vol 2 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 10:12:49 PM",
    "011 - Mr Leo ft Yamoe - Sola(Prod By Dj Rodo)Blessed Music.mp3*7734841*9/9/2020 10:27:30 PM",
    "012 - Tati &amp; El Elvis - Tu Y Yo(Prod By Dj Rodo &amp; Chanel Pro)Blessed Music.mp3*8348196*9/9/2020 10:27:46 PM",
    "013 - Eduar El Real - Mueres  Mi(Prod By Dj Rodo)Blessed Music.mp3*7488245*9/9/2020 10:27:58 PM",
    "014 - Arold Aranda - Alocate(Prod By Dj Rodo)Blessed Music.mp3*7516457*9/9/2020 10:28:09 PM",
    "015- Leskay &amp; El Autentico - Un Cuento De Hadas(Prod By Dj Rodo)Blessed Music.mp3*8583298*9/9/2020 10:28:16 PM",
    "016 - Mr Leo - No Me Llores(Prod By Dj Rodo)Blessed Music.mp3*7742155*9/9/2020 10:28:21 PM",
    "017 - Eduar El Real - Es Mentira(Prod By Dj Rodo)Blessed Music.mp3*8138171*9/9/2020 10:28:27 PM",
    "018- Cairon - Volver(Prod By Dj Rodo)Blessed Music.mp3*8291771*9/9/2020 10:28:30 PM",
    "019- Leskay &amp; El Autentico - Vamos A Celebrar(Prod By Dj Rodo)Blessed Music.mp3*7675282*9/9/2020 10:28:35 PM",
    "Leny - Envidia(Prod By Dj Rodo)Blessed Music.mp3*8359652*9/9/2020 10:30:36 PM",
    79878068,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ano Vol 3 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 11:23:20 PM",
    "020- Los Likes  - Fan De Tus Besos(Prod By Dj Rodo)Blessed Music.mp3*9454743*9/9/2020 10:28:38 PM",
    "021 - Arold Aranda - Me Enreda(Prod By Dj Rodo)Blessed Music.mp3*8325208*9/9/2020 10:28:41 PM",
    "022 - Vivita - El Culpable(Prod By Dj Rodo)Blessed Music.mp3*8082792*9/9/2020 10:28:42 PM",
    "023- Mr Leo - El Hechizo(Prod By Dj Rodo)Blessed Music.mp3*7310612*9/9/2020 10:28:43 PM",
    "024- Tati &amp; El Elvis - No Mas(Prod By Dj Rodo)Blessed Music.mp3*8970955*9/9/2020 10:28:43 PM",
    "025- Eduar El Real - Me Enamore(Prod By Dj Rodo)Blessed Music.mp3*8288637*9/9/2020 10:28:45 PM",
    "026- Los Likes  - Pensando En Ti(Prod By Dj Rodo)Blessed Music.mp3*8713910*9/9/2020 10:28:46 PM",
    "027- Danny - Novios o Amigos(Prod By Dj Rodo)Blessed Music.mp3*7238514*9/9/2020 10:28:46 PM",
    "028- Eduar El Real - Me llamó(Prod By Dj Rodo)Blessed Music.mp3*10542482*9/9/2020 10:28:47 PM",
    "029- Avik &amp; AG &amp; RanC - Ella Me Enfermo(Prod By dj Rodo)Blessed Music.mp3*8853926*9/9/2020 10:28:48 PM",
    "2N1 - Una Vida Linda(Prod By Dj Rodo)Blessed Music).mp3*7472571*9/9/2020 10:23:05 PM",
    "Leskay &amp; El Autentico -  Tu Amor(Prod By Dj Rodo)Blessed Music.mp3*8086971*9/9/2020 10:30:37 PM",
    "Rian &amp; Yail - La Traición(Prod By Dj Rodo)Blessed Music.mp3*8396261*9/9/2020 10:31:02 PM",
    "The Sami - Como Tu Ninguna(Prod By Dj Rodo)Blessed Music.mp3*8387838*9/9/2020 10:31:13 PM",
    118125420,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ant Garcia - Mi Estrella(2020)()*0*9/13/2020 9:25:15 PM",
    "01.Bryant Garcia - Mi Estrella.mp3*6248149*9/9/2020 10:21:52 PM",
    "02.Bryant Garcia - We Not Nice.mp3*8351509*9/9/2020 10:22:40 PM",
    14599658,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ar Y El Expresso - El Expresso(2020)*0*9/13/2020 9:25:12 PM",
    "01.Aisar Y El Expresso De Cuba - Un Pastel.mp3*4408162*9/9/2020 10:21:51 PM",
    "02.Aisar Y El Expresso De Cuba - El Camaron.mp3*4603618*9/9/2020 10:22:38 PM",
    "03.Aisar Y El Expresso De Cuba - A Mi Madre.mp3*3591010*9/9/2020 10:23:29 PM",
    "04.Aisar Y El Expresso De Cuba - 60 Años.mp3*3653986*9/9/2020 10:24:16 PM",
    16256776,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\athan Wilson - 69 Corvette(2020)(POP)*0*9/13/2020 11:24:16 PM",
    "01 - 69 Corvette (Acoustic).mp3*9307537*9/9/2020 10:21:26 PM",
    "02 - Enemies (Acoustic).mp3*7367857*9/9/2020 10:22:21 PM",
    "03 - Fun For The Masses (Acoustic).mp3*6564337*9/9/2020 10:23:12 PM",
    "04 - Golden Apples (Acoustic).mp3*8571697*9/9/2020 10:24:01 PM",
    "05 - 69 Corvette (feat. Mark O Connor).mp3*10517137*9/9/2020 10:24:39 PM",
    42328565,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Blues Doctors - Come Together(2020)(POP)*0*9/13/2020 9:24:45 PM",
    "01 - Come Together.mp3*11032500*9/9/2020 10:21:28 PM",
    "02 - Sanctified Blues.mp3*9454704*9/9/2020 10:22:29 PM",
    "03 - Seventh Avenue.mp3*11743030*9/9/2020 10:23:22 PM",
    "04 - I Want You.mp3*12838083*9/9/2020 10:24:03 PM",
    "05 - I Got You (I Feel Good).mp3*6524810*9/9/2020 10:24:44 PM",
    "06 - Little Red Rooster.mp3*15356287*9/9/2020 10:25:25 PM",
    "07 - Heartbreak (Its Hurtin Me).mp3*8410851*9/9/2020 10:25:52 PM",
    "08 - So Mean.mp3*11815128*9/9/2020 10:26:28 PM",
    "09 - No More Doggin.mp3*8283373*9/9/2020 10:26:46 PM",
    "10 - Freedom for My People.mp3*10699177*9/9/2020 10:27:10 PM",
    "11 - Whatd I Say.mp3*9626067*9/9/2020 10:27:36 PM",
    115784010,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\bo - CD Renacer (2020)()*0*9/13/2020 11:21:36 PM",
    "00-EL YAMBO-RENACER(Prød By Dj Riky)™.mp3*5348478*9/9/2020 10:21:24 PM",
    "02-EL YAMBO-NADA SIN TI(Prød By Chico Frank &amp; Dj Riky &amp; Luisito El Insuperable)™.mp3*9599984*9/9/2020 10:22:58 PM",
    "03-EL YAMBO-LA FINA(Prød By Chico Frank &amp; Dj Riky &amp; Luisito El Insuperable)™.mp3*9048278*9/9/2020 10:23:47 PM",
    "04-EL YAMBO-NO SOY DELINCUENTE(Prød By Chico Frank &amp; Dj Riky)™.mp3*8443282*9/9/2020 10:24:31 PM",
    "05-EL YAMBO-BRUJERIA(Prød By Chico Frank &amp; Dj Riky)™.mp3*9488180*9/9/2020 10:25:09 PM",
    "06-EL YAMBO FEAT ANTHONY-RICA(Prød By Dj Riky)™.mp3*7418238*9/9/2020 10:25:44 PM",
    "07-EL YAMBO FEAT ALEXITO-QUERER Y AMAR(Prød By Dj Riky)™.mp3*7919358*9/9/2020 10:26:13 PM",
    "1-Intro Champoleate-(Prød By DJ-RIKY)™.mp3*5202558*9/9/2020 10:22:14 PM",
    "2-Yambo-Te toca llorar-(Prod By Chico Frank).mp3*9589535*9/9/2020 10:23:07 PM",
    "3-Yambo-Tiza-(Prød By DJ-RIKY)™.mp3*7729278*9/9/2020 10:23:52 PM",
    "4-Yambo-Que Rico-(Prød By DJ-RIKY)™.mp3*8147838*9/9/2020 10:24:36 PM",
    "5-Yambo feat Alexito-Te vas Bloqueá-(Prød By DJ-RIKY)™.mp3*8183358*9/9/2020 10:25:17 PM",
    "6-Yambo-Tornillo-(Prød By DJ-RIKY)™.mp3*7566078*9/9/2020 10:25:50 PM",
    "7-Yambo-Felicidad-(Prød By DJ-RIKY)™.mp3*8354238*9/9/2020 10:26:19 PM",
    "8-Yambo-Chica prepago-(Prod By Chico Frank &amp; Dj Riky)-1.mp3*8962596*9/9/2020 10:26:43 PM",
    121001277,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Bory En La Ksa(2020)()*0*9/13/2020 11:17:04 PM",
    "El Bory EnLa KSA- Como se Puede.mp3*9510084*9/9/2020 10:29:43 PM",
    "El Bory EnLa KSA- Donde estaba yo.mp3*3441316*9/9/2020 10:29:43 PM",
    "El Bory EnLa KSA- Ella no Quiere Na.mp3*3379458*9/9/2020 10:29:43 PM",
    "El Bory EnLa KSA- Mami.mp3*8874519*9/9/2020 10:29:44 PM",
    "El Bory EnLa KSA- No me Trates así.mp3*3328049*9/9/2020 10:29:44 PM",
    "El Bory EnLa KSA- Que puedo Hacer Yo.mp3*3369845*9/9/2020 10:29:44 PM",
    "El Bory EnLa KSA- Ya tu no me Quiere.mp3*8603799*9/9/2020 10:29:45 PM",
    "El Bory EnLa KSA- Yo no te Compro Cerveza.mp3*3053868*9/9/2020 10:29:45 PM",
    43560938,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\bs In Aspic - Madness And Magic(2020)(POP)*0*9/13/2020 9:24:27 PM",
    "01 - I Vow to Thee, My Screen.mp3*20142965*9/9/2020 10:21:32 PM",
    "02 - Lullaby for Modern Kids, Part 1.mp3*20008173*9/9/2020 10:22:23 PM",
    "03 - Lullaby for Modern Kids, Part 2.mp3*5099569*9/9/2020 10:23:19 PM",
    "04 - High-Tech Parent.mp3*11029365*9/9/2020 10:24:01 PM",
    "05 - Madness and Magic.mp3*16351030*9/9/2020 10:24:45 PM",
    "06 - Heaven in Your Eye.mp3*40278149*9/9/2020 10:25:22 PM",
    112909251,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\By - WUHAN(2020)()*0*9/13/2020 9:24:22 PM",
    "01.JayBy - Tokyo.mp3*3307605*9/9/2020 10:21:58 PM",
    "02.Jayby x Dj Unic - Rio.mp3*5839330*9/9/2020 10:22:44 PM",
    "03.Jayby - Wuhan.mp3*5282552*9/9/2020 10:23:35 PM",
    "04.Jayby X DNIX-E - Tokyo Remix.mp3*9721321*9/9/2020 10:24:25 PM",
    "05.Jayby - WUHAN.mp3*5282507*9/9/2020 10:25:04 PM",
    29433315,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Calle(2020)()*0*9/13/2020 11:34:12 PM",
    "AMOR PROHIBIDO-SON CALLE FEAT ESCUADRON SUICIDA-1600 MUSIC.mp3*5649604*9/9/2020 10:29:17 PM",
    "Perdoname - Son Calle ( 96kbps ).mp3*3388235*9/9/2020 10:30:59 PM",
    "SON CALLE - SONRISA DE PAPEL (MASTER MUSIC).mp3*9642786*9/9/2020 10:31:10 PM",
    "SON CALLE - UN AÑO cover (MASTER MUSIC).mp3*9985239*9/9/2020 10:31:11 PM",
    "Son Calle- Si No Me llamas.mp3*4288519*9/9/2020 10:31:11 PM",
    "SonCalleVamossoldadoftWhitneyKelly,VisaAbierta96kbps.mp3*4721943*9/9/2020 10:31:11 PM",
    37676326,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Carli - Hasta Afuera(2020)()*0*9/13/2020 11:27:58 PM",
    "01.El Uniko Ft El Yowa &amp; El Carli - No Hay Amor.mp3*5684303*9/9/2020 10:21:57 PM",
    "02.El Taiger Ft El Carli X El Yowa - Te Nesecito.mp3*5084949*9/9/2020 10:22:44 PM",
    "03.El Kamel Ft El Carli,Yowa &amp; Kcho - Con Otra.mp3*4409313*9/9/2020 10:23:32 PM",
    "04.Anubix Ft El Carli - Hasta Afuera.mp3*4963950*9/9/2020 10:24:17 PM",
    "05.El Enviado Ft El Carli x El Yowa - Pa Mi Cama.mp3*4431679*9/9/2020 10:25:01 PM",
    "06.El Negrito &amp; Kokito Ft El Carli - De 7 A 12.mp3*5323813*9/9/2020 10:25:38 PM",
    "07.Carluchi &amp; TLC Dayan - Puro Veneno.mp3*2884374*9/9/2020 10:26:05 PM",
    "08.El Carli - El Que La Hace La Paga.mp3*5151405*9/9/2020 10:26:35 PM",
    "09.El Carli - Taste(Spanish Versión).mp3*2140427*9/9/2020 10:26:54 PM",
    "10.El Carli X El Yowa - La Flor.mp3*4589668*9/9/2020 10:27:21 PM",
    "11.El Carli X El Yowa - Riega La Bola.mp3*4269909*9/9/2020 10:27:40 PM",
    "12.El Carli X El Yowa Ft Lei X Dandy - Loma Abajo.mp3*5316290*9/9/2020 10:27:54 PM",
    "13.Yakarta Ft El Carli &amp; El Yowa &amp; El Los De Los 90 - Pa Que Decirte.mp3*4769599*9/9/2020 10:28:06 PM",
    "14.El Carli Ft Eddy K - Eco.mp3*2962762*9/9/2020 10:28:12 PM",
    "15.El Carli - Háblame De Tengo Tengo.mp3*2741452*9/9/2020 10:28:19 PM",
    64723893,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ch Mob - Wicked Sensation Reimagined(2020)(POP)*0*9/13/2020 10:29:18 PM",
    "01 - Wicked Sensation (Reimagined).mp3*12241685*9/9/2020 10:21:48 PM",
    "02 - River of Love (Reimagined).mp3*12983562*9/9/2020 10:22:28 PM",
    "03 - Sweet Sister Mercy (Reimagined).mp3*9951268*9/9/2020 10:23:22 PM",
    "04 - All I Want (Reimagined).mp3*13174779*9/9/2020 10:23:58 PM",
    "05 - Hellchild (Reimagined).mp3*10910485*9/9/2020 10:24:43 PM",
    "06 - Shes So Evil (Reimagined).mp3*12289750*9/9/2020 10:25:31 PM",
    "07 - Dance of the Dogs (Reimagined).mp3*8976379*9/9/2020 10:25:51 PM",
    80527908,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\chi Diamond - Quitarnos Las Ganas(2020)()*0*9/13/2020 9:20:38 PM",
    "Chuchi Diamond - Quitarnos Las Ganas.mp3*2143969*9/9/2020 10:29:32 PM",
    2143969,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Chiko De La Calle - De La Que Te Salvaste(2020)()*0*9/13/2020 11:14:57 PM",
    "01.El Chiko De La Calle - De Prende En Prende.mp3*7958007*9/9/2020 10:21:56 PM",
    "02.El Chiko De La Calle - Es Verdad.mp3*8047671*9/9/2020 10:22:43 PM",
    "03.El Chiko De La Calle - Lo Repa Se Te Salio.mp3*9098238*9/9/2020 10:23:31 PM",
    "04.El Chiko De La Calle - De La Que Te Salvaste.mp3*4730530*9/9/2020 10:24:20 PM",
    "05.El Chiko De La Calle - Jicamo No Timbales.mp3*8919639*9/9/2020 10:25:00 PM",
    38754085,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Cooder - Just A Workin Man(2020)(POP)*0*9/13/2020 10:20:53 PM",
    "01 - Police Dog Blues.mp3*9378459*9/9/2020 10:21:39 PM",
    "02 - F.D.R In Trinidad.mp3*8218623*9/9/2020 10:22:21 PM",
    "03 - If Walls Could Talk.mp3*9765072*9/9/2020 10:23:16 PM",
    "04 - Tamp em Up Solid.mp3*9192467*9/9/2020 10:24:09 PM",
    "05 - Ax Sweet Mama.mp3*10098394*9/9/2020 10:24:40 PM",
    "06 - Billy The Kid.mp3*15030312*9/9/2020 10:25:20 PM",
    "07 - Vigilante Man.mp3*12311488*9/9/2020 10:25:58 PM",
    "08 - How Can A Poor Man Stand Such Times And Live.mp3*15925790*9/9/2020 10:26:23 PM",
    "09 - Tattler.mp3*9610427*9/9/2020 10:26:50 PM",
    "10 - Comin In On A Wing And A Prayer.mp3*12766019*9/9/2020 10:27:08 PM",
    "11 - Alimony.mp3*9992860*9/9/2020 10:27:32 PM",
    "12 - Teardrops Will Fall.mp3*8608370*9/9/2020 10:27:48 PM",
    "13 - Im A Pilgrim.mp3*7078640*9/9/2020 10:27:59 PM",
    137976921,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\da Ezhel - Made In Turkey(2020)(POP)*0*9/13/2020 11:32:34 PM",
    "01 - Cümle Alem (feat. Kalibwoy).mp3*6311764*9/9/2020 10:21:29 PM",
    "02 - Anadolu Flex.mp3*8488268*9/9/2020 10:22:17 PM",
    "03 - Duman.mp3*7451729*9/9/2020 10:23:11 PM",
    "04 - Söz.mp3*8916676*9/9/2020 10:24:09 PM",
    "05 - Ter.mp3*6382799*9/9/2020 10:24:55 PM",
    "06 - Made In Turkey.mp3*6684774*9/9/2020 10:25:25 PM",
    "07 - Boynumdaki Chain.mp3*5588676*9/9/2020 10:25:51 PM",
    "08 - Bi Sonraki Hayatımda Gel.mp3*6742244*9/9/2020 10:26:20 PM",
    56566930,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Dinastia - Nota(2020)()*0*9/13/2020 9:21:08 PM",
    "La Dinastia - Nota.mp3*4881442*9/9/2020 10:30:31 PM",
    4881442,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Division - Atmosphere Remastered(2020)(POP)*0*9/13/2020 9:21:11 PM",
    "01 - Atmosphere (2020 Digital Remaster).mp3*10131888*9/9/2020 10:21:28 PM",
    "02 - Shes Lost Control (2020 Digital Remaster).mp3*11500704*9/9/2020 10:22:31 PM",
    21632592,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\e Yamil(El Chavo) - Contigo Quiero Todo(2020)()*0*9/13/2020 11:24:05 PM",
    "01.Jose Yamil(El Chavo) - Contigo Quiero Todo.mp3*2343022*9/9/2020 10:21:59 PM",
    "02.Baby Lores Ft Jose Yamil(El Chavo) - Se Fue.mp3*3216687*9/9/2020 10:22:39 PM",
    "03.Jose Yamil(El Chavo) - El Negocio.mp3*3691569*9/9/2020 10:23:38 PM",
    "04.Jose Yamil(El Chavo) - Muñequita.mp3*3399771*9/9/2020 10:24:25 PM",
    "05.Jose Yamil(El Chavo) -  Que Sera.mp3*3639647*9/9/2020 10:25:04 PM",
    "06.Jose Yamil(El Chavo) - Soy Figura.mp3*3591163*9/9/2020 10:25:39 PM",
    "07.Jose Yamil(El Chavo) - Suave Suavecito.mp3*3856521*9/9/2020 10:26:08 PM",
    "08.El Kamel Ft Jose Yamil(El Chavo) - Con La Misma Moneda.mp3*2848032*9/9/2020 10:26:35 PM",
    "09.Baby Lores Ft Jose Yamil(El Chavo) - Te Solte.mp3*2568417*9/9/2020 10:26:53 PM",
    29154829,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ecadas &amp; Varony - LA NUEVA ERA(2020)()*0*9/13/2020 10:20:47 PM",
    "01.Bavecadas &amp; Varony - Adios.mp3*7681242*9/9/2020 10:21:52 PM",
    "02.Bavecadas &amp; Varony - Mama Ya Bana.mp3*8543586*9/9/2020 10:22:39 PM",
    "03.Bavecadas &amp; Varony - Acercate Ft Danny.mp3*4362366*9/9/2020 10:23:30 PM",
    "04.Bavecadas &amp; Varony Ft King Viezzo - Un Angelito.mp3*8140146*9/9/2020 10:24:17 PM",
    "05.Bavecadas &amp; Varony Ft King Viezzo - Tu Te Vienes Y Te Vas.mp3*7342986*9/9/2020 10:24:59 PM",
    36070326,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ee Show - El Arrebato(2020)()*0*9/13/2020 10:20:48 PM",
    "Three Show - El Arrebato by Meko.mp3*7974319*9/9/2020 10:31:13 PM",
    7974319,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\el El Profe(2020)()*0*9/13/2020 10:20:48 PM",
    "05.PARQUIA YULINDO ft- RIGEL EL PROFEBy EL KRTEL.mp3*6823690*9/9/2020 10:25:08 PM",
    "06ENUNSEGUNDORIGELELPROFE&amp;YULINDOftLAREYNADEFLOW.mp3*7697224*9/9/2020 10:25:44 PM",
    "08.TLC DAYAN X RIGEL FT JEREMI--METAFORA.mp3*5293911*9/9/2020 10:26:40 PM",
    "09.TLC DAYAN X RIGEL--100 PA  ABAJOTORRE MUSIC.mp3*7797414*9/9/2020 10:26:59 PM",
    "10TLCDAYANXRIGELVELOCIPEDO2CYBORGRECORD´S54027605.mp3*7884795*9/9/2020 10:27:30 PM",
    "12.TLC DAYAN X RIGEL FT LA MEKANIK--PIENSA EN MI.mp3*5045271*9/9/2020 10:27:57 PM",
    "13.TLC DAYAN X RIGEL--MI LUGAR 2020TORRE MUSIC.mp3*7293894*9/9/2020 10:28:06 PM",
    "14.TLC DAYAN X RIGEL--OJO CON ESO--CYBORG RECORD´S 54027605.mp3*8200083*9/9/2020 10:28:15 PM",
    56036282,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\elkovski - Live in Seynod(2020)(POP)*0*9/13/2020 9:20:01 PM",
    "01 - Oriental Toridanzon.mp3*9509079*9/9/2020 10:21:38 PM",
    "02 - Meditterra.mp3*14013399*9/9/2020 10:22:24 PM",
    "03 - Makedonija.mp3*9256599*9/9/2020 10:23:20 PM",
    "04 - Angustia.mp3*17531799*9/9/2020 10:24:00 PM",
    "05 - Afro Macé.mp3*17969559*9/9/2020 10:24:40 PM",
    68280435,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\en Ramos - Pensando En Ti(2020)()*0*9/13/2020 9:20:02 PM",
    "01.Allen Ramos - Pensando En Ti.mp3*8924942*9/9/2020 10:21:52 PM",
    "02.Allen Ramos - No Funciona.mp3*7107864*9/9/2020 10:22:38 PM",
    "03.Allen Ramos Ft El Ankla - Mala Tuya.mp3*8947930*9/9/2020 10:23:29 PM",
    "04.Allen Ramos Ft Kimiko - Hagamos El Sexo.mp3*8059494*9/9/2020 10:24:17 PM",
    33040230,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\enia Diaz - Tell Me(2020)()*0*9/13/2020 9:20:02 PM",
    "01.Karenia Diaz - Tell Me.mp3*9084938*9/9/2020 10:22:00 PM",
    "02.Karenia Diaz - Tranquilita Y Relaja.mp3*4398846*9/9/2020 10:22:48 PM",
    "03.Karenia Diaz - Y Yo Que Decia.mp3*4107373*9/9/2020 10:23:38 PM",
    17591157,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\enis 22K(2020)()*0*9/13/2020 9:20:03 PM",
    "Orlenis 22K - Olvidalo ft BLACK BOYS - DJ ROWA (Atocha).mp3*8884119*9/9/2020 10:30:52 PM",
    "Orlenis 22K - PARTY PLUS - DJ ROWA (Atocha).mp3*6661719*9/9/2020 10:30:53 PM",
    "Orlenis 22K - Se Demora ft KOKI ELKANO - DJ ROWA (Atocha).mp3*7522839*9/9/2020 10:30:53 PM",
    "Orlenis 22K - UN BESO ft DUKESITO - DJ ROWA (Atocha).mp3*7290519*9/9/2020 10:30:54 PM",
    "Orlenis 22K - UN CANAL - DJ ROWA (Atocha).mp3*5819763*9/9/2020 10:30:54 PM",
    "Orlenis 22K - Una Cara  Un Cuerpo (ft KIng Boys) - DJ ROWA (Atocha).mp3*7487319*9/9/2020 10:30:55 PM",
    43666278,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Estreno Especial - GNIO &amp; DAYRONI(2020)()*0*9/13/2020 9:20:03 PM",
    "Gnio X Dayroni Chanchullo Larra Dj 58020966.mp3*7769283*9/9/2020 10:30:03 PM",
    "Ni A La Buena Ni Las Malas Master Gnio X Dayroni X Logico Rey.mp3*8294903*9/9/2020 10:30:47 PM",
    "Somos SENSEI- Dj Unic, Gnio &amp; Dayroni(Omar1to Promo).mp3*6191401*9/9/2020 10:31:09 PM",
    22255587,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Estreno Especial - Ozuna - ENOC(2020)*0*9/13/2020 9:20:03 PM",
    "07 Ozuna, Zion &amp; Lennox - Que Tú Esperas.mp3*3593862*9/9/2020 10:26:03 PM",
    "13 Ozuna, Camilo - Despeinada.mp3*3633973*9/9/2020 10:28:01 PM",
    "16 Ozuna, Jhay Cortez - No La Mires.mp3*3428347*9/9/2020 10:28:23 PM",
    "20 Ozuna - Gracias.mp3*2629603*9/9/2020 10:28:39 PM",
    13285785,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Estreno Especial - Un Iroso(2020)()*0*9/13/2020 9:20:04 PM",
    "1-ANOCHE.mp3*3213939*9/9/2020 10:22:11 PM",
    "2-COSITA BONITA.mp3*3503649*9/9/2020 10:23:03 PM",
    "9-IROSO X LUCHEN X PEDRO.mp3*4346157*9/9/2020 10:27:01 PM",
    "Iroso x Daimon x MGY  TARDE O  TENPRANO.mp3*7210371*9/9/2020 10:30:23 PM",
    18274116,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\gal - Gucci &amp; Prada(2020)()*0*9/13/2020 9:20:04 PM",
    "01.Reggal - Gucci &amp; Prada.mp3*7975650*9/9/2020 10:22:05 PM",
    "02.Reggal - MI Nena.mp3*7572666*9/9/2020 10:22:54 PM",
    15548316,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ge Luis Pacheco - Te Vi Reir(2020)(POP)*0*9/13/2020 9:20:04 PM",
    "Jorge Luis Pacheco - Te Vi Reir.mp3*14412982*9/9/2020 10:30:26 PM",
    14412982,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Gobernantes(Los Ojos Sobre Mi)(2020)()*0*9/13/2020 9:20:09 PM",
    "Como Wildey - LosGobernantes .mp3*7867531*9/9/2020 10:29:32 PM",
    "Los Gobernantes - Pegao.mp3*8219946*9/9/2020 10:30:37 PM",
    "Los Gobernantes - TiNGUILY ft Kilo 2 .mp3*10383114*9/9/2020 10:30:38 PM",
    "No Me Interesa - Los Gobernantes .mp3*9627195*9/9/2020 10:30:49 PM",
    "Wildey ft Los Gobernantes - La Cara.mp3*7924146*9/9/2020 10:31:25 PM",
    44021932,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\goFarai(2020)()*0*9/13/2020 9:20:09 PM",
    "KingoFarai - Aparte.mp3*3031263*9/9/2020 10:30:30 PM",
    "KingoFarai - Me va muy bien.mp3*2368853*9/9/2020 10:30:30 PM",
    "KingoFarai - Rica.mp3*1803951*9/9/2020 10:30:30 PM",
    "KingoFarai - Tu Directa.mp3*2214262*9/9/2020 10:30:30 PM",
    "KingoFarai×Harrison×ElGallo247×7MevamuybienRemix.mp3*2146040*9/9/2020 10:30:30 PM",
    11564369,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\hael Landau - Killing Time(2020)(POP)*0*9/13/2020 9:20:10 PM",
    "01 - Killing Time (Live).mp3*17751223*9/9/2020 10:21:34 PM",
    "02 - Cant Walk Away From It Now (Live).mp3*12872594*9/9/2020 10:22:19 PM",
    "03 - Cant Buy My Way Home (Live).mp3*14595631*9/9/2020 10:23:10 PM",
    45219448,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\hel Yero(El Kila) - A La Carandanga(2020)*0*9/13/2020 9:02:37 PM",
    "01. Yero(El Kila) - A La Carandanga.mp3*6791717*9/9/2020 10:21:51 PM",
    "02. Yero(El Kila) - Pa Mi Gente.mp3*8451604*9/9/2020 10:22:37 PM",
    "03. Yero El Kila - Licra  La Rodilla.mp3*7944318*9/9/2020 10:23:28 PM",
    23187639,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ho - El Havano(2020)()*0*9/13/2020 9:02:41 PM",
    "10-Ticho El Havano-No queda nada.AM-PRO..mp3*8482041*9/9/2020 10:27:30 PM",
    "11-Ticho(El Havano)-Vamos a ver si arde.AM-PRO.mp3*9065107*9/9/2020 10:27:45 PM",
    "12TichoElHavanoComoTeExtrañoVersionSalsaGuettoAMPRO.mp3*9201975*9/9/2020 10:27:57 PM",
    "13-Ticho(El Havano)-Amor a 90 millas.AM-PRO..mp3*9200930*9/9/2020 10:28:09 PM",
    "14-Ticho(El Havano)- ti.AM-PRO..mp3*9419314*9/9/2020 10:28:16 PM",
    "15-Ticho(El Havano) &amp; El Pipo-Ananae.AM-PRO..mp3*9183167*9/9/2020 10:28:21 PM",
    "16-Ticho(El Havano)-A que te disparo.AM-PRO..mp3*9742188*9/9/2020 10:28:26 PM",
    "1-Ticho(El Havano)-Intro.Lo Que Suena Soy Yo.AM-PRO.mp3*6376570*9/9/2020 10:22:15 PM",
    "2-Ticho(El Havano)-Ponte pa tu talla.AM-PRO.mp3*7859281*9/9/2020 10:23:06 PM",
    "3-Ticho(El Havano)-A mi aire.AM-pro..mp3*8791329*9/9/2020 10:23:50 PM",
    "4 - Ticho(El Havano) - Maldita eres tu-AM-PRO.mp3*7553125*9/9/2020 10:24:32 PM",
    "5- Ticho(El havano)-Sigo siendo yo.AM-PRO..mp3*8153942*9/9/2020 10:25:13 PM",
    "6-Ticho(El Havano)-Es  tu amor.(DINASTIA RECORDS).1.mp3*8985680*9/9/2020 10:25:49 PM",
    "7-Ticho(El Havano)-Como Te extraño.AM-PRO..mp3*9153908*9/9/2020 10:26:18 PM",
    "8-Ticho(El Havano)-No vale la pena.AM-PRO.mp3*8104831*9/9/2020 10:26:43 PM",
    "9-Ticho(El Havano)-Tu eres la que se usa.AM-PRO..mp3*8807003*9/9/2020 10:27:05 PM",
    138080391,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\hony - La Melodia(2020)()*0*9/13/2020 9:02:43 PM",
    "Anthony la Melodia bella.mp3*7499838*9/9/2020 10:29:19 PM",
    "Anthony la Melodia choca (Prod By Chico Frank).mp3*6658596*9/9/2020 10:29:19 PM",
    "Anthony la Melodia ft KamelProd(1).mp3*7289559*9/9/2020 10:29:19 PM",
    "Anthony la Melodia ft Wil CampaEnamorao(Prød By DJ-RIKY)™.mp3*7847319*9/9/2020 10:29:20 PM",
    "Anthony la Melodia ft Yambo -tu estas rica(Prod By Chico Frank).mp3*7955314*9/9/2020 10:29:21 PM",
    "Anthony la Melodia ft Yambo.mp3*7404759*9/9/2020 10:29:20 PM",
    "Anthony la Melodia Los Pitufos(Prød By DJ-RIKY x Flow Musik)™.mp3*6548475*9/9/2020 10:29:21 PM",
    "Anthony la Melodia mi mochilita.mp3*7054398*9/9/2020 10:29:22 PM",
    "Anthony la Melodia parque de diverciones.mp3*7076439*9/9/2020 10:29:22 PM",
    "Anthony la Melodia vamos a bailar.mp3*6642435*9/9/2020 10:29:22 PM",
    "Anthony la MelodiaBajodelmarProdbyRaphel.mp3*6732759*9/9/2020 10:29:23 PM",
    78709891,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\iado Vol 2 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 10:27:42 PM",
    "Andresito &amp; AllinOne - Pensando En Tí(Blessed Music).mp3*7617775*9/9/2020 10:29:18 PM",
    "Arold Aranda - Me Enreda(Prod By Dj Rodo)Blessed Music.mp3*8324126*9/9/2020 10:29:23 PM",
    "Brian &amp; Andresito Ft Allinone - Alegria(Prod By Dj Rodo).mp3*9659505*9/9/2020 10:29:25 PM",
    "Cairon - Volver(Prod By Dj Rodo)Blessed Music.mp3*8291734*9/9/2020 10:29:26 PM",
    "Chris Casa - Equivocado(Blessed Music).mp3*6931277*9/9/2020 10:29:30 PM",
    "Eduar El Real - Es Mentira(Prod By Dj Rodo)Blessed Music.mp3*8138134*9/9/2020 10:29:39 PM",
    "Eydrian &amp; RanC - El Culpable(Prod By Dj Rodo)Blessed Music.mp3*9273938*9/9/2020 10:29:57 PM",
    "Henry - La Bendición(Prod By Dj Rodo)Blessed Music.mp3*9365889*9/9/2020 10:30:14 PM",
    67602378,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\iado Vol 3 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 10:27:43 PM",
    "Andresito &amp; AllinOne - Fin De Semana(Prod By Dj Rodo)Blessed Music.mp3*7262509*9/9/2020 10:29:18 PM",
    "Brian &amp; Andresito Ft Allinone - Alegria(Prod By Dj Rodo).mp3*9659505*9/9/2020 10:29:26 PM",
    "Chris Casa - Esta noche(Blessed Music).mp3*8207097*9/9/2020 10:29:30 PM",
    "Eduar El Real - Me Enamore(Prod By Dj Rodo)Blessed Music.mp3*8287554*9/9/2020 10:29:40 PM",
    "Eduar El Real - Todo Para Ti(Prod By Dj Rodo)Blessed Music.mp3*7494477*9/9/2020 10:29:42 PM",
    "Eydrian &amp; RanC - Te Encontre(Prod By Dj Rodo)Blessed Music.mp3*9860126*9/9/2020 10:29:57 PM",
    "Leny - Envidia(Prod By Dj Rodo)Blessed Music.mp3*8359652*9/9/2020 10:30:35 PM",
    "Leskay &amp; El Autentico -  Tu Amor(Prod By Dj Rodo)Blessed Music.mp3*8085889*9/9/2020 10:30:36 PM",
    "Los Likes  - Fan De Tus Besos(Prod By Dj Rodo)Blessed Music.mp3*9453660*9/9/2020 10:30:39 PM",
    "Los Likes  - Pensando En Ti(Prod By Dj Rodo)Blessed Music.mp3*8712828*9/9/2020 10:30:39 PM",
    "Mr Leo - Calla Esa Boquita(Prod By Dj Rodo)Blessed Music.mp3*7469399*9/9/2020 10:30:46 PM",
    "Mr Leo - Despecho(Prod By Dj Rodo)Blessed Music.mp3*7940648*9/9/2020 10:30:47 PM",
    "Shel Flores Ft Chris Casa - Confia(Blessed Music).mp3*9744142*9/9/2020 10:31:08 PM",
    "Tati &amp; El Elvis - No Mas(Prod By Dj Rodo)Blessed Music.mp3*8970917*9/9/2020 10:31:12 PM",
    "Tati &amp; El Elvis - Tu Y Yo(Prod By Dj Rodo &amp; Chanel Pro)Blessed Music.mp3*8347113*9/9/2020 10:31:12 PM",
    "Vivita - El Culpable(Prod By Dj Rodo)Blessed Music.mp3*8081709*9/9/2020 10:31:21 PM",
    "Vivita - Puede Ser(Prod By Dj Rodo)Blessed Music.mp3*7950052*9/9/2020 10:31:21 PM",
    "Vynkel - Matame(Blessed Music).mp3*7842428*9/9/2020 10:31:22 PM",
    "Walter x Danny - Muevete(Prod By Dj Rodo)Blessed Music.mp3*7439097*9/9/2020 10:31:22 PM",
    "WEST - RAMBO - (BLESSED MUSIC).mp3*6717603*9/9/2020 10:31:24 PM",
    "Yari SR - Cuentale(Prod By Dj Rodo)Blessed Music.mp3*7091146*9/9/2020 10:31:26 PM",
    "Yousef - Diferente(Prod By Dj Rodo)Blessed Music.mp3*7648077*9/9/2020 10:31:30 PM",
    180625628,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ica Cristiana - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 11:31:53 PM",
    "10go -  Un Motivo(Prod By Rodo)Blessed Music.mp3*7720175*9/9/2020 10:27:26 PM",
    "10go - Destino(Prod By Dj Rodo)Blessed Music.mp3*8543554*9/9/2020 10:27:26 PM",
    "10go - Modo Gozo(Prod By Rodo)Blessed Music.mp3*9975064*9/9/2020 10:27:27 PM",
    "10go - No voy pa trás(Blessed Music).mp3*7476591*9/9/2020 10:27:27 PM",
    "10go - Tus Promesas(Prod By Rodo)Blessed Music.mp3*9697121*9/9/2020 10:27:28 PM",
    "10go Ft Ronald - Tu Presencia(Blessed Music).mp3*9913287*9/9/2020 10:27:28 PM",
    "2n1 - Lo Que Tengo Yo(Prod By Dj Rodo)Blessed Music.mp3*8200083*9/9/2020 10:23:04 PM",
    "2N1 - Una Vida Linda(Prod By Dj Rodo)Blessed Music).mp3*7470327*9/9/2020 10:23:05 PM",
    "Chiwycan &amp; Ronald - Venceras(Blessed Music).mp3*9033195*9/9/2020 10:29:29 PM",
    "Dando Vida - Aqui Estoy(Blessed Music).mp3*12325040*9/9/2020 10:29:37 PM",
    "El Arkitecto &amp; MC Manuel - Dame una señal(Blessed Music).mp3*10433775*9/9/2020 10:29:42 PM",
    "Migue El Escogido Ft Jcyordi - Guaperia Espitual (Blessed Music).mp3*10854868*9/9/2020 10:30:44 PM",
    "Oney El Arkitecto - Cristo estara(Blessed Music).mp3*8597889*9/9/2020 10:30:50 PM",
    "Oney El Arkitecto - Puedes confiar en jesus(Blessed Music).mp3*9124517*9/9/2020 10:30:51 PM",
    "Oney El Arkitecto &amp; Melody - Cristo estara(Blessed Music).mp3*8645954*9/9/2020 10:30:51 PM",
    "Oney El Arkitecto &amp; Melody ft 2N1 - Cuando todo termina(Prod By Dj Rodo)Blessed Music.mp3*9032647*9/9/2020 10:30:51 PM",
    "Oney El Arkitecto Ft Naomi ,Karlitah &amp; Keren - Hermosa princesa(Blessed Music).mp3*8423391*9/9/2020 10:30:52 PM",
    "Ronald - Te esperare(Blessed Music).mp3*8052879*9/9/2020 10:31:03 PM",
    163520357,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\id &amp; Out Side - Me Hizo El Amor(2020)(FUSION)*0*9/13/2020 11:31:53 PM",
    "David &amp; Out Side - Me Hizo El Amor ( Pro M-music).mp3*7525659*9/9/2020 10:29:37 PM",
    7525659,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\id &amp; Out Side - Pendiente(2020)(FUSION)*0*9/13/2020 11:34:09 PM",
    "01.David &amp; Out Side - Pendiente(Oficina Secreta- Pro).mp3*10126514*9/9/2020 10:21:56 PM",
    "02.David &amp; Out Side Ft Mayco D Alma - Esto Esta Bueno.mp3*8846389*9/9/2020 10:22:43 PM",
    "03.David &amp; Out Side - Boquita Chula.mp3*8615401*9/9/2020 10:23:31 PM",
    "04.David &amp; Out Side - Solita Pa Mi.mp3*9086761*9/9/2020 10:24:18 PM",
    "05.David &amp; Out Side - Hasta Afuera(prod-black Diamond rec.).mp3*8595759*9/9/2020 10:25:00 PM",
    "06.David &amp; Out Side - Seguire(14k.prod black Diamond)..mp3*8857853*9/9/2020 10:25:37 PM",
    "07.David &amp; Out Side - Tu La Pasas Bailando.mp3*4075761*9/9/2020 10:26:06 PM",
    "08.David &amp; Out Side - Soy Yo.mp3*10154280*9/9/2020 10:26:34 PM",
    68358718,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ier Waño - Single Demo(2020)(FUNK)*0*9/13/2020 10:17:41 PM",
    "�Lenier Waño- Bailalo ahora- by Youngdaddy &amp; Dj Sonny.mp3*8583231*9/9/2020 10:30:35 PM",
    "🎧Lenier Waño- Contigo- by Youngdaddy &amp; Dj Sonny &amp; Dj Cham-1.mp3*9537447*9/9/2020 10:31:30 PM",
    "🎧Lenier Waño- Muevelo- by Youngdaddy &amp; Dj Cham-2.mp3*8831703*9/9/2020 10:31:31 PM",
    "🎧Lenier Waño- Si tu no estas- by Youngdaddy &amp; Dj Sonny &amp; Dj Cham.mp3*8739831*9/9/2020 10:31:31 PM",
    "🎧Lenier Waño- Vida loca- by Youngdaddy &amp; Dj Sonny &amp; Dj Cham.mp3*9150123*9/9/2020 10:31:32 PM",
    44842335,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ilyn Manson - We Are Chaos(2020)(ROCK)*0*9/13/2020 11:31:04 PM",
    "01 - Red, Black, And Blue.mp3*12416006*9/9/2020 10:21:40 PM",
    "02 - We Are Chaos.mp3*9590726*9/9/2020 10:22:35 PM",
    "03 - Dont Chase The Dead.mp3*10375046*9/9/2020 10:23:11 PM",
    "04 - Paint You With My Love.mp3*10731206*9/9/2020 10:24:06 PM",
    "05 - Half-Way &amp; one Step Forward.mp3*8123846*9/9/2020 10:24:43 PM",
    "06 - Infinite Darkness.mp3*8528006*9/9/2020 10:25:23 PM",
    "07 - Perfume.mp3*8552006*9/9/2020 10:25:56 PM",
    "08 - Keep My Head Together.mp3*8972486*9/9/2020 10:26:24 PM",
    "09 - Solve Coagula.mp3*10706246*9/9/2020 10:26:49 PM",
    "10 - Broken Needle.mp3*12991047*9/9/2020 10:27:07 PM",
    100986621,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ima Alerta - Deja La Locura(2020)()*0*9/13/2020 10:17:50 PM",
    "Maxima Alerta - Deja La Locura.mp3*8636934*9/9/2020 10:30:42 PM",
    8636934,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Indomables - Sobrenatural(2020)()*0*9/13/2020 10:26:15 PM",
    "5 -El Tiger Ft Indomables -Mi Locura Mi Relajo.mp3*9071235*9/9/2020 10:25:11 PM",
    "Indomables - Peligro [Prod By Sobrenatural - Wanted Music].mp3*9202323*9/9/2020 10:30:15 PM",
    "Indomables -Mi Cocha.mp3*6147159*9/9/2020 10:30:19 PM",
    "Indomables-Virgencita.mp3*5994985*9/9/2020 10:30:21 PM",
    "Las Balas - Indomables.mp3*3904440*9/9/2020 10:30:33 PM",
    34320142,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\inlucho(2020)()*0*9/13/2020 10:26:24 PM",
    "1-Mujer- alvilucho x shale .mp3*9713910*9/9/2020 10:22:15 PM",
    "2-Volvio a prender- alvilucho x shale .mp3*8312862*9/9/2020 10:23:06 PM",
    "3-Mas Dura- alvilucho x shale ft Buly x Jarol.mp3*7266774*9/9/2020 10:23:50 PM",
    "4-Mi Puta- alvilucho x shale ft Joker.mp3*9886170*9/9/2020 10:24:33 PM",
    "5-Antes de la Fama- alvilucho x shale ft skinnyyy.mp3*8886018*9/9/2020 10:25:14 PM",
    44065734,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\iolent - 2Bandoleros(2020)()*0*9/13/2020 11:31:04 PM",
    "1. J.Violent  - Bien suave - .mp3*5203053*9/9/2020 10:22:10 PM",
    "10. Dinamiko ft J. Violent - Soledad -  .mp3*8755706*9/9/2020 10:27:19 PM",
    "11. J.Violent  - Dale mami ven - .mp3*7014906*9/9/2020 10:27:38 PM",
    "12. J.Violent··#Entregate - prod by PromoRecords.mp3*7880619*9/9/2020 10:27:52 PM",
    "13. Guilero ft J. Violent - Me matas  -  .mp3*7760963*9/9/2020 10:28:03 PM",
    "2.  J. Violent ft Maikol  - Vete de mi vida - .mp3*7675282*9/9/2020 10:23:00 PM",
    "3. J.Violent - No Te  Conosco - .mp3*7648114*9/9/2020 10:23:50 PM",
    "4. J. violent  ft Zamoray- Eres tu -  .mp3*7520637*9/9/2020 10:24:32 PM",
    "5. J.Violent  - Ocean (version morfa)- .mp3*8262514*9/9/2020 10:25:14 PM",
    "6. J.Violent ft Adonaisito  - La Maldita - .mp3*7173731*9/9/2020 10:25:47 PM",
    "7. J.Violent ft Barreiro  - Encendia - .mp3*9522661*9/9/2020 10:26:15 PM",
    "8. J.Violent ft Adinaisito  - Eso es Mentira - .mp3*7029535*9/9/2020 10:26:40 PM",
    "9. J.Violent  - La conoci bailando - .mp3*7198808*9/9/2020 10:27:01 PM",
    "Encendía.J.violent X Barreiro.Star Factory productions.mp3*7485987*9/9/2020 10:29:56 PM",
    106132516,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Iroso(2020)()*0*9/13/2020 11:23:20 PM",
    "-    Dj Unic, El Chulo, Wampi, El Iroso - El Canilleo (Db) .mp3*3738782*9/9/2020 10:20:39 PM",
    "1-ANOCHE.mp3*3213939*9/9/2020 10:22:12 PM",
    "2-COSITA BONITA.mp3*3503649*9/9/2020 10:23:04 PM",
    "3-NADA SERA IGUAL.mp3*2836011*9/9/2020 10:23:50 PM",
    "4-ME DUELE.mp3*11155479*9/9/2020 10:24:33 PM",
    "5-GUACHICULEO.mp3*9094214*9/9/2020 10:25:15 PM",
    "6- YA NO MAS.mp3*3639369*9/9/2020 10:25:45 PM",
    "9-IROSO X LUCHEN X PEDRO.mp3*4346157*9/9/2020 10:27:02 PM",
    "9-MADRE IROSO.mp3*7362854*9/9/2020 10:27:03 PM",
    "Iroso x Daimon x MGY  TARDE O  TENPRANO.mp3*7210371*9/9/2020 10:30:24 PM",
    56100825,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\is Tamayo - S E E N(2020)()*0*9/13/2020 10:12:59 PM",
    "01.Chris Tamayo - La Primera Vez( S E E N ).mp3*4695759*9/9/2020 10:21:54 PM",
    "02.Chris Tamayo - Marquesina( S E E N ).mp3*4549682*9/9/2020 10:22:42 PM",
    "03.Chris Tamayo - Peliculeo( S E E N ).mp3*4195461*9/9/2020 10:23:30 PM",
    "04.Chris Tamayo - Cuarentena.mp3*3074984*9/9/2020 10:24:18 PM",
    "05.Chris Tamayo - Hello.mp3*4592176*9/9/2020 10:25:00 PM",
    "06.Chris Tamayo Ft Nicole Manzo &amp; Quique - Presidente.mp3*7047582*9/9/2020 10:25:37 PM",
    "07.Chris Tamayo Ft Kadel - Invierno.mp3*5416600*9/9/2020 10:26:06 PM",
    "08.Chris Tamayo - Mátame.mp3*3806812*9/9/2020 10:26:34 PM",
    "09.Chris Tamayo - Ratito.mp3*4957681*9/9/2020 10:26:53 PM",
    42336737,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ista Miny Cuore - En Las Nubes(2020)()*0*9/13/2020 10:12:46 PM",
    ".SAL DE ARRIBA DE MICOSA MIA X MINY CUORE X MAX PRO MUSIC.mp3*49495*9/9/2020 10:21:22 PM",
    "11-A MI MANERA -LA MINY CUORE X  DUKE X EL YEYO(MAS PRO MUSIC BY PIVE).mp3*6706661*9/9/2020 10:27:43 PM",
    "2-A LA CARA-LA MINY CUORE-{MAS PRO MUSIC PIVE PRODUCE).mp3*8204000*9/9/2020 10:23:01 PM",
    "7GANAS DE TI -LA MINY CUORE X ANUBIX X JAIRO (MAS PRO MUSIC BY PIVE).mp3*8564490*9/9/2020 10:26:17 PM",
    "8-MODO DE AVION -LA MINY CUORE (MAS PRO MUSIC BY PIVE).mp3*6709796*9/9/2020 10:26:41 PM",
    "9-OYE ESO -LA MINY CUORE X KAPOLAN(MAS PRO MUSIC BY PIVE).mp3*7918743*9/9/2020 10:27:03 PM",
    "EL PADRE-MINY CUORE-MAX PRO MUSIC BY PIVE.mp3*8441247*9/9/2020 10:29:49 PM",
    "PA Q TE ARDA X MINY CUORE X WILDEY(MAS PRO MUSIC BY PIVE).mp3*7247918*9/9/2020 10:30:55 PM",
    "SEGUROSKY-MINY CUORE-MAX PRO MUSICBY PIVE.mp3*6422718*9/9/2020 10:31:05 PM",
    60265068,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\istopher Simpson &amp; Elevación - Absolución 2019 (Bis Music)*0*9/13/2020 11:32:15 PM",
    "01 Hiéreme.mp3*7637144*9/9/2020 10:21:49 PM",
    "02 Rascacielos de Dolor.mp3*10108184*9/9/2020 10:22:37 PM",
    "03 Jugando a ser Tu Duda.mp3*7828184*9/9/2020 10:23:27 PM",
    "04 Vela  Mi Feat. Franko.mp3*10095704*9/9/2020 10:24:16 PM",
    "05 La Mentira Feat. Annie Garcés.mp3*6722264*9/9/2020 10:24:58 PM",
    "06 Elevación del Silencio.mp3*9315224*9/9/2020 10:25:34 PM",
    "07 Danielle.mp3*11656664*9/9/2020 10:25:59 PM",
    "08 Matices en el Cristal Feat. Tiago Felipe.mp3*9232664*9/9/2020 10:26:32 PM",
    "09 Un Año Atrás Feat. Maylin Quintana.mp3*7475864*9/9/2020 10:26:52 PM",
    "10 Mi Absolución Feat. Dúo Iris.mp3*9946905*9/9/2020 10:27:17 PM",
    "11 Respírame Feat. Jan Cruz (Bonus Track).mp3*9067545*9/9/2020 10:27:38 PM",
    "12 Jugando a ser tu duda (Versión Alternativa).mp3*7866585*9/9/2020 10:27:50 PM",
    "13 Elevación del Silencio (Sesión de Estudio).mp3*8879385*9/9/2020 10:28:01 PM",
    "14 Hiéreme (Sesión de Estudio).mp3*11353305*9/9/2020 10:28:12 PM",
    127185621,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\istopher Simpson &amp; Elevación - Lo Más Reciente (2020)*0*9/13/2020 11:32:14 PM",
    "Christopher Simpson &amp; Elevación - Latiendo (2020).mp3*5412384*9/9/2020 10:29:31 PM",
    "Christopher Simpson &amp; Elevación - Lejos.mp3*5109402*9/9/2020 10:29:31 PM",
    "Christopher Simpson &amp; Elevación - Sublime Gracia.mp3*6447215*9/9/2020 10:29:31 PM",
    16969001,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ito King Wolf(2020)()*0*9/13/2020 11:14:28 PM",
    "IRETE Y PEPITO KING WOLW-TELA PARTO.mp3*7255038*9/9/2020 10:30:22 PM",
    "IRETE Y PEPITO KING WOLW-YO KIERO PROBAR.mp3*7695678*9/9/2020 10:30:23 PM",
    "PEPITO KING WALLS X NENE - ELLA ES MALA - MAQUETA - LARRA DJ 58020966.mp3*7217679*9/9/2020 10:30:58 PM",
    "PEPITO KING WOLF FT EL IRETE-DIRLE A CUPIDO.mp3*7999038*9/9/2020 10:30:58 PM",
    "PEPITO KING WOLF FT EL IRETE-EL CABALLITO.mp3*8590398*9/9/2020 10:30:59 PM",
    38757831,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ivi - Lo Mas Reciente()*0*9/13/2020 11:14:27 PM",
    "Creivi- Donde está.mp3*2814377*9/9/2020 10:29:34 PM",
    "Creivi ft Fory- Lento.mp3*3155850*9/9/2020 10:29:34 PM",
    "Creivi- Ram Pa Pa Pam.mp3*3142057*9/9/2020 10:29:34 PM",
    9112284,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Kapoland(2020)()*0*9/13/2020 11:26:41 PM",
    "-               La Verdad - El Kapoland.mp3*2326094*9/9/2020 10:20:39 PM",
    "-           La Carita 2 Ennis &amp; El KapolandHigh.mp3*2259963*9/9/2020 10:20:39 PM",
    "-      Pintalo - El Kapoland.mp3*2903903*9/9/2020 10:20:39 PM",
    "- Eso no se vale - El Kapoland.mp3*9727505*9/9/2020 10:20:39 PM",
    "- Real hasta la Muerte - El Kapoland.mp3*3054787*9/9/2020 10:20:40 PM",
    "- Verde - El Kapoland.mp3*3253735*9/9/2020 10:20:40 PM",
    "!!!KimboteoDjHondaXElKapolandXYoanElCashXEnigmaXEl.mp3*4162796*9/9/2020 10:20:42 PM",
    "01. El Kapoland - El VerdeBy Honda ♫♪.mp3*3253720*9/9/2020 10:21:50 PM",
    "02. El Kapoland - La Turun Tun TunBy Honda ♫♪.mp3*2741302*9/9/2020 10:22:37 PM",
    "03. El Kapoland feat. Dj Unic (Célula music) - La Recarga.mp3*2765892*9/9/2020 10:23:28 PM",
    "04. El Kapoland - PitaloBy Honda ♫♪.mp3*2903913*9/9/2020 10:24:16 PM",
    "05. El Kapoland - Real Hasta La MuerteBy Honda ♫♪.mp3*3054825*9/9/2020 10:24:58 PM",
    "06ElKapolandXYoanElCashXEnigmaXElCondeXDjHondaKimboteo.mp3*4162781*9/9/2020 10:25:44 PM",
    "07. El Kapoland feat. Dj Unic (Célula music) -La Policía.mp3*7697311*9/9/2020 10:26:04 PM",
    "08. El kapoland - No Se Vale (Prod.ElectroBeat).mp3*9727523*9/9/2020 10:26:33 PM",
    "09ElKapolandLaVerdadProdbyNinfactory&amp;ElectroBeat.mp3*7640499*9/9/2020 10:26:59 PM",
    "10. El Kapoland  - Guapo NaturalBy Honda ♫♪.mp3*2698291*9/9/2020 10:27:19 PM",
    "El Kapoland - Loca.mp3*7978770*9/9/2020 10:29:47 PM",
    "El Kapoland - Turuntuntun.mp3*2741317*9/9/2020 10:29:47 PM",
    "El Kapoland-Lo mio es mio.mp3*3007975*9/9/2020 10:29:48 PM",
    "EL KPOLAND - GUAPO NATURALBy Honda.mp3*2698267*9/9/2020 10:29:48 PM",
    "EL KPOLAND - LA TURN TUN TUNBy Honda.mp3*2741317*9/9/2020 10:29:48 PM",
    "EL KPOLAND - LO MIO ES MIOBy Honda.mp3*3007975*9/9/2020 10:29:48 PM",
    "EL KPOLAND - PITALOBy Honda.mp3*2903903*9/9/2020 10:29:48 PM",
    "EL KPOLAND - REAL ASTA LA MUERTEBy Honda.mp3*3054787*9/9/2020 10:29:49 PM",
    "EL KPOLAND - SE ACABO EL AMORBy Honda.mp3*2699939*9/9/2020 10:29:49 PM",
    "EL KPOLAND - VERDEBy Honda.mp3*3253735*9/9/2020 10:29:49 PM",
    "KimboteoDjHondaElKapolandYoanElCashEnigmaElCrack&amp;EL.mp3*4235733*9/9/2020 10:30:28 PM",
    "KimboteoDjHondaXElKapolandXYoanElCashXEnigmaXEl.mp3*4162796*9/9/2020 10:30:28 PM",
    "Los King Boys x Kapolan En Tu Mente.mp3*4469923*9/9/2020 10:30:38 PM",
    "Olvidame que te olvide - El Kapoland.mp3*2699939*9/9/2020 10:30:50 PM",
    123991216,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\kel Miki - Jerusalema(2020)()*0*9/13/2020 9:32:03 PM",
    "Maikel Miki - Jerusalema ( Afrobeat - Afrohouse ) spanish Version Hit.mp3*8967820*9/9/2020 10:30:40 PM",
    "Maikel Miki - JERUSALEMA(Spanish Version , Master KG ft Nomcebo).mp3*8967820*9/9/2020 10:30:40 PM",
    17935640,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\kel Miki - Maikel Miki(2020)()*0*9/13/2020 9:32:10 PM",
    "01.Maikel Miki - Como Ehh !!.mp3*7692000*9/9/2020 10:22:02 PM",
    "02.Maikel Miki - Flash.mp3*7847690*9/9/2020 10:22:50 PM",
    "03.Maikel Miki - Bomba.mp3*9168441*9/9/2020 10:23:39 PM",
    "04.Maikel Miki - Temblor.mp3*7919788*9/9/2020 10:24:28 PM",
    "05.Maikel Miki - The Boom ft Seo Fernandez ( Planet Rcord ).mp3*10591513*9/9/2020 10:25:06 PM",
    "06.Maikel Miki - POM  POM ft Nofilter ( Saifam Musci Italy ).mp3*4893459*9/9/2020 10:25:41 PM",
    "07.Maikel Miki - Flow.mp3*9049322*9/9/2020 10:26:09 PM",
    "08.Maikel Miki - Pegao ft Sapienza.mp3*7044124*9/9/2020 10:26:38 PM",
    "09.Maikel Miki - Gozalo Mamacita.mp3*9247853*9/9/2020 10:26:57 PM",
    "10.Maikel Miki - Jerusalema ft Joseph Sinatra x Cacciola ( Dance Remix ).mp3*7209523*9/9/2020 10:27:23 PM",
    "11.Maikel Miki - JERUSALEMA ( Spanish Version , Master KG ft Nomcebo ) wav.mp3*8967820*9/9/2020 10:27:42 PM",
    "12.Maikel Miki - Jerusalema ( Afrobeat - Afrohouse ) spanish Version Hit.mp3*8967820*9/9/2020 10:27:55 PM",
    "13.Maikel Miki - Pegajosa ft Mike Morato ( The King of Mashup.mp3*7978568*9/9/2020 10:28:04 PM",
    "14.Maikel Miki -Reggaeton x Cubaton ( LA NUEVA CRUZ ).mp3*7729616*9/9/2020 10:28:14 PM",
    "15.Maikel Miki - El Amor.mp3*9167439*9/9/2020 10:28:20 PM",
    "16.Maikel Miki - Caliente.mp3*12608245*9/9/2020 10:28:25 PM",
    "17.Maikel Miki - Me Enamore.mp3*10472516*9/9/2020 10:28:30 PM",
    "18.Maikel Miki ( HASTA BAJO )ft Jonh Joaquim , France.mp3*8368049*9/9/2020 10:28:35 PM",
    "19.Maikel Miki - PEGATE.mp3*6933472*9/9/2020 10:28:38 PM",
    "20.Maikel Miki - ZUKU ZUKU.mp3*9677362*9/9/2020 10:28:41 PM",
    171534620,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\kel Miki - Rough Diamond(2020)()*0*9/13/2020 9:32:15 PM",
    "01.Maikel Miki - DURO(Rough Diamond).mp3*8247886*9/9/2020 10:22:02 PM",
    "02.Maikel Miki - Libres(Rough Diamond ).mp3*9567592*9/9/2020 10:22:50 PM",
    "03.Maikel Miki - Ella tiene algo(Rough Diamond).mp3*9861208*9/9/2020 10:23:40 PM",
    "04.Maikel Miki - Dejalo( Rough Diamond ).mp3*12251935*9/9/2020 10:24:27 PM",
    "05.Maikel Miki - Prendelo(Rough Diamond) prod by Geniotheproducer.mp3*8928114*9/9/2020 10:25:06 PM",
    "06. Maikel Miki - Fugitivos( Rough Diamond).mp3*10768180*9/9/2020 10:25:36 PM",
    "07.Maikel Miki - Guapo(Rough Diamond ).mp3*9527886*9/9/2020 10:26:09 PM",
    "08.Maikel Miki - Fresca(Rough Diamond).mp3*10168408*9/9/2020 10:26:38 PM",
    "09. Maikel Miki - Yo quiero Ron(Rough Diamond).mp3*10367984*9/9/2020 10:26:52 PM",
    "10. Maikel Miki - Potranca(Rough Diamond ).mp3*9608343*9/9/2020 10:27:20 PM",
    "11. Maikel Miki - Duro Version Reggaeton(Rough Diamond ).mp3*8247886*9/9/2020 10:27:39 PM",
    "12.Maikel Miki - Outro FInal X Geniotheproducer (Rough Diamond ).mp3*9608343*9/9/2020 10:27:56 PM",
    117153765,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Khris-DY(2020)()*0*9/13/2020 10:39:52 PM",
    "- MOIKAN FT KHRIS RAUDE &amp; DIONNY - YA NO  PROD BY EL PERSO.mp3*6934755*9/9/2020 10:20:39 PM",
    "! ! ! LOS Khris-Dy - EMBORRACHARTE  BY EL PERSO.mp3*8309497*9/9/2020 10:20:40 PM",
    "! ! ! LOS KRIDIIS - AL FLAY  PROD BY EL PERSO.mp3*8304483*9/9/2020 10:20:40 PM",
    "! ! ! LOS KRIDIS - ARRANCA  PROD BY EL PERSO.mp3*6548475*9/9/2020 10:20:40 PM",
    "! ! ! LOS KRIDIS - INFIELES  PROD BY EL PERSO.mp3*8888079*9/9/2020 10:20:41 PM",
    "! ! ! LOS KRIDIS - SE TERMINO  BY EL PERSO.mp3*8789943*9/9/2020 10:20:41 PM",
    "! ! ! LOS KRIDIS - TE IMAGINÉ  BY EL PERSO.mp3*7418127*9/9/2020 10:20:41 PM",
    "Confia en nuestro amor   ( KHRIS RAUDE ) prod AL2 el CKlassico &amp; DJ Mostro.mp3*9897918*9/9/2020 10:29:33 PM",
    "Gracias a ti ( KHRIS RAUDE ) prod AL2 el CKlassico &amp; DJ Mostro.mp3*10270398*9/9/2020 10:30:04 PM",
    "KHRIS RAUDE X DIONNY X DALE A LOS PEDALES X LA AIFON FENIX.mp3*8163249*9/9/2020 10:30:27 PM",
    "KHRIS RAUDEN&amp;DIOSNNY-¨DIME¨.mp3*10718820*9/9/2020 10:30:27 PM",
    "KHRIS RAUDEN&amp;DIOSNNY-¨MAMI TU¨.mp3*7391864*9/9/2020 10:30:27 PM",
    "KHRIS RAUDEN&amp;DIOSNNY-¨MI NENA¨.mp3*8955032*9/9/2020 10:30:28 PM",
    "KHRIS RAUDEN&amp;DIOSNNY-¨NO DESEASTE¨.mp3*8860991*9/9/2020 10:30:28 PM",
    "KHRIS RAUDEN&amp;DIOSNNYY-¨DEJAME HACERTE EL AMOR¨.mp3*7526656*9/9/2020 10:30:28 PM",
    "La Selva--Khris Raude Y Dionny--Los Khris-Dy (2).mp3*5413465*9/9/2020 10:30:32 PM",
    "La vi en la disco ( KHRIS RAUDE &amp; DIOSNEY ) prod AL2 el CKlassico &amp; DJ Mostro.mp3*11185278*9/9/2020 10:30:33 PM",
    143577030,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\King &amp; Jhonny - Como Tu No Hay(2020)()*0*9/13/2020 9:31:59 PM",
    "ReyKing &amp; Jhonny - Como Tu No Hay.mp3*7174431*9/9/2020 10:31:02 PM",
    7174431,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ko369(2020)()*0*9/13/2020 9:32:01 PM",
    "!!!LA PAPAYAREADYSALVI&amp;JOAKO369.mp3*7118314*9/9/2020 10:20:42 PM",
    "Caminala - Joako369.mp3*7883751*9/9/2020 10:29:27 PM",
    "Fuego - Joako369 .mp3*2734965*9/9/2020 10:29:59 PM",
    "Joako369 - Celosa.mp3*8393087*9/9/2020 10:30:25 PM",
    "JOAKO369 - COPAS .mp3*2770491*9/9/2020 10:30:25 PM",
    "JOAKO369 - TAL VEZ QUIZAS .mp3*2974873*9/9/2020 10:30:25 PM",
    "Joako369xReadySalvixChocolateMCxYesmyLaPapayaRemix.mp3*3513205*9/9/2020 10:30:26 PM",
    "SIGUEME - JOAKO369, YESMI, C4 .mp3*2828170*9/9/2020 10:31:08 PM",
    38216856,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\l Life - Mistr Will()(2020)()*0*9/13/2020 9:32:02 PM",
    "!00-PA ESTAR CONMIGO...MISTR WILL D.CUBA.mp3*9216637*9/9/2020 10:20:43 PM",
    "!10-100 PA BAJO...MISTR WILL D.CUBA.mp3*7986531*9/9/2020 10:21:00 PM",
    "!15-CANALLA...MISTR WILL D.CUBA.mp3*7641207*9/9/2020 10:21:11 PM",
    "!17-DE TI ME ENAMORE...MISTR WILL D.CUBA.mp3*10666297*9/9/2020 10:21:15 PM",
    "!18-PATABOOM...MISTR WILL D.CUBA.mp3*10929921*9/9/2020 10:21:17 PM",
    46440593,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Las Nubes - Miny Cuore(2020)()*0*9/13/2020 9:32:04 PM",
    ".SAL DE ARRIBA DE MICOSA MIA X MINY CUORE X MAX PRO MUSIC.mp3*49495*9/9/2020 10:21:22 PM",
    "1-DESCONTROL-LA MINY CUORE FT GNIO &amp; DAIRONY(MAS PRO MUSIC BY PIVE).mp3*8530008*9/9/2020 10:22:13 PM",
    "NO TE  QUIERE MINY CUORE FEAT HARRYSON  WAV.mp3*7888441*9/9/2020 10:30:49 PM",
    "WOW-MINY CUORE-MAX PRO MUSIC BY PIVE.mp3*8978269*9/9/2020 10:31:25 PM",
    25446213,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\lear Warfare - Lobotomy(2020)(POP)*0*9/13/2020 9:32:06 PM",
    "01 - Lobotomy.mp3*8219794*9/9/2020 10:21:37 PM",
    "02 - Bombshell Disease.mp3*8242782*9/9/2020 10:22:17 PM",
    "03 - Gladiator.mp3*18226782*9/9/2020 10:23:13 PM",
    "04 - Fuck Face.mp3*10410945*9/9/2020 10:24:01 PM",
    "05 - Betrayers from Hell.mp3*7468513*9/9/2020 10:24:41 PM",
    "06 - The Blood Lord Will Return.mp3*10400496*9/9/2020 10:25:31 PM",
    62969312,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Libre - Funkimba(2020)()*0*9/13/2020 9:32:10 PM",
    "01 - Qvalibre - QUE ESTAS FORMANDO TU.mp3*3819638*9/9/2020 10:21:39 PM",
    "02 - Qvalibre - LA KIM KARDASHIAN.mp3*3608569*9/9/2020 10:22:27 PM",
    "03 - Qvalibre - QUE CALOR (Homenaje a Formell).mp3*3104510*9/9/2020 10:23:21 PM",
    "04 - Qvalibre - INTERNACIONALES FEAT DESCEMER BUENO REMIX.mp3*3177235*9/9/2020 10:24:07 PM",
    "05 - Qvalibre - QUE TE PARECE.mp3*3144216*9/9/2020 10:24:50 PM",
    "06 - Qvalibre - EL TERMOMETRO.mp3*3867285*9/9/2020 10:25:29 PM",
    "07 - Qvalibre - MONEY MONI.mp3*3025934*9/9/2020 10:25:56 PM",
    "08 - Qvalibre - VAMONOS CONMIGO.mp3*3471896*9/9/2020 10:26:27 PM",
    "09 - Qvalibre - ECHALE LA CULPA AL POMO.mp3*3039308*9/9/2020 10:26:47 PM",
    "10 - Qvalibre - DURAKO.mp3*3978046*9/9/2020 10:27:12 PM",
    "11 - Qvalibre - TIRATE UN PASE.mp3*3695505*9/9/2020 10:27:34 PM",
    37932142,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\light King - Moonshine(2020)(POP)*0*9/13/2020 9:32:15 PM",
    "01 - Lift The Curse.mp3*6149795*9/9/2020 10:21:36 PM",
    "02 - Champion.mp3*7261566*9/9/2020 10:22:20 PM",
    "03 - Long Way To Heaven.mp3*7640864*9/9/2020 10:23:18 PM",
    "04 - Not Dead Yet.mp3*7304407*9/9/2020 10:24:06 PM",
    "05 - Until Youre Dead.mp3*7563542*9/9/2020 10:24:57 PM",
    "06 - Working Man.mp3*11052456*9/9/2020 10:25:32 PM",
    "07 - Highwire.mp3*8301239*9/9/2020 10:25:52 PM",
    "08 - Something To Die For.mp3*9083868*9/9/2020 10:26:28 PM",
    "09 - Dont Drink The Water.mp3*6577158*9/9/2020 10:26:44 PM",
    "10 - Nobody Wins.mp3*8393191*9/9/2020 10:27:12 PM",
    79328086,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\liva - (2020)()*0*9/13/2020 9:32:15 PM",
    "H Oliva - Hoy me la encontre (prod DACHAY).mp3*8001750*9/9/2020 10:30:07 PM",
    8001750,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\luchi - La Mentirosa(2020)()*0*9/13/2020 9:32:18 PM",
    "01.Carluchi Ft El Kenny - La Mentirosa.mp3*3653224*9/9/2020 10:21:53 PM",
    "02.Carluchi Ft TLC Dayan &amp; El Bachiller - En Vício.mp3*3457202*9/9/2020 10:22:40 PM",
    "03.Carluchi - Se Me Nota.mp3*4020193*9/9/2020 10:23:30 PM",
    "04.Carluchi Ft Fere Prowa - Desde Cero.mp3*2803931*9/9/2020 10:24:18 PM",
    "05.Carluchi Ft Lykan King - pre Te Vas.mp3*2579487*9/9/2020 10:24:59 PM",
    "06.Carluchi x El Malcriao&amp; El Bebo - No Me Hables De Amor.mp3*3049273*9/9/2020 10:25:36 PM",
    "07.Carluchi - Mi Ángel.mp3*5348049*9/9/2020 10:26:05 PM",
    "08.Carluchi  Ft El Kenny - Perdóname(Cover).mp3*4776699*9/9/2020 10:26:34 PM",
    "09.Carluchi Ft Anubix - De Cara Contigo.mp3*4892479*9/9/2020 10:26:53 PM",
    "10.Carluchi Ft Futuristico - No Voy A Dañarte.mp3*3781956*9/9/2020 10:27:21 PM",
    38362493,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ly - Heart Game(2020)()*0*9/13/2020 9:32:20 PM",
    "01.Yosly - Hola Que Tal.mp3*2973822*9/9/2020 10:22:07 PM",
    "02.Yosly - Te Mentiste.mp3*7508021*9/9/2020 10:22:56 PM",
    "03.Yosly - Escapate.mp3*52106697*9/9/2020 10:23:46 PM",
    "04.El Negrito X Yosly - Mas De Ti.mp3*38165193*9/9/2020 10:24:22 PM",
    100753733,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ly Tuttle - But I D Rather Be With You(2020)(POP)*0*9/13/2020 11:17:28 PM",
    "01 - Fake Empire.mp3*8249972*9/9/2020 10:21:29 PM",
    "02 - Shes A Rainbow.mp3*9229042*9/9/2020 10:22:29 PM",
    "03 - A Little Lost.mp3*7881123*9/9/2020 10:23:08 PM",
    "04 - Something On Your Mind.mp3*7909336*9/9/2020 10:24:08 PM",
    "05 - Mirrored Heart.mp3*10057646*9/9/2020 10:24:48 PM",
    "06 - Olympia, WA.mp3*10433809*9/9/2020 10:25:26 PM",
    "07 - Standing On The Moon.mp3*12029368*9/9/2020 10:25:57 PM",
    "08 - Zero.mp3*8694054*9/9/2020 10:26:30 PM",
    "09 - Sunflower, Vol. 6.mp3*9465189*9/9/2020 10:26:49 PM",
    "10 - How Can I Tell You.mp3*10122431*9/9/2020 10:27:11 PM",
    94071970,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ma Arcanus - Stardust Solitude(2020)(POP)*0*9/13/2020 9:31:53 PM",
    "01 - Stardust Solitude.mp3*22237216*9/9/2020 10:21:45 PM",
    "02 - Orphans.mp3*13269901*9/9/2020 10:22:26 PM",
    "03 - Haven.mp3*15823632*9/9/2020 10:23:15 PM",
    "04 - The Lighthouse Keeper.mp3*16446391*9/9/2020 10:24:11 PM",
    "05 - Straits of Devotion.mp3*15986636*9/9/2020 10:24:55 PM",
    "06 - Pilgrim.mp3*16122473*9/9/2020 10:25:29 PM",
    "07 - Kingdom of Ruins.mp3*13516497*9/9/2020 10:25:54 PM",
    "08 - Brave.mp3*22089885*9/9/2020 10:26:21 PM",
    135492631,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Marka(2020)()*0*9/13/2020 9:31:54 PM",
    "1585627200La Marka - La Bebecita.mp3*3214935*9/9/2020 10:29:03 PM",
    "1585627200MujeriegoLaMarkaftOmaritoProdQueBolaRecords.mp3*9448599*9/9/2020 10:29:04 PM",
    "1585886400LaMarkaxYairoEngaÃ±oProdPromoStudiosByPerso.mp3*2703063*9/9/2020 10:29:05 PM",
    "1585886400LaMarkaxYairoMeQuedoconunaftOmaritoByPerso.mp3*2767959*9/9/2020 10:29:05 PM",
    "1585886400LaMarkaxYairoSienteelRitmo.mp3*2714199*9/9/2020 10:29:06 PM",
    "1585886400LaMarkaxYairo-VerteIrPromoEstudioByPerso.mp3*2940759*9/9/2020 10:29:06 PM",
    "1585886400LaMarkaxYairoxKokiPichichiHazmeloByPersoPromo.mp3*2797143*9/9/2020 10:29:06 PM",
    "1585886400TeVas-LaMarkaftElPekaprodQueBolaRecords.mp3*2766807*9/9/2020 10:29:06 PM",
    "1588305600Modo no - La Marka Cubana.mp3*2300287*9/9/2020 10:29:07 PM",
    "1590984000BethobeenLaMarka&amp;YairoftAndyCalientawwwseponeweno.mp3*2542647*9/9/2020 10:29:07 PM",
    "1591156800LA MARKA -TU TIENES (www.seponeweno.nat.cu).mp3*2594199*9/9/2020 10:29:08 PM",
    "1591156800Navega Mulata- La Marka (www.seponeweno.nat.cu).mp3*2362647*9/9/2020 10:29:08 PM",
    "1591156800Niña Mala - La Marka (www.seponeweno.nat.cu).mp3*2002647*9/9/2020 10:29:09 PM",
    "Me Canse- La Marka Cubana x Joako369 x La Diosa (2).mp3*4608885*9/9/2020 10:30:42 PM",
    "Me Canse- La Marka Cubana x Joako369 x La Diosa.mp3*4608885*9/9/2020 10:30:42 PM",
    "MuchoTalentoLaMarkaCubanaFtElHueso&amp;Juanovideooficial.mp3*3159071*9/9/2020 10:30:47 PM",
    53532732,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Mayoral - Tu No Te Escapas(2020)()*0*9/13/2020 9:31:54 PM",
    "01.El Mayoral - Tu No Te Escapas.mp3*4176091*9/9/2020 10:21:56 PM",
    "02.El Mayoral - Dandola.mp3*6777714*9/9/2020 10:22:43 PM",
    "03.El Mayoral - Mi Pieza.mp3*2653661*9/9/2020 10:23:33 PM",
    13607466,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\mera Imagen - Friends(2020)()*0*9/13/2020 9:31:55 PM",
    "01.1era Imagen - Bailame.mp3*5194446*9/9/2020 10:21:51 PM",
    "02.1era Imagen - El Secreto Del Amor.mp3*6024827*9/9/2020 10:22:38 PM",
    "03.1era imagen &amp; El Santi - Meneito.mp3*7775175*9/9/2020 10:23:28 PM",
    "04.1era Imagen &amp; El Chily - Oscuridad.mp3*9331479*9/9/2020 10:24:16 PM",
    "05.1era Imagen - Gucci Gucci.mp3*8504931*9/9/2020 10:24:58 PM",
    "06.1era Imagen &amp; El Santy - Repartera.mp3*6094335*9/9/2020 10:25:36 PM",
    42925193,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\MON - R3YMON(2020)()*0*9/13/2020 9:31:55 PM",
    "01.R3YMON - Aquella Noche.mp3*3077909*9/9/2020 10:22:05 PM",
    "02.R3YMON - De  Life.mp3*3895438*9/9/2020 10:22:53 PM",
    "03.R3YMON - Dile.mp3*3216254*9/9/2020 10:23:41 PM",
    "04.R3YMON - Hoy Voy A Beber.mp3*4358954*9/9/2020 10:24:30 PM",
    "05.R3YMON - La Cama.mp3*3155650*9/9/2020 10:25:09 PM",
    "06.R3YMON - La Recuerdo.mp3*3159411*9/9/2020 10:25:43 PM",
    "07.R3YMON - No Se Imagina.mp3*3230465*9/9/2020 10:26:11 PM",
    24094081,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Montano - En La Mesa Del Bar(2020)(POP)*0*9/13/2020 9:31:57 PM",
    "1.Piq Montano - Hola.mp3*8848434*9/9/2020 10:22:11 PM",
    "2.Piq Montano - Y Duele.mp3*10106439*9/9/2020 10:23:01 PM",
    "3.Piq Montano - Para No Pensar.mp3*10882146*9/9/2020 10:23:50 PM",
    "4.Piq Montano - En La Mesa Del Bar.mp3*9783858*9/9/2020 10:24:33 PM",
    "5.Piq Montano - Deja Que La Noche Avance.mp3*6791754*9/9/2020 10:25:14 PM",
    "6.Piq Montano - No Se Si Volvera.mp3*7560138*9/9/2020 10:25:47 PM",
    "7.Piq Montano - Tenemos Toda La Noche Mami.mp3*8042068*9/9/2020 10:26:16 PM",
    "8.Piq Montano - Todos Los Días.mp3*8514697*9/9/2020 10:26:41 PM",
    70529534,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\n McLean - Shadow Man(2020)(POP)*0*9/13/2020 11:18:02 PM",
    "01 - Leaky Shoes Blues.mp3*8974051*9/9/2020 10:21:34 PM",
    "02 - The Brooklyn Blues Cafe.mp3*7957365*9/9/2020 10:22:32 PM",
    "03 - Lucia.mp3*12076353*9/9/2020 10:23:18 PM",
    "04 - Shadow Man.mp3*8422345*9/9/2020 10:24:07 PM",
    "05 - She Cry Blues.mp3*12846443*9/9/2020 10:24:54 PM",
    "06 - Sister of Mine.mp3*11920663*9/9/2020 10:25:31 PM",
    "07 - Bathtub Blues.mp3*8118279*9/9/2020 10:25:51 PM",
    "08 - Lac D’argent.mp3*13244549*9/9/2020 10:26:25 PM",
    "09 - Black Train.mp3*12280108*9/9/2020 10:26:44 PM",
    "10 - New Life.mp3*9247814*9/9/2020 10:27:12 PM",
    105087970,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\n Menez - A Lo Anuel &amp; Karol(2020)()*0*9/13/2020 11:18:21 PM",
    "01.Jhon Menez Ft Vane D - A Lo Anuel &amp; Karol.mp3*4973354*9/9/2020 10:21:59 PM",
    "02.Jhon Menez - Muerto Contigo.mp3*8958237*9/9/2020 10:22:46 PM",
    "03.Jhon Menez Ft Kimiko &amp; Yordy - Caperucita.mp3*8457117*9/9/2020 10:23:35 PM",
    "05.Jhon Menez Ft Wampy - La Conquista.mp3*8264157*9/9/2020 10:25:04 PM",
    "06.Jhon Menez Ft Charlie Maykol - Me Kemas.mp3*7712157*9/9/2020 10:25:39 PM",
    "07.Jhon Menez Ft El Furia x Mr Haison - Vuelve.mp3*8649117*9/9/2020 10:26:08 PM",
    47014139,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\n Menez - Caperucita(2020)()*0*9/13/2020 11:29:01 PM",
    "01.Jhon Menez Ft Wampy - La Conquista.mp3*8264157*9/9/2020 10:21:59 PM",
    "02.Jhon Menez - Muerto Contigo.mp3*8958237*9/9/2020 10:22:48 PM",
    "03.Jhon Menez Ft Kimiko &amp; Yordy - Caperucita.mp3*8457117*9/9/2020 10:23:36 PM",
    "04.Jhon Menez - Ganga.mp3*3254626*9/9/2020 10:24:25 PM",
    28934137,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\n Menez - Lo Mas Reciente(2020)*0*9/13/2020 11:18:40 PM",
    "!! Jhon Menez Feet Kimiko Y Yordy La Caperusita Roja.mp3*8342933*9/9/2020 10:20:41 PM",
    "!!LA CONQUISTA !! YON MENEZ FT WAMPY..2019 - copia.mp3*8493447*9/9/2020 10:20:42 PM",
    "!!LA CONQUISTA !! YON MENEZ FT WAMPY..2019.mp3*8493447*9/9/2020 10:20:42 PM",
    "GANGA MASTER.mp3*3230283*9/9/2020 10:30:00 PM",
    "JHON MENES--MUERTO CONTIGO.mp3*3163827*9/9/2020 10:30:24 PM",
    31723937,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\na - ENOC(2020)()*0*9/13/2020 11:29:57 PM",
    "01 Ozuna, Wisin, Myke Towers, Arcangel, Juanka, Cosculluela - Enemigos Ocultos.mp3*7178762*9/9/2020 10:21:50 PM",
    "02 Ozuna - Caramelo.mp3*3522781*9/9/2020 10:22:36 PM",
    "03 Ozuna, Daddy Yankee - No Se Da Cuenta.mp3*3754718*9/9/2020 10:23:28 PM",
    "04 Ozuna - Mala.mp3*3009945*9/9/2020 10:24:15 PM",
    "05 Ozuna, Doja Cat, Sia - Del Mar.mp3*3478921*9/9/2020 10:24:58 PM",
    "06 Ozuna - Sincero.mp3*3175457*9/9/2020 10:25:34 PM",
    "07 Ozuna, Zion &amp; Lennox - Que Tú Esperas.mp3*3593862*9/9/2020 10:26:04 PM",
    "08 Ozuna - Un Get.mp3*3425397*9/9/2020 10:26:33 PM",
    "09 Ozuna, J Balvin, Chencho Corleone - Una Locura.mp3*3738082*9/9/2020 10:26:52 PM",
    "10 Ozuna - A Escondidas.mp3*3261140*9/9/2020 10:27:18 PM",
    "11 Ozuna, Nicky Jam - Esto No Acaba.mp3*3519876*9/9/2020 10:27:36 PM",
    "12 Ozuna - El Reggaetón.mp3*3518184*9/9/2020 10:27:52 PM",
    "13 Ozuna, Camilo - Despeinada.mp3*3633973*9/9/2020 10:28:02 PM",
    "14 Ozuna, Wisin - Gistro Amarillo.mp3*3740969*9/9/2020 10:28:12 PM",
    "15 Ozuna - El Oso Del Dinero.mp3*2559804*9/9/2020 10:28:18 PM",
    "16 Ozuna, Jhay Cortez - No La Mires.mp3*3428347*9/9/2020 10:28:24 PM",
    "17 Ozuna - Duele Querer.mp3*1967556*9/9/2020 10:28:29 PM",
    "18 Ozuna, Karol G, Myke Towers - Caramelo (remix).mp3*3777359*9/9/2020 10:28:33 PM",
    "19 Ozuna - Del Mar (solo Version).mp3*3351001*9/9/2020 10:28:37 PM",
    "20 Ozuna - Gracias.mp3*2629603*9/9/2020 10:28:40 PM",
    70265737,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\na a Cubano(2020)()*0*9/13/2020 11:29:22 PM",
    "001.Bailen Con La Punta Del Pie - SUENA A CUBANO feat. TIKKO.mp3*3756794*9/9/2020 10:21:24 PM",
    "01.Con La Puntika.mp3*9371113*9/9/2020 10:21:55 PM",
    "02.mayami.mp3*9847829*9/9/2020 10:22:51 PM",
    "03Rica tu.mp3*6685725*9/9/2020 10:23:49 PM",
    "04.Dinero.mp3*4800297*9/9/2020 10:24:19 PM",
    "05.PELLIZCAME.mp3*4638999*9/9/2020 10:25:08 PM",
    "06.No Estas Lista----livan sama-elioxis perez---SUENA A CUBANO.mp3*9233186*9/9/2020 10:25:43 PM",
    "07.RAMPAPAN.mp3*4126917*9/9/2020 10:26:12 PM",
    "08 Como Te Pusiste.mp3*9211479*9/9/2020 10:26:30 PM",
    "09.TE LA VOY A PONER.mp3*5196495*9/9/2020 10:26:58 PM",
    "10.A Rey Muerto.mp3*8973007*9/9/2020 10:27:21 PM",
    "11.El  PUM PUM.mp3*6039678*9/9/2020 10:27:39 PM",
    "12.Amor Amor.mp3*4357119*9/9/2020 10:27:53 PM",
    "13.YAYA.mp3*10428550*9/9/2020 10:28:08 PM",
    "14. A Cojer La Cola.mp3*4732959*9/9/2020 10:28:12 PM",
    "15.Fumiga Y Cierra.mp3*10037986*9/9/2020 10:28:19 PM",
    "16. DEPENDE DE TI.mp3*9669976*9/9/2020 10:28:25 PM",
    "17.La Gloria Soy Yo.mp3*9337676*9/9/2020 10:28:29 PM",
    "18.A Recogerse.mp3*9549790*9/9/2020 10:28:34 PM",
    "MK - PARTY...SUENA CUBANO x LOS PIKAROS.mp3*7724439*9/9/2020 10:30:45 PM",
    "MKT SE FORMO..SUENA A CUBANO  mk con metales.mp3*9411159*9/9/2020 10:30:46 PM",
    "Pa Que Te Lo GozesSuena A Cubano(Homenaje Al Benny More).mp3*8897774*9/9/2020 10:30:57 PM",
    166028947,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\nny Cash - I Walk The Line The Best(2020)(POP)*0*9/13/2020 10:23:00 PM",
    "01 - Ring of Fire.mp3*6368231*9/9/2020 10:21:41 PM",
    "02 - I Walk the Line.mp3*7109063*9/9/2020 10:22:21 PM",
    "03 - Folsom Prison Blues.mp3*6527056*9/9/2020 10:23:12 PM",
    "04 - Highwayman.mp3*7369229*9/9/2020 10:24:01 PM",
    "05 - (Ghost) Riders in the Sky.mp3*9096446*9/9/2020 10:24:38 PM",
    "07 - Jackson.mp3*6681701*9/9/2020 10:25:53 PM",
    "08 - One Piece at a Time.mp3*9696217*9/9/2020 10:26:27 PM",
    "09 - Man in Black.mp3*6949181*9/9/2020 10:26:45 PM",
    "10 - Sunday Morning Coming Down.mp3*9910422*9/9/2020 10:27:14 PM",
    "11 - Cocaine Blues.mp3*7378649*9/9/2020 10:27:32 PM",
    "12 - Ragged Old Flag.mp3*7589704*9/9/2020 10:27:47 PM",
    "13 - Dont Take Your Guns to Town.mp3*7354602*9/9/2020 10:27:59 PM",
    "14 - American Remains.mp3*9928200*9/9/2020 10:28:09 PM",
    "15 - The General Lee.mp3*6928298*9/9/2020 10:28:17 PM",
    "16 - Orange Blossom Special.mp3*7491498*9/9/2020 10:28:23 PM",
    "17 - There Aint No Good Chain Gang.mp3*7855108*9/9/2020 10:28:27 PM",
    "18 - I Still Miss Someone.mp3*6188510*9/9/2020 10:28:31 PM",
    "19 - It Aint Me, Babe.mp3*7366096*9/9/2020 10:28:36 PM",
    "20 - She Used to Love Me a Lot.mp3*7577165*9/9/2020 10:28:39 PM",
    "21 - A Thing Called Love.mp3*6128937*9/9/2020 10:28:42 PM",
    "22 - Five Feet High and Rising.mp3*4339040*9/9/2020 10:28:42 PM",
    "23 - Tennessee Flat-Top Box.mp3*7208330*9/9/2020 10:28:43 PM",
    "24 - Sunday Morning Coming Down (Live).mp3*10043138*9/9/2020 10:28:44 PM",
    "25 - Hey ter.mp3*5658747*9/9/2020 10:28:45 PM",
    "26 - The Ballad of Ira Hayes.mp3*9957443*9/9/2020 10:28:46 PM",
    "28 - If I Were a Carpenter.mp3*7271024*9/9/2020 10:28:48 PM",
    "30 - Flesh and Blood.mp3*6294044*9/9/2020 10:28:48 PM",
    "31 - I Got Stripes.mp3*4980594*9/9/2020 10:28:49 PM",
    "32 - The One on the Right Is on the Left.mp3*6754843*9/9/2020 10:28:49 PM",
    "33 - Song of the Patriot.mp3*8402649*9/9/2020 10:28:49 PM",
    "34 - The Legend of John Henrys Hammer.mp3*20300902*9/9/2020 10:28:50 PM",
    "35 - Out Among the Stars.mp3*7278338*9/9/2020 10:28:51 PM",
    "36 - Understand Your Man.mp3*6565718*9/9/2020 10:28:51 PM",
    "37 - All Over Again.mp3*5344232*9/9/2020 10:28:52 PM",
    "38 - Dark as the Dungeon.mp3*7556281*9/9/2020 10:28:53 PM",
    "39 - Im Movin On.mp3*7547921*9/9/2020 10:28:53 PM",
    "40 - Wanted Man.mp3*6886488*9/9/2020 10:28:53 PM",
    "41 - Long-Legged Guitar Pickin Man.mp3*6248069*9/9/2020 10:28:54 PM",
    "43 - I Drove Her Out of My Mind.mp3*7178027*9/9/2020 10:28:54 PM",
    "44 - The Rebel-Johnny Yuma.mp3*4527122*9/9/2020 10:28:55 PM",
    "46 - The Wall.mp3*5252281*9/9/2020 10:28:55 PM",
    "47 - Me and Bobby McGee.mp3*7473720*9/9/2020 10:28:55 PM",
    "48 - Send a Picture of Mother.mp3*5266910*9/9/2020 10:28:55 PM",
    "49 - The City of New Orleans.mp3*8891661*9/9/2020 10:28:56 PM",
    "50 - Help Me Make It Through the Night.mp3*7146667*9/9/2020 10:28:56 PM",
    "51 - Baby Ride Easy.mp3*6509293*9/9/2020 10:28:57 PM",
    "52 - Jim, I Wore a Tie Today.mp3*6692151*9/9/2020 10:28:57 PM",
    "53 - All I Do Is Drive.mp3*5232428*9/9/2020 10:28:57 PM",
    "54 - Wabash Cannonball.mp3*6403745*9/9/2020 10:28:58 PM",
    "55 - If I Told You Who It Was.mp3*7396411*9/9/2020 10:28:58 PM",
    "56 - Oney.mp3*7487318*9/9/2020 10:28:59 PM",
    "57 - Highway Patrolman.mp3*12825688*9/9/2020 10:29:00 PM",
    "58 - Committed To Parkview.mp3*7896904*9/9/2020 10:29:00 PM",
    "59 - Give My Love to Rose.mp3*5714125*9/9/2020 10:29:00 PM",
    "60 - Dont Think Twice, Its All Right.mp3*7121603*9/9/2020 10:29:01 PM",
    "61 - These Are My People.mp3*6405849*9/9/2020 10:29:01 PM",
    "62 - Swing Low, Sweet Chariot.mp3*4528167*9/9/2020 10:29:02 PM",
    "63 - Its All Over.mp3*4690126*9/9/2020 10:29:02 PM",
    424772310,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\nny El Fenomeno - (2020)()*0*9/13/2020 9:06:35 PM",
    "Franny - Alma Rota (14 DE FEBRERO) ==lAFORMULA==.mp3*6849147*9/9/2020 10:29:58 PM",
    6849147,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\nny Guitar - Watson Chicago Blues(2020)(POP)*0*9/13/2020 9:06:37 PM",
    "01 - Gangster of Love.mp3*6877042*9/9/2020 10:21:29 PM",
    "02 - Space Guitar.mp3*6252193*9/9/2020 10:22:32 PM",
    "03 - Those Lonely Lonely Nights.mp3*7000340*9/9/2020 10:23:24 PM",
    "04 - Cuttin In.mp3*7930299*9/9/2020 10:24:00 PM",
    "05 - Looking Back.mp3*4788291*9/9/2020 10:24:45 PM",
    "06 - Johnny Guitar.mp3*5908421*9/9/2020 10:25:24 PM",
    "07 - I Love to Love You.mp3*6447588*9/9/2020 10:25:52 PM",
    "08 - Gettin Drunk.mp3*6583425*9/9/2020 10:26:21 PM",
    "09 - Love Me Baby.mp3*7007654*9/9/2020 10:26:44 PM",
    "10 - Embraceable You.mp3*6539541*9/9/2020 10:27:09 PM",
    "11 - Posin.mp3*6524912*9/9/2020 10:27:34 PM",
    71859706,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Nohaya - No Olvides Mi Nombre(2020)()*0*9/13/2020 9:06:38 PM",
    "01.Sr Nohaya - No Olvides Mi Nombre - Larra dj 58020966.mp3*7530002*9/9/2020 10:22:07 PM",
    "02.Sr Nohaya - Una Viducha - Larra dj 58020966.mp3*6939634*9/9/2020 10:22:55 PM",
    "03.Sr Nohaya Ft La Geisha - No Hagas Planes.mp3*7851159*9/9/2020 10:23:41 PM",
    22320795,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\NY V - No Estan Pa Na(2020)()*0*9/13/2020 9:06:38 PM",
    "01.GENNY V - Modo Prosti(MAX PRO MUSIC BY PIVE).mp3*7041243*9/9/2020 10:21:58 PM",
    "02.GENNY V - No Estan Pa Na(MAX PRO MUSIC BY PIVE).mp3*4427113*9/9/2020 10:22:44 PM",
    11468356,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ny X Ozamy Ft Wow Popy - Si Pudiera(2020)()*0*9/13/2020 8:58:51 PM",
    "JDany X Ozamy Ft Wow Popy - Si Pudiera.mp3*6743517*9/9/2020 10:30:24 PM",
    6743517,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\O &amp; DAYRONI(2020)()*0*9/13/2020 8:59:59 PM",
    "Cruzando Los Dedos Gnio X Dayroni X Cristiancito.mp3*8580099*9/9/2020 10:29:35 PM",
    "Free Style Gnio.mp3*6059799*9/9/2020 10:29:59 PM",
    "Gnio &amp; Dayroni Adicto.mp3*2978671*9/9/2020 10:30:00 PM",
    "Gnio &amp; Dayroni Cualquier Cosa.mp3*4358685*9/9/2020 10:30:00 PM",
    "Gnio &amp; Dayroni Mueve Mueve Larra Dj 58020966.mp3*7860190*9/9/2020 10:30:01 PM",
    "Gnio &amp; Dayroni Patinaje.mp3*3232530*9/9/2020 10:30:02 PM",
    "Gnio &amp; DayroniMuñecas7maMusic.mp3*6849809*9/9/2020 10:30:03 PM",
    "Gnio X Dayroni Chanchullo Larra Dj 58020966.mp3*7769283*9/9/2020 10:30:04 PM",
    "Karma - Gnio - Wampi- Iyawo Oggun 7maMusic.mp3*9819409*9/9/2020 10:30:27 PM",
    "Ni A La Buena Ni Las Malas Master Gnio X Dayroni X Logico Rey.mp3*8294903*9/9/2020 10:30:48 PM",
    "Ni Hablar De Lola Gnio &amp; Dayroni Ft Andi Calienta &amp; Cuco.mp3*4911696*9/9/2020 10:30:48 PM",
    "R I P Dany Gnio &amp; Dayroni.mp3*9274839*9/9/2020 10:31:01 PM",
    "Somos SENSEI- Dj Unic, Gnio &amp; Dayroni(Omar1to Promo).mp3*6191401*9/9/2020 10:31:10 PM",
    86181314,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\o Frank La Melodía - No Me Llores(2020)()*0*9/13/2020 11:09:38 PM",
    "01.Omi Hernandez Ft Solo Frank La Melodía - Ojalá.mp3*2372488*9/9/2020 10:22:04 PM",
    "02.Baby Lores Ft Solo Frank La Melodia - No Me Llores.mp3*3065610*9/9/2020 10:22:39 PM",
    5438098,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\o Light NiNo - Rookie(2020)()*0*9/13/2020 9:00:12 PM",
    "NiNo Light NiNo - Rookie.mp3*3043840*9/9/2020 10:30:48 PM",
    3043840,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\o Y Zizo - (2020)()*0*9/13/2020 11:15:49 PM",
    "AIDOOO ORIGINAL TOYO, ZIZO, EL MAFIA.mp3*6759912*9/9/2020 10:29:16 PM",
    "Copia de Wow BOUNCE.mp3*8492403*9/9/2020 10:29:34 PM",
    "repar.mp3*8207319*9/9/2020 10:31:02 PM",
    "sipsom toyo bonche.mp3*6762002*9/9/2020 10:31:09 PM",
    "TOYO NOCHE DH C F AH 120.mp3*5086695*9/9/2020 10:31:13 PM",
    "TOYO X ZIZO X EL GUAPO X LA AIFON FENIX.mp3*7969940*9/9/2020 10:31:14 PM",
    "TOYO X ZIZO X FALCEDAD X LA AIFON FENIX.mp3*7779768*9/9/2020 10:31:14 PM",
    "TOYO X ZIZO X INTENCIONES X LA AIFON FENIX.mp3*7855001*9/9/2020 10:31:15 PM",
    "TOYO X ZIZO X LA MOSITA X LA AIFON FENIX.mp3*7838286*9/9/2020 10:31:15 PM",
    "TOYO X ZIZO X MAFIA X TO EL TIEMPO X LA AIFON FENIX.mp3*8031589*9/9/2020 10:31:15 PM",
    "TOYO X ZIZO X SE REPARTEA X LA AIFON FENIX.mp3*8082792*9/9/2020 10:31:16 PM",
    "TOYO X ZIZO X VAGABUNDO X LA AIFON FENIX.mp3*8057711*9/9/2020 10:31:16 PM",
    90923418,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ocho(2020)()*0*9/13/2020 9:01:14 PM",
    "14904805587780370602.mp3*6620439*9/9/2020 10:29:13 PM",
    "14904805587780370604.mp3*8926359*9/9/2020 10:29:13 PM",
    "14918328485585355002.mp3*7326999*9/9/2020 10:29:13 PM",
    "14924975140749443396.mp3*7326999*9/9/2020 10:29:14 PM",
    "15165896297516892334.mp3*5726679*9/9/2020 10:29:15 PM",
    "15168148097330577579.mp3*8366679*9/9/2020 10:29:15 PM",
    44294154,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\old Light - Estrenos(2020)()*0*9/13/2020 9:01:30 PM",
    "(((...HOY LA FIESTA NO PARA-HAROL LIGHT-PROD BY KEVIN IBRAUNCH...))).mp3*3661815*9/9/2020 10:21:21 PM",
    "(((...NO AGUANTO UN DIA MAS-HAROL LIGHT-PRO BY KEVIN IBRAUNCH-...))).mp3*3447273*9/9/2020 10:21:21 PM",
    "HAROL LIGHT - Admitelo -  .mp3*8435967*9/9/2020 10:30:10 PM",
    "harol lihgt -  Basta ya - Star Factory  by Dinamiko.mp3*7280310*9/9/2020 10:30:10 PM",
    "harol lihgt -  Mala - Star Factory  by Dinamiko.mp3*7708718*9/9/2020 10:30:11 PM",
    "harol lihgt -  Me perdi en tu voca  - Star Factory  by Dinamiko.mp3*8887363*9/9/2020 10:30:11 PM",
    "harol maket.mp3*8320143*9/9/2020 10:30:12 PM",
    "Harold - Admitelo -  .mp3*8435967*9/9/2020 10:30:12 PM",
    56177556,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\omables - Sobrenatural(2020)()*0*9/13/2020 11:27:22 PM",
    "1- El Envia2 Ft Los Indomables - El Daño.mp3*8031898*9/9/2020 10:22:09 PM",
    "2- El Envia2 Ft Los Indomables-Trankilo Contigo.mp3*7137178*9/9/2020 10:22:59 PM",
    "5 -El Tiger Ft Indomables -Mi Locura Mi Relajo.mp3*9071235*9/9/2020 10:25:12 PM",
    "7 -Indomables -Ke Bien Estamos.mp3*8941719*9/9/2020 10:26:14 PM",
    "Bajale 2El Taiger Ft IndomableProd.By Meko.mp3*8347287*9/9/2020 10:29:24 PM",
    "Indomables - La Dura [Prod By Sobrenatural - Wanted Music].mp3*9159519*9/9/2020 10:30:15 PM",
    "Indomables - Peligro [Prod By Sobrenatural - Wanted Music].mp3*9202323*9/9/2020 10:30:16 PM",
    "Indomables - Yo Soy Asi [Prod By Sobrenatural - Wanted Music].mp3*8158323*9/9/2020 10:30:17 PM",
    "Indomables- A Ke No Entras.mp3*6074967*9/9/2020 10:30:17 PM",
    "Indomables Ft Coto El Chocolate.mp3*6035055*9/9/2020 10:30:18 PM",
    "Indomables Ft Harrison - La Wariskay [Prod By Sobrenatural - Wanted Music].mp3*9448707*9/9/2020 10:30:19 PM",
    "Indomables -Mi Cocha.mp3*6147159*9/9/2020 10:30:20 PM",
    "Indomables-Ke Kenke.mp3*6008151*9/9/2020 10:30:21 PM",
    "Indomables-Virgencita.mp3*5994985*9/9/2020 10:30:22 PM",
    "Las Balas - Indomables.mp3*3904440*9/9/2020 10:30:34 PM",
    111662946,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Oro - Toxica(Prod By Ernesto Losa-T.M)(2020)()*0*9/13/2020 9:02:34 PM",
    "Mi Oro - Toxica(Prod By Ernesto Losa-T.M).mp3*5042265*9/9/2020 10:30:43 PM",
    5042265,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Padrino Del Genero - A La Moda(2020)()*0*9/13/2020 9:02:35 PM",
    "01.El Padrino Del Genero Ft Jeikel - Mojaito 2.mp3*4779323*9/9/2020 10:21:56 PM",
    "02.El Padrino Del Genero - A La Moda.mp3*3901845*9/9/2020 10:22:43 PM",
    "03.El Padrino Del Genero - Mojaito 2 Ft Heikel.mp3*3389845*9/9/2020 10:23:33 PM",
    "04.El Padrino Del Genero - Miami Esta De Party.mp3*3457979*9/9/2020 10:24:22 PM",
    "05.El Padrino Del Genero Ft El Negrito &amp; El Yuka - PutiPuteo.mp3*9485045*9/9/2020 10:25:01 PM",
    "06.El Padrino Del Genero Ft Alvaro La Figura - 12 De La Noche.mp3*6797746*9/9/2020 10:25:38 PM",
    "07.El Padrino Del Genero - Farándula Cubana.mp3*4926961*9/9/2020 10:26:06 PM",
    "08.El Padrino Del Genero Ft NKM01 - Pa Folloni.mp3*5908120*9/9/2020 10:26:35 PM",
    "09.El Padrino Del Genero Ft Osmani Garcia - Mientes.mp3*8262999*9/9/2020 10:26:55 PM",
    "10.El Padrino Del Genero Ft Manu Manu - Menor.mp3*4856117*9/9/2020 10:27:21 PM",
    "11.El Padrino Del Genero - Fiesta Na Ma.mp3*5303074*9/9/2020 10:27:40 PM",
    61069054,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Payaso -(2020)(CUBANO)*0*9/13/2020 8:26:24 PM",
    "(Amigos)(El Payaso) Prod By Dj Johnny + El YeyoMas Music.mp3*8068153*9/9/2020 10:21:22 PM",
    "El PayasoEl Payaso X LeyMas Music Dj Johnny + Yenko2020 !!!.mp3*9447159*9/9/2020 10:29:51 PM",
    "Hablate PiezaEl Payaso X LeyMas Music Dj Johnny + Yenko2020 !!!.mp3*7383639*9/9/2020 10:30:09 PM",
    "Lanchona - El Payaso X Ley.mp3*4190591*9/9/2020 10:30:33 PM",
    "Los King BoysXPayaso X Ley-Cazadera-( Remix )ByForyGalaxy Musik.mp3*8063401*9/9/2020 10:30:38 PM",
    "Payaso - El Payaso X Ley.mp3*7938519*9/9/2020 10:30:57 PM",
    "Payaso X Amenazy X Chavalito X Wasa Wasa X La Aifon Fenix.mp3*8242839*9/9/2020 10:30:58 PM",
    53334301,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\pi - Demo (2020)()*0*9/13/2020 8:26:16 PM",
    "Campista - Wampi.mp3*7857966*9/9/2020 10:29:27 PM",
    "Ciego Sordo Y Mudo - (Wampi Feat Mariana Y La Makynaria).mp3*7643886*9/9/2020 10:29:32 PM",
    "Wampi - Muñeca.mp3*4450605*9/9/2020 10:31:23 PM",
    "Wampi - Traqueteo.mp3*4479196*9/9/2020 10:31:23 PM",
    "Wampi X Titico - Chikitico.mp3*7593006*9/9/2020 10:31:23 PM",
    32024659,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\pi - Mama Eh(2020)()*0*9/13/2020 8:26:11 PM",
    "1-Wampi - Mama Eh.mp3*6869127*9/9/2020 10:22:16 PM",
    "2-Wampi - 3 Marias.mp3*4002207*9/9/2020 10:23:07 PM",
    "3-Wampi - Gastando Papeles.mp3*7250247*9/9/2020 10:23:52 PM",
    "4-Wampi X Manu Manu - La Cosita.mp3*8654727*9/9/2020 10:24:35 PM",
    "5-Wampi - La Suerte.mp3*3575211*9/9/2020 10:25:17 PM",
    "6-Wampi - Amor de Escuela.mp3*4095123*9/9/2020 10:25:49 PM",
    "7-Wampi - Enfocao.mp3*6979695*9/9/2020 10:26:19 PM",
    41426337,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Pirata ft El Brillante(2020)()*0*9/13/2020 8:26:05 PM",
    "01 Intro El Pirata ft El Brillante - X Musik (Intro).mp3*10212338*9/9/2020 10:21:49 PM",
    "02 El Pirata ft El Brillante - X Musik (Ni mala ni buena).mp3*8593791*9/9/2020 10:22:36 PM",
    "03 El Pirata ft El Brillante - X Musik (No soy perfecto).mp3*10609398*9/9/2020 10:23:27 PM",
    "04 El Pirata ft El Brillante - X Musik (Corazon lastimado).mp3*9741089*9/9/2020 10:24:13 PM",
    "04 El Pirata ft El Brillante - X Musik (Que decida ella).mp3*8614689*9/9/2020 10:24:13 PM",
    "05 El Pirata ft El Brillante - X Musik (Y me enamore).mp3*9261480*9/9/2020 10:24:57 PM",
    "06 El Pirata ft El Brillante - X Musik (Hasta el piso).mp3*8256289*9/9/2020 10:25:33 PM",
    "07 El Pirata ft El Brillante - X Musik (Amor sin cura).mp3*9647048*9/9/2020 10:26:00 PM",
    "07 El Pirata ft El Brillante - X Musik (La nena mia).mp3*8357644*9/9/2020 10:26:02 PM",
    "08 El Pirata ft El Brillante - X Musik (Tengo).mp3*8008647*9/9/2020 10:26:30 PM",
    "08 El Pirata ft El Brillante ft Ciey- X Musik (Me Llamas).mp3*10064682*9/9/2020 10:26:31 PM",
    "09 El Pirata ft El Brillante - X Musik ( pal Face).mp3*8447504*9/9/2020 10:26:52 PM",
    "10 El Pirata ft El Brillante - X Musik (Amor sin cura).mp3*9647048*9/9/2020 10:27:16 PM",
    "12 El Pirata ft El Brillante - X Musik (Malas lenguas).mp3*10477742*9/9/2020 10:27:49 PM",
    "13 El Pirata ft El Brillante - X Musik (Sexo Bruto).mp3*9537334*9/9/2020 10:28:00 PM",
    "2- Amor Sin Cura - ( JP.El Pirata).mp3*9647048*9/9/2020 10:22:58 PM",
    "3- Enamorate de mi - ( JP.El Pirata ).mp3*7810116*9/9/2020 10:23:49 PM",
    "4- Te AmoYami &amp; BIG Jc Ft JP El Pirata++.mp3*3287543*9/9/2020 10:24:32 PM",
    "5- MadreJP El Pirata &amp; BIG Jc++.mp3*3038439*9/9/2020 10:25:12 PM",
    "DISPUESTAELPIRATE &amp; EL BRILLANTEpro byHALEX THE BEST clean sound records ok.mp3*8604196*9/9/2020 10:29:39 PM",
    171864065,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\PRO MUSIC BY PIVE(2020)()*0*9/13/2020 8:25:54 PM",
    ".CRUZANDO LOS DEDOS-GNIO X DAYRONI X CRISTIANCITO-MAX PRO MUSIC BY PIVE.mp3*49495*9/9/2020 10:21:22 PM",
    ".MI NEGRITO-GNIO X DIRONI X ANDI CALIENTA X CUCO- MAX PRO MUSIC BY PIVE.mp3*49495*9/9/2020 10:21:22 PM",
    ".MIS SUEÑOS-ASHELI XCHARLES X PUIG-MAX PRO MUSIC BY PIVE.mp3*49495*9/9/2020 10:21:22 PM",
    ".RICO TITI-LANDY EL 13-MAX PRO MUSIC BY PIVE.mp3*49495*9/9/2020 10:21:22 PM",
    ".SIN TI-RICHARD-MAX PRO MUSIC BY PIVE.mp3*49495*9/9/2020 10:21:23 PM",
    ".VOLVERE-CHARLES X PUIG -MAX PRO MUSIC BY PIVE.mp3*49495*9/9/2020 10:21:23 PM",
    "CRUZANDO LOS DEDOS-GNIO X DAYRONI X CRISTIANCITO-MAX PRO MUSIC BY PIVE.mp3*8580099*9/9/2020 10:29:35 PM",
    "CUALQUIER COSA-GNIO X DAYRONI-MAX PRO MUSIC BY PIVE.mp3*4358685*9/9/2020 10:29:36 PM",
    "HECHAME BALA-CHARLES X PUIG-MAX PRO MUSIC BY PIVE.mp3*7892103*9/9/2020 10:30:13 PM",
    "LAS MALDITAS-EMINENCIA 3 X POPI-MAX PRO MUSIC BY PIVE.mp3*7190535*9/9/2020 10:30:34 PM",
    "MI NEGRITO-GNIO X DIRONI X ANDI CALIENTA X CUCO- MAX PRO MUSIC BY PIVE.mp3*4911696*9/9/2020 10:30:43 PM",
    "MIS SUEÑOS-ASHELI XCHARLES X PUIG-MAX PRO MUSIC BY PIVE.mp3*7666883*9/9/2020 10:30:45 PM",
    "REGAETON Y WHATSAPP-GNIO X DAIRONY-MAX PRO MUSIC BY PIVE.mp3*5109673*9/9/2020 10:31:01 PM",
    "RICO TITI-LANDY EL 13-MAX PRO MUSIC BY PIVE.mp3*7162347*9/9/2020 10:31:03 PM",
    "SACLA DE AQUI-GNIO X DAIRONY-MAX PRO MUSIC BY PIVE.mp3*9289610*9/9/2020 10:31:04 PM",
    "SAL DE ARRIBA DE MI-COSA MIA X MINY CUORE X MAX PRO MUSIC.mp3*8339799*9/9/2020 10:31:05 PM",
    "SIN TI-RICHARD-MAX PRO MUSIC BY PIVE.mp3*7725063*9/9/2020 10:31:09 PM",
    "VOLVERE-CHARLES X PUIG -MAX PRO MUSIC BY PIVE.mp3*9211479*9/9/2020 10:31:22 PM",
    87734942,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ra De Oro - MrWill D.Cuba(2020)()*0*9/13/2020 9:16:28 PM",
    "!00-LOBO...MISTR WILL D.CUBA.mp3*9583101*9/9/2020 10:20:43 PM",
    "!01-TU NA MA...MISTR WILL D.CUBA.mp3*7854314*9/9/2020 10:20:48 PM",
    "!02-CAE LA NOCHE...MISTR WILL D.CUBA.mp3*9715681*9/9/2020 10:20:49 PM",
    27153096,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ré - Suerte(2020)()*0*9/13/2020 9:16:34 PM",
    "01.Tiaré Ft Damian The Lion - Doble Cara(by. Tony el Genial)STARS MUSIC.mp3*7667643*9/9/2020 10:22:07 PM",
    "02.Tiaré - A Cualquier Hora(by. Tony el Genial)STARS MUSIC.mp3*8660487*9/9/2020 10:22:56 PM",
    "03.Tiaré Ft Damian The Lion - Interminable(by. Tony el Genial)STARS MUSIC.mp3*10612767*9/9/2020 10:23:42 PM",
    "04.Tiaré Ft. Damian The Lion - Haz Como El Mar(by. Tony el Genial)STARS MUSIC.mp3*8540427*9/9/2020 10:24:31 PM",
    "05.Tiaré - No Es Como Tu Quieras.mp3*5821603*9/9/2020 10:25:09 PM",
    "06.Tiaré - Ando Sola(by. Tony el Genial)STARS MUSIC.mp3*8149734*9/9/2020 10:25:44 PM",
    "07.Tiaré - Para Tocarte De Nuevo(by Tony el Genial).mp3*6987198*9/9/2020 10:26:13 PM",
    "08.Tiarè Ft Tony El Genial - Besame En Tus Sueños.mp3*7961546*9/9/2020 10:26:39 PM",
    "09.Tiarè - Dame La Otunidad.mp3*7494490*9/9/2020 10:26:58 PM",
    "10.Tiaré - Un Mundo Que No Sabe(by. Tony el Genial).mp3*10441088*9/9/2020 10:27:24 PM",
    "11.Tiaré Ft Damian The Lion - Sin Frenos(By Tony El Genial).mp3*8392179*9/9/2020 10:27:43 PM",
    "12.Tiarè - Tu Corazòn En El Cielo(By. Tony El Genial) Stars Music.mp3*6657512*9/9/2020 10:27:56 PM",
    "13.Tiarè - Cizaña Con Maldad.mp3*4818501*9/9/2020 10:28:05 PM",
    102205175,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Real Y La Espiral(2020)()*0*9/13/2020 9:16:30 PM",
    "01-CUANDO MUERA {CrazY-72670089}.mp3*4762713*9/9/2020 10:22:08 PM",
    "02-FIESTA AND PARTY {CrazY-72670089}.mp3*7837203*9/9/2020 10:22:58 PM",
    "03-MENTIRA {CrazY-72670089}.mp3*9448707*9/9/2020 10:23:48 PM",
    "04-MI VECINA {CrazY-72670089}.mp3*8959959*9/9/2020 10:24:31 PM",
    "05-PALO BOMBO {CrazY-72670089}.mp3*9877719*9/9/2020 10:25:10 PM",
    "06-PENSARAS EN MI {CrazY-72670089}.mp3*11415639*9/9/2020 10:25:45 PM",
    "07-QUE TU TIENES {CrazY-72670089}.mp3*4346679*9/9/2020 10:26:13 PM",
    "08-SOY ASI {CrazY-72670089}.mp3*3876879*9/9/2020 10:26:40 PM",
    "09-LAGRIMAS NEGRAS {CrazY-72670089}.mp3*4716255*9/9/2020 10:27:00 PM",
    "10-SE TE FUE LA ROSCA {CrazY-72670089}.mp3*10716123*9/9/2020 10:27:29 PM",
    "11-AMIGO DE QUE {CrazY-72670089}.mp3*4333660*9/9/2020 10:27:45 PM",
    "12-VERDADERO - OKANA {CrazY-72670089}.mp3*5174345*9/9/2020 10:27:57 PM",
    "13-SABOR A MI TEMA {CrazY-72670089}.mp3*8485719*9/9/2020 10:28:08 PM",
    "15-GOOD BYE {CrazY-72670089}.mp3*8355159*9/9/2020 10:28:20 PM",
    102306759,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\reno Especial 10 - Mistr Will - Real Life()(2020)()*0*9/13/2020 9:16:31 PM",
    "!02-DESCONTROL...MISTR WILL D.CUBA.mp3*8870912*9/9/2020 10:20:51 PM",
    "!04-TRANCA...MISTR WILL D.CUBA.mp3*9915375*9/9/2020 10:20:53 PM",
    "!05-LO MENEAN...MISTR WILL D.CUBA.mp3*8316724*9/9/2020 10:20:55 PM",
    "!08-NUNCA ANDA SOLA...MISTR WILL D.CUBA x YOLDIA.mp3*10871679*9/9/2020 10:20:59 PM",
    "!11-AMIGA X AMIGA...MISTR WILL D.CUBA x TU ALBE LA ROYA.mp3*10479380*9/9/2020 10:21:03 PM",
    "!12-PROMESAS DE AMOR...MISTR WILL D.CUBA.mp3*9288294*9/9/2020 10:21:05 PM",
    "!13-FUCKING PANDA...MISTR WILL (D.CUBA).mp3*9686694*9/9/2020 10:21:07 PM",
    "!18-PATABOOM...MISTR WILL D.CUBA.mp3*10929921*9/9/2020 10:21:18 PM",
    78358979,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ri - Ya Es Muy Tarde(2020)()*0*9/13/2020 9:16:31 PM",
    "01.Ferri - Ya Es Muy Tarde  (Prod Roit el quimico).mp3*3604395*9/9/2020 10:21:57 PM",
    "02.Ferri - Preso (Prod Roit el quimico).mp3*4873899*9/9/2020 10:22:44 PM",
    "03.Ferri - Casi Terminado (Prod Roit el quimico).mp3*4678671*9/9/2020 10:23:34 PM",
    "04.Ferri - Cuidame (Prod Roit el Quimico).mp3*4605591*9/9/2020 10:24:24 PM",
    "05.Ferri - Llevatelo Todo  (Prod Roit el quimico).mp3*3913941*9/9/2020 10:25:03 PM",
    "06.Ferri - Necio (makete Roit el Quimico).mp3*4313271*9/9/2020 10:25:39 PM",
    "07.Ferri - Se Parte (Prod Roit el quimico).mp3*3906111*9/9/2020 10:26:07 PM",
    "08.Ferri - Supe Que Si (Prod Roit el Quimico).mp3*4918269*9/9/2020 10:26:36 PM",
    "09.Ferri - Verte Otra Vez (Prod Roit el quimico).mp3*4493361*9/9/2020 10:26:55 PM",
    "10.Ferri Ft El Heriero Papa - La Patica (Prod Roit el quimico).mp3*4192689*9/9/2020 10:27:22 PM",
    "11.Ferri Ft Jonathan X Marco El Del Barrio - Yo De Ti.mp3*7360638*9/9/2020 10:27:40 PM",
    50860836,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Ricky &amp; El Yanky - CON CLASE(2020)()*0*9/13/2020 9:16:32 PM",
    "01.El Ricky &amp; El Yanky - Hasta El Final.mp3*6576663*9/9/2020 10:21:57 PM",
    "02.El Ricky &amp; El Yanky - Amigo De Que.mp3*7165479*9/9/2020 10:22:44 PM",
    "03.Harryson Ft El Ricky &amp; El Yanky - Tu Tanke.mp3*7568478*9/9/2020 10:23:34 PM",
    "04.El Ricky &amp; El Yanky - Para Mi Casita.mp3*7524615*9/9/2020 10:24:24 PM",
    "05.El Ricky &amp; El Yanky - Tactea.mp3*6575619*9/9/2020 10:25:02 PM",
    "06.El Ricky &amp; El Yanky - Tu Tanke Soy Yo.mp3*7568463*9/9/2020 10:25:39 PM",
    "07.El Ricky &amp; El Yanky - La Hija Del Cabo.mp3*6474519*9/9/2020 10:26:07 PM",
    "08.El Ricky &amp; El Yanky Ft El Doni - Publicalo.mp3*6504654*9/9/2020 10:26:36 PM",
    "09.El Ricky &amp; El Yanky - Q Fue Lo Q Fumates.mp3*7935951*9/9/2020 10:26:55 PM",
    63894441,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\rles Luis - 2 Locos(2020)()*0*9/13/2020 9:16:32 PM",
    "01.Charles Luis - Besame Lento.mp3*6216566*9/9/2020 10:21:54 PM",
    "02.Charles Luis - Ven A Mi.mp3*3223562*9/9/2020 10:22:41 PM",
    "03.Charles Luis - 2 Locos.mp3*2956104*9/9/2020 10:23:30 PM",
    12396232,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\rlie Anthony (2020)()*0*9/13/2020 9:16:32 PM",
    "Charlie Anthony Ft Leo Murga - PANTY MP3 .mp3*10351026*9/9/2020 10:29:29 PM",
    "Kimiko X Yordy Ft Leo Murga y Charlie Anthony - MULATA.mp3*8779673*9/9/2020 10:30:29 PM",
    19130699,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\rpoljak - La Roue Tourne Igo(2020)(POP)*0*9/13/2020 9:16:33 PM",
    "01 - Triomphe de lamour.mp3*6426809*9/9/2020 10:21:47 PM",
    "02 - Clarks aux pieds (feat. Daddy Mory).mp3*7935641*9/9/2020 10:22:20 PM",
    "03 - Jeu de con.mp3*8308670*9/9/2020 10:23:17 PM",
    "04 - Beber.mp3*8395396*9/9/2020 10:24:00 PM",
    "05 - Les anges aux dents cassées.mp3*6771625*9/9/2020 10:24:45 PM",
    "06 - Igo.mp3*6041241*9/9/2020 10:25:22 PM",
    "07 - 2min40.mp3*5821813*9/9/2020 10:25:50 PM",
    "08 - Trash com.mp3*7777862*9/9/2020 10:26:29 PM",
    "09 - Penave.mp3*7942955*9/9/2020 10:26:47 PM",
    "10 - Gueuler cest pas la peine (feat. Sir Samuel).mp3*6863577*9/9/2020 10:27:11 PM",
    "11 - 7 ans de malheur.mp3*8667071*9/9/2020 10:27:32 PM",
    80952660,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\s Vera Ft Nesto - Te Esperare(2020)()*0*9/13/2020 9:07:24 PM",
    "Kris Vera Ft Nesto - Te Esperare.mp3*3107370*9/9/2020 10:30:30 PM",
    3107370,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\sa Vol 1 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 11:23:52 PM",
    "01- Gracil y sus cumbancheros - que tu quieres(Prod By Dj rodo).mp3*10409822*9/9/2020 10:21:48 PM",
    "03- Los Universales - (Victor Manuel Tellez Revé)Que Bien Me Siento(Blessed Music).mp3*8963603*9/9/2020 10:23:28 PM",
    "04- Kiuban Salsa - Te Quedaras Solo (Rogelio Sotolongo)(Blessed Music).mp3*16859897*9/9/2020 10:24:14 PM",
    "05- Gracil Y Sus Cumbancheros - Amor Amor(Prod By Dj Rodo)Blessed Music.mp3*12486999*9/9/2020 10:24:58 PM",
    "06- Yohana Abreu - Si Te Viera(Yosvani Laurencio)(Prod By Dj Rodo)Blessed Music.mp3*9486052*9/9/2020 10:25:35 PM",
    "07- Melao Ft Legendarios - Cuando Tin Tiene(Alberto Lopez)Blessed Music.mp3*9477731*9/9/2020 10:26:02 PM",
    "08- Gracil y sus cumbancheros - Toca el tres(Prod By Dj rodo).mp3*9069175*9/9/2020 10:26:32 PM",
    "09- Aroldis Aranda - Dame un Beso (Aroldis Aranda Escalona)Blessed Music.mp3*9428620*9/9/2020 10:26:51 PM",
    "10- Yohana Abreu - Estoy Buscando un hombre (D.R) Blessed Music.mp3*9366971*9/9/2020 10:27:18 PM",
    95548870,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\sa Vol 2 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 11:25:54 PM",
    "11- Los Universales - Señorita(Camila Cabello)Blessed Music.mp3*9673126*9/9/2020 10:27:36 PM",
    "12- Kiuban Salsa - Magico Hechizo (Rogelio Sotolongo)(Blessed Music).mp3*14310384*9/9/2020 10:27:51 PM",
    "14- Dando Vida - Aqui Estoy(Blessed Music).mp3*12316718*9/9/2020 10:28:11 PM",
    "15- Yohana Abreu - No mereces este amor (Idalberto Revé) Blessed Music .mp3*8381633*9/9/2020 10:28:19 PM",
    "16- Alberto López - Lucete(Alberto López)13- (Prod By Dj Rodo)Blessed Music).mp3*9860163*9/9/2020 10:28:23 PM",
    "17- Aroldis Aranda - Asere Son (Aroldis Aranda Escalona)Blessed Music.mp3*8859151*9/9/2020 10:28:28 PM",
    "18- Son De Aqui - Seguir Guarachando(Enrrique Delgado)Blessed Music.mp3*13229959*9/9/2020 10:28:34 PM",
    "19- Alberto López - Soltera(Alberto López)(Prod By Dj Rodo)Blessed Music).mp3*10609355*9/9/2020 10:28:37 PM",
    "Arold Aranda - Solo Tú (Aroldis Aranda)(Prod By Dj Rodo)Blessed Music.mp3*8080702*9/9/2020 10:29:23 PM",
    "Leskay &amp; El Auténtico - Recuerdos(Prod By Dj Rodo)Blessed Music.mp3*8870645*9/9/2020 10:30:37 PM",
    104191836,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Sea Brigade Luke Sital Singh - All The Ways(2020)(POP)*0*9/13/2020 11:09:59 PM",
    "01 - Call Me When You Land.mp3*11552917*9/9/2020 10:21:28 PM",
    "02 - Summertime Low.mp3*10527872*9/9/2020 10:22:32 PM",
    "03 - Amaranth Moonlight.mp3*8947986*9/9/2020 10:23:08 PM",
    "04 - Los Feliz.mp3*6863415*9/9/2020 10:24:04 PM",
    37892190,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Shepal - Yo Soy El Malo(POP)*0*9/13/2020 10:19:50 PM",
    "01 - The Shepal - No Puedo Parar (feat. Ernesto Blanco).mp3*7313477*9/9/2020 10:21:46 PM",
    "02 - The Shepal - Adios (feat. Roberto Perdomo(Tesis de Menta)).mp3*9013468*9/9/2020 10:22:34 PM",
    "03 - The Shepal - Voy A Estar Bien.mp3*10298379*9/9/2020 10:23:23 PM",
    "03 - Voy a estar Bien Feat. Osamu Menendez.mp3*10253045*9/9/2020 10:23:25 PM",
    "04 - The Shepal - No Eres Tú.mp3*7054822*9/9/2020 10:24:12 PM",
    "05 - The Shepal - Creo en ti (feat. Diana Ruz(Tracks)).mp3*9435096*9/9/2020 10:24:56 PM",
    53368287,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\simo Ferrara - Mi Mejor Receta(2020)()*0*9/13/2020 10:42:09 PM",
    "01.Massimo Ferrara - Cuando Suena El Dembow.mp3*3319899*9/9/2020 10:22:03 PM",
    "02.Massimo Ferrara - Llorando Sola.mp3*9743097*9/9/2020 10:22:51 PM",
    "03.Massimo Ferrara - Nadie Como Yo.mp3*4223319*9/9/2020 10:23:40 PM",
    "04.Massimo Ferrara - Escuchame.mp3*3833175*9/9/2020 10:24:28 PM",
    "05.Massimo Ferrara - Hay Un Dueño.mp3*8272119*9/9/2020 10:25:06 PM",
    "06.Massimo Ferrara Ft Ikaro MC - Ella Es Candela.mp3*8728599*9/9/2020 10:25:41 PM",
    "07.Massimo Ferrara &amp; PuchoMan Feat El Medico - Waka Waka.mp3*8587440*9/9/2020 10:26:10 PM",
    "08.Massimo Ferrara Feat Yoanis Star - Ven bailalo.mp3*10179864*9/9/2020 10:26:38 PM",
    "09.Massimo Ferrara Ft. PuchoMan - Yo Soy Tu Maestro.mp3*4006607*9/9/2020 10:26:57 PM",
    "10.Massimo Ferrara - Nadie Como Yo.mp3*4279680*9/9/2020 10:27:23 PM",
    "11.Massimo Ferrara Ft Un Titico - Bum Bum Ye.mp3*3496007*9/9/2020 10:27:42 PM",
    "12.Charanga Habanera Ft Massimo Ferrara - Facciamo El Amore.mp3*5603631*9/9/2020 10:27:53 PM",
    "13.Massimo Ferrara - Mi Mejor Receta.mp3*2892316*9/9/2020 10:28:04 PM",
    77165753,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\son Diaz - Emigrante()(2020)*0*9/13/2020 11:16:25 PM",
    "01.Nelson Diaz - Rumba &amp; Fiesta.mp3*8048310*9/9/2020 10:22:04 PM",
    "02.Nelson Diaz - Chica Vitamina.mp3*6929224*9/9/2020 10:22:52 PM",
    "03.Nelson Diaz - Contrato.mp3*7395249*9/9/2020 10:23:41 PM",
    "04.Nelson Diaz - Devuelveme Mi Calma.mp3*7567657*9/9/2020 10:24:30 PM",
    "05.Nelson Diaz - El  Que.mp3*6447526*9/9/2020 10:25:08 PM",
    "06.Nelson Diaz - Amor Prohibido.mp3*6662775*9/9/2020 10:25:42 PM",
    "07.Nelson Diaz - Orgullosa.mp3*8648082*9/9/2020 10:26:11 PM",
    "08.Nelson Diaz - Emigrante.mp3*8368049*9/9/2020 10:26:39 PM",
    "09.Nelson Diaz - Infame.mp3*7088049*9/9/2020 10:26:58 PM",
    "10.Nelson Diaz - Mente Desnuda.mp3*8168473*9/9/2020 10:27:23 PM",
    "11.Nelson Diaz - Mi Cuba Tiene.mp3*7448539*9/9/2020 10:27:42 PM",
    "12.Nelson Diaz - Mi Vecina.mp3*7247918*9/9/2020 10:27:56 PM",
    "13.Nelson Diaz - Si Yo Pudiera.mp3*6212424*9/9/2020 10:28:05 PM",
    "14.Nelson Diaz - Te Amare.mp3*8807951*9/9/2020 10:28:15 PM",
    105040226,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\t level Record(2020)()*0*9/13/2020 8:25:39 PM",
    "Demente.mp3*8262502*9/9/2020 10:29:38 PM",
    "DileElSupremenftDawler&amp;ElKillerPrdByAmplifieR&amp;AnedNext.mp3*6551398*9/9/2020 10:29:38 PM",
    "EstasMalReynamsftAnedPrdByAmplifieR&amp;AnedNextLevelRecord.mp3*7833958*9/9/2020 10:29:56 PM",
    "MírameAnedCuellarNextLevelRecordsProdbyAmplifier.mp3*7246386*9/9/2020 10:30:44 PM",
    "MOJADITAFrancysfeatAmplifieRPrdbyAmplifieR&amp;AnedNextLevel.mp3*8849735*9/9/2020 10:30:46 PM",
    "SéquefuiyoFranklinXPablitoProdbyAmplifieR&amp;AnedNextLevel.mp3*7912529*9/9/2020 10:31:07 PM",
    46656508,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\te Rivero -(2020)(CUBANO)*0*9/13/2020 8:25:39 PM",
    "Mama Inez ( Final Mix ) ok.mp3*3710670*9/9/2020 10:30:41 PM",
    "Perdoname ( Final Mix ) Maite Rivero ok.mp3*3820906*9/9/2020 10:31:00 PM",
    7531576,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ter Brayzan(2020)()*0*9/13/2020 8:25:39 PM",
    "1593748800001-LeStEr BrAyZaN[A FULL]Ray  Produccion Oficial.mp3*1833024*9/9/2020 10:29:09 PM",
    "1593748800002LeStErBrAyZaNHASTAABAJODALERayProduccionOficial.mp3*1848697*9/9/2020 10:29:09 PM",
    "1593748800003-LeStEr BrAyZaN[OFICIAL]Ray  Produccion.mp3*1558843*9/9/2020 10:29:09 PM",
    "1593748800004LeStErBrAyZaNELPUMPUMRayProduccionOficial.mp3*1639300*9/9/2020 10:29:09 PM",
    "1593748800005-LeStEr BrAyZaN[NO PUEDO ESTAR  SIN TI]New CrUz.mp3*1417573*9/9/2020 10:29:10 PM",
    "1593748800006-LeStEr BrAyZaN[MAMA HEEE]Ray  Produccion.mp3*1611924*9/9/2020 10:29:11 PM",
    "1593748800007LeStErBrAyZaN&amp;BrUkCoNALOPLAYERORayProduccion.mp3*1652675*9/9/2020 10:29:11 PM",
    "1593748800008LeStErBrAyZaNfeatHawaiian,CriminalVerbalFANATICO.mp3*1946500*9/9/2020 10:29:12 PM",
    "1593748800009-LeStEr BrAyZaN[TIRALE]Ray  Produccion.mp3*1697396*9/9/2020 10:29:12 PM",
    "1593748800010-LeStEr BrAyZaN[ADICTA AL  BLOMRay Produccion.mp3*1687783*9/9/2020 10:29:12 PM",
    "1593748800011LeStErBrAyZaNfeatFlowKaroORDENCATEORayProduccion.mp3*2640103*9/9/2020 10:29:12 PM",
    19533818,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\th &amp; Myers - Not Mad Enough Rockin In The Free World(2020)(POP)*0*9/13/2020 8:25:38 PM",
    "01 - NOT MAD ENOUGH.mp3*8824877*9/9/2020 10:21:37 PM",
    "02 - ROCKIN IN THE FREE WORLD.mp3*8603359*9/9/2020 10:22:28 PM",
    17428236,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\tin Wells - The United State(2020)(POP)*0*9/13/2020 8:25:38 PM",
    "01 - Youll Never Know, Dear, How Much I Love You.mp3*1984756*9/9/2020 10:21:48 PM",
    "02 - The Screaming Song.mp3*8544626*9/9/2020 10:22:33 PM",
    "03 - No Time for a Broken Heart.mp3*8277132*9/9/2020 10:23:21 PM",
    "04 - Some Distance from It All.mp3*9997034*9/9/2020 10:24:07 PM",
    "05 - Never Better.mp3*9152756*9/9/2020 10:24:49 PM",
    "06 - After the Fall.mp3*12355368*9/9/2020 10:25:19 PM",
    "07 - Itll All Work Out.mp3*8348185*9/9/2020 10:25:53 PM",
    "08 - Temary Blue.mp3*8768234*9/9/2020 10:26:28 PM",
    "09 - Walls Fall Down.mp3*10021066*9/9/2020 10:26:51 PM",
    "10 - Ruby.mp3*8448496*9/9/2020 10:27:13 PM",
    "11 - The Bridge.mp3*8137116*9/9/2020 10:27:35 PM",
    "12 - Farewell, Mr. Hooper.mp3*872986*9/9/2020 10:27:46 PM",
    94907755,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\to - Journeys End(2020)(POP)*0*9/13/2020 8:25:37 PM",
    "01 - Hound.mp3*9940608*9/9/2020 10:21:30 PM",
    "02 - Join the Queue.mp3*8951090*9/9/2020 10:22:22 PM",
    "03 - Big Black Creeper.mp3*5490388*9/9/2020 10:23:09 PM",
    "04 - Work out a Plan.mp3*12453588*9/9/2020 10:24:13 PM",
    "05 - Man Overboard.mp3*8983482*9/9/2020 10:24:45 PM",
    "06 - Better Run.mp3*7437033*9/9/2020 10:25:19 PM",
    "07 - Natalie.mp3*9163204*9/9/2020 10:25:55 PM",
    "08 - Hope.mp3*11182992*9/9/2020 10:26:22 PM",
    "09 - Sunday Best.mp3*6876968*9/9/2020 10:26:49 PM",
    "10 - Done My Crying Now.mp3*7092218*9/9/2020 10:27:08 PM",
    "11 - Just Like a Train.mp3*10757720*9/9/2020 10:27:33 PM",
    "12 - Westward Blues #2.mp3*11530944*9/9/2020 10:27:49 PM",
    "13 - Can You Heard Me Calling (Bonus Track).mp3*9127679*9/9/2020 10:27:58 PM",
    "14 - Blue Dog Blues (Bonus Track).mp3*12991711*9/9/2020 10:28:10 PM",
    "15 - Down and out (Live) (Bonus Track).mp3*8074422*9/9/2020 10:28:17 PM",
    "16 - Gotta Lotta Nerve (Bonus Track).mp3*10040920*9/9/2020 10:28:22 PM",
    "17 - Riverboat Man (Bonus Track).mp3*8341915*9/9/2020 10:28:27 PM",
    "18 - Im Leaving (Bonus Track).mp3*12767058*9/9/2020 10:28:32 PM",
    "19 - Hound (Live) (Bonus Track).mp3*10366928*9/9/2020 10:28:36 PM",
    181570868,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Toxic Avenger - Midnight Resistance(2020)(POP)*0*9/13/2020 10:45:26 PM",
    "01 - Americana.mp3*9840446*9/9/2020 10:21:27 PM",
    "02 - Lies.mp3*10650242*9/9/2020 10:22:22 PM",
    "03 - Midnight Resistance.mp3*9189475*9/9/2020 10:23:21 PM",
    "04 - I Need You.mp3*12147581*9/9/2020 10:24:02 PM",
    "05 - Rent Boy.mp3*10370209*9/9/2020 10:24:51 PM",
    "06 - ЧЕРНОЕ ЛЕТО.mp3*12837214*9/9/2020 10:25:33 PM",
    "07 - Lavenir davant.mp3*7930373*9/9/2020 10:25:54 PM",
    "08 - Mandala.mp3*11593785*9/9/2020 10:26:26 PM",
    "09 - On Sight.mp3*7936642*9/9/2020 10:26:46 PM",
    "10 - Falling Apart.mp3*8523876*9/9/2020 10:27:09 PM",
    "11 - Long Hair, Black Leather Jacket!.mp3*11335696*9/9/2020 10:27:34 PM",
    "12 - On My Own.mp3*9737002*9/9/2020 10:27:47 PM",
    "13 - Les heures.mp3*13144415*9/9/2020 10:28:00 PM",
    135236956,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\tr Will - Real Life()(2020)()*0*9/13/2020 11:15:02 PM",
    "!00-PA ESTAR CONMIGO...MISTR WILL D.CUBA.mp3*9216637*9/9/2020 10:20:44 PM",
    "!01-TE BOTE (REMIX)...MISTR WILL D.CUBA x YOLDIA.mp3*10686860*9/9/2020 10:20:47 PM",
    "!02-DESCONTROL...MISTR WILL D.CUBA.mp3*8870912*9/9/2020 10:20:52 PM",
    "!03- QUE TE VAS DE MI...MISTR WILL D.CUBA x EL YOSVA.mp3*5387799*9/9/2020 10:20:53 PM",
    "!04-TRANCA...MISTR WILL D.CUBA.mp3*9915375*9/9/2020 10:20:54 PM",
    "!05-LO MENEAN...MISTR WILL D.CUBA.mp3*8316724*9/9/2020 10:20:56 PM",
    "!06-Q LOKURA...MISTR WILL D.CUBA x AKUNA AND YOLDIA.mp3*9754941*9/9/2020 10:20:57 PM",
    "!07-ME TIENES LOCO...MISTR WILL D.CUBA.mp3*7981373*9/9/2020 10:20:58 PM",
    "!08-NUNCA ANDA SOLA...MISTR WILL D.CUBA x YOLDIA.mp3*10871679*9/9/2020 10:21:00 PM",
    "!10-100 PA BAJO...MISTR WILL D.CUBA.mp3*7986531*9/9/2020 10:21:03 PM",
    "!11-AMIGA X AMIGA...MISTR WILL D.CUBA x TU ALBE LA ROYA.mp3*10479380*9/9/2020 10:21:04 PM",
    "!12-PROMESAS DE AMOR...MISTR WILL D.CUBA.mp3*9288294*9/9/2020 10:21:06 PM",
    "!13-FUCKING PANDA...MISTR WILL (D.CUBA).mp3*9686694*9/9/2020 10:21:08 PM",
    "!14-OH MI GOD...MISTR WILL D.CUBA x CUBAN OFFICIAL.mp3*8470359*9/9/2020 10:21:11 PM",
    "!15-CANALLA...MISTR WILL D.CUBA.mp3*7641207*9/9/2020 10:21:12 PM",
    "!16-SALIR PA LA CALLE...MISTR WILL D.CUBA x LODIN.mp3*8838649*9/9/2020 10:21:13 PM",
    "!17-DE TI ME ENAMORE...MISTR WILL D.CUBA.mp3*10666297*9/9/2020 10:21:16 PM",
    "!18-PATABOOM...MISTR WILL D.CUBA.mp3*10929921*9/9/2020 10:21:20 PM",
    164989632,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\tr Will - Real Life(2020)()*0*9/13/2020 11:20:54 PM",
    "00.Mistr Will D.Cuba - Pa Estar Conmigo.mp3*9211719*9/9/2020 10:21:23 PM",
    "01.Mistr Will D.Cuba Ft Yoldia - Te Bote(Remix).mp3*10686860*9/9/2020 10:22:03 PM",
    "02.Mistr Will D.Cuba - Descontrol.mp3*8866155*9/9/2020 10:22:52 PM",
    "03.Mistr Will D.Cuba Ft El Yosva - que Te Vas De Mi.mp3*5387799*9/9/2020 10:23:40 PM",
    "04.Mistr Will D.Cuba - Tranca.mp3*9915375*9/9/2020 10:24:29 PM",
    "05.Mistr Will D.Cuba - Lo Menean.mp3*4192716*9/9/2020 10:25:07 PM",
    "06.Mistr Will D.Cuba Ft Acuna And Yoldia Ell Liberal - Que Locura.mp3*9754626*9/9/2020 10:25:42 PM",
    "07.Mistr Will D.Cuba - Me Tienes Loco.mp3*4538310*9/9/2020 10:26:10 PM",
    "08.Mistr Will D.Cuba Ft Yoldia El Liberal - Nunca Andas Sola.mp3*10871679*9/9/2020 10:26:39 PM",
    "09.Mistr Will D.Cuba Ft Cuban Official - Oh My God.mp3*8470359*9/9/2020 10:26:57 PM",
    81895598,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\Tuly De Los Sitios - Demo(2020)()*0*9/13/2020 10:39:52 PM",
    "El Tuly De Los Sitios - Perdóname.mp3*6215394*9/9/2020 10:29:51 PM",
    "El Tuly De Los Sitios - Sigue Tu Camino.mp3*7178199*9/9/2020 10:29:52 PM",
    "El Tuly De Los Sitios - Yo Te Amo.mp3*4222443*9/9/2020 10:29:52 PM",
    "El Tuly De Los Sitios Ft El Pirata - La P..A.mp3*7525659*9/9/2020 10:29:53 PM",
    "El Tuly De Los Sitios Ft El Yabo Y Adonis - A Descochar.mp3*7285539*9/9/2020 10:29:53 PM",
    "El Tuly De Los Sitios Y El Favy - Eres Tu.mp3*3202455*9/9/2020 10:29:54 PM",
    "El Tuly De Los Sitios Y El Favy - No Te Supe Cuidar.mp3*7005747*9/9/2020 10:29:54 PM",
    "El Tuly Dw Los Sitios Y El Favy - El Amor De Mi Vida.mp3*7136247*9/9/2020 10:29:55 PM",
    "ElTulyDeLosSitiosYElFavyFtReinielitoNaQueVerContigo.mp3*3958311*9/9/2020 10:29:55 PM",
    53729994,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\uar Raudel - Corone(2020)()*0*9/13/2020 10:42:36 PM",
    "01.Leduar Raudel - Corone.mp3*8034726*9/9/2020 10:22:01 PM",
    "02.Leduar Raudel - Ya No Hablamos.mp3*3894165*9/9/2020 10:22:49 PM",
    "03.Leduar Raudel Ft Alto Riesgo - Enamorate De Mi.mp3*3368372*9/9/2020 10:23:39 PM",
    "04.Leduar Raudel Ft Chesco - Dicelo.mp3*4398223*9/9/2020 10:24:27 PM",
    "05.Leduar Raudel - El Negrito.mp3*4452976*9/9/2020 10:25:05 PM",
    "06.Leduar Raudel - Sin Tu Amor.mp3*8278398*9/9/2020 10:25:40 PM",
    "07.Leduar Raudel - Tu Bandolero.mp3*4532388*9/9/2020 10:26:09 PM",
    "08.Leduar Raudel Ft El Kamel - Una Locura.mp3*9194563*9/9/2020 10:26:37 PM",
    "09.Leduar Raudel X El Carissimo Jinete - Arriba De Los Dollares.mp3*9740159*9/9/2020 10:26:57 PM",
    "10.Leduar Raudel x Brayan Omeg x Menor Gabila x Osmel - Buscando Amor.mp3*8896926*9/9/2020 10:27:22 PM",
    "11.Leduar Raudel - El Tiempo Lo Cambia Tò.mp3*8933498*9/9/2020 10:27:41 PM",
    "12.Leduar Raudel - Estudié Con Pinocho.mp3*11566482*9/9/2020 10:27:54 PM",
    "13.Leduar Raudel X Dj Unic - Tu Estás Dura.mp3*8393126*9/9/2020 10:28:03 PM",
    "14.Leduar Raudel X Dkano X Efowa -  La Ventana.mp3*7902183*9/9/2020 10:28:13 PM",
    101586185,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ujo(Big Gaby &amp; El Filio) - Errores(2020)()*0*9/13/2020 10:30:44 PM",
    "01.D´Lujo(Big Gaby &amp; El Filio) Ft Waldo Mendoza - Me Prometi.mp3*8465259*9/9/2020 10:21:55 PM",
    "02.D´Lujo(Big Gaby &amp; El Filio) - Errores(53972754-76980111).mp3*8883184*9/9/2020 10:22:42 PM",
    17348443,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ula Music - Estrenos(2020)()*0*9/13/2020 11:21:50 PM",
    "Amilkele  - Condéname.mp3*2668727*9/9/2020 10:29:16 PM",
    "Benggie Ft CNK IL Y Kpitano - Un Pare.mp3*4711921*9/9/2020 10:29:24 PM",
    "Chacal Ft Enzo - El Mentiroso.mp3*5323186*9/9/2020 10:29:27 PM",
    "Chacal Ft Harrison - La Duraka.mp3*9184076*9/9/2020 10:29:28 PM",
    "Chacal Ft Kimiko Y Yordy - Un segundo.mp3*5167705*9/9/2020 10:29:28 PM",
    "Chacal Ft Yet Garbey - La Culipandea.mp3*3854896*9/9/2020 10:29:28 PM",
    "Denver - Diferente.mp3*2607287*9/9/2020 10:29:38 PM",
    "Duran - De Ti Me Enamore.mp3*3683532*9/9/2020 10:29:39 PM",
    "El Chulo Ft El Kamel,El Rocko, Ale el más buscado,Anubix,Lobo Kin Dowa Y Harrisom -  Mango.mp3*4897704*9/9/2020 10:29:45 PM",
    "El Chulo Ft Wampi Y El Iroso - El Canilleo.mp3*9262557*9/9/2020 10:29:46 PM",
    "El Kamel - Dale.mp3.mp3*2822098*9/9/2020 10:29:46 PM",
    "El Kamel - Hablabas.mp3*3168607*9/9/2020 10:29:46 PM",
    "Elain Morales Ft Henry - Ya no Cree.mp3*3233390*9/9/2020 10:29:55 PM",
    "Gretchen Gray - Dímelo.mp3*2679594*9/9/2020 10:30:04 PM",
    "Heikel Ft TiN Mojaito - Tu Eres Un Komico.mp3*4602834*9/9/2020 10:30:13 PM",
    "Henry - Hasta Atras.mp3*1070897*9/9/2020 10:30:13 PM",
    "Kimiko &amp; Yordi - Borracho.mp3*3252322*9/9/2020 10:30:28 PM",
    "Kimiko Y Yordy Ft Henry - Tu No Sientes Na.mp3*5521053*9/9/2020 10:30:29 PM",
    "Lenier - Se acabó El Amor.mp3*8226950*9/9/2020 10:30:34 PM",
    "Louis Mikan - Dime Que No.mp3*3220851*9/9/2020 10:30:39 PM",
    "Manu Manu - Yeah.mp3*8275677*9/9/2020 10:30:41 PM",
    "Yomil  Y El Dany - Que Pena.mp3*3538500*9/9/2020 10:31:28 PM",
    "Yomil Y Dany Ft Kimiko &amp; Yordy - Pasado.mp3*8694019*9/9/2020 10:31:29 PM",
    "Yomil Y EL Dany Ft Chacal Y El Micha - Mi Pikete.mp3*10222705*9/9/2020 10:31:29 PM",
    "Yulien Oviedo - El Kiki.mp3*2860356*9/9/2020 10:31:30 PM",
    122751444,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\val x Misa(2020)()*0*9/13/2020 11:14:35 PM",
    "!!! Helinton el Chaval - El Mundele - .mp3*7710808*9/9/2020 10:20:42 PM",
    "!!!Talento!!!El Misa!!!Pro By El Ruso!!.mp3*4462891*9/9/2020 10:20:42 PM",
    "#DE TOEL NEGRITOLOS 0 ONEkartel music...mp3*7940127*9/9/2020 10:21:21 PM",
    "A CINTURIARRRR EL MISA X EL CHAVAL PROD BY EL RUSO.mp3*3871659*9/9/2020 10:29:15 PM",
    "INTRO.mp3*2493420*9/9/2020 10:30:22 PM",
    "LA PATICA EN EL AIRE.mp3*2963222*9/9/2020 10:30:32 PM",
    "SEXO Y ALCOHOL.mp3*2822787*9/9/2020 10:31:07 PM",
    32264914,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\ver ft H Oliva - (2020)()*0*9/13/2020 10:13:45 PM",
    "H Oliva - Amiga Mia.mp3*9837102*9/9/2020 10:30:05 PM",
    "H Oliva - Apagalo (prod DACHAY).mp3*9447690*9/9/2020 10:30:07 PM",
    "H Oliva - Hoy me la encontre (prod DACHAY).mp3*8001750*9/9/2020 10:30:08 PM",
    "H Oliva - Mala Mia (prod Dachay).mp3*6510918*9/9/2020 10:30:08 PM",
    "H Oliva ft Denver Perreo.mp3*7297050*9/9/2020 10:30:09 PM",
    41094510,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\y Cuore - En Las Nubes(2020)()*0*9/13/2020 10:14:21 PM",
    ".SAL DE ARRIBA DE MICOSA MIA X MINY CUORE X MAX PRO MUSIC.mp3*49495*9/9/2020 10:21:23 PM",
    "10-ANDANDILE-LA MINY CUORE(MAS PRO MUSIC BY PIVE).mp3*6679494*9/9/2020 10:27:25 PM",
    "11-A MI MANERA -LA MINY CUORE X  DUKE X EL YEYO(MAS PRO MUSIC BY PIVE).mp3*6706661*9/9/2020 10:27:45 PM",
    "1-DESCONTROL-LA MINY CUORE FT GNIO &amp; DAIRONY(MAS PRO MUSIC BY PIVE).mp3*8530008*9/9/2020 10:22:14 PM",
    "2-A LA CARA-LA MINY CUORE-{MAS PRO MUSIC PIVE PRODUCE).mp3*8204000*9/9/2020 10:23:03 PM",
    "4-MUJER DECIDIDA-LA MINY CUORE(MAS PRO MUSIC BY PIVE).mp3*6658596*9/9/2020 10:24:35 PM",
    "5-MUEVELO -LA MINY CUORE  X EL LOBO MALO(MAS PRO MUSIC BY PIVE).mp3*6745322*9/9/2020 10:25:16 PM",
    "6-EN LAS NUBES-LA MINY CUORE(MAS PRO MUSIC BY PIVE).mp3*8112049*9/9/2020 10:25:49 PM",
    "7GANAS DE TI -LA MINY CUORE X ANUBIX X JAIRO (MAS PRO MUSIC BY PIVE).mp3*8564490*9/9/2020 10:26:18 PM",
    "8-MODO DE AVION -LA MINY CUORE (MAS PRO MUSIC BY PIVE).mp3*6709796*9/9/2020 10:26:42 PM",
    "9-OYE ESO -LA MINY CUORE X KAPOLAN(MAS PRO MUSIC BY PIVE).mp3*7918743*9/9/2020 10:27:05 PM",
    "EL PADRE-MINY CUORE-MAX PRO MUSIC BY PIVE.mp3*8441247*9/9/2020 10:29:50 PM",
    "NO TE  QUIERE MINY CUORE FEAT HARRYSON  WAV.mp3*7888441*9/9/2020 10:30:50 PM",
    "PA Q TE ARDA X MINY CUORE X WILDEY(MAS PRO MUSIC BY PIVE).mp3*7247918*9/9/2020 10:30:56 PM",
    "SAL DE ARRIBA DE MICOSA MIA X MINY CUORE X MAX PRO MUSIC.mp3*8339799*9/9/2020 10:31:05 PM",
    "SEGUROSKY-MINY CUORE-MAX PRO MUSICBY PIVE.mp3*6422718*9/9/2020 10:31:06 PM",
    "WOW-MINY CUORE-MAX PRO MUSIC BY PIVE.mp3*8978269*9/9/2020 10:31:26 PM",
    122197046,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\y Laura - Tutti Fruti(2020)()*0*9/13/2020 10:36:51 PM",
    "01.Lady Laura Ft El Taiger - Amnesia.mp3*8301137*9/9/2020 10:22:00 PM",
    "02.Lady Laura - Que Te Preocupa.mp3*9349169*9/9/2020 10:22:48 PM",
    "03.Lady Laura Ft Chacal - Loca.mp3*9168279*9/9/2020 10:23:38 PM",
    "04.Lady Laura Ft Micha - Mienteme.mp3*8094039*9/9/2020 10:24:26 PM",
    "05.Lady Laura Ft Srta Dayana - No Te Emociones.mp3*3289787*9/9/2020 10:25:04 PM",
    "06.Lady Laura Ft El Taiger - Me Gusto.mp3*4202638*9/9/2020 10:25:40 PM",
    "07.Lady Laura Ft Divan - Pensando En Mi.mp3*7620678*9/9/2020 10:26:08 PM",
    "08.Lady Laura Ft Franny El Fenomeno - Animal.mp3*8536599*9/9/2020 10:26:37 PM",
    "09.Lady Laura - No Me Controlas.mp3*2539067*9/9/2020 10:26:56 PM",
    "10.Lady Laura Ft Dayron Sexappeal - Tutti Fruti.mp3*752937*9/9/2020 10:27:22 PM",
    "11.Lady Laura - Bandolera.mp3*7860190*9/9/2020 10:27:41 PM",
    69714520,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\y Pulido - Mi Locura(2020)(SALSA)*0*9/13/2020 10:36:55 PM",
    "01.Roby Pulido - Cama y Mesa (Versión Salsa).mp3*6872766*9/9/2020 10:22:06 PM",
    "02.Roby Pulido - Que Locura.mp3*5948692*9/9/2020 10:22:54 PM",
    "03.Roby Pulido - Quererte A Mi Manera.mp3*5566259*9/9/2020 10:23:41 PM",
    "04.Roby Pulido - Duele.mp3*5173693*9/9/2020 10:24:30 PM",
    "05.Roby Pulido - Tengo El Control.mp3*5258238*9/9/2020 10:25:09 PM",
    "06.Roby Pulido - Mis Raices.mp3*6558704*9/9/2020 10:25:43 PM",
    "07.Roby Pulido Ft Haila - El Amor Es Asi.mp3*6311063*9/9/2020 10:26:12 PM",
    41689415,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\y Rodriguez - Kimika(2020)()*0*9/13/2020 10:14:58 PM",
    "01.Ruly Rodriguez Ft BESFORD - Rico Rico.mp3*2888813*9/9/2020 10:22:06 PM",
    "02.Ruly Rodriguez Ft Kiki Aguero - Esta Noche.mp3*3333939*9/9/2020 10:22:54 PM",
    "03.Ruly Rodriguez - Kimika RMX.mp3*3056594*9/9/2020 10:23:41 PM",
    "04.Ruly Rodriguez Ft Ydalia Suarez - Killer.mp3*3058922*9/9/2020 10:24:30 PM",
    12338268,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Internacional]\\y x Onlyne(2020)()*0*9/13/2020 10:14:59 PM",
    "1-BLOKEA-TONY X ONLYONE-PLAY BACK.mp3*8050791*9/9/2020 10:22:12 PM",
    "2-TONY X ONLYONE -NAVEGA (MPLAY BACK).mp3*8323479*9/9/2020 10:23:06 PM",
    "3-Tony x Only one ft Nesty Obligao...( Una Relacion).mp3*4922437*9/9/2020 10:23:51 PM",
    "4-TE SOLTAMOS-TONY X ONLYONE-PLAY BACK.mp3*6907611*9/9/2020 10:24:35 PM",
    "PLAY BACK - TONY &amp; ONLYONE FT KOKI ELKANO - EL BUENO - DJ ROWA.mp3*10512279*9/9/2020 10:31:00 PM",
    38716597,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]*0*9/13/2020 10:15:48 PM",
    0,
    "742*743*744*745*746*747*748*749*750*751*752*753*754*755*756*757*758*759*760*761*762*763*764*765*766*767*768*769*770*771*772*773*774*775*776*777*778*779*780*781*782*785*786*787*788*789*790*791*792*793*794*795*796*797*798*799*800*801*802*803*804*805*806*807*808*809*810*811*812*813*814*815*816*817*818*819*820*821*822*823*824*825*826*827*828*829*830*831*832*833*834*835*836*837*838*839*840*841*842*843*844*845*846*847*848*849*850*851*852*853*854*855*856*857*858*859*860*861*862*863*864*865*866*867*868*869*870*871*872*873*874*875*876*877*878*879*880*881*882*883*884*885*886*887*888*889*890*891*892*893*894*895*896*897*898*899*900*901*902*903*904*905*906*907*908*909*910*911*912*913*914*915*916*917*918*919*920*921*922*923*924*925*926*927*929*930*931*932*933*934*935*936*937*938*939*940*941*942*943*944*951*952*953*954*955*956*957*958*959*960*961*962*963*964*965*966*967*968*969*970*971*972*973*974*975*976*977*978*979*980*981*982*983*984*985*986*987*988*989*990*991*992*993*994*995*996*997"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-            Estreno Exclusivo - Chacal Ft Tempo - Me Quiero Perder*0*9/13/2020 9:54:03 PM",
    "Chacal Ft Tempo - Me Quiero Perder.mp3*3766906*9/9/2020 11:33:00 PM",
    3766906,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Canción Oficial del Verano 2020 en Cuba - Sin Miedo a Naufragar*0*9/13/2020 9:54:07 PM",
    "Christopher Simpson &amp; Elevación feat. Artistas Varios - Sin Miedo a Naufragar (Desde Casa Remix).mp3*14434010*9/9/2020 11:33:04 PM",
    14434010,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Celula Music (2020)*0*9/13/2020 9:54:10 PM",
    "Henry - Hasta Atras.mp3*1070897*9/9/2020 11:33:47 PM",
    1070897,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Dj Unic - Wow Popy El Yito - El Maldito*0*9/13/2020 10:33:53 PM",
    "Dj Unic - Wow Popy El Yito - El Maldito ( Video Oficial )(720PHD).mp3*6041440*9/9/2020 11:33:14 PM",
    6041440,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - EL BIRBO MICKY - AMORES PARA PRE*0*9/13/2020 9:54:19 PM",
    "EL BIRBO MICKY - AMORES PARA PRE - (SALSA VERSION) SALSA 2020.mp3*2814796*9/9/2020 11:33:17 PM",
    2814796,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - El UNIKO*0*9/13/2020 10:33:57 PM",
    "El UNIKO - LA QUE ME VIO NACER.mp3*1777420*9/9/2020 11:33:33 PM",
    "El Uniko - Pase La Pagina~1.mp3*4530518*9/9/2020 11:33:33 PM",
    "No Mas Mentiras - El Uniko.mp3*5376258*9/9/2020 11:34:27 PM",
    11684196,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - El Uniko - Quisiera*0*9/13/2020 9:54:35 PM",
    "El Uniko - Quisiera.mp3*2734965*9/9/2020 11:33:33 PM",
    2734965,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - El Uniko ft July Roby*0*9/13/2020 9:54:40 PM",
    "Lo Aprendi de ti - El Uniko ft July Roby.mp3*3398684*9/9/2020 11:34:09 PM",
    3398684,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - El Uniko Ft Un Titico - Esa Es La Actitud*0*9/13/2020 9:54:45 PM",
    "El Uniko Ft Un Titico - Esa Es La Actitud.mp3*2761714*9/9/2020 11:33:33 PM",
    2761714,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - El Uniko ft Yabo*0*9/13/2020 9:54:51 PM",
    "El Uniko ft The Yabo - Rumores !!!.mp3*2310736*9/9/2020 11:33:33 PM",
    2310736,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - El Uniko x Chocolate x El Chulo - El Santo (REMIX)*0*9/13/2020 10:34:05 PM",
    "El Uniko x Chocolate x El Chulo - El Santo (REMIX).mp3*3787804*9/9/2020 11:33:33 PM",
    3787804,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - El Uniko x D Jota Andy - Bipolaridad*0*9/13/2020 11:33:31 PM",
    "El Uniko x D Jota Andy - Bipolaridad.mp3*3414149*9/9/2020 11:33:33 PM",
    3414149,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - EL VALLO [DEMO 2020]*0*9/13/2020 11:33:31 PM",
    "15156629141071593617.mp3*7684647*9/9/2020 11:32:42 PM",
    "15156629141071593618.mp3*7777643*9/9/2020 11:32:43 PM",
    "15156629141071593619.mp3*9258263*9/9/2020 11:32:43 PM",
    "15156629141071593620.mp3*8230083*9/9/2020 11:32:43 PM",
    "15156629141071593621.mp3*8531014*9/9/2020 11:32:43 PM",
    "15156629141071593622.mp3*8022149*9/9/2020 11:32:44 PM",
    "15156629141071593624.mp3*8955243*9/9/2020 11:32:44 PM",
    "15156629141071593625.mp3*9488141*9/9/2020 11:32:44 PM",
    67947183,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - El Vallo CD Frape*0*9/13/2020 11:24:56 PM",
    "01- INTRO FRAPE   EL VALLO   B.Y.LA BESTIA.mp3*2510283*9/9/2020 11:29:10 PM",
    "02- FUEGO CON TU JEVA VALLO &amp; KEKENKE BY LA BESTIA .....mp3*8354595*9/9/2020 11:29:31 PM",
    "03- OJO CON ESO   EL VALLO KEKENKE  B.Y.LA BESTIA.mp3*7815891*9/9/2020 11:29:53 PM",
    "04- UN TOPE-LANDY EL 13 X GNIO Y DAYRONI X LA MINI CUORE X EL TROYANO X EL VALLO X WAMPI X KIMIKO Y YORDIS X POPI.mp3*11770563*9/9/2020 11:30:11 PM",
    "05-  LA CHINGONA-EL VALLO.mp3*8713731*9/9/2020 11:30:27 PM",
    "06- PUESTO  EL VALLO  B.Y.LA BESTIA.mp3*8547735*9/9/2020 11:30:45 PM",
    "07- AZUKITA(VALLO)GALAXY MUSIK.mp3*4092987*9/9/2020 11:30:58 PM",
    "08- El Vallo x Kekenke - Haste de Tus Cositas (Gomeko).mp3*7691961*9/9/2020 11:31:11 PM",
    "09- Inflaita+ELValloOkOk(ByKingSIzeStudio)+LaRyal.mp3*8999787*9/9/2020 11:31:24 PM",
    "10- la puntika VALLO.mp3*8500755*9/9/2020 11:31:35 PM",
    "11- Normal Normalita Vallo Yenke Wow Popy.mp3*7931243*9/9/2020 11:31:45 PM",
    "12- OBLIGAO.EL VALLO OKOK.taly musik.dj.Pive.mp3*8094639*9/9/2020 11:31:52 PM",
    "13- PONCHAO (VALLO)DjPive.mp3*8349375*9/9/2020 11:31:58 PM",
    "14- Raidel Feat El Vallo - Si Si Como No ().mp3*8147799*9/9/2020 11:32:02 PM",
    109521344,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - En Las Nubes - Miny Cuore*0*9/13/2020 9:56:22 PM",
    ".SAL DE ARRIBA DE MICOSA MIA X MINY CUORE X MAX PRO MUSIC.mp3*49495*9/9/2020 11:29:07 PM",
    49495,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Estudio Blessed - Producciones*0*9/13/2020 9:56:31 PM",
    "033- Yousef - Diferente(Prod By Dj Rodo)Blessed Music.wav*50280190*7/1/2020 5:23:34 PM",
    "Eduar El Real - Todo Para Ti(Prod By Dj Rodo)Blessed Music.wav*49264166*6/16/2020 1:47:05 AM",
    "Mr Leo - Calla Esa Boquita(Prod By Dj Rodo)Blessed Music.wav*49097484*7/1/2020 4:43:28 PM",
    "Yari SR - Cuentale(Prod By Dj Rodo)Blessed Music.wav*46596520*7/1/2020 5:04:11 PM",
    195238360,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Franny El Fenomeno - Alma Rota*0*9/13/2020 9:56:33 PM",
    "Franny - Alma Rota (14 DE FEBRERO) ==lAFORMULA==.mp3*6849147*9/9/2020 11:33:38 PM",
    6849147,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Franny El Fenomeno x El Kapoland - Maltrato*0*9/13/2020 9:56:41 PM",
    "Franny El Fenomeno  El Kapoland - Maltrato (Audio Oficial)(480P)1.mp3*3218544*9/9/2020 11:33:38 PM",
    3218544,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Jacob Forever X Uniko X Micha- No Mas Mentiras [Remix]*0*9/13/2020 9:56:46 PM",
    "Jacob Forever X Uniko X Micha- No Mas Mentiras [Remix] [VIDEO OFICIAL].mp3*4067001*9/9/2020 11:33:53 PM",
    4067001,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Jayby - WUHAN*0*9/13/2020 9:56:49 PM",
    "Jayby - WUHAN.mp3*5282295*9/9/2020 11:33:53 PM",
    5282295,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Jhon Menez - A Lo Anuel y Karol ft. Vane*0*9/13/2020 9:56:54 PM",
    "Jhon Menez - A Lo Anuel y Karol (Official Lyric Video) ft. Vane D(720PHD)1.mp3*5087239*9/9/2020 11:33:56 PM",
    5087239,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Jhon Menez Ft Vane D - A Lo Anuel &amp; Karol*0*9/13/2020 8:45:53 PM",
    "Jhon Menez Ft Vane D - A Lo Anuel &amp; Karol.mp3*4973136*9/9/2020 11:33:57 PM",
    4973136,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Jose Yamil(El Chavo) - Muñequita*0*9/13/2020 8:45:46 PM",
    "Jose Yamil(El Chavo) - Muñequita.mp3*3399771*9/9/2020 11:33:59 PM",
    3399771,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Kimiko y Yordy Ft Jhon Menez - La Caperucita  (2020)*0*9/13/2020 8:45:43 PM",
    "+Jhon Menez Feet Kimiko Y Yordy La Caperusita Roja.mp3*8342933*9/9/2020 11:29:07 PM",
    8342933,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - La Figura Soy Yo - Los Gobernantes*0*9/13/2020 8:45:41 PM",
    "FIGURA SOY YOLOS GOBERNANTES...PULPO.MANIA.mp3*4282473*9/9/2020 11:33:38 PM",
    4282473,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - LA RECARGA-JHON MENEZ*0*9/13/2020 8:45:40 PM",
    "LA RECARGA-JHON MENEZ.mp3*4618916*9/9/2020 11:34:06 PM",
    4618916,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Lenier Waño -Single Demo(2020)(FUNK)*0*9/13/2020 8:45:36 PM",
    "�Lenier Waño- Bailalo ahora- by Youngdaddy &amp; Dj Sonny.mp3*8583231*9/9/2020 11:34:07 PM",
    8583231,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Popi ft Los Gobernantes PaketeKILO 2*0*9/13/2020 8:45:35 PM",
    "Popi ft Los Gobernantes PaketeKILO 2 .mp3*7943259*9/9/2020 11:34:42 PM",
    7943259,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Ruly Rodriguez Ft Ydalia Suarez - Killer*0*9/13/2020 8:45:24 PM",
    "Ruly Rodriguez Ft Ydalia Suarez - Killer.mp3*3058922*9/9/2020 11:34:48 PM",
    3058922,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - WAMPI &amp; READY SALVI - ENGANCHÁ*0*9/13/2020 8:45:22 PM",
    "! ! ! WAMPI &amp; READY SALVI - ENGANCHÁ  BY EL PERSO.mp3*7015143*9/9/2020 11:29:01 PM",
    7015143,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Wampi El Taiger - La Pieza Remix*0*9/13/2020 8:45:07 PM",
    "Dj Unic Wampi El Taiger - La Pieza Remix (Video Oficial)(720PHD)1.mp3*4488513*9/9/2020 11:33:14 PM",
    4488513,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Wampi Ft Alex Duvall - La Verdad*0*9/13/2020 8:45:18 PM",
    "Wampi Ft Alex Duvall - La Verdad (Planet Records &amp; La Oficina Secreta).mp3*4397198*9/9/2020 11:35:07 PM",
    4397198,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - WAMPI FT GNIO &amp; DAYRONI*0*9/13/2020 8:45:13 PM",
    "GNIO&amp;DAYRONIFTWAMPIIYAWO OGUNKARMA 7maMUSIC.mp3.mp3*9819374*9/9/2020 11:33:41 PM",
    9819374,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - WAMPI -fUEGO CON TU PIQUETE*0*9/13/2020 8:45:22 PM",
    "! ! ! WAMPI - FUEGO CON TU PIKETE  BY EL PERSO.mp3*8040351*9/9/2020 11:29:01 PM",
    8040351,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - WAMPI X EL IROSO X KIMIKO -CANILLEO CELULA MUSIC*0*9/13/2020 8:45:13 PM",
    "Canilleo-Wampi X  Kimiko X Iroso(DJUNIC  DJROIT).mp3*7104855*9/9/2020 11:32:57 PM",
    7104855,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\-       Estreno Especial - Wow Popy feat. Moikan y El Banki*0*9/13/2020 8:45:07 PM",
    "Wow Popy feat. Moikan y El Banki.mp3*7667643*9/9/2020 11:35:09 PM",
    7667643,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\1 - Temas De Estreno 533(Exclusivos)*0*9/13/2020 8:45:02 PM",
    "Chacal Ft Tempo - Me Quiero Perder.mp3*3766906*9/9/2020 11:33:00 PM",
    "El Carli - Háblame De Tengo Tengo.mp3*2741452*9/9/2020 11:33:19 PM",
    "El Lucky Ta Loco - Salud.mp3*9667813*9/9/2020 11:33:23 PM",
    "El Negrito X Yosly - Mas De Ti.mp3*38165193*9/9/2020 11:33:25 PM",
    "El Taiger - Wait A Minute.mp3*2606451*9/9/2020 11:33:30 PM",
    "El Uniko - Quisiera.mp3*2736640*9/9/2020 11:33:33 PM",
    "Jayby - WUHAN.mp3*5282507*9/9/2020 11:33:54 PM",
    "Jhon Menez Ft Charlie Maykol - Me Kemas.mp3*7712157*9/9/2020 11:33:57 PM",
    "Jhon Menez Ft El Furia x Mr Haison - Vuelve.mp3*8649117*9/9/2020 11:33:57 PM",
    "Yosly - Escapate.mp3*52106697*9/9/2020 11:35:22 PM",
    "Yosly - Hola Que Tal.mp3*2973822*9/9/2020 11:35:22 PM",
    "Yosly - Te Mentiste.mp3*7508021*9/9/2020 11:35:22 PM",
    143916776,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\111 indetenible x J violent(2020)()*0*9/13/2020 8:44:37 PM",
    "Indetenible ft J.Violent - Ganas de Ti - .mp3*7817388*9/9/2020 11:33:48 PM",
    7817388,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\2 - Temas Nacionales(Exclusivos)*0*9/13/2020 9:32:18 PM",
    " Yero(El Kila) - A La Carandanga.mp3*6791717*9/9/2020 11:28:59 PM",
    " Yero(El Kila) - Pa Mi Gente.mp3*8451617*9/9/2020 11:28:59 PM",
    "1era Imagen - Bailame.mp3*5194446*9/9/2020 11:29:27 PM",
    "1era Imagen - El Secreto Del Amor.mp3*6024827*9/9/2020 11:29:27 PM",
    "1era Imagen - Gucci Gucci.mp3*8504931*9/9/2020 11:29:28 PM",
    "1era Imagen &amp; El Chily - Oscuridad.mp3*9331479*9/9/2020 11:29:28 PM",
    "1era imagen &amp; El Santi - Meneito.mp3*7775175*9/9/2020 11:29:28 PM",
    "1era Imagen &amp; El Santy - Repartera.mp3*6094335*9/9/2020 11:29:28 PM",
    "Aisar Y El Expresso De Cuba - Un Pastel.mp3*4408162*9/9/2020 11:32:46 PM",
    "Alex Duvall - Amigo (Version) [Homenaje A El Dany].mp3*3639244*9/9/2020 11:32:46 PM",
    "Alex Eqne - Imposible (Cover Morffa).mp3*7977711*9/9/2020 11:32:46 PM",
    "Allen Ramos Ft Kimiko - Hagamos El Sexo.mp3*8059494*9/9/2020 11:32:47 PM",
    "Angel D Angeles Ft Los Yakuza(YK) - Cuando Hables Con El(58877280).mp3*8127722*9/9/2020 11:32:49 PM",
    "Anubix Ft El Carli - Hasta Afuera.mp3*4963950*9/9/2020 11:32:52 PM",
    "Baby Lores Ft Jose Yamil(El Chavo) - Te Solte.mp3*2568417*9/9/2020 11:32:54 PM",
    "Baby Lores Ft Solo Frank La Melodia - No Me Llores.mp3*3065610*9/9/2020 11:32:54 PM",
    "Bryant Garcia - Mi Estrella.mp3*6248149*9/9/2020 11:32:55 PM",
    "Bryant Garcia - We Not Nice.mp3*8351509*9/9/2020 11:32:55 PM",
    "Carlos Heber Rodriguez - Levantate (Cancion Dedicada Al COVID 19).mp3*7038982*9/9/2020 11:32:57 PM",
    "Carluchi &amp; TLC Dayan - Puro Veneno.mp3*2884374*9/9/2020 11:32:58 PM",
    "Carluchi Ft Anubix - De Cara Contigo.mp3*4892479*9/9/2020 11:32:58 PM",
    "Chacal - Introl.mp3*7344163*9/9/2020 11:32:58 PM",
    "Chacal Ft Lenier - Besame.mp3*5047333*9/9/2020 11:32:59 PM",
    "Chacal Ft Lenier Mesa - Ganas De Ti.mp3*7373079*9/9/2020 11:33:00 PM",
    "Chris Tamayo - Hello.mp3*4592176*9/9/2020 11:33:02 PM",
    "Chris Tamayo - La Primera Vez( S E E N ).mp3*4695759*9/9/2020 11:33:02 PM",
    "Chris Tamayo - Marquesina( S E E N ).mp3*4549682*9/9/2020 11:33:02 PM",
    "Chris Tamayo - Mátame.mp3*3806812*9/9/2020 11:33:02 PM",
    "Chris Tamayo - Peliculeo( S E E N ).mp3*4195461*9/9/2020 11:33:03 PM",
    "Chris Tamayo - Ratito.mp3*4957681*9/9/2020 11:33:03 PM",
    "Chris Tamayo Ft Nicole Manzo &amp; Quique - Presidente.mp3*7047582*9/9/2020 11:33:03 PM",
    "Cuban Deejays - DVD (Planet Records &amp; La Oficina Secreta).mp3*3907558*9/9/2020 11:33:10 PM",
    "Cuban Deejays x Alex Duvall x Chacal x El Chulo - El Gustazo.mp3*2872289*9/9/2020 11:33:10 PM",
    "Cuban Deejays x Casper Magico x Rochy RD - A Escondidas.mp3*7285779*9/9/2020 11:33:10 PM",
    "Cuban Deeyays x Divan x Chimbala - Cash (Planet Records &amp; La Oficina Secreta).mp3*4512661*9/9/2020 11:33:11 PM",
    "D´Lujo(Big Gaby &amp; El Filio) - Errores(Contrataciones 53972754-76980111).mp3*8883184*9/9/2020 11:33:11 PM",
    "D´Lujo(Big Gaby &amp; El Filio) - Master Card(Contrataciones 53972754-76980111).mp3*8287592*9/9/2020 11:33:11 PM",
    "DJROWA Ft Lacho El Durako,Manu Manu &amp; El Bala - Yo Te La Di.mp3*7350431*9/9/2020 11:33:14 PM",
    "Eddy K Ft El Carli - Eco.mp3*2962762*9/9/2020 11:33:15 PM",
    "El Chiko De La Calle - De La Que Te Salvaste.mp3*4730530*9/9/2020 11:33:19 PM",
    "El Chiko De La Calle - Es Verdad.mp3*8047671*9/9/2020 11:33:19 PM",
    "El Chulo Ft El Rocko,El Corto,Wildey,Wampi,El Kamel,Ale El Mas Buscado - Mango.mp3*4828159*9/9/2020 11:33:19 PM",
    "El Duro - Mujeres Pierdan El Control.mp3*7362519*9/9/2020 11:33:20 PM",
    "El Duro Ft La Diosa - Tu Te Enamoras.mp3*4597191*9/9/2020 11:33:20 PM",
    "El Enviado Ft El Carli x El Yowa - Pa Mi Cama.mp3*4431679*9/9/2020 11:33:21 PM",
    "El Lucky - Eva Tiene Dos Caras.mp3*8176933*9/9/2020 11:33:23 PM",
    "El Lucky Ta Loco - Agoniza(Contigo Me Voy De Rumba).mp3*9158073*9/9/2020 11:33:23 PM",
    "El Negrito &amp; Kokito Ft El Carli - De 7 A 12.mp3*5323813*9/9/2020 11:33:24 PM",
    "El Pichy - Inflador.mp3*7422237*9/9/2020 11:33:26 PM",
    "El Pichy - Los Poderes.mp3*2857848*9/9/2020 11:33:26 PM",
    "El Puky &amp; El Ozi Ft El Jonihk - Tu Amiguita.mp3*6868983*9/9/2020 11:33:26 PM",
    "El Ricky &amp; El Yanky - Amigo De Que.mp3*7165479*9/9/2020 11:33:28 PM",
    "El Ricky &amp; El Yanky - Hasta El Final.mp3*6576663*9/9/2020 11:33:28 PM",
    "El Ricky &amp; El Yanky - La Hija Del Cabo.mp3*6474519*9/9/2020 11:33:28 PM",
    "El Ricky &amp; El Yanky - Para Mi Casita.mp3*7524615*9/9/2020 11:33:28 PM",
    "El Ricky &amp; El Yanky - Q Fue Lo Q Fumates.mp3*7935951*9/9/2020 11:33:29 PM",
    "El Ricky &amp; El Yanky - Tactea.mp3*6575619*9/9/2020 11:33:29 PM",
    "El Ricky &amp; El Yanky - Tu Tanke Soy Yo.mp3*7568463*9/9/2020 11:33:29 PM",
    "El Ricky &amp; El Yanky Ft El Doni - Publicalo.mp3*6504654*9/9/2020 11:33:29 PM",
    "El Taiger Ft El Carli X El Yowa - Te Nesecito.mp3*5084949*9/9/2020 11:33:30 PM",
    "El Taiger X Un Titico - El Palo Aqui Mismo(Remix).mp3*4446687*9/9/2020 11:33:30 PM",
    "EL TIKKO x EL Kimiko &amp; Yordy - Tu Recuerdo.mp3*7715037*9/9/2020 11:33:30 PM",
    "Enzo La Melodia Secreta Ft Lobo King Dowa - Tu Novio.mp3*3807451*9/9/2020 11:33:34 PM",
    "Enzo La Melodia Secreta x Harryson - Corazón Partido.mp3*6261402*9/9/2020 11:33:35 PM",
    "Erick Lexi - Mujer Bonita.mp3*4164603*9/9/2020 11:33:35 PM",
    "GENNY V - No Estan Pa(MAX PRO MUSIC BY PIVE).mp3*4427113*9/9/2020 11:33:39 PM",
    "Gente De Zona Ft Becky G - Muchacha.mp3*3258221*9/9/2020 11:33:39 PM",
    "Gente De Zona Ft Carlos Vives &amp; Pacific Broders - La Noche Pinta Buena.mp3*3218165*9/9/2020 11:33:39 PM",
    "Gnio &amp; Dayroni X Marlon94 - Tu Primera Vez.mp3*8226183*9/9/2020 11:33:40 PM",
    "Harryson Ft El Ricky &amp; El Yanky - Tu Tanke.mp3*7568478*9/9/2020 11:33:46 PM",
    "Henry Ft Jay Maly &amp; Dj Unic - El Orgullo.mp3*3062648*9/9/2020 11:33:47 PM",
    "Jacob Forever Ft. El Chulo, El Uniko, El Micha, Lenier,El Taiger - Más Rico Mañana.mp3*13825629*9/9/2020 11:33:53 PM",
    "JayBy - Tokyo.mp3*3307605*9/9/2020 11:33:53 PM",
    "Jayby - Wuhan.mp3*5282552*9/9/2020 11:33:54 PM",
    "Jayby x Dj Unic - Rio.mp3*5839330*9/9/2020 11:33:54 PM",
    "Jayby X DNIX-E - Tokyo Remix.mp3*9721321*9/9/2020 11:33:54 PM",
    "Jeikel Acosta - Ahora Te Vas.mp3*8693012*9/9/2020 11:33:56 PM",
    "Jeikel Acosta - Quitarnos Las Ganas.mp3*8397383*9/9/2020 11:33:56 PM",
    "Jeikel Acosta Ft Srta Dayana - 14 De Febrero.mp3*5267359*9/9/2020 11:33:56 PM",
    "Jeikel Acosta -Tengo Ganas.mp3*3579043*9/9/2020 11:33:56 PM",
    "Jhon Menez Ft Kimiko &amp; Yordy - Caperucita.mp3*8457117*9/9/2020 11:33:57 PM",
    "Jhon Menez Ft Vane D - A Lo Anuel &amp; Karol.mp3*4973354*9/9/2020 11:33:57 PM",
    "Jose Valladares - Mas Solidarios(COVID 19).mp3*1881683*9/9/2020 11:33:59 PM",
    "Jose Yamil(El Chavo) - Contigo Quiero Todo.mp3*2343022*9/9/2020 11:33:59 PM",
    "Karenia Diaz - Tell Me.mp3*9084938*9/9/2020 11:34:00 PM",
    "L+D El Duro Ft Eddy-K - Quiere Bailar.mp3*8048919*9/9/2020 11:34:04 PM",
    "Leony Cabrera x Powell - Quiero Una Diabla.mp3*6446694*9/9/2020 11:34:08 PM",
    "Lorna Ft Cuban Deejays - Toma Dembow(Planet Records &amp; La Oficina Secreta).mp3*4019154*9/9/2020 11:34:10 PM",
    "Los Yakuza - Amnesia(58877280).mp3*9379474*9/9/2020 11:34:13 PM",
    "Maikel Miki Ft Mike Morato - Pegajosa.mp3*3219419*9/9/2020 11:34:16 PM",
    "Maikel Miki Ft Sapienza - Pegao.mp3*2844927*9/9/2020 11:34:16 PM",
    "Maikel Miki X Joseph Sinatra X Cacciola - Jerusalema(Radio Edit)(ZUMBA).mp3*3614390*9/9/2020 11:34:16 PM",
    "Marlon94 Ft Yordy - Amigos No.mp3*6548882*9/9/2020 11:34:19 PM",
    "Marlon94 x D MenT - Quiero Entrar.mp3*3579243*9/9/2020 11:34:19 PM",
    "Marlon94 x Tanger - Tu Mejor Pieza.mp3*8047998*9/9/2020 11:34:20 PM",
    "Maykel Blanco Y Su Salsa Mayor Ft Yoruba Andabo - La Cafetera.mp3*15373467*9/9/2020 11:34:21 PM",
    "Nelson Manuel - Millonario En El Amor.mp3*8645992*9/9/2020 11:34:25 PM",
    "Nelson Manuel Ft Arney - Millonario En El Amor(Remix).mp3*8447334*9/9/2020 11:34:25 PM",
    "Nelson Manuel Ft Giselle - Sin Medida.mp3*2509666*9/9/2020 11:34:26 PM",
    "Nelson Manuel Ft La Corte - Bumbata.mp3*5072411*9/9/2020 11:34:26 PM",
    "Omi Hernandez Ft Solo Frank La Melodía - Ojalá.mp3*2372488*9/9/2020 11:34:28 PM",
    "Osmani Garcia Ft Armani G. - Amor De Escuela.mp3*5012852*9/9/2020 11:34:32 PM",
    "Osmani Garcia La Voz Ft Yomil &amp; El Dany,Yordy - Perrigatea.mp3*5639163*9/9/2020 11:34:33 PM",
    "Osmany Garcia - Mi Hogar Eres Tú(Planet Records &amp; La Oficina Secreta).mp3*4320640*9/9/2020 11:34:33 PM",
    "Piq Montano - Deja Que La Noche Avance.mp3*6791754*9/9/2020 11:34:39 PM",
    "Piq Montano - En La Mesa Del Bar.mp3*9783858*9/9/2020 11:34:39 PM",
    "Piq Montano - Hola.mp3*8848434*9/9/2020 11:34:39 PM",
    "Piq Montano - No Se Si Volvera.mp3*7560138*9/9/2020 11:34:39 PM",
    "Piq Montano - Para No Pensar.mp3*10882146*9/9/2020 11:34:40 PM",
    "Piq Montano - Tenemos Toda La Noche Mami.mp3*8042068*9/9/2020 11:34:40 PM",
    "Piq Montano - Todos Los Días.mp3*8514697*9/9/2020 11:34:40 PM",
    "Piq Montano - Y Duele.mp3*10106439*9/9/2020 11:34:41 PM",
    "R3YMON - Hoy Voy A Beber.mp3*4358954*9/9/2020 11:34:43 PM",
    "R3YMON - La Cama.mp3*3155650*9/9/2020 11:34:43 PM",
    "Roby Pulido - Cama y Mesa (Versión Salsa)(www.AbdelLaEsenciayEstudiosOdisea.com).mp3*6872766*9/9/2020 11:34:46 PM",
    "Roby Pulido - Cama y Mesa (Versión Salsa).mp3*6872766*9/9/2020 11:34:45 PM",
    "Roby Pulido - Duele.mp3*5173693*9/9/2020 11:34:46 PM",
    "Roby Pulido - Mis Raices.mp3*6558704*9/9/2020 11:34:46 PM",
    "Roby Pulido - Que Locura.mp3*5948692*9/9/2020 11:34:46 PM",
    "Roby Pulido - Quererte A Mi Manera.mp3*5566259*9/9/2020 11:34:46 PM",
    "Roby Pulido - Tengo El Control.mp3*5258238*9/9/2020 11:34:47 PM",
    "Roby Pulido Ft Haila - El Amor Es Asi(www.AbdelLaEsenciayEstudiosOdisea.com).mp3*6311063*9/9/2020 11:34:47 PM",
    "Roby Pulido Ft Haila - El Amor Es Asi.mp3*6311063*9/9/2020 11:34:47 PM",
    "Ruly Rodriguez - Kimika RMX.mp3*3056594*9/9/2020 11:34:48 PM",
    "Ruly Rodriguez Ft BESFORD - Rico Rico.mp3*2888813*9/9/2020 11:34:48 PM",
    "Ruly Rodriguez Ft Kiki Aguero - Esta Noche.mp3*3333939*9/9/2020 11:34:48 PM",
    "Sandy El White Ft Mr Vazquez - Me Matas.mp3*5220816*9/9/2020 11:34:49 PM",
    "Sandy El White Ft Mr Vazquez - One Night Stand.mp3*4359581*9/9/2020 11:34:50 PM",
    "Sandy El White Ft Mr Vazquez - Sha La La La La.mp3*5313782*9/9/2020 11:34:50 PM",
    "The Shepal - Adios (feat. Roberto Perdomo(Tesis de Menta)).mp3*9013468*9/9/2020 11:34:56 PM",
    "The Shepal - Creo en ti (feat. Diana Ruz(Tracks)).mp3*9435096*9/9/2020 11:34:57 PM",
    "The Shepal - No Eres Tú.mp3*7054822*9/9/2020 11:34:57 PM",
    "The Shepal - No Puedo Parar (feat. Ernesto Blanco).mp3*7313477*9/9/2020 11:34:57 PM",
    "The Shepal - Voy A Estar Bien.mp3*10298379*9/9/2020 11:34:57 PM",
    "Tiarè - Cizaña Con Maldad.mp3*4818501*9/9/2020 11:34:58 PM",
    "Tiaré Ft Damian The Lion - Sin Frenos(By Tony El Genial).mp3*8392179*9/9/2020 11:34:58 PM",
    "Wil Campa - La Cama.mp3*9030476*9/9/2020 11:35:08 PM",
    "Wil Campa Ft Papi Lacho - No Te Quedes Con Ganas.mp3*8522694*9/9/2020 11:35:08 PM",
    "Yakarta Ft El Carli &amp; El Yowa &amp; El Los De Los 90 - Pa Que Decirte.mp3*4769599*9/9/2020 11:35:10 PM",
    "Yomil &amp; El Dany - Intro.mp3*8252206*9/9/2020 11:35:13 PM",
    "Yomil &amp; El Dany Ft Alex Duvall &amp; William El Magnifico - Pa Darte (Remix).mp3*7919929*9/9/2020 11:35:13 PM",
    "Yomil &amp; El Dany Ft Baby Lores - No Quiere Saber de Amor.mp3*8491616*9/9/2020 11:35:14 PM",
    "Yomil &amp; El Dany Ft Chesco - Úsala.mp3*9941806*9/9/2020 11:35:14 PM",
    "Yomil &amp; El Dany Ft Chesco &amp; Srta Dayana - Hablame Claro.mp3*7012957*9/9/2020 11:35:15 PM",
    "Yomil &amp; El Dany Ft Damian &amp; Norlam - Perreo.mp3*8147716*9/9/2020 11:35:15 PM",
    "Yomil &amp; El Dany Ft Divan &amp; Eddy K (AL Grano Remix) loschampions.mp3*5269896*9/9/2020 11:35:15 PM",
    "Yomil &amp; El Dany Ft El Chulo - Solabaya.mp3*12751537*9/9/2020 11:35:15 PM",
    "Yomil &amp; El Dany Ft El Kamel - Navega (Remix).mp3*9007667*9/9/2020 11:35:15 PM",
    "Yomil &amp; El Dany Ft El Kamel - Ni Sexo Ni Romance(Los Champions).mp3*2845106*9/9/2020 11:35:16 PM",
    "Yomil &amp; El Dany ft El Taiger - Vida Nueva.mp3*5946590*9/9/2020 11:35:16 PM",
    "Yomil &amp; El Dany Ft El Uniko - Sin Ti Mucho Mejor (Remix).mp3*7771681*9/9/2020 11:35:16 PM",
    "Yomil &amp; El Dany Ft Insurrecto - Sumando(Versión).mp3*7604370*9/9/2020 11:35:16 PM",
    "Yomil &amp; El Dany Ft July Roby - Deseos.mp3*7095504*9/9/2020 11:35:17 PM",
    "Yomil &amp; El Dany Ft Kimiko &amp; Yordy - Tengo Una Pila.mp3*5222876*9/9/2020 11:35:17 PM",
    "Yomil &amp; El Dany Ft Kola Loka - Soketonloschampions dembow.mp3*5071157*9/9/2020 11:35:17 PM",
    "Yomil &amp; El Dany Ft Lenier &amp; El Chacal - Crazy Loca.mp3*8013970*9/9/2020 11:35:17 PM",
    "Yomil &amp; El Dany Ft UnTitico, KN1ONE - Prometeme (Remix).mp3*7927371*9/9/2020 11:35:17 PM",
    "Yomil &amp; El Dany Ft Wampi &amp; Yakarta - Tóxica (Remix).mp3*7903210*9/9/2020 11:35:18 PM",
    "Yomil &amp; El Dany Ft Yulien Oviedo - Im Sorry For You.mp3*7602183*9/9/2020 11:35:18 PM",
    "Yomil &amp; El Dany Ft Zurdo Mc &amp; Harryson - Brujeria.mp3*8174884*9/9/2020 11:35:18 PM",
    "Young K - Nunca Me Olvide De Ti.mp3*5138687*9/9/2020 11:35:22 PM",
    1024087828,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\2N1 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 11:25:37 PM",
    "01 - A mi manera no(Prod By Dj Rodo)Blessed Music.mp3*11201854*9/9/2020 11:29:08 PM",
    "02 - Conectate(Prod By Dj Rodo)Blessed Music.mp3*9189396*9/9/2020 11:29:30 PM",
    "03 - Ven Pá-K(Prod By Dj Rodo)Blessed Music.mp3*9974910*9/9/2020 11:29:51 PM",
    "04 - No Puedo Negarte (Prod byRey Diaz).mp3*10966710*9/9/2020 11:30:09 PM",
    "05 - Mas que navidad(Prod By Dj Rodo)Blessed Music.mp3*12430668*9/9/2020 11:30:28 PM",
    "06 -Tatuaje (Prod byRey Diaz).mp3*11756466*9/9/2020 11:30:45 PM",
    "07 - Padre(Prod By Dj Rodo)Blessed Music.mp3*10048300*9/9/2020 11:30:57 PM",
    "08- Gozate(Prod By Dj Rodo)Blessed Music.mp3*8598918*9/9/2020 11:31:12 PM",
    "09 - Mi tiempo (Prod byRey Diaz).mp3*12983718*9/9/2020 11:31:23 PM",
    "10 - Vida  (Prod byRey Diaz).mp3*9839191*9/9/2020 11:31:33 PM",
    "11 - 2ble Uncion (Prod byRey Diaz).mp3*11000947*9/9/2020 11:31:43 PM",
    "12- No moriré(Prod By Dj Rodo)Blessed Music.mp3*13097313*9/9/2020 11:31:52 PM",
    "13 - 2N1 Intro(Prod By Dj Rodo)Blessed Music.mp3*6048418*9/9/2020 11:31:57 PM",
    137136809,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\3 - Discos Nacionales(Exclusivos)*0*9/13/2020 10:05:45 PM",
    0,
    "783"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\3 - Discos Nacionales(Exclusivos)\\Julio Kaiser (CD OFICIAL)(2020)*0*9/13/2020 10:05:48 PM",
    "-    Cuando Tin Tiene - Julio KaiSer  Prod BY- Made by the Diamond +Iris Sound (T.S.I).mp3*5550969*9/9/2020 11:28:59 PM",
    5550969,
    "784"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\3 - Discos Nacionales(Exclusivos)\\Julio Kaiser (CD OFICIAL)(2020)\\! Estreno De La Semana 0 - Julio Kaiser - Tin Tiene*0*9/13/2020 10:05:48 PM",
    "Cuando Tin Tiene - Julio KaiSer  Prod BY- Made by the Diamond +Iris Sound (T.S.I).mp3*5550969*9/9/2020 11:33:10 PM",
    5550969,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Adelanto De Pedrito Kmacho &amp; El Clan - Acabé(2020)(FUSION)*0*9/13/2020 11:08:44 PM",
    "01.Pedrito Kmacho &amp; El Clan - Yo Me Acuerdo.mp3*7828446*9/9/2020 11:29:22 PM",
    "02.Pedrito Kmacho &amp; El Clan - Acabé.mp3*8837300*9/9/2020 11:29:42 PM",
    16665746,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Agrupacion 1era Imagen - Friends(2020)()*0*9/13/2020 11:14:05 PM",
    "01.1era Imagen - Bailame.mp3*5194446*9/9/2020 11:29:12 PM",
    "02.1era Imagen - El Secreto Del Amor.mp3*6024827*9/9/2020 11:29:33 PM",
    "03.1era imagen &amp; El Santi - Meneito.mp3*7775175*9/9/2020 11:29:55 PM",
    "04.1era Imagen &amp; El Chily - Oscuridad.mp3*9331479*9/9/2020 11:30:13 PM",
    "05.1era Imagen - Gucci Gucci.mp3*8504931*9/9/2020 11:30:32 PM",
    "06.1era Imagen &amp; El Santy - Repartera.mp3*6094335*9/9/2020 11:30:47 PM",
    42925193,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Agrupacion Kimiko &amp; Yordy Ft Henry Manuel - La Blanca Y El Negrito(2020)*0*9/13/2020 11:14:10 PM",
    "01.Kimiko &amp; Yordy Ft Henry Manuel - Mala.mp3*7416108*9/9/2020 11:29:18 PM",
    "02.Kimiko &amp; Yordy Ft Henry Manuel - La Blanca Y El Negrito.mp3*8070066*9/9/2020 11:29:38 PM",
    "03.Henry Manuel - Tu Y Yo.mp3*6573956*9/9/2020 11:29:59 PM",
    "04.Henry Manuel - Coronavirus.mp3*8194839*9/9/2020 11:30:18 PM",
    "05.Henry Manuel - X Tu Talla.mp3*6785025*9/9/2020 11:30:35 PM",
    "06.Wildey Ft Henry Manuel - La Blanca Y El Negrito.mp3*8286509*9/9/2020 11:30:53 PM",
    45326503,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Aisar Y El Expresso De Cuba - Lo Mas Reciente(2020)*0*9/13/2020 11:30:46 PM",
    "01.Aisar Y El Expresso De Cuba - Un Pastel.mp3*4408162*9/9/2020 11:29:12 PM",
    "02.Aisar Y El Expresso De Cuba - El Camaron.mp3*4603618*9/9/2020 11:29:33 PM",
    "03.Aisar Y El Expresso De Cuba - A Mi Madre.mp3*3591010*9/9/2020 11:29:55 PM",
    "04.Aisar Y El Expresso De Cuba - 60 Años.mp3*3653986*9/9/2020 11:30:13 PM",
    16256776,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Ale Global(2020)()*0*9/13/2020 11:14:16 PM",
    "Ale Global++ la Necesidad   - .mp3*7359722*9/9/2020 11:32:46 PM",
    7359722,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Alex David - Calabaza Calabaza(2020)()*0*9/13/2020 10:06:16 PM",
    "Alex David - Calabaza Calabaza.mp3*4517644*9/9/2020 11:32:46 PM",
    4517644,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Alex Eqne - The Numbers One (Morffa Edition)(2020)()*0*9/13/2020 11:25:05 PM",
    "01. Alex Eqne - Duele (Cover Morffa).mp3*7892103*9/9/2020 11:29:11 PM",
    "02. Alex Eqne - Imposible (Cover Morffa).mp3*7977711*9/9/2020 11:29:32 PM",
    "03. Alex Eqne - HP (Cover Morffa).mp3*7978755*9/9/2020 11:29:54 PM",
    "04. Alex Eqne - Ay Corazon (Cover Morffa).mp3*6819159*9/9/2020 11:30:12 PM",
    "05. Alex Eqne - Tan Bonita Y Tan Sola (Cover Morffa).mp3*8183379*9/9/2020 11:30:31 PM",
    "06. Alex Eqne - Aullando (Cover Morffa).mp3*8430999*9/9/2020 11:30:46 PM",
    "07. Alex Eqne - Maldita Despedida (Cover Morffa).mp3*8787855*9/9/2020 11:31:01 PM",
    "08. Alex Eqne - El Prestamo (Cover Morffa).mp3*8291955*9/9/2020 11:31:13 PM",
    "09. Alex Eqne - Mal Acompañado (Cover Morffa).mp3*8505975*9/9/2020 11:31:25 PM",
    "10. Alex Eqne - Me Niego (Cover Morffa).mp3*8950719*9/9/2020 11:31:36 PM",
    "11. Alex Eqne - Parecen Viernes (Cover Morffa).mp3*8012163*9/9/2020 11:31:45 PM",
    "12. Alex Eqne - Hola (Cover Morffa).mp3*8356683*9/9/2020 11:31:53 PM",
    "13. Alex Eqne - Un Año (Cover Morffa).mp3*9168915*9/9/2020 11:31:58 PM",
    "14. Alex Eqne - Que Gano Olvidandote (Cover Morffa).mp3*9365187*9/9/2020 11:32:03 PM",
    "15. Alex Eqne - Que Mas Pues (Cover Morffa).mp3*7674534*9/9/2020 11:32:06 PM",
    "16. Alex Eqne - Cariño Mio (Cover Morffa).mp3*9932079*9/9/2020 11:32:09 PM",
    "17. Alex Eqne - El Culpable (Cover Morffa).mp3*6499407*9/9/2020 11:32:11 PM",
    "18. Alex Eqne - Hablame Bajito (Cover Morffa).mp3*7446315*9/9/2020 11:32:13 PM",
    148273893,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Allen Ramos - Pensando En Ti(2020)()*0*9/13/2020 10:25:19 PM",
    "01.Allen Ramos - Pensando En Ti.mp3*8924942*9/9/2020 11:29:12 PM",
    "02.Allen Ramos - No Funciona.mp3*7107864*9/9/2020 11:29:33 PM",
    "03.Allen Ramos Ft El Ankla - Mala Tuya.mp3*8947930*9/9/2020 11:29:55 PM",
    "04.Allen Ramos Ft Kimiko - Hagamos El Sexo.mp3*8059494*9/9/2020 11:30:13 PM",
    33040230,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Alvarito x  Braco x Pedritin(2020)()*0*9/13/2020 10:06:50 PM",
    "Alvarito x  Braco ft Pedritin  - Aquella noche - .mp3*7992931*9/9/2020 11:32:47 PM",
    7992931,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Alvinlucho(2020)()*0*9/13/2020 10:25:23 PM",
    "1-Mujer- alvilucho x shale .mp3*9713910*9/9/2020 11:29:29 PM",
    "2-Volvio a prender- alvilucho x shale .mp3*8312862*9/9/2020 11:29:49 PM",
    "3-Mas Dura- alvilucho x shale ft Buly x Jarol.mp3*7266774*9/9/2020 11:30:07 PM",
    "4-Mi Puta- alvilucho x shale ft Joker.mp3*9886170*9/9/2020 11:30:26 PM",
    "5-Antes de la Fama- alvilucho x shale ft skinnyyy.mp3*8886018*9/9/2020 11:30:42 PM",
    44065734,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\AndyWay x Layn Layn(2020)()*0*9/13/2020 10:07:05 PM",
    "AndyWay x Layn Layn - Tu te pones -  .mp3*6789208*9/9/2020 11:32:48 PM",
    6789208,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Anthony - La Melodia(2020)()*0*9/13/2020 10:07:07 PM",
    "Anthony la Melodia bella.mp3*7499838*9/9/2020 11:32:49 PM",
    "Anthony la Melodia choca (Prod By Chico Frank).mp3*6658596*9/9/2020 11:32:49 PM",
    "Anthony la Melodia ft KamelProd(1).mp3*7289559*9/9/2020 11:32:50 PM",
    "Anthony la Melodia ft Wil CampaEnamorao(Prød By DJ-RIKY)™.mp3*7847319*9/9/2020 11:32:50 PM",
    "Anthony la Melodia ft Yambo -tu estas rica(Prod By Chico Frank).mp3*7955314*9/9/2020 11:32:50 PM",
    "Anthony la Melodia ft Yambo.mp3*7404759*9/9/2020 11:32:50 PM",
    "Anthony la Melodia Los Pitufos(Prød By DJ-RIKY x Flow Musik)™.mp3*6548475*9/9/2020 11:32:51 PM",
    "Anthony la Melodia mi mochilita.mp3*7054398*9/9/2020 11:32:51 PM",
    "Anthony la Melodia parque de diverciones.mp3*7076439*9/9/2020 11:32:51 PM",
    "Anthony la Melodia vamos a bailar.mp3*6642435*9/9/2020 11:32:51 PM",
    "Anthony la MelodiaBajodelmarProdbyRaphel.mp3*6732759*9/9/2020 11:32:52 PM",
    78709891,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Anubix ft Osiesmith x El Menor(2020)()*0*9/13/2020 8:54:36 PM",
    "Anubix ft Osiesmith x El Menor - Pal Pley  -  .mp3*8488212*9/9/2020 11:32:52 PM",
    8488212,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Artista  Yero(El Kila) - Pa Mi Gente(Prevencion Del Coronavirus)(2020)*0*9/13/2020 8:54:36 PM",
    " Yero(El Kila) - Pa Mi Gente.mp3*8451617*9/9/2020 11:29:00 PM",
    8451617,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Artista GENNY V - No Estan Pa(2020)()*0*9/13/2020 8:54:37 PM",
    "02.GENNY V - No Estan Pa(MAX PRO MUSIC BY PIVE).mp3*4427113*9/9/2020 11:29:37 PM",
    4427113,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Artista Lady Laura - Lo Mas Reciente(2020)*0*9/13/2020 8:54:37 PM",
    "10.Lady Laura Ft Dayron Sexappeal - Tutti Fruti.mp3*752937*9/9/2020 11:31:38 PM",
    752937,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Artista Miny Cuore - En Las Nubes(2020)()*0*9/13/2020 8:54:37 PM",
    ".SAL DE ARRIBA DE MICOSA MIA X MINY CUORE X MAX PRO MUSIC.mp3*49495*9/9/2020 11:29:07 PM",
    49495,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Artista Nelson Manuel &amp; La Corte - Lo Mas Reciente(2020)*0*9/13/2020 8:54:38 PM",
    "01.Nelson Manuel - Millonario En El Amor.mp3*8645992*9/9/2020 11:29:21 PM",
    "02.Nelson Manuel Ft Giselle - Sin Medida.mp3*2509666*9/9/2020 11:29:41 PM",
    "04.Nelson Manuel Ft La Corte - Bumbata.mp3*5072411*9/9/2020 11:30:22 PM",
    16228069,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Artista Roby Pulido - Mi Locura(2020)(SALSA)*0*9/13/2020 8:54:38 PM",
    "01.Roby Pulido - Cama y Mesa (Versión Salsa).mp3*6872766*9/9/2020 11:29:23 PM",
    6872766,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Artistas Cubanos - Que Dedicaron Temas Al Covid-19(Coronavirus)(2020)*0*9/13/2020 8:54:40 PM",
    "01.Yomil &amp; El Dany,Lenier,JG,Wampi,Isaac Delgado,Laritza - La Tierra.mp3*4658034*9/9/2020 11:29:24 PM",
    "02.El Lucky Ft Kola Loka - Corona Virus.mp3*8582073*9/9/2020 11:29:35 PM",
    "03.Douglas Show - Corona Virus.mp3*4972174*9/9/2020 11:29:56 PM",
    "04.Chico Querol - COVID - 19  El Mundo.mp3*7023265*9/9/2020 11:30:14 PM",
    "05.Srta. Dayana - Luz (Cancion Dedicada Al COVID 19).mp3*5850945*9/9/2020 11:30:38 PM",
    "06. Yero(El Kila) - Pa Mi Gente.mp3*8451604*9/9/2020 11:30:47 PM",
    "07.Carlos Heber Rodriguez - Levantate (Cancion Dedicada Al COVID 19).mp3*7038982*9/9/2020 11:31:01 PM",
    "08.Jose Valladares - Mas Solidarios(COVID 19).mp3*1881683*9/9/2020 11:31:16 PM",
    "09.Henry Manuel - Coronavirus.mp3*8194839*9/9/2020 11:31:27 PM",
    56653599,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Artistas Cubanos Le Cantan Al Dany - Homenajes(2020)*0*9/13/2020 8:54:41 PM",
    "01.Crazy Duany - Para El Dany.mp3*2992049*9/9/2020 11:29:14 PM",
    "02.El Joker Cuba - Rip Dany.mp3*2506501*9/9/2020 11:29:35 PM",
    "03.Luis Antonio - RIP Dany.mp3*2220875*9/9/2020 11:30:00 PM",
    "04.El Cangri,TheNa,J Chris &amp; Otros - Homenaje Al Dany.mp3*15949829*9/9/2020 11:30:15 PM",
    "05.Gnio &amp; Dayroni - Somos SENSEI.mp3*3886501*9/9/2020 11:30:34 PM",
    "06.Enzo La Melodia Secreta - RIP Dany.mp3*1739182*9/9/2020 11:30:49 PM",
    "07.Alex Duvall - Amigo (Version) [Homenaje A El Dany].mp3*3639229*9/9/2020 11:31:01 PM",
    32934166,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Artistas Sr Nohaya &amp; Mr Don - A Lo Drake(2020)()*0*9/13/2020 11:32:15 PM",
    "00-Sr Nohaya &amp; Mr Don Ft El Taiger - Tuto Bene.mp3*8489559*9/9/2020 11:29:08 PM",
    "01-Sr Nohaya &amp; Mr Don Ft Wildey - A Lo Drake.mp3*6473307*9/9/2020 11:29:25 PM",
    "02-Sr Nohaya &amp; Mr Don - 3 Con 2.mp3*2590387*9/9/2020 11:29:44 PM",
    "03-Sr Nohaya &amp; Mr Don Ft Yank3ll - La Cerebrina.mp3*8071260*9/9/2020 11:30:05 PM",
    "04-Sr Nohaya &amp; Mr Don Ft Jorge Jr ( Los 4) - La Forma.mp3*3458906*9/9/2020 11:30:24 PM",
    "05-Sr Nohaya &amp; Mr Don Ft Popi Y La Moda - Trankila Tekila.mp3*8244975*9/9/2020 11:30:40 PM",
    "06-Sr Nohaya &amp; Mr Don Ft Un Titico - No Esta En Nada.mp3*7090311*9/9/2020 11:30:55 PM",
    "07-Sr Nohaya &amp; Mr Don Ft Davi &amp; The Fire - XQ Pisos Rico.mp3*3488740*9/9/2020 11:31:07 PM",
    "08-Sr Nohaya &amp; Mr Don Ft Wildey,Kimiko &amp; Yordy,Genio &amp; Daironi - Ricamente.mp3*8883357*9/9/2020 11:31:20 PM",
    "09-Sr Nohaya &amp; Mr Don Ft El Kamel - Poquitico Pa Todo.mp3*3008508*9/9/2020 11:31:30 PM",
    "10-Sr Nohaya &amp; Mr Don Ft Yeye x El Knaya - Maldita.mp3*6616797*9/9/2020 11:31:42 PM",
    "11-Sr Nohaya &amp; Mr Don Ft Los Tropelaje - No Da La Cara.mp3*9726237*9/9/2020 11:31:50 PM",
    "12-Sr Nohaya &amp; Mr Don Ft Yank3ll - Coco.mp3*5097936*9/9/2020 11:31:56 PM",
    "13-Sr Nohaya &amp; Mr Don Ft Los Tropelaje - Tate Trankilito.mp3*5424610*9/9/2020 11:32:01 PM",
    "14-Sr Nohaya - No Olvides Mi Nombre - Larra dj 58020966.mp3*7530002*9/9/2020 11:32:05 PM",
    "15-Sr Nohaya - Una Viducha - Larra dj 58020966.mp3*6939634*9/9/2020 11:32:08 PM",
    101134526,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Baby Hann(2020)()*0*9/13/2020 8:54:44 PM",
    "Baby Hann - Sexo completo -  Trap .mp3*8649126*9/9/2020 11:32:53 PM",
    "Baby Hann - Soy real-  Trap .mp3*8032637*9/9/2020 11:32:53 PM",
    16681763,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Barreiro(2020)()*0*9/13/2020 8:54:45 PM",
    "Barreiro - En tu lugar - .mp3*7662743*9/9/2020 11:32:54 PM",
    "Barreiro - Sin Piedad - .mp3*7791265*9/9/2020 11:32:54 PM",
    "Barreiro x Maikol x  Pipi - Mi Kardasha - .mp3*8454775*9/9/2020 11:32:54 PM",
    23908783,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Bavecadas &amp; Varony - Angola &amp; Cuba LA NUEVA ERA(2020)*0*9/13/2020 11:11:31 PM",
    "01.Bavecadas &amp; Varony - Adios.mp3*7681242*9/9/2020 11:29:12 PM",
    "02.Bavecadas &amp; Varony - Mama Ya Bana.mp3*8543586*9/9/2020 11:29:33 PM",
    "03.Bavecadas &amp; Varony - Acercate Ft Danny.mp3*4362366*9/9/2020 11:29:55 PM",
    "04.Bavecadas &amp; Varony Ft King Viezzo - Un Angelito.mp3*8140146*9/9/2020 11:30:14 PM",
    "05.Bavecadas &amp; Varony Ft King Viezzo - Tu Te Vienes Y Te Vas.mp3*7342986*9/9/2020 11:30:32 PM",
    36070326,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Birbo (2020)()*0*9/13/2020 11:21:58 PM",
    "BIRBO X CHAKA DEMUS X Livan Pro EL CULALE mix.wav*53879052*7/9/2020 2:34:42 PM",
    "BIRBO X MICKY X Livan Pro AMORES PA PRE salsa.wav*30859708*6/16/2020 6:03:06 PM",
    "BIRBO X MICKY X Livan Pro AMORES PA PRE.wav*33602578*4/18/2020 9:07:24 PM",
    "BIRBO X Wildey X Livan ProENGANCHA tema.wav*46671922*7/9/2020 2:20:17 PM",
    165013260,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Bryant Garcia - Mi Estrella(2020)()*0*9/13/2020 9:35:51 PM",
    "01.Bryant Garcia - Mi Estrella.mp3*6248149*9/9/2020 11:29:13 PM",
    "02.Bryant Garcia - We Not Nice.mp3*8351509*9/9/2020 11:29:34 PM",
    14599658,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Carlos Heber Rodriguez - Levantate(Cancion Dedicada Al COVID 19)*0*9/13/2020 9:35:51 PM",
    "Carlos Heber Rodriguez - Levantate (Cancion Dedicada Al COVID 19).mp3*7038982*9/9/2020 11:32:58 PM",
    7038982,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Carluchi - La Mentirosa(2020)()*0*9/13/2020 9:35:54 PM",
    "01.Carluchi Ft El Kenny - La Mentirosa.mp3*3653224*9/9/2020 11:29:13 PM",
    "02.Carluchi Ft TLC Dayan &amp; El Bachiller - En Vício.mp3*3457202*9/9/2020 11:29:34 PM",
    "03.Carluchi - Se Me Nota.mp3*4020193*9/9/2020 11:29:55 PM",
    "04.Carluchi Ft Fere Prowa - Desde Cero.mp3*2803931*9/9/2020 11:30:14 PM",
    "05.Carluchi Ft Lykan King - pre Te Vas.mp3*2579487*9/9/2020 11:30:32 PM",
    "06.Carluchi x El Malcriao&amp; El Bebo - No Me Hables De Amor.mp3*3049273*9/9/2020 11:30:47 PM",
    "07.Carluchi - Mi Ángel.mp3*5348049*9/9/2020 11:31:01 PM",
    "08.Carluchi  Ft El Kenny - Perdóname(Cover).mp3*4776699*9/9/2020 11:31:13 PM",
    "09.Carluchi Ft Anubix - De Cara Contigo.mp3*4892479*9/9/2020 11:31:25 PM",
    "10.Carluchi Ft Futuristico - No Voy A Dañarte.mp3*3781956*9/9/2020 11:31:37 PM",
    38362493,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Cele x Menor(2020)()*0*9/13/2020 9:35:54 PM",
    "Cele x El menor - To esta en talla -  .mp3*7127755*9/9/2020 11:32:58 PM",
    "Cele x El menor - Vuela -  .mp3*6791298*9/9/2020 11:32:58 PM",
    "Cele x El menor -La corona -  .mp3*8613600*9/9/2020 11:32:58 PM",
    22532653,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Celula Music - Estrenos(2020)()*0*9/13/2020 9:35:59 PM",
    "Amilkele  - Condéname.mp3*2668727*9/9/2020 11:32:48 PM",
    "Benggie Ft CNK IL Y Kpitano - Un Pare.mp3*4711921*9/9/2020 11:32:55 PM",
    "Chacal Ft Enzo - El Mentiroso.mp3*5323186*9/9/2020 11:32:59 PM",
    "Chacal Ft Harrison - La Duraka.mp3*9184076*9/9/2020 11:32:59 PM",
    "Chacal Ft Kimiko Y Yordy - Un segundo.mp3*5167705*9/9/2020 11:32:59 PM",
    "Chacal Ft Yet Garbey - La Culipandea.mp3*3854896*9/9/2020 11:33:00 PM",
    "Denver - Diferente.mp3*2607287*9/9/2020 11:33:12 PM",
    "Duran - De Ti Me Enamore.mp3*3683532*9/9/2020 11:33:15 PM",
    "El Chulo Ft El Kamel,El Rocko, Ale el más buscado,Anubix,Lobo Kin Dowa Y Harrisom -  Mango.mp3*4897704*9/9/2020 11:33:19 PM",
    "El Chulo Ft Wampi Y El Iroso - El Canilleo.mp3*9262557*9/9/2020 11:33:20 PM",
    "El Kamel - Dale.mp3.mp3*2822098*9/9/2020 11:33:21 PM",
    "El Kamel - Hablabas.mp3*3168607*9/9/2020 11:33:21 PM",
    "Elain Morales Ft Henry - Ya no Cree.mp3*3233390*9/9/2020 11:33:34 PM",
    "Gretchen Gray - Dímelo.mp3*2679594*9/9/2020 11:33:41 PM",
    "Heikel Ft TiN Mojaito - Tu Eres Un Komico.mp3*4602834*9/9/2020 11:33:46 PM",
    "Henry - Hasta Atras.mp3*1070897*9/9/2020 11:33:47 PM",
    "Kimiko &amp; Yordi - Borracho.mp3*3252322*9/9/2020 11:34:01 PM",
    "Kimiko Y Yordy Ft Henry - Tu No Sientes Na.mp3*5521053*9/9/2020 11:34:02 PM",
    "Lenier - Se acabó El Amor.mp3*8226950*9/9/2020 11:34:07 PM",
    "Louis Mikan - Dime Que No.mp3*3220851*9/9/2020 11:34:13 PM",
    "Manu Manu - Yeah.mp3*8275677*9/9/2020 11:34:19 PM",
    "Yomil  Y El Dany - Que Pena.mp3*3538500*9/9/2020 11:35:13 PM",
    "Yomil Y Dany Ft Kimiko &amp; Yordy - Pasado.mp3*8694019*9/9/2020 11:35:18 PM",
    "Yomil Y EL Dany Ft Chacal Y El Micha - Mi Pikete.mp3*10222705*9/9/2020 11:35:18 PM",
    "Yulien Oviedo - El Kiki.mp3*2860356*9/9/2020 11:35:23 PM",
    122751444,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Chacal - Lo Mas Reciente(2020)()*0*9/13/2020 9:36:00 PM",
    "01.Chacal Ft Tempo - Me Quiero Perder.mp3*3766906*9/9/2020 11:29:13 PM",
    3766906,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Charles Luis - 2 Locos(2020)()*0*9/13/2020 9:36:00 PM",
    "01.Charles Luis - Besame Lento.mp3*6216566*9/9/2020 11:29:14 PM",
    "02.Charles Luis - Ven A Mi.mp3*3223562*9/9/2020 11:29:34 PM",
    "03.Charles Luis - 2 Locos.mp3*2956104*9/9/2020 11:29:56 PM",
    12396232,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Charlie Anthony (2020)()*0*9/13/2020 9:36:01 PM",
    "Charlie Anthony Ft Leo Murga - PANTY MP3 .mp3*10351026*9/9/2020 11:33:01 PM",
    "Kimiko X Yordy Ft Leo Murga y Charlie Anthony - MULATA.mp3*8779673*9/9/2020 11:34:02 PM",
    19130699,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Chaval x Misa(2020)()*0*9/13/2020 9:36:03 PM",
    "!!! Helinton el Chaval - El Mundele - .mp3*7710808*9/9/2020 11:29:01 PM",
    "!!!Talento!!!El Misa!!!Pro By El Ruso!!.mp3*4462891*9/9/2020 11:29:01 PM",
    "#DE TOEL NEGRITOLOS 0 ONEkartel music...mp3*7940127*9/9/2020 11:29:06 PM",
    "A CINTURIARRRR EL MISA X EL CHAVAL PROD BY EL RUSO.mp3*3871659*9/9/2020 11:32:45 PM",
    "INTRO.mp3*2493420*9/9/2020 11:33:50 PM",
    "LA PATICA EN EL AIRE.mp3*2963222*9/9/2020 11:34:05 PM",
    "SEXO Y ALCOHOL.mp3*2822787*9/9/2020 11:34:50 PM",
    32264914,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Chris Tamayo - S E E N(2020)()*0*9/13/2020 11:34:04 PM",
    "01.Chris Tamayo - La Primera Vez( S E E N ).mp3*4695759*9/9/2020 11:29:14 PM",
    "02.Chris Tamayo - Marquesina( S E E N ).mp3*4549682*9/9/2020 11:29:34 PM",
    "03.Chris Tamayo - Peliculeo( S E E N ).mp3*4195461*9/9/2020 11:29:56 PM",
    "04.Chris Tamayo - Cuarentena.mp3*3074984*9/9/2020 11:30:14 PM",
    "05.Chris Tamayo - Hello.mp3*4592176*9/9/2020 11:30:32 PM",
    "06.Chris Tamayo Ft Nicole Manzo &amp; Quique - Presidente.mp3*7047582*9/9/2020 11:30:47 PM",
    "07.Chris Tamayo Ft Kadel - Invierno.mp3*5416600*9/9/2020 11:31:02 PM",
    "08.Chris Tamayo - Mátame.mp3*3806812*9/9/2020 11:31:14 PM",
    "09.Chris Tamayo - Ratito.mp3*4957681*9/9/2020 11:31:26 PM",
    42336737,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Christopher Simpson &amp; Elevación - Absolución 2019 (Bis Music)*0*9/13/2020 11:34:03 PM",
    "01 Hiéreme.mp3*7637144*9/9/2020 11:29:10 PM",
    "02 Rascacielos de Dolor.mp3*10108184*9/9/2020 11:29:32 PM",
    "03 Jugando a ser Tu Duda.mp3*7828184*9/9/2020 11:29:52 PM",
    "04 Vela  Mi Feat. Franko.mp3*10095704*9/9/2020 11:30:11 PM",
    "05 La Mentira Feat. Annie Garcés.mp3*6722264*9/9/2020 11:30:30 PM",
    "06 Elevación del Silencio.mp3*9315224*9/9/2020 11:30:44 PM",
    "07 Danielle.mp3*11656664*9/9/2020 11:30:58 PM",
    "08 Matices en el Cristal Feat. Tiago Felipe.mp3*9232664*9/9/2020 11:31:13 PM",
    "09 Un Año Atrás Feat. Maylin Quintana.mp3*7475864*9/9/2020 11:31:24 PM",
    "10 Mi Absolución Feat. Dúo Iris.mp3*9946905*9/9/2020 11:31:35 PM",
    "11 Respírame Feat. Jan Cruz (Bonus Track).mp3*9067545*9/9/2020 11:31:45 PM",
    "12 Jugando a ser tu duda (Versión Alternativa).mp3*7866585*9/9/2020 11:31:51 PM",
    "13 Elevación del Silencio (Sesión de Estudio).mp3*8879385*9/9/2020 11:31:57 PM",
    "14 Hiéreme (Sesión de Estudio).mp3*11353305*9/9/2020 11:32:02 PM",
    127185621,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Christopher Simpson &amp; Elevación - Lo Más Reciente (2020)*0*9/13/2020 11:24:59 PM",
    "Christopher Simpson &amp; Elevación - Latiendo (2020).mp3*5412384*9/9/2020 11:33:03 PM",
    "Christopher Simpson &amp; Elevación - Lejos.mp3*5109402*9/9/2020 11:33:04 PM",
    "Christopher Simpson &amp; Elevación - Sublime Gracia.mp3*6447215*9/9/2020 11:33:04 PM",
    16969001,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Chuchi Diamond - Quitarnos Las Ganas(2020)()*0*9/13/2020 10:41:02 PM",
    "Chuchi Diamond - Quitarnos Las Ganas.mp3*2143969*9/9/2020 11:33:05 PM",
    2143969,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Classico(2020)()*0*9/13/2020 11:33:40 PM",
    "Classico -  Soy un 13  -  soy un 13.mp3*8467314*9/9/2020 11:33:05 PM",
    "Classico - Ayer Hubo Disco - .mp3*8310580*9/9/2020 11:33:05 PM",
    "Classico - Cositas Malas  - .mp3*8849747*9/9/2020 11:33:05 PM",
    "Classico - Hoy soy yo  -  .mp3*9457878*9/9/2020 11:33:05 PM",
    "Classico - Malianteo  -  .mp3*8011739*9/9/2020 11:33:05 PM",
    "Classico - No descargamos  - .mp3*8431788*9/9/2020 11:33:06 PM",
    "Classico - Nos descargamos  - .mp3*8431788*9/9/2020 11:33:06 PM",
    "Classico - Ya te supere  -  .mp3*7393159*9/9/2020 11:33:06 PM",
    "Classico ft Ale Global - Historia -  .mp3*8734808*9/9/2020 11:33:07 PM",
    "Classico ft BBy Joshua - No Love  -  .mp3*8659575*9/9/2020 11:33:07 PM",
    84748376,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Creivi - Lo Mas Reciente()*0*9/13/2020 9:57:05 PM",
    "Creivi- Donde está.mp3*2814377*9/9/2020 11:33:08 PM",
    "Creivi ft Fory- Lento.mp3*3155850*9/9/2020 11:33:09 PM",
    "Creivi- Ram Pa Pa Pam.mp3*3142057*9/9/2020 11:33:09 PM",
    9112284,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\D´Lujo(Big Gaby &amp; El Filio) - Master Card(Contrataciones 53972754-76980111)*0*9/13/2020 10:24:15 PM",
    "01.D´Lujo(Big Gaby &amp; El Filio) Ft Waldo Mendoza - Me Prometi.mp3*8465259*9/9/2020 11:29:14 PM",
    "02.D´Lujo(Big Gaby &amp; El Filio) - Errores(53972754-76980111).mp3*8883184*9/9/2020 11:29:34 PM",
    "03.D´Lujo(Big Gaby &amp; El Filio) - Master Card.mp3*8287592*9/9/2020 11:29:56 PM",
    25636035,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\David &amp; Out Side - Me Hizo El Amor(2020)(FUSION)*0*9/13/2020 9:57:05 PM",
    "David &amp; Out Side - Me Hizo El Amor ( Pro M-music).mp3*7525659*9/9/2020 11:33:12 PM",
    7525659,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\David &amp; Out Side - Pendiente(2020)(FUSION)*0*9/13/2020 9:57:05 PM",
    "01.David &amp; Out Side - Pendiente(Oficina Secreta- Pro).mp3*10126514*9/9/2020 11:29:14 PM",
    "02.David &amp; Out Side Ft Mayco D Alma - Esto Esta Bueno.mp3*8846389*9/9/2020 11:29:35 PM",
    "03.David &amp; Out Side - Boquita Chula.mp3*8615401*9/9/2020 11:29:56 PM",
    "04.David &amp; Out Side - Solita Pa Mi.mp3*9086761*9/9/2020 11:30:14 PM",
    "05.David &amp; Out Side - Hasta Afuera(prod-black Diamond rec.).mp3*8595759*9/9/2020 11:30:33 PM",
    "06.David &amp; Out Side - Seguire(14k.prod black Diamond)..mp3*8857853*9/9/2020 11:30:48 PM",
    "07.David &amp; Out Side - Tu La Pasas Bailando.mp3*4075761*9/9/2020 11:31:02 PM",
    "08.David &amp; Out Side - Soy Yo.mp3*10154280*9/9/2020 11:31:14 PM",
    68358718,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Dayan(2020)()*0*9/13/2020 10:34:17 PM",
    "1585627200TLC DAYAN(YA YO)LION MUSIC .mp3*2915415*9/9/2020 11:32:38 PM",
    "1585627200TLCDAYANLACARADESCECHABLEPRODBYYAMILKOM.mp3*2651991*9/9/2020 11:32:39 PM",
    "1585627200TLCDAYANLADRONAPRODBYYAMILKOMLIONMUSIC.mp3*3070935*9/9/2020 11:32:39 PM",
    "1585627200TLCDAYANXYAMILKOMKUCHIMBANLIONMUSIC.mp3*3026775*9/9/2020 11:32:39 PM",
    "1585886400HARRYSONXTLCDAYANXRIGELPROFESECARGARON2020MASTER.mp3*2891282*9/9/2020 11:32:39 PM",
    "1586577600TLCDayanxTikkoxRigelElProfeMalaCostumbre.mp3*2848650*9/9/2020 11:32:40 PM",
    "1586836800TLCDayanxTikkoxRigelElProfeMalaCostumbrewwwseponeweno.mp3*2848650*9/9/2020 11:32:40 PM",
    "1590984000TLCDAYANELBUENOYELMALOwwwseponewenonatcu.mp3*1923159*9/9/2020 11:32:40 PM",
    "1590984000TLCDAYANXLADIOSAXJOAKO369MeVoyPaLaCallewww.mp3*2038750*9/9/2020 11:32:40 PM",
    "1591156800RIGELXTLCDAYANOJOCONESOwwwseponewenonatcu.mp3*2500491*9/9/2020 11:32:41 PM",
    "TLC DAYAN X LA DIOSA X JOAKO369 - Me Voy Pa La Calle .mp3*2703200*9/9/2020 11:34:58 PM",
    29419298,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Eduanis(2020)()*0*9/13/2020 9:48:36 PM",
    "Eduanis  -  Todo x Ti  -  .mp3*7986661*9/9/2020 11:33:15 PM",
    7986661,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Bory En La Ksa(2020)()*0*9/13/2020 9:48:37 PM",
    "El Bory EnLa KSA- Como se Puede.mp3*9510084*9/9/2020 11:33:17 PM",
    "El Bory EnLa KSA- Donde estaba yo.mp3*3441316*9/9/2020 11:33:17 PM",
    "El Bory EnLa KSA- Ella no Quiere Na.mp3*3379458*9/9/2020 11:33:18 PM",
    "El Bory EnLa KSA- Mami.mp3*8874519*9/9/2020 11:33:18 PM",
    "El Bory EnLa KSA- No me Trates así.mp3*3328049*9/9/2020 11:33:18 PM",
    "El Bory EnLa KSA- Que puedo Hacer Yo.mp3*3369845*9/9/2020 11:33:18 PM",
    "El Bory EnLa KSA- Ya tu no me Quiere.mp3*8603799*9/9/2020 11:33:18 PM",
    "El Bory EnLa KSA- Yo no te Compro Cerveza.mp3*3053868*9/9/2020 11:33:19 PM",
    43560938,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Carli - Hasta Afuera(2020)()*0*9/13/2020 9:48:38 PM",
    "01.El Uniko Ft El Yowa &amp; El Carli - No Hay Amor.mp3*5684303*9/9/2020 11:29:16 PM",
    "02.El Taiger Ft El Carli X El Yowa - Te Nesecito.mp3*5084949*9/9/2020 11:29:36 PM",
    "03.El Kamel Ft El Carli,Yowa &amp; Kcho - Con Otra.mp3*4409313*9/9/2020 11:29:57 PM",
    "04.Anubix Ft El Carli - Hasta Afuera.mp3*4963950*9/9/2020 11:30:14 PM",
    "05.El Enviado Ft El Carli x El Yowa - Pa Mi Cama.mp3*4431679*9/9/2020 11:30:33 PM",
    "06.El Negrito &amp; Kokito Ft El Carli - De 7 A 12.mp3*5323813*9/9/2020 11:30:48 PM",
    "07.Carluchi &amp; TLC Dayan - Puro Veneno.mp3*2884374*9/9/2020 11:31:01 PM",
    "08.El Carli - El Que La Hace La Paga.mp3*5151405*9/9/2020 11:31:14 PM",
    "09.El Carli - Taste(Spanish Versión).mp3*2140427*9/9/2020 11:31:26 PM",
    "10.El Carli X El Yowa - La Flor.mp3*4589668*9/9/2020 11:31:37 PM",
    "11.El Carli X El Yowa - Riega La Bola.mp3*4269909*9/9/2020 11:31:47 PM",
    "12.El Carli X El Yowa Ft Lei X Dandy - Loma Abajo.mp3*5316290*9/9/2020 11:31:53 PM",
    "13.Yakarta Ft El Carli &amp; El Yowa &amp; El Los De Los 90 - Pa Que Decirte.mp3*4769599*9/9/2020 11:32:01 PM",
    "14.El Carli Ft Eddy K - Eco.mp3*2962762*9/9/2020 11:32:03 PM",
    "15.El Carli - Háblame De Tengo Tengo.mp3*2741452*9/9/2020 11:32:07 PM",
    64723893,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Chiko De La Calle - De La Que Te Salvaste(2020)()*0*9/13/2020 9:48:39 PM",
    "01.El Chiko De La Calle - De Prende En Prende.mp3*7958007*9/9/2020 11:29:15 PM",
    "02.El Chiko De La Calle - Es Verdad.mp3*8047671*9/9/2020 11:29:35 PM",
    "03.El Chiko De La Calle - Lo Repa Se Te Salio.mp3*9098238*9/9/2020 11:29:56 PM",
    "04.El Chiko De La Calle - De La Que Te Salvaste.mp3*4730530*9/9/2020 11:30:15 PM",
    "05.El Chiko De La Calle - Jicamo No Timbales.mp3*8919639*9/9/2020 11:30:33 PM",
    38754085,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Ciudadano(2020)()*0*9/13/2020 9:48:39 PM",
    "cosa de loco master.mp3*8387076*9/9/2020 11:33:08 PM",
    "El amor pa mi no vale .mp3*7927038*9/9/2020 11:33:17 PM",
    "inocente.mp3*7511358*9/9/2020 11:33:50 PM",
    "Si te vas.mp3*8120958*9/9/2020 11:34:51 PM",
    31946430,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Dairan x El Talento(2020)()*0*9/13/2020 9:48:39 PM",
    "El Dairan x El Talento -La Tu Gevita   - .mp3*8228033*9/9/2020 11:33:20 PM",
    8228033,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Duro - Yo Soy El Duro(Adelanto)(2020)()*0*9/13/2020 9:48:40 PM",
    "01.El Duro Ft Eddy-K - Quiere Bailar.mp3*8048919*9/9/2020 11:29:15 PM",
    "02.El Duro Ft La Diosa - Tu Te Enamoras.mp3*4597191*9/9/2020 11:29:35 PM",
    "03.El Duro - Mujeres Pierdan El Control.mp3*7362519*9/9/2020 11:29:57 PM",
    20008629,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\EL Kaiser ft El Dairan x El Talento(2020)()*0*9/13/2020 9:48:40 PM",
    "El Dairan x El Talento ft El Kaiser -La Puntica 3  - .mp3*8741078*9/9/2020 11:33:20 PM",
    8741078,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Kapoland(2020)()*0*9/13/2020 11:19:32 PM",
    "-               La Verdad - El Kapoland.mp3*2326094*9/9/2020 11:28:59 PM",
    "-           La Carita 2 Ennis &amp; El KapolandHigh.mp3*2259963*9/9/2020 11:28:59 PM",
    "-      Pintalo - El Kapoland.mp3*2903903*9/9/2020 11:28:59 PM",
    "- Eso no se vale - El Kapoland.mp3*9727505*9/9/2020 11:28:59 PM",
    "- Real hasta la Muerte - El Kapoland.mp3*3054787*9/9/2020 11:28:59 PM",
    "- Verde - El Kapoland.mp3*3253735*9/9/2020 11:28:59 PM",
    "!!!KimboteoDjHondaXElKapolandXYoanElCashXEnigmaXEl.mp3*4162796*9/9/2020 11:29:01 PM",
    "01. El Kapoland - El VerdeBy Honda ♫♪.mp3*3253720*9/9/2020 11:29:11 PM",
    "02. El Kapoland - La Turun Tun TunBy Honda ♫♪.mp3*2741302*9/9/2020 11:29:32 PM",
    "03. El Kapoland feat. Dj Unic (Célula music) - La Recarga.mp3*2765892*9/9/2020 11:29:54 PM",
    "04. El Kapoland - PitaloBy Honda ♫♪.mp3*2903913*9/9/2020 11:30:13 PM",
    "05. El Kapoland - Real Hasta La MuerteBy Honda ♫♪.mp3*3054825*9/9/2020 11:30:32 PM",
    "06ElKapolandXYoanElCashXEnigmaXElCondeXDjHondaKimboteo.mp3*4162781*9/9/2020 11:30:54 PM",
    "07. El Kapoland feat. Dj Unic (Célula music) -La Policía.mp3*7697311*9/9/2020 11:31:01 PM",
    "08. El kapoland - No Se Vale (Prod.ElectroBeat).mp3*9727523*9/9/2020 11:31:13 PM",
    "09ElKapolandLaVerdadProdbyNinfactory&amp;ElectroBeat.mp3*7640499*9/9/2020 11:31:30 PM",
    "10. El Kapoland  - Guapo NaturalBy Honda ♫♪.mp3*2698291*9/9/2020 11:31:36 PM",
    "El Kapoland - Loca.mp3*7978770*9/9/2020 11:33:22 PM",
    "El Kapoland - Turuntuntun.mp3*2741317*9/9/2020 11:33:22 PM",
    "El Kapoland-Lo mio es mio.mp3*3007975*9/9/2020 11:33:22 PM",
    "EL KPOLAND - GUAPO NATURALBy Honda.mp3*2698267*9/9/2020 11:33:22 PM",
    "EL KPOLAND - LA TURN TUN TUNBy Honda.mp3*2741317*9/9/2020 11:33:22 PM",
    "EL KPOLAND - LO MIO ES MIOBy Honda.mp3*3007975*9/9/2020 11:33:22 PM",
    "EL KPOLAND - PITALOBy Honda.mp3*2903903*9/9/2020 11:33:22 PM",
    "EL KPOLAND - REAL ASTA LA MUERTEBy Honda.mp3*3054787*9/9/2020 11:33:23 PM",
    "EL KPOLAND - SE ACABO EL AMORBy Honda.mp3*2699939*9/9/2020 11:33:23 PM",
    "EL KPOLAND - VERDEBy Honda.mp3*3253735*9/9/2020 11:33:23 PM",
    "KimboteoDjHondaElKapolandYoanElCashEnigmaElCrack&amp;EL.mp3*4235733*9/9/2020 11:34:01 PM",
    "KimboteoDjHondaXElKapolandXYoanElCashXEnigmaXEl.mp3*4162796*9/9/2020 11:34:01 PM",
    "Los King Boys x Kapolan En Tu Mente.mp3*4469923*9/9/2020 11:34:12 PM",
    "Olvidame que te olvide - El Kapoland.mp3*2699939*9/9/2020 11:34:28 PM",
    123991216,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Lucky Ta Loco - Dembow A Lo Cubano 2k20 (Adelanto 3)(2020)*0*9/13/2020 11:19:33 PM",
    "01-El Lucky Ta Loco - No Me Tumban.mp3*7885093*9/9/2020 11:29:25 PM",
    "02-El Lucky Ta Loco - Agoniza(Contigo Me Voy De Rumba).mp3*9158073*9/9/2020 11:29:44 PM",
    "03-El Lucky - Eva Tiene Dos Caras.mp3*8176933*9/9/2020 11:30:05 PM",
    "04-El Lucky Ta Loco - Salud.mp3*9667813*9/9/2020 11:30:23 PM",
    34887912,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Mayoral - Tu No Te Escapas(2020)()*0*9/13/2020 10:40:49 PM",
    "01.El Mayoral - Tu No Te Escapas.mp3*4176091*9/9/2020 11:29:15 PM",
    "02.El Mayoral - Dandola.mp3*6777714*9/9/2020 11:29:36 PM",
    "03.El Mayoral - Mi Pieza.mp3*2653661*9/9/2020 11:29:57 PM",
    13607466,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Miki x El Way(2020)()*0*9/13/2020 9:52:04 PM",
    "El Miki x El Way - Te buscaste un problemita - .mp3*7275086*9/9/2020 11:33:24 PM",
    7275086,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Padrino Del Genero - Lo Mas Reciente(2020)()*0*9/13/2020 11:30:17 PM",
    "01.El Padrino Del Genero Ft Jeikel - Mojaito 2.mp3*4779323*9/9/2020 11:29:15 PM",
    "02.El Padrino Del Genero - A La Moda.mp3*3901845*9/9/2020 11:29:36 PM",
    "03.El Padrino Del Genero - Mojaito 2 Ft Heikel.mp3*3389845*9/9/2020 11:29:57 PM",
    "04.El Padrino Del Genero - Miami Esta De Party.mp3*3457979*9/9/2020 11:30:16 PM",
    "05.El Padrino Del Genero Ft El Negrito &amp; El Yuka - PutiPuteo.mp3*9485045*9/9/2020 11:30:33 PM",
    "06.El Padrino Del Genero Ft Alvaro La Figura - 12 De La Noche.mp3*6797746*9/9/2020 11:30:49 PM",
    "07.El Padrino Del Genero - Farándula Cubana.mp3*4926961*9/9/2020 11:31:02 PM",
    "08.El Padrino Del Genero Ft NKM01 - Pa Folloni.mp3*5908120*9/9/2020 11:31:14 PM",
    "09.El Padrino Del Genero Ft Osmani Garcia - Mientes.mp3*8262999*9/9/2020 11:31:26 PM",
    "10.El Padrino Del Genero Ft Manu Manu - Menor.mp3*4856117*9/9/2020 11:31:37 PM",
    "11.El Padrino Del Genero - Fiesta Na Ma.mp3*5303074*9/9/2020 11:31:47 PM",
    61069054,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Payaso -(2020)(CUBANO)*0*9/13/2020 11:26:28 PM",
    "(Amigos)(El Payaso) Prod By Dj Johnny + El YeyoMas Music.mp3*8068153*9/9/2020 11:29:06 PM",
    "El PayasoEl Payaso X LeyMas Music Dj Johnny + Yenko2020 !!!.mp3*9447159*9/9/2020 11:33:26 PM",
    "Hablate PiezaEl Payaso X LeyMas Music Dj Johnny + Yenko2020 !!!.mp3*7383639*9/9/2020 11:33:43 PM",
    "Lanchona - El Payaso X Ley.mp3*4190591*9/9/2020 11:34:06 PM",
    "Los King BoysXPayaso X Ley-Cazadera-( Remix )ByForyGalaxy Musik.mp3*8063401*9/9/2020 11:34:12 PM",
    "Payaso - El Payaso X Ley.mp3*7938519*9/9/2020 11:34:35 PM",
    "Payaso X Amenazy X Chavalito X Wasa Wasa X La Aifon Fenix.mp3*8242839*9/9/2020 11:34:36 PM",
    53334301,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Pipi(2020)()*0*9/13/2020 9:52:29 PM",
    "Pipi - Sin Sentimiento - .mp3*9376375*9/9/2020 11:34:38 PM",
    "Pipi x Maikol x Barreiro - Mi Kardasha - .mp3*8454775*9/9/2020 11:34:38 PM",
    17831150,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Pirata ft El Brillante(2020)()*0*9/13/2020 11:21:13 PM",
    "01 Intro El Pirata ft El Brillante - X Musik (Intro).mp3*10212338*9/9/2020 11:29:10 PM",
    "02 El Pirata ft El Brillante - X Musik (Ni mala ni buena).mp3*8593791*9/9/2020 11:29:31 PM",
    "03 El Pirata ft El Brillante - X Musik (No soy perfecto).mp3*10609398*9/9/2020 11:29:52 PM",
    "04 El Pirata ft El Brillante - X Musik (Corazon lastimado).mp3*9741089*9/9/2020 11:30:10 PM",
    "04 El Pirata ft El Brillante - X Musik (Que decida ella).mp3*8614689*9/9/2020 11:30:10 PM",
    "05 El Pirata ft El Brillante - X Musik (Y me enamore).mp3*9261480*9/9/2020 11:30:30 PM",
    "06 El Pirata ft El Brillante - X Musik (Hasta el piso).mp3*8256289*9/9/2020 11:30:44 PM",
    "07 El Pirata ft El Brillante - X Musik (Amor sin cura).mp3*9647048*9/9/2020 11:30:59 PM",
    "07 El Pirata ft El Brillante - X Musik (La nena mia).mp3*8357644*9/9/2020 11:30:59 PM",
    "08 El Pirata ft El Brillante - X Musik (Tengo).mp3*8008647*9/9/2020 11:31:11 PM",
    "08 El Pirata ft El Brillante ft Ciey- X Musik (Me Llamas).mp3*10064682*9/9/2020 11:31:11 PM",
    "09 El Pirata ft El Brillante - X Musik ( pal Face).mp3*8447504*9/9/2020 11:31:24 PM",
    "10 El Pirata ft El Brillante - X Musik (Amor sin cura).mp3*9647048*9/9/2020 11:31:34 PM",
    "12 El Pirata ft El Brillante - X Musik (Malas lenguas).mp3*10477742*9/9/2020 11:31:51 PM",
    "13 El Pirata ft El Brillante - X Musik (Sexo Bruto).mp3*9537334*9/9/2020 11:31:57 PM",
    "2- Amor Sin Cura - ( JP.El Pirata).mp3*9647048*9/9/2020 11:29:45 PM",
    "3- Enamorate de mi - ( JP.El Pirata ).mp3*7810116*9/9/2020 11:30:06 PM",
    "4- Te AmoYami &amp; BIG Jc Ft JP El Pirata++.mp3*3287543*9/9/2020 11:30:24 PM",
    "5- MadreJP El Pirata &amp; BIG Jc++.mp3*3038439*9/9/2020 11:30:40 PM",
    "DISPUESTAELPIRATE &amp; EL BRILLANTEpro byHALEX THE BEST clean sound records ok.mp3*8604196*9/9/2020 11:33:13 PM",
    "X Musik - El Pirata &amp; El Brillante (Robarte un beso).wav*36514844*4/14/2019 6:55:11 PM",
    208378909,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\EL Real Y La Espiral(2020)()*0*9/13/2020 11:21:13 PM",
    "01-CUANDO MUERA {CrazY-72670089}.mp3*4762713*9/9/2020 11:29:25 PM",
    "02-FIESTA AND PARTY {CrazY-72670089}.mp3*7837203*9/9/2020 11:29:44 PM",
    "03-MENTIRA {CrazY-72670089}.mp3*9448707*9/9/2020 11:30:05 PM",
    "04-MI VECINA {CrazY-72670089}.mp3*8959959*9/9/2020 11:30:24 PM",
    "05-PALO BOMBO {CrazY-72670089}.mp3*9877719*9/9/2020 11:30:39 PM",
    "06-PENSARAS EN MI {CrazY-72670089}.mp3*11415639*9/9/2020 11:30:54 PM",
    "07-QUE TU TIENES {CrazY-72670089}.mp3*4346679*9/9/2020 11:31:07 PM",
    "08-SOY ASI {CrazY-72670089}.mp3*3876879*9/9/2020 11:31:19 PM",
    "09-LAGRIMAS NEGRAS {CrazY-72670089}.mp3*4716255*9/9/2020 11:31:30 PM",
    "10-SE TE FUE LA ROSCA {CrazY-72670089}.mp3*10716123*9/9/2020 11:31:42 PM",
    "11-AMIGO DE QUE {CrazY-72670089}.mp3*4333660*9/9/2020 11:31:50 PM",
    "12-VERDADERO - OKANA {CrazY-72670089}.mp3*5174345*9/9/2020 11:31:57 PM",
    "13-SABOR A MI TEMA {CrazY-72670089}.mp3*8485719*9/9/2020 11:32:01 PM",
    "15-GOOD BYE {CrazY-72670089}.mp3*8355159*9/9/2020 11:32:08 PM",
    "mp3tag.m3u*854*8/10/2020 1:25:32 AM",
    102307613,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\EL Repa x EL Deny(2020)()*0*9/13/2020 10:33:45 PM",
    "El Repa x El Deni ft Orland - Tas Carga -  .mp3*8749437*9/9/2020 11:33:26 PM",
    "El Repa x El deni x El bolo - Tuno sientes 2 -  (2).mp3*6813241*9/9/2020 11:33:27 PM",
    "El Repa x El deni x El bolo - Tuno sientes 2 - .mp3*6813241*9/9/2020 11:33:27 PM",
    "EL Repa x EL Deny ft Chocolito - Tu no sientes - .mp3*7453763*9/9/2020 11:33:27 PM",
    "El repa x EL Deny -Tu no tienes - .mp3*7677371*9/9/2020 11:33:27 PM",
    "JC. El Cesar x El Repa x El Bolo x Un Denis - Fuego con L a Candelita -  (2).mp3*9159037*9/9/2020 11:33:55 PM",
    "JC. El Cesar x El Repa x El Bolo x Un Denis - Fuego con L a Candelita - .mp3*9159037*9/9/2020 11:33:55 PM",
    55825127,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Ricky &amp; El Yanky - CON CLASE(2020)()*0*9/13/2020 11:13:22 PM",
    "01.El Ricky &amp; El Yanky - Hasta El Final.mp3*6576663*9/9/2020 11:29:16 PM",
    "02.El Ricky &amp; El Yanky - Amigo De Que.mp3*7165479*9/9/2020 11:29:36 PM",
    "03.Harryson Ft El Ricky &amp; El Yanky - Tu Tanke.mp3*7568478*9/9/2020 11:29:59 PM",
    "04.El Ricky &amp; El Yanky - Para Mi Casita.mp3*7524615*9/9/2020 11:30:16 PM",
    "05.El Ricky &amp; El Yanky - Tactea.mp3*6575619*9/9/2020 11:30:33 PM",
    "06.El Ricky &amp; El Yanky - Tu Tanke Soy Yo.mp3*7568463*9/9/2020 11:30:49 PM",
    "07.El Ricky &amp; El Yanky - La Hija Del Cabo.mp3*6474519*9/9/2020 11:31:02 PM",
    "08.El Ricky &amp; El Yanky Ft El Doni - Publicalo.mp3*6504654*9/9/2020 11:31:15 PM",
    "09.El Ricky &amp; El Yanky - Q Fue Lo Q Fumates.mp3*7935951*9/9/2020 11:31:26 PM",
    63894441,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\EL TIKKO - Estrenos(2020)()*0*9/13/2020 10:42:40 PM",
    "01.Wildey Ft El TIKKO - Lo Malito Que Tu Tienes.mp3*8909572*9/9/2020 11:29:24 PM",
    "02.EL TIKKO Ft El Micha - Partete Toa.mp3*8613600*9/9/2020 11:29:36 PM",
    "03.EL TIKKO - Dame Lo Mio.mp3*3026530*9/9/2020 11:29:58 PM",
    "04.EL TIKKO x EL Kimiko &amp; Yordy - Tu Recuerdo.mp3*7715037*9/9/2020 11:30:17 PM",
    28264739,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\EL TIKKO - Los Buenos Son Buenos(2020)()*0*9/13/2020 9:53:46 PM",
    "01.EL TIKKO - Los Buenos Son Buenos(prodbyElZur2)ZonaMusic.mp3*3883143*9/9/2020 11:29:16 PM",
    "02.EL TIKKO - Que Tu Te Piensas prodbyElZur2.mp3*4114911*9/9/2020 11:29:36 PM",
    "03.EL TIKKO - No Te Da.mp3*3688959*9/9/2020 11:29:58 PM",
    "04.EL TIKKO - El Cuerpo Lo Sabe prodbyElZur2.mp3*4283517*9/9/2020 11:30:17 PM",
    "05.EL TIKKO - El Edificio prodbyElZur2.mp3*4403055*9/9/2020 11:30:34 PM",
    "06.EL TIKKO - Tu Me Das Risa(prodbyElZur2)OFFIALAUDIO.mp3*7240614*9/9/2020 11:30:49 PM",
    "07.EL TIKKO - Currukuku prodbyElZur2.mp3*8127988*9/9/2020 11:31:03 PM",
    "08.EL TIKKO - Un TembaprodbyElZur2.mp3*3255699*9/9/2020 11:31:15 PM",
    "09.EL TIKKO - Hazmelo Riko prodbyElZur2.mp3*3333999*9/9/2020 11:31:26 PM",
    "10.EL TIKKO - El Hotel.mp3*6696279*9/9/2020 11:31:37 PM",
    "11.EL TIKKO - Tu Me Comprendes prodbyElZur2.mp3*3948393*9/9/2020 11:31:47 PM",
    "12.EL TIKKO - Un Sueño MaloprodbyElZur2.mp3*3981801*9/9/2020 11:31:54 PM",
    56958358,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Tuly De Los Sitios - Demo(2020)()*0*9/13/2020 9:53:47 PM",
    "El Tuly De Los Sitios - Perdóname.mp3*6215394*9/9/2020 11:33:30 PM",
    "El Tuly De Los Sitios - Sigue Tu Camino.mp3*7178199*9/9/2020 11:33:31 PM",
    "El Tuly De Los Sitios - Yo Te Amo.mp3*4222443*9/9/2020 11:33:31 PM",
    "El Tuly De Los Sitios Ft El Pirata - La P..A.mp3*7525659*9/9/2020 11:33:31 PM",
    "El Tuly De Los Sitios Ft El Yabo Y Adonis - A Descochar.mp3*7285539*9/9/2020 11:33:32 PM",
    "El Tuly De Los Sitios Y El Favy - Eres Tu.mp3*3202455*9/9/2020 11:33:32 PM",
    "El Tuly De Los Sitios Y El Favy - No Te Supe Cuidar.mp3*7005747*9/9/2020 11:33:32 PM",
    "El Tuly Dw Los Sitios Y El Favy - El Amor De Mi Vida.mp3*7136247*9/9/2020 11:33:32 PM",
    "ElTulyDeLosSitiosYElFavyFtReinielitoNaQueVerContigo.mp3*3958311*9/9/2020 11:33:34 PM",
    53729994,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\El Yoa(2020)()*0*9/13/2020 11:20:00 PM",
    "1ElYoalavidaesUnCachumbambéprodbyAdvancedStudio.wav*29198386*7/19/2020 8:27:38 PM",
    "2.EL YOA -me tiene sin cuidado.wav*31638572*7/19/2020 8:27:43 PM",
    "3.El Yoa - Maricusa.wav*36497282*7/19/2020 8:28:21 PM",
    "4.El Yoa - Enfocadita(prod. by Advanced Studio).wav*31753146*7/19/2020 8:26:21 PM",
    "5SILEVAADARQUELEDEELYOAftKOKIELKANOProdByWanted.wav*35085356*7/19/2020 8:25:11 PM",
    "6LLENADEODIOELYOAftHARRISONProdByWantedMusic2k20.wav*35553988*7/19/2020 8:25:20 PM",
    "7.El Yoa - Bandolera(prod. by Advanced Studio).wav*34307902*7/19/2020 8:25:13 PM",
    "8PALCARAJOELYOAftHARRISONProdByWantedMusic2k20.wav*30353128*7/19/2020 8:24:15 PM",
    264387760,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\En Las Nubes - Miny Cuore(2020)()*0*9/13/2020 9:53:49 PM",
    ".SAL DE ARRIBA DE MICOSA MIA X MINY CUORE X MAX PRO MUSIC.mp3*49495*9/9/2020 11:29:07 PM",
    49495,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Enzo La Melodia Secreta - Ponte CD1(2020)()*0*9/13/2020 11:22:50 PM",
    "01.Enzo La Melodia Secreta - Intro - Adrian Fernandez.mp3*1763799*9/9/2020 11:29:16 PM",
    "02.Enzo La Melodia Secreta Ft Chacal - El Mentiroso.mp3*5236834*9/9/2020 11:29:37 PM",
    "03.Enzo La Melodia Secreta Ft Jacob Forever - Ponte(Remix).mp3*5088084*9/9/2020 11:29:58 PM",
    "04.Enzo La Melodia Secreta - 90 Millas.mp3*7458988*9/9/2020 11:30:17 PM",
    "05.Enzo La Melodia Secreta - Amante.mp3*9669015*9/9/2020 11:30:34 PM",
    "06.Enzo La Melodia Secreta Ft DJ Desco - Alcoholica.mp3*4481345*9/9/2020 11:30:50 PM",
    "07.Enzo La Melodia Secreta Ft Amilkele El Ministro - Adiccion.mp3*9723282*9/9/2020 11:31:03 PM",
    "08.Enzo La Melodia Secreta - Apaga La Luz.mp3*6606389*9/9/2020 11:31:15 PM",
    "09.Enzo La Melodia Secreta Ft El Kamel - A La Cara.mp3*4718865*9/9/2020 11:31:26 PM",
    "10.Enzo La Melodia Secreta - Amor Ilegal.mp3*3393032*9/9/2020 11:31:37 PM",
    "11.Enzo La Melodia Secreta Ft Popy &amp; La Moda - Bebesita.mp3*3440261*9/9/2020 11:31:47 PM",
    "12.Enzo La Melodia Secreta Ft Amilkele El Ministro - Anda Cuemtale.mp3*7962680*9/9/2020 11:31:54 PM",
    "13.Enzo La Melodia Secreta - Dame Una Otunidad.mp3*7795479*9/9/2020 11:31:58 PM",
    "14.Enzo La Melodia Secreta Ft El Troyano - 2 Mujeres.mp3*4628101*9/9/2020 11:32:03 PM",
    "15.Enzo La Melodia Secreta - Descarada Sin Cara.mp3*3647987*9/9/2020 11:32:07 PM",
    "16.Enzo La Melodia Secreta - Esa Boquita.mp3*3604101*9/9/2020 11:32:09 PM",
    "17.Enzo La Melodia Secreta - El Viernes.mp3*9511235*9/9/2020 11:32:12 PM",
    "18.Enzo La Melodia Secreta Ft El Enviado - Forever.mp3*3598668*9/9/2020 11:32:14 PM",
    "19.Enzo La Melodia Secreta - La Cintura.mp3*3642135*9/9/2020 11:32:16 PM",
    "20.Enzo La Melodia Secreta Ft Yakarta - Infieles.mp3*3567321*9/9/2020 11:32:17 PM",
    "21.Enzo La Melodia Secreta - Snapchat.mp3*5386372*9/9/2020 11:32:18 PM",
    "22.Enzo La Melodia Secreta - Mala.mp3*3872013*9/9/2020 11:32:20 PM",
    "23.Enzo La Melodia Secreta - Vente.mp3*3028989*9/9/2020 11:32:21 PM",
    "24.Enzo La Melodia Secreta Ft Amilkele El Ministro Ondure x Franny - La Pasion.mp3*4791941*9/9/2020 11:32:21 PM",
    "25.Enzo La Melodia Secreta Ft Osmani Garcia &amp; La Musa -  Tu Life.mp3*3850279*9/9/2020 11:32:23 PM",
    "26.Enzo La Melodia Secreta - Tengo Ache.mp3*2695040*9/9/2020 11:32:23 PM",
    "27.Enzo La Melodia Secreta - Mojadito.mp3*3275167*9/9/2020 11:32:24 PM",
    "28.Enzo La Melodia Secreta Ft Genio &amp; Dairony - Osorbo Que Esto Es Polvo.mp3*8638678*9/9/2020 11:32:25 PM",
    145076080,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Enzo La Melodia Secreta - Ponte CD2(2020)()*0*9/13/2020 11:25:37 PM",
    "01.Enzo La Melodia Secreta - Intro 2 - Adrian Fernnadez.mp3*6858543*9/9/2020 11:29:16 PM",
    "02.Enzo La Melodia Secreta - Mi Diablita.mp3*3350818*9/9/2020 11:29:36 PM",
    "03.Enzo La Melodia Secreta - Loca  El Dembow.mp3*6441945*9/9/2020 11:29:58 PM",
    "04.Enzo La Melodia Secreta - Para Ti.mp3*5372931*9/9/2020 11:30:17 PM",
    "05.Enzo La Melodia Secreta - Otra.mp3*3352908*9/9/2020 11:30:34 PM",
    "06.Enzo La Melodia Secreta Ft Moikan - Matala.mp3*3504209*9/9/2020 11:30:50 PM",
    "07.Enzo La Melodia Secreta - Luz.mp3*3945156*9/9/2020 11:31:03 PM",
    "08.Enzo La Melodia Secreta - Prohibido.mp3*3724473*9/9/2020 11:31:15 PM",
    "09.Enzo La Melodia Secreta - Para Mi.mp3*3379239*9/9/2020 11:31:26 PM",
    "10.Enzo La Melodia Secreta Ft El Kacho Mc &amp; Womy - Fiesta,Fiesta.mp3*3444859*9/9/2020 11:31:37 PM",
    "11.Enzo La Melodia Secreta - Si Se Puede.mp3*8891543*9/9/2020 11:31:47 PM",
    "12.Enzo La Melodia Secreta Ft Mandy Cantero - Soy Cubano.mp3*9567555*9/9/2020 11:31:54 PM",
    "13.Enzo La Melodia Secreta - Mi Nesecidad.mp3*3284362*9/9/2020 11:31:59 PM",
    "14.Enzo La Melodia Secreta Ft Negrito,Kokito &amp; Manu Manu - No Chirrea.mp3*4928614*9/9/2020 11:32:03 PM",
    "15.Enzo La Melodia Secreta Ft x El Conde Fidel - Ya No Mas.mp3*3708173*9/9/2020 11:32:07 PM",
    "16.Enzo La Melodia Secreta Ft El Vallo - Sexo.mp3*3494178*9/9/2020 11:32:10 PM",
    "17.Enzo La Melodia Secreta Ft Sencilla Conexion - Mujeres Sueltas.mp3*3887059*9/9/2020 11:32:12 PM",
    "18.Enzo La Melodia Secreta Ft Limboys - Me Eleva.mp3*3032751*9/9/2020 11:32:14 PM",
    "19.Enzo La Melodia Secreta Ft Yet Garbay - Veneno.mp3*4447125*9/9/2020 11:32:16 PM",
    "20.Enzo La Melodia Secreta - No Quiere Enamorarse.mp3*3734086*9/9/2020 11:32:17 PM",
    "21.Enzo La Melodia Secreta - Un Buen Rato.mp3*7779771*9/9/2020 11:32:19 PM",
    "22.Enzo La Melodia Secreta x Giorgio El Italiano - Se Transforma.mp3*9049322*9/9/2020 11:32:20 PM",
    "23.Enzo La Melodia Secreta - Si Supieras.mp3*3622749*9/9/2020 11:32:21 PM",
    "24.Enzo La Melodia Secreta Ft Gipsy Prince - La Sexta.mp3*3805557*9/9/2020 11:32:22 PM",
    "25.Enzo La Melodia Secreta - Un Beso En 4K.mp3*3143092*9/9/2020 11:32:23 PM",
    "26.Enzo La Melodia Secreta - Vecinita.mp3*2978416*9/9/2020 11:32:23 PM",
    "27.Enzo La Melodia Secreta - Vive La Vida.mp3*3450292*9/9/2020 11:32:24 PM",
    "28.Enzo La Melodia Secreta - Amor En Cuarentena.mp3*5382354*9/9/2020 11:32:25 PM",
    "29.Enzo La Melodia Secreta Ft Lobo King Dowa - Tu Novio.mp3*3807451*9/9/2020 11:32:25 PM",
    "30.Enzo La Melodia Secreta x Harryson - Corazón Partido.mp3*6261402*9/9/2020 11:32:26 PM",
    141630933,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Eviezer(2020)()*0*9/13/2020 9:32:40 PM",
    "Eviezer - Tengo que admitir que te kiero - .mp3*9340849*9/9/2020 11:33:35 PM",
    9340849,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Fayer x Flexxx(2020)()*0*9/13/2020 11:24:29 PM",
    "Fayer x Flex - A solas -  .mp3*6874890*9/9/2020 11:33:37 PM",
    "Fayer x Flex - Culpables -  .mp3*5997175*9/9/2020 11:33:37 PM",
    "Fayer x Flex ft  Alex Global, Dinamiko -No kiere amores  -  .mp3*11928016*9/9/2020 11:33:37 PM",
    "Fayer x Flex -No kiere amores  -  .mp3*7729616*9/9/2020 11:33:37 PM",
    32529697,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Ferri - Ya Es Muy Tarde(2020)*0*9/13/2020 11:21:00 PM",
    "01.Ferri - Ya Es Muy Tarde  (Prod Roit el quimico).mp3*3604395*9/9/2020 11:29:17 PM",
    "02.Ferri - Preso (Prod Roit el quimico).mp3*4873899*9/9/2020 11:29:37 PM",
    "03.Ferri - Casi Terminado (Prod Roit el quimico).mp3*4678671*9/9/2020 11:29:59 PM",
    "04.Ferri - Cuidame (Prod Roit el Quimico).mp3*4605591*9/9/2020 11:30:18 PM",
    "05.Ferri - Llevatelo Todo  (Prod Roit el quimico).mp3*3913941*9/9/2020 11:30:34 PM",
    "06.Ferri - Necio (makete Roit el Quimico).mp3*4313271*9/9/2020 11:30:50 PM",
    "07.Ferri - Se Parte (Prod Roit el quimico).mp3*3906111*9/9/2020 11:31:03 PM",
    "08.Ferri - Supe Que Si (Prod Roit el Quimico).mp3*4918269*9/9/2020 11:31:16 PM",
    "09.Ferri - Verte Otra Vez (Prod Roit el quimico).mp3*4493361*9/9/2020 11:31:27 PM",
    "10.Ferri Ft El Heriero Papa - La Patica (Prod Roit el quimico).mp3*4192689*9/9/2020 11:31:38 PM",
    "11.Ferri Ft Jonathan X Marco El Del Barrio - Yo De Ti.mp3*7360638*9/9/2020 11:31:47 PM",
    50860836,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Franny El Fenomeno - (2020)()*0*9/13/2020 9:32:55 PM",
    "CORRECTAMENTE tema newwww 114 bpm .wav*57978522*1/26/2020 8:30:53 PM",
    "Franny - Alma Rota (14 DE FEBRERO) ==lAFORMULA==.mp3*6849147*9/9/2020 11:33:38 PM",
    64827669,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\GENNY V - No Estan Pa Na(2020)()*0*9/13/2020 9:40:31 PM",
    "01.GENNY V - Modo Prosti(MAX PRO MUSIC BY PIVE).mp3*7041243*9/9/2020 11:29:17 PM",
    "02.GENNY V - No Estan Pa Na(MAX PRO MUSIC BY PIVE).mp3*4427113*9/9/2020 11:29:37 PM",
    11468356,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Gisel (G)(2020)()*0*9/13/2020 9:40:31 PM",
    "G - Yo Quiero -  .mp3*10471429*9/9/2020 11:33:39 PM",
    "G. ft  Alfred - Cuando tu no estas - .mp3*8061894*9/9/2020 11:33:39 PM",
    18533323,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\GMS - Karma(2020)()*0*9/13/2020 9:40:31 PM",
    "GMS - Karma.mp3*2595966*9/9/2020 11:33:39 PM",
    2595966,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\GNIO &amp; DAYRONI(2020)()*0*9/13/2020 9:40:32 PM",
    "Cruzando Los Dedos Gnio X Dayroni X Cristiancito.mp3*8580099*9/9/2020 11:33:09 PM",
    "Free Style Gnio.mp3*6059799*9/9/2020 11:33:38 PM",
    "Gnio &amp; Dayroni Adicto.mp3*2978671*9/9/2020 11:33:40 PM",
    "Gnio &amp; Dayroni Cualquier Cosa.mp3*4358685*9/9/2020 11:33:40 PM",
    "Gnio &amp; Dayroni Mueve Mueve Larra Dj 58020966.mp3*7860190*9/9/2020 11:33:40 PM",
    "Gnio &amp; Dayroni Patinaje.mp3*3232530*9/9/2020 11:33:40 PM",
    "Gnio &amp; DayroniMuñecas7maMusic.mp3*6849809*9/9/2020 11:33:40 PM",
    "Gnio X Dayroni Chanchullo Larra Dj 58020966.mp3*7769283*9/9/2020 11:33:41 PM",
    "Karma - Gnio - Wampi- Iyawo Oggun 7maMusic.mp3*9819409*9/9/2020 11:34:00 PM",
    "Ni A La Buena Ni Las Malas Master Gnio X Dayroni X Logico Rey.mp3*8294903*9/9/2020 11:34:26 PM",
    "Ni Hablar De Lola Gnio &amp; Dayroni Ft Andi Calienta &amp; Cuco.mp3*4911696*9/9/2020 11:34:26 PM",
    "R I P Dany Gnio &amp; Dayroni.mp3*9274839*9/9/2020 11:34:43 PM",
    "Somos SENSEI- Dj Unic, Gnio &amp; Dayroni(Omar1to Promo).mp3*6191401*9/9/2020 11:34:52 PM",
    86181314,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\H Oliva - (2020)()*0*9/13/2020 9:40:34 PM",
    "H Oliva - Amiga Mia.mp3*9837102*9/9/2020 11:33:42 PM",
    "H Oliva - Apagalo (prod DACHAY).mp3*9447690*9/9/2020 11:33:42 PM",
    "H Oliva - Hoy me la encontre (prod DACHAY).mp3*8001750*9/9/2020 11:33:42 PM",
    "H Oliva - Mala Mia (prod Dachay).mp3*6510918*9/9/2020 11:33:42 PM",
    "H Oliva ft Denver Perreo.mp3*7297050*9/9/2020 11:33:43 PM",
    41094510,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Harol light(2020)()*0*9/13/2020 9:40:34 PM",
    "harol light -  Basta ya - Star Factory  by Dinamiko.mp3*7280310*9/9/2020 11:33:43 PM",
    "harol lihgt -  Mala - Star Factory  by Dinamiko.mp3*7708718*9/9/2020 11:33:44 PM",
    14989028,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Harold Light - Estrenos(2020)()*0*9/13/2020 9:40:35 PM",
    "(((...HOY LA FIESTA NO PARA-HAROL LIGHT-PROD BY KEVIN IBRAUNCH...))).mp3*3661815*9/9/2020 11:29:06 PM",
    "(((...NO AGUANTO UN DIA MAS-HAROL LIGHT-PRO BY KEVIN IBRAUNCH-...))).mp3*3447273*9/9/2020 11:29:06 PM",
    "HAROL LIGHT - Admitelo -  .mp3*8435967*9/9/2020 11:33:43 PM",
    "harol lihgt -  Basta ya - Star Factory  by Dinamiko.mp3*7280310*9/9/2020 11:33:43 PM",
    "harol lihgt -  Mala - Star Factory  by Dinamiko.mp3*7708718*9/9/2020 11:33:44 PM",
    "harol lihgt -  Me perdi en tu voca  - Star Factory  by Dinamiko.mp3*8887363*9/9/2020 11:33:45 PM",
    "harol maket.mp3*8320143*9/9/2020 11:33:45 PM",
    "Harold - Admitelo -  .mp3*8435967*9/9/2020 11:33:45 PM",
    56177556,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Helinton el Chaval (2020)()*0*9/13/2020 9:40:36 PM",
    "Helinton el Chaval - El Mundele - .mp3*7710808*9/9/2020 11:33:47 PM",
    7710808,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\HM-Music - HM-Music(2020)*0*9/13/2020 9:40:40 PM",
    "Aprendi de ti-Polaris feat Madame Lety y Hector el WUAY (HM-Music) ....mp3*8488599*9/9/2020 11:32:52 PM",
    "Burundanga-La Crema feat Leo fonobernáculo( HM.Music).mp3*8434839*9/9/2020 11:32:56 PM",
    "Charanga Habanera X Hector El Wuay X Madame Lety X Damian X Norlan X La Diosa X Antony Puig X La Crema &amp; Mas - Pasillea 2(HM-Music).mp3*13998999*9/9/2020 11:33:01 PM",
    "Dame una opurtunidad-LA REINA DEL REPARTO feat JOKER (HM-Music)....mp3*8189643*9/9/2020 11:33:11 PM",
    "Donde nadie nos vea (HM-Music).mp3*7789791*9/9/2020 11:33:15 PM",
    "El Favory - Equivocada (HM-Music).mp3*8447358*9/9/2020 11:33:21 PM",
    "El Negro Pega Conto &amp; Mas - Pasillea 3 (HM-Music).......mp3*17621079*9/9/2020 11:33:25 PM",
    "EN LA HABANA SE DESCARGA-Yuny Torres( HM-Music).......mp3*8833791*9/9/2020 11:33:34 PM",
    "ES UNA ORDEN(Hector el Wuay-Madame Lety-EL FAVORY.)HM-MUSIC 1.mp3*7954743*9/9/2020 11:33:35 PM",
    "Hechale la culpa -Hector el Wuay-Madame Lety-Yuny Torres el Maniaco.(HM-Music).......mp3*8663619*9/9/2020 11:33:46 PM",
    "Hechizo-Wildey (HM-MUSIC)....mp3*7246911*9/9/2020 11:33:46 PM",
    "HM WILDEY FT TOBY Y EL BRUJO (HM-Music).......mp3*7648075*9/9/2020 11:33:48 PM",
    "Joan MC Ft Hector El Wuay &amp; Madame Lety - Farsantes(HM-Music).........mp3*6986955*9/9/2020 11:33:58 PM",
    "Junto a el-Anly feat HECTOR EL WUAY Y MADAME LETY (HM-Music).......mp3*7764735*9/9/2020 11:34:00 PM",
    "KALENTAN2-Yaser Azcuy feat L Mily (HM-Music).........mp3*9920595*9/9/2020 11:34:00 PM",
    "Kimiko &amp; Yordy Ft Carlichowa y El Principal - El Castigo( HM-Music ).......mp3*8375475*9/9/2020 11:34:01 PM",
    "La mismisima-Los Malditos ( HM-Music)........mp3*7673907*9/9/2020 11:34:05 PM",
    "Latin Root - La Conocí by KIKO DJ... ( HM - Music ).....mp3*8659443*9/9/2020 11:34:07 PM",
    "LOBO MALO FT EL AKALLABON - ERES TU.mp3*6820119*9/9/2020 11:34:10 PM",
    "Mala persona-Yaser Azcuy feat Bryan (HM-Music).........mp3*8037219*9/9/2020 11:34:18 PM",
    "Maneja con cuidao(HM-Music).......mp3*7720887*9/9/2020 11:34:18 PM",
    "Me gusta-Super Lucas y el Exclusivo feat Moikan (HM-Music).....mp3*7306419*9/9/2020 11:34:21 PM",
    "Mi mundo-Fabicile&amp;Yere( HM-Music ).....mp3*7495383*9/9/2020 11:34:22 PM",
    "Mi Razon- Dany Daniel y Narra NCY ( HM-Music ).......mp3*11262135*9/9/2020 11:34:23 PM",
    "NI LUCHAEL KOWA x KALI...By pulpo feat KikoDJ ...(HM-Music).......mp3*4026693*9/9/2020 11:34:27 PM",
    "No eres la mia- Wildey-(HM-Music)........mp3*7881879*9/9/2020 11:34:27 PM",
    "NO HAY COMO -MKT   KOWA (HM-Music).......mp3*7325211*9/9/2020 11:34:27 PM",
    "Pasillea - Wampi X Wildey X Harryson X Popy X Lobo Malo  Gnio &amp; Dayroni X Alvaro La Figura X Favory X Wuay X Madame Lety X Iyawo Oggun  -1.mp3*10574835*9/9/2020 11:34:35 PM",
    "Perderte-TLC DAYANWildeyHarrynson ( HM-Music )..........mp3*6421107*9/9/2020 11:34:37 PM",
    "Prendio -HECTOR EL WUAY y MADAME LETY feat YUNY TORRES ( HM-MUSIC ).mp3*9829767*9/9/2020 11:34:42 PM",
    "RIKACHU.HM MUSIC.mp3*4986385*9/9/2020 11:34:44 PM",
    "Sale pa la pista-(HM-Music).......mp3*8137443*9/9/2020 11:34:49 PM",
    "Saludate-Wildey feat Hector el WUAY y MADAME LETY(HM-Music)......mp3*9306723*9/9/2020 11:34:49 PM",
    "Sigue tu camino- LA REINA del REPARTO feat JOKER (HM-Music)... 1.mp3*7161303*9/9/2020 11:34:51 PM",
    "Te kiero pero no-Dany Daniel and Narra NCY (HM-Music)....Kiko DJ.mp3*9906558*9/9/2020 11:34:55 PM",
    "TemalFabicile&amp;Yere( HM-Music ).....mp3*7679127*9/9/2020 11:34:55 PM",
    "Tetas-(HM-Music).mp3*8679279*9/9/2020 11:34:56 PM",
    "TU CAMA-LATIN ROOT by DJ KIKO ( HM-MUSIC )........mp3*7411863*9/9/2020 11:35:00 PM",
    "Ultimo Pasillea (HM-Music).......mp3*17621118*9/9/2020 11:35:01 PM",
    "Verano con MultiCuba-La Crema(HM-Music).......mp3*9172047*9/9/2020 11:35:02 PM",
    "Wildey feat ROMAY y Mister Cohiba- los pies (HM-Music).....mp3*8707479*9/9/2020 11:35:08 PM",
    "Wildey-Lobo MaloHarrynson - Alquiler(HM-Music ).........mp3*8936103*9/9/2020 11:35:09 PM",
    "YASER AZCUY &amp;  MADAME LETTY(ADICTO) ( HM-Music ).......mp3*8430807*9/9/2020 11:35:11 PM",
    "YASER AZCUY feat Bryan-Mala persona- (HM-Music).........mp3*8037219*9/9/2020 11:35:11 PM",
    "YASER AZCUY feat L Mily-KALENTAN2- (HM-Music).........mp3*9920595*9/9/2020 11:35:11 PM",
    "YASER AZCUY-ECHU OLOYU ( HM MUSIC).mp3*8490315*9/9/2020 11:35:11 PM",
    399984575,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\J de oro x Duglas(2020)()*0*9/13/2020 8:54:37 PM",
    "J.J x Duglas - Mientra mas pasa el tiempo - .mp3*8655396*9/9/2020 11:33:51 PM",
    "J.J x Duglas - No fue intencional- .mp3*8174743*9/9/2020 11:33:52 PM",
    "J.J x Duglas - Sola - .mp3*7690955*9/9/2020 11:33:52 PM",
    24521094,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\J violent (demo)2 (2020)()*0*9/13/2020 8:54:40 PM",
    "1. J.Violent  - Bien suave - .mp3*5203053*9/9/2020 11:29:26 PM",
    "2. J.Violent  - Dale mami ven - .mp3*7014906*9/9/2020 11:29:47 PM",
    "3. J.Violent - No Te  Conosco - .mp3*7648114*9/9/2020 11:30:07 PM",
    "4. J.Violent  - La conoci bailando - .mp3*7198808*9/9/2020 11:30:25 PM",
    "5. J.Violent  - Ocean (version morfa)- .mp3*8262514*9/9/2020 11:30:41 PM",
    "6. J. Violent ft Maikol  - Vete de mi vida - .mp3*7675282*9/9/2020 11:30:55 PM",
    "7. J.Violent ft Adinaisito  - Eso es Mentira - .mp3*7029535*9/9/2020 11:31:08 PM",
    "8. J.Violent ft Adinaisito  - La Maldita - .mp3*7173731*9/9/2020 11:31:21 PM",
    "9. J.Violent ft Barreiro  - Encendia - .mp3*9522661*9/9/2020 11:31:32 PM",
    "Dinamiko ft J. Violent - Soledad -  .mp3*8755706*9/9/2020 11:33:13 PM",
    "Guilero ft J. Violent - Me matas  -  .mp3*7760963*9/9/2020 11:33:41 PM",
    "J.Violent··#Entregate - prod by PromoRecords.mp3*7880619*9/9/2020 11:33:52 PM",
    91125892,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\J Violent - 2Bandoleros(2020)()*0*9/13/2020 8:54:42 PM",
    "1. J.Violent  - Bien suave - .mp3*5203053*9/9/2020 11:29:26 PM",
    "10. Dinamiko ft J. Violent - Soledad -  .mp3*8755706*9/9/2020 11:31:36 PM",
    "11. J.Violent  - Dale mami ven - .mp3*7014906*9/9/2020 11:31:46 PM",
    "12. J.Violent··#Entregate - prod by PromoRecords.mp3*7880619*9/9/2020 11:31:53 PM",
    "13. Guilero ft J. Violent - Me matas  -  .mp3*7760963*9/9/2020 11:31:58 PM",
    "2.  J. Violent ft Maikol  - Vete de mi vida - .mp3*7675282*9/9/2020 11:29:47 PM",
    "3. J.Violent - No Te  Conosco - .mp3*7648114*9/9/2020 11:30:06 PM",
    "4. J. violent  ft Zamoray- Eres tu -  .mp3*7520637*9/9/2020 11:30:25 PM",
    "5. J.Violent  - Ocean (version morfa)- .mp3*8262514*9/9/2020 11:30:41 PM",
    "6. J.Violent ft Adonaisito  - La Maldita - .mp3*7173731*9/9/2020 11:30:55 PM",
    "7. J.Violent ft Barreiro  - Encendia - .mp3*9522661*9/9/2020 11:31:09 PM",
    "8. J.Violent ft Adinaisito  - Eso es Mentira - .mp3*7029535*9/9/2020 11:31:21 PM",
    "9. J.Violent  - La conoci bailando - .mp3*7198808*9/9/2020 11:31:31 PM",
    "Encendía.J.violent X Barreiro.Star Factory productions.mp3*7485987*9/9/2020 11:33:34 PM",
    106132516,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\JayBy - WUHAN(2020)()*0*9/13/2020 11:15:28 PM",
    "01.JayBy - Tokyo.mp3*3307605*9/9/2020 11:29:17 PM",
    "01-JAYBY--TOKYO.mp3*3307605*9/9/2020 11:29:25 PM",
    "02.Jayby x Dj Unic - Rio.mp3*5839330*9/9/2020 11:29:37 PM",
    "02-JAYBY X DJ UNIC -- RÍO.mp3*9319913*9/9/2020 11:29:44 PM",
    "03.Jayby - Wuhan.mp3*5282552*9/9/2020 11:29:59 PM",
    "03-JAYBY--WUHAN.mp3*5282775*9/9/2020 11:30:05 PM",
    "04.Jayby X DNIX-E - Tokyo Remix.mp3*9721321*9/9/2020 11:30:18 PM",
    "05.Jayby - WUHAN.mp3*5282507*9/9/2020 11:30:35 PM",
    "JAYBY X DNIX-E --TOKYO REMIX.mp3*9721321*9/9/2020 11:33:54 PM",
    57064929,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\JDany X Ozamy Ft Wow Popy - Si Pudiera(2020)()*0*9/13/2020 8:54:44 PM",
    "JDany X Ozamy Ft Wow Popy - Si Pudiera.mp3*6743517*9/9/2020 11:33:55 PM",
    6743517,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Jhon Menez - A Lo Anuel &amp; Karol(2020)()*0*9/13/2020 11:23:43 PM",
    "01.Jhon Menez Ft Vane D - A Lo Anuel &amp; Karol.mp3*4973354*9/9/2020 11:29:17 PM",
    "02.Jhon Menez - Muerto Contigo.mp3*8958237*9/9/2020 11:29:37 PM",
    "03.Jhon Menez Ft Kimiko &amp; Yordy - Caperucita.mp3*8457117*9/9/2020 11:29:59 PM",
    "04.Jhon Menez - Ganga.mp3*3254626*9/9/2020 11:30:18 PM",
    "05.Jhon Menez Ft Wampy - La Conquista.mp3*8264157*9/9/2020 11:30:35 PM",
    "06.Jhon Menez Ft Charlie Maykol - Me Kemas.mp3*7712157*9/9/2020 11:30:50 PM",
    "07.Jhon Menez Ft El Furia x Mr Haison - Vuelve.mp3*8649117*9/9/2020 11:31:03 PM",
    50268765,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Jhon Menez - Caperucita(2020)()*0*9/13/2020 8:58:39 PM",
    "01.Jhon Menez Ft Wampy - La Conquista.mp3*8264157*9/9/2020 11:29:17 PM",
    "02.Jhon Menez - Muerto Contigo.mp3*8958237*9/9/2020 11:29:38 PM",
    "03.Jhon Menez Ft Kimiko &amp; Yordy - Caperucita.mp3*8457117*9/9/2020 11:29:59 PM",
    "04.Jhon Menez - Ganga.mp3*3254626*9/9/2020 11:30:19 PM",
    28934137,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Jhon Menez - Lo Mas Reciente(2020)*0*9/13/2020 8:58:38 PM",
    "!! Jhon Menez Feet Kimiko Y Yordy La Caperusita Roja.mp3*8342933*9/9/2020 11:29:01 PM",
    "!!LA CONQUISTA !! YON MENEZ FT WAMPY..2019 - copia.mp3*8493447*9/9/2020 11:29:01 PM",
    "!!LA CONQUISTA !! YON MENEZ FT WAMPY..2019.mp3*8493447*9/9/2020 11:29:01 PM",
    "GANGA MASTER.mp3*3230283*9/9/2020 11:33:39 PM",
    "JHON MENES--MUERTO CONTIGO.mp3*3163827*9/9/2020 11:33:56 PM",
    31723937,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Joako369(2020)()*0*9/13/2020 8:58:32 PM",
    "!!!LA PAPAYAREADYSALVI&amp;JOAKO369.mp3*7118314*9/9/2020 11:29:01 PM",
    "Caminala - Joako369.mp3*7883751*9/9/2020 11:32:56 PM",
    "Fuego - Joako369 .mp3*2734965*9/9/2020 11:33:39 PM",
    "Joako369 - Celosa.mp3*8393087*9/9/2020 11:33:58 PM",
    "JOAKO369 - COPAS .mp3*2770491*9/9/2020 11:33:58 PM",
    "JOAKO369 - TAL VEZ QUIZAS .mp3*2974873*9/9/2020 11:33:58 PM",
    "Joako369xReadySalvixChocolateMCxYesmyLaPapayaRemix.mp3*3513205*9/9/2020 11:33:58 PM",
    "SIGUEME - JOAKO369, YESMI, C4 .mp3*2828170*9/9/2020 11:34:51 PM",
    38216856,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Jorge Luis Pacheco - Te Vi Reir(2020)(POP)*0*9/13/2020 8:58:31 PM",
    "Jorge Luis Pacheco - Te Vi Reir.mp3*14412982*9/9/2020 11:33:59 PM",
    14412982,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Jose Valladares - Mas Solidarios(COVID 19)(2020)(POP)*0*9/13/2020 8:58:31 PM",
    "Jose Valladares - Mas Solidarios(COVID 19).mp3*1881683*9/9/2020 11:33:59 PM",
    1881683,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Jose Yamil(El Chavo) - Contigo Quiero Todo(2020)()*0*9/13/2020 8:58:37 PM",
    "01.Jose Yamil(El Chavo) - Contigo Quiero Todo.mp3*2343022*9/9/2020 11:29:17 PM",
    "02.Baby Lores Ft Jose Yamil(El Chavo) - Se Fue.mp3*3216687*9/9/2020 11:29:33 PM",
    "03.Jose Yamil(El Chavo) - El Negocio.mp3*3691569*9/9/2020 11:29:59 PM",
    "04.Jose Yamil(El Chavo) - Muñequita.mp3*3399771*9/9/2020 11:30:19 PM",
    "05.Jose Yamil(El Chavo) -  Que Sera.mp3*3639647*9/9/2020 11:30:35 PM",
    "06.Jose Yamil(El Chavo) - Soy Figura.mp3*3591163*9/9/2020 11:30:50 PM",
    "07.Jose Yamil(El Chavo) - Suave Suavecito.mp3*3856521*9/9/2020 11:31:03 PM",
    "08.El Kamel Ft Jose Yamil(El Chavo) - Con La Misma Moneda.mp3*2848032*9/9/2020 11:31:14 PM",
    "09.Baby Lores Ft Jose Yamil(El Chavo) - Te Solte.mp3*2568417*9/9/2020 11:31:25 PM",
    29154829,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Julio Kaiser (CD OFICIAL)- Demo(2020)()*0*9/13/2020 8:58:37 PM",
    "00.EL MATADOR - Julio KaiSer  Prod BY- DiamanTONE  ADEOS Productions.mp3*8851594*9/9/2020 11:29:07 PM",
    "01.Cero Compromiso - JULIO KAISER  Prod BY- Angel el Diamante - THE SOUND IRIS (T.S.I).mp3*3476123*9/9/2020 11:29:13 PM",
    "01.JULIO KAISER - NO ME HACES FALTA  Prod BY- Made by the Diamond -  (T.S.I)OK.mp3*3649994*9/9/2020 11:29:18 PM",
    "01.JULIO KAISER - SOLEDAD  Prod BY- Made by the Diamond -  (T.S.I)OK.mp3*3807564*9/9/2020 11:29:18 PM",
    "01.MI MUJER - JULIO KAISER  Prod BY- Made by the Diamond -  (T.S.I) OK.mp3*3243657*9/9/2020 11:29:21 PM",
    "06.COMO ME GUSTA - JULIO KAISER  Prod BY- Made by the Diamond -  (T.S.I) OK.mp3*8093194*9/9/2020 11:30:48 PM",
    "06.JULIO KAISER-ESA NIÑA NO ME QUIERE  Prod BY- YEISI RECORDS.mp3*9814215*9/9/2020 11:30:50 PM",
    "07.ABUSADORA - JULIO KAISER  Prod BY- Made by the Diamondcronox -  (T.S.I) OK.mp3*7762954*9/9/2020 11:31:01 PM",
    48699295,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Julio Kaiser - Demo(2020)()*0*9/13/2020 8:58:35 PM",
    "ABUSADORA - JULIO KAISER  Prod BY- Made by the Diamondcronox -  (T.S.I) OK.mp3*7762954*9/9/2020 11:32:45 PM",
    "COMO ME GUSTA - JULIO KAISER  Prod BY- Made by the Diamond -  (T.S.I) OK.mp3*8093194*9/9/2020 11:33:07 PM",
    15856148,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Kaiser(2020)()*0*9/13/2020 8:58:35 PM",
    "El Kaiser -  que tu no cres  -  .mp3*7783951*9/9/2020 11:33:21 PM",
    7783951,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Karenia Diaz - Tell Me(2020)()*0*9/13/2020 8:58:35 PM",
    "01.Karenia Diaz - Tell Me.mp3*9084938*9/9/2020 11:29:18 PM",
    "02.Karenia Diaz - Tranquilita Y Relaja.mp3*4398846*9/9/2020 11:29:38 PM",
    "03.Karenia Diaz - Y Yo Que Decia.mp3*4107373*9/9/2020 11:30:00 PM",
    17591157,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\King Cachi x Tony Flow x Un Chama(2020)()*0*9/13/2020 8:58:34 PM",
    "King Cachi x Tony Flow x Un Chama -  Te Fuiste  - .mp3.mp3*7088049*9/9/2020 11:34:02 PM",
    "King Cachi x Tony Flow x Un Chama -  Ya no siento - .mp3.mp3*6693078*9/9/2020 11:34:02 PM",
    13781127,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\King of Kings(2020)()*0*9/13/2020 8:58:34 PM",
    "King of Kings - Lo q se lleva - Star Factory productions.mp3*8649126*9/9/2020 11:34:03 PM",
    8649126,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\KingoFarai(2020)()*0*9/13/2020 8:58:33 PM",
    "KingoFarai - Aparte.mp3*3031263*9/9/2020 11:34:03 PM",
    "KingoFarai - Me va muy bien.mp3*2368853*9/9/2020 11:34:03 PM",
    "KingoFarai - Rica.mp3*1803951*9/9/2020 11:34:03 PM",
    "KingoFarai - Tu Directa.mp3*2214262*9/9/2020 11:34:03 PM",
    "KingoFarai×Harrison×ElGallo247×7MevamuybienRemix.mp3*2146040*9/9/2020 11:34:03 PM",
    11564369,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Kris Vera Ft Nesto - Te Esperare(2020)()*0*9/13/2020 8:58:33 PM",
    "Kris Vera Ft Nesto - Te Esperare.mp3*3107370*9/9/2020 11:34:03 PM",
    3107370,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\La Dinastia - Nota(2020)()*0*9/13/2020 8:58:32 PM",
    "La Dinastia - Nota.mp3*4881442*9/9/2020 11:34:04 PM",
    4881442,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\La Marka(2020)()*0*9/13/2020 9:36:04 PM",
    "1585627200La Marka - La Bebecita.mp3*3214935*9/9/2020 11:32:38 PM",
    "1585627200MujeriegoLaMarkaftOmaritoProdQueBolaRecords.mp3*9448599*9/9/2020 11:32:38 PM",
    "1585886400LaMarkaxYairoEngaÃ±oProdPromoStudiosByPerso.mp3*2703063*9/9/2020 11:32:39 PM",
    "1585886400LaMarkaxYairoMeQuedoconunaftOmaritoByPerso.mp3*2767959*9/9/2020 11:32:39 PM",
    "1585886400LaMarkaxYairoSienteelRitmo.mp3*2714199*9/9/2020 11:32:39 PM",
    "1585886400LaMarkaxYairo-VerteIrPromoEstudioByPerso.mp3*2940759*9/9/2020 11:32:39 PM",
    "1585886400LaMarkaxYairoxKokiPichichiHazmeloByPersoPromo.mp3*2797143*9/9/2020 11:32:39 PM",
    "1585886400TeVas-LaMarkaftElPekaprodQueBolaRecords.mp3*2766807*9/9/2020 11:32:39 PM",
    "1588305600Modo no - La Marka Cubana.mp3*2300287*9/9/2020 11:32:40 PM",
    "1590984000BethobeenLaMarka&amp;YairoftAndyCalientawwwseponeweno.mp3*2542647*9/9/2020 11:32:40 PM",
    "1591156800LA MARKA -TU TIENES (www.seponeweno.nat.cu).mp3*2594199*9/9/2020 11:32:40 PM",
    "1591156800Navega Mulata- La Marka (www.seponeweno.nat.cu).mp3*2362647*9/9/2020 11:32:40 PM",
    "1591156800Niña Mala - La Marka (www.seponeweno.nat.cu).mp3*2002647*9/9/2020 11:32:40 PM",
    "Me Canse- La Marka Cubana x Joako369 x La Diosa (2).mp3*4608885*9/9/2020 11:34:21 PM",
    "Me Canse- La Marka Cubana x Joako369 x La Diosa.mp3*4608885*9/9/2020 11:34:21 PM",
    "MuchoTalentoLaMarkaCubanaFtElHueso&amp;Juanovideooficial.mp3*3159071*9/9/2020 11:34:25 PM",
    53532732,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Lady Laura - Tutti Fruti(2020)()*0*9/13/2020 9:36:04 PM",
    "01.Lady Laura Ft El Taiger - Amnesia.mp3*8301137*9/9/2020 11:29:19 PM",
    "02.Lady Laura - Que Te Preocupa.mp3*9349169*9/9/2020 11:29:38 PM",
    "03.Lady Laura Ft Chacal - Loca.mp3*9168279*9/9/2020 11:30:00 PM",
    "04.Lady Laura Ft Micha - Mienteme.mp3*8094039*9/9/2020 11:30:19 PM",
    "05.Lady Laura Ft Srta Dayana - No Te Emociones.mp3*3289787*9/9/2020 11:30:35 PM",
    "06.Lady Laura Ft El Taiger - Me Gusto.mp3*4202638*9/9/2020 11:30:50 PM",
    "07.Lady Laura Ft Divan - Pensando En Mi.mp3*7620678*9/9/2020 11:31:04 PM",
    "08.Lady Laura Ft Franny El Fenomeno - Animal.mp3*8536599*9/9/2020 11:31:16 PM",
    "09.Lady Laura - No Me Controlas.mp3*2539067*9/9/2020 11:31:27 PM",
    "10.Lady Laura Ft Dayron Sexappeal - Tutti Fruti.mp3*752937*9/9/2020 11:31:38 PM",
    "11.Lady Laura - Bandolera.mp3*7860190*9/9/2020 11:31:47 PM",
    69714520,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Leduar Raudel - Corone(2020)()*0*9/13/2020 9:36:06 PM",
    "01.Leduar Raudel - Corone.mp3*8034726*9/9/2020 11:29:19 PM",
    "02.Leduar Raudel - Ya No Hablamos.mp3*3894165*9/9/2020 11:29:39 PM",
    "03.Leduar Raudel Ft Alto Riesgo - Enamorate De Mi.mp3*3368372*9/9/2020 11:30:00 PM",
    "04.Leduar Raudel Ft Chesco - Dicelo.mp3*4398223*9/9/2020 11:30:19 PM",
    "05.Leduar Raudel - El Negrito.mp3*4452976*9/9/2020 11:30:35 PM",
    "06.Leduar Raudel - Sin Tu Amor.mp3*8278398*9/9/2020 11:30:51 PM",
    "07.Leduar Raudel - Tu Bandolero.mp3*4532388*9/9/2020 11:31:04 PM",
    "08.Leduar Raudel Ft El Kamel - Una Locura.mp3*9194563*9/9/2020 11:31:16 PM",
    "09.Leduar Raudel X El Carissimo Jinete - Arriba De Los Dollares.mp3*9740159*9/9/2020 11:31:27 PM",
    "10.Leduar Raudel x Brayan Omeg x Menor Gabila x Osmel - Buscando Amor.mp3*8896926*9/9/2020 11:31:38 PM",
    "11.Leduar Raudel - El Tiempo Lo Cambia Tò.mp3*8933498*9/9/2020 11:31:48 PM",
    "12.Leduar Raudel - Estudié Con Pinocho.mp3*11566482*9/9/2020 11:31:55 PM",
    "13.Leduar Raudel X Dj Unic - Tu Estás Dura.mp3*8393126*9/9/2020 11:31:59 PM",
    "14.Leduar Raudel X Dkano X Efowa -  La Ventana.mp3*7902183*9/9/2020 11:32:04 PM",
    101586185,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Lenier Waño - A Cualquiera Le Gusta(2020)(FUNK)*0*9/13/2020 11:21:59 PM",
    "1 INTRO.wav*18260302*5/10/2019 7:08:26 PM",
    "10 BAILANDO.wav*39866306*5/10/2019 7:08:40 PM",
    "11 SUERTE PA QUE.wav*38623430*5/10/2019 7:08:48 PM",
    "12 CAMILA.wav*34008444*5/13/2019 8:00:10 PM",
    "2 DÍMELO.wav*37021778*5/10/2019 7:08:58 PM",
    "3 AHORA QUE TE VI.wav*36824806*5/10/2019 7:09:04 PM",
    "4 LO QUE SIENTO YO.wav*37635822*5/10/2019 7:09:10 PM",
    "5 HACERLO ASÍ.wav*40791302*5/10/2019 7:09:16 PM",
    "6 REGÁLAME.wav*38105854*5/10/2019 7:09:34 PM",
    "7 LA RECETA.wav*39799294*5/10/2019 7:09:28 PM",
    "8 SOLA.wav*36577422*5/10/2019 7:09:22 PM",
    "9 NO VOY A PARAR.wav*41455294*5/13/2019 7:54:04 PM",
    438970054,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Lenier Waño - Single Demo(2020)(FUNK)*0*9/13/2020 11:22:00 PM",
    "�Lenier Waño- Bailalo ahora- by Youngdaddy &amp; Dj Sonny.mp3*8583231*9/9/2020 11:34:08 PM",
    "🎧Lenier Waño- Contigo- by Youngdaddy &amp; Dj Sonny &amp; Dj Cham-1.mp3*9537447*9/9/2020 11:35:23 PM",
    "🎧Lenier Waño- Muevelo- by Youngdaddy &amp; Dj Cham-2.mp3*8831703*9/9/2020 11:35:24 PM",
    "🎧Lenier Waño- Si tu no estas- by Youngdaddy &amp; Dj Sonny &amp; Dj Cham.mp3*8739831*9/9/2020 11:35:24 PM",
    "🎧Lenier Waño- Vida loca- by Youngdaddy &amp; Dj Sonny &amp; Dj Cham.mp3*9150123*9/9/2020 11:35:24 PM",
    44842335,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Leony Cabrera &amp; El Powell - Leony Cabrera &amp; El Powell(2020)*0*9/13/2020 11:14:32 PM",
    "01.El Bacoco Ft Leony Cabrera  - Hoy Te Toka.mp3*7691655*9/9/2020 11:29:14 PM",
    "02.Leony Cabrera &amp; El Vega Ft El Vallo Ok Ok - El Babylon.mp3*3611181*9/9/2020 11:29:39 PM",
    "03.Leony Cabrera - La Mia.mp3*3556893*9/9/2020 11:30:00 PM",
    "04.Leony Cabrera Ft Powell x Lauta X Marty La Barbie &amp; La Bebita Leal - Mi Negrita.mp3*7553847*9/9/2020 11:30:20 PM",
    "05.Leony Cabrera &amp; El Vega - Esa Karita.mp3*7007835*9/9/2020 11:30:36 PM",
    "06.Popy x El Leony Cabrera - Hermano Mio.mp3*3785529*9/9/2020 11:30:52 PM",
    "07.Leony Cabrera Ft El Vega &amp; Robertony - No Me Valoraste.mp3*3557415*9/9/2020 11:31:04 PM",
    "08.Leony Cabrera &amp; El Vega Ft Popy - Frito Contigo.mp3*3512523*9/9/2020 11:31:16 PM",
    "09.Leony Cabrera &amp; El Vega Ft El Power - No Hay Perdon.mp3*7013079*9/9/2020 11:31:28 PM",
    "10.Leony Cabrera &amp; El Vega Ft Powell x Vega x Robertony - 14 De Febrero.mp3*6800079*9/9/2020 11:31:38 PM",
    "11.Leony Cabrera x Powell x Robertony - Señorita De Que.mp3*7196799*9/9/2020 11:31:48 PM",
    "12.Leony Cabrera Ft Powell x Lauta - Mi Negrita.mp3*6892119*9/9/2020 11:31:55 PM",
    "13.Leony Cabrera x Los Ley Ley x El Galan - Vuelve.mp3*8599959*9/9/2020 11:31:59 PM",
    "14.Leony Cabrera Ft El Powell - Tiradera.mp3*9356835*9/9/2020 11:32:04 PM",
    "15.Leony Cabrera x Powell x Lauta - Explicale.mp3*7218738*9/9/2020 11:32:08 PM",
    "16.Leony Cabrera x Powell x Los Blue Sky - La .mp3*7797099*9/9/2020 11:32:10 PM",
    "17.Powell Ft Langer - Nadie Me Ayudo.mp3*7219767*9/9/2020 11:32:13 PM",
    "18.Leony Cabrera Ft El Powell - Paso Tu Tiempo.mp3*6886746*9/9/2020 11:32:14 PM",
    "19.Anubix Ft Leony Cabrera &amp; El Galan - Ahora No.mp3*7553862*9/9/2020 11:32:16 PM",
    "20.Leony Cabrera Ft Powell &amp; J King - Otra Cara.mp3*6607014*9/9/2020 11:32:18 PM",
    "21.Leony Cabrera Ft Powell &amp; Robertony - Palo Que Preocupa.mp3*7129254*9/9/2020 11:32:19 PM",
    "22.Anubix Ft El Leony Cabrera,Powell &amp; J King - Otra Cara Remix.mp3*6872118*9/9/2020 11:32:20 PM",
    "23.Leony Cabrera Ft El Powell,Tito - Sigo Aqui.mp3*6738534*9/9/2020 11:32:21 PM",
    "24.Leony Cabrera x Powell - Quiero Una Diabla.mp3*6446694*9/9/2020 11:32:22 PM",
    156605574,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Leslie Guzmán - Castigo(2020)()*0*9/13/2020 11:14:33 PM",
    "Leslie Guzmán Ft Los Yakuza(YK) - Castigo.mp3*8683806*9/9/2020 11:34:09 PM",
    8683806,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Lester Brayzan(2020)()*0*9/13/2020 11:14:35 PM",
    "1593748800001-LeStEr BrAyZaN[A FULL]Ray  Produccion Oficial.mp3*1833024*9/9/2020 11:32:41 PM",
    "1593748800002LeStErBrAyZaNHASTAABAJODALERayProduccionOficial.mp3*1848697*9/9/2020 11:32:41 PM",
    "1593748800003-LeStEr BrAyZaN[OFICIAL]Ray  Produccion.mp3*1558843*9/9/2020 11:32:41 PM",
    "1593748800004LeStErBrAyZaNELPUMPUMRayProduccionOficial.mp3*1639300*9/9/2020 11:32:41 PM",
    "1593748800005-LeStEr BrAyZaN[NO PUEDO ESTAR  SIN TI]New CrUz.mp3*1417573*9/9/2020 11:32:41 PM",
    "1593748800006-LeStEr BrAyZaN[MAMA HEEE]Ray  Produccion.mp3*1611924*9/9/2020 11:32:41 PM",
    "1593748800007LeStErBrAyZaN&amp;BrUkCoNALOPLAYERORayProduccion.mp3*1652675*9/9/2020 11:32:41 PM",
    "1593748800008LeStErBrAyZaNfeatHawaiian,CriminalVerbalFANATICO.mp3*1946500*9/9/2020 11:32:41 PM",
    "1593748800009-LeStEr BrAyZaN[TIRALE]Ray  Produccion.mp3*1697396*9/9/2020 11:32:41 PM",
    "1593748800010-LeStEr BrAyZaN[ADICTA AL  BLOMRay Produccion.mp3*1687783*9/9/2020 11:32:41 PM",
    "1593748800011LeStErBrAyZaNfeatFlowKaroORDENCATEORayProduccion.mp3*2640103*9/9/2020 11:32:41 PM",
    19533818,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\LNC ( Juanky &amp; Javy)(2020)()*0*9/13/2020 10:13:44 PM",
    "LNC ( Juanky &amp; Javy) - Me Enamore - .mp3*7995020*9/9/2020 11:34:09 PM",
    7995020,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Lobo Kin Dowa ft King Cachi x Tony Flow x Un Chama(2020)()*0*9/13/2020 10:13:45 PM",
    "King Cachi x Tony Flow x Un Chama - Esa Muchachita - .mp3*6998188*9/9/2020 11:34:02 PM",
    "Lobo Kin Dowa ft King Cachi x Tony Flow x Un Chama - Esa Muchachita - .mp3*10375298*9/9/2020 11:34:10 PM",
    17373486,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Los Doble J(2020)()*0*9/13/2020 10:13:45 PM",
    "Los Doble J - Cambio repentino - .mp3*8985584*9/9/2020 11:34:10 PM",
    "Los Doble J - Pa la baya - .mp3*8009649*9/9/2020 11:34:10 PM",
    16995233,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Los Gobernantes(Los Ojos Sobre Mi)(2020)()*0*9/13/2020 10:13:56 PM",
    "Como Wildey - LosGobernantes .mp3*7867531*9/9/2020 11:33:08 PM",
    "Los Gobernantes - Pegao.mp3*8219946*9/9/2020 11:34:11 PM",
    "Los Gobernantes - TiNGUILY ft Kilo 2 .mp3*10383114*9/9/2020 11:34:11 PM",
    "No Me Interesa - Los Gobernantes .mp3*9627195*9/9/2020 11:34:28 PM",
    "Wildey ft Los Gobernantes - La Cara.mp3*7924146*9/9/2020 11:35:09 PM",
    44021932,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Los Indomables - Sobrenatural(2020)()*0*9/13/2020 10:35:09 PM",
    "1- El Envia2 Ft Los Indomables - El Daño.mp3*8031898*9/9/2020 11:29:25 PM",
    "2- El Envia2 Ft Los Indomables-Trankilo Contigo.mp3*7137178*9/9/2020 11:29:46 PM",
    "5 -El Tiger Ft Indomables -Mi Locura Mi Relajo.mp3*9071235*9/9/2020 11:30:40 PM",
    "7 -Indomables -Ke Bien Estamos.mp3*8941719*9/9/2020 11:31:08 PM",
    "Bajale 2El Taiger Ft IndomableProd.By Meko.mp3*8347287*9/9/2020 11:32:54 PM",
    "Indomables - La Dura [Prod By Sobrenatural - Wanted Music].mp3*9159519*9/9/2020 11:33:48 PM",
    "Indomables - Peligro [Prod By Sobrenatural - Wanted Music].mp3*9202323*9/9/2020 11:33:48 PM",
    "Indomables - Yo Soy Asi [Prod By Sobrenatural - Wanted Music].mp3*8158323*9/9/2020 11:33:49 PM",
    "Indomables- A Ke No Entras.mp3*6074967*9/9/2020 11:33:49 PM",
    "Indomables Ft Coto El Chocolate.mp3*6035055*9/9/2020 11:33:49 PM",
    "Indomables Ft Harrison - La Wariskay [Prod By Sobrenatural - Wanted Music].mp3*9448707*9/9/2020 11:33:49 PM",
    "Indomables -Mi Cocha.mp3*6147159*9/9/2020 11:33:49 PM",
    "Indomables-Ke Kenke.mp3*6008151*9/9/2020 11:33:50 PM",
    "Indomables-Virgencita.mp3*5994985*9/9/2020 11:33:50 PM",
    "Las Balas - Indomables.mp3*3904440*9/9/2020 11:34:06 PM",
    111662946,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\LOs iTACOS (Ordeley x Wayle x Barreiro)(2020)()*0*9/13/2020 10:36:51 PM",
    "iNTRO - LOs iTakos  (Ordeley x Waylei x Barreiro ) - .mp3*8586433*9/9/2020 11:33:50 PM",
    "LOs iTakos  (Ordeley x Wayle x Barreiro ) - Otra Obbini - .mp3*8197731*9/9/2020 11:34:11 PM",
    "LOs iTakos  (Ordeley x Wayle x Barreiro ) - Vale 2  - .mp3*8229078*9/9/2020 11:34:12 PM",
    25013242,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Los Khris-DY(2020)()*0*9/13/2020 10:36:55 PM",
    "- MOIKAN FT KHRIS RAUDE &amp; DIONNY - YA NO  PROD BY EL PERSO.mp3*6934755*9/9/2020 11:28:59 PM",
    "! ! ! LOS Khris-Dy - EMBORRACHARTE  BY EL PERSO.mp3*8309497*9/9/2020 11:29:00 PM",
    "! ! ! LOS KRIDIIS - AL FLAY  PROD BY EL PERSO.mp3*8304483*9/9/2020 11:29:00 PM",
    "! ! ! LOS KRIDIS - ARRANCA  PROD BY EL PERSO.mp3*6548475*9/9/2020 11:29:00 PM",
    "! ! ! LOS KRIDIS - INFIELES  PROD BY EL PERSO.mp3*8888079*9/9/2020 11:29:00 PM",
    "! ! ! LOS KRIDIS - SE TERMINO  BY EL PERSO.mp3*8789943*9/9/2020 11:29:00 PM",
    "! ! ! LOS KRIDIS - TE IMAGINÉ  BY EL PERSO.mp3*7418127*9/9/2020 11:29:00 PM",
    "Confia en nuestro amor   ( KHRIS RAUDE ) prod AL2 el CKlassico &amp; DJ Mostro.mp3*9897918*9/9/2020 11:33:08 PM",
    "Gracias a ti ( KHRIS RAUDE ) prod AL2 el CKlassico &amp; DJ Mostro.mp3*10270398*9/9/2020 11:33:41 PM",
    "KHRIS RAUDE X DIONNY X DALE A LOS PEDALES X LA AIFON FENIX.mp3*8163249*9/9/2020 11:34:01 PM",
    "KHRIS RAUDEN&amp;DIOSNNY-¨DIME¨.mp3*10718820*9/9/2020 11:34:01 PM",
    "KHRIS RAUDEN&amp;DIOSNNY-¨MAMI TU¨.mp3*7391864*9/9/2020 11:34:01 PM",
    "KHRIS RAUDEN&amp;DIOSNNY-¨MI NENA¨.mp3*8955032*9/9/2020 11:34:01 PM",
    "KHRIS RAUDEN&amp;DIOSNNY-¨NO DESEASTE¨.mp3*8860991*9/9/2020 11:34:01 PM",
    "KHRIS RAUDEN&amp;DIOSNNYY-¨DEJAME HACERTE EL AMOR¨.mp3*7526656*9/9/2020 11:34:01 PM",
    "La Selva--Khris Raude Y Dionny--Los Khris-Dy (2).mp3*5413465*9/9/2020 11:34:06 PM",
    "La vi en la disco ( KHRIS RAUDE &amp; DIOSNEY ) prod AL2 el CKlassico &amp; DJ Mostro.mp3*11185278*9/9/2020 11:34:06 PM",
    "Los Khris-Dy``ZAMBA``-Khris Raude y Diosny-Platiniums Records.wav*50590914*5/26/2020 1:54:52 AM",
    "ULA LA LA REMIX+WILDAY+MOIKANO+KHRIS RAUDE &amp; DIONNE(MUSIC RECORDS).mp2*8627145*9/9/2020 11:35:01 PM",
    202795089,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Los Samawa ft Kelly x Nelly(2020)()*0*9/13/2020 9:13:31 PM",
    "Los Samawa ft Kelly x Nelly - Quiesiera repetir - .mp3*6686808*9/9/2020 11:34:13 PM",
    6686808,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Los Yakuza(YK) - Amnesia(Adelanto)(58877280)(2020)*0*9/13/2020 9:13:32 PM",
    "01.Los Yakuza - Amnesia(58877280).mp3*9379474*9/9/2020 11:29:19 PM",
    "02.Los Yakuza - Interés(58877280).mp3*7598398*9/9/2020 11:29:39 PM",
    "03.Los Yakuza - Homenaje A Los Cangris(58877280).mp3*8550894*9/9/2020 11:30:00 PM",
    "04.Los Yakuza - Mama(58877280).mp3*9781719*9/9/2020 11:30:20 PM",
    "05.Los Yakuza Ft Angeles- Cuando hables con él(58877280).mp3*8127722*9/9/2020 11:30:36 PM",
    "06.Los Yakuza Ft Código De Barra - La Culpa(58877280).mp3*7971474*9/9/2020 11:30:51 PM",
    "07.Los Yakuza - Dile(58877280).mp3*8586242*9/9/2020 11:31:04 PM",
    "08.Los Yakuza - Gracias A Ti(58877280).mp3*8717079*9/9/2020 11:31:17 PM",
    "09. Los Yakuza(YK) Ft Leslie Guzmán - Castigo.mp3*8683806*9/9/2020 11:31:25 PM",
    77396808,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Macho(2020)()*0*9/13/2020 9:13:33 PM",
    "Macho - NO te vallas  -  .mp3*6741143*9/9/2020 11:34:13 PM",
    "Macho - Una Mezcla -  .mp3*7445404*9/9/2020 11:34:14 PM",
    "Macho x Briansito x El Javi - Dile  a tu novio -  .mp3*7169551*9/9/2020 11:34:14 PM",
    21356098,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Maikel King x Hierro(2020)()*0*9/13/2020 9:13:33 PM",
    "Maikel King x Hierro - Arranca  -  .mp3*9413992*9/9/2020 11:34:14 PM",
    "Maikel King x Hierro -Rosas con espinas -  .mp3*7583331*9/9/2020 11:34:15 PM",
    16997323,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Maikel Miki - Jerusalema(2020)()*0*9/13/2020 9:13:33 PM",
    "Maikel Miki - Jerusalema ( Afrobeat - Afrohouse ) spanish Version Hit.mp3*8967820*9/9/2020 11:34:15 PM",
    "Maikel Miki - JERUSALEMA(Spanish Version , Master KG ft Nomcebo).mp3*8967820*9/9/2020 11:34:15 PM",
    17935640,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Maikel Miki - Maikel Miki(2020)()*0*9/13/2020 9:13:35 PM",
    "01.Maikel Miki - Como Ehh !!.mp3*7692000*9/9/2020 11:29:20 PM",
    "02.Maikel Miki - Flash.mp3*7847690*9/9/2020 11:29:39 PM",
    "03.Maikel Miki - Bomba.mp3*9168441*9/9/2020 11:30:00 PM",
    "04.Maikel Miki - Temblor.mp3*7919788*9/9/2020 11:30:21 PM",
    "05.Maikel Miki - The Boom ft Seo Fernandez ( Planet Rcord ).mp3*10591513*9/9/2020 11:30:36 PM",
    "06.Maikel Miki - POM  POM ft Nofilter ( Saifam Musci Italy ).mp3*4893459*9/9/2020 11:30:51 PM",
    "07.Maikel Miki - Flow.mp3*9049322*9/9/2020 11:31:05 PM",
    "08.Maikel Miki - Pegao ft Sapienza.mp3*7044124*9/9/2020 11:31:17 PM",
    "09.Maikel Miki - Gozalo Mamacita.mp3*9247853*9/9/2020 11:31:28 PM",
    "10.Maikel Miki - Jerusalema ft Joseph Sinatra x Cacciola ( Dance Remix ).mp3*7209523*9/9/2020 11:31:38 PM",
    "11.Maikel Miki - JERUSALEMA ( Spanish Version , Master KG ft Nomcebo ) wav.mp3*8967820*9/9/2020 11:31:48 PM",
    "12.Maikel Miki - Jerusalema ( Afrobeat - Afrohouse ) spanish Version Hit.mp3*8967820*9/9/2020 11:31:55 PM",
    "13.Maikel Miki - Pegajosa ft Mike Morato ( The King of Mashup.mp3*7978568*9/9/2020 11:31:59 PM",
    "14.Maikel Miki -Reggaeton x Cubaton ( LA NUEVA CRUZ ).mp3*7729616*9/9/2020 11:32:05 PM",
    "15.Maikel Miki - El Amor.mp3*9167439*9/9/2020 11:32:08 PM",
    "16.Maikel Miki - Caliente.mp3*12608245*9/9/2020 11:32:10 PM",
    "17.Maikel Miki - Me Enamore.mp3*10472516*9/9/2020 11:32:13 PM",
    "18.Maikel Miki ( HASTA BAJO )ft Jonh Joaquim , France.mp3*8368049*9/9/2020 11:32:14 PM",
    "19.Maikel Miki - PEGATE.mp3*6933472*9/9/2020 11:32:16 PM",
    "20.Maikel Miki - ZUKU ZUKU.mp3*9677362*9/9/2020 11:32:18 PM",
    171534620,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Maikel Miki - Rough Diamond(2020)()*0*9/13/2020 9:13:37 PM",
    "01.Maikel Miki - DURO(Rough Diamond).mp3*8247886*9/9/2020 11:29:20 PM",
    "02.Maikel Miki - Libres(Rough Diamond ).mp3*9567592*9/9/2020 11:29:39 PM",
    "03.Maikel Miki - Ella tiene algo(Rough Diamond).mp3*9861208*9/9/2020 11:30:01 PM",
    "04.Maikel Miki - Dejalo( Rough Diamond ).mp3*12251935*9/9/2020 11:30:21 PM",
    "05.Maikel Miki - Prendelo(Rough Diamond) prod by Geniotheproducer.mp3*8928114*9/9/2020 11:30:36 PM",
    "06. Maikel Miki - Fugitivos( Rough Diamond).mp3*10768180*9/9/2020 11:30:46 PM",
    "07.Maikel Miki - Guapo(Rough Diamond ).mp3*9527886*9/9/2020 11:31:05 PM",
    "08.Maikel Miki - Fresca(Rough Diamond).mp3*10168408*9/9/2020 11:31:17 PM",
    "09. Maikel Miki - Yo quiero Ron(Rough Diamond).mp3*10367984*9/9/2020 11:31:25 PM",
    "10. Maikel Miki - Potranca(Rough Diamond ).mp3*9608343*9/9/2020 11:31:36 PM",
    "11. Maikel Miki - Duro Version Reggaeton(Rough Diamond ).mp3*8247886*9/9/2020 11:31:46 PM",
    "12.Maikel Miki - Outro FInal X Geniotheproducer (Rough Diamond ).mp3*9608343*9/9/2020 11:31:55 PM",
    117153765,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Maikol the One(2020)()*0*9/13/2020 9:13:38 PM",
    "Maikol ft Barreiro - Mi Kardasha - .mp3*8350286*9/9/2020 11:34:16 PM",
    "Maikol the one  - Que quieres q le haga -  (Star Factory productions X Comandancia).mp3*7754694*9/9/2020 11:34:16 PM",
    "Maikol The One - Mi vida Te Pertenece - .mp3*8713910*9/9/2020 11:34:17 PM",
    "Maikol The One ft Kowa - A la Mano - .mp3*8953192*9/9/2020 11:34:17 PM",
    "Maikol the one ft Papitico - La otunidad - .mp3*9152767*9/9/2020 11:34:17 PM",
    "Maikol The One -Loco x ti - .mp3*7324196*9/9/2020 11:34:17 PM",
    "Maikol the One x Barreiro x  Helinton el Chaval  - Warachea KuKu -  .mp3*8841388*9/9/2020 11:34:18 PM",
    "Maikol x J. Violent - Vete de mi vida - .mp3*7675282*9/9/2020 11:34:18 PM",
    "Ya te olvide - Maikol the one ft John Kawa x A.T - Joe MC.mp3*10578008*9/9/2020 11:35:10 PM",
    77343723,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Maite Rivero -(2020)(CUBANO)*0*9/13/2020 10:33:18 PM",
    "Mama Inez ( Final Mix ) ok.mp3*3710670*9/9/2020 11:34:18 PM",
    "Perdoname ( Final Mix ) Maite Rivero ok.mp3*3820906*9/9/2020 11:34:38 PM",
    7531576,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Marlon 94 - Quiero Entrar(2020)()*0*9/13/2020 11:19:22 PM",
    "01.Marlon94 Ft Yordy - Amigos No.mp3*6548882*9/9/2020 11:29:20 PM",
    "02.Marlon 94 x D MenT - Quiero Entrar.mp3*3579243*9/9/2020 11:29:40 PM",
    "03.Marlon94 x Tanger - Tu Mejor Pieza.mp3*8047998*9/9/2020 11:30:01 PM",
    "04.Gnio &amp; Dayroni X Marlon94 - Tu Primera Vez.mp3*8226183*9/9/2020 11:30:18 PM",
    26402306,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Marlon x Joldan x Melo(2020)()*0*9/13/2020 9:50:56 PM",
    "Marlon x Joldan x Melo - Me Abandonastes -  .mp3*8613600*9/9/2020 11:34:19 PM",
    "Yordan x Melo x Marlon - Campeona profesionar - .mp3*7008637*9/9/2020 11:35:19 PM",
    15622237,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Massimo Ferrara - Mi Mejor Receta(2020)()*0*9/13/2020 11:19:25 PM",
    "01.Massimo Ferrara - Cuando Suena El Dembow.mp3*3319899*9/9/2020 11:29:20 PM",
    "02.Massimo Ferrara - Llorando Sola.mp3*9743097*9/9/2020 11:29:40 PM",
    "03.Massimo Ferrara - Nadie Como Yo.mp3*4223319*9/9/2020 11:30:01 PM",
    "04.Massimo Ferrara - Escuchame.mp3*3833175*9/9/2020 11:30:21 PM",
    "05.Massimo Ferrara - Hay Un Dueño.mp3*8272119*9/9/2020 11:30:37 PM",
    "06.Massimo Ferrara Ft Ikaro MC - Ella Es Candela.mp3*8728599*9/9/2020 11:30:51 PM",
    "07.Massimo Ferrara &amp; PuchoMan Feat El Medico - Waka Waka.mp3*8587440*9/9/2020 11:31:06 PM",
    "08.Massimo Ferrara Feat Yoanis Star - Ven bailalo.mp3*10179864*9/9/2020 11:31:17 PM",
    "09.Massimo Ferrara Ft. PuchoMan - Yo Soy Tu Maestro.mp3*4006607*9/9/2020 11:31:28 PM",
    "10.Massimo Ferrara - Nadie Como Yo.mp3*4279680*9/9/2020 11:31:39 PM",
    "11.Massimo Ferrara Ft Un Titico - Bum Bum Ye.mp3*3496007*9/9/2020 11:31:48 PM",
    "12.Charanga Habanera Ft Massimo Ferrara - Facciamo El Amore.mp3*5603631*9/9/2020 11:31:53 PM",
    "13.Massimo Ferrara - Mi Mejor Receta.mp3*2892316*9/9/2020 11:32:00 PM",
    77165753,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\MAX PRO MUSIC BY PIVE(2020)()*0*9/13/2020 11:30:13 PM",
    ".CRUZANDO LOS DEDOS-GNIO X DAYRONI X CRISTIANCITO-MAX PRO MUSIC BY PIVE.mp3*49495*9/9/2020 11:29:06 PM",
    ".MI NEGRITO-GNIO X DIRONI X ANDI CALIENTA X CUCO- MAX PRO MUSIC BY PIVE.mp3*49495*9/9/2020 11:29:07 PM",
    ".MIS SUEÑOS-ASHELI XCHARLES X PUIG-MAX PRO MUSIC BY PIVE.mp3*49495*9/9/2020 11:29:07 PM",
    ".RICO TITI-LANDY EL 13-MAX PRO MUSIC BY PIVE.mp3*49495*9/9/2020 11:29:07 PM",
    ".SIN TI-RICHARD-MAX PRO MUSIC BY PIVE.mp3*49495*9/9/2020 11:29:07 PM",
    ".VOLVERE-CHARLES X PUIG -MAX PRO MUSIC BY PIVE.mp3*49495*9/9/2020 11:29:07 PM",
    "CRUZANDO LOS DEDOS-GNIO X DAYRONI X CRISTIANCITO-MAX PRO MUSIC BY PIVE.mp3*8580099*9/9/2020 11:33:09 PM",
    "CUALQUIER COSA-GNIO X DAYRONI-MAX PRO MUSIC BY PIVE.mp3*4358685*9/9/2020 11:33:09 PM",
    "HECHAME BALA-CHARLES X PUIG-MAX PRO MUSIC BY PIVE.mp3*7892103*9/9/2020 11:33:46 PM",
    "LAS MALDITAS-EMINENCIA 3 X POPI-MAX PRO MUSIC BY PIVE.mp3*7190535*9/9/2020 11:34:06 PM",
    "MI NEGRITO-GNIO X DIRONI X ANDI CALIENTA X CUCO- MAX PRO MUSIC BY PIVE.mp3*4911696*9/9/2020 11:34:22 PM",
    "MIS SUEÑOS-ASHELI XCHARLES X PUIG-MAX PRO MUSIC BY PIVE.mp3*7666883*9/9/2020 11:34:23 PM",
    "REGAETON Y WHATSAPP-GNIO X DAIRONY-MAX PRO MUSIC BY PIVE.mp3*5109673*9/9/2020 11:34:43 PM",
    "RICO TITI-LANDY EL 13-MAX PRO MUSIC BY PIVE.mp3*7162347*9/9/2020 11:34:44 PM",
    "SACLA DE AQUI-GNIO X DAIRONY-MAX PRO MUSIC BY PIVE.mp3*9289610*9/9/2020 11:34:48 PM",
    "SAL DE ARRIBA DE MI-COSA MIA X MINY CUORE X MAX PRO MUSIC.mp3*8339799*9/9/2020 11:34:49 PM",
    "SIN TI-RICHARD-MAX PRO MUSIC BY PIVE.mp3*7725063*9/9/2020 11:34:52 PM",
    "VOLVERE-CHARLES X PUIG -MAX PRO MUSIC BY PIVE.mp3*9211479*9/9/2020 11:35:06 PM",
    87734942,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Maxima Alerta - Deja La Locura(2020)()*0*9/13/2020 11:24:52 PM",
    "Maxima Alerta - Deja La Locura.mp3*8636934*9/9/2020 11:34:20 PM",
    8636934,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Maykel Blanco &amp; Su Salsa Mayor - Festival De La Salsa 5ta Edicion(2020)*0*9/13/2020 9:51:04 PM",
    "01- Afloja la Guayaba.mp3*17855722*9/9/2020 11:29:09 PM",
    "02- Chaca Chaca.mp3*19054220*9/9/2020 11:29:30 PM",
    "03- Que Tiene Que Te Mueve.mp3*22815853*9/9/2020 11:29:53 PM",
    "04- Yo Comprendo.mp3*19259020*9/9/2020 11:30:12 PM",
    "05- Te Escapaste.mp3*24833551*9/9/2020 11:30:31 PM",
    "06- Ya Se Acabó.mp3*22863918*9/9/2020 11:30:46 PM",
    "07- pre Que Llueve Escampa.mp3*24515902*9/9/2020 11:31:00 PM",
    151198186,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Maykel Blanco &amp; Su Salsa Mayor - MB Records(2020)*0*9/13/2020 11:19:25 PM",
    "Alerta Roja - Soñando Contigo.mp3*10965665*9/9/2020 11:32:46 PM",
    "Alex La Nueva Voz - Valió La Pena.mp3*8522238*9/9/2020 11:32:47 PM",
    "Arturo Cruz y Ashe Okun ft Mayito Rivera - Donde Estabas Tú.mp3*9847651*9/9/2020 11:32:53 PM",
    "Christian y Rey ft  Maza - La Unión Perfecta.mp3*12164360*9/9/2020 11:33:03 PM",
    "Djs Timberos feat. Dayan Carrera - Todo Empezó Bailando.mp3*11687893*9/9/2020 11:33:15 PM",
    "Maichel Blanco - Ella No Está.mp3*11383624*9/9/2020 11:34:14 PM",
    "Maykel Blanco y su Salsa Mayor - La Lengua.mp3*11224177*9/9/2020 11:34:20 PM",
    "RSK, J PRINCE y REAL - No Hay.mp3*8100555*9/9/2020 11:34:47 PM",
    "Tania Pantoja - Ya No Me Busques.mp3*10237371*9/9/2020 11:34:54 PM",
    "Yordis Larrazabal - Addimú Pa´ Mi Timba.mp3*10924103*9/9/2020 11:35:19 PM",
    105057637,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Maykel Blanco Y Su Salsa Mayor - Que Tiene Que Te Mueve(2020)*0*9/13/2020 11:19:25 PM",
    "01- Ya Tú No Coronas.mp3*11617061*9/9/2020 11:29:11 PM",
    "02- Lo Que Sobra.mp3*11975780*9/9/2020 11:29:31 PM",
    "03- Que Tiene Que Te Mueve.mp3*13356816*9/9/2020 11:29:54 PM",
    "04- Yo Comprendo.mp3*12306693*9/9/2020 11:30:12 PM",
    "05- Cogele La Chapa.mp3*12472830*9/9/2020 11:30:29 PM",
    "06- Mi Monte.mp3*11846936*9/9/2020 11:30:44 PM",
    "07- Chaca Chaca.mp3*10230465*9/9/2020 11:30:58 PM",
    "08- En La Selva.mp3*14457091*9/9/2020 11:31:12 PM",
    "09- La Cafetera feat Yoruba Andabo.mp3*15373467*9/9/2020 11:31:24 PM",
    "10- La Lengua.mp3*11224177*9/9/2020 11:31:35 PM",
    "11- Dejame Volver.mp3*13184406*9/9/2020 11:31:44 PM",
    "12- Ya Se Acabó.mp3*10797437*9/9/2020 11:31:52 PM",
    148843159,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Maykel Blanco Y Su Salsa Mayor - Temas En Vivo(2020)*0*9/13/2020 8:58:38 PM",
    "01 YA TU NO CORONAS.mp3*18673878*9/9/2020 11:29:11 PM",
    "02 QUE TIENE QUE TE MUEVE.mp3*18469078*9/9/2020 11:29:32 PM",
    "03 MI MONTE.mp3*17811837*9/9/2020 11:29:52 PM",
    54954793,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Mi Oro - Toxica(Prod By Ernesto Losa-T.M)(2020)()*0*9/13/2020 8:58:37 PM",
    "Mi Oro - Toxica(Prod By Ernesto Losa-T.M).mp3*5042265*9/9/2020 11:34:22 PM",
    5042265,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Miny Cuore - En Las Nubes(2020)()*0*9/13/2020 8:58:38 PM",
    ".SAL DE ARRIBA DE MICOSA MIA X MINY CUORE X MAX PRO MUSIC.mp3*49495*9/9/2020 11:29:07 PM",
    "10-ANDANDILE-LA MINY CUORE(MAS PRO MUSIC BY PIVE).mp3*6679494*9/9/2020 11:31:40 PM",
    "11-A MI MANERA -LA MINY CUORE X  DUKE X EL YEYO(MAS PRO MUSIC BY PIVE).mp3*6706661*9/9/2020 11:31:49 PM",
    "1-DESCONTROL-LA MINY CUORE FT GNIO &amp; DAIRONY(MAS PRO MUSIC BY PIVE).mp3*8530008*9/9/2020 11:29:26 PM",
    "2-A LA CARA-LA MINY CUORE-{MAS PRO MUSIC PIVE PRODUCE).mp3*8204000*9/9/2020 11:29:48 PM",
    "4-MUJER DECIDIDA-LA MINY CUORE(MAS PRO MUSIC BY PIVE).mp3*6658596*9/9/2020 11:30:26 PM",
    "5-MUEVELO -LA MINY CUORE  X EL LOBO MALO(MAS PRO MUSIC BY PIVE).mp3*6745322*9/9/2020 11:30:43 PM",
    "6-EN LAS NUBES-LA MINY CUORE(MAS PRO MUSIC BY PIVE).mp3*8112049*9/9/2020 11:30:56 PM",
    "7GANAS DE TI -LA MINY CUORE X ANUBIX X JAIRO (MAS PRO MUSIC BY PIVE).mp3*8564490*9/9/2020 11:31:09 PM",
    "8-MODO DE AVION -LA MINY CUORE (MAS PRO MUSIC BY PIVE).mp3*6709796*9/9/2020 11:31:22 PM",
    "9-OYE ESO -LA MINY CUORE X KAPOLAN(MAS PRO MUSIC BY PIVE).mp3*7918743*9/9/2020 11:31:32 PM",
    "EL PADRE-MINY CUORE-MAX PRO MUSIC BY PIVE.mp3*8441247*9/9/2020 11:33:25 PM",
    "NO TE  QUIERE MINY CUORE FEAT HARRYSON  WAV.mp3*7888441*9/9/2020 11:34:28 PM",
    "PA Q TE ARDA X MINY CUORE X WILDEY(MAS PRO MUSIC BY PIVE).mp3*7247918*9/9/2020 11:34:33 PM",
    "SAL DE ARRIBA DE MICOSA MIA X MINY CUORE X MAX PRO MUSIC.mp3*8339799*9/9/2020 11:34:48 PM",
    "SEGUROSKY-MINY CUORE-MAX PRO MUSICBY PIVE.mp3*6422718*9/9/2020 11:34:50 PM",
    "WOW-MINY CUORE-MAX PRO MUSIC BY PIVE.mp3*8978269*9/9/2020 11:35:09 PM",
    122197046,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Mistr Will D.Cuba - Real Life(2020)()*0*9/13/2020 8:58:34 PM",
    "00.Mistr Will D.Cuba - Pa Estar Conmigo.mp3*9211719*9/9/2020 11:29:07 PM",
    "01.Mistr Will D.Cuba Ft Yoldia - Te Bote(Remix).mp3*10686860*9/9/2020 11:29:21 PM",
    "02.Mistr Will D.Cuba - Descontrol.mp3*8866155*9/9/2020 11:29:40 PM",
    "03.Mistr Will D.Cuba Ft El Yosva - que Te Vas De Mi.mp3*5387799*9/9/2020 11:30:01 PM",
    "04.Mistr Will D.Cuba - Tranca.mp3*9915375*9/9/2020 11:30:21 PM",
    "05.Mistr Will D.Cuba - Lo Menean.mp3*4192716*9/9/2020 11:30:37 PM",
    "06.Mistr Will D.Cuba Ft Acuna And Yoldia Ell Liberal - Que Locura.mp3*9754626*9/9/2020 11:30:51 PM",
    "07.Mistr Will D.Cuba - Me Tienes Loco.mp3*4538310*9/9/2020 11:31:06 PM",
    "08.Mistr Will D.Cuba Ft Yoldia El Liberal - Nunca Andas Sola.mp3*10871679*9/9/2020 11:31:18 PM",
    "09.Mistr Will D.Cuba Ft Cuban Official - Oh My God.mp3*8470359*9/9/2020 11:31:28 PM",
    81895598,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Morocho(2020)()*0*9/13/2020 8:58:33 PM",
    "14904805587780370602.mp3*6620439*9/9/2020 11:32:42 PM",
    "14904805587780370604.mp3*8926359*9/9/2020 11:32:42 PM",
    "14918328485585355002.mp3*7326999*9/9/2020 11:32:42 PM",
    "14924975140749443396.mp3*7326999*9/9/2020 11:32:42 PM",
    "15165896297516892334.mp3*5726679*9/9/2020 11:32:44 PM",
    "15168148097330577579.mp3*8366679*9/9/2020 11:32:45 PM",
    44294154,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\MP3 REGGAETON VOL 64 2020*0*9/13/2020 8:58:30 PM",
    0,
    "928"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\MP3 REGGAETON VOL 64 2020\\MP3*0*9/13/2020 11:21:50 PM",
    "01.Yomil &amp; El Dany Ft Kimiko &amp; Yordy - Tengo Una Pila.mp3*5637486*9/9/2020 11:29:24 PM",
    "02.Enzo La Melodia Secreta Ft Harryson - Corazon Partido.mp3*3951647*9/9/2020 11:29:37 PM",
    "03.DIVAN - Tutorial(Planet Records &amp; La Oficina Secreta).mp3*4276646*9/9/2020 11:29:56 PM",
    "04.Kimiko Ft Allen Ramos - Hagamos El Sexo.mp3*5277730*9/9/2020 11:30:19 PM",
    "05.Chris Tamayo - Ratito.mp3*4957257*9/9/2020 11:30:32 PM",
    "06.Amilkele - Condename.mp3*3963177*9/9/2020 11:30:47 PM",
    "07.El Duro - Mujeres Pierdan El Control.mp3*4437143*9/9/2020 11:31:02 PM",
    "08.Enzo La Melodia Secreta Ft Lobo King Dowa - Tu Novio.mp3*5739677*9/9/2020 11:31:15 PM",
    "09.Wil Campa - La Cama.mp3*5286018*9/9/2020 11:31:29 PM",
    "10.Maxima Alerta - Deja La Locura.mp3*6031830*9/9/2020 11:31:39 PM",
    "100.Julio Kaiser - Cero Compromiso.mp3*3476008*9/9/2020 11:32:37 PM",
    "101.Julio Kaiser - Abusadora.mp3*7762839*9/9/2020 11:32:38 PM",
    "102.Julio Kaiser - Como Me Gusta.mp3*8093079*9/9/2020 11:32:38 PM",
    "11.Charles Luis - 2 Locos.mp3*4975056*9/9/2020 11:31:46 PM",
    "12.Erick Lexi - Mujer Bonita.mp3*4576950*9/9/2020 11:31:54 PM",
    "13.Lady Laura - No Me Controlas.mp3*4738073*9/9/2020 11:31:59 PM",
    "14.El Samu - Pegueta Pegueta(Prod By Dj Volty)(53093431).mp3*5363131*9/9/2020 11:32:03 PM",
    "15.Kimiko &amp; Yordy Ft Jhon Menez - Caperucita.mp3*5090413*9/9/2020 11:32:07 PM",
    "16.Mi Oro - Toxica(Prod By Ernesto Losa-T.M).mp3*5433250*9/9/2020 11:32:11 PM",
    "17.Los Yakuza - Amnesia(58877280).mp3*6035837*9/9/2020 11:32:12 PM",
    "18.Nelson Manuel Ft La Corte - Bumbata.mp3*5071360*9/9/2020 11:32:15 PM",
    "19.Nersy Labrada - Mala.mp3*5023330*9/9/2020 11:32:17 PM",
    "20.Julio Kaiser - Cuando Tin Tiene.mp3*5617042*9/9/2020 11:32:17 PM",
    "21.Pedrito Kmacho &amp; El Clan - Acabé.mp3*5317992*9/9/2020 11:32:19 PM",
    "22.Sandy El White Ft Mr Vazquez - One Night Stand.mp3*4358531*9/9/2020 11:32:20 PM",
    "23.Nelson Manuel Ft Giselle - Sin Medida.mp3*3878540*9/9/2020 11:32:21 PM",
    "24.Jacob Forever Ft El Chulo,Taiger,Lenier,Micha &amp; Uniko - Mas Rico Mañana.mp3*8743967*9/9/2020 11:32:22 PM",
    "25.El Kamel Ft Jose Yamil(El Chavo) - Con La Misma Moneda(Facebook-Jose Yamil-Instagram Joseyamil real).mp3*4248189*9/9/2020 11:32:23 PM",
    "26.Jeikel Acosta Ft Srta Dayana - 14 De Febrero.mp3*6308937*9/9/2020 11:32:23 PM",
    "27.El Pichy - Inflador.mp3*4885404*9/9/2020 11:32:24 PM",
    "28.El Mayoral - Dandola.mp3*3939109*9/9/2020 11:32:24 PM",
    "29.Majela Ft Aaron Bodden - Independiente.mp3*4998253*9/9/2020 11:32:25 PM",
    "30.Lady Laura Ft El Taiger - Me Gusto.mp3*6275327*9/9/2020 11:32:26 PM",
    "31.El Micha vs DJ Shorty - Shake Señora (Planet Records &amp; La Oficina Secreta).mp3*4132450*9/9/2020 11:32:26 PM",
    "32.Ruly Rodriguez Ft Ydalia Suarez - Killer.mp3*4436898*9/9/2020 11:32:26 PM",
    "33.Enzo La Melodia Secreta Ft Chacal - El Mentiroso(DB).mp3*5771362*9/9/2020 11:32:27 PM",
    "34.Jeikel Acosta - Ahora Te Vas(AdrianoDJ by elCartelStudio).mp3*5189851*9/9/2020 11:32:27 PM",
    "35.Hanssel Ft Denver &amp; Danny - Roma.mp3*5133672*9/9/2020 11:32:27 PM",
    "36.Omi Hernandez Ft Solo Frank La Melodía - Ojalá.mp3*4698958*9/9/2020 11:32:27 PM",
    "37.Mistr Will D.Cuba Ft Cuban Official - Oh My God.mp3*5176354*9/9/2020 11:32:27 PM",
    "38.Amilkele &amp; Dj Unic Ft July Roby x Enviado x Denver &amp; El Kokito  - Tengo Ganas.mp3*5756849*9/9/2020 11:32:27 PM",
    "39.Osmani Garcia La Voz Ft Yomil &amp; El Dany,Yordy - Perrigatea.mp3*6052519*9/9/2020 11:32:27 PM",
    "40.Alex Duvall - Polea(Planet Records &amp; La Oficina Secreta).mp3*3995151*9/9/2020 11:32:28 PM",
    "41.A K U N A - Estamos Bien.mp3*3933711*9/9/2020 11:32:28 PM",
    "42.Tiarè - Cizaña Con Maldad.mp3*4818077*9/9/2020 11:32:28 PM",
    "43.Ruly Rodriguez Ft Kiki Aguero - Esta Noche.mp3*4977319*9/9/2020 11:32:28 PM",
    "44.Sandy El White Ft Mr Vazquez - Me Matas.mp3*5220816*9/9/2020 11:32:28 PM",
    "45.Roby Pulido - Cama y Mesa.mp3*6871301*9/9/2020 11:32:28 PM",
    "46.Orishango Ft Mendieta Music - Baila Con To.mp3*4346864*9/9/2020 11:32:28 PM",
    "47.Tiarè Ft Tony El Genial - Besame En Tus Sueños.mp3*4850295*9/9/2020 11:32:29 PM",
    "48.Felix Mc - Apotabolosa.mp3*5103334*9/9/2020 11:32:29 PM",
    "49.Reggal - Gucci &amp; Prada.mp3*5043393*9/9/2020 11:32:29 PM",
    "50.D´Lujo(Big Gaby &amp; El Filio) - Master Card(Contrataciones 53972754-76980111).mp3*4988222*9/9/2020 11:32:29 PM",
    "51.Karenia Diaz - Y Yo Que Decia.mp3*5046527*9/9/2020 11:32:29 PM",
    "52.Lady Laura Ft El Taiger - Amnesia(Planet Records &amp; La Oficina Secreta).mp3*5819543*9/9/2020 11:32:30 PM",
    "53.Jhon Menez Ft Yulien Oviedo - Que Tu No Llores.mp3*6962452*9/9/2020 11:32:30 PM",
    "54.El TIKKO x El MICHA - Partete TO´A.mp3*5291660*9/9/2020 11:32:30 PM",
    "55.Los Yakuza(YK) Ft Denver - Que Viva Shango.mp3*5643755*9/9/2020 11:32:30 PM",
    "56.Roby Pulido Ft Haila - El Amor Es Asi.mp3*6234331*9/9/2020 11:32:31 PM",
    "57.Nelson Diaz - Chica Vitamina.mp3*4269123*9/9/2020 11:32:31 PM",
    "58.Charanga Habanera Ft Chacal - Hacuna Maaa(Planet Records).mp3*8546726*9/9/2020 11:32:31 PM",
    "59.El Chiko De La Calle - De La Que Te Salvaste.mp3*4730530*9/9/2020 11:32:31 PM",
    "60.Chuchi Diamond - Quitarnos Las Ganas.mp3*4490815*9/9/2020 11:32:31 PM",
    "61.A K U N A - Dia De Suerte.mp3*4944336*9/9/2020 11:32:31 PM",
    "62.Chris Tamayo - Hello.mp3*4590116*9/9/2020 11:32:32 PM",
    "63.R3YMON - De  Life.mp3*5818289*9/9/2020 11:32:32 PM",
    "64.El Padrino Del Genero - Fiesta Na Ma.mp3*5301691*9/9/2020 11:32:32 PM",
    "65.Orishango - Carita De Mafiosa.mp3*4820202*9/9/2020 11:32:32 PM",
    "66.Kiki El Genio - Loco Loco.mp3*6521469*9/9/2020 11:32:32 PM",
    "67.Yakarta Ft El Carli &amp; El Yowa &amp; El Los De Los 90 - Pa Que Decirte.mp3*4768549*9/9/2020 11:32:32 PM",
    "68.Kimiko &amp; Yordy Ft Henry Manuel - Mala.mp3*4474132*9/9/2020 11:32:32 PM",
    "69.El Samu - Duro Duremio (Prod By Dj Volty)(53093431).mp3*5204578*9/9/2020 11:32:32 PM",
    "70.El Lucky Ta Loco - Agoniza(Contigo Me Voy De Rumba)(Abdel La Esencia &amp; Wailer).mp3*5520493*9/9/2020 11:32:33 PM",
    "71.QVA LIBRE Ft Descemer Bueno - Internacionales.mp3*4786978*9/9/2020 11:32:33 PM",
    "72.Charanga Habanera Ft Massimo Ferrara - Facciamo El Amore.mp3*5601995*9/9/2020 11:32:33 PM",
    "73.Baby Lores Ft Solo Frank La Melodia - No Me Llores.mp3*5385456*9/9/2020 11:32:33 PM",
    "74. Yero(El Kila) - A La Carandanga.mp3*6791298*9/9/2020 11:32:34 PM",
    "75.Leony Cabrera x Powell - Quiero Una Diabla.mp3*4428993*9/9/2020 11:32:34 PM",
    "76. Yero(El Kila) - Pa Mi Gente(Prevencion &amp; Control Del Coronavirus).mp3*5199500*9/9/2020 11:32:34 PM",
    "77.Frank El Imparable - No Finjas De Loca.mp3*4260973*9/9/2020 11:32:34 PM",
    "78.Charles Luis - Besame Lento.mp3*3587641*9/9/2020 11:32:34 PM",
    "79.El Pichy - Los Poderes.mp3*4996999*9/9/2020 11:32:34 PM",
    "80.Karenia Diaz - Tranquilita Y Relaja.mp3*5314857*9/9/2020 11:32:34 PM",
    "81.La Dura - Tu Me Pones.mp3*4316770*9/9/2020 11:32:35 PM",
    "82.Sr Nohaya &amp; Mr Don Ft Yeye x El Knaya - Maldita.mp3*3987628*9/9/2020 11:32:35 PM",
    "83.Roby Pulido - Que Locura.mp3*5954717*9/9/2020 11:32:35 PM",
    "84.El Samu - Tu Galán(Prod By Dj Volty)(53093431).mp3*4386116*9/9/2020 11:32:35 PM",
    "85.Kris Vera Ft Nesto - Te Esperare.mp3*4637518*9/9/2020 11:32:35 PM",
    "86.Leony Cabrera X El Powell - Otra Cara.mp3*4299216*9/9/2020 11:32:35 PM",
    "87.Nelson Diaz - Mi Cuba.mp3*4498583*9/9/2020 11:32:36 PM",
    "88.Jennifer Ksanova - El Hombre Que Yo Amo(Adriano Dj &amp; Dj Maikel Music)(53118098).mp3*5488546*9/9/2020 11:32:36 PM",
    "89.El Pichy - Que Se Enteren.mp3*4601401*9/9/2020 11:32:36 PM",
    "90.Jose Yamil(El Chavo) - Contigo Quiero Todo(Facebook-Jose Yamil-Instagram Joseyamil real).mp3*4534700*9/9/2020 11:32:36 PM",
    "91.Amilkele Ft Enzo - Fin de Semana.mp3*5507327*9/9/2020 11:32:36 PM",
    "92.QVA LIBRE  - Que Te Parece.mp3*4755874*9/9/2020 11:32:36 PM",
    "93.Negrito,Kokito &amp; Manu Manu Ft El Padrino Del Genero - Tu Eres.mp3*5906687*9/9/2020 11:32:36 PM",
    "94.Jayby x Dj Unic - Rio.mp3*5837724*9/9/2020 11:32:37 PM",
    "95.Sr Nohaya &amp; Mr Don Ft Yank3ll - La Cerebrina.mp3*4973802*9/9/2020 11:32:37 PM",
    "96.Ferri - Ya Es Muy Tarde.mp3*4404514*9/9/2020 11:32:37 PM",
    "97.Nelson Diaz - Devuelveme Mi Calma.mp3*4986968*9/9/2020 11:32:37 PM",
    "98.Papi Gansta - Safaera.mp3*4704610*9/9/2020 11:32:37 PM",
    "99.Julio Kaiser - Besito De Piquito.mp3*3117816*9/9/2020 11:32:37 PM",
    524521148,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\MrWill D.Cuba - Letra De Oro(2020)()*0*9/13/2020 11:22:03 PM",
    "!00-LOBO...MISTR WILL D.CUBA.mp3*9583101*9/9/2020 11:29:02 PM",
    "!01-TU NA MA...MISTR WILL D.CUBA.mp3*7854314*9/9/2020 11:29:02 PM",
    "!02-CAE LA NOCHE...MISTR WILL D.CUBA.mp3*9715681*9/9/2020 11:29:02 PM",
    27153096,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Musica Cristiana - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 11:28:54 PM",
    "10go - Destino(Prod By Dj Rodo)Blessed Music.mp3*8543554*9/9/2020 11:31:40 PM",
    "10go - Modo Gozo(Prod By Rodo)Blessed Music.mp3*9975064*9/9/2020 11:31:41 PM",
    "10go - No voy pa trás(Blessed Music).mp3*7476591*9/9/2020 11:31:41 PM",
    "10go - Tus Promesas(Prod By Rodo)Blessed Music.mp3*9697121*9/9/2020 11:31:41 PM",
    "10go Ft Ronald - Tu Presencia(Blessed Music).mp3*9913287*9/9/2020 11:31:41 PM",
    "2n1 - Lo Que Tengo Yo(Prod By Dj Rodo)Blessed Music.mp3*8200083*9/9/2020 11:29:48 PM",
    "2N1 - Una Vida Linda(Prod By Dj Rodo)Blessed Music).mp3*7470327*9/9/2020 11:29:48 PM",
    "Chiwycan &amp; Ronald - Venceras(Blessed Music).mp3*9033195*9/9/2020 11:33:01 PM",
    "Dando Vida - Aqui Estoy(Blessed Music).mp3*12325040*9/9/2020 11:33:12 PM",
    "El Arkitecto - Armadura De Diosl(Blessed Music).wav*44354256*1/2/2040 6:18:35 PM",
    "El Arkitecto &amp; MC Manuel - Dame una señal(Blessed Music).mp3*10433775*9/9/2020 11:33:17 PM",
    "Oney El Arkitecto - Cristo estara(Blessed Music).mp3*8597889*9/9/2020 11:34:28 PM",
    "Oney El Arkitecto - Puedes confiar en jesus(Blessed Music).mp3*9124517*9/9/2020 11:34:29 PM",
    "Oney El Arkitecto &amp; Melody - Cristo estara(Blessed Music).mp3*8645954*9/9/2020 11:34:29 PM",
    "Oney El Arkitecto &amp; Melody ft 2N1 - Cuando todo termina(Prod By Dj Rodo)Blessed Music.mp3*9032647*9/9/2020 11:34:29 PM",
    "Oney El Arkitecto Ft Naomi ,Karlitah &amp; Keren - Hermosa princesa(Blessed Music).mp3*8423391*9/9/2020 11:34:29 PM",
    "Ronald - Te esperare(Blessed Music).mp3*8052879*9/9/2020 11:34:47 PM",
    189299570,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Nelson Diaz - Acaramelao()*0*9/13/2020 11:32:03 PM",
    "01.Nelson Diaz - Acaramelao.mp3*7727526*9/9/2020 11:29:21 PM",
    "02.Nelson Diaz - Amante.mp3*7827837*9/9/2020 11:29:41 PM",
    "03.Nelson Diaz - Confecion De Amor.mp3*8035131*9/9/2020 11:30:01 PM",
    "04.Nelson Diaz - Se Va Conmigo.mp3*7356631*9/9/2020 11:30:21 PM",
    "05.Nelson Diaz - DJ Dale Play.mp3*7981437*9/9/2020 11:30:37 PM",
    "06.Nelson Diaz - Tatuaje De Amor.mp3*8194596*9/9/2020 11:30:52 PM",
    "07.Nelson Diaz - Te Extraño Tanto.mp3*8033682*9/9/2020 11:31:06 PM",
    "08.Nelson Diaz - Tu Mirada.mp3*10030215*9/9/2020 11:31:18 PM",
    "09.Nelson Diaz - Corazon Rebelde.mp3*8422427*9/9/2020 11:31:29 PM",
    "10.Nelson Diaz - Lo Nuestro Es.mp3*8088016*9/9/2020 11:31:39 PM",
    "11.Nelson Diaz - Ahora No Te Toca.mp3*7368082*9/9/2020 11:31:49 PM",
    "12.Nelson Diaz - Obsesion De Amarte.mp3*7662743*9/9/2020 11:31:56 PM",
    "13.Nelson Diaz Ft El Gio &amp; Romay - Majadera.mp3*8405751*9/9/2020 11:32:00 PM",
    105134074,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Nelson Diaz - Emigrante()(2020)*0*9/13/2020 11:11:43 PM",
    "01.Nelson Diaz - Rumba &amp; Fiesta.mp3*8048310*9/9/2020 11:29:21 PM",
    "02.Nelson Diaz - Chica Vitamina.mp3*6929224*9/9/2020 11:29:41 PM",
    "03.Nelson Diaz - Contrato.mp3*7395249*9/9/2020 11:30:02 PM",
    "04.Nelson Diaz - Devuelveme Mi Calma.mp3*7567657*9/9/2020 11:30:21 PM",
    "05.Nelson Diaz - El  Que.mp3*6447526*9/9/2020 11:30:38 PM",
    "06.Nelson Diaz - Amor Prohibido.mp3*6662775*9/9/2020 11:30:51 PM",
    "07.Nelson Diaz - Orgullosa.mp3*8648082*9/9/2020 11:31:06 PM",
    "08.Nelson Diaz - Emigrante.mp3*8368049*9/9/2020 11:31:18 PM",
    "09.Nelson Diaz - Infame.mp3*7088049*9/9/2020 11:31:29 PM",
    "10.Nelson Diaz - Mente Desnuda.mp3*8168473*9/9/2020 11:31:39 PM",
    "11.Nelson Diaz - Mi Cuba Tiene.mp3*7448539*9/9/2020 11:31:49 PM",
    "12.Nelson Diaz - Mi Vecina.mp3*7247918*9/9/2020 11:31:56 PM",
    "13.Nelson Diaz - Si Yo Pudiera.mp3*6212424*9/9/2020 11:32:00 PM",
    "14.Nelson Diaz - Te Amare.mp3*8807951*9/9/2020 11:32:05 PM",
    105040226,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Nelson Manuel - Nelson Manuel(2020)*0*9/13/2020 9:39:27 PM",
    "01.Nelson Manuel - Millonario En El Amor.mp3*8645992*9/9/2020 11:29:22 PM",
    "02.Nelson Manuel Ft Giselle - Sin Medida.mp3*2509666*9/9/2020 11:29:41 PM",
    "03.Nelson Manuel Ft Arney - Millonario En El Amor(Remix).mp3*8447334*9/9/2020 11:30:02 PM",
    19602992,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Nesty(2020)()*0*9/13/2020 9:39:31 PM",
    "Sin medida -- Nesty x El Yety - Star Factory.mp3*4603258*9/9/2020 11:34:52 PM",
    4603258,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Next level Record(2020)()*0*9/13/2020 9:39:40 PM",
    "Demente.mp3*8262502*9/9/2020 11:33:12 PM",
    "DileElSupremenftDawler&amp;ElKillerPrdByAmplifieR&amp;AnedNext.mp3*6551398*9/9/2020 11:33:13 PM",
    "EstasMalReynamsftAnedPrdByAmplifieR&amp;AnedNextLevelRecord.mp3*7833958*9/9/2020 11:33:35 PM",
    "MírameAnedCuellarNextLevelRecordsProdbyAmplifier.mp3*7246386*9/9/2020 11:34:23 PM",
    "MOJADITAFrancysfeatAmplifieRPrdbyAmplifieR&amp;AnedNextLevel.mp3*8849735*9/9/2020 11:34:24 PM",
    "SéquefuiyoFranklinXPablitoProdbyAmplifieR&amp;AnedNextLevel.mp3*7912529*9/9/2020 11:34:50 PM",
    46656508,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\NiNo Light NiNo - Rookie(2020)()*0*9/13/2020 10:15:48 PM",
    "NiNo Light NiNo - Rookie.mp3*3043840*9/9/2020 11:34:27 PM",
    3043840,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Ocean (version morfa)(2020)()*0*9/13/2020 10:15:48 PM",
    "J.Violent  - Ocean (version morfa)- .mp3*8262514*9/9/2020 11:33:52 PM",
    8262514,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\One Arian(2020)()*0*9/13/2020 10:15:48 PM",
    "One Arian - Esa Mulata - .mp3*6755771*9/9/2020 11:34:28 PM",
    6755771,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Orlenis 22K(2020)()*0*9/13/2020 10:15:47 PM",
    "Orlenis 22K - Olvidalo ft BLACK BOYS - DJ ROWA (Atocha).mp3*8884119*9/9/2020 11:34:30 PM",
    "Orlenis 22K - PARTY PLUS - DJ ROWA (Atocha).mp3*6661719*9/9/2020 11:34:31 PM",
    "Orlenis 22K - Se Demora ft KOKI ELKANO - DJ ROWA (Atocha).mp3*7522839*9/9/2020 11:34:31 PM",
    "Orlenis 22K - UN BESO ft DUKESITO - DJ ROWA (Atocha).mp3*7290519*9/9/2020 11:34:31 PM",
    "Orlenis 22K - UN CANAL - DJ ROWA (Atocha).mp3*5819763*9/9/2020 11:34:31 PM",
    "Orlenis 22K - Una Cara  Un Cuerpo (ft KIng Boys) - DJ ROWA (Atocha).mp3*7487319*9/9/2020 11:34:32 PM",
    43666278,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\OsielSmith x El menor(2020)()*0*9/13/2020 10:15:45 PM",
    "20 20 - Osiesmith x El Menor - .mp3*9848669*9/9/2020 11:32:17 PM",
    "Osiel - Adicto a tu piel - .mp3*8318939*9/9/2020 11:34:32 PM",
    "OsielSmith x El Menor - tu y yo - .mp3*7917698*9/9/2020 11:34:32 PM",
    "Osiesmith x El Menor -  Y dale Salte - .mp3*8268784*9/9/2020 11:34:32 PM",
    "Pal Pley  - Osiesmith x El Menor - .mp3*8309535*9/9/2020 11:34:34 PM",
    42663625,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Osmany Garcia - Singles(2020)()*0*9/13/2020 10:15:45 PM",
    "01.Osmany Garcia - Mi Hogar Eres Tú.mp3*4320640*9/9/2020 11:29:22 PM",
    "02.Osmani Garcia Ft Armani G. - Amor De Escuela.mp3*5012852*9/9/2020 11:29:41 PM",
    "03.Osmany Garcia ft Sencilla Conexion &amp; Rene Ortiz - Viva Las Vegas.mp3*4308898*9/9/2020 11:30:02 PM",
    "04.Osmani Garcia La Voz Ft Yomil &amp; El Dany,Yordy - Perrigatea.mp3*5639163*9/9/2020 11:30:22 PM",
    19281553,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Papitiko x El Nany(2020)()*0*9/13/2020 11:31:53 PM",
    "Papitico - Como voy a olvidarte - .mp3*6962661*9/9/2020 11:34:34 PM",
    "Papitiko x El Nany  - Pa Bellakeo - .mp3*7855004*9/9/2020 11:34:34 PM",
    "Papitiko x El Nany ft Comenda - Instagram  - .mp3*8897812*9/9/2020 11:34:35 PM",
    "Papitiko x El Nany ft klasic Boy - Kimba en el p9  2   - .mp3*8716000*9/9/2020 11:34:35 PM",
    32431477,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Pedritin ft  Alvarito x  BracoPapitiko x El Nany(2020)()*0*9/13/2020 10:15:44 PM",
    "Alvarito x  Braco ft Pedritin  - Esto se llama Warinnay - .mp3*7702449*9/9/2020 11:32:47 PM",
    "Alvarito x  Braco ft Pedritin  - Pa Robarnos el Show - .mp3*8603151*9/9/2020 11:32:48 PM",
    "Pedritin x Dinamico x J violent - Se bota - .mp3*9098433*9/9/2020 11:34:36 PM",
    25404033,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Pedrito Kmacho &amp; El Clan - Discografia(2020)(FUSION)*0*9/13/2020 10:15:42 PM",
    0,
    "945*946*947*948*949*950"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Pedrito Kmacho &amp; El Clan - Discografia(2020)(FUSION)\\1- Ese soy yo*0*9/13/2020 11:26:44 PM",
    "1- Me tiene cansao.mp3*9342771*9/9/2020 11:29:26 PM",
    "10- Abriendo caminos.mp3*7364153*9/9/2020 11:31:33 PM",
    "2- Que bien, que bien.mp3*7938428*9/9/2020 11:29:46 PM",
    "3- Tú con nada y yo contigo.mp3*7455267*9/9/2020 11:30:06 PM",
    "4- Atado.mp3*9545063*9/9/2020 11:30:24 PM",
    "5- Donde estás mi Lola.mp3*9255836*9/9/2020 11:30:40 PM",
    "6- Ese soy yo.mp3*6691238*9/9/2020 11:30:55 PM",
    "7- Esto está bueno.mp3*6367738*9/9/2020 11:31:08 PM",
    "8- Piedad.mp3*6153743*9/9/2020 11:31:20 PM",
    "9- Negrita linda.mp3*8668185*9/9/2020 11:31:31 PM",
    78782422,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Pedrito Kmacho &amp; El Clan - Discografia(2020)(FUSION)\\2- No tengo la culpa*0*9/13/2020 10:15:42 PM",
    "10- Ella me quiere matar.mp3*6901934*9/9/2020 11:31:35 PM",
    "11- Hoy voy a hacer una fiesta.mp3*5725797*9/9/2020 11:31:44 PM",
    "12- Yo no sé  qué.mp3*8534483*9/9/2020 11:31:52 PM",
    "1-Intro (Soy el que soy).mp3*4046437*9/9/2020 11:29:28 PM",
    "2- No tengo la culpa.mp3*6620230*9/9/2020 11:29:46 PM",
    "3- Pa la calle.mp3*7996988*9/9/2020 11:30:06 PM",
    "4- Tú eres mala.mp3*7395962*9/9/2020 11:30:25 PM",
    "5- El rey de tu cama.mp3*6545833*9/9/2020 11:30:40 PM",
    "6- Estoy tan solo.mp3*6058493*9/9/2020 11:30:55 PM",
    "7- Deja.mp3*5580348*9/9/2020 11:31:07 PM",
    "8- Ay pero que lindo estoy.mp3*7024814*9/9/2020 11:31:20 PM",
    "9- El desafinao.mp3*6733079*9/9/2020 11:31:31 PM",
    79164398,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Pedrito Kmacho &amp; El Clan - Discografia(2020)(FUSION)\\3- Pedrito Kmacho &amp; El Clan*0*9/13/2020 10:15:38 PM",
    "01 Tumba Y Deja.mp3*10171710*9/9/2020 11:29:10 PM",
    "02 Cintura Y Caderas.mp3*12126714*9/9/2020 11:29:31 PM",
    "03 Que Va a Ser De MI.mp3*8448673*9/9/2020 11:29:54 PM",
    "04 Tanto Que No Aguanto.mp3*8036983*9/9/2020 11:30:11 PM",
    38784080,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Pedrito Kmacho &amp; El Clan - Discografia(2020)(FUSION)\\4- Cero millas*0*9/13/2020 10:15:38 PM",
    "01 Sin Compromiso.mp3*4945587*9/9/2020 11:29:10 PM",
    "02 Soy Tu Papi.mp3*5082887*9/9/2020 11:29:32 PM",
    "03 Dale Duro.mp3*4677257*9/9/2020 11:29:51 PM",
    "04 Les t party all night.mp3*4766909*9/9/2020 11:30:11 PM",
    "05 Yo quiero conquistarte nena.mp3*5082260*9/9/2020 11:30:31 PM",
    "06 Nadie Como Tú.mp3*5452154*9/9/2020 11:30:44 PM",
    "07 Déjame.mp3*4828976*9/9/2020 11:30:58 PM",
    "08 Pana.mp3*5136176*9/9/2020 11:31:13 PM",
    "09 Poquito a Poquito.mp3*4592620*9/9/2020 11:31:24 PM",
    "10 A que no.mp3*4326798*9/9/2020 11:31:33 PM",
    48891624,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Pedrito Kmacho &amp; El Clan - Discografia(2020)(FUSION)\\sencillos*0*9/13/2020 8:46:21 PM",
    "- el techo.(PEDRITO CAMACHO CLAN).mp3*5154949*9/9/2020 11:28:59 PM",
    "Un ratico mala.mp3*8679666*9/9/2020 11:35:02 PM",
    "YO ME ACUERDO.mp3*7828446*9/9/2020 11:35:12 PM",
    21663061,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Pedrito Kmacho &amp; El Clan - Discografia(2020)(FUSION)\\SI o SI*0*9/13/2020 8:46:21 PM",
    "10- Coge salsa.mp3*9877746*9/9/2020 11:31:34 PM",
    "11- La amiga de mi mujer.mp3*8348466*9/9/2020 11:31:45 PM",
    "1-Dime si tú.mp3*9710706*9/9/2020 11:29:27 PM",
    "2- De un día para otro.mp3*8519346*9/9/2020 11:29:45 PM",
    "3- El tipo soy yo.mp3*7943346*9/9/2020 11:30:06 PM",
    "4- Un ratico mala.mp3*8679666*9/9/2020 11:30:25 PM",
    "5- No vuelves a mi lado.mp3*8736306*9/9/2020 11:30:40 PM",
    "6- Es mentira.mp3*9300786*9/9/2020 11:30:55 PM",
    "7- Que viva changó.mp3*9863346*9/9/2020 11:31:08 PM",
    "8- Mi obsesión.mp3*7512306*9/9/2020 11:31:20 PM",
    "9- No me voy a quedar dao.mp3*8872626*9/9/2020 11:31:31 PM",
    97364646,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Pepito King Wolf(2020)()*0*9/13/2020 8:46:09 PM",
    " el face.wav*67069940*11/22/2019 1:15:23 PM",
    "DESPACITO...PEPITO KING WOLF... EL IRET...PROD EXTREME MUSIC.wav*31853610*2/2/2020 11:42:56 PM",
    "EL PLAN.PEPITO KING WOLF...EL IRET...PROD EXTREME MUSIC...wav*30515542*2/2/2020 11:43:06 PM",
    "IRETE Y PEPITO KING WOLW-TELA PARTO.mp3*7255038*9/9/2020 11:33:50 PM",
    "IRETE Y PEPITO KING WOLW-YO KIERO PROBAR.mp3*7695678*9/9/2020 11:33:51 PM",
    "PEPITO KING WALLS X NENE - ELLA ES MALA - MAQUETA - LARRA DJ 58020966.mp3*7217679*9/9/2020 11:34:36 PM",
    "PEPITO KING WOLF FT EL IRETE-DIRLE A CUPIDO.mp3*7999038*9/9/2020 11:34:37 PM",
    "PEPITO KING WOLF FT EL IRETE-EL CABALLITO.mp3*8590398*9/9/2020 11:34:37 PM",
    168196923,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Piq Montano - En La Mesa Del Bar(2020)(POP)*0*9/13/2020 8:45:59 PM",
    "1.Piq Montano - Hola.mp3*8848434*9/9/2020 11:29:26 PM",
    "2.Piq Montano - Y Duele.mp3*10106439*9/9/2020 11:29:47 PM",
    "3.Piq Montano - Para No Pensar.mp3*10882146*9/9/2020 11:30:07 PM",
    "4.Piq Montano - En La Mesa Del Bar.mp3*9783858*9/9/2020 11:30:25 PM",
    "5.Piq Montano - Deja Que La Noche Avance.mp3*6791754*9/9/2020 11:30:41 PM",
    "6.Piq Montano - No Se Si Volvera.mp3*7560138*9/9/2020 11:30:56 PM",
    "7.Piq Montano - Tenemos Toda La Noche Mami.mp3*8042068*9/9/2020 11:31:09 PM",
    "8.Piq Montano - Todos Los Días.mp3*8514697*9/9/2020 11:31:21 PM",
    70529534,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Popi x El krawa ft El Cele x El menor(2020)()*0*9/13/2020 8:46:22 PM",
    "Popi x El krawa ft El Cele x El menor - Cual es tu Curralo - Flow Music - Star Factory productions.mp3*7777682*9/9/2020 11:34:42 PM",
    7777682,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Qva Libre - Funkimba(2020)()*0*9/13/2020 8:47:40 PM",
    "01 - Qvalibre - QUE ESTAS FORMANDO TU.mp3*3819638*9/9/2020 11:29:08 PM",
    "02 - Qvalibre - LA KIM KARDASHIAN.mp3*3608569*9/9/2020 11:29:30 PM",
    "03 - Qvalibre - QUE CALOR (Homenaje a Formell).mp3*3104510*9/9/2020 11:29:50 PM",
    "04 - Qvalibre - INTERNACIONALES FEAT DESCEMER BUENO REMIX.mp3*3177235*9/9/2020 11:30:09 PM",
    "05 - Qvalibre - QUE TE PARECE.mp3*3144216*9/9/2020 11:30:28 PM",
    "06 - Qvalibre - EL TERMOMETRO.mp3*3867285*9/9/2020 11:30:43 PM",
    "07 - Qvalibre - MONEY MONI.mp3*3025934*9/9/2020 11:30:58 PM",
    "08 - Qvalibre - VAMONOS CONMIGO.mp3*3471896*9/9/2020 11:31:10 PM",
    "09 - Qvalibre - ECHALE LA CULPA AL POMO.mp3*3039308*9/9/2020 11:31:23 PM",
    "10 - Qvalibre - DURAKO.mp3*3978046*9/9/2020 11:31:32 PM",
    "11 - Qvalibre - TIRATE UN PASE.mp3*3695505*9/9/2020 11:31:43 PM",
    37932142,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Real Life - Mistr Will()(2020)()*0*9/13/2020 8:47:16 PM",
    "!00-PA ESTAR CONMIGO...MISTR WILL D.CUBA.mp3*9216637*9/9/2020 11:29:02 PM",
    "!01-TE BOTE (REMIX)...MISTR WILL D.CUBA x YOLDIA.mp3*10686860*9/9/2020 11:29:02 PM",
    "!02-DESCONTROL...MISTR WILL D.CUBA.mp3*8870912*9/9/2020 11:29:02 PM",
    "!03- QUE TE VAS DE MI...MISTR WILL D.CUBA x EL YOSVA.mp3*5387799*9/9/2020 11:29:02 PM",
    "!04-TRANCA...MISTR WILL D.CUBA.mp3*9915375*9/9/2020 11:29:03 PM",
    "!05-LO MENEAN...MISTR WILL D.CUBA.mp3*8316724*9/9/2020 11:29:03 PM",
    "!06-Q LOKURA...MISTR WILL D.CUBA x AKUNA AND YOLDIA.mp3*9754941*9/9/2020 11:29:03 PM",
    "!07-ME TIENES LOCO...MISTR WILL D.CUBA.mp3*7981373*9/9/2020 11:29:03 PM",
    "!08-NUNCA ANDA SOLA...MISTR WILL D.CUBA x YOLDIA.mp3*10871679*9/9/2020 11:29:03 PM",
    "!10-100 PA BAJO...MISTR WILL D.CUBA.mp3*7986531*9/9/2020 11:29:03 PM",
    "!11-AMIGA X AMIGA...MISTR WILL D.CUBA x TU ALBE LA ROYA.mp3*10479380*9/9/2020 11:29:04 PM",
    "!12-PROMESAS DE AMOR...MISTR WILL D.CUBA.mp3*9288294*9/9/2020 11:29:04 PM",
    "!13-FUCKING PANDA...MISTR WILL (D.CUBA).mp3*9686694*9/9/2020 11:29:04 PM",
    "!14-OH MI GOD...MISTR WILL D.CUBA x CUBAN OFFICIAL.mp3*8470359*9/9/2020 11:29:05 PM",
    "!15-CANALLA...MISTR WILL D.CUBA.mp3*7641207*9/9/2020 11:29:05 PM",
    "!16-SALIR PA LA CALLE...MISTR WILL D.CUBA x LODIN.mp3*8838649*9/9/2020 11:29:05 PM",
    "!17-DE TI ME ENAMORE...MISTR WILL D.CUBA.mp3*10666297*9/9/2020 11:29:05 PM",
    "!18-PATABOOM...MISTR WILL D.CUBA.mp3*10929921*9/9/2020 11:29:06 PM",
    164989632,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Reggal - Gucci &amp; Prada(2020)()*0*9/13/2020 8:46:23 PM",
    "01.Reggal - Gucci &amp; Prada.mp3*7975650*9/9/2020 11:29:23 PM",
    "02.Reggal - MI Nena.mp3*7572666*9/9/2020 11:29:42 PM",
    15548316,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\ReyKing &amp; Jhonny - Como Tu No Hay(2020)()*0*9/13/2020 9:06:38 PM",
    "ReyKing &amp; Jhonny - Como Tu No Hay.mp3*7174431*9/9/2020 11:34:44 PM",
    7174431,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Rigel El Profe(2020)()*0*9/13/2020 9:06:38 PM",
    "05.PARQUIA YULINDO ft- RIGEL EL PROFEBy EL KRTEL.mp3*6823690*9/9/2020 11:30:38 PM",
    "06ENUNSEGUNDORIGELELPROFE&amp;YULINDOftLAREYNADEFLOW.mp3*7697224*9/9/2020 11:30:54 PM",
    "08.TLC DAYAN X RIGEL FT JEREMI--METAFORA.mp3*5293911*9/9/2020 11:31:19 PM",
    "09.TLC DAYAN X RIGEL--100 PA  ABAJOTORRE MUSIC.mp3*7797414*9/9/2020 11:31:29 PM",
    "10TLCDAYANXRIGELVELOCIPEDO2CYBORGRECORD´S54027605.mp3*7884795*9/9/2020 11:31:43 PM",
    "12.TLC DAYAN X RIGEL FT LA MEKANIK--PIENSA EN MI.mp3*5045271*9/9/2020 11:31:56 PM",
    "13.TLC DAYAN X RIGEL--MI LUGAR 2020TORRE MUSIC.mp3*7293894*9/9/2020 11:32:01 PM",
    "14.TLC DAYAN X RIGEL--OJO CON ESO--CYBORG RECORD´S 54027605.mp3*8200083*9/9/2020 11:32:05 PM",
    56036282,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Roberton x FitoPapitiko x El Nany(2020)()*0*9/13/2020 9:06:37 PM",
    "Roberton x Fito -  Vengo ire  - .mp3*7794400*9/9/2020 11:34:45 PM",
    "Roberton x Fito - Bellakita - .mp3*8045175*9/9/2020 11:34:45 PM",
    "Roberton x Fito - Es pal Laguito - .mp3*8810041*9/9/2020 11:34:45 PM",
    "Roberton x Fito - Sin intereses - .mp3*7184180*9/9/2020 11:34:45 PM",
    "Vivir del Cuento - Roberton x Te Botas - .mp3*6952212*9/9/2020 11:35:05 PM",
    38786008,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Roby Pulido - Mi Locura(2020)(SALSA)*0*9/13/2020 9:06:36 PM",
    "01.Roby Pulido - Cama y Mesa (Versión Salsa).mp3*6872766*9/9/2020 11:29:23 PM",
    "02.Roby Pulido - Que Locura.mp3*5948692*9/9/2020 11:29:42 PM",
    "03.Roby Pulido - Quererte A Mi Manera.mp3*5566259*9/9/2020 11:30:02 PM",
    "04.Roby Pulido - Duele.mp3*5173693*9/9/2020 11:30:22 PM",
    "05.Roby Pulido - Tengo El Control.mp3*5258238*9/9/2020 11:30:38 PM",
    "06.Roby Pulido - Mis Raices.mp3*6558704*9/9/2020 11:30:52 PM",
    "07.Roby Pulido Ft Haila - El Amor Es Asi.mp3*6311063*9/9/2020 11:31:06 PM",
    41689415,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Ruly Rodriguez - Kimika(2020)()*0*9/13/2020 9:06:35 PM",
    "01.Ruly Rodriguez Ft BESFORD - Rico Rico.mp3*2888813*9/9/2020 11:29:23 PM",
    "02.Ruly Rodriguez Ft Kiki Aguero - Esta Noche.mp3*3333939*9/9/2020 11:29:43 PM",
    "03.Ruly Rodriguez - Kimika RMX.mp3*3056594*9/9/2020 11:30:02 PM",
    "04.Ruly Rodriguez Ft Ydalia Suarez - Killer.mp3*3058922*9/9/2020 11:30:22 PM",
    12338268,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Salsa Vol 1 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 9:06:34 PM",
    "01- Gracil y sus cumbancheros - que tu quieres(Prod By Dj rodo).mp3*10409822*9/9/2020 11:29:10 PM",
    "03- Los Universales - (Victor Manuel Tellez Revé)Que Bien Me Siento(Blessed Music).mp3*8963603*9/9/2020 11:29:52 PM",
    "04- Kiuban Salsa - Te Quedaras Solo (Rogelio Sotolongo)(Blessed Music).mp3*16859897*9/9/2020 11:30:10 PM",
    "05- Gracil Y Sus Cumbancheros - Amor Amor(Prod By Dj Rodo)Blessed Music.mp3*12486999*9/9/2020 11:30:30 PM",
    "06- Yohana Abreu - Si Te Viera(Yosvani Laurencio)(Prod By Dj Rodo)Blessed Music.mp3*9486052*9/9/2020 11:30:46 PM",
    "07- Melao Ft Legendarios - Cuando Tin Tiene(Alberto Lopez)Blessed Music.mp3*9477731*9/9/2020 11:30:59 PM",
    "08- Gracil y sus cumbancheros - Toca el tres(Prod By Dj rodo).mp3*9069175*9/9/2020 11:31:12 PM",
    "09- Aroldis Aranda - Dame un Beso (Aroldis Aranda Escalona)Blessed Music.mp3*9428620*9/9/2020 11:31:23 PM",
    "10- Yohana Abreu - Estoy Buscando un hombre (D.R) Blessed Music.mp3*9366971*9/9/2020 11:31:36 PM",
    "10- Yohana Abreu - Estoy Buscando un hombre (D.R) Blessed Music.wav*41097532*10/11/2019 4:24:02 PM",
    136646402,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Salsa Vol 2 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 9:06:29 PM",
    "11- Los Universales - Señorita(Camila Cabello)Blessed Music.mp3*9673126*9/9/2020 11:31:45 PM",
    "12- Kiuban Salsa - Magico Hechizo (Rogelio Sotolongo)(Blessed Music).mp3*14310384*9/9/2020 11:31:51 PM",
    "14- Dando Vida - Aqui Estoy(Blessed Music).mp3*12316718*9/9/2020 11:32:02 PM",
    "15- Yohana Abreu - No mereces este amor (Idalberto Revé) Blessed Music .mp3*8381633*9/9/2020 11:32:06 PM",
    "16- Alberto López - Lucete(Alberto López)13- (Prod By Dj Rodo)Blessed Music).mp3*9860163*9/9/2020 11:32:09 PM",
    "17- Aroldis Aranda - Asere Son (Aroldis Aranda Escalona)Blessed Music.mp3*8859151*9/9/2020 11:32:11 PM",
    "18- Son De Aqui - Seguir Guarachando(Enrrique Delgado)Blessed Music.mp3*13229959*9/9/2020 11:32:13 PM",
    "19- Alberto López - Soltera(Alberto López)(Prod By Dj Rodo)Blessed Music).mp3*10609355*9/9/2020 11:32:15 PM",
    "Arold Aranda - Solo Tú (Aroldis Aranda)(Prod By Dj Rodo)Blessed Music.mp3*8080702*9/9/2020 11:32:53 PM",
    "Leskay &amp; El Auténtico - Recuerdos(Prod By Dj Rodo)Blessed Music.mp3*8870645*9/9/2020 11:34:09 PM",
    104191836,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Sensei ft BoloPapitiko x El Nany(2020)()*0*9/13/2020 9:06:20 PM",
    "Sensei ft Bolo - Final - .mp3*7067151*9/9/2020 11:34:50 PM",
    7067151,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Solo Frank La Melodía - No Me Llores(2020)()*0*9/13/2020 9:06:20 PM",
    "01.Omi Hernandez Ft Solo Frank La Melodía - Ojalá.mp3*2372488*9/9/2020 11:29:22 PM",
    "02.Baby Lores Ft Solo Frank La Melodia - No Me Llores.mp3*3065610*9/9/2020 11:29:33 PM",
    5438098,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Son Calle(2020)()*0*9/13/2020 9:06:20 PM",
    "AMOR PROHIBIDO-SON CALLE FEAT ESCUADRON SUICIDA-1600 MUSIC.mp3*5649604*9/9/2020 11:32:48 PM",
    "Perdoname - Son Calle ( 96kbps ).mp3*3388235*9/9/2020 11:34:37 PM",
    "SON CALLE - SONRISA DE PAPEL (MASTER MUSIC).mp3*9642786*9/9/2020 11:34:53 PM",
    "SON CALLE - UN AÑO cover (MASTER MUSIC).mp3*9985239*9/9/2020 11:34:53 PM",
    "Son Calle- Si No Me llamas.mp3*4288519*9/9/2020 11:34:53 PM",
    "SonCalleVamossoldadoftWhitneyKelly,VisaAbierta96kbps.mp3*4721943*9/9/2020 11:34:53 PM",
    37676326,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Suena a Cubano(2020)()*0*9/13/2020 9:06:19 PM",
    "001.Bailen Con La Punta Del Pie - SUENA A CUBANO feat. TIKKO.mp3*3756794*9/9/2020 11:29:08 PM",
    "01.Con La Puntika.mp3*9371113*9/9/2020 11:29:14 PM",
    "02.mayami.mp3*9847829*9/9/2020 11:29:40 PM",
    "03Rica tu.mp3*6685725*9/9/2020 11:30:05 PM",
    "04.Dinero.mp3*4800297*9/9/2020 11:30:15 PM",
    "05.PELLIZCAME.mp3*4638999*9/9/2020 11:30:38 PM",
    "06.No Estas Lista----livan sama-elioxis perez---SUENA A CUBANO.mp3*9233186*9/9/2020 11:30:52 PM",
    "07.RAMPAPAN.mp3*4126917*9/9/2020 11:31:06 PM",
    "08 Como Te Pusiste.mp3*9211479*9/9/2020 11:31:11 PM",
    "09.TE LA VOY A PONER.mp3*5196495*9/9/2020 11:31:29 PM",
    "10.A Rey Muerto.mp3*8973007*9/9/2020 11:31:37 PM",
    "11.El  PUM PUM.mp3*6039678*9/9/2020 11:31:46 PM",
    "12.Amor Amor.mp3*4357119*9/9/2020 11:31:53 PM",
    "13.YAYA.mp3*10428550*9/9/2020 11:32:01 PM",
    "14. A Cojer La Cola.mp3*4732959*9/9/2020 11:32:03 PM",
    "15.Fumiga Y Cierra.mp3*10037986*9/9/2020 11:32:07 PM",
    "16. DEPENDE DE TI.mp3*9669976*9/9/2020 11:32:09 PM",
    "17.La Gloria Soy Yo.mp3*9337676*9/9/2020 11:32:12 PM",
    "18.A Recogerse.mp3*9549790*9/9/2020 11:32:14 PM",
    "CON LA PUNTICA-SUENA A CUBANO feat. TIKKO.wav*61361798*7/4/2019 2:08:00 PM",
    "MK - PARTY...SUENA CUBANO x LOS PIKAROS.mp3*7724439*9/9/2020 11:34:24 PM",
    "MKT SE FORMO..SUENA A CUBANO  mk con metales.mp3*9411159*9/9/2020 11:34:24 PM",
    "Pa Que Te Lo GozesSuena A Cubano(Homenaje Al Benny More).mp3*8897774*9/9/2020 11:34:33 PM",
    227390745,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Talento x Gin tonik(2020)()*0*9/13/2020 8:49:01 PM",
    "Talento x Gin tonik x EL Chino - Quimbeta en la Piscina - .mp3*7528996*9/9/2020 11:34:54 PM",
    "Talento x Gin tonik x Roynelito - La profesora - .mp3*8500751*9/9/2020 11:34:54 PM",
    16029747,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\The Shepal - Yo Soy El Malo(POP)*0*9/13/2020 8:49:14 PM",
    "01 - The Shepal - No Puedo Parar (feat. Ernesto Blanco).mp3*7313477*9/9/2020 11:29:09 PM",
    "02 - The Shepal - Adios (feat. Roberto Perdomo(Tesis de Menta)).mp3*9013468*9/9/2020 11:29:30 PM",
    "03 - The Shepal - Voy A Estar Bien.mp3*10298379*9/9/2020 11:29:50 PM",
    "03 - Voy a estar Bien Feat. Osamu Menendez.mp3*10253045*9/9/2020 11:29:51 PM",
    "04 - The Shepal - No Eres Tú.mp3*7054822*9/9/2020 11:30:09 PM",
    "05 - The Shepal - Creo en ti (feat. Diana Ruz(Tracks)).mp3*9435096*9/9/2020 11:30:28 PM",
    53368287,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Three Show - El Arrebato(2020)()*0*9/13/2020 8:49:20 PM",
    "Three Show - El Arrebato by Meko.mp3*7974319*9/9/2020 11:34:58 PM",
    7974319,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Tiaré - Suerte(Adelanto 8)(2020)()*0*9/13/2020 8:58:39 PM",
    "01.Tiaré Ft Damian The Lion - Doble Cara(by. Tony el Genial)STARS MUSIC.mp3*7667643*9/9/2020 11:29:23 PM",
    "02.Tiaré - A Cualquier Hora(by. Tony el Genial)STARS MUSIC.mp3*8660487*9/9/2020 11:29:43 PM",
    "03.Tiaré Ft Damian The Lion - Interminable(by. Tony el Genial)STARS MUSIC.mp3*10612767*9/9/2020 11:30:03 PM",
    "04.Tiaré Ft. Damian The Lion - Haz Como El Mar(by. Tony el Genial)STARS MUSIC.mp3*8540427*9/9/2020 11:30:23 PM",
    "05.Tiaré - No Es Como Tu Quieras.mp3*5821603*9/9/2020 11:30:39 PM",
    "06.Tiaré - Ando Sola(by. Tony el Genial)STARS MUSIC.mp3*8149734*9/9/2020 11:30:53 PM",
    "07.Tiaré - Para Tocarte De Nuevo(by Tony el Genial).mp3*6987198*9/9/2020 11:31:06 PM",
    "08.Tiarè Ft Tony El Genial - Besame En Tus Sueños.mp3*7961546*9/9/2020 11:31:18 PM",
    "09.Tiarè - Dame La Otunidad.mp3*7494490*9/9/2020 11:31:29 PM",
    "10.Tiaré - Un Mundo Que No Sabe(by. Tony el Genial).mp3*10441088*9/9/2020 11:31:40 PM",
    "11.Tiaré Ft Damian The Lion - Sin Frenos(By Tony El Genial).mp3*8392179*9/9/2020 11:31:49 PM",
    "12.Tiarè - Tu Corazòn En El Cielo(By. Tony El Genial) Stars Music.mp3*6657512*9/9/2020 11:31:56 PM",
    "13.Tiarè - Cizaña Con Maldad.mp3*4818501*9/9/2020 11:32:00 PM",
    102205175,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Ticho - El Havano(2020)()*0*9/13/2020 8:50:22 PM",
    "10-Ticho El Havano-No queda nada.AM-PRO..mp3*8482041*9/9/2020 11:31:42 PM",
    "11-Ticho(El Havano)-Vamos a ver si arde.AM-PRO.mp3*9065107*9/9/2020 11:31:50 PM",
    "12TichoElHavanoComoTeExtrañoVersionSalsaGuettoAMPRO.mp3*9201975*9/9/2020 11:31:56 PM",
    "13-Ticho(El Havano)-Amor a 90 millas.AM-PRO..mp3*9200930*9/9/2020 11:32:02 PM",
    "14-Ticho(El Havano)- ti.AM-PRO..mp3*9419314*9/9/2020 11:32:05 PM",
    "15-Ticho(El Havano) &amp; El Pipo-Ananae.AM-PRO..mp3*9183167*9/9/2020 11:32:09 PM",
    "16-Ticho(El Havano)-A que te disparo.AM-PRO..mp3*9742188*9/9/2020 11:32:11 PM",
    "1-Ticho(El Havano)-Intro.Lo Que Suena Soy Yo.AM-PRO.mp3*6376570*9/9/2020 11:29:29 PM",
    "2-Ticho(El Havano)-Ponte pa tu talla.AM-PRO.mp3*7859281*9/9/2020 11:29:49 PM",
    "3-Ticho(El Havano)-A mi aire.AM-pro..mp3*8791329*9/9/2020 11:30:08 PM",
    "4 - Ticho(El Havano) - Maldita eres tu-AM-PRO.mp3*7553125*9/9/2020 11:30:24 PM",
    "5- Ticho(El havano)-Sigo siendo yo.AM-PRO..mp3*8153942*9/9/2020 11:30:41 PM",
    "6-Ticho(El Havano)-Es  tu amor.(DINASTIA RECORDS).1.mp3*8985680*9/9/2020 11:30:57 PM",
    "7-Ticho(El Havano)-Como Te extraño.AM-PRO..mp3*9153908*9/9/2020 11:31:09 PM",
    "8-Ticho(El Havano)-No vale la pena.AM-PRO.mp3*8104831*9/9/2020 11:31:22 PM",
    "9-Ticho(El Havano)-Tu eres la que se usa.AM-PRO..mp3*8807003*9/9/2020 11:31:32 PM",
    138080391,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Tony ft OnlyonePapitiko x El Nany(2020)()*0*9/13/2020 8:50:24 PM",
    "Tony ft Onlyone  - El amor en una relacion -  Star Factory  by Dinamiko.mp3*7407788*9/9/2020 11:34:58 PM",
    7407788,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Tony x Onlyne(2020)()*0*9/13/2020 8:50:39 PM",
    "1-BLOKEA-TONY X ONLYONE-PLAY BACK.mp3*8050791*9/9/2020 11:29:26 PM",
    "2-TONY X ONLYONE -NAVEGA (MPLAY BACK).mp3*8323479*9/9/2020 11:29:49 PM",
    "3-Tony x Only one ft Nesty Obligao...( Una Relacion).mp3*4922437*9/9/2020 11:30:08 PM",
    "4-TE SOLTAMOS-TONY X ONLYONE-PLAY BACK.mp3*6907611*9/9/2020 11:30:26 PM",
    "PLAY BACK - TONY &amp; ONLYONE FT KOKI ELKANO - EL BUENO - DJ ROWA.mp3*10512279*9/9/2020 11:34:41 PM",
    38716597,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Toyo Y Zizo - (2020)()*0*9/13/2020 9:02:58 PM",
    "ahora dicen.wav*58346480*5/18/2020 1:08:02 PM",
    "AIDOOO ORIGINAL TOYO, ZIZO, EL MAFIA.mp3*6759912*9/9/2020 11:32:46 PM",
    "Copia de Wow BOUNCE.mp3*8492403*9/9/2020 11:33:08 PM",
    "definitivamente.wma*3470328*9/9/2020 11:33:12 PM",
    "repar.mp3*8207319*9/9/2020 11:34:43 PM",
    "sipsom toyo bonche.mp3*6762002*9/9/2020 11:34:52 PM",
    "TOYO NOCHE DH C F AH 120.mp3*5086695*9/9/2020 11:34:58 PM",
    "TOYO X ZIZO X EL GUAPO X LA AIFON FENIX.mp3*7969940*9/9/2020 11:34:59 PM",
    "TOYO X ZIZO X FALCEDAD X LA AIFON FENIX.mp3*7779768*9/9/2020 11:34:59 PM",
    "TOYO X ZIZO X INTENCIONES X LA AIFON FENIX.mp3*7855001*9/9/2020 11:34:59 PM",
    "TOYO X ZIZO X LA MOSITA X LA AIFON FENIX.mp3*7838286*9/9/2020 11:34:59 PM",
    "TOYO X ZIZO X MAFIA X TO EL TIEMPO X LA AIFON FENIX.mp3*8031589*9/9/2020 11:35:00 PM",
    "TOYO X ZIZO X MAFIA X TO EL TIEMPO X LA AIFON FENIX.wav*76636224*5/7/2020 6:28:10 PM",
    "TOYO X ZIZO X SE REPARTEA X LA AIFON FENIX.mp3*8082792*9/9/2020 11:35:00 PM",
    "TOYO X ZIZO X VAGABUNDO X LA AIFON FENIX.mp3*8057711*9/9/2020 11:35:00 PM",
    229376450,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Un BrianPapitiko x El Nany(2020)()*0*9/13/2020 11:21:20 PM",
    "JC Cesar ft Un Brian x EL Neko - El Puntilleo .mp3*7361812*9/9/2020 11:33:54 PM",
    "Un Brian - La Cara q me parte  - Star Factory productions.mp3*6780849*9/9/2020 11:35:01 PM",
    14142661,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Un Iroso(2020)()*0*9/13/2020 10:27:47 PM",
    "-    Dj Unic, El Chulo, Wampi, El Iroso - El Canilleo (Db) .mp3*3738782*9/9/2020 11:28:59 PM",
    "1-ANOCHE.mp3*3213939*9/9/2020 11:29:26 PM",
    "2-COSITA BONITA.mp3*3503649*9/9/2020 11:29:48 PM",
    "3-NADA SERA IGUAL.mp3*2836011*9/9/2020 11:30:07 PM",
    "4-ME DUELE.mp3*11155479*9/9/2020 11:30:25 PM",
    "5-GUACHICULEO.mp3*9094214*9/9/2020 11:30:42 PM",
    "6- YA NO MAS.mp3*3639369*9/9/2020 11:30:55 PM",
    "9-IROSO X LUCHEN X PEDRO.mp3*4346157*9/9/2020 11:31:32 PM",
    "9-MADRE IROSO.mp3*7362854*9/9/2020 11:31:32 PM",
    "Iroso x Daimon x MGY  TARDE O  TENPRANO.mp3*7210371*9/9/2020 11:33:51 PM",
    56100825,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Un Sensei (Los Champions)Papitiko x El Nany(2020)()*0*9/13/2020 10:19:16 PM",
    "Un Sensei ( Los Champios ) - Nadie mas  - .mp3*8235347*9/9/2020 11:35:02 PM",
    8235347,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Variado Vol 2 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 11:09:41 PM",
    "10go -  Un Motivo(Prod By Rodo)Blessed Music.mp3*7720175*9/9/2020 11:31:40 PM",
    "Andresito &amp; AllinOne - Pensando En Tí(Blessed Music).mp3*7617775*9/9/2020 11:32:48 PM",
    "Arold Aranda - Me Enreda(Prod By Dj Rodo)Blessed Music.mp3*8324126*9/9/2020 11:32:52 PM",
    "Cairon - Volver(Prod By Dj Rodo)Blessed Music.mp3*8291734*9/9/2020 11:32:56 PM",
    "Chris Casa - Equivocado(Blessed Music).mp3*6931277*9/9/2020 11:33:01 PM",
    "Eduar El Real - Es Mentira(Prod By Dj Rodo)Blessed Music.mp3*8138134*9/9/2020 11:33:16 PM",
    "Eydrian &amp; RanC - El Culpable(Prod By Dj Rodo)Blessed Music.mp3*9273938*9/9/2020 11:33:36 PM",
    "Henry - La Bendición(Prod By Dj Rodo)Blessed Music.mp3*9365889*9/9/2020 11:33:47 PM",
    65663048,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Variado Vol 3 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 11:27:30 PM",
    "Andresito &amp; AllinOne - Fin De Semana(Prod By Dj Rodo)Blessed Music.mp3*7262509*9/9/2020 11:32:48 PM",
    "Brian &amp; Andresito Ft Allinone - Alegria(Prod By Dj Rodo).mp3*9659505*9/9/2020 11:32:55 PM",
    "Chris Casa - Esta noche(Blessed Music).mp3*8207097*9/9/2020 11:33:02 PM",
    "Eduar El Real - Me Enamore(Prod By Dj Rodo)Blessed Music.mp3*8287554*9/9/2020 11:33:16 PM",
    "Eduar El Real - Todo Para Ti(Prod By Dj Rodo)Blessed Music.mp3*7494477*9/9/2020 11:33:17 PM",
    "Eydrian &amp; RanC - Te Encontre(Prod By Dj Rodo)Blessed Music.mp3*9860126*9/9/2020 11:33:37 PM",
    "Leny - Envidia(Prod By Dj Rodo)Blessed Music.mp3*8359652*9/9/2020 11:34:08 PM",
    "Leskay &amp; El Autentico -  Tu Amor(Prod By Dj Rodo)Blessed Music.mp3*8085889*9/9/2020 11:34:08 PM",
    "Los Likes  - Fan De Tus Besos(Prod By Dj Rodo)Blessed Music.mp3*9453660*9/9/2020 11:34:13 PM",
    "Los Likes  - Pensando En Ti(Prod By Dj Rodo)Blessed Music.mp3*8712828*9/9/2020 11:34:13 PM",
    "Migue El Escogido Ft Jcyordi - Guaperia Espitual (Blessed Music).mp3*10854868*9/9/2020 11:34:23 PM",
    "Mr Leo - Calla Esa Boquita(Prod By Dj Rodo)Blessed Music.mp3*7469399*9/9/2020 11:34:24 PM",
    "Mr Leo - Despecho(Prod By Dj Rodo)Blessed Music.mp3*7940648*9/9/2020 11:34:25 PM",
    "Shel Flores Ft Chris Casa - Confia(Blessed Music).mp3*9744142*9/9/2020 11:34:51 PM",
    "Tati &amp; El Elvis - No Mas(Prod By Dj Rodo)Blessed Music.mp3*8970917*9/9/2020 11:34:55 PM",
    "Tati &amp; El Elvis - Tu Y Yo(Prod By Dj Rodo &amp; Chanel Pro)Blessed Music.mp3*8347113*9/9/2020 11:34:55 PM",
    "Vivita - El Culpable(Prod By Dj Rodo)Blessed Music.mp3*8081709*9/9/2020 11:35:06 PM",
    "Vivita - Puede Ser(Prod By Dj Rodo)Blessed Music.mp3*7950052*9/9/2020 11:35:06 PM",
    "Vynkel - Matame(Blessed Music).mp3*7842428*9/9/2020 11:35:06 PM",
    "Walter x Danny - Muevete(Prod By Dj Rodo)Blessed Music.mp3*7439097*9/9/2020 11:35:07 PM",
    "WEST - RAMBO - (BLESSED MUSIC).mp3*6717603*9/9/2020 11:35:08 PM",
    "Yari SR - Cuentale(Prod By Dj Rodo)Blessed Music.mp3*7091146*9/9/2020 11:35:10 PM",
    "Yousef - Diferente(Prod By Dj Rodo)Blessed Music.mp3*7648077*9/9/2020 11:35:23 PM",
    191480496,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Vidal &amp; Armin - Demo(2020)()*0*9/13/2020 9:03:16 PM",
    "Vidal &amp; Armin --Diganle(Big Noise Studio).mp3*9260670*9/9/2020 11:35:03 PM",
    "Vidal &amp; Armin Ft Masorra--Ekelecua(Big Noise Studio ).mp3*8567341*9/9/2020 11:35:03 PM",
    "Vidal &amp; Armin-- Se Acaba el Amor (BigNoise).mp3*7727488*9/9/2020 11:35:04 PM",
    "Vidal &amp; Armin-- Vacilòn(Big Noise Studio).mp3*7954743*9/9/2020 11:35:04 PM",
    "Vidal &amp; Armin- Yo te Amo(Big Noise).mp3*7829888*9/9/2020 11:35:04 PM",
    "Vidal &amp; Armin-Dando Like(Corasound Music).mp3*3070662*9/9/2020 11:35:04 PM",
    "Vidal &amp; Armin--Díganle(Big Noise Studio).mp3*7657203*9/9/2020 11:35:04 PM",
    "Vidal &amp; Armin-La Mulata(Big Noise Studio).mp3*8014835*9/9/2020 11:35:05 PM",
    "Vidal &amp; Armin--Motivos(Recvolution studio).mp3*8083946*9/9/2020 11:35:05 PM",
    "Vidal &amp; Armin-Moviendo el cuerpo(BIG Noise Studio).mp3*6708713*9/9/2020 11:35:05 PM",
    74875489,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Vol 1 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 11:21:57 PM",
    "001 - STH - Culpable(Prod By Dj Rodo)Blessed Music.mp3*8593747*9/9/2020 11:29:08 PM",
    "002 - John Ramy - Explicame(Prod By Dj Rodo)Blessed Music).mp3*8438057*9/9/2020 11:29:30 PM",
    "003 - Los Likes - Tu Fantasía (Daniel)(Prod By Dj Rodo)Blessed Music.mp3*9168441*9/9/2020 11:29:50 PM",
    "004 - Los Likes - Yo Me Enamoro (Daniel)(Prod By Dj Rodo)Blessed Music.mp3*7928147*9/9/2020 11:30:09 PM",
    "005 - Mr Leo - Como Olvidarte(Prod By Dj Rodo)Blessed Music.mp3*7843510*9/9/2020 11:30:27 PM",
    "006 - John Ramy - Quedate Conmigo(Prod By Dj Rodo)Blessed Music.mp3*7184180*9/9/2020 11:30:43 PM",
    "007 - Los Likes - Tu y Yo(Daniel)(Prod By Dj Rodo)Blessed Music.mp3*8865420*9/9/2020 11:30:57 PM",
    "008 - Otto &amp; Yari - Olvidala(Prod By Dj rodo)Blessed Music.mp3*7847690*9/9/2020 11:31:10 PM",
    "009 - Mr Leo - Loco  Tu Amor(Prod By Dj Rodo)Blessed Music.mp3*8460000*9/9/2020 11:31:22 PM",
    "010 - Leskay &amp; El Auténtico - Saldré A Buscarte(Prod By Dj Rodo)Blessed Music.mp3*7537355*9/9/2020 11:31:32 PM",
    "Eduar El Real - Me llamó(Prod By Dj Rodo)Blessed Music.mp3*10500648*9/9/2020 11:33:16 PM",
    "Eduar El Real - Mueres  Mi(Prod By Dj Rodo)Blessed Music.mp3*7487162*9/9/2020 11:33:16 PM",
    99854357,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Vol 2 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 9:35:53 PM",
    "011 - Mr Leo ft Yamoe - Sola(Prod By Dj Rodo)Blessed Music.mp3*7734841*9/9/2020 11:31:43 PM",
    "012 - Tati &amp; El Elvis - Tu Y Yo(Prod By Dj Rodo &amp; Chanel Pro)Blessed Music.mp3*8348196*9/9/2020 11:31:51 PM",
    "013 - Eduar El Real - Mueres  Mi(Prod By Dj Rodo)Blessed Music.mp3*7488245*9/9/2020 11:31:57 PM",
    "014 - Arold Aranda - Alocate(Prod By Dj Rodo)Blessed Music.mp3*7516457*9/9/2020 11:32:02 PM",
    "015- Leskay &amp; El Autentico - Un Cuento De Hadas(Prod By Dj Rodo)Blessed Music.mp3*8583298*9/9/2020 11:32:06 PM",
    "016 - Mr Leo - No Me Llores(Prod By Dj Rodo)Blessed Music.mp3*7742155*9/9/2020 11:32:09 PM",
    "017 - Eduar El Real - Es Mentira(Prod By Dj Rodo)Blessed Music.mp3*8138171*9/9/2020 11:32:11 PM",
    "018- Cairon - Volver(Prod By Dj Rodo)Blessed Music.mp3*8291771*9/9/2020 11:32:13 PM",
    "019- Leskay &amp; El Autentico - Vamos A Celebrar(Prod By Dj Rodo)Blessed Music.mp3*7675282*9/9/2020 11:32:15 PM",
    71518416,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Vol 3 - Blessed Music(Adelanto 12)(2020)()*0*9/13/2020 9:35:57 PM",
    "020- Los Likes  - Fan De Tus Besos(Prod By Dj Rodo)Blessed Music.mp3*9454743*9/9/2020 11:32:17 PM",
    "021 - Arold Aranda - Me Enreda(Prod By Dj Rodo)Blessed Music.mp3*8325208*9/9/2020 11:32:18 PM",
    "022 - Vivita - El Culpable(Prod By Dj Rodo)Blessed Music.mp3*8082792*9/9/2020 11:32:20 PM",
    "023- Mr Leo - El Hechizo(Prod By Dj Rodo)Blessed Music.mp3*7310612*9/9/2020 11:32:21 PM",
    "024- Tati &amp; El Elvis - No Mas(Prod By Dj Rodo)Blessed Music.mp3*8970955*9/9/2020 11:32:21 PM",
    "025- Eduar El Real - Me Enamore(Prod By Dj Rodo)Blessed Music.mp3*8288637*9/9/2020 11:32:22 PM",
    "026- Los Likes  - Pensando En Ti(Prod By Dj Rodo)Blessed Music.mp3*8713910*9/9/2020 11:32:23 PM",
    "027- Danny - Novios o Amigos(Prod By Dj Rodo)Blessed Music.mp3*7238514*9/9/2020 11:32:24 PM",
    "028- Eduar El Real - Me llamó(Prod By Dj Rodo)Blessed Music.mp3*10542482*9/9/2020 11:32:24 PM",
    "029- Avik &amp; AG &amp; RanC - Ella Me Enfermo(Prod By dj Rodo)Blessed Music.mp3*8853926*9/9/2020 11:32:25 PM",
    "2N1 - Una Vida Linda(Prod By Dj Rodo)Blessed Music).mp3*7472571*9/9/2020 11:29:48 PM",
    "Leskay &amp; El Autentico -  Tu Amor(Prod By Dj Rodo)Blessed Music.mp3*8086971*9/9/2020 11:34:09 PM",
    "Rian &amp; Yail - La Traición(Prod By Dj Rodo)Blessed Music.mp3*8396261*9/9/2020 11:34:44 PM",
    "The Sami - Como Tu Ninguna(Prod By Dj Rodo)Blessed Music.mp3*8387838*9/9/2020 11:34:56 PM",
    118125420,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\wachoPapitiko x El Nany(2020)()*0*9/13/2020 9:35:57 PM",
    "Wacho ft Karol -  Mira como vengo - Star Factory  by Dinamiko.mp3*6690988*9/9/2020 11:35:06 PM",
    6690988,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Wampi - Demo (2020)()*0*9/13/2020 9:35:58 PM",
    "Campista - Wampi.mp3*7857966*9/9/2020 11:32:56 PM",
    "Ciego Sordo Y Mudo - (Wampi Feat Mariana Y La Makynaria).mp3*7643886*9/9/2020 11:33:05 PM",
    "Wampi - Muñeca.mp3*4450605*9/9/2020 11:35:07 PM",
    "Wampi - Traqueteo.mp3*4479196*9/9/2020 11:35:07 PM",
    "Wampi X Titico - Chikitico.mp3*7593006*9/9/2020 11:35:08 PM",
    32024659,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Wampi - Mama Eh(2020)()*0*9/13/2020 9:35:59 PM",
    "1-Wampi - Mama Eh.mp3*6869127*9/9/2020 11:29:29 PM",
    "2-Wampi - 3 Marias.mp3*4002207*9/9/2020 11:29:49 PM",
    "3-Wampi - Gastando Papeles.mp3*7250247*9/9/2020 11:30:08 PM",
    "4-Wampi X Manu Manu - La Cosita.mp3*8654727*9/9/2020 11:30:26 PM",
    "5-Wampi - La Suerte.mp3*3575211*9/9/2020 11:30:43 PM",
    "6-Wampi - Amor de Escuela.mp3*4095123*9/9/2020 11:30:57 PM",
    "7-Wampi - Enfocao.mp3*6979695*9/9/2020 11:31:10 PM",
    41426337,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Wil Campa - Mi Filosofia(2020)(FUSION)*0*9/13/2020 9:36:02 PM",
    "01.Wil Campa - La Cama.mp3*9030476*9/9/2020 11:29:23 PM",
    "02.Wil Campa - La Noche.mp3*9019560*9/9/2020 11:29:43 PM",
    "03.Wil Campa Ft Mr Jordan - Rumba Y Vacilon.mp3*6225309*9/9/2020 11:30:03 PM",
    "04.Wil Campa - Mi Filosofia.mp3*8899864*9/9/2020 11:30:23 PM",
    "05.Wil Campa - La Vida Sigue.mp3*9840477*9/9/2020 11:30:39 PM",
    "06.Wil Campa Ft Andro El Talento - Buscando Un Amor.mp3*7234077*9/9/2020 11:30:53 PM",
    "07.Wil Campa Ft Un Titico &amp; El Kaneka - Take It Easy.mp3*7182999*9/9/2020 11:31:06 PM",
    "08.Wil Campa - Si Tu Te Vas.mp3*8689437*9/9/2020 11:31:19 PM",
    "09.Wil Campa Ft Papi Lacho - No Te Quedes Con Ganas.mp3*8522694*9/9/2020 11:31:29 PM",
    74644893,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Yambo - CD Renacer (2020)()*0*9/13/2020 9:36:03 PM",
    "00-EL YAMBO-RENACER(Prød By Dj Riky)™.mp3*5348478*9/9/2020 11:29:08 PM",
    "02-EL YAMBO-NADA SIN TI(Prød By Chico Frank &amp; Dj Riky &amp; Luisito El Insuperable)™.mp3*9599984*9/9/2020 11:29:44 PM",
    "03-EL YAMBO-LA FINA(Prød By Chico Frank &amp; Dj Riky &amp; Luisito El Insuperable)™.mp3*9048278*9/9/2020 11:30:05 PM",
    "04-EL YAMBO-NO SOY DELINCUENTE(Prød By Chico Frank &amp; Dj Riky)™.mp3*8443282*9/9/2020 11:30:23 PM",
    "05-EL YAMBO-BRUJERIA(Prød By Chico Frank &amp; Dj Riky)™.mp3*9488180*9/9/2020 11:30:39 PM",
    "06-EL YAMBO FEAT ANTHONY-RICA(Prød By Dj Riky)™.mp3*7418238*9/9/2020 11:30:54 PM",
    "07-EL YAMBO FEAT ALEXITO-QUERER Y AMAR(Prød By Dj Riky)™.mp3*7919358*9/9/2020 11:31:07 PM",
    "1-Intro Champoleate-(Prød By DJ-RIKY)™.mp3*5202558*9/9/2020 11:29:29 PM",
    "2-Yambo-Te toca llorar-(Prod By Chico Frank).mp3*9589535*9/9/2020 11:29:50 PM",
    "3-Yambo-Tiza-(Prød By DJ-RIKY)™.mp3*7729278*9/9/2020 11:30:09 PM",
    "4-Yambo-Que Rico-(Prød By DJ-RIKY)™.mp3*8147838*9/9/2020 11:30:27 PM",
    "5-Yambo feat Alexito-Te vas Bloqueá-(Prød By DJ-RIKY)™.mp3*8183358*9/9/2020 11:30:43 PM",
    "6-Yambo-Tornillo-(Prød By DJ-RIKY)™.mp3*7566078*9/9/2020 11:30:57 PM",
    "7-Yambo-Felicidad-(Prød By DJ-RIKY)™.mp3*8354238*9/9/2020 11:31:10 PM",
    "8-Yambo-Chica prepago-(Prod By Chico Frank &amp; Dj Riky)-1.mp3*8962596*9/9/2020 11:31:22 PM",
    121001277,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Yero(El Kila) - A La Carandanga(2020)*0*9/13/2020 9:02:30 PM",
    "01. Yero(El Kila) - A La Carandanga.mp3*6791717*9/9/2020 11:29:11 PM",
    "02. Yero(El Kila) - Pa Mi Gente.mp3*8451604*9/9/2020 11:29:33 PM",
    "03. Yero El Kila - Licra  La Rodilla.mp3*7944318*9/9/2020 11:29:55 PM",
    23187639,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Yildoni x Beian (La nueva imagen)(2020)()*0*9/13/2020 11:27:22 PM",
    "La Nueva Imagen ( Yildoni x Brian ) - Tienes q vivirla pa que creas  -   (2).mp3*7623037*9/9/2020 11:34:05 PM",
    "La Nueva Imagen ( Yildoni x Brian ) - Tienes q vivirla pa que creas  -  .mp3*7623037*9/9/2020 11:34:05 PM",
    "Yildoni - Que vengan todas  -  .mp3*7842465*9/9/2020 11:35:12 PM",
    23088539,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Yintoni x Roynelito X Talento(2020)()*0*9/13/2020 9:01:56 PM",
    "Talento x Yintoni x Roynelito - La profesora - .mp3*8500751*9/9/2020 11:34:54 PM",
    8500751,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Yohanluis(2020)()*0*9/13/2020 9:01:47 PM",
    "+LOBO MALO &amp; YOHANLUIS - EL CHULO (PROD. BY MAGIC MUSIC).mp3*7088126*9/9/2020 11:29:07 PM",
    "A VER COMO TE EXPLICO - POPY (Feat. CRAWEL  YOHANLUIS(720PHD).mp3*3744813*9/9/2020 11:32:45 PM",
    "Dime YohanLuis  YeiYei Prod by MrRey La LLaveRecords.mp3*8267871*9/9/2020 11:33:13 PM",
    "LA CUERDA - POPY la moda(Feat. YOHANLUI) (Audio(720PHD).mp3*7142679*9/9/2020 11:34:04 PM",
    "La distancia.Mauro Misterio X Yohanluis(720PHD).mp3*8941323*9/9/2020 11:34:04 PM",
    "mejor solo.mp3*7390445*9/9/2020 11:34:21 PM",
    "YOHANLUIS - AKELLA VEZ (PROD. BY DJ GOMEZ GOMEKO).mp3*6597543*9/9/2020 11:35:12 PM",
    "YOHANLUIS POPY X MODA  la patipolvo.mp3*6833559*9/9/2020 11:35:12 PM",
    "Yohanluis Readysalvi. Se mira y no se toca.mp3*8466605*9/9/2020 11:35:12 PM",
    "YohanLuis. Voy a Seguir (audioficial).mp3*6849965*9/9/2020 11:35:13 PM",
    71322929,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Yordano 23(2020)()*0*9/13/2020 11:15:49 PM",
    "Ordeley x Wayle x Barreiro ( lOS iTAKOS ) x Yordano 23  - Bótate - .mp3*8563445*9/9/2020 11:34:30 PM",
    "Ordeley x Wayle x Barreiro x Yordano 23  - Bótate - .mp3*8563445*9/9/2020 11:34:30 PM",
    17126890,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Yoskin x BiuryPapitiko x El Nany(2020)()*0*9/13/2020 9:01:14 PM",
    "Yoskin x Biury - Con Deseo  -  .mp3*7892620*9/9/2020 11:35:19 PM",
    "Yoskin x Biury - La Culpable  -  .mp3*8632408*9/9/2020 11:35:19 PM",
    "Yoskin x Biury - La yalorde - .mp3*8229078*9/9/2020 11:35:19 PM",
    "Yoskin x Biury ft Barreiro - 24 Veces -  .mp3*7788131*9/9/2020 11:35:20 PM",
    "Yoskin x Biury ft Barreiro - 24 Veces 2 -  .mp3*8730629*9/9/2020 11:35:20 PM",
    "Yoskin x Biury ft Lacho  - esa Maldita  -  .mp3*6734873*9/9/2020 11:35:20 PM",
    "Yoskin x Biury ft Papitiko - El Payaso -  .mp3*8941698*9/9/2020 11:35:20 PM",
    56949437,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Yosly - Heart Game(2020)()*0*9/13/2020 11:15:49 PM",
    "01.Yosly - Hola Que Tal.mp3*2973822*9/9/2020 11:29:25 PM",
    "02.Yosly - Te Mentiste.mp3*7508021*9/9/2020 11:29:43 PM",
    "03.Yosly - Escapate.mp3*52106697*9/9/2020 11:30:04 PM",
    "04.El Negrito X Yosly - Mas De Ti.mp3*38165193*9/9/2020 11:30:16 PM",
    100753733,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Música Actualizada [2020]\\[Nacional]\\Young K - Nunca Me Olvide De Ti(2020)()*0*9/13/2020 9:00:38 PM",
    "01.Young K - Nunca Me Olvide De Ti.mp3*5138687*9/9/2020 11:29:25 PM",
    "02.Young K - Low Key(Remix).mp3*5443101*9/9/2020 11:29:43 PM",
    10581788,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]*0*9/13/2020 9:39:21 PM",
    0,
    "999*1000*1001*1002*1003*1004*1008*1009*1010*1011*1012"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]\\!  Especial Coreano Loco*0*9/13/2020 9:39:32 PM",
    "cuando Don Omar era el rey del reggaeton 🤴🔥.mp4*303892069*9/3/2020 11:18:38 AM",
    "la canción que nos mató a todos 😅.mp4*188405936*9/3/2020 11:15:49 AM",
    "reaccion a caidas de cantantes de reggaeton 😂🤣.mp4*289408390*9/3/2020 11:16:39 AM",
    "reacción a Soy Guapo de Faraon Love Shady 😂🤣😅.mp4*158272867*9/3/2020 11:13:50 AM",
    "Traperos escapados del Area 51👽😂.mp4*166771833*9/3/2020 11:13:57 AM",
    1106751095,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]\\!  Especial Tomorrowland*0*9/13/2020 9:39:21 PM",
    "Dimitri Vegas &amp; Like Mike Live At Tomorrowland 2020 (MainStage).mp4*426391454*9/3/2020 11:28:00 AM",
    426391454,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]\\!  Videos [K POP de Estreno]*0*9/13/2020 9:39:20 PM",
    "(여자)아이들((G)I-DLE) - '덤디덤디 (DUMDi DUMDi)' Official Music Video.mp4*127589816*9/3/2020 11:55:55 AM",
    "CHUNG HA 청하 ‘PLAY (feat. 창모)’ Official MV.mp4*108343945*9/3/2020 11:55:37 AM",
    "EXO-SC 세훈&amp;찬열 '척 (Telephone) (Feat. 10CM)' MV.mp4*78766945*9/3/2020 11:54:44 AM",
    "ITZY “Not Shy” MV.mp4*117496732*9/3/2020 11:54:44 AM",
    "KDA - THE BADDEST ft. (G)I-DLE, Bea Miller, Wolftyla (Official Lyric Video)  League of Legends.mp4*70201276*9/3/2020 11:54:02 AM",
    "Lady Gaga, BLACKPINK - Sour Candy (Audio).mp4*62517108*9/3/2020 11:53:47 AM",
    "Red Velvet - IRENE &amp; SEULGI 'Monster' MV.mp4*125124466*9/3/2020 11:53:18 AM",
    "SEVENTEEN (세븐틴) 'Left &amp; Right' Official MV.mp4*97774648*9/3/2020 11:53:18 AM",
    "TREASURE - 'BOY' MV.mp4*106363449*9/3/2020 11:52:22 AM",
    "TWICE MORE &amp; MORE MV.mp4*137199341*9/3/2020 11:52:29 AM",
    1031377726,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]\\! CONCIERTOS  [HD]*0*9/13/2020 11:11:44 PM",
    "Backstreet Boys - Full DNA World Tour Show -  Argentina 372020.mp4*2941072682*9/3/2020 12:37:28 PM",
    2941072682,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]\\BILLBOARD*0*9/13/2020 11:11:43 PM",
    "Top 10 Songs Of The Week - August 31 2020 (Your Choice Top 10).mp4*12735548*8/30/2020 12:00:26 PM",
    12735548,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]\\TOPS*0*9/13/2020 9:38:52 PM",
    0,
    "1005*1006*1007"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]\\TOPS\\# Top 5 Odisea*0*9/13/2020 11:32:04 PM",
    "01.Wisin Ft Ozuna,Arcangel,Cosculluela,Myke Towers &amp; Juanka El Problematik - Enemigos Ocultos.mp4*492606302*9/2/2020 6:37:14 PM",
    "02.Yomil &amp; El Dany Ft Chesco - Usala(Loschampions).mp4*186936836*8/30/2020 6:37:33 PM",
    "03.El Duro - Mujeres Pierdan El Control(Lyric Video).mp4*135590727*7/8/2020 11:02:56 PM",
    "04.Kimiko Ft Allen Ramos - Hagamos El Sexo.mp4*166587705*8/24/2020 12:09:31 AM",
    "05.Divan - Mi Amor Es Pobre.mp4*137230563*9/2/2020 3:42:21 PM",
    1118952133,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]\\TOPS\\[INTERNACIONAL ]*0*9/13/2020 11:28:54 PM",
    "01-Yandel Ft Manuel Turizo - Celda.mp4*181328737*8/20/2020 5:57:02 PM",
    "02-El Alfa El Jefe Ft Tyga - Trap Pea.mp4*310409045*8/20/2020 12:31:19 AM",
    "03-Yandel Ft Anuel AA -  Mi Reggae Muero 2020.mp4*292525772*8/6/2020 9:06:55 AM",
    "04-Ozuna Ft Karol G &amp; Myke Towers - Caramelo(Official Remix).mp4*335697209*8/20/2020 12:26:14 AM",
    "05-Charles Luis - 2 Locos.mp4*157211901*7/22/2020 12:06:52 PM",
    "06-David Bisbal - Amor Amé (Summer Mix).mp4*280640727*8/9/2020 2:11:15 PM",
    "07-Black Eyed Peas Ft Nicky Jam &amp; Tyga - VIDA LOCA.mp4*315447796*8/23/2020 5:54:14 PM",
    "08-Erick Lexi - Mujer Bonita.mp4*144306953*6/4/2020 11:40:04 AM",
    "09-Camila - Bandera Blanca.mp4*182378567*8/16/2020 7:56:07 PM",
    "10-Chris Tamayo - Ratito.mp4*147562103*8/18/2020 7:17:56 PM",
    "11-Nersy Labrada - Mala.mp4*158366714*5/7/2020 3:20:47 PM",
    "12-Los Yakuza - Amnesia.mp4*191207598*6/21/2020 6:28:11 PM",
    "13-Darell Ft Kevvo - Booty Call.mp4*139528802*8/20/2020 1:04:40 AM",
    "14-Chris Tamayo - Mátame.mp4*145028302*7/12/2020 4:19:03 PM",
    "15-Karenia Diaz - Tell Me.mp4*169086334*8/26/2020 10:52:25 PM",
    "16-Wisin Ft Ozuna,Arcangel,Cosculluela,Myke Towers &amp; Juanka El Problematik - Enemigos Ocultos.mp4*492606302*9/2/2020 6:37:14 PM",
    "17-Rauw Alejandro Ft Anuel,Farruko,Dalex,Lenny Tavarez,Sech,Justin Quiles - Elegí Remix.mp4*270237668*8/30/2020 5:37:30 PM",
    "18-Maikel Miki Ft Sapienza - Pegao.mp4*128842129*9/1/2020 12:47:06 PM",
    "19-De La Ghetto Ft Nicky Jam - Sube La Music.mp4*134918907*8/30/2020 6:12:52 PM",
    "20-Thalía Ft Myke Towers - La Luz.mp4*230926771*8/30/2020 5:33:09 PM",
    4408258337,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]\\TOPS\\[NACIONAL]*0*9/13/2020 11:26:17 PM",
    "01-Yomil &amp; El Dany Ft Chesco - Usala(Loschampions).mp4*186936836*8/30/2020 6:37:33 PM",
    "02-Pedrito Kmacho &amp; El Clan - Acabé.mp4*164930876*7/29/2020 4:29:16 PM",
    "03-DIVAN - Tutorial - Prod By Cuban Deejays(Planet Records).mp4*133204385*7/5/2020 7:14:13 PM",
    "04-El Duro - Mujeres Pierdan El Control(Lyric Video).mp4*135590727*7/8/2020 11:02:56 PM",
    "05-Omi Hernandez Ft Solo Frank La Melodía - Ojalá.mp4*147674302*8/10/2020 10:19:33 PM",
    "06-Los Yakuza - Amnesia(Video Oficial)(58877280).mp4*191207598*6/21/2020 6:28:11 PM",
    "07-Nelson Manuel Ft La Corte - Bumbata.mp4*156608317*8/2/2020 5:19:18 PM",
    "08-Kimiko Ft Allen Ramos - Hagamos El Sexo.mp4*166587705*8/24/2020 12:09:31 AM",
    "09-EL TIKKO x EL Kimiko &amp; Yordy - Tu Recuerdo.mp4*145889397*8/20/2020 12:04:31 AM",
    "10-Charles Luis - 2 Locos.mp4*157211901*7/22/2020 12:06:52 PM",
    "11-El Samu - Pegueta Pegueta(Prod By Dj Volty)(53093431).mp4*166512290*7/5/2020 5:18:05 PM",
    "12-Karenia Diaz - Tranquilita Y Relaja.mp4*169706197*7/22/2020 11:49:51 PM",
    "13-Charanga Habanera X Hector El Wuay X Madame Lety X Damian X Norlan X La Diosa &amp; Mas - Pasillea 2(HM).mp4*273998897*9/1/2020 2:16:01 PM",
    "14-Lady Laura - No Me Controlas.mp4*148586336*11/4/2019 5:11:32 PM",
    "15-Wil Campa - La Cama.mp4*165196946*2/10/2020 1:25:53 AM",
    "16-Jose Yamil(El Chavo) - Contigo Quiero Todo(Facebook-Jose Yamil-Instagram Joseyamil real).mp4*142221799*7/23/2020 12:48:28 AM",
    "17-El Chiko De La Calle - De La Que Te Salvaste.mp4*149911522*5/30/2018 8:57:22 PM",
    "18-Kimiko &amp; Yordy Ft Henry Manuel - La Blanca Y El Negrito.mp4*151978661*7/19/2020 4:18:14 PM",
    "19-Three Show - El Arrebato.mp4*148241967*8/23/2020 6:22:18 PM",
    "20-Jhon Menez Ft Vane D - A Lo Anuel &amp; Karol.mp4*156318834*8/2/2020 5:19:36 PM",
    3258515493,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]\\Videografia -[Baby Lores]*0*9/13/2020 8:24:14 PM",
    "Ahora soy yo - Baby Lores (Official Video).mp4*91665945*2/24/2017 11:26:05 AM",
    "Baby Lores - Para Matarme a Mi.mp4*73750349*9/22/2017 3:16:42 PM",
    "Baby Lores - Te Quiero.mp4*295345296*8/22/2016 8:26:01 PM",
    "Baby Lores &amp; Luís - Dime Porque.mp4*75775762*2/24/2017 11:24:16 AM",
    "BABY LORES ► DE LA CALLE SOY (OFFICIAL VIDEO).mp4*237273936*5/25/2016 10:10:19 AM",
    "Baby Lores and Danny - Yo te Sigo.mp4*1374325436*9/24/2016 10:01:13 AM",
    "Baby Lores -Diabla Asesina.mp4*84623276*2/12/2017 6:01:06 AM",
    "BABY LORES FT CLASSIC BABY(STARS MUSIC).mp4*791619046*4/6/2016 3:08:26 AM",
    "Baby Lores ft Insurrecto - Mi Vecina.mp4*105830348*1/28/2017 5:51:13 AM",
    "Baby Lores ft Kenny y Zantana - El Culpable - By Dj Conds.mp4*94238577*2/8/2017 6:45:16 AM",
    "Baby Lores Ft Luis - Dime.mp4*336346464*8/22/2016 8:27:41 PM",
    "Baby Lores Luis - Tu y Yo (Prod By Dj Volty).mp4*99469240*9/1/2017 6:06:10 PM",
    "Baby Lores -TOA TOA.mp4*104307486*7/23/2017 5:42:14 PM",
    "DAMIAN, BABY LORES, EDDY K, YAKARTA - DONDE TU ESTAS - (OFFI.mp4*87019284*2/24/2017 11:21:27 AM",
    "Descemer Bueno Y Baby Lores - Quisiera Volver    .mp4*112465124*1/16/2016 11:30:52 PM",
    "Ganas -Baby Lores ft Onyl El Gerente[EJE RECORD 53639408].mp4*151859948*4/1/2017 7:01:40 PM",
    "Said Aguilar ft. Baby Lores - Dancing to the Rhythm.mp4*131038226*8/18/2017 3:06:41 PM",
    4246953743,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]\\Videografia -[Buena Fe]*0*9/13/2020 8:23:32 PM",
    "Alain Daniel ft Buena Fe (Remix) .mp4*1758171029*8/14/2016 11:25:27 PM",
    "Buena Fe - La Culpa.mp4*105821557*1/16/2016 11:18:31 PM",
    "Buena Fe - Pleiesteichon.mp4*115310322*1/16/2016 11:32:14 PM",
    "Buena Fe &amp; Descemer Bueno - Ser De Sol.mp4*158872075*1/16/2016 11:25:01 PM",
    "Buena Fe Ft Alain Daniel - La Carta .mp4*156797814*4/27/2016 3:58:42 PM",
    "Buena Fe ft Arjona -Para bien o para mal.mp4*227362072*7/2/2016 9:11:37 AM",
    "Buena Fe, Omara Portuondo, Yomil y el Dany - Musica Vital (V.mp4*104018715*12/29/2017 10:27:10 AM",
    "Dame Guerra -[Buena Fe].mp4*226542714*3/5/2016 9:04:14 AM",
    "Soñar en azul - Buena Fe.mp4*14369153*3/15/2018 1:22:10 PM",
    2867265451,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]\\VIDEOS CLASICOS*0*9/13/2020 8:23:31 PM",
    "Alejandro Sanz - A Que No Me Dejas ft. Alejandro Fernandez.mp4*60423548*9/9/2020 11:41:38 PM",
    "Britney Spears Ft G-Eazy - Make Me.mp4*80723751*8/8/2016 8:03:50 AM",
    "Camila - Alejate De Mi.mp4*42470497*8/16/2020 4:29:47 PM",
    "Chenoa - Júrame.mp4*43614381*2/14/2014 10:39:42 AM",
    "Cristian Castro Ft Alek Syntek - Te Perdi.mp4*48855083*5/14/2015 6:21:34 AM",
    "David Bisbal - Bésame.mp4*52696660*1/16/2020 3:41:57 PM",
    "Demi Lovato - Angels Among Us.mp4*29458682*12/20/2017 5:33:40 PM",
    "Don Omar - Danza Kuduro ft. Lucenzo.mp4*23717831*3/3/2019 7:06:18 PM",
    "Jesse &amp; Joy - Llorar Ft Mario Down.mp4*14260760*11/25/2018 9:55:49 PM",
    "Juanes - Fíjate Bien (MTV Unplugged).mp4*75539862*5/30/2019 10:04:56 PM",
    "Maná -  Lábios Divididos (feat. Thiaguinho) [Video Oficial].mp4*82459202*11/25/2019 5:23:06 PM",
    "Shakira - Can't Remember to Forget You ft. Rihanna.mp4*35096664*1/25/2018 12:28:45 AM",
    "Yomil y el Dany - Pega pega (Official video).mp4*150165596*12/16/2016 11:02:51 AM",
    739482517,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]\\VIDEOS INTERNACIONALES*0*9/13/2020 11:32:40 PM",
    "24kGoldn - Mood (Official Video) ft. Iann Dior.mp4*61571221*8/6/2020 8:10:31 PM",
    "645AR - Sum Bout U (Official Video) ft. FKA twigs.mp4*56183808*8/6/2020 8:10:09 PM",
    "Akon - Te Quiero Amar ft. Pitbull.mp4*113082071*4/9/2020 4:01:06 PM",
    "Alex Sensation, Myke Towers, Jhay Cortez - La Calle ft. Arcangel, De La Ghetto, Darell.mp4*147165835*4/9/2020 3:59:40 PM",
    "Anderson .Paak, Justin Timberlake - Don't Slack (from Trolls World Tour) (Official Video).mp4*70886766*4/9/2020 4:27:53 PM",
    "beabadoobee - Sorry.mp4*84315744*8/6/2020 8:09:31 PM",
    "Big Sean - Don Life (Detroit 2 Preview).mp4*42454753*9/3/2020 11:51:27 AM",
    "Big Sean - Harder Than My Demons.mp4*57991615*9/3/2020 11:51:31 AM",
    "Billie Eilish - my future.mp4*82016518*8/6/2020 8:09:19 PM",
    "Blueface - Respect My Cryppin' ft. Snoop Dogg.mp4*96742532*8/6/2020 8:08:10 PM",
    "Brandy - Borderline.mp4*117338437*8/6/2020 8:08:06 PM",
    "Bring Me The Horizon - Obey with YUNGBLUD (Official Video).mp4*88910518*9/3/2020 11:50:59 AM",
    "C Money Laflare - No Justice No Peace.mp4*119308390*9/3/2020 11:50:54 AM",
    "Calboy - Holy Water (Live Session)  Vevo Ctrl.At.Home.mp4*50813693*8/6/2020 8:06:48 PM",
    "Cariño, Natalia Lacunza - Modo Avión.mp4*64757108*9/3/2020 11:50:04 AM",
    "Carlos Arroyo x Farruko - Aruba (Video Oficial).mp4*77942714*9/3/2020 11:35:33 AM",
    "Cazzu - Miedo.mp4*73882191*9/3/2020 11:50:00 AM",
    "Christina Aguilera - Reflection (2020) (From Mulan).mp4*85693036*9/3/2020 11:49:21 AM",
    "CNCO &amp; Natti Natasha - Honey Boo (Official Video).mp4*113508424*4/9/2020 3:54:12 PM",
    "Crown Lands - Sun Dance.mp4*131179395*9/3/2020 11:49:16 AM",
    "De La Ghetto, Nicky Jam - Sube La Music (Official Lyric Video).mp4*72109209*9/3/2020 11:36:15 AM",
    "De La Ghetto, Nicky Jam - Sube La Music (Official Music Video).mp4*74390190*9/3/2020 11:34:45 AM",
    "Dimitri Vegas &amp; Like Mike vs Regard - Say My Name (Official Music Video).mp4*72433546*9/3/2020 11:48:38 AM",
    "Disclosure, Kehlani, Syd - Birthday.mp4*97510355*9/3/2020 11:47:54 AM",
    "Doja Cat - The Tale of Becoming Doja Cat  Vevo LIFT.mp4*103039786*8/6/2020 8:06:27 PM",
    "Domino Saints, Sharo Towers - No Es Pecado.mp4*79225672*8/6/2020 8:05:27 PM",
    "EARTHGANG - Top Down (Official Music Video).mp4*101915168*8/6/2020 8:05:05 PM",
    "El Boke X Rochy RD X Shelow Shaq X Ele A El Dominio X Jon Z - La Weed En El Caco (Official Video).mp4*136209977*9/3/2020 11:34:29 AM",
    "Eladio Carrión - Ele Uve (Video Oficial)  Sauce Boyz Care Package.mp4*69655547*8/6/2020 7:44:36 PM",
    "Evel - Pensando (Video Oficial).mp4*191829970*8/6/2020 7:43:45 PM",
    "Frisco - Red Card (Official Video) ft. Skepta, Jammer, JME, Shorty.mp4*98585745*9/3/2020 11:47:56 AM",
    "Gigolo. La Exce x Myke Towers - Luna (Video Oficial).mp4*91147250*9/3/2020 11:33:58 AM",
    "I DONT KNOW HOW BUT THEY FOUND ME - Leave Me Alone.mp4*88425245*9/3/2020 11:47:04 AM",
    "India Shawn - Movin' On (Official Music Video - Single Version) ft. Anderson .Paak.mp4*86425448*9/3/2020 11:47:02 AM",
    "J ALVAREZ - NOS CONOCIMOS (AUDIO COVER) EL JONSON.mp4*81172594*8/6/2020 7:42:20 PM",
    "J Balvin - Rojo (Official Live Performance)  Vevo.mp4*44612934*4/9/2020 4:18:08 PM",
    "J Balvin, Bad Bunny,  Farruko,  Arcangel,  Nicky Jam,  Jowell y Randy  Brytiago - Mix 2020.mp4*595549543*8/6/2020 7:41:26 PM",
    "Jenn Morel, Mariah, Elisama - No Quiero Novio (Official Video).mp4*70119347*4/9/2020 4:18:17 PM",
    "Jon Z - Iris Chacón (Official Video).mp4*79937955*8/6/2020 7:57:07 PM",
    "Jordan Suaste - Body.mp4*83513027*9/3/2020 11:46:17 AM",
    "Jory Boy x Caci - Yo No Se [Official Video].mp4*79934700*8/6/2020 7:27:46 PM",
    "Jozzy - Funny Friends (Official Video).mp4*58421698*8/6/2020 7:56:23 PM",
    "Kany García, Camilo - Titanic (Official Video).mp4*97138801*9/3/2020 11:46:08 AM",
    "Katy Perry - Champagne Problems (The Smile Video Series).mp4*79758285*9/3/2020 11:45:36 AM",
    "Katy Perry - Resilient (The Smile Video Series).mp4*84904088*9/3/2020 11:45:24 AM",
    "Katy Perry - What Makes A Woman (The Smile Video Series).mp4*60976632*9/3/2020 11:44:48 AM",
    "Keith Urban - Tumbleweed (Official Visualizer).mp4*114697887*9/3/2020 11:44:45 AM",
    "Kelsea Ballerini - hole in the bottle (Official Music Video).mp4*66655103*9/3/2020 11:44:01 AM",
    "Kesha - Little Bit Of Love (Official Video).mp4*62972699*8/6/2020 7:55:55 PM",
    "Kevin Morby - Campfire (Official Video).mp4*125294377*9/3/2020 11:44:08 AM",
    "Kingdom - Lightning (feat. Kayla Blackmon) (Official Video).mp4*80370354*9/3/2020 11:43:18 AM",
    "Koito - Dayme y El High, Eladio Carrion , Pablo Chill-E y Neo Pistea (Video Oficial).mp4*96580696*8/6/2020 7:26:51 PM",
    "Kombat - Again &amp; Again.mp4*62180516*8/6/2020 7:55:34 PM",
    "Little Mix - Holiday (Official Video).mp4*85551447*9/3/2020 11:42:59 AM",
    "Lola Young - Woman.mp4*67527924*8/6/2020 7:54:44 PM",
    "Loren Gray - Alone.mp4*58505112*9/3/2020 11:42:23 AM",
    "lovelytheband - buzz cut.mp4*98870435*8/6/2020 7:55:07 PM",
    "lovelytheband - emo (Live Performance)  Vevo.mp4*87519658*9/3/2020 11:42:04 AM",
    "MARIA SIOKE, Omaigold - Flexin on my Ex's.mp4*49088811*9/3/2020 11:41:07 AM",
    "Mariah Angeliq, Ñengo Flow - Tócame (Official Video).mp4*91078755*8/6/2020 7:25:46 PM",
    "Marvel Boy &amp; Marconi Impara - El Bella (Video Oficial).mp4*82154295*9/3/2020 11:33:01 AM",
    "Master Peace - Regular Feelings (Trulee Remix).mp4*58900300*9/3/2020 11:41:04 AM",
    "Mathilda Homer - I Hate That I Love You So Little (Official Video).mp4*71042408*8/6/2020 7:53:50 PM",
    "Mozzy - Death Is Callin (Official Video).mp4*69917841*9/3/2020 11:40:27 AM",
    "Nio Garcia &amp; Casper Magico Ft. Rochy RD - Te Gua (Video Oficial).mp4*87644987*9/3/2020 11:32:57 AM",
    "Oscar Lang - Get Out.mp4*220331704*8/6/2020 7:53:42 PM",
    "Ozuna x Camilo - Despeinada (Video Oficial)(720P_HD)_1.mp4*116715894*9/4/2020 11:40:14 AM",
    "Ozuna x Daddy Yankee- No Se Da Cuenta (Audio Oficial)(480P)_1.mp4*117687904*9/6/2020 8:01:45 AM",
    "Ozuna x J Balvin x Chencho Corleone- Una Locura (Audio Oficial)(720P_HD)_1.mp4*95257118*9/6/2020 8:01:46 AM",
    "Ozuna x Wisin x Myke Towers Ft. Arcangel, Cosculluela y Juanka - Enemigos Ocultos (Video Oficial).mp4*187045544*9/3/2020 11:31:26 AM",
    "Pretty Sick - Allen Street.mp4*142660368*9/3/2020 11:40:34 AM",
    "Purple Disco Machine, Sophie and the Giants - Hypnotized (Official Video).mp4*170456091*8/6/2020 7:52:19 PM",
    "Que Malo - Bad Bunny, Ñengo Flow.mp4*58951739*4/9/2020 3:45:44 PM",
    "Queen Naija - Pack Lite (Official Video).mp4*141905621*8/6/2020 7:52:40 PM",
    "Rauw Alejandro, Anuel, Farruko, Dalex, Lenny, Sech, Dimelo, J Quiles - Elegí Remix (Video Oficial).mp4*136773506*9/3/2020 11:29:51 AM",
    "Reuben Vincent - Here We Go Again (Visualizer).mp4*81582464*9/3/2020 11:39:51 AM",
    "Ruston Kelly - Pressure (Official Music Video).mp4*66438496*8/6/2020 7:51:01 PM",
    "Sam Fischer - Everybody's Got Somebody (Official Video).mp4*76938702*9/3/2020 11:39:18 AM",
    "Shaylen - Forgive Myself (Official Music Video).mp4*79044055*8/6/2020 7:50:46 PM",
    "Slatt Zy - Drama (Official Video).mp4*63348518*8/6/2020 7:49:57 PM",
    "Tempo - Relax (Video Oficial).mp4*81721789*8/6/2020 7:22:17 PM",
    "Thalía, Myke Towers - La Luz (Official Video).mp4*51952729*9/3/2020 11:38:35 AM",
    "The Kid LAROI - SELFISH (Official Video).mp4*107302954*8/6/2020 7:49:43 PM",
    "The LOX - Bout Shit ft. DMX.mp4*106084262*9/3/2020 11:39:03 AM",
    "TOBi, Juls - Dollas and Cents (Official Video).mp4*84763114*9/3/2020 11:38:02 AM",
    "Toni Braxton - Dance.mp4*95373635*8/6/2020 7:47:18 PM",
    "Troye Sivan - Rager teenager! (Official Video).mp4*81652162*8/6/2020 7:46:16 PM",
    "Ty Bri - Wake Up (Official Video) ft. Mulatto.mp4*92646977*9/3/2020 11:38:04 AM",
    "Tyler Rich - Feels Like Home.mp4*82096183*9/3/2020 11:37:07 AM",
    "Vevo - Hot This Week August 28, 2020 (The Biggest New Music Videos).mp4*70158952*9/3/2020 11:37:04 AM",
    "XAXO, Lalo Ebratt, Mike Bahía - BEBE.mp4*82700855*9/3/2020 11:28:43 AM",
    "Y2K, JoJo - Damage is Done (Official Video).mp4*77696775*8/6/2020 7:45:29 PM",
    "Yandel - Espionaje (Video Oficial).mp4*73241400*4/9/2020 3:42:48 PM",
    "Yandel Ft. J Balvin - No Te Vayas (Video Oficial).mp4*106223362*8/6/2020 7:21:29 PM",
    "Yandel x Anuel AA - Por Mi Reggae Muero 2020 (Video Oficial).mp4*95731283*8/6/2020 7:20:31 PM",
    "Yazza - Fría  [Official Video].mp4*94751975*8/6/2020 7:19:39 PM",
    9170477217,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Musicales, Video Clips [2020]\\VIDEOS NACIONALES*0*9/13/2020 11:33:08 PM",
    ".Chacal X Tempo - Me quiero perder.mp4*106683308*9/4/2020 8:53:04 AM",
    ".Moikan  - En Visto  createdby.RAULIN RIGUAL  (((RDJ Productions Video.mp4*150915894*1/23/2020 1:43:18 PM",
    "Aisar Y El Expresso De Cuba - El Camaron.mp4*109467560*8/16/2020 8:22:52 PM",
    "Ale_&amp;_D´Ray_Aunque_tu_digas.mp4*120025854*8/9/2020 11:38:30 AM",
    "Alex Febles - The One (Video Oficial 4K)(720P_HD)_1.mp4*83209671*9/6/2020 8:01:19 AM",
    "Alvaro la Figura ft Hector el Wuay y Madame Lety-Un Mundo Contigo.mp4*110217117*9/6/2020 8:01:21 AM",
    "Alvaro la Figura ft Hector el Wuay-No me vuelvo a enamorar-Promo.mp4*151576360*9/6/2020 8:01:22 AM",
    "Andy Calienta &amp; El Cuco feat. Moikan - Tu.mp4*88907224*9/6/2020 8:01:23 AM",
    "Aprendi de ti-Polaris feat Madame Lety y Hector el WUAY (HM-Music) ....mp4*125954862*9/6/2020 8:01:25 AM",
    "AWARESPIKI-FIGUE X KAMYKACY X REY DAVID.mp4*128853973*6/20/2019 5:40:16 PM",
    "Baby Lores Ft Jose Yamil(El Chavo) - Se Fue(Facebook-Jose Yamil-Instagram Joseyamil real).mp4*148778158*7/23/2020 12:48:04 AM",
    "Baby Lores Ft Solo Frank La Melodia - No Me Llores.mp4*85241928*8/16/2020 8:24:43 PM",
    "Black Boy ❌ Orlenis 22K - Olvidalo (Video Oficial) Dj Rowa .mp4*122865740*9/6/2020 8:01:26 AM",
    "BLACK BOYS - UN DIA EN ROYAL SMITH BARBER_1.mp4*21784769*9/6/2020 8:01:26 AM",
    "BLACK BOYS - VAMPIROS EN LA HABANA - Ft A.B  - Dj Unic - CELULA MUSIC -.mp4*80278563*9/6/2020 8:01:28 AM",
    "Black Boys- Loco (video oficial) Dj Rowa.mp4*126274704*9/6/2020 8:01:29 AM",
    "Bryant Garcia - Mi Estrella.mp4*118058651*9/3/2020 11:22:33 PM",
    "Buena Fe Acompáñame ft. La Colmenita - Video Oficial.mp4*142637721*9/3/2020 12:06:32 PM",
    "CALIENTE COMO UN HORNO-BIG GABY x EL FIGLIO.mp4*149288652*9/12/2018 12:54:06 PM",
    "CARGADORA- LOBO MALO FT EL PITU Y EL KRLY X EL VERDADERO.mp4*108046224*2/21/2020 5:45:57 PM",
    "CHACAL - Amor en Paris [Live Performance].mp4*121271030*2/9/2019 5:13:26 AM",
    "Chacal x Akon - Billin _ Billin [Official Video](720P_HD).mp4*89757747*11/22/2019 8:33:35 PM",
    "CHARLY - EN VISTO (Video Oficial @cartelstudiocuba).mp4*87143613*8/7/2020 9:04:29 AM",
    "Chuchi Diamond - Quitarnos Las Ganas.mp4*137388430*8/2/2020 5:04:36 PM",
    "COMPARTAN -EL PITU Y EL KRLY &amp; EL VERDADERO.mp4*89337220*2/21/2020 5:39:56 PM",
    "Corazoncito Roto - El Coyote Feat Chocolate MC (Video Oficial)(360P).mp4*84832987*9/6/2020 8:01:30 AM",
    "D LUJO -ME ENGANCHE.mp4*126772514*12/6/2018 9:53:55 PM",
    "D´Lujo(Big Gaby &amp; El Figlio) - Master Card(Contrataciones 53972754-76980111).mp4*156438316*7/14/2020 10:36:58 AM",
    "David &amp; Out Side - Me Hizo El Amor.mp4*151200543*8/20/2020 12:42:59 PM",
    "DAYRONI PAL PISTEO - GNIO &amp;  ft LEAN PUÑETA  (Video Oficial).mp4*153284879*11/24/2019 7:35:42 PM",
    "Diabla -Ale_&amp;_D´Ray_(Video_Oficial).mp4*83593046*8/9/2020 11:38:34 AM",
    "Divan - Mi Amor Es Pobre.mp4*137230563*9/2/2020 3:42:21 PM",
    "Diván - Una sola na ma (Sesión de fotos en CartelStudioCuba) 52507902 - 52461684.mp4*62898290*8/22/2019 3:52:53 AM",
    "DIVAN -Cuando sientas.mp4*77268640*8/29/2019 3:43:18 AM",
    "DIVAN -Rico.mp4*75201799*8/29/2019 3:41:11 AM",
    "Dj Unic - Wow Popy_ El Yito - El Maldito ( Video Oficial )(720P_HD).mp4*148545974*9/6/2020 7:32:26 AM",
    "Drumi López- Déjame Amarte (Made by  The Diamond)(720P_HD)_1.mp4*85042813*9/4/2020 8:53:06 AM",
    "El Baice - La Yuma ( Video Oficial ) ft Dj Conds.mp4*79304594*9/3/2020 12:02:34 PM",
    "EL CHARLY FT LEYXE- VAMOS A PERDERNOS.mp4*86130784*8/7/2020 9:02:28 AM",
    "EL DANY ❌ RANDY MALCOM (GENTE DE ZONA) ► DIME A QUIEN ► (EN MEMORIA - DESCANSA EN PAZ) (DpuntoD).mp4*120349511*9/3/2020 12:01:52 PM",
    "EL DANY (DpuntoD) (YOMIL Y EL DANY) - PIDE - (EN MEMORIA - DESCANSA EN PAZ) SALSA DEL BARRIO.mp4*111371152*9/3/2020 12:03:03 PM",
    "EL DANY, LOS 4 - POR MUJERES - (EN MEMORIA - DESCANSA EN PAZ) (DpuntoD).mp4*87747479*9/3/2020 12:00:54 PM",
    "El Micha X Noel Schajris - Bandera.mp4*72549391*1/23/2020 11:12:02 AM",
    "El Pichi _ El Kimiko y Yordy - Secreto (Video Oficial)(720P_HD)_1.mp4*84991099*9/6/2020 8:01:31 AM",
    "El Pocho_ Jaysong_ Navir - Tic Taric (Audio Oficial)(360P).mp4*98610336*9/4/2020 8:53:07 AM",
    "El Real y la Espiral y Producciónes PMG Michel Productions(360P).mp4*57718336*9/4/2020 11:40:12 AM",
    "El Samu - Tu Galán ( Official Lyric Video)(480P)_1.mp4*84302172*12/7/2019 4:37:54 PM",
    "El Taiger - Wait a Minute (Video Oficial)(1080P_HD)_1.mp4*66803719*9/6/2020 8:01:32 AM",
    "El Taiger Ft El Carli X El Yowa - Te Nesecito.mp4*81853838*6/11/2020 2:51:43 PM",
    "El Uniko - Quisiera ( VIDEO OFICIAL )(720P_HD)_1.mp4*70732487*9/4/2020 8:53:08 AM",
    "Enzo La Melodia Secreta Ft Harryson - Corazon Partido.mp4*122134407*8/27/2020 3:53:31 PM",
    "Erick Lexi - Mujer Bonita.mp4*144306953*6/4/2020 11:40:04 AM",
    "FRANKLIN % ARRIBA DE MI_826x464.mp4*26153698*2/1/2019 4:55:38 AM",
    "Franny El Fenomeno ❌ El Kapoland - Maltrato (Audio Oficial)(480P)_1.mp4*83070203*9/6/2020 8:01:35 AM",
    "GENNY V - Modo Prosti(MAX PRO MUSIC BY PIVE).mp4*132834531*8/18/2020 7:29:00 PM",
    "Harrsion x Diplomatiko -Mas que eso.mp4*106447413*10/4/2019 5:40:30 PM",
    "Harryson - Mi Caminito (Animated Video)(720P_HD).mp4*85008032*9/4/2020 8:53:09 AM",
    "Harryson _ Orlenis 22k - Cobarde (Official Video)(720P_HD).mp4*83596095*9/4/2020 8:53:10 AM",
    "Harryson Ft El Ricky &amp; El Yanky - Tu Tanke.mp4*144622691*8/16/2020 8:41:09 PM",
    "Harryson❌Sultán Kbron-Tu Estas Pasa.Pro by Morales Record(Video Oficial 2020)(360P).mp4*108204012*9/4/2020 8:53:11 AM",
    "Hechizo-Wildey Q´Bola Studios OFICIAL.mp4*117371043*9/6/2020 8:01:18 AM",
    "JayBy - Tokyo(cartelstudiocuba 52507902 - 52461684).mp4*65099188*3/9/2020 12:40:00 PM",
    "JDany X Ozamy Ft Wow Popy - Si Pudiera.mp4*126954852*8/24/2020 12:22:11 PM",
    "Jeikel Acosta Ft Eduardo Antonio &amp; Abel SBS - La Cuenta No Da.mp4*153314147*8/6/2020 2:13:23 PM",
    "Jennifer Ksanova - TiTanic(PROD BY DJMAIKEL MUSIC).mp4*111471054*6/11/2020 11:55:01 AM",
    "Jhon Menez x El Furia x Mr Haison Full HD.mp4*101175680*9/4/2020 10:25:30 AM",
    "Kaiser Attakawa feat Kilo 2 - Cupido  (Video Oficial)(360P).mp4*86527353*9/6/2020 8:01:36 AM",
    "Kimiko &amp; Yordy Ft Henry Manuel - Mala.mp4*139871843*7/19/2020 4:15:14 PM",
    "KRLY X ARKY - PEGAITA A LA PARED.mp4*107267923*4/5/2019 5:31:33 AM",
    "La Crema ft El Negro pega con To_ - Burundanga (Vi(360P).mp4*87590172*9/6/2020 8:01:37 AM",
    "La Dinastia - Nota.mp4*154329620*9/6/2020 7:59:45 PM",
    "La Diosa_ Jorge Jr. -  Aprendiz (Video Oficial) _ Venganza(720P_HD)_1.mp4*120870178*9/4/2020 8:53:13 AM",
    "LA DURA EL BESITO.mp4*156033941*1/23/2020 11:20:28 AM",
    "LA ESTAS DANDO BIEN - NESTY REVOLUTIONS.mp4*129092557*12/15/2019 4:50:02 PM",
    "LA LA LA - LA DURA OK .mp4*91661395*1/23/2020 11:13:59 AM",
    "Lady Laura ft Micha - Mienteme.mp4*151955325*7/4/2017 3:58:33 PM",
    "Lady Laura Ft Srta Dayana - No Te Emociones.mp4*153244422*11/4/2018 1:47:02 PM",
    "Lady Laura QUE TE PREOCUPA prod Oficina Secreta.mp4*117336124*9/27/2017 4:10:52 PM",
    "Leony Cabrera _ El Powell X Robertony  - Palo Que Preocupa  (Video Oficial)(360P).mp4*73182522*9/6/2020 8:01:38 AM",
    "Lester Brayzan ► Hasta Abajo, Dale (OFFICIAL VIDEO) ► DEMBOW, REGGAETON 2020.mp4*95517246*9/3/2020 11:58:41 AM",
    "LKM - PLANES CONTIGO - (SALSA GHETTO - SALSA DEL BARRIO) I LOVE SALSA_(480P)_1.mp4*94732568*9/6/2020 8:01:39 AM",
    "Llora conmigo NBA.mp4*136075949*8/6/2020 9:25:33 PM",
    "Lobo Malo - Para Ella (Animated Video)(720P_HD)_1.mp4*75492812*9/4/2020 8:53:14 AM",
    "Madame Lety Ft Alvaro la Figura.mp4*90837047*9/6/2020 8:01:40 AM",
    "Maikel Miki Ft Sapienza - Pegao.mp4*128842129*9/1/2020 12:47:06 PM",
    "Maldita Maldita _ Wildey x El Kacho Mc.mp4*107623782*8/7/2020 9:01:28 AM",
    "MAWELL X CHAPY X EL FINAL X LA AIFON FENIX.mp4*84053611*7/12/2020 8:32:46 PM",
    "MAWELL X CHAPY X JORGITO YEI YEI X VERANO 2020 X LA AIFON FENIX.mp4*120217768*7/12/2020 8:33:25 PM",
    "ME OBSECIONE HD (Video oficial).mp4*113106392*5/3/2019 12:04:25 AM",
    "Me quieres -Ale_&amp;_D´Ray_Feat_The Maxi_ver.mp4*102018556*8/9/2020 11:38:39 AM",
    "Mistr Will D.Cuba - Me Tienes Loco.mp4*146263431*5/10/2020 10:39:31 PM",
    "Moikan  - En Visto  createdby.RAULIN RIGUAL  (((RDJ Productions Video.mp4*150915894*1/23/2020 1:43:18 PM",
    "MOIKAN X ALEX NAY - MIENTEME FULLHD.mp4*67826445*9/6/2020 8:01:41 AM",
    "MOIKAN X KRIS SOLA EJE RECORD.mp4*76794627*9/6/2020 8:01:42 AM",
    "NBA... RECUERDOS.mp4*107610522*8/6/2020 9:20:17 PM",
    "Nelson Diaz - Chica Vitamina.mp4*131035319*7/9/2020 4:05:22 PM",
    "No_me_mates_Full HD.mp4*64954024*3/5/2020 11:32:32 AM",
    "Ogunda ft Hector el Wuay-Ricachu.mp4*121823281*9/6/2020 8:01:44 AM",
    "Orishango - Wepa.mp4*20614617*8/16/2020 8:33:30 PM",
    "Orishango Ft Mendieta Music - Baila Con To.mp4*135749298*4/27/2020 12:36:38 AM",
    "Patry White - Correctamente Correcto.mp4*87192914*10/31/2019 12:08:17 PM",
    "PAYASO POR LEY X MAWELL X CHAPY  X TACTO X AIFON FENIX.mp4*97746517*7/12/2020 8:33:06 PM",
    "Piq Montano - No Se Si Volvera.mp4*142474945*8/18/2020 11:57:02 PM",
    "Prendio - HM Music Madame Lety_Hector El Wuay_Yuny Torres_EL MANIAKO.mp4*127848933*9/6/2020 8:01:15 AM",
    "Promo-Hector el Wuay-Melo Mc-Iyawo Oggun-Adonis Mc-Himno Nacional.mp4*101516749*9/6/2020 8:01:53 AM",
    "R3YMON - De  Life (Video Oficial).mp4*92247439*7/12/2020 4:21:06 PM",
    "Reggal - Gucci &amp; Prada.mp4*153922661*7/29/2020 10:51:42 PM",
    "RICHARD EL MORENO - QUIZAS - (SALSA GHETTO - SALSA DEL BARRIO) I LOVE SALSA!.mp4*90851835*9/3/2020 11:59:05 AM",
    "Roby Pulido - Cama y Mesa (Versión Salsa).mp4*70376718*8/27/2020 2:09:00 AM",
    "Ruly Rodriguez Ft BESFORD - Rico Rico.mp4*145670389*8/16/2020 8:54:13 PM",
    "SE ME OLVIDÓ (ÁNGEL D_ ANGELES)(360P).mp4*90361186*9/4/2020 8:53:16 AM",
    "Stefano - Noche Prohibida feat.Gero (Official Video - Version Explicit)(360P).mp4*75123011*9/4/2020 11:40:15 AM",
    "Tiarè - Cizaña Con Maldad.mp4*151787143*8/10/2020 10:11:26 PM",
    "Tiaré Ft Damian The Lion - Sin Frenos(By Tony El Genial).mp4*156521184*7/12/2020 4:57:05 PM",
    "TURBINA - HARRYSON FT EL PITU Y EL KRLY X EL ARTISTA VERDADERO.mp4*127742532*2/21/2020 5:46:50 PM",
    "UN CARIÑITO - EL TERRY.mp4*95477457*8/14/2020 8:41:48 AM",
    "Wil Campa Ft Papi Lacho - No Te Quedes Con Ganas.mp4*158821379*9/10/2018 2:35:20 AM",
    "WILDEY FT TOBY Y EL BRUJO  LA MANIATICA.mp4*119537197*9/6/2020 8:01:55 AM",
    "Wildey-No Eres la Mia (QBola Studios) video oficial.mp4*82231816*9/6/2020 8:01:16 AM",
    "X Alfonso_Solo es tiempo de dar amor_INSIDE (Video Oficial).mp4*67477421*8/2/2020 6:04:24 PM",
    "Xandor_La_X_No_queda_nada_★_$Wall_Street_Music_$_Prod._By_Dj_Chamo.mp4*92364371*8/7/2020 2:12:20 PM",
    "Xandor-Te deseo lo mejor(Official Video).mp4*156789925*8/7/2020 2:12:19 PM",
    "Yo te quiero menos JONNY X COJIMAR &amp; HAROL SL.mp4*152592112*9/6/2020 8:01:57 AM",
    13338248761,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]*0*9/13/2020 10:03:31 PM",
    0,
    "1014*1015*1016*1017*1018*1019*1020*1021*1022*1023*1024*1025*1026*1027*1028*1029*1030*1031*1032*1033*1034*1035*1036*1037*1038*1039*1040*1041*1042*1043*1044*1045"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Acorralada*0*9/13/2020 10:27:33 PM",
    "123 - Acorralada (Mexico).mpg*457832648*3/13/2015 3:45:39 AM",
    "124 - Acorralada (Mexico).mpg*470024352*3/13/2015 3:46:07 AM",
    "125 - Acorralada (Mexico).mpg*485994880*3/13/2015 3:46:15 AM",
    1413851880,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Al fondo hay sitio*0*9/13/2020 11:09:38 PM",
    "Al fondo hay sitio 8 [Cap-183].mp4*207990569*11/22/2016 11:11:47 PM",
    "Al fondo hay sitio 8 [Cap-184].mp4*208721099*11/23/2016 11:50:14 PM",
    "Al fondo hay sitio 8 [Cap-185].mp4*215266521*11/25/2016 12:17:43 AM",
    631978189,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Al otro lado del muro*0*9/13/2020 8:59:58 PM",
    "61Al otro lado del muro [Cap-61] [17-05-2018].avi*153973890*5/17/2018 11:19:13 PM",
    "62Al otro lado del muro [Cap-62] [18-05-2018].avi*159172994*5/18/2018 11:40:59 PM",
    "63Al otro lado del muro [Cap-63] [21-05-2018].avi*157320854*5/21/2018 10:59:22 PM",
    470467738,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\America [Brasil]*0*9/13/2020 8:59:35 PM",
    "America - 043.mpg*419016704*3/31/2020 10:18:43 PM",
    "America - 044.mpg*415244288*3/31/2020 10:18:46 PM",
    "America - 045.mpg*416122880*4/3/2020 8:25:44 PM",
    1250383872,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Amo de casa*0*9/13/2020 8:59:21 PM",
    "Amo de casa [cap-49].avi*146976584*9/15/2016 11:21:26 AM",
    "Amo de casa [cap-50].avi*146956960*9/15/2016 11:21:26 AM",
    "Amo de casa [cap-51].avi*287993592*9/22/2016 4:29:59 PM",
    581927136,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Asi En El Barrio Como En El Cielo*0*9/13/2020 11:06:59 PM",
    "34-ASI EN EL BARRIO COMO EN EL CIELO 2-4.avi*143138772*4/3/2015 2:20:27 AM",
    "35-ASI EN EL BARRIO COMO EN EL CIELO 3-4.avi*140332286*4/4/2015 12:57:56 AM",
    "36-ASI EN EL BARRIO COMO EN EL CIELO 6-4.avi*139463118*4/7/2015 1:19:39 PM",
    422934176,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Chica  Vampiro*0*9/13/2020 9:49:25 PM",
    "Chica Vampiro 046.mpg*226476032*7/29/2015 12:16:12 AM",
    "Chica Vampiro 047.mpg*309131264*7/29/2015 12:17:55 AM",
    "Chica Vampiro 048.mpg*290562048*7/29/2015 12:19:16 AM",
    826169344,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\De que te quiero te quiero (Mexico)*0*9/13/2020 9:49:51 PM",
    "099 - De que te quiero te quiero (Mexico).mpg*424076548*2/14/2015 8:12:05 PM",
    "100 - De que te quiero te quiero (Mexico).mpg*416744328*2/14/2015 8:12:31 PM",
    "101 - De que te quiero te quiero (Mexico).mpg*424013800*2/14/2015 8:14:05 PM",
    1264834676,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\El Beso Del Escorpion*0*9/13/2020 11:19:15 PM",
    "58El beso del escorpion [Cap-58] [16-11-2016].avi*174140428*11/16/2016 9:09:36 PM",
    "59El beso del escorpion [Cap-59] [16-11-2016].avi*168386444*11/17/2016 9:10:41 PM",
    "60El beso del escorpion [Cap-60] [18-11-2016].avi*168005078*11/18/2016 10:26:11 PM",
    510531950,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\El Dandy*0*9/13/2020 11:08:23 PM",
    "El dandy [Cap-66] [02-03-2016].avi*148073188*3/3/2016 1:52:02 AM",
    "El dandy [Cap-67] [03-03-2016].avi*151761714*3/3/2016 7:41:00 PM",
    "El dandy [Cap-68] [07-03-2016].avi*158519430*3/8/2016 2:06:28 AM",
    458354332,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\El fin*0*9/13/2020 9:50:54 PM",
    "El fin [son] [Cap-46].mp4*170702326*5/14/2020 10:17:56 PM",
    "El fin [son] [Cap-47].mp4*202121883*5/14/2020 10:17:56 PM",
    "El fin [son] [Cap-48].mp4*144029052*5/14/2020 10:17:56 PM",
    516853261,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\El hotel de los secretos*0*9/13/2020 11:19:22 PM",
    "25El hotel de los secretos [Cap-25] [29-02-2016].avi*150487536*3/1/2016 2:26:31 AM",
    "26El hotel de los secretos [Cap-26] [01-03-2016].avi*151763306*3/1/2016 11:32:02 PM",
    "27El hotel de los secretos [Cap-27] [02-03-2016].avi*148075626*3/2/2016 11:52:19 PM",
    450326468,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\El regreso de lucas*0*9/13/2020 10:23:43 PM",
    "40El regreso de lucas [Cap-40] [03-01-2017].avi*101447396*1/4/2017 12:55:34 AM",
    "41El regreso de lucas [Cap-41] [05-01-2017].avi*91167234*1/5/2017 11:04:40 PM",
    "42El regreso de lucas [Cap-42] [05-01-2017].avi*83239990*1/5/2017 11:04:52 PM",
    275854620,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\El sodero de mi vida*0*9/13/2020 11:08:28 PM",
    "132El sodero de mi vida [Cap-132] [13-12-2016].mp4*107540004*12/12/2016 9:09:24 PM",
    "133El sodero de mi vida [Cap-133] [14-12-2016].mp4*109525434*12/12/2016 12:41:50 PM",
    "134El sodero de mi vida [Cap-134] [15-12-2016].mp4*105941830*12/12/2016 9:09:26 PM",
    323007268,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\El Talisman*0*9/13/2020 9:51:11 PM",
    "89-El Talisman - 089.avi*148232024*6/17/2012 8:20:10 PM",
    "90-El Talisman - 090.avi*149969510*6/17/2012 8:33:05 PM",
    "91-El Talisman - 091.avi*149524578*6/17/2012 8:45:52 PM",
    447726112,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Fuego En La Sangre*0*9/13/2020 9:51:23 PM",
    "188.webm*205055095*8/30/2014 3:37:41 PM",
    "189.webm*227797272*8/28/2014 11:08:51 PM",
    "190.webm*197287060*8/30/2014 3:37:31 PM",
    630139427,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Isla Paraiso*0*9/13/2020 9:51:35 PM",
    "Isla paraiso [Cap-119].mp4*283723615*3/26/2019 8:48:49 PM",
    "Isla paraiso [Cap-120].mp4*283162111*3/27/2019 10:12:50 PM",
    "Isla paraiso [Cap-121].mp4*306370242*3/28/2019 8:54:40 PM",
    873255968,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Juana La Virgen*0*9/13/2020 10:23:48 PM",
    "Juana la Virgen - 064.mp4*153362181*11/15/2014 3:17:37 PM",
    "Juana la Virgen - 065.mp4*150285413*11/15/2014 3:17:59 PM",
    "Juana la Virgen - 066.mp4*175022731*11/15/2014 3:22:27 PM",
    478670325,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Juegos Prohibidos*0*9/13/2020 9:51:51 PM",
    "43 - Juegos Prohibidos.avi*366823424*7/22/2017 2:00:00 AM",
    "44 - Juegos Prohibidos.avi*314384384*7/22/2017 2:00:00 AM",
    "45 - Juegos Prohibidos.avi*236574720*7/22/2017 2:00:00 AM",
    917782528,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\LA TRAICION*0*9/13/2020 11:26:23 PM",
    "LA TRAICIÓN - 022.avi*344772608*1/7/2010 1:29:20 AM",
    "LA TRAICIÓN - 023.avi*323858432*1/5/2010 8:28:50 PM",
    "LA TRAICIÓN - 024.avi*327124992*1/5/2010 5:48:58 AM",
    995756032,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Los Vecinos en Guerra*0*9/13/2020 8:50:29 PM",
    "Los Vecinos en Guerra - 127.mpg*405940052*12/4/2013 1:43:10 AM",
    "Los Vecinos en Guerra - 128.mpg*257299336*12/6/2013 12:41:21 AM",
    "Los Vecinos en Guerra - 129.mpg*378726012*12/7/2013 3:49:31 AM",
    1041965400,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Maria magdalena*0*9/13/2020 11:19:57 PM",
    "Maria magdalena [Cap-34].mp4*567711437*11/30/2018 11:55:50 PM",
    "Maria magdalena [Cap-35].mp4*210014740*12/4/2018 12:00:59 AM",
    "Maria magdalena [Cap-36].mp4*237264680*12/5/2018 12:04:55 AM",
    1014990857,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Mi familia perfecta*0*9/13/2020 11:30:17 PM",
    "Mi familia perfecta [Cap-34] [25-05-2018].mp4*288354694*5/25/2018 9:58:52 PM",
    "Mi familia perfecta [Cap-35] [28-05-2018].mp4*349702316*5/28/2018 10:20:24 PM",
    "Mi familia perfecta [Cap-36] [29-05-2018].mp4*361279085*5/29/2018 11:17:37 PM",
    999336095,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Mi hermano es un clon*0*9/13/2020 11:31:44 PM",
    "Mi hermano es un clon [Cap-70].mp4*493635695*12/7/2018 9:34:38 PM",
    "Mi hermano es un clon [Cap-71].mp4*490039475*12/10/2018 10:38:15 PM",
    "Mi hermano es un clon [Cap-72].mp4*604053327*12/11/2018 11:31:44 PM",
    1587728497,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Promesa*0*9/13/2020 11:08:35 PM",
    "Promesa [Cap-76].mp4*333805620*1/5/2018 12:24:15 AM",
    "Promesa [Cap-77].mp4*353455030*1/5/2018 12:20:08 AM",
    "Promesa [Cap-78].mp4*345285602*1/5/2018 12:23:06 AM",
    1032546252,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Secretos de Amor*0*9/13/2020 10:02:46 PM",
    "Secretos de amor 33.divx*170027946*7/13/2011 11:33:36 AM",
    "Secretos de amor 34.divx*169930182*7/13/2011 12:01:42 PM",
    "Secretos de amor 35.divx*191177484*7/13/2011 12:29:20 PM",
    531135612,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Socias*0*9/13/2020 10:03:05 PM",
    "SOCIAS - 41.mpg*486035460*9/21/2013 3:39:38 AM",
    "SOCIAS - 42.mpg*486141956*9/21/2013 3:43:57 AM",
    "SOCIAS - 43.mpg*435685380*9/21/2013 3:47:47 AM",
    1407862796,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Teresa*0*9/13/2020 11:22:58 PM",
    "Teresa - Capitulo 62 (HD).mpg*426817536*4/25/2018 3:15:32 AM",
    "Teresa - Capitulo 63 (HD).mpg*447281152*4/25/2018 3:16:16 AM",
    "Teresa - Capitulo 64 (HD).mpg*437764096*4/25/2018 3:16:21 AM",
    1311862784,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Trapos intimos [Mexico]*0*9/13/2020 10:24:52 PM",
    "Trapos Intimos Capitulo 150.mp4*150503497*8/24/2015 3:52:40 PM",
    "Trapos Intimos Capitulo 151.mp4*175052184*8/27/2015 9:00:48 AM",
    "Trapos Intimos Capitulo 152.mp4*176068194*8/27/2015 9:00:47 AM",
    501623875,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\UN SUEÑO LLAMADO SALSA*0*9/13/2020 10:03:30 PM",
    "40-UN SUEÑO LLAMADO SALSA N&amp;R.avi*150278054*4/5/2013 4:26:28 PM",
    "41-UN SUEÑO LLAMADO SALSA N&amp;R.avi*152641378*4/5/2013 4:28:24 PM",
    "42-UN SUEÑO LLAMADO SALSA N&amp;R.avi*151055674*4/5/2013 4:30:14 PM",
    453975106,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Victoria*0*9/13/2020 10:24:54 PM",
    "Victoria [Cap-168] [17-02-2019].mp4*132099232*2/17/2019 7:42:00 PM",
    "Victoria [Cap-169] [17-02-2019].mp4*130142381*2/17/2019 7:42:59 PM",
    "Victoria [Cap-170] [17-02-2019].mp4*135735664*2/17/2019 7:43:20 PM",
    397977277,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Clásicas][Cap Sueltos]\\Yo Soy Bea*0*9/13/2020 10:25:00 PM",
    "Yo.Soy.Bea.S01E119.avi*357301018*9/4/2010 2:34:39 PM",
    "Yo.Soy.Bea.S01E120.avi*342742238*8/31/2010 1:09:35 PM",
    "Yo.Soy.Bea.S01E121.avi*333452114*9/1/2010 7:13:51 AM",
    1033495370,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]*0*9/13/2020 10:09:53 PM",
    0,
    "1047*1048*1049*1050*1051*1052*1053*1054*1055*1056*1057*1058*1059*1060*1061*1062*1063*1064*1065*1066*1067*1068*1069*1070*1071*1072*1073*1074*1075*1076*1077*1078*1079*1080*1081*1082*1083*1084*1085*1086*1087*1088*1089*1090*1091*1092*1093*1094*1095*1096*1097*1098*1099*1100*1101*1102*1103*1104*1105*1106*1107*1108*1109*1110*1111*1112*1113*1114*1115*1116*1117*1118*1119*1120*1121*1122*1123*1124*1125*1126*1127*1128*1129*1130*1131*1132*1133*1134*1135*1136*1137*1138*1139*1140*1141*1142*1143"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\[ESTRENO] Alma rebelde*0*9/13/2020 8:33:01 PM",
    "Alma rebelde [Cap-2] [08-09-2020].mp4*290681133*9/10/2020 2:35:44 AM",
    "Alma rebelde [Cap-3] [09-09-2020].avi*329296346*8/31/2020 1:37:49 AM",
    619977479,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\[ESTRENO] Calikusu*0*9/13/2020 10:18:58 PM",
    "Calikusu - 1.mp4*489371224*9/4/2020 8:41:50 PM",
    "Calikusu - 2.mp4*417361665*9/4/2020 8:38:48 PM",
    "Calikusu - 3.mp4*410869459*9/4/2020 8:38:03 PM",
    "Calikusu - 4.mp4*429421528*9/4/2020 8:37:49 PM",
    "Calikusu [Cap-5] [08-09-2020].mp4*970991912*9/6/2020 11:01:36 PM",
    "Calikusu [Cap-6] [08-09-2020].mp4*480566432*9/6/2020 11:04:50 PM",
    "Calikusu [Cap-7] [08-09-2020].mp4*967919648*9/6/2020 11:03:54 PM",
    4166501868,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\[ESTRENO] Casate conmigo*0*9/13/2020 11:27:13 PM",
    "Casate conmigo [Cap-4] [10-09-2020].mp4*672714289*9/4/2020 1:10:35 AM",
    "Casate conmigo [Cap-5] [11-09-2020].mp4*761052564*9/4/2020 1:13:55 AM",
    1433766853,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\[ESTRENO] Günahkar*0*9/13/2020 8:59:38 PM",
    "Günahkar [Cap-1] [08-09-2020].mp4*1176131760*9/7/2020 4:44:01 PM",
    1176131760,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\[ESTRENO] Hanım köylü*0*9/13/2020 8:59:48 PM",
    "Hanım köylü [Cap-1] [08-09-2020].mp4*1052281120*9/7/2020 5:05:19 PM",
    1052281120,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\[ESTRENO] Imperio de mentiras*0*9/13/2020 11:15:47 PM",
    "Imperio de mentiras [Cap-1] [12-09-2020].mp4*150892404*9/11/2020 7:34:33 PM",
    150892404,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\[ESTRENO] Jezabel*0*9/13/2020 11:07:01 PM",
    "Jezabel [Cap-1] [08-09-2020].mp4*666069114*9/7/2020 7:56:11 PM",
    "Jezabel [Cap-2] [08-09-2020].mp4*554909066*9/7/2020 7:38:15 PM",
    "Jezabel [Cap-3] [09-09-2020].mp4*604340157*9/8/2020 8:01:21 PM",
    "Jezabel [Cap-4] [09-09-2020].ts*784376984*9/8/2020 7:49:44 PM",
    "Jezabel [Cap-5] [11-09-2020].mp4*590180177*9/10/2020 7:49:39 PM",
    "Jezabel [Cap-6] [11-09-2020].mp4*583422663*9/10/2020 7:57:34 PM",
    3783298161,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\[ESTRENO] Karadayi*0*9/13/2020 10:19:06 PM",
    "!!!Version Extendida!!!.txt*0*9/11/2020 12:17:48 PM",
    "Karadayi - 1.mp4*969304798*9/4/2020 8:58:41 PM",
    "Karadayi - 2.mp4*194127452*9/4/2020 8:18:07 PM",
    "Karadayi - 3.mp4*409795372*9/4/2020 8:38:45 PM",
    "Karadayi - 4.mp4*492268848*9/4/2020 8:40:06 PM",
    "Karadayi - 5.mp4*483276551*9/4/2020 8:34:20 PM",
    "Karadayi [Cap-6] [08-09-2020].mp4*501075201*9/6/2020 11:42:22 PM",
    3049848222,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\[ESTRENO] Kirmizi Oda (La Habitacion Roja)*0*9/13/2020 11:15:50 PM",
    "Kirmizi Oda (La Habitacion Roja) 1.mp4*1730853208*9/10/2020 1:08:14 AM",
    1730853208,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\[ESTRENO] Sobreviviendo a escobar alias jj*0*9/13/2020 9:01:01 PM",
    "Sobreviviendo a escobar alias jj [Cap-5] [11-09-2020].mp4*636101477*9/9/2020 9:57:12 PM",
    "Sobreviviendo a escobar alias jj [Cap-6] [11-09-2020].mp4*633538259*9/10/2020 9:58:52 PM",
    1269639736,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\[estreno] Suerte de vivir*0*9/13/2020 9:01:06 PM",
    "Suerte de vivir [Cap-1] [08-09-2020].mp4*183176984*9/8/2020 12:57:01 AM",
    "Suerte De Vivir.jpeg*10161*9/8/2020 12:36:54 AM",
    183187145,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\[ESTRENO] Yeni Hayat*0*9/13/2020 9:01:19 PM",
    "Yeni Hayat - 1.mp4*642539383*9/4/2020 10:34:30 PM",
    "Yeni Hayat.jpg*12214*9/4/2020 10:30:31 PM",
    642551597,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Acil Ask Araniyor (Amor En Emergencia)*0*9/13/2020 11:32:08 PM",
    "Acil Ask Araniyor (Amor En Emergencia) - 61.mp4*375490896*9/8/2020 12:24:04 AM",
    "Acil Ask Araniyor (Amor En Emergencia) - 62.mp4*368630024*9/8/2020 12:23:17 AM",
    "Acil Ask Araniyor (Amor En Emergencia) - 63.mp4*379249016*9/8/2020 12:22:35 AM",
    "Acil Ask Araniyor (Amor En Emergencia) - 64.mp4*162248888*9/8/2020 12:21:50 AM",
    "Acil Ask Araniyor (Amor En Emergencia) - 65.mp4*301664424*9/8/2020 12:21:32 AM",
    "Acil Ask Araniyor (Amor En Emergencia) - 66.mp4*123175156*9/8/2020 12:20:58 AM",
    "Acil Ask Araniyor (Amor En Emergencia) - 67.mp4*119589808*9/8/2020 12:20:43 AM",
    "Acil Ask Araniyor (Amor En Emergencia) - 68.mp4*132332448*9/8/2020 12:20:30 AM",
    1962380660,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Amarte asi frijolito*0*9/13/2020 11:30:23 PM",
    "Amarte asi frigolito [Cap-39] [06-09-2020].mp4*373519501*9/2/2020 8:23:01 PM",
    "Amarte asi frigolito [Cap-40] [06-09-2020].mp4*350338623*9/2/2020 8:23:39 PM",
    "Amarte asi frigolito [Cap-41] [06-09-2020].mp4*328980211*9/2/2020 8:24:15 PM",
    "Amarte asi frijolito [Cap-100] [08-09-2020].mp4*391684483*9/5/2020 9:28:52 PM",
    "Amarte asi frijolito [Cap-101] [08-09-2020].mp4*365281962*9/5/2020 9:27:59 PM",
    "Amarte asi frijolito [Cap-102] [08-09-2020].mp4*609175643*9/5/2020 9:27:03 PM",
    "Amarte asi frijolito [Cap-103] [08-09-2020].mp4*606103491*9/5/2020 9:25:30 PM",
    "Amarte asi frijolito [Cap-105] [08-09-2020].mp4*375056606*9/5/2020 9:23:29 PM",
    "Amarte asi frijolito [Cap-106] [08-09-2020].mp4*600307409*9/5/2020 9:22:46 PM",
    "Amarte asi frijolito [Cap-107] [08-09-2020].mp4*504269865*9/5/2020 9:21:40 PM",
    "Amarte asi frijolito [Cap-108] [08-09-2020].mp4*540334394*9/5/2020 9:20:13 PM",
    "Amarte asi frijolito [Cap-109] [08-09-2020].mp4*527978834*9/5/2020 9:18:50 PM",
    "Amarte asi frijolito [Cap-110] [08-09-2020].mp4*521511881*9/5/2020 9:17:58 PM",
    "Amarte asi frijolito [Cap-111] [08-09-2020].mp4*557595385*9/5/2020 9:17:11 PM",
    "Amarte asi frijolito [Cap-112] [08-09-2020].mp4*568706132*9/5/2020 9:16:20 PM",
    "Amarte asi frijolito [Cap-113] [08-09-2020].mp4*499375945*9/5/2020 9:14:42 PM",
    "Amarte asi frijolito [Cap-115] [08-09-2020].mp4*532859611*9/5/2020 9:11:55 PM",
    "Amarte asi frijolito [Cap-117] [08-09-2020].mp4*545997001*9/5/2020 9:09:56 PM",
    "Amarte asi frijolito [Cap-118] [08-09-2020].mp4*557996421*9/5/2020 9:08:45 PM",
    "Amarte asi frijolito [Cap-42] [06-09-2020].mp4*349654734*9/3/2020 10:25:18 PM",
    "Amarte asi frijolito [Cap-43] [06-09-2020].mp4*332954635*9/3/2020 10:25:37 PM",
    "Amarte asi frijolito [Cap-44] [06-09-2020].mp4*358133692*9/3/2020 10:26:44 PM",
    "Amarte asi frijolito [Cap-45] [08-09-2020].mp4*343023775*9/4/2020 8:50:06 PM",
    "Amarte asi frijolito [Cap-46] [08-09-2020].mp4*368423002*9/4/2020 8:50:44 PM",
    "Amarte asi frijolito [Cap-47] [08-09-2020].mp4*368697383*9/4/2020 8:28:58 PM",
    "Amarte asi frijolito [Cap-48] [08-09-2020].mp4*383100670*9/4/2020 8:30:37 PM",
    "Amarte asi frijolito [Cap-49] [08-09-2020].mp4*323345014*9/4/2020 8:51:21 PM",
    "Amarte asi frijolito [Cap-50] [08-09-2020].mp4*336089540*9/4/2020 8:49:40 PM",
    "Amarte asi frijolito [Cap-51] [08-09-2020].mp4*330988383*9/5/2020 10:20:18 PM",
    "Amarte asi frijolito [Cap-52] [08-09-2020].mp4*346720018*9/5/2020 10:19:40 PM",
    "Amarte asi frijolito [Cap-53] [08-09-2020].mp4*341821090*9/5/2020 10:18:57 PM",
    "Amarte asi frijolito [Cap-54] [08-09-2020].mp4*363833286*9/5/2020 10:18:23 PM",
    "Amarte asi frijolito [Cap-55] [08-09-2020].mp4*345156464*9/5/2020 10:17:45 PM",
    "Amarte asi frijolito [Cap-56] [08-09-2020].mp4*365698585*9/5/2020 10:17:09 PM",
    "Amarte asi frijolito [Cap-57] [08-09-2020].mp4*364498850*9/5/2020 10:16:33 PM",
    "Amarte asi frijolito [Cap-58] [08-09-2020].mp4*506644065*9/5/2020 10:15:53 PM",
    "Amarte asi frijolito [Cap-59] [08-09-2020].mp4*565246032*9/5/2020 10:14:56 PM",
    "Amarte asi frijolito [Cap-60] [08-09-2020].mp4*362717886*9/5/2020 10:13:51 PM",
    "Amarte asi frijolito [Cap-61] [08-09-2020].mp4*380215552*9/5/2020 10:13:12 PM",
    "Amarte asi frijolito [Cap-62] [08-09-2020].mp4*337660968*9/5/2020 10:12:21 PM",
    "Amarte asi frijolito [Cap-63] [08-09-2020].mp4*358335541*9/5/2020 10:11:41 PM",
    "Amarte asi frijolito [Cap-64] [08-09-2020].mp4*546795805*9/5/2020 10:10:59 PM",
    "Amarte asi frijolito [Cap-65] [08-09-2020].mp4*569884088*9/5/2020 10:09:46 PM",
    "Amarte asi frijolito [Cap-66] [08-09-2020].mp4*511937503*9/5/2020 10:08:37 PM",
    "Amarte asi frijolito [Cap-67] [08-09-2020].mp4*526336183*9/5/2020 10:07:37 PM",
    "Amarte asi frijolito [Cap-68] [08-09-2020].mp4*530368852*9/5/2020 10:06:33 PM",
    "Amarte asi frijolito [Cap-69] [08-09-2020].mp4*537106090*9/5/2020 10:02:54 PM",
    "Amarte asi frijolito [Cap-70] [08-09-2020].mp4*507961113*9/5/2020 9:59:29 PM",
    "Amarte asi frijolito [Cap-71] [08-09-2020].mp4*490505667*9/5/2020 9:58:16 PM",
    "Amarte asi frijolito [Cap-72] [08-09-2020].mp4*554875022*9/5/2020 9:56:45 PM",
    "Amarte asi frijolito [Cap-73] [08-09-2020].mp4*590277291*9/5/2020 9:55:31 PM",
    "Amarte asi frijolito [Cap-74] [08-09-2020].mp4*362632696*9/5/2020 9:54:21 PM",
    "Amarte asi frijolito [Cap-75] [08-09-2020].mp4*368868640*9/5/2020 9:53:32 PM",
    "Amarte asi frijolito [Cap-76] [08-09-2020].mp4*386913309*9/5/2020 9:52:51 PM",
    "Amarte asi frijolito [Cap-77] [08-09-2020].mp4*413602818*9/5/2020 9:52:09 PM",
    "Amarte asi frijolito [Cap-78] [08-09-2020].mp4*390462334*9/5/2020 9:51:27 PM",
    "Amarte asi frijolito [Cap-79] [08-09-2020].mp4*575058820*9/5/2020 9:50:44 PM",
    "Amarte asi frijolito [Cap-82] [08-09-2020].mp4*367870597*9/5/2020 9:48:17 PM",
    "Amarte asi frijolito [Cap-83] [08-09-2020].mp4*370516902*9/5/2020 9:47:35 PM",
    "Amarte asi frijolito [Cap-84] [08-09-2020].mp4*400103702*9/5/2020 9:46:55 PM",
    "Amarte asi frijolito [Cap-85] [08-09-2020].mp4*380961036*9/5/2020 9:46:15 PM",
    "Amarte asi frijolito [Cap-86] [08-09-2020].mp4*407185851*9/5/2020 9:45:34 PM",
    "Amarte asi frijolito [Cap-87] [10-09-2020].mp4*346096350*9/5/2020 9:43:04 PM",
    "Amarte asi frijolito [Cap-88] [08-09-2020].mp4*457169247*9/5/2020 9:44:48 PM",
    "Amarte asi frijolito [Cap-89] [08-09-2020].mp4*481182795*9/5/2020 9:43:57 PM",
    "Amarte asi frijolito [Cap-90] [08-09-2020].mp4*346096350*9/5/2020 9:43:04 PM",
    "Amarte asi frijolito [Cap-91] [08-09-2020].mp4*513477018*9/5/2020 9:42:30 PM",
    "Amarte asi frijolito [Cap-92] [08-09-2020].mp4*547282077*9/5/2020 9:41:22 PM",
    "Amarte asi frijolito [Cap-94] [08-09-2020].mp4*570773892*9/5/2020 9:38:07 PM",
    "Amarte asi frijolito [Cap-95] [08-09-2020].mp4*538643076*9/5/2020 9:36:58 PM",
    "Amarte asi frijolito [Cap-96] [08-09-2020].mp4*693851406*9/5/2020 9:35:42 PM",
    "Amarte asi frijolito [Cap-97] [08-09-2020].mp4*651940250*9/5/2020 9:34:04 PM",
    "Amarte asi frijolito [Cap-98] [08-09-2020].mp4*580919223*9/5/2020 9:32:57 PM",
    "Amarte asi frijolito [Cap-99] [08-09-2020].mp4*578416820*9/5/2020 9:31:01 PM",
    33586105386,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Amor bravío [1080p]*0*9/13/2020 10:42:09 PM",
    "Amor bravio [Cap-125] [10-09-2020].mp4*1599287675*8/21/2020 2:39:11 AM",
    "Amor bravio [Cap-126] [11-09-2020].mp4*1530876594*8/21/2020 2:40:17 AM",
    3130164269,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Amor de madre*0*9/13/2020 10:38:04 PM",
    "Amor de madre [Cap-47] [08-09-2020].mp4*493356835*9/7/2020 9:07:40 PM",
    "Amor de madre [Cap-48] [10-09-2020].mp4*462943263*9/9/2020 10:21:47 AM",
    "Amor de madre [Cap-49] [10-09-2020].mp4*528295038*9/9/2020 9:16:18 PM",
    "Amor de madre [Cap-50] [11-09-2020].mp4*465903596*9/10/2020 11:57:59 PM",
    "Amor de madre [Cap-51] [12-09-2020].mp4*476747196*9/11/2020 11:24:37 PM",
    2427245928,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Apuesta por un amor*0*9/13/2020 11:16:25 PM",
    "Apuesta por un amor [Cap-24] [08-09-2020].mp4*363847128*9/5/2020 10:28:22 PM",
    "Apuesta por un amor [Cap-25] [08-09-2020].mp4*360817892*9/5/2020 10:27:42 PM",
    "Apuesta por un amor [Cap-26] [08-09-2020].mp4*362684615*9/5/2020 10:27:00 PM",
    "Apuesta por un amor [Cap-28] [08-09-2020].mp4*365745660*9/5/2020 10:25:33 PM",
    "Apuesta por un amor [Cap-29] [08-09-2020].mp4*366309379*9/5/2020 9:15:24 PM",
    "Apuesta por un amor [Cap-30] [08-09-2020].mp4*249080233*9/5/2020 9:16:51 PM",
    "Apuesta por un amor [Cap-31] [08-09-2020].mp4*364717145*9/5/2020 9:21:53 PM",
    "Apuesta por un amor [Cap-32] [08-09-2020].mp4*363813519*9/5/2020 11:16:44 PM",
    "Apuesta por un amor [Cap-34] [08-09-2020].mp4*361519323*9/5/2020 9:26:37 PM",
    "Apuesta por un amor [Cap-35] [08-09-2020].mp4*360168417*9/5/2020 9:28:40 PM",
    "Apuesta por un amor [Cap-36] [08-09-2020].mp4*364240282*9/5/2020 9:30:47 PM",
    "Apuesta por un amor [Cap-37] [08-09-2020].mp4*362385589*9/5/2020 9:32:54 PM",
    "Apuesta por un amor [Cap-38] [08-09-2020].mp4*361925497*9/5/2020 9:37:38 PM",
    "Apuesta por un amor [Cap-39] [08-09-2020].mp4*358870455*9/5/2020 9:42:07 PM",
    4966125134,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Aquelarre*0*9/13/2020 8:38:55 PM",
    "Aquelarre - 38.mp4*825268027*9/4/2020 7:25:33 PM",
    "Aquelarre [Cap-36] [06-09-2020].mp4*922650112*9/6/2020 7:45:12 AM",
    "Aquelarre [Cap-37] [06-09-2020].mp4*809346451*9/3/2020 9:40:39 PM",
    2557264590,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Baraj*0*9/13/2020 8:39:03 PM",
    "Baraj [Cap-9] [08-09-2020].mp4*492026832*9/7/2020 5:38:45 PM",
    492026832,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Beintehaa*0*9/13/2020 8:39:12 PM",
    "Beintehaa [Cap-197] [12-09-2020].mp4*100215153*9/11/2020 9:52:14 PM",
    "Beintehaa [Cap-198] [12-09-2020].mp4*113825356*9/11/2020 9:52:34 PM",
    214040509,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Blanco color de amor*0*9/13/2020 11:14:16 PM",
    "Blanco Color De Amor - 131.mp4*620219786*9/1/2020 9:50:51 PM",
    "Blanco Color De Amor - 134.mp4*320997780*9/4/2020 6:47:28 PM",
    "Blanco Color De Amor - 135.mp4*324472772*9/4/2020 7:13:35 PM",
    "Blanco Color De Amor - 136.mp4*320746988*9/4/2020 6:58:42 PM",
    "Blanco Color De Amor - 137.mp4*319048408*9/4/2020 7:00:20 PM",
    "Blanco Color De Amor - 138.mp4*312942168*9/4/2020 7:01:50 PM",
    "Blanco Color De Amor - 139.mp4*316488036*9/4/2020 7:03:20 PM",
    "Blanco Color De Amor - 140.mp4*303571872*9/4/2020 7:04:49 PM",
    "Blanco Color De Amor - 141.mp4*307537544*9/4/2020 7:06:53 PM",
    "Blanco Color De Amor - 142.mp4*308322444*9/4/2020 7:08:25 PM",
    "Blanco Color De Amor - 143.mp4*307944000*9/4/2020 7:09:55 PM",
    "Blanco Color De Amor - 144.mp4*301421716*9/4/2020 7:31:17 PM",
    "Blanco Color De Amor - 145.mp4*309784144*9/4/2020 6:52:38 PM",
    "Blanco Color De Amor - 146.mp4*308744504*9/4/2020 7:14:31 PM",
    "Blanco Color De Amor - 147.mp4*311268968*9/4/2020 6:52:27 PM",
    "Blanco Color De Amor - 148.mp4*311364848*9/4/2020 6:51:28 PM",
    "Blanco Color De Amor - 149.mp4*298213120*9/4/2020 6:51:07 PM",
    "Blanco Color De Amor - 150.mp4*293897204*9/4/2020 6:50:20 PM",
    "Blanco Color De Amor - 151.mp4*307920876*9/4/2020 7:16:02 PM",
    "Blanco Color De Amor - 152.mp4*304261456*9/4/2020 6:50:07 PM",
    "Blanco Color De Amor - 153.mp4*300122072*9/4/2020 6:49:14 PM",
    "Blanco Color De Amor - 154.mp4*304972660*9/4/2020 6:49:03 PM",
    "Blanco Color De Amor - 155.mp4*304957432*9/4/2020 6:47:35 PM",
    "Blanco Color De Amor - 156.mp4*302358332*9/4/2020 7:16:05 PM",
    "Blanco Color De Amor - 157.mp4*297281580*9/4/2020 6:47:26 PM",
    "Blanco Color De Amor - 158.mp4*301220556*9/4/2020 7:14:32 PM",
    "Blanco Color De Amor - 159.mp4*312400540*9/4/2020 7:13:31 PM",
    "Blanco Color De Amor - 160.mp4*310615668*9/4/2020 7:12:20 PM",
    "Blanco color de amor [Cap-114] [02-09-2020].mp4*342323890*8/30/2020 7:56:41 PM",
    "Blanco color de amor [Cap-115] [02-09-2020].mp4*224052626*8/30/2020 7:54:55 PM",
    "Blanco color de amor [Cap-116] [02-09-2020].mp4*696068207*8/30/2020 7:59:37 PM",
    "Blanco color de amor [Cap-117] [02-09-2020].mp4*258501302*8/30/2020 7:56:56 PM",
    "Blanco color de amor [Cap-118] [02-09-2020].mp4*259487688*8/30/2020 7:58:35 PM",
    "Blanco color de amor [Cap-119] [02-09-2020].mp4*208903862*8/30/2020 7:58:35 PM",
    "Blanco color de amor [Cap-120] [03-09-2020].mp4*228232295*8/31/2020 11:04:22 PM",
    "Blanco color de amor [Cap-121] [03-09-2020].mp4*282342448*8/31/2020 11:04:31 PM",
    "Blanco color de amor [Cap-122] [03-09-2020].mp4*296346992*8/31/2020 11:05:03 PM",
    "Blanco color de amor [Cap-123] [03-09-2020].mp4*242622616*8/31/2020 11:05:07 PM",
    "Blanco color de amor [Cap-124] [03-09-2020].mp4*255992497*8/31/2020 11:05:23 PM",
    "Blanco color de amor [Cap-125] [03-09-2020].mp4*236033480*8/31/2020 11:05:38 PM",
    "Blanco color de amor [Cap-126] [03-09-2020].mp4*709216813*8/31/2020 11:06:20 PM",
    "Blanco color de amor [Cap-127] [03-09-2020].mp4*737643188*8/31/2020 11:06:58 PM",
    "Blanco color de amor [Cap-128] [03-09-2020].mp4*688509266*8/31/2020 11:07:03 PM",
    "Blanco color de amor [Cap-129] [03-09-2020].mp4*282938714*8/31/2020 11:06:47 PM",
    "Blanco color de amor [Cap-130] [03-09-2020].mp4*651544626*8/31/2020 11:06:57 PM",
    "Blanco color de amor [Cap-134] [08-09-2020].mp4*320997780*9/4/2020 6:47:28 PM",
    "Blanco color de amor [Cap-135] [08-09-2020].mp4*324472772*9/4/2020 7:13:35 PM",
    "Blanco color de amor [Cap-136] [08-09-2020].mp4*320746988*9/4/2020 6:58:42 PM",
    "Blanco color de amor [Cap-137] [08-09-2020].mp4*319048408*9/4/2020 7:00:20 PM",
    "Blanco color de amor [Cap-138] [08-09-2020].mp4*312942168*9/4/2020 7:01:50 PM",
    "Blanco color de amor [Cap-139] [08-09-2020].mp4*316488036*9/4/2020 7:03:20 PM",
    "Blanco color de amor [Cap-140] [08-09-2020].mp4*303571872*9/4/2020 7:04:49 PM",
    "Blanco color de amor [Cap-141] [08-09-2020].mp4*307537544*9/4/2020 7:06:53 PM",
    "Blanco color de amor [Cap-142] [08-09-2020].mp4*308322444*9/4/2020 7:08:25 PM",
    "Blanco color de amor [Cap-143] [08-09-2020].mp4*307944000*9/4/2020 7:09:55 PM",
    "Blanco color de amor [Cap-144] [08-09-2020].mp4*301421716*9/4/2020 7:31:17 PM",
    "Blanco color de amor [Cap-145] [08-09-2020].mp4*309784144*9/4/2020 6:52:38 PM",
    "Blanco color de amor [Cap-146] [08-09-2020].mp4*308744504*9/4/2020 7:14:31 PM",
    "Blanco color de amor [Cap-147] [08-09-2020].mp4*311268968*9/4/2020 6:52:27 PM",
    "Blanco color de amor [Cap-148] [08-09-2020].mp4*311364848*9/4/2020 6:51:28 PM",
    "Blanco color de amor [Cap-149] [08-09-2020].mp4*298213120*9/4/2020 6:51:07 PM",
    "Blanco color de amor [Cap-150] [08-09-2020].mp4*293897204*9/4/2020 6:50:20 PM",
    "Blanco color de amor [Cap-151] [08-09-2020].mp4*307920876*9/4/2020 7:16:02 PM",
    "Blanco color de amor [Cap-152] [08-09-2020].mp4*304261456*9/4/2020 6:50:07 PM",
    "Blanco color de amor [Cap-153] [08-09-2020].mp4*300122072*9/4/2020 6:49:14 PM",
    "Blanco color de amor [Cap-154] [08-09-2020].mp4*304972660*9/4/2020 6:49:03 PM",
    "Blanco color de amor [Cap-155] [08-09-2020].mp4*304957432*9/4/2020 6:47:35 PM",
    "Blanco color de amor [Cap-156] [08-09-2020].mp4*302358332*9/4/2020 7:16:05 PM",
    "Blanco color de amor [Cap-157] [08-09-2020].mp4*297281580*9/4/2020 6:47:26 PM",
    "Blanco color de amor [Cap-158] [08-09-2020].mp4*301220556*9/4/2020 7:14:32 PM",
    "Blanco color de amor [Cap-159] [08-09-2020].mp4*312400540*9/4/2020 7:13:31 PM",
    "Blanco color de amor [Cap-160] [08-09-2020].mp4*310615668*9/4/2020 7:12:20 PM",
    "Blanco Color De Amor 113 [No Disponible].txt*0*9/11/2020 9:04:03 AM",
    "Blanco, color de amor [Cap-132] [06-09-2020].mp4*627522909*9/2/2020 7:03:10 PM",
    "Blanco, color de amor [Cap-133] [06-09-2020].mp4*1163019319*9/3/2020 10:25:07 PM",
    25657277900,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Casate conmigo*0*9/13/2020 10:43:20 PM",
    "Casate conmigo [Cap-13] [07-09-2020].mp4*153236018*9/7/2020 12:11:33 AM",
    "Casate conmigo [Cap-14] [09-09-2020].mp4*153823488*9/8/2020 9:12:16 PM",
    "Casate conmigo [Cap-15] [10-09-2020].mp4*152806379*9/9/2020 7:44:04 PM",
    "Casate conmigo [Cap-16] [11-09-2020].mp4*581798243*9/10/2020 9:38:36 PM",
    "Casate conmigo [Cap-17] [12-09-2020].mp4*149357435*9/11/2020 7:40:16 PM",
    1191021563,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Cati kati ask amor en la azotea*0*9/13/2020 10:25:32 PM",
    "Catı Katı Ask 5.mp4*426653968*9/7/2020 5:21:46 PM",
    "Cati kati ask amor en la azotea [Cap-6] [08-09-2020].mp4*320439608*9/7/2020 5:12:37 PM",
    "Cati kati ask amor en la azotea [Cap-7] [11-09-2020].mp4*868044880*9/11/2020 1:57:03 AM",
    "Cati kati ask amor en la azotea [Cap-8] [11-09-2020].mp4*1058961136*9/11/2020 1:28:53 AM",
    2674099592,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Celebridad*0*9/13/2020 10:36:48 PM",
    "Celebridad [Cap-116] [07-09-2020].mp4*276768522*9/6/2020 7:14:04 PM",
    "Celebridad [Cap-117] [09-09-2020].mp4*289886852*9/8/2020 7:31:39 PM",
    "Celebridad [Cap-118] [10-09-2020].mp4*664224047*9/9/2020 8:31:06 PM",
    "Celebridad [Cap-119] [10-09-2020].mp4*149150119*9/9/2020 8:17:39 PM",
    "Celebridad [Cap-120] [10-09-2020].mp4*630930795*9/9/2020 8:44:24 PM",
    "Celebridad [Cap-121] [10-09-2020].mp4*41575827*9/9/2020 8:24:56 PM",
    "Celebridad [Cap-122] [10-09-2020].mp4*146282377*9/9/2020 8:29:03 PM",
    "Celebridad [Cap-123] [10-09-2020].mp4*679372803*9/9/2020 8:56:49 PM",
    "Celebridad [Cap-124] [10-09-2020].mp4*153777242*9/9/2020 8:35:43 PM",
    "Celebridad [Cap-125] [11-09-2020].mp4*164764472*9/10/2020 8:52:28 PM",
    3196733056,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Chepe Fortuna*0*9/13/2020 11:34:15 PM",
    "Chepe Fortuna Capitulo 131 .mp4*222119356*9/10/2020 11:56:11 PM",
    "Chepe Fortuna Capitulo 132 .mp4*213931555*9/11/2020 12:10:28 AM",
    "Chepe Fortuna Capitulo 133 .mp4*233243319*9/11/2020 12:26:08 AM",
    "Chepe Fortuna Capitulo 134 .mp4*226709587*9/11/2020 12:41:16 AM",
    "Chepe Fortuna Capitulo 135 .mp4*243155694*9/11/2020 12:57:29 AM",
    1139159511,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Choti sarrdaarni*0*9/13/2020 10:08:43 PM",
    "Choti sarrdaarni [Cap-175] [12-09-2020].mp4*102567510*9/11/2020 9:55:48 PM",
    "Choti sarrdaarni [Cap-176] [12-09-2020].mp4*371467107*9/11/2020 10:18:03 PM",
    474034617,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Corazon indomable [1080p]*0*9/13/2020 11:08:48 PM",
    "Corazon indomable [Cap-121] [10-09-2020].mp4*1558997279*5/10/2020 4:57:16 AM",
    "Corazon indomable [Cap-122] [11-09-2020].mp4*1644134721*5/10/2020 5:03:24 AM",
    3203132000,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Corazones cruzados*0*9/13/2020 11:26:37 PM",
    "Corazones cruzados [Cap-10] [08-09-2020].mp4*180634724*9/6/2020 10:11:29 PM",
    "Corazones cruzados [Cap-11] [08-09-2020].mp4*259260272*9/6/2020 10:11:01 PM",
    "Corazones cruzados [Cap-12] [08-09-2020].mp4*222837528*9/6/2020 10:10:27 PM",
    "Corazones cruzados [Cap-13] [08-09-2020].mp4*255093440*9/6/2020 10:09:37 PM",
    "Corazones cruzados [Cap-14] [08-09-2020].mp4*256311492*9/6/2020 10:08:41 PM",
    1174137456,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Corona de Lágrimas [720p]*0*9/13/2020 10:36:43 PM",
    "Corona de lagrimas [Cap-91] [07-09-2020].mp4*471571622*9/8/2020 11:43:30 PM",
    "Corona de lagrimas [Cap-92] [08-09-2020].mp4*449545668*9/10/2020 3:43:23 AM",
    "Corona de lagrimas [Cap-93] [09-09-2020].mp4*444884877*9/10/2020 9:35:19 PM",
    "Corona de lagrimas [Cap-94] [10-09-2020].mp4*1481429975*5/19/2020 9:26:25 PM",
    "Corona de lagrimas [Cap-95] [11-09-2020].mp4*1473470049*5/19/2020 9:35:31 PM",
    4320902191,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Correo de inocentes [720p]*0*9/13/2020 10:10:05 PM",
    "Correo de inocentes [720p] [Cap-2] [07-09-2020].mp4*392557225*9/7/2020 4:22:13 AM",
    "Correo de inocentes [Cap-1] [07-09-2020].mp4*402955554*9/5/2020 1:37:48 PM",
    "Correo de inocentes [Cap-3] [08-09-2020].mp4*378030369*9/7/2020 3:47:38 AM",
    "Correo de inocentes [Cap-4] [10-09-2020].mp4*386610031*9/7/2020 3:19:02 AM",
    1560153179,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Crash*0*9/13/2020 11:31:37 PM",
    "Crash [Cap-67] [07-09-2020].mp4*505706273*9/7/2020 1:55:25 AM",
    505706273,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\CUANDO ME ENAMORO*0*9/13/2020 11:10:38 PM",
    "79.mpg*440590892*2/17/2011 9:35:00 AM",
    "80.mpg*451887856*2/17/2011 9:35:12 AM",
    "81.mpg*461146672*2/17/2011 9:35:24 AM",
    "82.mpg*423925488*2/17/2011 9:35:36 AM",
    "83.mpg*432124560*2/17/2011 9:35:48 AM",
    "Cuando me enamoro [Cap-86] [10-09-2020].mp4*762662945*5/22/2020 1:32:24 AM",
    "Cuando me enamoro [Cap-87] [11-09-2020].mp4*749111523*5/22/2020 1:35:13 AM",
    3721449936,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Dame Chocolate*0*9/13/2020 10:29:22 PM",
    "Dame Chocolate - 58.mp4*168539409*9/2/2020 11:04:33 PM",
    "Dame Chocolate - 59.mp4*185582712*9/2/2020 10:54:48 PM",
    354122121,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Dos hogares [1080p]*0*9/13/2020 10:20:56 PM",
    "Dos hogares [Cap-100] [11-09-2020].mp4*1041605474*7/3/2020 5:40:44 AM",
    "Dos hogares [Cap-96] [07-09-2020].mp4*442870601*9/8/2020 11:23:07 PM",
    "Dos hogares [Cap-97] [08-09-2020].mp4*447050177*9/10/2020 3:42:59 AM",
    "Dos hogares [Cap-98] [09-09-2020].mp4*438204744*9/10/2020 9:16:33 PM",
    "Dos hogares [Cap-99] [10-09-2020].mp4*1061735739*7/3/2020 5:36:38 AM",
    3431466735,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Dulce ambicion*0*9/13/2020 9:22:28 PM",
    "Dulce ambicion [Cap-10] [12-09-2020].mp4*636009816*9/12/2020 12:15:26 AM",
    "Dulce ambicion [Cap-6] [08-09-2020].mp4*636055625*9/8/2020 12:35:05 AM",
    "Dulce ambicion [Cap-7] [10-09-2020].mp4*0*9/11/2020 12:17:48 PM",
    "Dulce ambicion [Cap-8] [10-09-2020].mp4*634815951*9/10/2020 12:58:00 AM",
    "Dulce ambicion [Cap-9] [11-09-2020].mp4*622297512*9/11/2020 2:05:41 AM",
    2529178904,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\El fantasma de Elena*0*9/13/2020 11:20:45 PM",
    "El Fantasma De Elena - 79.mp4*615860176*9/3/2020 12:21:41 AM",
    "El Fantasma De Elena - 80.mp4*642066248*9/4/2020 9:53:04 PM",
    "El Fantasma De Elena - 81.mp4*592897104*9/4/2020 9:55:30 PM",
    "El Fantasma De Elena - 82.mp4*607161040*9/4/2020 9:58:06 PM",
    2457984568,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\El ultimo matrimonio feliz*0*9/13/2020 11:25:27 PM",
    "El ultimo matrimonio feliz [Cap-141] [07-09-2020].mp4*462980765*9/8/2020 11:24:25 PM",
    "El ultimo matrimonio feliz [Cap-142] [08-09-2020].mp4*446564424*9/10/2020 3:48:58 AM",
    "El ultimo matrimonio feliz [Cap-143] [09-09-2020].mp4*426539728*9/10/2020 9:12:27 PM",
    "El ultimo matrimonio feliz [Cap-144] [10-09-2020].mp4*1117377474*5/31/2020 6:16:13 PM",
    "El ultimo matrimonio feliz [Cap-145] [11-09-2020].mp4*588784087*5/31/2020 5:54:04 PM",
    3042246478,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\El zorro la espada y la rosa [1080p]*0*9/13/2020 11:16:54 PM",
    "El zorro la espada y la rosa [Cap-28] [10-09-2020].mp4*717928800*8/21/2020 5:07:49 AM",
    "El zorro la espada y la rosa [Cap-29] [11-09-2020].mp4*717357810*8/21/2020 5:09:56 AM",
    1435286610,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Emperatriz ki*0*9/13/2020 9:23:19 PM",
    "Emperatriz ki [Cap-60] [06-09-2020].mp4*195033138*9/3/2020 10:21:11 PM",
    "Emperatriz ki [Cap-61] [06-09-2020].mp4*196872696*9/3/2020 10:21:23 PM",
    "Emperatriz ki [Cap-62] [06-09-2020].mp4*200758850*9/3/2020 10:23:07 PM",
    "Emperatriz ki [Cap-63] [08-09-2020].mp4*176385672*9/4/2020 7:33:57 PM",
    "Emperatriz ki [Cap-64] [08-09-2020].mp4*194810068*9/4/2020 7:37:49 PM",
    "Emperatriz ki [Cap-65] [08-09-2020].mp4*197928836*9/4/2020 7:35:41 PM",
    1161789260,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\En todas partes tu*0*9/13/2020 10:29:31 PM",
    "En todas partes tu [Cap-10] [12-09-2020].mp4*677245683*9/11/2020 8:13:03 PM",
    "En todas partes tu [Cap-6] [08-09-2020].mp4*166418315*9/7/2020 8:40:42 PM",
    "En todas partes tu [Cap-7] [09-09-2020].mp4*159721437*9/8/2020 9:18:31 PM",
    "En todas partes tu [Cap-8] [10-09-2020].mp4*669754711*9/9/2020 8:07:15 PM",
    "En todas partes tu [Cap-9] [11-09-2020].mp4*157666776*9/10/2020 8:51:27 PM",
    1830806922,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Enamorandome de ramon*0*9/13/2020 10:21:08 PM",
    "Enamorandome de ramon [Cap-74] [10-09-2020].mp4*1097100002*7/2/2020 9:30:14 PM",
    "Enamorandome de ramon [Cap-75] [11-09-2020].mp4*1072591945*7/2/2020 9:36:59 PM",
    2169691947,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Enemigo intimo 2*0*9/13/2020 10:21:20 PM",
    "Enemigo intimo 2 [Cap-50] [08-09-2020].mp4*530357155*9/8/2020 12:17:26 AM",
    "Enemigo intimo 2 [Cap-52] [10-09-2020].mp4*1248636026*9/10/2020 12:40:11 AM",
    "Enemigo intimo 2 [Cap-53] [11-09-2020].mp4*136177406*9/11/2020 1:23:48 AM",
    "Enemigo intimo 2 [Cap-54] [12-09-2020].mp4*586457888*9/12/2020 12:27:27 AM",
    2501628475,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Eres mi tesoro*0*9/13/2020 10:29:33 PM",
    "Eres mi tesoro [Cap-164] [06-09-2020].mp4*152536808*9/3/2020 9:43:58 PM",
    "Eres mi tesoro [Cap-165] [06-09-2020].mp4*144001044*9/3/2020 9:43:12 PM",
    "Eres mi tesoro [Cap-166] [06-09-2020].mp4*22206372*9/3/2020 9:41:51 PM",
    "Eres mi tesoro [Cap-167] [08-09-2020].mp4*138763928*9/4/2020 7:26:13 PM",
    "Eres mi tesoro [Cap-168] [08-09-2020].mp4*159600344*9/7/2020 11:42:53 PM",
    617108496,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Esta historia me suena 3*0*9/13/2020 11:34:09 PM",
    "Esta historia me suena 3 [Cap-17] [08-09-2020].mp4*353800551*9/4/2020 5:35:47 PM",
    "Esta historia me suena 3 [Cap-18] [08-09-2020].mp4*353012425*9/4/2020 5:30:43 PM",
    "Esta historia me suena 3 [Cap-19] [08-09-2020].mp4*342482870*9/4/2020 5:27:21 PM",
    "Esta historia me suena 3 [Cap-20] [08-09-2020].mp4*353130142*9/4/2020 5:21:31 PM",
    "Esta historia me suena 3 [Cap-21] [08-09-2020].mp4*355248630*9/4/2020 5:12:18 PM",
    "Esta historia me suena 3 [Cap-22] [08-09-2020].mp4*353735873*9/5/2020 7:41:33 PM",
    "Esta historia me suena 3 [Cap-23] [08-09-2020].mp4*130303698*9/6/2020 3:55:57 PM",
    "Esta historia me suena 3 [Cap-27] [12-09-2020].mp4*126096807*9/11/2020 7:39:33 PM",
    "Esta historia me suena vol 3 [Cap-25] [11-09-2020].mp4*137411871*9/10/2020 8:46:26 PM",
    "Esta historia me suena vol 3 [Cap-26] [11-09-2020].mp4*121871148*9/10/2020 8:46:16 PM",
    2627094015,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Eva Luna [720p]*0*9/13/2020 9:15:36 PM",
    "Eva luna [Cap-27] [10-09-2020].mp4*1707342220*8/8/2020 3:59:08 AM",
    "Eva luna [Cap-28] [11-09-2020].mp4*2057752601*8/8/2020 4:05:38 AM",
    3765094821,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Fugitiva*0*9/13/2020 11:10:16 PM",
    "Fugitiva (sen anlat karadeniz) [Cap-21] [12-09-2020].ts*1751135400*9/11/2020 9:27:25 PM",
    "Fugitiva [sen anlat karadeniz] 2 [Cap-19] [07-09-2020].ts*362535064*9/5/2020 1:57:26 PM",
    2113670464,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Fugitiva (sen anlat karadeniz)*0*9/13/2020 9:15:06 PM",
    "Fugitiva (sen anlat karadeniz) [Cap-21] [12-09-2020].ts*1751135400*9/11/2020 9:27:25 PM",
    1751135400,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Gathbandhan*0*9/13/2020 10:38:32 PM",
    "Gathbandhan [Cap-128] [08-09-2020].mp4*187376217*9/7/2020 9:58:44 PM",
    "Gathbandhan [Cap-129] [08-09-2020].mp4*198025490*9/7/2020 10:00:02 PM",
    "Gathbandhan [Cap-130] [10-09-2020].mp4*433707487*9/9/2020 11:55:58 PM",
    "Gathbandhan [Cap-131] [10-09-2020].mp4*226085676*9/9/2020 11:44:30 PM",
    "Gathbandhan [Cap-132] [12-09-2020].mp4*197608726*9/11/2020 10:51:23 PM",
    "Gathbandhan [Cap-133] [12-09-2020].mp4*201727542*9/11/2020 10:57:12 PM",
    1444531138,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Geet hui sabse parayi*0*9/13/2020 9:14:54 PM",
    "Geet hui sabse parayi [Cap-192] [08-09-2020].mp4*212002967*9/7/2020 10:06:21 PM",
    "Geet hui sabse parayi [Cap-193] [08-09-2020].mp4*215636068*9/7/2020 10:09:40 PM",
    "Geet hui sabse parayi [Cap-194] [12-09-2020].mp4*221362334*9/12/2020 2:38:08 AM",
    649001369,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Guddan tumse na ho payega*0*9/13/2020 9:14:50 PM",
    "Guddan tumse na ho payega [Cap-158] [08-09-2020].mp4*79053094*9/7/2020 11:38:20 AM",
    "Guddan tumse na ho payega [Cap-159] [08-09-2020].mp4*79053094*9/7/2020 9:52:55 PM",
    "Guddan tumse na ho payega [Cap-160] [11-09-2020].mp4*241378731*9/10/2020 10:39:24 PM",
    "Guddan tumse na ho payega [Cap-161] [11-09-2020].mp4*182440840*9/10/2020 10:53:29 PM",
    "Guddan tumse na ho payega [Cap-162] [12-09-2020].mp4*74419082*9/11/2020 10:00:40 PM",
    "Guddan tumse na ho payega [Cap-163] [12-09-2020].mp4*81416017*9/11/2020 10:01:04 PM",
    "Guddan tumse na ho payega [Cap-164] [12-09-2020].mp4*74369261*9/11/2020 10:06:02 PM",
    812130119,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Humsafars*0*9/13/2020 11:10:09 PM",
    "Humsafars [Cap-100] [12-09-2020].mp4*107894014*9/11/2020 11:06:22 PM",
    "Humsafars [Cap-91] [09-09-2020].mp4*195934422*9/8/2020 11:00:30 PM",
    "Humsafars [Cap-92] [09-09-2020].mp4*191352939*9/8/2020 10:56:51 PM",
    "Humsafars [Cap-93] [09-09-2020].mp4*165878408*9/8/2020 11:05:34 PM",
    "Humsafars [Cap-94] [11-09-2020].mp4*169997526*9/10/2020 10:22:19 PM",
    "Humsafars [Cap-95] [11-09-2020].mp4*165521984*9/10/2020 10:26:55 PM",
    "Humsafars [Cap-96] [11-09-2020].mp4*165407284*9/10/2020 10:29:45 PM",
    "Humsafars [Cap-97] [12-09-2020].mp4*165400399*9/11/2020 10:30:43 PM",
    "Humsafars [Cap-98] [12-09-2020].mp4*166549362*9/11/2020 10:49:22 PM",
    "Humsafars [Cap-99] [12-09-2020].mp4*165715205*9/11/2020 11:00:31 PM",
    "Humsafars- 1×86.mp4*172014966*9/9/2020 11:52:57 AM",
    "Humsafars- 1×87.mp4*171617146*9/9/2020 12:23:59 PM",
    "Humsafars- 1×88.mp4*170317281*9/9/2020 12:45:33 PM",
    "Humsafars- 1×89.mp4*191721514*9/9/2020 1:55:21 PM",
    "Humsafars- 1×90.mp4*193267406*9/9/2020 1:11:20 PM",
    2558589856,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Insensato corazon*0*9/13/2020 11:16:35 PM",
    "Insensato corazon [Cap-100] [08-09-2020].mp4*527696755*9/7/2020 8:11:41 PM",
    "Insensato corazon [Cap-101] [09-09-2020].mp4*677691751*9/8/2020 9:52:20 PM",
    "Insensato corazon [Cap-102] [10-09-2020].mp4*182895065*9/9/2020 7:31:43 PM",
    "Insensato corazon [Cap-103] [10-09-2020].mp4*620549556*9/10/2020 2:30:08 AM",
    "Insensato corazon [Cap-104] [11-09-2020].mp4*685466013*9/11/2020 2:19:10 AM",
    2694299140,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Isla de hierro*0*9/13/2020 9:13:46 PM",
    "Isla de hierro [Cap-6] [08-09-2020].mp4*474667142*9/7/2020 7:18:10 PM",
    474667142,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Iss pyaar ko kya naam doon*0*9/13/2020 9:13:45 PM",
    "Iss pyaar ko kya naam doon [Cap-207] [09-09-2020].mp4*114043496*9/8/2020 11:05:16 PM",
    "Iss pyaar ko kya naam doon [Cap-208] [09-09-2020].mp4*106919716*9/8/2020 11:08:02 PM",
    "Iss pyaar ko kya naam doon [Cap-209] [11-09-2020].mp4*108617990*9/10/2020 10:51:48 PM",
    "Iss pyaar ko kya naam doon [Cap-210] [11-09-2020].mp4*102633187*9/10/2020 10:54:07 PM",
    432214389,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Jenny Rivera Mariposa de Barrio [1080p]*0*9/13/2020 10:28:45 PM",
    "Jenny rivera mariposa de barrio [Cap-83] [07-09-2020].mp4*426048011*9/8/2020 11:21:56 PM",
    "Jenny rivera mariposa de barrio [Cap-84] [08-09-2020].mp4*426664515*9/10/2020 3:48:14 AM",
    "Jenny rivera mariposa de barrio [Cap-85] [09-09-2020].mp4*426184803*9/10/2020 10:23:43 PM",
    "Jenny rivera mariposa de barrio [Cap-86] [10-09-2020].mp4*1319102065*4/9/2020 6:11:50 AM",
    "Jenny rivera mariposa de barrio [Cap-87] [11-09-2020].mp4*1313276804*4/9/2020 6:18:01 AM",
    3911276198,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Jodha akbar romance real*0*9/13/2020 11:16:41 PM",
    "Jodha akbar romance real [Cap-127] [08-09-2020].mp4*612348897*9/7/2020 11:45:32 PM",
    "Jodha akbar romance real [Cap-128] [08-09-2020].mp4*647170378*9/7/2020 11:32:03 PM",
    1259519275,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\La guerrera*0*9/13/2020 11:05:12 PM",
    "La guerrera [Cap-66] [07-09-2020].mp4*454094534*9/8/2020 11:28:30 PM",
    "La guerrera [Cap-67] [08-09-2020].mp4*458000754*9/10/2020 3:52:35 AM",
    "La guerrera [Cap-68] [09-09-2020].mp4*468680683*9/10/2020 10:27:04 PM",
    "La guerrera [Cap-69] [10-09-2020].mp4*868318738*8/16/2020 12:48:58 AM",
    "La guerrera [Cap-70] [11-09-2020].mp4*775410309*8/16/2020 12:52:43 AM",
    3024505018,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\La Hija del Mariachi [720p]*0*9/13/2020 8:51:12 PM",
    "La hija del mariachi [Cap-33] [10-09-2020].mp4*607613647*8/26/2020 6:35:06 AM",
    "La hija del mariachi [Cap-34] [11-09-2020].mp4*619681357*8/26/2020 6:37:42 AM",
    1227295004,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\La mexicana y el guero*0*9/13/2020 8:51:21 PM",
    "La mexicana y el guero [Cap-15] [07-09-2020].mp4*144805322*9/7/2020 12:02:56 AM",
    "La mexicana y el guero [Cap-16] [09-09-2020].mp4*159614315*9/8/2020 8:52:33 PM",
    "La mexicana y el guero [Cap-17] [10-09-2020].mp4*160978154*9/9/2020 7:32:34 PM",
    "La mexicana y el guero [Cap-18] [11-09-2020].mp4*159908643*9/10/2020 8:46:00 PM",
    "La mexicana y el guero [Cap-19] [12-09-2020].mp4*151888380*9/11/2020 7:37:36 PM",
    777194814,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\La otra orilla*0*9/13/2020 11:05:30 PM",
    "La otra orilla [Cap-26] [08-09-2020].mp4*393037641*9/8/2020 12:08:37 AM",
    "La otra orilla [Cap-27] [09-09-2020].mp4*697153987*9/8/2020 11:54:15 PM",
    "La otra orilla [Cap-28] [10-09-2020].mp4*221827582*9/9/2020 11:38:08 PM",
    "La otra orilla [Cap-29] [11-09-2020].mp4*465076431*9/11/2020 12:31:39 AM",
    "La otra orilla [Cap-30] [12-09-2020].mp4*373087672*9/11/2020 11:40:32 PM",
    2150183313,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\La que no podia amar [1080p]*0*9/13/2020 10:18:19 PM",
    "La que no podia amar [Cap-69] [10-09-2020].mp4*1507299296*7/2/2020 10:54:07 PM",
    "La que no podia amar [Cap-70] [11-09-2020].mp4*1324229885*7/2/2020 10:59:00 PM",
    2831529181,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\La sombra del pasado [1080p]*0*9/13/2020 8:51:55 PM",
    "La sombra del pasado [Cap-39] [10-09-2020].mp4*1287445767*8/14/2020 6:32:32 AM",
    "La sombra del pasado [Cap-40] [11-09-2020].mp4*1408925339*8/14/2020 6:33:21 AM",
    2696371106,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\La viuda de la mafia*0*9/13/2020 8:52:06 PM",
    "La viuda de la mafia [Cap-106] [10-09-2020].mp4*135593900*5/16/2020 12:43:58 AM",
    "La viuda de la mafia [Cap-107] [11-09-2020].mp4*141165171*5/16/2020 12:31:06 AM",
    276759071,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Laberintos del corazon [720p]*0*9/13/2020 8:52:24 PM",
    "Laberintos del corazon [Cap-49] [07-09-2020].mp4*509206875*9/8/2020 11:53:25 PM",
    "Laberintos del corazon [Cap-50] [08-09-2020].mp4*462563839*9/10/2020 3:53:47 AM",
    "Laberintos del corazon [Cap-51] [09-09-2020].mp4*467720382*9/10/2020 10:24:39 PM",
    "Laberintos del corazon [Cap-52] [10-09-2020].mp4*870094682*8/17/2020 2:01:06 AM",
    "Laberintos del corazon [Cap-53] [11-09-2020].mp4*876050150*8/17/2020 2:02:36 AM",
    3185635928,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Lado a lado [720p]*0*9/13/2020 11:06:22 PM",
    "Lado a lado [Cap-60] [04-09-2020].mp4*434117418*9/5/2020 2:25:44 PM",
    "Lado a lado [Cap-61] [07-09-2020].mp4*409150785*9/9/2020 12:10:41 AM",
    "Lado a lado [Cap-62] [08-09-2020].mp4*429460294*9/10/2020 3:57:28 AM",
    "Lado a lado [Cap-63] [09-09-2020].mp4*433024789*9/10/2020 10:27:25 PM",
    "Lado a lado [Cap-64] [10-09-2020].mkv*511532100*7/31/2020 5:36:51 AM",
    "Lado a lado [Cap-65] [11-09-2020].mkv*501506845*7/31/2020 5:36:39 AM",
    2718792231,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Las brasileñas*0*9/13/2020 8:52:47 PM",
    "Las brasileñas [Cap-15] [10-09-2020].mp4*344506497*9/10/2020 2:36:28 AM",
    344506497,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Las reglas para mi familia*0*9/13/2020 8:52:57 PM",
    "Las reglas para mi familia [Cap-3] [10-09-2020].mp4*620256806*9/9/2020 10:44:54 PM",
    "Las reglas, para mi familia [Cap-2] [07-09-2020].mp4*356137583*9/5/2020 1:43:37 PM",
    "Las reglas, para mi familia [Cap-4] [12-09-2020].mp4*308509653*9/11/2020 11:45:28 PM",
    1284904042,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Las tontas no van al cielo [1080p]*0*9/13/2020 11:32:16 PM",
    "Las tontas no van al cielo [Cap-88] [07-09-2020].mp4*442765886*9/9/2020 12:44:44 AM",
    "Las tontas no van al cielo [Cap-89] [08-09-2020].mp4*460218073*9/10/2020 2:09:45 AM",
    "Las tontas no van al cielo [Cap-90] [09-09-2020].mp4*441767749*9/10/2020 10:34:07 PM",
    "Las tontas no van al cielo [Cap-91] [10-09-2020].mp4*1340931798*5/12/2020 5:27:15 AM",
    "Las tontas no van al cielo [Cap-92] [11-09-2020].mp4*1270200298*5/12/2020 5:32:09 AM",
    3955883804,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Llena de amor*0*9/13/2020 8:53:30 PM",
    "Llena de amor [Cap-104] [07-09-2020].mp4*411681770*9/9/2020 12:35:25 AM",
    "Llena de amor [Cap-105] [08-09-2020].mp4*411699770*9/10/2020 2:02:24 AM",
    "Llena de amor [Cap-106] [09-09-2020].mp4*413419755*9/10/2020 10:41:33 PM",
    "Llena de amor [Cap-107] [10-09-2020].mp4*528007890*4/21/2020 1:33:56 AM",
    "Llena de amor [Cap-108] [11-09-2020].mp4*537783731*4/21/2020 1:37:25 AM",
    2302592916,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Los herederos del monte*0*9/13/2020 8:53:45 PM",
    "Los herederos del monte [Cap-26] [06-09-2020].mp4*592598184*9/3/2020 12:18:11 AM",
    "Los herederos del monte [Cap-27] [08-09-2020].mp4*577338600*9/4/2020 10:00:28 PM",
    "Los herederos del monte [Cap-28] [08-09-2020].mp4*225696820*9/4/2020 10:02:04 PM",
    "Los herederos del monte [Cap-29] [08-09-2020].mp4*521272*9/4/2020 10:02:10 PM",
    1396154876,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Los increibles 90*0*9/13/2020 8:54:00 PM",
    "Los Increibles 90 - 80.mp4*680957230*9/9/2020 6:01:32 AM",
    "Los increibles 90 [Cap-81] [10-09-2020].mp4*681650390*9/9/2020 11:08:24 PM",
    "Los increibles 90 [Cap-82] [11-09-2020].mp4*686059110*9/11/2020 1:35:45 AM",
    "Los increibles 90 [Cap-83] [12-09-2020].mp4*678746747*9/12/2020 12:02:56 AM",
    2727413477,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Los miserables [1080p]*0*9/13/2020 11:18:03 PM",
    "Los miserables [Cap-40] [07-09-2020].mp4*478499986*9/9/2020 1:02:38 AM",
    "Los miserables [Cap-41] [08-09-2020].mp4*425068144*9/10/2020 2:30:57 AM",
    "Los miserables [Cap-42] [09-09-2020].mp4*519381460*9/10/2020 11:09:21 PM",
    "Los miserables [Cap-43] [10-09-2020].mp4*1367224398*8/14/2020 8:46:14 AM",
    "Los miserables [Cap-44] [11-09-2020].mp4*1365202738*8/14/2020 8:51:21 AM",
    4155376726,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Los unicos 2*0*9/13/2020 11:18:21 PM",
    "Los unicos 2 [Cap-42].mp4*162926846*5/25/2017 8:53:34 PM",
    "Los unicos 2 [Cap-43].mp4*173780748*5/25/2017 8:53:39 PM",
    "Los unicos 2 [Cap-44].mp4*142100874*5/25/2017 12:35:34 AM",
    "Los unicos 2 [Cap-45].mp4*174693648*5/22/2017 12:02:06 AM",
    "Los unicos 2 [Cap-46].mp4*251051154*5/22/2017 12:03:19 AM",
    "Los unicos 2 [Cap-47].mp4*171965682*5/22/2017 12:02:57 AM",
    "Los unicos 2 [Cap-48].mp4*165892618*5/22/2017 12:03:08 AM",
    1242411570,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Luz maria [720p]*0*9/13/2020 11:29:01 PM",
    "Luz maria [Cap-65] [10-09-2020].mp4*1466365261*8/25/2020 12:45:39 PM",
    "Luz maria [Cap-66] [11-09-2020].mp4*1472948762*8/25/2020 12:46:00 PM",
    2939314023,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Mañana es para siempre [1080p]*0*9/13/2020 11:18:41 PM",
    "Mañana es para siempre [Cap-53] [10-09-2020].mp4*922915089*7/3/2020 3:03:08 AM",
    "Mañana es para siempre [Cap-54] [11-09-2020].mp4*919111730*7/3/2020 3:04:20 AM",
    1842026819,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Mi amor el wachiman 2*0*9/13/2020 9:42:31 PM",
    "Mi amor el wachiman 2 [Cap-16] [08-09-2020].mp4*541256434*9/7/2020 8:53:06 PM",
    "Mi amor el wachiman 2 [Cap-17] [09-09-2020].mp4*526411303*9/8/2020 8:38:34 PM",
    "Mi amor el wachiman 2 [Cap-18] [10-09-2020].mp4*549482330*9/9/2020 9:06:48 PM",
    "Mi amor el wachiman 2da [Cap-19] [11-09-2020].mp4*533294672*9/10/2020 11:49:57 PM",
    "Mi amor el wachiman 2da [Cap-20] [12-09-2020].mp4*535576553*9/11/2020 11:16:27 PM",
    2686021292,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Misterio*0*9/13/2020 9:42:42 PM",
    "Misterio [Cap-14] [07-09-2020].mp4*468475948*9/7/2020 12:28:30 AM",
    468475948,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Muchacha italiana viene a casarse [1080p]*0*9/13/2020 11:29:57 PM",
    "Muchacha italiana viene a casarse [Cap-36] [07-09-2020].mp4*404169438*9/9/2020 12:56:45 AM",
    "Muchacha italiana viene a casarse [Cap-37] [08-09-2020].mp4*414469363*9/10/2020 2:17:59 AM",
    "Muchacha italiana viene a casarse [Cap-38] [09-09-2020].mp4*416794211*9/10/2020 11:04:30 PM",
    "Muchacha italiana viene a casarse [Cap-39] [10-09-2020].mp4*1045355434*8/26/2020 12:48:13 AM",
    "Muchacha italiana viene a casarse [Cap-40] [11-09-2020].mp4*1009682921*8/26/2020 12:46:31 AM",
    3290471367,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Mujer [kadin] [doblado al español]*0*9/13/2020 9:43:38 PM",
    "Mujer [kadin] [doblado al español] [Cap-17] [08-09-2020].ts*358500960*9/6/2020 3:18:56 PM",
    "Mujer [kadin] [doblado al español] [Cap-18] [08-09-2020].ts*373067576*9/6/2020 3:28:17 PM",
    "Mujer [kadin] [doblado al español] [Cap-19] [09-09-2020].mkv*2012323930*9/9/2020 12:55:43 PM",
    2743892466,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Niño [cocuk]*0*9/13/2020 11:24:35 PM",
    "Niño [cocuk] [Cap-51] [07-09-2020].mp4*164181980*9/7/2020 2:39:16 AM",
    "Niño [cocuk] [Cap-52] [07-09-2020].mp4*145385610*9/7/2020 3:31:40 AM",
    "Niño [cocuk] [Cap-53] [07-09-2020].mp4*299409505*9/7/2020 4:15:11 AM",
    608977095,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Omer suenos robados*0*9/13/2020 10:32:16 PM",
    "Omer suenos robados [Cap-146] [11-09-2020].mp4*162031573*9/10/2020 8:00:28 PM",
    "Omer suenos robados [Cap-147] [11-09-2020].mp4*152132237*9/10/2020 8:11:29 PM",
    314163810,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Palabra de mujer*0*9/13/2020 9:44:08 PM",
    "Palabra de mujer [Cap-15] [06-09-2020].mp4*376230273*9/2/2020 7:25:42 PM",
    "Palabra de mujer [Cap-16] [06-09-2020].mp4*855058437*9/2/2020 7:16:22 PM",
    1231288710,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Partes de mi*0*9/13/2020 11:24:40 PM",
    "Partes de mi [Cap-24] [08-09-2020].mp4*711766872*9/6/2020 10:42:39 PM",
    711766872,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Pecado mortal*0*9/13/2020 10:32:19 PM",
    "Pecado mortal [Cap-137] [08-09-2020].mp4*781962783*9/8/2020 1:12:24 AM",
    "Pecado mortal [Cap-138] [09-09-2020].mp4*797228009*9/9/2020 12:46:04 AM",
    "Pecado mortal [Cap-139] [10-09-2020].mp4*581674895*9/10/2020 1:09:09 AM",
    "Pecado mortal [Cap-140] [11-09-2020].mp4*599241020*9/11/2020 1:47:09 AM",
    2760106707,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Por amor*0*9/13/2020 11:29:23 PM",
    "Por amor [Cap-63] [07-09-2020].mp4*424319732*1/10/2019 10:36:51 PM",
    "Por amor [Cap-64] [08-09-2020].mp4*524063678*9/10/2020 2:40:24 AM",
    "Por amor [Cap-65] [09-09-2020].mp4*418409751*1/11/2019 5:24:26 AM",
    "Por amor [Cap-66] [10-09-2020].mp4*428256040*1/13/2019 12:12:08 AM",
    "Por amor [Cap-67] [11-09-2020].mp4*415997319*1/13/2019 12:31:47 AM",
    2211046520,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Punar vivah zindagi milegi dobara*0*9/13/2020 9:45:16 PM",
    "Punar vivah zindagi milegi [Cap-67] [12-09-2020].mp4*63176134*9/11/2020 10:43:09 PM",
    "Punar vivah zindagi milegi [Cap-68] [12-09-2020].mp4*65555405*9/11/2020 10:44:38 PM",
    "Punar vivah zindagi milegi dobara [Cap-65] [08-09-2020].mp4*65261584*9/7/2020 11:08:36 PM",
    "Punar vivah zindagi milegi dobara [Cap-66] [08-09-2020].mp4*62672297*9/7/2020 11:09:40 PM",
    256665420,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Que vida buena*0*9/13/2020 10:32:28 PM",
    "Que vida buena [Cap-124] [11-09-2020].mp4*136634616*9/10/2020 9:11:48 PM",
    "Que vida buena [Cap-125] [12-09-2020].mp4*96296356*9/11/2020 7:45:13 PM",
    232930972,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Quiero amarte [720p]*0*9/13/2020 10:23:00 PM",
    "Quiero amarte [Cap-36] [07-09-2020].mp4*448483128*9/9/2020 1:09:35 AM",
    "Quiero amarte [Cap-37] [08-09-2020].mp4*424761526*9/10/2020 2:28:28 AM",
    "Quiero amarte [Cap-38] [09-09-2020].mp4*451760975*9/10/2020 11:05:56 PM",
    "Quiero amarte [Cap-39] [10-09-2020].mp4*1014526116*8/14/2020 7:30:41 AM",
    "Quiero amarte [Cap-40] [11-09-2020].mp4*942758917*8/14/2020 7:31:10 AM",
    3282290662,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Sacrificio de amor*0*9/13/2020 10:49:21 PM",
    "Sacrificio de amor [Cap-13] [07-09-2020].mp4*399855251*9/6/2020 7:39:16 PM",
    "Sacrificio de amor [Cap-14] [09-09-2020].mp4*452569084*9/8/2020 8:07:32 PM",
    "Sacrificio de amor [Cap-15] [11-09-2020].mp4*430137271*9/10/2020 8:16:23 PM",
    "Sacrificio de amor [Cap-16] [11-09-2020].mp4*439147841*9/10/2020 8:29:45 PM",
    1721709447,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Soz (La Promesa)*0*9/13/2020 8:28:11 PM",
    "Soz (La Promesa) - 1.mp4*505280951*9/3/2020 11:54:01 PM",
    "Soz (La Promesa) - 10.mp4*356267331*9/2/2020 2:01:17 PM",
    "Soz (La Promesa) - 2.mp4*552627971*9/3/2020 11:54:58 PM",
    "Soz (La Promesa) - 3.mp4*609653904*9/3/2020 11:55:28 PM",
    "Soz (La Promesa) - 4.mp4*386352568*9/3/2020 11:52:48 PM",
    "Soz (La Promesa) - 5.mp4*859546562*9/3/2020 11:56:01 PM",
    "Soz (La Promesa) - 6.mp4*725775952*9/3/2020 11:57:47 PM",
    "Soz (La Promesa) - 7.mp4*357203870*9/2/2020 1:42:21 PM",
    "Soz (La Promesa) - 8.mp4*347152175*9/2/2020 1:52:45 PM",
    "Soz (La Promesa) - 9.mp4*489458747*9/2/2020 2:05:18 PM",
    5189320031,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Te volvere a encontrar*0*9/13/2020 8:28:32 PM",
    "Te volvere a encontrar [Cap-101] [08-09-2020].mp4*512664110*9/8/2020 12:54:00 AM",
    "Te volvere a encontrar [Cap-102] [09-09-2020].mp4*283052274*9/9/2020 12:13:57 AM",
    "Te volvere a encontrar [Cap-103] [10-09-2020].mp4*1004609817*9/10/2020 12:16:23 AM",
    "Te volvere a encontrar [Cap-104] [11-09-2020].mp4*642330344*9/11/2020 12:46:00 AM",
    "Te volvere a encontrar [Cap-105] [12-09-2020].mp4*634564481*9/12/2020 12:40:30 AM",
    3077221026,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Tierra amarga*0*9/13/2020 10:53:07 PM",
    "Tierra amarga [Cap-154] [08-09-2020].mp4*131666228*9/7/2020 8:43:11 PM",
    "Tierra amarga [Cap-155] [08-09-2020].mp4*125399433*9/9/2020 5:01:34 AM",
    "Tierra amarga [Cap-156] [10-09-2020].mp4*560072984*9/9/2020 8:12:14 PM",
    "Tierra amarga [Cap-157] [11-09-2020].mp4*112748592*9/10/2020 9:10:39 PM",
    "Tierra amarga [Cap-158] [12-09-2020].mp4*82579429*9/11/2020 7:45:04 PM",
    1012466666,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Verano en venecia*0*9/13/2020 8:29:01 PM",
    "Verano en venecia [Cap-1] [07-09-2020].mp4*813983988*9/5/2020 1:21:31 PM",
    "Verano en venecia [Cap-2] [07-09-2020].mp4*525275045*9/5/2020 1:31:27 PM",
    "Verano en venecia [Cap-3] [07-09-2020].mp4*611588907*9/7/2020 1:55:50 AM",
    "Verano en venecia [Cap-4] [08-09-2020].mp4*630208786*9/7/2020 1:53:48 AM",
    "Verano en venecia [Cap-5] [10-09-2020].mp4*487610418*9/10/2020 11:07:18 PM",
    3068667144,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Verdad oculta*0*9/13/2020 8:29:25 PM",
    "Verdad oculta [Cap-47] [09-09-2020].mp4*143981671*9/8/2020 9:25:27 PM",
    "Verdad oculta [Cap-48] [09-09-2020].mp4*142776618*9/9/2020 2:52:27 AM",
    "Verdad oculta [Cap-49] [11-09-2020].mp4*599696378*9/10/2020 9:56:36 PM",
    "Verdad oculta [Cap-50] [11-09-2020].mp4*488306034*9/11/2020 1:54:23 AM",
    "Verdad oculta [Cap-51] [12-09-2020].mp4*1035844195*9/12/2020 2:20:26 AM",
    2410604896,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Vino el Amor [720p]*0*9/13/2020 8:29:56 PM",
    "Vino el amor [Cap-100] [11-09-2020].mp4*1166011069*7/3/2020 2:17:25 AM",
    "Vino el amor [Cap-99] [10-09-2020].mp4*1191542414*7/3/2020 2:13:19 AM",
    2357553483,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Wounded birds*0*9/13/2020 10:55:38 PM",
    "Wounded birds [Cap-151] [08-09-2020].mp4*281601666*9/7/2020 11:45:11 PM",
    "Wounded birds [Cap-152] [10-09-2020].mp4*87999350*9/9/2020 7:28:59 AM",
    "Wounded birds [Cap-153] [10-09-2020].mp4*83200545*9/10/2020 12:46:02 AM",
    "Wounded birds [Cap-154] [11-09-2020].mp4*78183485*9/11/2020 1:22:03 AM",
    "Wounded birds [Cap-155] [12-09-2020].mp4*77792255*9/12/2020 1:07:00 AM",
    608777301,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Novelas en Transmisión [Estrenos][Cap Sueltos]\\Yo no creo en los hombres [720p]*0*9/13/2020 10:55:39 PM",
    "Yo no creo en los hombres [Cap-36] [07-09-2020].mp4*631790048*8/14/2020 3:50:15 AM",
    "Yo no creo en los hombres [Cap-37] [08-09-2020].mp4*693514416*8/14/2020 3:52:58 AM",
    "Yo no creo en los hombres [Cap-38] [08-09-2020].mp4*683747837*8/14/2020 3:57:28 AM",
    "Yo no creo en los hombres [Cap-39] [08-09-2020].mp4*662260242*8/14/2020 4:00:07 AM",
    "Yo no creo en los hombres [Cap-40] [08-09-2020].mp4*660025912*8/14/2020 4:03:33 AM",
    "Yo no creo en los hombres [Cap-41] [08-09-2020].mp4*638817088*8/14/2020 4:03:56 AM",
    "Yo no creo en los hombres [Cap-42] [08-09-2020].mp4*647840304*8/14/2020 4:06:25 AM",
    "Yo no creo en los hombres [Cap-43] [08-09-2020].mp4*701150859*8/14/2020 4:07:02 AM",
    "Yo no creo en los hombres [Cap-44] [08-09-2020].mp4*700559546*8/14/2020 4:10:11 AM",
    "Yo no creo en los hombres [Cap-45] [08-09-2020].mp4*687269099*8/14/2020 4:10:32 AM",
    "Yo no creo en los hombres [Cap-46] [08-09-2020].mp4*686529022*8/14/2020 4:13:29 AM",
    "Yo no creo en los hombres [Cap-47] [08-09-2020].mp4*706601857*8/14/2020 4:13:58 AM",
    "Yo no creo en los hombres [Cap-48] [08-09-2020].mp4*637914228*8/14/2020 4:17:13 AM",
    "Yo no creo en los hombres [Cap-49] [08-09-2020].mp4*406133057*9/10/2020 2:55:04 AM",
    "Yo no creo en los hombres [Cap-50] [09-09-2020].mp4*429461884*9/10/2020 11:05:37 PM",
    "Yo no creo en los hombres [Cap-51] [10-09-2020].mp4*710425005*8/16/2020 1:12:25 AM",
    "Yo no creo en los hombres [Cap-52] [11-09-2020].mp4*715561979*8/16/2020 1:11:59 AM",
    10999602383,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [Dobladas al Español] [Finalizadas]*0*9/13/2020 9:54:29 PM",
    0,
    "1145*1146*1147*1148*1149"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [Dobladas al Español] [Finalizadas]\\Heroes (Temporada 2) [11 Cap.] FDT*0*9/13/2020 10:36:35 PM",
    "Heroes 2x01.mp4*369674279*9/3/2018 9:30:00 AM",
    "Heroes 2x02.mp4*301699052*9/3/2018 9:22:39 AM",
    "Heroes 2x03.mp4*301492684*9/3/2018 9:23:22 AM",
    "Heroes 2x04.mp4*305923098*9/3/2018 9:23:06 AM",
    "Heroes 2x05.mp4*303892246*10/5/2017 4:31:05 AM",
    "Heroes 2x06.mp4*198515559*3/11/2017 7:12:56 AM",
    "Heroes 2x07.mp4*302277967*9/3/2018 9:25:08 AM",
    "Heroes 2x08.mp4*299541372*9/3/2018 9:25:16 AM",
    "Heroes 2x09.mp4*330952554*9/3/2018 9:30:38 AM",
    "Heroes 2x10.mp4*322121607*5/23/2017 12:57:59 AM",
    "Heroes 2x11.mp4*306340082*3/11/2017 7:45:55 AM",
    3342430500,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [Dobladas al Español] [Finalizadas]\\Mary Kills People (Temporada 2) [6 Cap.] FDT*0*9/13/2020 11:13:22 PM",
    "2x01 Mary Kills People.avi*507495948*8/17/2020 12:41:40 AM",
    "2x02 Mary Kills People.avi*507746314*8/17/2020 12:41:00 AM",
    "2x03 Mary Kills People.avi*507497160*8/17/2020 12:40:01 AM",
    "2x04 Mary Kills People.avi*507005960*8/17/2020 12:39:36 AM",
    "2x05 Mary Kills People.avi*490609944*8/17/2020 12:38:51 AM",
    "2x06 Mary Kills People.avi*507108944*8/17/2020 12:37:47 AM",
    3027464270,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [Dobladas al Español] [Finalizadas]\\Pulsaciones (Temporada 1) [10 Cap.] FDT*0*9/13/2020 10:34:11 PM",
    "Pulsaciones - 1x01.mp4*498861867*1/10/2017 10:46:38 PM",
    "Pulsaciones - 1x02.mp4*474307573*1/17/2017 11:08:20 PM",
    "Pulsaciones - 1x03.mp4*486047522*1/24/2017 10:00:43 PM",
    "Pulsaciones - 1x04.mp4*527785649*1/31/2017 9:49:21 PM",
    "Pulsaciones - 1x05.avi*795750220*2/8/2017 2:38:48 PM",
    "Pulsaciones - 1x06.mp4*400937925*2/14/2017 9:42:22 PM",
    "Pulsaciones - 1x07.mp4*388728878*2/21/2017 7:38:24 AM",
    "Pulsaciones - 1x08.mp4*396484227*2/28/2017 10:57:30 PM",
    "Pulsaciones - 1x09.mp4*374016634*3/7/2017 11:12:12 PM",
    "Pulsaciones - 1x10.mp4*368095655*3/15/2017 1:34:52 AM",
    4711016150,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [Dobladas al Español] [Finalizadas]\\Romper Stomper (Temporada 1) [6 Cap.] FDT*0*9/13/2020 11:24:56 PM",
    "Romper Stomper - 1x01 [Esp].mp4*243275494*3/31/2019 3:23:31 PM",
    "Romper Stomper - 1x02 [Esp].mp4*256820448*3/31/2019 3:14:14 PM",
    "Romper Stomper - 1x03 [Esp].mp4*234151432*3/31/2019 3:29:54 PM",
    "Romper Stomper - 1x04 [Esp].mp4*213235333*3/31/2019 3:49:20 PM",
    "Romper Stomper - 1x05 [Esp].mp4*186561660*3/31/2019 3:39:58 PM",
    "Romper Stomper - 1x06 [Esp].mp4*184443813*3/31/2019 3:36:51 PM",
    1318488180,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [Dobladas al Español] [Finalizadas]\\Teenage Bounty Hunters (Temporada 1) [10 Cap.] [1080p] [Dual Audio] FDT*0*9/13/2020 11:33:32 PM",
    "S01E01.mkv*538864618*9/9/2020 1:44:40 AM",
    "S01E02.mkv*492086478*9/9/2020 1:43:25 AM",
    "S01E03.mkv*549699835*9/9/2020 1:54:39 AM",
    "S01E04.mkv*466853090*9/9/2020 1:52:01 AM",
    "S01E05.mkv*542044905*9/9/2020 2:05:12 AM",
    "S01E06.mkv*482187976*9/9/2020 2:04:55 AM",
    "S01E07.mkv*539479534*9/9/2020 2:12:54 AM",
    "S01E08.mkv*612702349*9/9/2020 2:17:36 AM",
    "S01E09.mkv*525745086*9/9/2020 2:22:52 AM",
    "S01E10.mkv*646622174*9/9/2020 2:24:57 AM",
    5396286045,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]*0*9/13/2020 9:47:27 PM",
    0,
    "1151*1152*1153*1154*1155*1156*1157*1158*1159*1160*1161*1162*1163*1164*1165*1166*1167*1168*1169*1170*1171*1172*1173*1174*1175*1176*1177*1178*1179*1180*1181*1182*1183*1184*1185*1186*1187*1188*1189*1190*1191*1192*1193*1194*1195*1196*1197*1198*1199*1200*1201*1202*1203*1204*1205*1206*1207*1208*1209*1210*1211*1212*1213*1214*1215*1216*1217*1218*1219*1220*1221*1222*1223*1224*1225*1226*1227*1228*1229*1230*1231*1232*1233*1234*1235*1236*1237*1238*1239*1240*1241*1242*1243*1244*1245*1246*1247*1248*1249*1250*1251*1252*1253*1254*1255*1256*1257*1258*1259*1260*1261*1262*1263*1264*1265*1266*1267*1268*1269*1270*1271*1272*1273*1274*1275*1276*1277*1278*1279*1280*1281*1282*1283*1284*1285*1286*1287*1288*1289*1290*1291*1292*1293*1294*1295*1296*1297*1298*1299*1300*1301*1302*1303*1304*1305*1306*1307*1308*1309*1310"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\!!! Subtitulos*0*9/13/2020 9:11:03 PM",
    "Madagascar A Little Wild 1x06 - Gloriasaurus (Español (Latinoamérica)).srt*29470*9/9/2020 9:24:08 AM",
    "Muppets Now - 01x05 - The I.T. Factor (1).srt*32901*9/7/2020 12:05:42 AM",
    "Muppets Now - 01x05 - The I.T. Factor.srt*33891*9/7/2020 12:05:41 AM",
    "Muppets Now - 01x06 - Socialized (1).srt*35410*9/7/2020 12:05:40 AM",
    "Muppets Now - 01x06 - Socialized.srt*34421*9/7/2020 12:05:39 AM",
    "Power Book Ii_ Ghost - 01x01 - The Stranger.srt*93257*9/7/2020 12:05:45 AM",
    "P-Valley - 01x06 - Legacy (1).srt*55488*9/7/2020 12:05:39 AM",
    "P-Valley - 01x06 - Legacy.srt*62009*9/7/2020 12:05:37 AM",
    "P-Valley - 01x07 - Last Call For Alcohol (1).srt*49440*9/7/2020 12:05:36 AM",
    "P-Valley - 01x07 - Last Call For Alcohol.srt*54405*9/7/2020 12:05:34 AM",
    "P-Valley - 01x08 - Murda Night (1).srt*54785*9/7/2020 12:05:33 AM",
    "P-Valley - 01x08 - Murda Night.srt*61846*9/7/2020 12:05:33 AM",
    "Star Trek Lower Decks 1x05 - Cupids Errant Arrow.AJP69_ION10_TEPES.es-es.srt*45972*9/9/2020 9:25:46 AM",
    "Strike 4x03 - Lethal White Episode 3 (Español (España)).srt*66825*9/9/2020 9:24:41 AM",
    "Strike 4x03 - Lethal White Episode 3.BTN.es-es(1).srt*68349*9/9/2020 9:26:04 AM",
    "Strike 4x03 - Lethal White Episode 3.BTN.es-es.srt*68349*9/9/2020 9:25:51 AM",
    "The Witcher A Look Inside the Episodes 1x01 - Episode 1 (Español (Latinoamérica)).srt*7526*9/9/2020 9:24:58 AM",
    "Wentworth - 08x03 - Enemy Of The State (1).srt*55306*9/7/2020 12:05:33 AM",
    "Wentworth - 08x03 - Enemy Of The State.srt*55565*9/7/2020 12:05:33 AM",
    "Wentworth 8x04 - Revenant.FUtV.es-es.srt*50337*9/9/2020 9:26:01 AM",
    1015552,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\!! Subtitulos*0*9/13/2020 10:16:23 PM",
    "Away - 01x05 - Space Dogs.srt*47059*9/5/2020 12:22:45 AM",
    "Away - 01x06 - A Little Faith.srt*48342*9/5/2020 12:22:42 AM",
    "Away - 01x07 - Goodsnight Mars (1).srt*48590*9/5/2020 12:22:39 AM",
    "Away - 01x07 - Goodsnight Mars.srt*49102*9/5/2020 12:22:39 AM",
    "Away - 01x08 - Vitals Signs.srt*45497*9/5/2020 12:22:39 AM",
    "Away - 01x09 - Spektr.srt*31101*9/5/2020 12:22:39 AM",
    "Away - 01x10 - Home (1).srt*37427*9/5/2020 12:22:39 AM",
    "Away - 01x10 - Home.srt*37555*9/5/2020 12:22:39 AM",
    "Infinity Train 3x10 - The New Apex.WALT_ION10_BLACKHAT.es-es.srt*9364*9/8/2020 11:54:34 AM",
    "Madagascar A Little Wild 1x01 - The Bear Necessities (Español (Latinoamérica)).srt*28852*9/8/2020 11:53:09 AM",
    "Muppets Now - 01x05 - The I.T. Factor (1).srt*32901*9/7/2020 12:05:42 AM",
    "Muppets Now - 01x05 - The I.T. Factor.srt*33891*9/7/2020 12:05:41 AM",
    "Muppets Now - 01x06 - Socialized (1).srt*35410*9/7/2020 12:05:40 AM",
    "Muppets Now - 01x06 - Socialized.srt*34421*9/7/2020 12:05:39 AM",
    "Power Book Ii_ Ghost - 01x01 - The Stranger.srt*93257*9/7/2020 12:05:45 AM",
    "P-Valley - 01x06 - Legacy (1).srt*55488*9/7/2020 12:05:39 AM",
    "P-Valley - 01x06 - Legacy.srt*62009*9/7/2020 12:05:37 AM",
    "P-Valley - 01x07 - Last Call For Alcohol (1).srt*49440*9/7/2020 12:05:36 AM",
    "P-Valley - 01x07 - Last Call For Alcohol.srt*54405*9/7/2020 12:05:34 AM",
    "P-Valley - 01x08 - Murda Night (1).srt*54785*9/7/2020 12:05:33 AM",
    "P-Valley - 01x08 - Murda Night.srt*61846*9/7/2020 12:05:33 AM",
    "Raised By Wolves - 01x02 - Pentagram (1).srt*31856*9/5/2020 12:22:58 AM",
    "Raised By Wolves - 01x02 - Pentagram.srt*42825*9/5/2020 12:22:57 AM",
    "Raised By Wolves - 01x03 - Virtual Faith (1).srt*45090*9/5/2020 12:22:55 AM",
    "Raised By Wolves - 01x03 - Virtual Faith.srt*58856*9/5/2020 12:22:55 AM",
    "Raised by Wolves 1x01 - Raised by Wolves.ION10_NTG.es-es(1).srt*32104*9/8/2020 11:56:43 AM",
    "Raised by Wolves 1x01 - Raised by Wolves.ION10_NTG.es-es.srt*32104*9/9/2020 2:32:00 AM",
    "Raised by Wolves 1x02 - Pentagram.ION10_NTG.es-es(1).srt*31188*9/8/2020 11:56:38 AM",
    "Raised by Wolves 1x02 - Pentagram.ION10_NTG.es-es.srt*31187*9/9/2020 2:32:12 AM",
    "Raised by Wolves 1x03 - Virtual Faith.ION10_NTG.es-es(1).srt*43552*9/8/2020 11:56:33 AM",
    "Raised by Wolves 1x03 - Virtual Faith.ION10_NTG.es-es.srt*43552*9/9/2020 2:32:19 AM",
    "Reno 911! 7x25 - Sofia the Drug Lord (Español (Latinoamérica)).srt*10991*9/8/2020 11:52:30 AM",
    "Star Trek Lower Decks 1x04 - Moist Vessel.iNSPiRiT_TEPES_AJP69.es-es.srt*41556*9/8/2020 11:54:41 AM",
    "Star Trek Lower Decks 1x05 - Cupids Errant Arrow.AJP69_ION10_TEPES.es-es(1).srt*45972*9/8/2020 11:56:27 AM",
    "Star Trek Lower Decks 1x05 - Cupids Errant Arrow.AJP69_ION10_TEPES.es-es.srt*45972*9/8/2020 11:55:08 AM",
    "Star Trek_ Lower Decks 1x05 - Cupids Errant Arrow.AJP69_ION10_TEPES.es-es.srt*45972*9/9/2020 2:32:42 AM",
    "Strike 4x02 - Lethal White Episode 2.BTN.es-es(1).srt*56735*9/8/2020 11:56:53 AM",
    "Strike 4x02 - Lethal White Episode 2.BTN.es-es.srt*56735*9/8/2020 11:54:24 AM",
    "Strike 4x03 - Lethal White Episode 3.BTN.es-es(1).srt*68349*9/8/2020 11:56:12 AM",
    "Strike 4x03 - Lethal White Episode 3.BTN.es-es.srt*68349*9/8/2020 11:55:04 AM",
    "Strike 4x03 - Lethal White Episode 3.srt*66825*9/8/2020 11:51:45 AM",
    "Strike 4x03 - Lethal White_ Episode 3.BTN.es-es.srt*68349*9/9/2020 2:32:54 AM",
    "The Boys - 02x01 - The Big Ride (1).srt*63257*9/5/2020 12:22:52 AM",
    "The Boys - 02x01 - The Big Ride.srt*63474*9/5/2020 12:22:52 AM",
    "The Boys - 02x02 - Proper Preparation And Planning.srt*48038*9/5/2020 12:22:50 AM",
    "The Boys - 02x03 - Over The Hill With The Swords Of A Thousand Men.srt*53175*9/5/2020 12:22:47 AM",
    "The Virtues 1x02 - Episode 2.Cinefeel.es-es.srt*48161*9/8/2020 11:54:28 AM",
    "Wentworth - 08x03 - Enemy Of The State (1).srt*55306*9/7/2020 12:05:33 AM",
    "Wentworth - 08x03 - Enemy Of The State.srt*55565*9/7/2020 12:05:33 AM",
    "Wentworth 8x03 - Enemy of the State.FUtV_REPACK FUtV.es-es(1).srt*55565*9/8/2020 11:56:48 AM",
    "Wentworth 8x03 - Enemy of the State.FUtV_REPACK FUtV.es-es.srt*55565*9/9/2020 2:32:32 AM",
    "Wentworth 8x04 - Revenant.FUtV.es-es.srt*50337*9/9/2020 2:33:03 AM",
    2467304,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\! Subtitulos*0*9/13/2020 10:16:18 PM",
    "Animal Kingdom 4x01 - Janine.TBS.es-es.srt*42630*9/12/2020 3:16:40 AM",
    "Animal Kingdom 4x01 - Janine.TBS.es-es.srt.[0].BACKUP*43418*9/11/2020 11:00:08 AM",
    "Away - 01x05 - Space Dogs.srt*47059*9/5/2020 12:22:45 AM",
    "Away - 01x06 - A Little Faith.srt*48342*9/5/2020 12:22:42 AM",
    "Away - 01x07 - Goodsnight Mars (1).srt*48590*9/5/2020 12:22:39 AM",
    "Away - 01x07 - Goodsnight Mars.srt*49102*9/5/2020 12:22:39 AM",
    "Away - 01x08 - Vitals Signs.srt*45497*9/5/2020 12:22:39 AM",
    "Away - 01x09 - Spektr.srt*31101*9/5/2020 12:22:39 AM",
    "Away - 01x10 - Home (1).srt*37427*9/5/2020 12:22:39 AM",
    "Away - 01x10 - Home.srt*37555*9/5/2020 12:22:39 AM",
    "Brooklyn Nine Nine 5x18 - Gray Star Mutual.TBS.es-es.srt*50048*9/12/2020 3:16:40 AM",
    "Brooklyn Nine Nine 5x18 - Gray Star Mutual.TBS.es-es.srt.[0].BACKUP*50191*9/11/2020 11:00:08 AM",
    "Chefs Table_ Bbq - 01x01 - Tootsie Tomanetz (1).srt*34450*9/3/2020 11:10:37 PM",
    "Chefs Table_ Bbq - 01x01 - Tootsie Tomanetz.srt*35603*9/3/2020 11:10:37 PM",
    "Corporate - 03x06 - Episode 6.srt*36162*9/2/2020 11:15:21 PM",
    "De 12 Van Schouwendam - 01x08 - Episode 8.srt*34521*9/1/2020 11:35:07 PM",
    "Infinity Train - 03x10 - Episode 10.srt*9364*9/2/2020 11:15:24 PM",
    "Infinity Train 3x09 - The Origami Car.WALT_ION10_BLACKHAT.es-es.srt*10067*9/7/2020 11:11:19 AM",
    "Infinity Train 3x10 - The New Apex.WALT_ION10_BLACKHAT.es-es(1).srt*9364*9/7/2020 11:11:03 AM",
    "Infinity Train 3x10 - The New Apex.WALT_ION10_BLACKHAT.es-es.srt*9364*9/7/2020 11:09:56 AM",
    "L.A.S Finest - 02x12 - Episode 12.srt*46477*9/3/2020 11:10:34 PM",
    "L.A.S Finest - 02x13 - Episode 13.srt*39600*9/3/2020 11:10:34 PM",
    "Mapleworth Murders - 01x10 - Episode 10.srt*13383*9/1/2020 11:35:02 PM",
    "Mapleworth Murders - 01x12 - Episode 12.srt*12802*9/1/2020 11:34:59 PM",
    "Raised By Wolves - 01x02 - Pentagram (1).srt*31856*9/5/2020 12:22:58 AM",
    "Raised By Wolves - 01x02 - Pentagram.srt*42825*9/5/2020 12:22:57 AM",
    "Raised By Wolves - 01x03 - Virtual Faith (1).srt*45090*9/5/2020 12:22:55 AM",
    "Raised By Wolves - 01x03 - Virtual Faith.srt*58856*9/5/2020 12:22:55 AM",
    "Raised by Wolves 1x01 - Raised by Wolves.ION10_NTG.es-es(1).srt*32104*9/7/2020 11:10:48 AM",
    "Raised by Wolves 1x01 - Raised by Wolves.ION10_NTG.es-es.srt*32104*9/7/2020 11:10:12 AM",
    "Raised by Wolves 1x02 - Pentagram.ION10_NTG.es-es(1).srt*31188*9/7/2020 11:10:43 AM",
    "Raised by Wolves 1x02 - Pentagram.ION10_NTG.es-es.srt*31188*9/7/2020 11:10:16 AM",
    "Raised by Wolves 1x02 - Pentagram.ION10_NTG.es-es.srt.[0].BACKUP*31180*9/11/2020 11:00:17 AM",
    "Raised by Wolves 1x02 - Pentagram.ION10_NTG.es-es.srt.[1].BACKUP*31037*9/12/2020 3:15:58 AM",
    "Raised by Wolves 1x03 - Virtual Faith.ION10_NTG.es-es(1).srt*43552*9/7/2020 11:10:22 AM",
    "Raised by Wolves 1x03 - Virtual Faith.ION10_NTG.es-es(2).srt*43552*9/7/2020 11:10:38 AM",
    "Raised by Wolves 1x03 - Virtual Faith.ION10_NTG.es-es.srt*43552*9/7/2020 11:09:43 AM",
    "Raised by Wolves 1x03 - Virtual Faith.ION10_NTG.es-es.srt.[0].BACKUP*43552*9/11/2020 11:00:19 AM",
    "Raised by Wolves 1x03 - Virtual Faith.ION10_NTG.es-es.srt.[1].BACKUP*43581*9/12/2020 3:15:58 AM",
    "Raised by Wolves 1x04 - Nature's Course.ION10_NTG.es-es.srt*35851*9/12/2020 3:16:40 AM",
    "Raised by Wolves 1x04 - Nature's Course.ION10_NTG.es-es.srt.[0].BACKUP*35621*9/11/2020 11:00:15 AM",
    "Raised by Wolves 1x04 - Nature's Course.ION10_NTG.es-es.srt.[1].BACKUP*35736*9/12/2020 3:15:58 AM",
    "Sex Next Door - 01x08 - Episode 8.srt*17504*9/1/2020 11:34:58 PM",
    "Star Trek Lower Decks 1x04 - Moist Vessel.iNSPiRiT_TEPES_AJP69.es-es.srt*41556*9/7/2020 11:11:11 AM",
    "Star Trek Lower Decks 1x05 - Cupids Errant Arrow.AJP69_ION10_TEPES.es-es(1).srt*45256*9/12/2020 3:16:40 AM",
    "Star Trek Lower Decks 1x05 - Cupids Errant Arrow.AJP69_ION10_TEPES.es-es(1).srt.[0].BACKUP*45972*9/10/2020 8:17:27 AM",
    "Star Trek Lower Decks 1x05 - Cupids Errant Arrow.AJP69_ION10_TEPES.es-es(1).srt.[1].BACKUP*45141*9/12/2020 3:15:58 AM",
    "Star Trek Lower Decks 1x05 - Cupids Errant Arrow.AJP69_ION10_TEPES.es-es.srt*45256*9/12/2020 3:16:40 AM",
    "Star Trek Lower Decks 1x05 - Cupids Errant Arrow.AJP69_ION10_TEPES.es-es.srt.[0].BACKUP*45972*9/10/2020 8:17:22 AM",
    "Star Trek Lower Decks 1x05 - Cupids Errant Arrow.AJP69_ION10_TEPES.es-es.srt.[1].BACKUP*45141*9/12/2020 3:15:58 AM",
    "Star Trek_ Lower Decks - 01x04 - Episode 4.srt*41556*9/2/2020 11:15:23 PM",
    "Strike 4x01 - Lethal White Episode 1.BTN.es-es.srt*56768*9/7/2020 11:11:15 AM",
    "Strike 4x02 - Lethal White Episode 2.BTN.es-es(1).srt*56750*9/7/2020 11:10:53 AM",
    "Strike 4x02 - Lethal White Episode 2.BTN.es-es.srt*56750*9/7/2020 11:10:02 AM",
    "The 100 7x13 - Blood Giant.ION10_BTN.es-es.srt*41455*9/12/2020 3:16:40 AM",
    "The 100 7x13 - Blood Giant.ION10_BTN.es-es.srt.[0].BACKUP*41383*9/11/2020 11:00:21 AM",
    "The Boys - 02x01 - The Big Ride (1).srt*63257*9/5/2020 12:22:52 AM",
    "The Boys - 02x01 - The Big Ride.srt*63474*9/5/2020 12:22:52 AM",
    "The Boys - 02x02 - Proper Preparation And Planning.srt*48038*9/5/2020 12:22:50 AM",
    "The Boys - 02x03 - Over The Hill With The Swords Of A Thousand Men.srt*53175*9/5/2020 12:22:47 AM",
    "The Guest Book - 02x10 - Episode 10.srt*28758*9/1/2020 11:35:05 PM",
    "The Virtues 1x02 - Episode 2.Cinefeel.es-es.srt*48161*9/7/2020 11:10:57 AM",
    "The Virtues 1x03 - Episode 3.Cinefeel.es-es.srt*39182*9/12/2020 3:16:40 AM",
    "The Virtues 1x03 - Episode 3.Cinefeel.es-es.srt.[0].BACKUP*38995*9/10/2020 8:17:38 AM",
    "The Virtues 1x03 - Episode 3.Cinefeel.es-es.srt.[1].BACKUP*39067*9/12/2020 3:15:58 AM",
    "The Vow - 01x02 - Episode 2.srt*74781*9/2/2020 11:15:26 PM",
    "Trinkets - 01x10 - Episode 10.srt*24885*9/1/2020 11:35:08 PM",
    "United We Fall - 01x07 - Episode 7 (1).srt*30304*9/2/2020 11:15:17 PM",
    "United We Fall - 01x07 - Episode 7.srt*30304*9/2/2020 11:15:16 PM",
    "United We Fall - 01x08 - Episode 8.srt*29614*9/2/2020 11:15:15 PM",
    "Wentworth 8x03 - Enemy of the State.FUtV_REPACK FUtV.es-es(1).srt*55565*9/7/2020 11:10:06 AM",
    "Wentworth 8x03 - Enemy of the State.FUtV_REPACK FUtV.es-es.srt*55565*9/7/2020 11:09:51 AM",
    "Wentworth 8x04 - Revenant.FUtV.es-es.srt*50065*9/12/2020 3:16:40 AM",
    "Wentworth 8x04 - Revenant.FUtV.es-es.srt.[0].BACKUP*50337*9/10/2020 8:17:33 AM",
    "Wentworth 8x05 - Fallout.Nemo.es-es(1).srt*54945*9/12/2020 3:16:40 AM",
    "Wentworth 8x05 - Fallout.Nemo.es-es(1).srt.[0].BACKUP*55432*9/10/2020 8:18:14 AM",
    "Wentworth 8x05 - Fallout.Nemo.es-es(2).srt*54945*9/12/2020 3:16:40 AM",
    "Wentworth 8x05 - Fallout.Nemo.es-es(2).srt.[0].BACKUP*55432*9/11/2020 11:00:23 AM",
    "Wentworth 8x05 - Fallout.Nemo.es-es.srt*54945*9/12/2020 3:16:40 AM",
    "Wentworth 8x05 - Fallout.Nemo.es-es.srt.[0].BACKUP*55432*9/10/2020 8:17:59 AM",
    "Wentworth 8x06 - Fugitive.BTN.es-es.srt*43789*9/12/2020 3:16:40 AM",
    "Wentworth 8x06 - Fugitive.BTN.es-es.srt.[0].BACKUP*43803*9/11/2020 11:00:09 AM",
    "Woke 1x01 - Rhymes with Broke (Español (Latinoamérica)).srt*47285*9/12/2020 3:16:40 AM",
    "Woke 1x01 - Rhymes with Broke (Español (Latinoamérica)).srt.[0].BACKUP*47055*9/10/2020 8:15:48 AM",
    "Woke 1x01 - Rhymes with Broke (Español (Latinoamérica)).srt.[1].BACKUP*47170*9/12/2020 3:15:58 AM",
    "Woke 1x08 - Blue Lies Matter (Español (Latinoamérica)).srt*34252*9/12/2020 3:16:40 AM",
    "Woke 1x08 - Blue Lies Matter (Español (Latinoamérica)).srt.[0].BACKUP*34137*9/10/2020 8:16:12 AM",
    "Wynonna Earp - 04x06 - Episode 6 (1).srt*58712*9/2/2020 11:15:19 PM",
    "Wynonna Earp - 04x06 - Episode 6.srt*58712*9/2/2020 11:15:19 PM",
    "Young Wallander - 01x01 - Episode 1 (1).srt*36545*9/3/2020 11:10:56 PM",
    "Young Wallander - 01x01 - Episode 1.srt*38263*9/3/2020 11:10:55 PM",
    "Young Wallander - 01x02 - Episode 2 (1).srt*36553*9/3/2020 11:10:52 PM",
    "Young Wallander - 01x02 - Episode 2.srt*37880*9/3/2020 11:10:52 PM",
    "Young Wallander - 01x03 - Episode 3 (1).srt*28767*9/3/2020 11:10:49 PM",
    "Young Wallander - 01x03 - Episode 3.srt*30017*9/3/2020 11:10:49 PM",
    "Young Wallander - 01x04 - Episode 4 (1).srt*36325*9/3/2020 11:10:46 PM",
    "Young Wallander - 01x04 - Episode 4.srt*38079*9/3/2020 11:10:46 PM",
    "Young Wallander - 01x05 - Episode 5 (1).srt*39175*9/3/2020 11:10:42 PM",
    "Young Wallander - 01x05 - Episode 5.srt*41245*9/3/2020 11:10:42 PM",
    "Young Wallander - 01x06 - Episode 6 (1).srt*38246*9/3/2020 11:10:39 PM",
    "Young Wallander - 01x06 - Episode 6.srt*39249*9/3/2020 11:10:39 PM",
    4150919,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Acacias 38 - [Temp 2] [Cap-348] [TS] [391,52 Mb]*0*9/13/2020 11:21:24 PM",
    "Acacias 38 - 2x348.ts*410542744*9/7/2020 9:36:10 PM",
    410542744,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Acacias 38 - [Temp 2] [Cap-349] [TS] [391,35 Mb]*0*9/13/2020 11:16:30 PM",
    "Acacias 38 - 2x349.ts*410360760*9/8/2020 10:25:17 PM",
    410360760,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Acacias 38 - [Temp 2] [Cap-350] [TS] [371,73 Mb]*0*9/13/2020 11:27:35 PM",
    "Acacias 38 - 2x350.ts*389786040*9/9/2020 10:24:51 PM",
    389786040,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Acacias 38 - [Temp 2] [Cap-352] [TS]*0*9/13/2020 9:11:25 PM",
    "Acacias 38 - 2x352.ts*173113784*9/11/2020 8:32:01 PM",
    173113784,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\All Creatures Great and Small 2020 - [Temp 1] [Cap-02] [MP4] [332,06 Mb]*0*9/13/2020 9:11:29 PM",
    "All Creatures Great and Small 2020 - 1x02.mp4*348189983*9/9/2020 1:40:02 PM",
    348189983,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bang - [Temp 1] [Cap-01] [MP4] [349,21 Mb]*0*9/13/2020 8:54:46 PM",
    "Bang - 1x01.mp4*366171505*9/9/2020 3:41:11 PM",
    366171505,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bang - [Temp 1] [Cap-02] [MP4] [346,73 Mb]*0*9/13/2020 8:54:49 PM",
    "Bang - 1x02.mp4*363571373*9/9/2020 3:44:11 PM",
    363571373,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bang - [Temp 1] [Cap-03] [MP4] [355,67 Mb]*0*9/13/2020 10:27:08 PM",
    "Bang - 1x03.mp4*372947000*9/9/2020 4:04:27 PM",
    372947000,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bang - [Temp 1] [Cap-04] [MP4] [346,81 Mb]*0*9/13/2020 8:54:52 PM",
    "Bang - 1x04.mp4*363653503*9/9/2020 3:55:24 PM",
    363653503,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bang - [Temp 1] [Cap-05] [MP4] [343,53 Mb]*0*9/13/2020 8:54:54 PM",
    "Bang - 1x05.mp4*360215985*9/9/2020 3:49:05 PM",
    360215985,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bang - [Temp 1] [Cap-06] [MP4] [348,91 Mb]*0*9/13/2020 10:18:34 PM",
    "Bang - 1x06.mp4*365861184*9/9/2020 3:52:24 PM",
    365861184,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bang - [Temp 1] [Cap-07] [MP4] [333,85 Mb]*0*9/13/2020 8:54:57 PM",
    "Bang - 1x07.mp4*350065481*9/9/2020 4:01:20 PM",
    350065481,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bang - [Temp 1] [Cap-08] [MP4] [352,55 Mb]*0*9/13/2020 8:55:00 PM",
    "Bang - 1x08.mp4*369675917*9/9/2020 3:58:26 PM",
    369675917,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bang - [Temp 2] [Cap-01] [MP4] [357,5 Mb]*0*9/13/2020 11:06:55 PM",
    "Bang - 2x01.mp4*374863482*9/9/2020 4:07:38 PM",
    374863482,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bang - [Temp 2] [Cap-02] [MP4] [357,51 Mb]*0*9/13/2020 8:55:02 PM",
    "Bang - 2x02.mp4*374873573*9/9/2020 4:19:19 PM",
    374873573,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bang - [Temp 2] [Cap-03] [MP4] [358,9 Mb]*0*9/13/2020 8:55:05 PM",
    "Bang - 2x03.mp4*376333274*9/9/2020 4:22:24 PM",
    376333274,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bang - [Temp 2] [Cap-04] [MP4] [355,53 Mb]*0*9/13/2020 8:55:08 PM",
    "Bang - 2x04.mp4*372802619*9/9/2020 4:25:28 PM",
    372802619,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bang - [Temp 2] [Cap-05] [MP4] [356,63 Mb]*0*9/13/2020 10:18:38 PM",
    "Bang - 2x05.mp4*373953316*9/9/2020 4:28:35 PM",
    373953316,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bang - [Temp 2] [Cap-06] [MP4] [354,88 Mb]*0*9/13/2020 8:55:11 PM",
    "Bang - 2x06.mp4*372114590*9/9/2020 4:33:38 PM",
    372114590,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bron (El Puente) - [Temp 1] [Cap-01] [MP4] [133,73 Mb]*0*9/13/2020 8:55:14 PM",
    "Bron (El Puente) - 1x01.mp4*140222056*9/8/2020 9:07:46 AM",
    140222056,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bron (El Puente) - [Temp 1] [Cap-03] [MP4] [139,58 Mb]*0*9/13/2020 8:55:15 PM",
    "Bron (El Puente) - 1x03.mp4*146359504*9/8/2020 9:08:32 AM",
    146359504,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bron (El Puente) - [Temp 1] [Cap-04] [MP4] [132,62 Mb]*0*9/13/2020 8:55:16 PM",
    "Bron (El Puente) - 1x04.mp4*139063976*9/8/2020 9:20:47 AM",
    139063976,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bron (El Puente) - [Temp 1] [Cap-06] [MP4] [126,04 Mb]*0*9/13/2020 8:55:17 PM",
    "Bron (El Puente) - 1x06.mp4*132163624*9/8/2020 9:29:56 AM",
    132163624,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bron (El Puente) - [Temp 1] [Cap-08] [MP4] [128,6 Mb]*0*9/13/2020 8:55:18 PM",
    "Bron (El Puente) - 1x08.mp4*134847512*9/8/2020 7:46:00 AM",
    134847512,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Bron (El Puente) - [Temp 1] [Cap-09] [MP4] [135,48 Mb]*0*9/13/2020 11:15:28 PM",
    "Bron (El Puente) - 1x09.mp4*142063328*9/8/2020 9:30:02 AM",
    142063328,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Chosen - [Temp 1] [Cap-01] [MKV] [826,81 Mb]*0*9/13/2020 8:55:22 PM",
    "Chosen - 1x01.mkv*866794789*9/7/2020 7:25:05 PM",
    866794789,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Chosen - [Temp 1] [Cap-02] [MKV] [872,4 Mb]*0*9/13/2020 8:55:28 PM",
    "Chosen - 1x02.mkv*914598224*9/7/2020 8:18:08 PM",
    914598224,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Chosen - [Temp 1] [Cap-03] [MKV] [806,88 Mb]*0*9/13/2020 8:55:35 PM",
    "Chosen - 1x03.mkv*845895828*9/7/2020 6:37:33 PM",
    845895828,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Chosen - [Temp 1] [Cap-04] [MKV] [860,29 Mb]*0*9/13/2020 11:09:08 PM",
    "Chosen - 1x04.mkv*901900301*9/7/2020 7:51:10 PM",
    901900301,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Chosen - [Temp 1] [Cap-05] [MKV] [860,96 Mb]*0*9/13/2020 8:55:42 PM",
    "Chosen - 1x05.mkv*902609715*9/7/2020 7:55:24 PM",
    902609715,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Chosen - [Temp 1] [Cap-06] [MKV] [883,24 Mb]*0*9/13/2020 8:55:49 PM",
    "Chosen - 1x06.mkv*925970626*9/7/2020 7:47:53 PM",
    925970626,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Family Business - [Temp 1] [Cap-01] [MP4] [260,92 Mb]*0*9/13/2020 8:55:55 PM",
    "Family Business - 1x01.mp4*273589272*9/11/2020 4:14:09 PM",
    273589272,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Family Business - [Temp 1] [Cap-02] [MP4] [287,87 Mb]*0*9/13/2020 8:55:57 PM",
    "Family Business - 1x02.mp4*301857929*9/11/2020 4:11:51 PM",
    301857929,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Family Business - [Temp 1] [Cap-03] [MP4] [291,42 Mb]*0*9/13/2020 8:56:00 PM",
    "Family Business - 1x03.mp4*305580801*9/11/2020 4:19:17 PM",
    305580801,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Family Business - [Temp 1] [Cap-04] [MP4] [111,5 Mb]*0*9/13/2020 11:09:19 PM",
    "Family Business - 1x04.mp4*116920093*9/11/2020 7:41:28 PM",
    116920093,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Family Business - [Temp 1] [Cap-05] [MP4] [294,05 Mb]*0*9/13/2020 8:56:02 PM",
    "Family Business - 1x05.mp4*308336708*9/11/2020 4:25:54 PM",
    308336708,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Family Business - [Temp 1] [Cap-06] [MP4] [102,63 Mb]*0*9/13/2020 11:09:21 PM",
    "Family Business - 1x06.mp4*107611543*9/11/2020 7:41:16 PM",
    107611543,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Family Business - [Temp 2] [Cap-01] [MP4] [309,74 Mb]*0*9/13/2020 8:56:04 PM",
    "Family Business - 2x01.mp4*324781566*9/11/2020 4:28:37 PM",
    324781566,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Family Business - [Temp 2] [Cap-02] [MP4] [155,91 Mb]*0*9/13/2020 8:56:07 PM",
    "Family Business - 2x02.mp4*163483893*9/11/2020 7:41:57 PM",
    163483893,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Family Business - [Temp 2] [Cap-03] [MP4] [347,75 Mb]*0*9/13/2020 8:56:08 PM",
    "Family Business - 2x03.mp4*364637693*9/11/2020 4:34:49 PM",
    364637693,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Family Business - [Temp 2] [Cap-04] [MP4] [278,99 Mb]*0*9/13/2020 8:56:11 PM",
    "Family Business - 2x04.mp4*292541327*9/11/2020 4:37:14 PM",
    292541327,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Family Business - [Temp 2] [Cap-05] [MP4] [328,17 Mb]*0*9/13/2020 9:21:08 PM",
    "Family Business - 2x05.mp4*344105964*9/11/2020 4:40:03 PM",
    344105964,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Family Business - [Temp 2] [Cap-06] [MP4] [116,38 Mb]*0*9/13/2020 9:21:06 PM",
    "Family Business - 2x06.mp4*122033019*9/11/2020 7:41:32 PM",
    122033019,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\FurtherTime For Dinner AU - [Temp 1] [Cap-02] [MP4] [429,85 Mb]*0*9/13/2020 9:21:25 PM",
    "FurtherTime For Dinner AU - 1x02.mp4*450734702*9/7/2020 8:04:13 PM",
    450734702,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Habitacion 309 - [Temp 1] [Cap-47] [TS] [372,34 Mb]*0*9/13/2020 11:16:49 PM",
    "Habitacion 309 - 1x47.ts*390422984*9/8/2020 10:25:47 PM",
    390422984,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Habitacion 309 - [Temp 1] [Cap-48] [TS] [375,56 Mb]*0*9/13/2020 9:21:21 PM",
    "Habitacion 309 - 1x48.ts*393802096*9/9/2020 10:56:17 PM",
    393802096,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Habitacion 309 - [Temp 1] [Cap-49]*0*9/13/2020 9:21:18 PM",
    "Habitacion 309 - 1x49.ts*401194632*9/11/2020 1:17:20 AM",
    401194632,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Habitacion 309 - [Temp 1] [Cap-50] [TS]*0*9/13/2020 11:24:05 PM",
    "Habitacion 309 - 1x50.ts*433521232*9/11/2020 8:23:58 PM",
    433521232,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Halifax Retribution - [Temp 1] [Cap-03] [MKV] [426,63 Mb]*0*9/13/2020 9:21:15 PM",
    "Halifax Retribution - 1x03.mkv*447351901*9/8/2020 12:30:17 PM",
    447351901,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Inocentes - [Temp 1] [Cap-01] [MP4] [387,81 Mb]*0*9/13/2020 9:21:11 PM",
    "Inocentes - 1x01.mp4*406652058*9/8/2020 2:53:03 PM",
    406652058,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Inocentes - [Temp 1] [Cap-02] [MP4] [389,11 Mb]*0*9/13/2020 11:32:34 PM",
    "Inocentes - 1x02.mp4*408010625*9/8/2020 3:00:02 PM",
    408010625,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Julie And The Phantoms - [Temp 1] [Cap-01] [MP4] [343,14 Mb]*0*9/13/2020 9:21:03 PM",
    "Julie And The Phantoms - 1x01.mp4*359810946*9/11/2020 11:42:35 AM",
    359810946,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Julie And The Phantoms - [Temp 1] [Cap-02] [MP4] [290,61 Mb]*0*9/13/2020 9:21:01 PM",
    "Julie And The Phantoms - 1x02.mp4*304722023*9/11/2020 11:47:29 AM",
    304722023,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Julie And The Phantoms - [Temp 1] [Cap-03] [MP4] [245,27 Mb]*0*9/13/2020 9:20:58 PM",
    "Julie And The Phantoms - 1x03.mp4*257189327*9/11/2020 11:49:36 AM",
    257189327,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Julie And The Phantoms - [Temp 1] [Cap-04] [MP4] [277,06 Mb]*0*9/13/2020 10:20:53 PM",
    "Julie And The Phantoms - 1x04.mp4*290522118*9/11/2020 11:51:59 AM",
    290522118,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Julie And The Phantoms - [Temp 1] [Cap-05] [MP4] [264,79 Mb]*0*9/13/2020 9:20:56 PM",
    "Julie And The Phantoms - 1x05.mp4*277650559*9/11/2020 11:54:16 AM",
    277650559,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Julie And The Phantoms - [Temp 1] [Cap-06] [MP4] [130,64 Mb]*0*9/13/2020 9:20:54 PM",
    "Julie And The Phantoms - 1x06.mp4*136987059*9/11/2020 7:41:39 PM",
    136987059,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Julie And The Phantoms - [Temp 1] [Cap-07] [MP4] [235,06 Mb]*0*9/13/2020 9:20:52 PM",
    "Julie And The Phantoms - 1x07.mp4*246481032*9/11/2020 11:58:34 AM",
    246481032,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Julie And The Phantoms - [Temp 1] [Cap-08] [MP4] [208,33 Mb]*0*9/13/2020 9:20:50 PM",
    "Julie And The Phantoms - 1x08.mp4*218454479*9/11/2020 12:00:23 PM",
    218454479,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Julie And The Phantoms - [Temp 1] [Cap-09] [MP4] [171,08 Mb]*0*9/13/2020 9:20:48 PM",
    "Julie And The Phantoms - 1x09.mp4*179390426*9/11/2020 7:43:49 PM",
    179390426,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-01] [MP4] [346,49 Mb]*0*9/13/2020 11:14:57 PM",
    "Kingdom - 2x01.mp4*363316050*9/7/2020 12:22:20 AM",
    363316050,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-02] [MP4] [347,62 Mb]*0*9/13/2020 9:20:45 PM",
    "Kingdom - 2x02.mp4*364507995*9/7/2020 12:26:40 AM",
    364507995,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-03] [MP4] [346,2 Mb]*0*9/13/2020 9:20:42 PM",
    "Kingdom - 2x03.mp4*363019371*9/7/2020 12:26:58 AM",
    363019371,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-04] [MP4] [349,49 Mb]*0*9/13/2020 9:20:38 PM",
    "Kingdom - 2x04.mp4*366471432*9/7/2020 12:31:20 AM",
    366471432,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-05] [MP4] [343,97 Mb]*0*9/13/2020 10:29:18 PM",
    "Kingdom - 2x05.mp4*360682552*9/7/2020 12:31:35 AM",
    360682552,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-06] [MP4] [363,86 Mb]*0*9/13/2020 9:20:35 PM",
    "Kingdom - 2x06.mp4*381538513*9/7/2020 12:36:13 AM",
    381538513,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-07] [MP4] [376,72 Mb]*0*9/13/2020 9:20:32 PM",
    "Kingdom - 2x07.mp4*395022079*9/7/2020 12:36:36 AM",
    395022079,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-08] [MP4] [349,01 Mb]*0*9/13/2020 11:21:29 PM",
    "Kingdom - 2x08.mp4*365960948*9/7/2020 12:41:16 AM",
    365960948,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-09] [MP4] [279,28 Mb]*0*9/13/2020 9:20:28 PM",
    "Kingdom - 2x09.mp4*292846511*9/7/2020 5:29:34 PM",
    292846511,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-10] [MP4] [372,38 Mb]*0*9/13/2020 9:20:25 PM",
    "Kingdom - 2x10.mp4*390466274*9/7/2020 12:45:53 AM",
    390466274,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-11] [MP4] [350,2 Mb]*0*9/13/2020 9:20:22 PM",
    "Kingdom - 2x11.mp4*367210680*9/7/2020 12:51:25 AM",
    367210680,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-12] [MP4] [336,43 Mb]*0*9/13/2020 10:20:49 PM",
    "Kingdom - 2x12.mp4*352770516*9/7/2020 12:50:24 AM",
    352770516,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-13] [MP4] [408,88 Mb]*0*9/13/2020 9:20:19 PM",
    "Kingdom - 2x13.mp4*428745051*9/7/2020 12:46:45 AM",
    428745051,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-14] [MP4] [348,26 Mb]*0*9/13/2020 11:27:58 PM",
    "Kingdom - 2x14.mp4*365177572*9/7/2020 12:55:04 AM",
    365177572,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-15] [MP4] [361,53 Mb]*0*9/13/2020 9:20:16 PM",
    "Kingdom - 2x15.mp4*379092330*9/7/2020 12:56:16 AM",
    379092330,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-16] [MP4] [292,82 Mb]*0*9/13/2020 9:20:13 PM",
    "Kingdom - 2x16.mp4*307044648*9/7/2020 5:34:37 PM",
    307044648,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-17] [MP4] [339,67 Mb]*0*9/13/2020 9:20:10 PM",
    "Kingdom - 2x17.mp4*356174472*9/7/2020 1:00:48 AM",
    356174472,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-18] [MP4] [336,61 Mb]*0*9/13/2020 8:43:46 PM",
    "Kingdom - 2x18.mp4*352961394*9/7/2020 1:04:23 AM",
    352961394,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-19] [MP4] [292,53 Mb]*0*9/13/2020 8:43:49 PM",
    "Kingdom - 2x19.mp4*306741019*9/7/2020 5:34:43 PM",
    306741019,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom - [Temp 2] [Cap-20] [MP4] [400,23 Mb]*0*9/13/2020 8:43:51 PM",
    "Kingdom - 2x20.mp4*419670675*9/7/2020 1:07:54 AM",
    419670675,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 1] [Cap-01] [MP4] [386,51 Mb]*0*9/13/2020 8:43:55 PM",
    "Kingdom 2014 - 1x01.mp4*405285041*9/6/2020 11:30:06 PM",
    405285041,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 1] [Cap-02] [MP4] [328,36 Mb]*0*9/13/2020 8:44:01 PM",
    "Kingdom 2014 - 1x02.mp4*344309333*9/6/2020 11:29:49 PM",
    344309333,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 1] [Cap-03] [MP4] [333,02 Mb]*0*9/13/2020 8:44:04 PM",
    "Kingdom 2014 - 1x03.mp4*349200070*9/6/2020 11:34:14 PM",
    349200070,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 1] [Cap-04] [MP4] [320,29 Mb]*0*9/13/2020 8:44:07 PM",
    "Kingdom 2014 - 1x04.mp4*335848717*9/6/2020 11:34:22 PM",
    335848717,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 1] [Cap-05] [MP4] [365,26 Mb]*0*9/13/2020 8:44:13 PM",
    "Kingdom 2014 - 1x05.mp4*383005677*9/6/2020 11:38:59 PM",
    383005677,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 1] [Cap-06] [MP4] [327,78 Mb]*0*9/13/2020 8:44:16 PM",
    "Kingdom 2014 - 1x06.mp4*343700393*9/6/2020 11:38:57 PM",
    343700393,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 1] [Cap-07] [MP4] [265,55 Mb]*0*9/13/2020 8:44:22 PM",
    "Kingdom 2014 - 1x07.mp4*278454113*9/7/2020 5:27:26 PM",
    278454113,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 1] [Cap-08] [MP4] [323,46 Mb]*0*9/13/2020 8:44:25 PM",
    "Kingdom 2014 - 1x08.mp4*339173499*9/6/2020 11:43:21 PM",
    339173499,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 1] [Cap-09] [MP4] [249,74 Mb]*0*9/13/2020 8:44:29 PM",
    "Kingdom 2014 - 1x09.mp4*261872092*9/7/2020 5:27:23 PM",
    261872092,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 1] [Cap-10] [MP4] [344,86 Mb]*0*9/13/2020 8:44:31 PM",
    "Kingdom 2014 - 1x10.mp4*361616751*9/6/2020 11:48:02 PM",
    361616751,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 3] [Cap-01] [MP4] [347,24 Mb]*0*9/13/2020 8:44:37 PM",
    "Kingdom 2014 - 3x01.mp4*364104502*9/6/2020 11:52:12 PM",
    364104502,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 3] [Cap-02] [MP4] [384,57 Mb]*0*9/13/2020 8:44:40 PM",
    "Kingdom 2014 - 3x02.mp4*403247102*9/6/2020 11:53:09 PM",
    403247102,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 3] [Cap-03] [MP4] [371,56 Mb]*0*9/13/2020 8:44:44 PM",
    "Kingdom 2014 - 3x03.mp4*389606935*9/6/2020 11:57:11 PM",
    389606935,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 3] [Cap-04] [MP4] [435,35 Mb]*0*9/13/2020 8:44:47 PM",
    "Kingdom 2014 - 3x04.mp4*456500793*9/6/2020 11:58:07 PM",
    456500793,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 3] [Cap-05] [MP4] [389,61 Mb]*0*9/13/2020 8:44:52 PM",
    "Kingdom 2014 - 3x05.mp4*408531317*9/7/2020 12:08:51 AM",
    408531317,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 3] [Cap-06] [MP4] [372,01 Mb]*0*9/13/2020 8:44:55 PM",
    "Kingdom 2014 - 3x06.mp4*390085524*9/7/2020 12:08:44 AM",
    390085524,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 3] [Cap-07] [MP4] [274,39 Mb]*0*9/13/2020 8:44:59 PM",
    "Kingdom 2014 - 3x07.mp4*287717647*9/7/2020 5:27:34 PM",
    287717647,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 3] [Cap-08] [MP4] [374,03 Mb]*0*9/13/2020 8:45:03 PM",
    "Kingdom 2014 - 3x08.mp4*392202545*9/7/2020 12:16:11 AM",
    392202545,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 3] [Cap-09] [MP4] [285,58 Mb]*0*9/13/2020 8:45:07 PM",
    "Kingdom 2014 - 3x09.mp4*299454858*9/7/2020 5:27:48 PM",
    299454858,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Kingdom 2014 - [Temp 3] [Cap-10] [MP4] [435,06 Mb]*0*9/13/2020 8:45:09 PM",
    "Kingdom 2014 - 3x10.mp4*456196521*9/7/2020 12:22:00 AM",
    456196521,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\MADRES - [Temp 1] [Cap-03] [MP4] [235,45 Mb]*0*9/13/2020 8:45:20 PM",
    "MADRES - 1x03.mp4*246889355*9/8/2020 2:17:24 PM",
    246889355,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\MADRES  Amor y vida - [Temp 1] [Cap-01] [MP4] [264,43 Mb]*0*9/13/2020 8:45:13 PM",
    "MADRES  Amor y vida - 1x01.mp4*277271379*9/7/2020 3:53:12 PM",
    277271379,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\MADRES  Amor y vida - [Temp 1] [Cap-02] [MP4] [264,59 Mb]*0*9/13/2020 8:45:18 PM",
    "MADRES  Amor y vida - 1x02.mp4*277447732*9/7/2020 3:58:18 PM",
    277447732,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Mercado central - [Temp 1] [Cap-242] [TS] [153,87 Mb]*0*9/13/2020 8:45:22 PM",
    "Mercado central - 1x242.ts*161343480*9/7/2020 9:21:00 PM",
    161343480,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Mercado central - [Temp 1] [Cap-243] [TS] [345 Mb]*0*9/13/2020 8:45:24 PM",
    "Mercado central - 1x243.ts*361757496*9/8/2020 10:02:00 PM",
    361757496,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Mercado central - [Temp 1] [Cap-244] [TS] [416,41 Mb]*0*9/13/2020 8:45:29 PM",
    "Mercado central - 1x244.ts*436638648*9/9/2020 9:55:11 PM",
    436638648,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Next Big Thing 2019 - [Temp 2] [Cap-05] [MP4] [110,2 Mb]*0*9/13/2020 8:45:35 PM",
    "Next Big Thing 2019 - 2x05.mp4*115548964*9/7/2020 6:26:53 PM",
    115548964,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Next Big Thing 2019 - [Temp 2] [Cap-06] [MP4] [115,85 Mb]*0*9/13/2020 8:45:36 PM",
    "Next Big Thing 2019 - 2x06.mp4*121474529*9/7/2020 6:26:48 PM",
    121474529,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Next Big Thing 2019 - [Temp 2] [Cap-07] [MP4] [124,78 Mb]*0*9/13/2020 8:45:40 PM",
    "Next Big Thing 2019 - 2x07.mp4*130836500*9/7/2020 6:31:12 PM",
    130836500,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Raised by Wolves 2020 - [Temp 1] [Cap-04] [AVI] [252,89 Mb]*0*9/13/2020 8:45:41 PM",
    "Raised by Wolves 2020 - 1x04.avi*265177516*9/10/2020 3:33:16 PM",
    265177516,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Raised by Wolves 2020 - [Temp 1] [Cap-05] [AVI] [298,03 Mb]*0*9/13/2020 8:45:43 PM",
    "Raised by Wolves 2020 - 1x05.avi*312510471*9/10/2020 3:38:18 PM",
    312510471,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Record Of Youth - [Temp 1] [Cap-02] [MP4] [750,62 Mb]*0*9/13/2020 8:45:46 PM",
    "Record Of Youth - 1x02.mp4*787079551*9/9/2020 2:47:02 PM",
    787079551,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Room 104 - [Temp 4] [Cap-08] [MP4]*0*9/13/2020 8:45:53 PM",
    "Room 104 - 4x08.mp4*186215262*9/12/2020 2:24:59 AM",
    186215262,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Semi Detached - [Temp 1] [Cap-05] [MKV]*0*9/13/2020 8:33:07 PM",
    "Semi Detached - 1x05.mkv*297012746*9/6/2020 9:50:52 PM",
    297012746,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Semi Detached - [Temp 1] [Cap-06] [MKV]*0*9/13/2020 8:33:13 PM",
    "Semi Detached - 1x06.mkv*292294617*9/6/2020 9:35:44 PM",
    292294617,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Servir y proteger - [Temp 1] [Cap-845] [TS] [370,71 Mb]*0*9/13/2020 8:33:21 PM",
    "Servir y proteger - 1x845.ts*388719328*9/7/2020 9:28:12 PM",
    388719328,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Servir y proteger - [Temp 1] [Cap-846] [TS] [332,31 Mb]*0*9/13/2020 8:33:25 PM",
    "Servir y proteger - 1x846.ts*348451984*9/8/2020 10:07:57 PM",
    348451984,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Servir y proteger - [Temp 1] [Cap-847] [TS] [299,77 Mb]*0*9/13/2020 8:33:27 PM",
    "Servir y proteger - 1x847.ts*314332616*9/9/2020 10:08:27 PM",
    314332616,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Servir y proteger - [Temp 1] [Cap-848]*0*9/13/2020 8:33:30 PM",
    "Servir y proteger - 1x848.ts*337672064*9/11/2020 12:59:16 AM",
    337672064,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Servir y proteger - [Temp 1] [Cap-849] [TS]*0*9/13/2020 8:33:33 PM",
    "Servir y proteger - 1x849.ts*152757896*9/11/2020 8:28:09 PM",
    152757896,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Strangers from Hell - [Temp 1] [Cap-01] [MP4] [589,46 Mb]*0*9/13/2020 8:33:44 PM",
    "Strangers from Hell - 1x01.mp4*618092731*9/10/2020 5:10:52 PM",
    618092731,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Strangers from Hell - [Temp 1] [Cap-02] [MP4] [590,82 Mb]*0*9/13/2020 8:33:49 PM",
    "Strangers from Hell - 1x02.mp4*619524342*9/10/2020 5:15:57 PM",
    619524342,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Strangers from Hell - [Temp 1] [Cap-03] [MP4] [581,39 Mb]*0*9/13/2020 8:33:53 PM",
    "Strangers from Hell - 1x03.mp4*609631468*9/10/2020 5:20:55 PM",
    609631468,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Strangers from Hell - [Temp 1] [Cap-04] [MP4] [579,82 Mb]*0*9/13/2020 8:33:58 PM",
    "Strangers from Hell - 1x04.mp4*607988605*9/10/2020 5:25:53 PM",
    607988605,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Strangers from Hell - [Temp 1] [Cap-05] [MP4] [586,29 Mb]*0*9/13/2020 8:34:08 PM",
    "Strangers from Hell - 1x05.mp4*614766894*9/10/2020 5:30:58 PM",
    614766894,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Strangers from Hell - [Temp 1] [Cap-06] [MP4] [584,25 Mb]*0*9/13/2020 8:34:19 PM",
    "Strangers from Hell - 1x06.mp4*612633785*9/10/2020 5:36:07 PM",
    612633785,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Strangers from Hell - [Temp 1] [Cap-07] [MP4] [184,72 Mb]*0*9/13/2020 8:34:42 PM",
    "Strangers from Hell - 1x07.mp4*193696228*9/11/2020 7:44:25 PM",
    193696228,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Strangers from Hell - [Temp 1] [Cap-08] [MP4] [614,24 Mb]*0*9/13/2020 8:34:45 PM",
    "Strangers from Hell - 1x08.mp4*644072229*9/10/2020 5:46:25 PM",
    644072229,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Strangers from Hell - [Temp 1] [Cap-09] [MP4] [567,83 Mb]*0*9/13/2020 8:34:49 PM",
    "Strangers from Hell - 1x09.mp4*595409090*9/10/2020 5:56:49 PM",
    595409090,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Strangers from Hell - [Temp 1] [Cap-10] [MP4] [191,92 Mb]*0*9/13/2020 8:35:19 PM",
    "Strangers from Hell - 1x10.mp4*201244993*9/11/2020 7:44:38 PM",
    201244993,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Supernanny - [Temp 8] [Cap-15] [MKV] [287,38 Mb]*0*9/13/2020 8:35:20 PM",
    "Supernanny - 8x15.mkv*301342211*9/9/2020 3:13:05 AM",
    301342211,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Tacoma FD - [Temp 0] [Cap-13] [MKV] [455,11 Mb]*0*9/13/2020 8:35:24 PM",
    "Tacoma FD - 0x13.mkv*477215395*9/10/2020 12:18:36 PM",
    477215395,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Ted Lasso - [Temp 1] [Cap-07] [MP4] [309,1 Mb]*0*9/13/2020 8:35:30 PM",
    "Ted Lasso - 1x07.mp4*324110514*9/11/2020 3:03:01 PM",
    324110514,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\The 100 - [Temp 7] [Cap-13] [MKV] [277,82 Mb]*0*9/13/2020 8:35:32 PM",
    "The 100 - 7x13.mkv*291311740*9/10/2020 12:14:41 PM",
    291311740,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\The Haves and the Have Nots - [Temp 7] [Cap-11] [MKV] [200,36 Mb]*0*9/13/2020 8:35:55 PM",
    "The Haves and the Have Nots - 7x11.mkv*210097056*9/9/2020 3:16:56 AM",
    210097056,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\The Haves and the Have Nots - [Temp 7] [Cap-12] [MKV] [217,31 Mb]*0*9/13/2020 8:36:14 PM",
    "The Haves and the Have Nots - 7x12.mkv*227864715*9/9/2020 1:33:22 PM",
    227864715,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\The Haves and the Have Nots - [Temp 7] [Cap-13] [MKV] [219,24 Mb]*0*9/13/2020 8:37:25 PM",
    "The Haves and the Have Nots - 7x13.mkv*229892082*9/8/2020 7:07:51 PM",
    229892082,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\The Haves and the Have Nots - [Temp 7] [Cap-14] [MKV] [215,46 Mb]*0*9/13/2020 8:37:37 PM",
    "The Haves and the Have Nots - 7x14.mkv*225930976*9/8/2020 7:05:51 PM",
    225930976,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\The Haves And The Have Nots - [Temp 7] [Cap-14] [MP4] [150,11 Mb]*0*9/13/2020 8:37:38 PM",
    "The Haves And The Have Nots - 7x14.mp4*157401888*9/9/2020 2:03:47 PM",
    157401888,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\The Heights AU - [Temp 2] [Cap-27] [MKV] [212,73 Mb]*0*9/13/2020 8:37:41 PM",
    "The Heights AU - 2x27.mkv*223068743*9/10/2020 12:01:17 PM",
    223068743,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\The Sounds - [Temp 1] [Cap-03] [MKV] [282,8 Mb]*0*9/13/2020 8:38:11 PM",
    "The Sounds - 1x03.mkv*296533039*9/6/2020 4:52:31 PM",
    296533039,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Two Weeks To Live - [Temp 1] [Cap-1] [MP4]*0*9/13/2020 8:38:22 PM",
    "Two Weeks To Live - 1x1.mp4*187588038*9/5/2020 2:40:47 PM",
    "Two Weeks To Live 1.jpg*49840*9/5/2020 12:31:56 PM",
    187637878,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Two Weeks To Live - [Temp 1] [Cap-2] [MP4]*0*9/13/2020 8:38:24 PM",
    "Two Weeks To Live - 1x2.mp4*187996603*9/5/2020 1:52:21 PM",
    "Two Weeks To Live 1.jpg*49840*9/5/2020 12:31:56 PM",
    188046443,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Two Weeks To Live - [Temp 1] [Cap-3] [MP4]*0*9/13/2020 8:38:29 PM",
    "Two Weeks To Live - 1x3.mp4*179164825*9/5/2020 1:47:45 PM",
    "Two Weeks To Live 1.jpg*49840*9/5/2020 12:31:56 PM",
    179214665,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Two Weeks To Live - [Temp 1] [Cap-4] [MP4]*0*9/13/2020 8:38:31 PM",
    "Two Weeks To Live - 1x4.mp4*185093332*9/5/2020 2:52:26 PM",
    "Two Weeks To Live 1.jpg*49840*9/5/2020 12:31:56 PM",
    185143172,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Two Weeks To Live - [Temp 1] [Cap-5] [MP4]*0*9/13/2020 8:38:37 PM",
    "Two Weeks To Live - 1x5.mp4*185269765*9/5/2020 2:44:03 PM",
    "Two Weeks To Live 1.jpg*49840*9/5/2020 12:31:56 PM",
    185319605,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Two Weeks To Live - [Temp 1] [Cap-6] [MP4]*0*9/13/2020 9:04:53 PM",
    "Two Weeks To Live - 1x6.mp4*190638923*9/5/2020 2:48:21 PM",
    "Two Weeks To Live 1.jpg*49840*9/5/2020 12:31:56 PM",
    190688763,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\United We Fall - [Temp 1] [Cap-08] [MP4]*0*9/13/2020 9:04:53 PM",
    "United We Fall - 1x08.mp4*182777515*9/2/2020 7:21:06 PM",
    182777515,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Wentworth - [Temp 8] [Cap-07] [MP4] [346,99 Mb]*0*9/13/2020 11:27:31 PM",
    "Wentworth - 8x07.mp4*363845276*9/9/2020 3:07:27 AM",
    363845276,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Woke - [Temp 1] [Cap-01] [MP4] [248,6 Mb]*0*9/13/2020 9:04:54 PM",
    "Woke - 1x01.mp4*260677818*9/10/2020 2:19:12 AM",
    260677818,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Woke - [Temp 1] [Cap-02] [MP4] [237,79 Mb]*0*9/13/2020 9:04:56 PM",
    "Woke - 1x02.mp4*249340451*9/8/2020 8:07:01 PM",
    249340451,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Woke - [Temp 1] [Cap-03] [MP4] [246,25 Mb]*0*9/13/2020 9:04:58 PM",
    "Woke - 1x03.mp4*258216974*9/8/2020 8:07:39 PM",
    258216974,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Woke - [Temp 1] [Cap-04] [MP4] [206,25 Mb]*0*9/13/2020 9:05:00 PM",
    "Woke - 1x04.mp4*216271174*9/8/2020 8:19:21 PM",
    216271174,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Woke - [Temp 1] [Cap-05] [MP4] [224,42 Mb]*0*9/13/2020 9:05:02 PM",
    "Woke - 1x05.mp4*235317001*9/8/2020 8:20:01 PM",
    235317001,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Woke - [Temp 1] [Cap-06] [MP4] [202,33 Mb]*0*9/13/2020 10:28:17 PM",
    "Woke - 1x06.mp4*212163372*9/8/2020 8:28:02 PM",
    212163372,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Woke - [Temp 1] [Cap-07] [MP4] [223,52 Mb]*0*9/13/2020 9:05:04 PM",
    "Woke - 1x07.mp4*234373585*9/8/2020 8:28:00 PM",
    234373585,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX]\\Woke - [Temp 1] [Cap-08] [MP4] [254,85 Mb]*0*9/13/2020 9:05:06 PM",
    "Woke - 1x08.mp4*267226850*9/8/2020 8:40:12 PM",
    267226850,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX] Temporada Finalizada [Cap Sueltos]*0*9/13/2020 9:05:23 PM",
    0,
    "1312*1313*1314*1315"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX] Temporada Finalizada [Cap Sueltos]\\Dark (Temporada 2) [8 Cap.] FDT*0*9/13/2020 11:16:11 PM",
    "DARK - 2x1.mp4*301002601*9/10/2020 4:41:08 PM",
    "DARK - 2x1.srt*25799*6/28/2020 5:00:00 PM",
    "DARK - 2x2.mp4*306949267*9/10/2020 4:41:11 PM",
    "DARK - 2x2.srt*27725*6/28/2020 5:00:00 PM",
    "DARK - 2x3.mp4*314538498*9/10/2020 4:41:16 PM",
    "DARK - 2x3.srt*35074*6/28/2020 5:00:00 PM",
    "DARK - 2x4.mp4*339648854*9/10/2020 4:41:21 PM",
    "DARK - 2x4.srt*25405*6/28/2020 5:00:00 PM",
    "DARK - 2x5.mp4*315259638*9/10/2020 4:41:25 PM",
    "DARK - 2x5.srt*31177*6/28/2020 5:00:00 PM",
    "DARK - 2x6.mp4*303760182*9/10/2020 4:41:29 PM",
    "DARK - 2x6.srt*29079*6/28/2020 5:00:00 PM",
    "DARK - 2x7.mp4*327281290*9/10/2020 4:41:34 PM",
    "DARK - 2x7.srt*32451*6/28/2020 5:00:00 PM",
    "DARK - 2x8.mp4*357092455*9/10/2020 4:41:41 PM",
    "DARK - 2x8.srt*28519*6/28/2020 5:00:00 PM",
    2565768014,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX] Temporada Finalizada [Cap Sueltos]\\Luther - [Temp 5] [Caps.04]*0*9/13/2020 10:19:21 PM",
    "S05E01.mkv*1159426320*9/1/2020 10:22:14 PM",
    "S05E02.mkv*1069560452*9/1/2020 10:26:13 PM",
    "S05E03.mkv*1139180583*9/1/2020 10:30:03 PM",
    "S05E04.mkv*1111721646*9/1/2020 10:32:38 PM",
    4479889001,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX] Temporada Finalizada [Cap Sueltos]\\Sex Next Door (Temporada 1) [8 Cap.] FDT*0*9/13/2020 10:28:19 PM",
    "Sex Next Door 1x01 [Sub].mkv*213832273*8/31/2020 9:08:24 PM",
    "Sex Next Door 1x02 [Sub].mkv*163617068*8/31/2020 9:08:10 PM",
    "Sex Next Door 1x03 [Sub].mkv*210475679*8/31/2020 9:08:19 PM",
    "Sex Next Door 1x04 [Sub].mkv*174603766*8/31/2020 9:07:59 PM",
    "Sex Next Door 1x05 [Sub].mkv*140790847*8/31/2020 9:07:58 PM",
    "Sex Next Door 1x06 [Sub].mkv*202685704*8/31/2020 9:07:46 PM",
    "Sex Next Door 1x07 [Sub].mkv*217098310*8/31/2020 9:07:46 PM",
    "Sex Next Door 1x08 [Sub].mkv*231944648*8/31/2020 9:07:30 PM",
    "Sex-Next-Door.jpg*75157*8/31/2020 9:07:13 PM",
    1555123452,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX] Temporada Finalizada [Cap Sueltos]\\The Flash (Temporada 1) [23 Cap.] [1080p] [Dual Audio] FDT*0*9/13/2020 11:27:07 PM",
    "The Flash S01E011080p Dual.mkv*436512364*8/22/2020 7:41:03 AM",
    "The Flash S01E021080p Dual.mkv*432735671*8/22/2020 7:42:51 AM",
    "The Flash S01E031080p Dual.mkv*432674774*8/22/2020 7:42:58 AM",
    "The Flash S01E041080p Dual.mkv*432524816*8/22/2020 7:45:42 AM",
    "The Flash S01E051080p Dual.mkv*432502631*8/22/2020 7:45:38 AM",
    "The Flash S01E061080p Dual.mkv*432658967*8/22/2020 7:45:42 AM",
    "The Flash S01E071080p Dual.mkv*432508670*8/22/2020 7:45:40 AM",
    "The Flash S01E081080p Dual.mkv*432742271*8/22/2020 7:49:12 AM",
    "The Flash S01E091080p Dual.mkv*435533001*8/22/2020 7:49:03 AM",
    "The Flash S01E101080p Dual.mkv*432692458*8/22/2020 7:49:04 AM",
    "The Flash S01E111080p Dual.mkv*432551100*8/22/2020 7:49:04 AM",
    "The Flash S01E121080p Dual.mkv*432380292*8/22/2020 7:49:18 AM",
    "The Flash S01E131080p Dual.mkv*432819316*8/22/2020 7:52:29 AM",
    "The Flash S01E141080p Dual.mkv*432518936*8/22/2020 7:52:27 AM",
    "The Flash S01E151080p Dual.mkv*431991650*8/22/2020 7:52:29 AM",
    "The Flash S01E161080p Dual.mkv*432432562*8/22/2020 7:52:30 AM",
    "The Flash S01E171080p Dual.mkv*432930917*8/22/2020 7:52:25 AM",
    "The Flash S01E181080p Dual.mkv*432511879*9/29/2019 9:24:12 PM",
    "The Flash S01E191080p Dual.mkv*432820592*8/22/2020 7:58:28 AM",
    "The Flash S01E201080p Dual.mkv*432802280*8/22/2020 7:57:14 AM",
    "The Flash S01E211080p Dual.mkv*432685191*8/22/2020 7:55:50 AM",
    "The Flash S01E221080p Dual.mkv*432913039*8/22/2020 7:55:47 AM",
    "The Flash S01E231080p Dual.mkv*436690348*8/22/2020 7:59:41 AM",
    9961133725,
    "1316"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX] Temporada Finalizada [Cap Sueltos]\\The Flash (Temporada 1) [23 Cap.] [1080p] [Dual Audio] FDT\\Subs Srt*0*9/13/2020 9:05:58 PM",
    "The Flash (2014) 1x01 - Pilot (Español (Latinoamérica)).srt*52934*10/13/2019 11:45:08 PM",
    "The Flash (2014) 1x02 - Fastest Man Alive (Español (Latinoamérica)).srt*58609*10/13/2019 11:45:17 PM",
    "The Flash (2014) 1x03 - Things You Can't Outrun (Español (Latinoamérica)).srt*51749*10/13/2019 11:45:23 PM",
    "The Flash 2014 1x12 - Crazy For You (Español (Latinoamérica)).srt*55341*2/4/2015 7:04:14 PM",
    "The Flash 2014 1x18 - All Star team Up (Español (España)).srt*62959*4/15/2015 5:57:54 PM",
    "The Flash 2014 1x20 - The Trap  (Español (Latinoamérica)).srt*59748*4/29/2015 3:55:14 PM",
    "The Flash 2014 1x21 - Grodd Lives (Español (Latinoamérica)).srt*50222*5/6/2015 11:46:44 AM",
    "The Flash 2014 1x22 - Rogue Air (Español (Latinoamérica)).srt*53894*5/13/2015 10:22:36 AM",
    "The Flash 2014 1x23 - Fast Enough (Español (Latinoamérica)).WEB-DL.YFN.srt*53547*5/20/2015 12:26:02 PM",
    "The.Flash.2014.S01E04.Going.Rogue.1080p.WEB-DL.DD5.1.H.264-NTb.srt*57223*10/29/2014 7:10:19 PM",
    "The.Flash.2014.S01E05.Plastique.1080p.WEB-DL.DD5.1.H.264-NTb.srt*51908*11/14/2014 11:59:37 PM",
    "The.Flash.2014.S01E06.The.Flash.is.Born.1080p.WEB-DL.DD5.1.H.264-NTb.srt*54609*11/22/2014 12:11:31 AM",
    "The.Flash.2014.S01E07.Power.Outage.1080p.WEB-DL.DD5.1.H.264-NTb.srt*49441*12/2/2014 10:57:21 AM",
    "The.Flash.2014.S01E08.Flash.vs.Arrow.1080p.WEB-DL.DD5.1.AAC2.0.H.264-YFN.srt*57320*12/4/2014 12:02:12 AM",
    "The.Flash.2014.S01E09.The.Man.in.the.Yellow.Suit.1080p.WEB-DL.DD5.1.H.264-YFN.srt*46787*12/15/2014 7:48:26 PM",
    "The.Flash.2014.S01E10.1080p.WEB-DL.DD5.1.H.264-NTb.srt*51049*1/22/2015 9:37:40 PM",
    "The.Flash.2014.S01E11.1080p.WEB-DL.DD5.1.H.264-YFN.srt*54777*1/30/2015 2:58:23 AM",
    "The.Flash.2014.S01E13.The.Nuclear.Man.720-1080p.WEB-DL.DD5.1.H.264-NTb.srt*58362*2/11/2015 10:16:14 PM",
    "The.Flash.2014.S01E14.1080p.WEB-DL.DD5.1.H.264-NTb.srt*54129*2/21/2015 2:10:46 AM",
    "The.Flash.2014.S01E15.Out.of.Time.1080p.WEB-DL.DD5.1.H.264-NTb.srt*54166*3/27/2015 1:07:40 AM",
    "The.Flash.2014.S01E16.Rogue.Time.1080p.WEB-DL.DD5.1.H.264-NTb.srt*55502*4/4/2015 5:00:06 PM",
    "The.Flash.2014.S01E17.Tricksters.1080p.WEB-DL.DD5.1.H.264-NTb.srt*49445*4/11/2015 5:21:20 PM",
    "The.Flash.2014.S01E19.Who.Is.Harrison.Wells.1080p.WEB-DL.DD5.1.H.264-NTb.srt*55769*4/26/2015 1:30:06 AM",
    1249490,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]*0*9/13/2020 10:16:16 PM",
    0,
    "1318*1319*1320*1321*1322*1323*1324*1325*1326*1327*1328*1329*1330*1331*1332*1333*1334*1335"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\ Marvel shield agents3x11*0*9/13/2020 9:56:17 PM",
    "3x11.mpg*326175724*3/10/2016 4:29:26 PM",
    326175724,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\ Marvel shield agents3x12*0*9/13/2020 9:56:22 PM",
    "3x12.mpg*325529652*3/17/2016 9:07:36 AM",
    325529652,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\49 ER - [Temp 3] [Cap-02] [AVI]*0*9/13/2020 9:56:30 PM",
    "49 ER - 3x02.avi*388240374*2/23/2007 10:01:38 PM",
    "49 ER - 3x02.srt*57869*12/12/2008 3:14:24 PM",
    388298243,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\50 ER - [Temp 3] [Cap-03] [AVI]*0*9/13/2020 11:22:53 PM",
    "50 ER - 3x03.avi*377916488*2/23/2007 10:59:24 PM",
    "50 ER - 3x03.srt*57293*2/26/2009 8:09:32 PM",
    377973781,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\Aid - [Temp 10] [Cap-28] [AVI]*0*9/13/2020 9:56:35 PM",
    "Aid - 10x28.avi*575104124*3/24/2014 10:25:11 AM",
    575104124,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\Aid - [Temp 10] [Cap-29] [AVI]*0*9/13/2020 11:13:32 PM",
    "Aid - 10x29.avi*733892153*3/31/2014 4:12:57 PM",
    733892153,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\Brothers And Sisters - [Temp 1] [Cap-03] [AVI] [350,19 Mb]*0*9/13/2020 9:56:54 PM",
    "Brothers And Sisters - 1x03.avi*367138816*5/5/2011 7:49:12 AM",
    "Brothers And Sisters - 1x03.srt*67180*11/3/2006 10:17:10 PM",
    367205996,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\Brothers And Sisters - [Temp 1] [Cap-04] [AVI] [347,77 Mb]*0*9/13/2020 10:15:48 PM",
    "Brothers And Sisters - 1x04.avi*364591104*5/5/2011 2:56:22 PM",
    "Brothers And Sisters - 1x04.srt*69456*11/6/2006 11:20:40 PM",
    364660560,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\CSI - [Temp 9] [Cap-11] [AVI]*0*9/13/2020 10:15:52 PM",
    "CSI - 9x11.avi*367451288*1/25/2009 12:06:05 AM",
    "CSI - 9x11.srt*42412*1/24/2009 5:20:26 PM",
    367493700,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\CSI - [Temp 9] [Cap-12] [AVI]*0*9/13/2020 10:15:58 PM",
    "CSI - 9x12.avi*365557058*1/31/2009 8:06:08 AM",
    "CSI - 9x12.srt*44874*1/31/2009 6:38:22 AM",
    365601932,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\CSI New York - [Temp 3] [Cap-02] [AVI]*0*9/13/2020 10:16:02 PM",
    "CSI New York - 3x02.avi*366141440*9/27/2006 7:01:16 AM",
    "CSI New York - 3x02.srt*60044*5/26/2007 2:36:22 PM",
    366201484,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\CSI New York - [Temp 3] [Cap-03] [AVI]*0*9/13/2020 10:16:06 PM",
    "CSI New York - 3x03.avi*364513280*10/11/2006 1:43:48 AM",
    "CSI New York - 3x03.srt*57685*5/26/2007 2:37:18 PM",
    364570965,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\Man Seeking Woman - [Temp 3] [Cap-05] [MKV] [125,37 Mb]*0*9/13/2020 10:26:03 PM",
    "Man Seeking Woman - 3x05.mkv*131432611*2/1/2017 11:33:48 PM",
    "Man Seeking Woman - 3x05.srt*27017*2/6/2017 2:26:12 AM",
    131459628,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\Man Seeking Woman - [Temp 3] [Cap-06] [MP4] [126,99 Mb]*0*9/13/2020 10:26:04 PM",
    "Man Seeking Woman - 3x06.mp4*133125841*2/8/2017 11:49:06 PM",
    "Man Seeking Woman - 3x06.srt*29311*2/11/2017 12:40:01 AM",
    133155152,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\The Sentinel - [Temp 1] [Cap-09] [AVI]*0*9/13/2020 10:16:08 PM",
    "The Sentinel - 1x09.avi*366354432*8/27/2008 2:36:46 AM",
    366354432,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\The Sentinel - [Temp 1] [Cap-10] [AVI]*0*9/13/2020 10:16:12 PM",
    "The Sentinel - 1x10.avi*366356480*8/27/2008 3:10:44 AM",
    366356480,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\Two &amp; a half men - [Temp 6] [Cap-08] [AVI]*0*9/13/2020 11:26:46 PM",
    "Two &amp; a half men - 6x08.avi*182172278*11/24/2008 3:20:34 AM",
    "Two &amp; a half men - 6x08.srt*25472*11/20/2008 4:38:28 PM",
    182197750,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series [TX Clásicas]\\Two &amp; a half Men - [Temp 6] [Cap-09] [AVI]*0*9/13/2020 11:33:51 PM",
    "Two &amp; a half Men - 6x09.avi*182455538*4/20/2009 10:27:14 AM",
    "Two &amp; a half Men - 6x09.srt*24495*11/27/2008 2:53:44 AM",
    182480033,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series Mangas [TX]*0*9/13/2020 9:51:49 PM",
    0,
    "1337*1339*1340"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series Mangas [TX]\\Filmes Estreno*0*9/13/2020 9:01:19 PM",
    0,
    "1338"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series Mangas [TX]\\Filmes Estreno\\Digimon Adventure - Last Evolution Kizuna [2020] [1,1 Gb]*0*9/13/2020 9:01:29 PM",
    "Digimon Adventure - Last Evolution Kizuna [2020] [1080p].ass*94686*9/8/2020 4:48:26 PM",
    "Digimon Adventure - Last Evolution Kizuna [2020] [1080p].mp4*1178587629*9/5/2020 5:50:33 PM",
    "Digimon Adventure - Last Evolution Kizuna [2020] [1080p].png*395840*9/5/2020 5:43:07 PM",
    1179078155,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series Mangas [TX]\\Series Mangas [TX]*0*9/13/2020 11:33:26 PM",
    "Ahiru No Sora Episodio 47.mp4*195821567*9/9/2020 10:07:22 PM",
    "Almanac Master Episodio 8 Sub Español.mp4*379602832*9/3/2020 10:08:32 PM",
    "Black Clover (TV) Episodio 142.mp4*196077651*9/8/2020 9:58:19 PM",
    "Combat Continent Episodio 120 Sub Español.mp4*328577000*9/7/2020 9:13:18 PM",
    "Deca-Dence Episodio 10.mp4*199461577*9/9/2020 10:18:58 PM",
    "Fighter Of The Destiny 3 Episodio 2 Sub.mp4*306882928*9/6/2020 8:06:39 PM",
    "Fog Hill Of Five Elements Episodio 3 Sub Español.mp4*321017896*9/6/2020 8:08:57 PM",
    "Fruits Basket 2 x Episodio 23.mp4*199302879*9/8/2020 10:13:14 AM",
    "Gibiate Episodio 9.mp4*201871727*9/10/2020 2:17:30 AM",
    "Honkai Impact 3rd- Cooking With Valkyries 2 Episodio 5 Sub Español.mp4*117331740*9/3/2020 10:07:15 PM",
    "Houkago Teibou Nisshi Episodio 10.mp4*199422762*9/8/2020 9:04:38 PM",
    "I Will Eternal Episodio 4 Sub.mp4*287060772*9/6/2020 8:05:22 PM",
    "Jewelpet Twingle Episodio 12.mp4*225281997*9/10/2020 10:46:55 AM",
    "Koi To Producer- Evol×Love Episodio 9.mp4*201140218*9/10/2020 2:47:49 AM",
    "Martial Master Episodio 54 Sub Español.mp4*120317744*9/4/2020 6:26:47 PM",
    "Muhyo To Rouji No Mahouritsu Soudan Jimusho 2x Episodio 10.mp4*199750999*9/8/2020 10:05:12 PM",
    "Olympia Kyklos - 16.mp4*49950971*9/7/2020 8:03:47 PM",
    "One Step Toward Freedom Episodio 21 Sub.mp4*13034911*9/6/2020 8:09:57 PM",
    "Re-Zero kara Hajimeru Isekai Seikatsu - Temp 2 Episodio 10.mp4*202914398*9/10/2020 2:41:57 AM",
    "Shadowverse (TV) Episodio 22.mp4*194671733*9/8/2020 10:01:42 PM",
    "Song Of Taoists And Fairies Episodio 14 Sub Español.mp4*135045476*9/6/2020 8:03:03 PM",
    "Spiritual Master Episodio 19 Sub.mp4*174340672*9/3/2020 10:06:18 PM",
    "Spiritual Master Episodio 20 Sub Español.mp4*176086628*9/6/2020 8:03:56 PM",
    "Supreme God Episodio 34 Sub Español.mp4*24643205*9/6/2020 8:10:07 PM",
    "Tales Of Demons And Gods 4 Episodio 44 Sub.mp4*51393372*9/2/2020 8:19:30 PM",
    "Tales Of Demons And Gods 4 Episodio 45 Sub.mp4*127425648*9/6/2020 8:02:30 PM",
    "The Founder Of Diabolism Q Episodio 6 Sub.mp4*81266384*9/6/2020 8:09:20 PM",
    "The God Of High School 10 Sub.mp4*158561590*9/7/2020 9:12:34 PM",
    "Umayon Episodio 10.mp4*25925608*9/8/2020 9:54:54 PM",
    "Wan Jie Chun Qiu Episodio 41 Sub Español.mp4*130323480*9/4/2020 6:13:29 PM",
    "Wings Of The World Episodio 6 Sub.mp4*362241972*9/6/2020 8:07:49 PM",
    "Wu Geng Ji 3 Episodio 35 Sub.mp4*325271772*9/4/2020 6:26:13 PM",
    "Yu☆gi☆oh!- Sevens 14 Sub.mp4*369659705*9/7/2020 8:02:38 PM",
    6281679814,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series Mangas [TX]\\Series Mangas X Temporada Finalizadas [Capitulos]*0*9/13/2020 9:02:29 PM",
    0,
    "1341*1342"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series Mangas [TX]\\Series Mangas X Temporada Finalizadas [Capitulos]\\Baki La saga del gran torneo de Raitai - [Temp 3] [Caps.13]*0*9/13/2020 11:27:22 PM",
    "S03E01.mkv*530824420*7/31/2020 9:47:22 PM",
    "S03E02.mkv*508766066*7/31/2020 9:49:53 PM",
    "S03E03.mkv*530994402*7/31/2020 9:53:25 PM",
    "S03E04.mkv*530970983*7/31/2020 9:57:49 PM",
    "S03E05.mkv*530998263*7/31/2020 9:59:36 PM",
    "S03E06.mkv*530881688*7/31/2020 10:02:36 PM",
    "S03E07.mkv*530630733*7/31/2020 10:04:57 PM",
    "S03E08.mkv*530880659*7/31/2020 10:07:00 PM",
    "S03E09.mkv*530661952*7/31/2020 10:08:44 PM",
    "S03E10.mkv*530937804*7/31/2020 10:12:03 PM",
    "S03E11.mkv*530818315*7/31/2020 10:14:04 PM",
    "S03E12.mkv*530976723*7/31/2020 10:16:36 PM",
    "S03E13.mkv*530427308*7/31/2020 10:18:19 PM",
    6878769316,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series Mangas [TX]\\Series Mangas X Temporada Finalizadas [Capitulos]\\Yama no Susume [Temporada 1] [720p] [Latino]*0*9/13/2020 11:19:32 PM",
    "!.jpg*378269*9/7/2020 5:38:43 PM",
    "Yama no Susume - 1x01 [Latino].mkv*32524438*9/7/2020 12:20:40 PM",
    "Yama no Susume - 1x02 [Latino].mkv*30272258*9/7/2020 12:20:10 PM",
    "Yama no Susume - 1x03 [Latino].mkv*32819321*9/7/2020 12:20:08 PM",
    "Yama no Susume - 1x04 [Latino].mkv*29204097*9/7/2020 12:20:23 PM",
    "Yama no Susume - 1x05 [Latino].mkv*32481229*9/7/2020 12:20:17 PM",
    "Yama no Susume - 1x06 [Latino].mkv*32120527*9/7/2020 12:20:20 PM",
    "Yama no Susume - 1x07 [Latino].mkv*28812882*9/7/2020 12:20:19 PM",
    "Yama no Susume - 1x08 [Latino].mkv*33919256*9/7/2020 12:20:36 PM",
    "Yama no Susume - 1x09 [Latino].mkv*32041638*9/7/2020 12:20:09 PM",
    "Yama no Susume - 1x10 [Latino].mkv*33075940*9/7/2020 12:20:05 PM",
    "Yama no Susume - 1x11 [Latino].mkv*31026613*9/7/2020 12:20:41 PM",
    "Yama no Susume - 1x12 [Latino].mkv*37220933*9/7/2020 12:32:34 PM",
    385897401,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]*0*9/13/2020 9:53:04 PM",
    0,
    "1344*1345*1346*1347*1348*1349*1350*1351*1352*1353*1354*1355*1356*1357*1358*1359*1360*1361*1362*1363*1364*1365*1366*1367*1368*1369*1370*1371*1372*1373*1374*1375*1376*1377*1378*1379*1380*1381*1382*1383*1384*1385*1386*1387*1388*1389*1390*1391*1392*1393*1394*1395*1396*1397*1398*1399*1400*1401*1402*1403*1404*1405*1406*1407*1408*1409*1410*1411*1412*1413*1414*1415*1416*1417*1418*1419*1420*1421*1422*1423*1424*1425*1426*1427*1428*1429*1430*1431*1432*1433*1434*1435*1436*1437*1438*1439*1440*1441*1442*1443*1444*1445*1446*1447*1448*1449*1450*1451*1452*1453*1454*1455*1456*1457"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\100 argentinos dicen [04-09-20] [316,8 Mb]*0*9/13/2020 11:13:18 PM",
    "100 argentinos dicen [04-09-20].mkv*332191300*9/4/2020 8:03:22 AM",
    332191300,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\100 argentinos dicen [05-09-20] [312,1 Mb]*0*9/13/2020 9:52:33 PM",
    "100 argentinos dicen [05-09-20].mkv*327257144*9/4/2020 8:29:00 AM",
    327257144,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\100 argentinos dicen [06-09-20] [199,67 Mb]*0*9/13/2020 9:52:38 PM",
    "100 argentinos dicen [06-09-20].mkv*209369406*9/5/2020 12:44:23 AM",
    209369406,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\100 Argentinos Dicen [08-09-20] [201,51 Mb]*0*9/13/2020 9:52:40 PM",
    "100 Argentinos Dicen [08-09-20].mkv*211297024*9/7/2020 11:36:41 PM",
    211297024,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Adivina Qué Hago Esta Noche [03 09 20] [661,17 Mb]*0*9/13/2020 11:19:40 PM",
    "Adivina Qué Hago Esta Noche [03 09 20].mp4*693283088*9/4/2020 1:53:48 AM",
    693283088,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Ahora Caigo - 1918 [08-09-20] [352,73 Mb]*0*9/13/2020 9:52:43 PM",
    "Ahora Caigo - 1918 [08-09-20].mkv*369867752*9/8/2020 12:16:49 AM",
    369867752,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Ahora Caigo 1914 [06-09-20] [345,26 Mb]*0*9/13/2020 9:52:46 PM",
    "Ahora Caigo 1914 [06-09-20].mkv*362029925*9/4/2020 9:46:40 PM",
    362029925,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Almorzando con Mirtha Legrand [06 09 20] [509,2 Mb]*0*9/13/2020 11:19:50 PM",
    "Almorzando con Mirtha Legrand [06 09 20].mp4*533929946*9/5/2020 3:57:11 PM",
    533929946,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Aqui La Tierra [08-09-20] [215,92 Mb]*0*9/13/2020 9:52:50 PM",
    "Aqui La Tierra [08-09-20].ts*226403888*9/7/2020 8:49:02 PM",
    226403888,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Atrapame Si Puedes - 46 [08-09-20] [191,67 Mb]*0*9/13/2020 10:33:36 PM",
    "Atrapame Si Puedes - 46 [08-09-20].mkv*200980110*9/8/2020 3:54:08 AM",
    200980110,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Atrapame Si Puedes - 47 [08-09-20] [212,71 Mb]*0*9/13/2020 9:52:58 PM",
    "Atrapame Si Puedes - 47 [08-09-20].mkv*223041356*9/8/2020 4:09:40 AM",
    223041356,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Atrapame Si Puedes - 49 [08-09-20] [188,79 Mb]*0*9/13/2020 10:23:51 PM",
    "Atrapame Si Puedes - 49 [08-09-20].mkv*197962937*9/8/2020 4:38:14 AM",
    197962937,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Atrapame Si Puedes - 50 [08-09-20] [189,87 Mb]*0*9/13/2020 9:53:00 PM",
    "Atrapame Si Puedes - 50 [08-09-20].mkv*199091849*9/8/2020 4:51:45 AM",
    199091849,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Atrápame si puedes - 52 [09-09-20] [147,79 Mb]*0*9/13/2020 9:53:03 PM",
    "Atrápame si puedes - 52 [09-09-20].mkv*154972303*9/8/2020 11:21:20 PM",
    154972303,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Atrápame si puedes - 53 [09-09-20] [127,44 Mb]*0*9/13/2020 9:53:04 PM",
    "Atrápame si puedes - 53 [09-09-20].mkv*133635727*9/8/2020 11:30:37 PM",
    133635727,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Atrápame si puedes - 54 [09-09-20] [140,55 Mb]*0*9/13/2020 9:53:06 PM",
    "Atrápame si puedes - 54 [09-09-20].mkv*147373793*9/8/2020 11:40:41 PM",
    147373793,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Atrápame si puedes - 55 [09-09-20] [126,42 Mb]*0*9/13/2020 9:26:36 PM",
    "Atrápame si puedes - 55 [09-09-20].mkv*132561352*9/8/2020 11:49:29 PM",
    132561352,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Atrápame si puedes - 56 [09-09-20] [198,42 Mb]*0*9/13/2020 9:26:39 PM",
    "Atrápame si puedes - Programa 56.mkv*207693714*9/10/2020 11:49:30 PM",
    207693714,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Atrápame si puedes - 57 [09-09-20] [199,42 Mb]*0*9/13/2020 9:26:43 PM",
    "Atrápame si puedes - Programa 57.mkv*209020676*9/11/2020 12:03:25 AM",
    209020676,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Bake off Reino unido [10-09-20]*0*9/13/2020 9:26:47 PM",
    "Bake off Reino unido [10-09-20].mp4*1672603476*8/23/2020 12:32:17 PM",
    1672603476,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Bienvenidos a bordo [07 09 20] [394,18 Mb]*0*9/13/2020 10:29:51 PM",
    "Bienvenidos a bordo [07 09 20].mp4*413329714*9/6/2020 6:31:54 PM",
    413329714,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Bienvenidos a Bordo  [08-09-20] [270,19 Mb]*0*9/13/2020 10:29:48 PM",
    "Bienvenidos a Bordo  [08-09-20].mkv*283313795*9/8/2020 5:42:48 AM",
    283313795,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Bienvenidos a bordo 2 [08 09 20] [632,29 Mb]*0*9/13/2020 9:27:02 PM",
    "Bienvenidos a bordo 2 [08 09 20].mp4*663000348*9/7/2020 4:52:04 PM",
    663000348,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Bloqueados por el Muro 1 [08 09 20] [377,6 Mb]*0*9/13/2020 9:27:18 PM",
    "Bloqueados por el Muro 1 [08 09 20].mp4*395940071*9/7/2020 2:44:50 PM",
    395940071,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Bloqueados por el Muro 2 [08 09 20] [382,97 Mb]*0*9/13/2020 9:27:23 PM",
    "Bloqueados por el Muro 2 [08 09 20].mp4*401577849*9/7/2020 2:36:40 PM",
    401577849,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Boom  [08-09-20] [193 Mb]*0*9/13/2020 9:27:28 PM",
    "Boom  [08-09-20].mkv*202376938*9/8/2020 12:17:12 AM",
    202376938,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Cake boss [10-09-20]*0*9/13/2020 11:28:13 PM",
    "Cake boss [10-09-20].mp4*1362794328*8/23/2020 12:14:47 PM",
    1362794328,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Canadas Drag Race - [Temp 1] [Cap-10] [MPG] [586,08 Mb]*0*9/13/2020 9:27:33 PM",
    "Canadas Drag Race - 1x10.mpg*614795264*9/10/2020 12:17:29 AM",
    614795264,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Cantando 2020 - [Temp 1] [Cap-28] [MP4] [538,53 Mb]*0*9/13/2020 9:27:39 PM",
    "Cantando 2020 - 1x28.mp4*564687726*9/6/2020 7:10:41 PM",
    564687726,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Cantando 2020 - [Temp 1] [Cap-29] [MPG] [594,01 Mb]*0*9/13/2020 9:27:47 PM",
    "Cantando 2020 - 1x29.mpg*622964736*9/10/2020 12:14:16 AM",
    622964736,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Cantando 2020 - [Temp 1] [Cap-30] [MP4] [576,66 Mb]*0*9/13/2020 9:27:54 PM",
    "Cantando 2020 - 1x30.mp4*604669885*9/8/2020 9:25:17 PM",
    604669885,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Catfish The Tv Show, I , 2020 [07-09-20] [994,21 Mb]*0*9/13/2020 10:21:31 PM",
    "Catfish The Tv Show, I , 2020 [07-09-20].mpg*1042500217*9/6/2020 7:28:24 PM",
    1042500217,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Catfish The Tv Show, II , 2020 [07-09-20] [980,75 Mb]*0*9/13/2020 9:28:02 PM",
    "Catfish The Tv Show, II , 2020 [07-09-20].mpg*1028392939*9/6/2020 7:31:31 PM",
    1028392939,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Chefs Table - [Temp 7] [Cap-01] [MKV] [378,29 Mb]*0*9/13/2020 9:28:12 PM",
    "Chefs Table - 7x01.mkv*396664251*9/2/2020 7:41:44 PM",
    396664251,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Chefs Table - [Temp 7] [Cap-02] [MKV] [492,48 Mb]*0*9/13/2020 11:21:42 PM",
    "Chefs Table - 7x02.mkv*516405237*9/2/2020 7:42:45 PM",
    516405237,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Chefs Table - [Temp 7] [Cap-03] [MKV] [468,07 Mb]*0*9/13/2020 9:28:19 PM",
    "Chefs Table - 7x03.mkv*490805624*9/2/2020 7:43:12 PM",
    490805624,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Chefs Table - [Temp 7] [Cap-04] [MKV] [588,04 Mb]*0*9/13/2020 9:28:25 PM",
    "Chefs Table - 7x04.mkv*616602744*9/2/2020 7:44:39 PM",
    616602744,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Cuarto Milenio - [Temp 15] [Cap-27] [TS] [1,4 Gb]*0*9/13/2020 9:28:32 PM",
    "Cuarto Milenio - 15x27.ts*1506551536*9/5/2020 3:15:56 PM",
    1506551536,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\De Férias como eX Brasil, Celebridades, Cap. 3, ESTRENO [08-09-20] [575,35 Mb]*0*9/13/2020 9:28:45 PM",
    "De Férias como eX Brasil, Celebridades, Cap. 3, ESTRENO [08-09-20].mpg*514252800*9/9/2020 1:36:56 PM",
    514252800,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\De Férias como eX Brasil, Celebridades, Cap. 4, ESTRENO [08-09-20] [582,48 Mb]*0*9/13/2020 9:28:51 PM",
    "De Férias como eX Brasil, Celebridades, Cap. 4, ESTRENO [08-09-20].mpg*610772678*9/8/2020 3:20:44 PM",
    610772678,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\EH, el Hormiguero, ESPECTACULAR Inicio, XV Temp. [08-09-20] [629,45 Mb]*0*9/13/2020 11:10:58 PM",
    "EH, el Hormiguero, ESPECTACULAR Inicio, XV Temp. [08-09-20].mpg*660025239*9/8/2020 3:22:29 PM",
    660025239,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\El bribón [06-09-20] [199,11 Mb]*0*9/13/2020 9:30:03 PM",
    "El bribón [06-09-20].ts*208780016*9/4/2020 10:02:53 PM",
    208780016,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\El Bribon [08-09-20] [209,7 Mb]*0*9/13/2020 9:29:00 PM",
    "El Bribon [08-09-20].ts*219885176*9/7/2020 10:39:24 PM",
    219885176,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\El Cazador 1 [08 09 20] [464,8 Mb]*0*9/13/2020 9:29:06 PM",
    "El Cazador 1 [08 09 20].mp4*487380993*9/7/2020 3:48:43 PM",
    487380993,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\El Cazador 2 [08 09 20] [364,37 Mb]*0*9/13/2020 9:29:11 PM",
    "El Cazador 2 [08 09 20].mp4*382070587*9/7/2020 3:39:24 PM",
    382070587,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\El gran premio de la cocina [06-09-20] [451,9 Mb]*0*9/13/2020 9:30:10 PM",
    "El gran premio de la cocina [06-09-20].mkv*473856071*9/4/2020 10:48:38 PM",
    473856071,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\El Gran Premio de La Cocina [08-09-20] [287,97 Mb]*0*9/13/2020 9:29:27 PM",
    "El Gran Premio de La Cocina [08-09-20].mkv*301958882*9/8/2020 12:32:08 AM",
    301958882,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\El gran premio de la cocina [09-09-20] [271,09 Mb]*0*9/13/2020 9:29:35 PM",
    "El gran premio de la cocina [09-09-20].mkv*284256062*9/8/2020 10:13:45 PM",
    284256062,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\El gran premio de la cocina 2 [08 09 20] [610,38 Mb]*0*9/13/2020 9:29:16 PM",
    "El gran premio de la cocina 2 [08 09 20].mp4*640025700*9/7/2020 5:03:15 PM",
    640025700,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\El reventonazo de la chola [06 09 20] [1,27 Gb]*0*9/13/2020 9:29:38 PM",
    "El reventonazo de la chola [06 09 20].mp4*1359465426*9/5/2020 5:47:23 PM",
    1359465426,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\El wasap de jb [06 09 20] [1,35 Gb]*0*9/13/2020 9:29:52 PM",
    "El wasap de jb [06 09 20].mp4*1444769389*9/5/2020 5:12:29 PM",
    1444769389,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\En boca de todos [07 09 20] [1,04 Gb]*0*9/13/2020 9:32:55 PM",
    "En boca de todos [07 09 20].mpg*1109692416*9/8/2020 7:19:32 PM",
    1109692416,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\En boca de todos 2 [08 09 20] [0,97 Gb]*0*9/13/2020 10:36:20 PM",
    "En boca de todos 2 [08 09 20].mpg*1050492928*9/9/2020 11:38:24 PM",
    1050492928,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Estas en todas [06 09 20] [1,34 Gb]*0*9/13/2020 10:21:50 PM",
    "Estas en todas [06 09 20].mp4*1439905205*9/5/2020 4:45:21 PM",
    1439905205,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Esto es guerra [07 09 20] [0,97 Gb]*0*9/13/2020 9:33:05 PM",
    "Esto es guerra [07 09 20].mpg*1051246592*9/8/2020 7:14:10 PM",
    1051246592,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Esto Es Guerra [08-09-20] [526,17 Mb]*0*9/13/2020 10:30:31 PM",
    "Esto Es Guerra [08-09-20].mp4*551725202*9/8/2020 3:40:15 AM",
    551725202,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Esto es guerra [08 09 20] [993,16 Mb]*0*9/13/2020 11:32:52 PM",
    "Esto es guerra [08 09 20].mpg*1042092032*9/9/2020 11:51:13 PM",
    1042092032,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Esto es guerra [09-09-20] [475,44 Mb]*0*9/13/2020 10:30:38 PM",
    "Esto es guerra [09-09-20].mp4*498536062*9/9/2020 4:10:23 AM",
    498536062,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Esto es guerra [09 09 20] [1,00 Gb]*0*9/13/2020 9:33:28 PM",
    "Esto es guerra [09 09 20].mpg*1075544064*9/10/2020 3:16:39 PM",
    1075544064,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\FUEGO VS FUEGO - [Temp 1] [Cap-01] [MP4] [131,96 Mb]*0*9/13/2020 9:33:38 PM",
    "FUEGO VS FUEGO - 1x01.mp4*138372487*9/7/2020 9:10:34 PM",
    138372487,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\FUEGO VS FUEGO - [Temp 1] [Cap-02] [MP4] [112,83 Mb]*0*9/13/2020 9:33:40 PM",
    "FUEGO VS FUEGO - 1x02.mp4*118306329*9/7/2020 9:11:40 PM",
    118306329,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\FUEGO VS FUEGO - [Temp 1] [Cap-03] [MP4] [142,91 Mb]*0*9/13/2020 11:17:32 PM",
    "FUEGO VS FUEGO - 1x03.mp4*149848252*9/7/2020 9:11:56 PM",
    149848252,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Got Talent Uruguay - [Temp 1] [Cap-11] [TS] [355,25 Mb]*0*9/13/2020 9:33:44 PM",
    "Got Talent Uruguay - 1x11.ts*372502124*9/4/2020 11:31:24 AM",
    372502124,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Got Talent Uruguay - [Temp 1] [Cap-12] [MP4] [487,95 Mb]*0*9/13/2020 11:20:54 PM",
    "Got Talent Uruguay - 1x12.mp4*511656476*9/8/2020 11:11:09 AM",
    511656476,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Guerreros 2020, [08-09-20] [817,23 Mb]*0*9/13/2020 9:33:55 PM",
    "Guerreros 2020, [08-09-20].mpg*857004032*9/9/2020 1:38:14 PM",
    857004032,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Guerreros 2020, Últimos programas [09-09-20] [822,92 Mb]*0*9/13/2020 9:34:05 PM",
    "Guerreros 2020, Últimos programas [09-09-20].mpg*862304256*9/10/2020 2:46:55 PM",
    862304256,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Guerreros 2020 39 [07 09 20] [816,67 Mb]*0*9/13/2020 9:33:47 PM",
    "Guerreros 2020 39 [07 09 20].mpg*856354816*9/8/2020 6:55:18 PM",
    856354816,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Guerreros 2020 41 [09 09 20] [815,18 Mb]*0*9/13/2020 10:22:06 PM",
    "Guerreros 2020 41 [09 09 20].mpg*855152640*9/10/2020 2:45:29 PM",
    855152640,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Improvisando. 2020 [07-09-20] [1,46 Gb]*0*9/13/2020 9:34:12 PM",
    "Improvisando. 2020 [07-09-20].mpg*1572752667*9/6/2020 7:45:19 PM",
    1572752667,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Keeping up with Kardashians , ESTRENO Español [09-09-20] [424,72 Mb]*0*9/13/2020 10:39:53 PM",
    "Keeping up with Kardashians , ESTRENO Español [09-09-20].mpg*445282304*9/10/2020 2:33:47 PM",
    445282304,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Keeping up with Kardashians I [09-09-20] [457,07 Mb]*0*9/13/2020 9:34:26 PM",
    "Keeping up with Kardashians I [09-09-20].mpg*480110592*9/10/2020 2:38:15 PM",
    480110592,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Keeping up with Kardashians II [09-09-20] [463,98 Mb]*0*9/13/2020 9:34:35 PM",
    "Keeping up with Kardashians II [09-09-20].mpg*486520982*9/9/2020 3:39:30 PM",
    486520982,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Keeping up with Kardashians III [09-09-20] [443,23 Mb]*0*9/13/2020 11:08:02 PM",
    "Keeping up with Kardashians III [09-09-20].mpg*464763100*9/9/2020 3:39:50 PM",
    464763100,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\La Habitación Del Pánico - [Temp 1] [Cap-01] [MP4] [391,18 Mb]*0*9/13/2020 9:34:39 PM",
    "La Habitación Del Pánico - 1x01.mp4*410186347*9/7/2020 3:05:59 PM",
    410186347,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\La noche de Mirtha [06 09 20] [529,57 Mb]*0*9/13/2020 10:39:58 PM",
    "La noche de Mirtha [06 09 20].mp4*555299372*9/5/2020 3:27:40 PM",
    555299372,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\La Ruleta de la Suerte, 2020 [09-09-20] [402,31 Mb]*0*9/13/2020 9:34:47 PM",
    "La Ruleta de la Suerte, 2020 [09-09-20].mpg*422330368*9/10/2020 2:46:06 PM",
    422330368,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\La Ruleta de la Suerte 2020 [08-09-20] [453,86 Mb]*0*9/13/2020 9:34:43 PM",
    "La Ruleta de la Suerte 2020 [08-09-20].mpg*475910580*9/8/2020 3:21:47 PM",
    475910580,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\La Ruleta de la Suerte. 2020 [07-09-20] [1,18 Gb]*0*9/13/2020 10:22:17 PM",
    "La Ruleta de la Suerte. 2020 [07-09-20].mpg*1261728351*9/6/2020 7:50:45 PM",
    1261728351,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Los ángeles de la mañana [07 09 20] [727,43 Mb]*0*9/13/2020 9:35:04 PM",
    "Los ángeles de la mañana [07 09 20].mp4*762768956*9/6/2020 1:28:31 PM",
    762768956,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Los ángeles de la mañana 2 [08 09 20] [9561,48 Mb]*0*9/13/2020 9:34:55 PM",
    "Los ángeles de la mañana 2 [08 09 20].mpg*1003061248*9/9/2020 11:29:23 PM",
    1003061248,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\MAGALY  [08-09-20] [708,85 Mb]*0*9/13/2020 10:30:45 PM",
    "MAGALY  [08-09-20].mpg*743012352*9/9/2020 11:53:39 PM",
    743012352,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Mamushka [07 09 20] [590,34 Mb]*0*9/13/2020 11:11:13 PM",
    "Mamushka [07 09 20].mp4*619012368*9/6/2020 1:51:45 PM",
    619012368,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Mamushka 2 [08 09 20] [622,53 Mb]*0*9/13/2020 9:35:13 PM",
    "Mamushka 2 [08 09 20].mpg*652949504*9/9/2020 11:15:36 PM",
    652949504,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Masterchef Celebrity Uruguay - [Temp 1] [Cap-03] [MPG] [549,09 Mb]*0*9/13/2020 9:35:19 PM",
    "Masterchef Celebrity Uruguay - 1x03.mpg*576262144*9/10/2020 3:00:43 PM",
    576262144,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Mdq, Para Todo El Mundo [08-09-20] [189,87 Mb]*0*9/13/2020 9:35:24 PM",
    "Mdq, Para Todo El Mundo [08-09-20].mkv*199088654*9/7/2020 11:21:30 PM",
    199088654,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\mi mama cocina mejor que la tuya [07-09-20] [704,59 Mb]*0*9/13/2020 11:11:21 PM",
    "mi mama cocina mejor que la tuya [07-09-20].mpg*738375680*9/8/2020 7:14:49 PM",
    738375680,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\mi mama cocina mejor que la tuya [08-09-20] [704,59 Mb]*0*9/13/2020 9:35:26 PM",
    "mi mama cocina mejor que la tuya [08-09-20].mpg*738375680*9/9/2020 11:39:32 PM",
    738375680,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\MTV + Tú, EStreno [07-09-20] [450,64 Mb]*0*9/13/2020 11:21:51 PM",
    "MTV + Tú, EStreno [07-09-20].mpg*472535411*9/6/2020 7:52:15 PM",
    472535411,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Mujeres [07 09 20] [626,36 Mb]*0*9/13/2020 9:41:03 PM",
    "Mujeres [07 09 20].mp4*656791113*9/6/2020 1:40:27 PM",
    656791113,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Mujeres [08-09-20] [260,66 Mb]*0*9/13/2020 9:41:10 PM",
    "Mujeres [08-09-20].mkv*273326628*9/7/2020 11:55:38 PM",
    273326628,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Mujeres 2 [08 09 20] [719,06 Mb]*0*9/13/2020 9:40:44 PM",
    "Mujeres 2 [08 09 20].mpg*754274304*9/9/2020 11:36:02 PM",
    754274304,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Pasapalabra. 2020 [07-09-20] [1,26 Gb]*0*9/13/2020 11:18:23 PM",
    "Pasapalabra. 2020 [07-09-20].mpg*1352799456*9/6/2020 7:59:57 PM",
    1352799456,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Pasaplabra 2020 [08-09-20] [541,01 Mb]*0*9/13/2020 9:41:20 PM",
    "Pasaplabra 2020 [08-09-20].mpg*568139776*9/9/2020 11:24:37 PM",
    568139776,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\punto final [07-09-20] [1,40 Gb]*0*9/13/2020 9:41:26 PM",
    "punto final [07-09-20].mpg*1504116736*9/8/2020 7:56:17 PM",
    1504116736,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\SABADO DELUXE (PAZ PADILLA) [06 09 20] [1021,57 Mb]*0*9/13/2020 9:41:52 PM",
    "SABADO DELUXE (PAZ PADILLA) [06 09 20].mp4*1071194649*9/5/2020 4:16:20 PM",
    1071194649,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Saber y ganar [06-09-20] [162,86 Mb]*0*9/13/2020 9:42:01 PM",
    "Saber y ganar [06-09-20].ts*170773936*9/4/2020 9:23:07 PM",
    170773936,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Saber y ganar [08-09-20] [147,79 Mb]*0*9/13/2020 9:42:11 PM",
    "Saber y ganar [08-09-20].ts*154968400*9/7/2020 9:00:03 PM",
    154968400,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Salvame Limon [08 09 20] [511,94 Mb]*0*9/13/2020 11:22:08 PM",
    "Salvame Limon [08 09 20].mp4*536805290*9/7/2020 2:55:54 PM",
    536805290,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\SÁLVAME LIMÓN PAZ PADILLA [07 09 20] [669,63 Mb]*0*9/13/2020 10:32:08 PM",
    "SÁLVAME LIMÓN PAZ PADILLA [07 09 20].mpg*702126080*9/8/2020 6:55:38 PM",
    702126080,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Salvame Naranja [08 09 20] [639,17 Mb]*0*9/13/2020 9:42:36 PM",
    "Salvame Naranja [08 09 20].mp4*670215930*9/7/2020 2:29:41 PM",
    670215930,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Salvame Naranja 2 [08 09 20] [851,81 Mb]*0*9/13/2020 11:33:09 PM",
    "Salvame Naranja 2 [08 09 20].mp4*893184500*9/7/2020 3:20:47 PM",
    893184500,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\SÁLVAME TOMATE [07 09 20] [634,86 Mb]*0*9/13/2020 9:43:55 PM",
    "SÁLVAME TOMATE [07 09 20].mp4*665703073*9/6/2020 2:19:21 PM",
    665703073,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Salvame Tomate [08 09 20] [684,51 Mb]*0*9/13/2020 9:42:46 PM",
    "Salvame Tomate [08 09 20].mp4*717759488*9/9/2020 7:57:48 AM",
    717759488,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Shark Tank, EUA,  Temp. 10 [09-09-20] [1 Gb]*0*9/13/2020 9:43:34 PM",
    "Shark Tank, EUA,  Temp. 10 [09-09-20].mpg*447322112*9/10/2020 3:10:46 PM",
    447322112,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Shark Tank EUA [07-09-20] [1,04 Gb]*0*9/13/2020 11:12:20 PM",
    "Shark Tank EUA [07-09-20].mpg*1120246580*9/6/2020 8:07:20 PM",
    1120246580,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Shark Tank México, ESTRENO [07-09-20] [1,18 Gb]*0*9/13/2020 9:43:12 PM",
    "Shark Tank México, ESTRENO [07-09-20].mpg*1266353613*9/6/2020 8:11:01 PM",
    1266353613,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Shark Tank USA, El testimonio [08-09-20] [398,12 Mb]*0*9/13/2020 9:43:24 PM",
    "Shark Tank USA, El testimonio [08-09-20].mpg*417460118*9/8/2020 3:23:21 PM",
    417460118,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Summer House I [07-09-20] [219,55 Mb]*0*9/13/2020 10:40:27 PM",
    "Summer House I [07-09-20].mpg*230219997*9/7/2020 2:48:18 PM",
    230219997,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\The Soup I, 2020,  Nueva Temp. [07-09-20] [195,48 Mb]*0*9/13/2020 11:33:23 PM",
    "The Soup I, 2020,  Nueva Temp. [07-09-20].mpg*204979500*9/7/2020 2:48:49 PM",
    204979500,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\The Soup II, 2020,  Nueva Temp. [07-09-20] [193,68 Mb]*0*9/13/2020 9:44:06 PM",
    "The Soup II, 2020,  Nueva Temp. [07-09-20].mpg*203091904*9/7/2020 2:49:18 PM",
    203091904,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\The Soup II, ESTRENO [08-09-20] [177,56 Mb]*0*9/13/2020 11:18:53 PM",
    "The Soup II, ESTRENO [08-09-20].mpg*186185122*9/8/2020 3:23:36 PM",
    186185122,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Yo soy grandes batallas [07 09 20] [859,26 Mb]*0*9/13/2020 9:44:16 PM",
    "Yo soy grandes batallas [07 09 20].mpg*901533696*9/8/2020 7:19:16 PM",
    901533696,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Yo Soy Grandes Batallas [08 09 20] [780,44 Mb]*0*9/13/2020 10:22:51 PM",
    "Yo Soy Grandes Batallas [08 09 20].mpg*817903616*9/10/2020 12:17:16 AM",
    817903616,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Español]\\Yo soy grandes batallas [09 09 20] [787,03 Mb]*0*9/13/2020 9:44:41 PM",
    "Yo soy grandes batallas [09 09 20].mpg*825577472*9/10/2020 3:32:02 PM",
    825577472,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Ingles]*0*9/13/2020 9:45:59 PM",
    0,
    "1459*1460*1461*1462*1463*1464*1465*1466*1467*1468*1469"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Ingles]\\American ninja Warrior - [Temp 11] [Cap-18] [MP4] [641,75 Mb]*0*9/13/2020 9:44:57 PM",
    "American ninja Warrior - 11x18.mp4*672918602*9/5/2020 8:41:27 PM",
    672918602,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Ingles]\\Americas Got Talent - [Temp 15] [Cap-19] [MP4] [713,99 Mb]*0*9/13/2020 11:12:33 PM",
    "Americas Got Talent - 15x19.mp4*748676007*9/9/2020 1:54:52 PM",
    748676007,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Ingles]\\Big Brother US - [Temp 22] [Cap-14] [MP4]*0*9/13/2020 9:45:11 PM",
    "Big Brother US - 22x14.mp4*333104036*9/7/2020 9:13:00 AM",
    333104036,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Ingles]\\Big Brother US - [Temp 22] [Cap-15] [MKV] [350,75 Mb]*0*9/13/2020 11:08:14 PM",
    "Big Brother US - 22x15.mkv*367788851*9/8/2020 7:17:53 PM",
    367788851,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Ingles]\\Britains Got Talent - [Temp 14] [Cap-10] [MP4] [1022,86 Mb]*0*9/13/2020 9:45:16 PM",
    "Britains Got Talent - 14x10.mp4*1072546780*9/6/2020 4:46:48 PM",
    1072546780,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Ingles]\\James Corden 2020 09 08 Hillary Swank WEB h264 RBB [297,93 Mb]*0*9/13/2020 10:32:29 PM",
    "James Corden 2020 09 08 Hillary Swank WEB h264 RBB.mp4*312397532*9/9/2020 2:02:28 PM",
    312397532,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Ingles]\\Jimmy Kimmel Live 2020 09 08 WEBRip H 264 RBB [152,81 Mb]*0*9/13/2020 9:45:27 PM",
    "Jimmy Kimmel Live 2020 09 08 WEBRip H 264 RBB.mp4*160237176*9/8/2020 8:49:02 PM",
    160237176,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Ingles]\\Love Island US - [Temp 2] [Cap-12] [MKV]*0*9/13/2020 9:45:32 PM",
    "Love Island US - 2x12.mkv*290297654*9/7/2020 7:47:00 AM",
    290297654,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Ingles]\\The Kitchen - [Temp 25] [Cap-11] [MP4] [312,23 Mb]*0*9/13/2020 9:45:48 PM",
    "The Kitchen - 25x11.mp4*327395775*9/6/2020 10:36:41 PM",
    327395775,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Ingles]\\Who WantsA Millionaire - [Temp 35] [Cap-01] [MP4] [118,08 Mb]*0*9/13/2020 9:53:50 PM",
    "Who WantsA Millionaire - 35x01.mp4*123816133*9/8/2020 12:55:42 PM",
    123816133,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Series TV Shows [TX][Ingles]\\Who WantsA Millionaire - [Temp 35] [Cap-02] [MP4] [335,64 Mb]*0*9/13/2020 9:53:55 PM",
    "Who WantsA Millionaire - 35x02.mp4*351947765*9/9/2020 2:40:26 PM",
    351947765,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos*0*9/13/2020 9:35:26 PM",
    0,
    "1471*1472*1473*1474*1475*1476*1477*1478*1479*1480*1481*1482*1483*1484*1485*1486*1487*1488*1489*1490*1491*1492*1493*1494*1495*1496*1497*1498*1499*1500*1501*1502*1503*1504*1505*1506*1507*1508*1509*1510*1511*1512"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\100 LATINOS DIJERON*0*9/13/2020 8:57:12 PM",
    "100 Latinos Dijeron [10-09-20].mp4*3230483568*9/10/2020 9:28:00 AM",
    3230483568,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\100 MEXICANOS DIJERON*0*9/13/2020 11:15:30 PM",
    "100 Mexicanos Dijeron, M. Antonio R [09-09-20].mpg*410400768*9/10/2020 1:20:24 PM",
    "100 Mexicanos Dijeron, M.Nieto. Nueva Tep. [09-09-20].mpg*409645056*9/10/2020 1:21:32 PM",
    "100 Mexicanos Dijeron, Marco A. Regil, 2020 [07-09-20].mpg*436031488*9/8/2020 5:43:46 PM",
    "100 Mexicanos Dijeron, Mau Nieto, 2020 [08-09-20].mpg*436703232*9/9/2020 1:15:25 PM",
    1692780544,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\12 CORAZONES*0*9/13/2020 8:57:00 PM",
    "12 Corazones, Escuelita [07-09-20].mpg*416284672*9/8/2020 5:38:11 PM",
    "12 corazones, Pintores Vs. Fridas [08-09-20].mpg*417882112*9/9/2020 1:13:47 PM",
    834166784,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\AL ROJO VIVO*0*9/13/2020 10:18:42 PM",
    "ARV. Al Rojo Vivo co María Celeste [08-09-20].mpg*430206976*9/9/2020 1:13:59 PM",
    "ARV. Al Rojo Vivo sin María Celeste [09-09-20].mpg*438616064*9/10/2020 1:21:56 PM",
    868823040,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\CASO CERRADO*0*9/13/2020 11:27:04 PM",
    "CC. Caso Cerrado [07-09-20].mpg*197249024*9/8/2020 5:39:46 PM",
    "CC. Caso Cerrado [08-09-20].mpg*196018176*9/9/2020 1:06:23 PM",
    "CC. Caso Cerrado [09-09-20].mpg*172677120*9/10/2020 1:15:18 PM",
    565944320,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\CERO EN CONDUCTA*0*9/13/2020 11:23:44 PM",
    "Cero en Conducta, comedia [08-09-20].mpg*149579776*9/10/2020 1:15:48 PM",
    149579776,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\COMO DICE EL DICHO*0*9/13/2020 11:09:22 PM",
    "Como Dice el Dicho, 2020. Quien lazo me armó [09-09-20].mpg*353552384*9/10/2020 1:24:09 PM",
    "Como Dice el Dicho, como pecas, pagas [07-09-20].mpg*383690752*9/8/2020 5:44:54 PM",
    "Como Dice el Dicho, Mientras cuentas [10-09-20].mpg*387090351*9/10/2020 3:46:32 PM",
    "Como Dice el Dicho, Que se pierdan los [08-09-20].mpg*340563968*9/9/2020 12:59:51 PM",
    "Como Dice el Dicho, Quien tiene verguenza [07-09-20].mpg*382144512*9/8/2020 5:46:20 PM",
    "Como Dice el Dicho,... Don dinero [09-09-20].mpg*388620288*9/10/2020 1:26:12 PM",
    2235662255,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\COMO VIVEN LOS MULTIMILLONARIOS*0*9/13/2020 8:56:33 PM",
    "Como Viven los Muntimillonarios. [10-09-20].mpg*410190548*9/10/2020 3:47:21 PM",
    410190548,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\CUENTAMELO YA*0*9/13/2020 8:56:31 PM",
    "Cuéntamelo YA! [08-09-20].mpg*330070016*9/10/2020 1:25:09 PM",
    330070016,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\DE PRIMERA MANO*0*9/13/2020 8:56:20 PM",
    "DPM. De Primera Mano [08-09-20].mpg*663402496*9/10/2020 1:39:30 PM",
    663402496,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\DECISIONES*0*9/13/2020 8:56:28 PM",
    "Decisiones Extremas, doble vida [07-09-20].mpg*419158016*9/8/2020 5:48:48 PM",
    "Decisiones Extremas, Mala semilla [08-09-20].mpg*399998976*9/9/2020 1:00:46 PM",
    819156992,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\DL &amp; COMPAÑIA*0*9/13/2020 8:56:16 PM",
    "DL &amp; Compañia Est. 2020 [08-09-20].mpg*409376768*9/10/2020 1:29:22 PM",
    409376768,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\EL GORDO Y LA FLACA*0*9/13/2020 11:27:00 PM",
    "Gyf. el Gordo y la  flaca [09-09-20].mpg*430774272*9/10/2020 1:42:06 PM",
    "Gyf. el Gordo y la flaca [08-09-20].mpg*253716480*9/9/2020 12:55:40 PM",
    684490752,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\EL JUEGO DE LAS ESTRELLAS*0*9/13/2020 9:11:32 PM",
    "El Juego de las Estrellas [10-09-20].mpg*578652644*9/10/2020 3:51:12 PM",
    578652644,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\EL NEWS CAFE*0*9/13/2020 11:24:00 PM",
    "El News Café [07-09-20].mpg*440168448*9/8/2020 5:48:52 PM",
    "El News Café [08-09-20].mpg*449605632*9/10/2020 1:33:19 PM",
    "El News Café [09-09-20].mpg*417959936*9/10/2020 1:33:40 PM",
    1307734016,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\EN CASA*0*9/13/2020 9:11:42 PM",
    "En Casa. 2020 [08-09-20].mpg*201226240*9/10/2020 1:29:10 PM",
    201226240,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\ENCHUFE TV*0*9/13/2020 9:11:40 PM",
    "enchufe tv. 2020 [10-09-20].mpg*220834868*9/10/2020 3:50:16 PM",
    220834868,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\GUERRA DE CHISTES*0*9/13/2020 10:20:06 PM",
    "Guerra de Chistes [08-09-20].mpg*422080512*9/10/2020 1:38:07 PM",
    422080512,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\HASHTAG TV*0*9/13/2020 9:11:47 PM",
    "Hashtag TV [10-09-20].mpg*232792927*9/10/2020 3:51:39 PM",
    232792927,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\HOY*0*9/13/2020 10:28:40 PM",
    "HOY MX, resumen [07-09-20].mpg*702894080*9/8/2020 6:04:45 PM",
    "HOY, MX, resumen [09-09-20].mpg*488196096*9/10/2020 1:44:54 PM",
    "HOY, MX, resumen [10-09-20].mpg*804923257*9/10/2020 3:56:35 PM",
    "HOY, resumen [08-09-20].mpg*444092416*9/9/2020 12:59:23 PM",
    2440105849,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\ICONOS*0*9/13/2020 9:12:07 PM",
    "Íconos TV, Keira Knightley [08-09-20].mpg*397273088*9/10/2020 2:09:48 PM",
    397273088,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\LA HORA PICO*0*9/13/2020 9:12:04 PM",
    "LHP La Hora Pico [08-09-20].mpg*331266048*9/10/2020 1:43:41 PM",
    331266048,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\LA ROSA DE GUADALUPE*0*9/13/2020 11:25:59 PM",
    "La Rosa de Guadalupe, Amor de hermanas [07-09-20].mpg*395134976*9/8/2020 5:49:46 PM",
    "La Rosa de Guadalupe, El amigo de [07-09-20].mpg*395458560*9/8/2020 5:55:35 PM",
    "La Rosa de Guadalupe, el niño [07-09-20].mpg*325632000*9/8/2020 5:56:52 PM",
    "La rosa de uadalupè.mpg*1380569088*9/10/2020 4:24:26 AM",
    "La rosa de uadalupè2.mpg*1318320128*9/9/2020 9:48:00 PM",
    3815114752,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\LA ULTIMA Y NOS VAMOS*0*9/13/2020 9:12:29 PM",
    "La Última y nos Vamos, Ninel Conde [08-09-20].mpg*518225920*9/10/2020 1:49:01 PM",
    518225920,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\LAURA*0*9/13/2020 9:12:37 PM",
    "! Laura Sin Censura, MX [09-09-20].mpg*557205504*9/10/2020 1:23:52 PM",
    "Laura sin Censura, MX. ESTRENO [08-09-20].mpg*521510912*9/9/2020 1:04:44 PM",
    1078716416,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\LO MEJOR DE CASO CERRADO*0*9/13/2020 9:12:46 PM",
    "Lo Mejor de Caso Cerrado [07-09-20].mpg*394811392*9/8/2020 6:00:10 PM",
    "Lo Mejor de Caso Cerrado [08-09-20].mpg*166377472*9/9/2020 12:53:27 PM",
    "Lo Mejor de Caso Cerrado [09-09-20].mpg*420849664*9/10/2020 1:46:10 PM",
    982038528,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\LO QUE CALLAMOS LAS MUJERES*0*9/13/2020 9:12:49 PM",
    "Lo que callamos las mujeres. 2020 [07-09-20].mpg*322586624*9/8/2020 5:57:29 PM",
    322586624,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\LOS GONZALEZ*0*9/13/2020 9:12:52 PM",
    "Los GonZález, La estatua [08-09-20].mpg*213432320*9/10/2020 1:42:14 PM",
    213432320,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\NOSOTROS LOS GUAPOS*0*9/13/2020 9:12:54 PM",
    "Nosotros los Guapos [10-09-20].mpg*332223143*9/10/2020 3:59:33 PM",
    332223143,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\PRIMER IMPACTO*0*9/13/2020 10:20:11 PM",
    "Pi, Primer Impacto [08-09-20].mpg*376074240*9/9/2020 12:58:52 PM",
    "Pi. Primer impacto [07-09-20].mpg*406071296*9/8/2020 5:34:07 PM",
    "Pi. Primer impacto [09-09-20].mpg*385220608*9/10/2020 1:48:43 PM",
    1167366144,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\QUE CHULADA*0*9/13/2020 9:13:02 PM",
    "¡ Qué Chulada! [08-09-20].mpg*480528384*9/10/2020 2:06:37 PM",
    480528384,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\RECUERDA Y GANA*0*9/13/2020 9:13:06 PM",
    "Recuerda y Gana  ( 09-09-20 ) [10-09-20].mpg*567622401*9/10/2020 4:01:39 PM",
    567622401,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\SALE EL SOL*0*9/13/2020 10:20:15 PM",
    "Sale el Sol [08-09-20].mpg*405901312*9/10/2020 1:52:31 PM",
    405901312,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\SE RENTAN CUARTOS*0*9/13/2020 9:13:11 PM",
    "Se Rentan Cuartos [08-09-20].mpg*266139648*9/10/2020 1:50:01 PM",
    266139648,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\SUELTA LA SOPA*0*9/13/2020 9:13:20 PM",
    "¡SLS! Suelta la Sopa! [08-09-20].mpg*420413440*9/9/2020 1:09:13 PM",
    "¡SLS! Suelta la Sopa! [09-09-20].mpg*438990848*9/10/2020 2:06:40 PM",
    "¡SLS!. Suelta la Sopa! [07-09-20].mpg*343027712*9/8/2020 5:35:21 PM",
    1202432000,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\TESTIGO*0*9/13/2020 11:07:45 PM",
    "i Testigo, 2020 [08-09-20].mpg*333934592*9/9/2020 12:57:35 PM",
    333934592,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\TODOS QUIEREN FAMA*0*9/13/2020 9:13:22 PM",
    "Todos Quieren Fama, GRAN ESTRENO [08-09-20].mpg*562124800*9/10/2020 2:00:07 PM",
    562124800,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\TOP DE ESTILO*0*9/13/2020 9:13:27 PM",
    "TOP de Estilo, Nuevo Episodio [08-09-20].mpg*270141440*9/10/2020 1:51:36 PM",
    270141440,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\UN NUEVO DIA*0*9/13/2020 10:35:31 PM",
    "UND. Un Nuevo Día, resumen [07-09-20].mpg*940943360*9/8/2020 5:46:10 PM",
    "UND. Un Nuevo Día, resumen [08-09-20].mpg*1021931520*9/9/2020 1:34:40 PM",
    "UND. Un Nuevo Día, resumen [09-09-20].mpg*836278272*9/10/2020 2:15:40 PM",
    2799153152,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\VENGA LA ALEGRIA*0*9/13/2020 11:31:54 PM",
    "VLA. Venga la Alegría, Resumen [08-09-20].mpg*352219136*9/10/2020 2:02:22 PM",
    352219136,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\VENTANEANDO*0*9/13/2020 10:16:55 PM",
    "VentaneandoUno [08-09-20].mpg*407369728*9/10/2020 1:58:26 PM",
    407369728,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Shows Latinos\\VIDEOFASHION*0*9/13/2020 11:33:58 PM",
    "Videofashion! Men, Vol 32-4, Nuevo Episodio. [08-09-20].mpg*240232448*9/10/2020 1:56:20 PM",
    "Videofashion! Modelos Vol. 24, Nuevo Episodio [09-09-20].mpg*454939739*9/9/2020 5:18:45 PM",
    "Videofashion!, Diseñadores de 1er. nivel, Nuevo Episodio [08-09-20].mpg*237643776*9/10/2020 1:56:07 PM",
    932815963,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Trailers [2020]*0*9/13/2020 9:34:43 PM",
    0,
    "1514*1515"
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Trailers [2020]\\Game Tralier*0*9/13/2020 11:33:56 PM",
    "20 BEST NEW GAME TRAILERS 2020.mp4*259008614*9/2/2020 5:52:34 PM",
    "5 BEST GAME TRAILERS 2020.mp4*86460202*8/31/2020 10:23:36 PM",
    "Baldur's Gate 3 - Official Full Intro Cinematic.mp4*34383678*8/31/2020 10:21:24 PM",
    "Genshin Impact - Trailer  gamescom 2020.mp4*43571734*8/31/2020 10:19:28 PM",
    "Microsoft Flight Simulator - Asia &amp; The Middle East Trailer  gamescom 2020.mp4*55973144*8/31/2020 10:20:48 PM",
    "New Game Trailers 2020  Weekly #28.mp4*308643164*9/2/2020 5:53:12 PM",
    "New Game Trailers 2020  Weekly #31.mp4*390012272*8/31/2020 10:31:24 PM",
    "Project Wingman - Official Trailer  gamescom 2020.mp4*20952030*8/31/2020 10:20:04 PM",
    "Remothered Broken Porcelain - Official Trailer  gamescom 2020.mp4*17064220*8/31/2020 10:22:00 PM",
    "Sherlock Holmes Chapter One - Official Trailer  gamescom 2020.mp4*14398922*8/31/2020 10:20:16 PM",
    "TGA 2019 BEST GAME TRAILERS.mp4*130241490*9/2/2020 5:48:50 PM",
    "The Batman - Official Trailer  DC FanDome.mp4*6992415*8/31/2020 10:21:02 PM",
    "Transient - Official Cinematic Trailer  gamescom 2020.mp4*25303594*8/31/2020 10:19:38 PM",
    "Warhammer Age of Sigmar Storm Ground - Reveal Trailer  gamescom 2020.mp4*12479830*8/31/2020 10:19:02 PM",
    "Werewolf The Apocalypse Earthblood - Cinematic Trailer  gamescom 2020.mp4*15139249*8/31/2020 10:19:54 PM",
    1420624558,
    ""
];

dirs[i++] = [
    "G:\\((14-09-2020))\\Trailers [2020]\\Movie Trailer*0*9/13/2020 11:26:45 PM",
    "Ammonite Trailer #1 (2020)  Movieclips Trailers.mp4*36485329*9/6/2020 11:41:30 PM",
    "Black Adam Teaser Trailer (2021)  Movieclips Trailers.mp4*23160459*9/6/2020 11:41:45 PM",
    "BOOKSMART Trailer (2019) Lisa Kudrow, Olivia Wild, Teen Movie.mp4*9847127*8/31/2020 10:24:24 PM",
    "Chernobyl (2020) Tráiler Oficial Subtitulado.mp4*41969577*9/6/2020 11:42:16 PM",
    "Come Play Trailer #1 (2020)  Movieclips Trailers.mp4*7486658*8/31/2020 10:23:20 PM",
    "Emily en París Temporada 1 (2020) Netflix Serie Tráiler Oficial Subtitulado.mp4*25241122*9/6/2020 11:42:31 PM",
    "Enola Holmes Trailer #1 (2020)  Movieclips Trailers.mp4*31322487*9/6/2020 11:42:53 PM",
    "FATIMA Official Trailer (2020).mp4*38041865*9/6/2020 11:43:19 PM",
    "Grand Army Temporada 1 (2020) Netflix Serie Tráiler Oficial Subtitulado.mp4*21534868*9/2/2020 5:45:06 PM",
    "INHERITANCE Trailer (2020) Lily Collins, Simon Pegg Thriller Movie.mp4*36392977*9/6/2020 11:43:43 PM",
    "Jurassic World Campamento Cretácico (2020) Netflix Serie Tráiler Oficial #2 Español Latino.mp4*14372973*9/2/2020 5:44:46 PM",
    "Los Nuevos Mutantes Featurette Blu Hunt es Danielle Moonstar Subtitulado.mp4*10904619*9/2/2020 5:45:36 PM",
    "Los Nuevos Mutantes Featurette Charlie Heaton es Sam Subtitulado.mp4*9571434*9/2/2020 5:45:18 PM",
    "NEW MOVIE TRAILERS 2020 (August).mp4*185457713*9/6/2020 11:45:36 PM",
    "NEW MOVIE TRAILERS 2020 Weekly #22.mp4*132773843*8/31/2020 10:27:36 PM",
    "New Trailers This Week  Week 35 (2020)  Movieclips Trailers.mp4*212246143*8/31/2020 10:30:44 PM",
    "Regalos de un artista callejero llamado Yuier P. 2PARTE.mp4*157503750*7/10/2020 9:43:47 AM",
    "Se Busca Papá (2020) Netflix Tráiler Oficial Español Latino.mp4*11025887*9/2/2020 5:45:28 PM",
    "Sputnik Extraño Pasajero (2020) Tráiler Oficial Español Latino.mp4*5934458*9/2/2020 5:45:30 PM",
    "TENET - Final Trailer.mp4*15216457*8/31/2020 10:24:54 PM",
    "Tenet Behind the Scenes (2020)  Movieclips Trailers.mp4*46477863*8/31/2020 10:22:40 PM",
    "The 40-Year-Old Version Trailer #1 (2020)  Movieclips Trailers.mp4*25566009*8/31/2020 10:21:38 PM",
    "The Batman Teaser Trailer (2021)  Movieclips Trailers.mp4*6251790*8/31/2020 10:23:34 PM",
    "The Doorman Exclusive Trailer #1 (2020)  Movieclips Trailers.mp4*11108838*8/31/2020 10:23:10 PM",
    "The Haunting of Bly Manor Temporada 1 (2020) Netflix Serie Tráiler Oficial Subtitulado.mp4*8339527*9/2/2020 5:45:20 PM",
    "THE SUICIDE SQUAD 2 Official Teaser Trailer (2021) Sneak peek.mp4*13697410*8/31/2020 10:25:34 PM",
    "VENOM 2 Let There Be Carnage (2021) Teaser Trailer Concept  Tom Hardy  Woody Harrelson.mp4*10668076*8/31/2020 10:26:00 PM",
    "What to Watch Dynamic Movie Duos  Weekly Ticket.mp4*154977726*8/31/2020 10:28:46 PM",
    1303576985,
    ""
];

var f = function () {
    console.log('ejecutandi funcion');
    var SelectedFolderID = "-1";
    var PreviouslySelectedFolder = null;

    /* ---  Init --- */

    $("#tot_size").text(bytesToSize($("#tot_size").text()));

    $("#loading").remove();
    $("#content").show();

    $("#content").height($("#wrapper").outerHeight(true) - $("#top").outerHeight(true) - 1);

    $("#content").splitter({sizeLeft: 200});

    // prepare parent folder lookup
    var parent_folders = [];
    parent_folders[0] = 0;
    var numDirs = dirs.length;
    for (var id = 0; id < numDirs; id++) {
        var subdirs = getSubdirs(id);
        if (subdirs != "") {
            for (var c = 0; c < subdirs.length; c++) {
                parent_folders[subdirs[c]] = id;
            }
        }
    }

    /* --- Events --- */

    $("#search_form").submit(function () {
        return false;
    });

    // handle clicks on folders in file list
    $("body").delegate("a.folder_link", "click", function () {
        var tree = $("#tree").dynatree("getTree");
        var node = tree.getNodeByKey($(this).attr('id'));
        node.activate();
        return false;
    });

    // search delayer
    var delay = (function () {
        var timer = 0;
        return function (callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();

    // search for files
    var SearchFilenames = [];
    var SearchLocations = [];
    var SearchLocationsID = [];
    var SearchIsDir = [];

    $("#search_text").keyup(function (event) {

        var iDelay = 250;
        var PreviouslySelectedNode = null;
        if (7878 > 1000) iDelay = 1000;
        if (event.keyCode === '13') iDelay = 0;		// 13 = enter key

        delay(function () {

            var sSearchFor = $("#search_text").val().toLowerCase();

            if (sSearchFor === "") {
                if (PreviouslySelectedNode != null) {
                    PreviouslySelectedNode.activate();
                    PreviouslySelectedFolderID = -1;
                }
                return;
            }

            if (sSearchFor === "*") sSearchFor = "";

            if (SelectedFolderID != -1) {
                PreviouslySelectedNode = $("#tree").dynatree("getActiveNode");
                SelectedFolderID = "-1";
                $("#tree").dynatree("getActiveNode").deactivate();
            }

            $("#path_info").html("<b>&gt;&gt;</b> Search results:");

            var link_protocol = "[LINK PROTOCOL]";
            var link_root = "[LINK ROOT]";
            var source_root = "[SOURCE ROOT]";
            var source_parent = source_root.substring(0, source_root.lastIndexOf("/") + 1);
            var hide_root = (link_root != "" && link_root.substring(0, source_root.length) != source_root);
            var numDirs = dirs.length;
            var c;

            // if no previous search, do some pre-processing for faster search
            if (SearchFilenames.length === 0) {
                var nFound = 0;
                for (c = 1; c < numDirs; c++) // dirs first...
                {
                    SearchFilenames[nFound] = dirs[c][0].split("*");
                    SearchFilenames[nFound][0] = getDirName(c);
                    SearchFilenames[nFound][3] = SearchFilenames[nFound][0];		// keep original name (in non-lowercase)
                    SearchFilenames[nFound][0] = SearchFilenames[nFound][0].toLowerCase();
                    SearchFilenames[nFound][1] = Number(SearchFilenames[nFound][1]);
                    if (hide_root)
                        SearchLocations[nFound] = getDirNameAndPath(c).substring(source_parent.length);
                    else
                        SearchLocations[nFound] = getDirNameAndPath(c);
                    SearchLocationsID[nFound] = c;
                    SearchIsDir[nFound] = true;
                    nFound++;
                }
                for (c = 0; c < numDirs; c++) // ...then all files
                {
                    var arrLength = dirs[c].length;
                    for (var c2 = 1; c2 < arrLength - 2; c2++) {
                        SearchFilenames[nFound] = dirs[c][c2].split("*");
                        SearchFilenames[nFound][3] = SearchFilenames[nFound][0];		// keep original name (in non-lowercase)
                        SearchFilenames[nFound][0] = SearchFilenames[nFound][0].toLowerCase();
                        SearchFilenames[nFound][1] = Number(SearchFilenames[nFound][1]);
                        //SearchLocations[nFound] = getDirNameAndPath(c);
                        if (hide_root)
                            SearchLocations[nFound] = getDirNameAndPath(c).substring(source_parent.length);
                        else
                            SearchLocations[nFound] = getDirNameAndPath(c);

                        SearchLocationsID[nFound] = c;
                        SearchIsDir[nFound] = false;
                        nFound++;
                    }
                }
            }

            var table_html = "";
            table_html += "<table id='files' class='tablesorter'><thead><tr><th>Name</th><th>Folder</th><th>Size</th><th>Modified</th></tr></thead><tbody>\n";

            var countFiles = 0;
            var countDirs = 0;
            var tot_size = 0;
            for (c = 0; c < SearchFilenames.length; c++) {
                if (SearchFilenames[c][0].indexOf(sSearchFor) >= 0) {
                    var file_tmp = SearchFilenames[c][0];
                    var dir_tmp = getDirNameAndPath(SearchLocationsID[c]).substring(source_root.length);
                    if (dir_tmp != "") dir_tmp += "/";

                    if (SearchIsDir[c] === true) {
                        countDirs++;
                        //var located_in = SearchLocations[c];
                        //if( located_in === "" ) located_in = "[.]"
                        var subdir_id = parent_folders[SearchLocationsID[c]];
                        table_html += "<tr><td><span class='file_folder'><a href=\"#\" class=\"folder_link\" id=\"" + SearchLocationsID[c] + "\"> " + SearchFilenames[c][3] + "</a></span></td><td><span class='file_folder'><a href=\"#\" class=\"folder_link\" id=\"" + subdir_id + "\"> " + getDirNameAndPath(subdir_id) + "</a></span></td><td>" + bytesToSize(SearchFilenames[c][1]) + "</td><td>" + SearchFilenames[c][2] + "</td></tr>\n";
                    } else	// files
                    {
                        tot_size += SearchFilenames[c][1];
                        countFiles++;

                        if (false) {
                            file_tmp = link_protocol + link_root + dir_tmp.replace("\\", "/") + SearchFilenames[c][3] + "\">" + SearchFilenames[c][3] + "</a>";
                            if (navigator.userAgent.toLowerCase().indexOf("msie") >= 0 && link_protocol.indexOf("file:") >= 0) {
                                file_tmp = "javascript:alert('Internet Explorer does not allow linking to local files...')" + "\">" + SearchFilenames[c][3] + "</a>";
                            }
                            if (file_tmp.substr(0, 1) === "/") file_tmp = file_tmp.substr(1);
                            file_tmp = file_tmp.replace(/\\/g, "/");
                            file_tmp = file_tmp.replace(/#/g, "%23");

                            var indx = file_tmp.indexOf("://");
                            if (indx != -1) {
                                var protocol_tmp = file_tmp.substr(0, indx + 3);
                                file_tmp = file_tmp.substr(indx + 3);
                                file_tmp = file_tmp.replace(/\/\//g, "/");
                                file_tmp = protocol_tmp + file_tmp;
                            } else {
                                file_tmp = file_tmp.replace(/\/\//g, "/");
                            }

                            file_tmp = "<a href=\"" + file_tmp;
                        }

                        var located_in = SearchLocations[c];
                        if (located_in === "") located_in = "[.]";
                        table_html += "<tr><td><span class='file'>" + file_tmp + "</span></td><td><span class='file_folder'><a href=\"#\" class=\"folder_link\" id=\"" + SearchLocationsID[c] + "\"> " + located_in + "</a></span></td><td>" + bytesToSize(SearchFilenames[c][1]) + "</td><td>" + SearchFilenames[c][2] + "</td></tr>\n";
                    }

                }
            }
            table_html += "</tbody></table>\n";

            $("#file_list").html(table_html);
            $("#files").tablesorter({sortInitialOrder: "desc"});

            var sFiles = " files (";
            if (countFiles === 1) sFiles = " file (";
            var sDirs = " folders";
            if (countDirs === 1) sDirs = " folder";
            $("#num_files").html(countDirs + sDirs + "<br>" + countFiles + sFiles + bytesToSize(tot_size, 0) + ")");

            return false;

        }, iDelay);
    });


    // show folder content
    function ShowFolder(FolderID) {
        if (SelectedFolderID === FolderID) return false;
        $("#" + SelectedFolderID).removeClass("bold");
        SelectedFolderID = FolderID;

        $("#search_text").val("");

        var link_protocol = "[LINK PROTOCOL]";
        var link_root = "[LINK ROOT]";
        var source_root = "[SOURCE ROOT]";
        var source_parent = source_root.substring(0, source_root.lastIndexOf("/") + 1);
        var hide_root = (link_root != "" && link_root.substring(0, source_root.length) != source_root);

        var path_info = dirs[FolderID][0].split("*")[0].replace(/\:\\/g, "<span class='path_divider'></span>").replace(/\\/g, "<span class='path_divider'></span>");


        if (hide_root)
            $("#path_info").html(path_info.substring(source_parent.length));
        else
            $("#path_info").html(path_info);

        var table_html = "";
        table_html += "<table id='files' class='tablesorter'><thead><th>Name</th><th>Size</th><th>Modified</th></tr></thead><tbody>\n";

        var countFiles = 0;
        var countDirs = 0;
        var subdirTotSizes = 0;
        var c;

        // folders
        if (FolderID != 0) table_html += "<tr><td><span class='file_folder'><a href=\"#\" class=\"folder_link\" id=\"" + parent_folders[FolderID] + "\"> [..]</a></span></td><td></td><td></td></tr>\n";
        var subdirs = getSubdirs(SelectedFolderID);
        if (subdirs != "") {
            for (c = 0; c < subdirs.length; c++) {
                countDirs++;
                var sTmp = dirs[subdirs[c]][0].split("*");
                var name = sTmp[0].substring(sTmp[0].lastIndexOf("\\") + 1);
                var dirSize = getDirTreeSize(subdirs[c]);
                subdirTotSizes += dirSize;
                table_html += "<tr><td><span class='file_folder'><a href=\"#\" class=\"folder_link\" id=\"" + subdirs[c] + "\"> " + name + "</a></span></td><td>" + bytesToSize(dirSize) + "</td><td>" + sTmp[2] + "</td></tr>\n";
            }
        }

        // files
        for (c = 1; c < dirs[SelectedFolderID].length - 2; c++) {
            countFiles++;
            var sTmp = dirs[SelectedFolderID][c].split("*");
            var file_tmp = sTmp[0];
            var dir_tmp = getDirNameAndPath(SelectedFolderID).substring(source_root.length);
            if (dir_tmp != "") dir_tmp += "/";
            if (false) {
                file_tmp = link_protocol + link_root + dir_tmp + sTmp[0] + "\">" + sTmp[0] + "</a>";
                if (navigator.userAgent.toLowerCase().indexOf("msie") >= 0 && link_protocol.indexOf("file:") >= 0) {
                    file_tmp = "javascript:alert('Internet Explorer does not allow linking to local files...')" + "\">" + sTmp[0] + "</a>";
                }
                if (file_tmp.substr(0, 1) === "/") file_tmp = file_tmp.substr(1);
                file_tmp = file_tmp.replace(/\\/g, "/");
                file_tmp = file_tmp.replace(/#/g, "%23");

                var indx = file_tmp.indexOf("://");
                if (indx != -1) {
                    var protocol_tmp = file_tmp.substr(0, indx + 3);
                    file_tmp = file_tmp.substr(indx + 3);
                    file_tmp = file_tmp.replace(/\/\//g, "/");
                    file_tmp = protocol_tmp + file_tmp;
                } else {
                    file_tmp = file_tmp.replace(/\/\//g, "/");
                }

                file_tmp = "<a href=\"" + file_tmp;
            }
            table_html += "<tr><td><span class='file'>" + file_tmp + "</span></td><td>" + bytesToSize(sTmp[1]) + "</td><td>" + sTmp[2] + "</td></tr>\n";
        }

        table_html += "</tbody></table>\n";

        $("#file_list").html(table_html);
        $("#files").tablesorter({sortInitialOrder: "desc"});

        var sFiles = " files (";
        if (countFiles === 1) sFiles = " file (";
        var sDirs = " folders (";
        if (countDirs === 1) sDirs = " folder (";
        $("#num_files").html(countDirs + sDirs + bytesToSize(subdirTotSizes) + ")<br>" + countFiles + sFiles + bytesToSize(dirs[SelectedFolderID][dirs[SelectedFolderID].length - 2]) + ")");

        $("#" + SelectedFolderID).addClass("bold");

        return false;
    }


    /* --- Treeview --- */

    function PopulateTreeviewNode(node) {
        var subdirs = getSubdirs(node.data.key);
        if (subdirs != "") {
            for (var c = 0; c < subdirs.length; c++) {
                var newNode = node.addChild({
                    title: getDirName(subdirs[c]),
                    key: subdirs[c],
                    unselectable: true,
                    isFolder: true,
                });
                PopulateTreeviewNode(newNode)
            }
        }
    }

    $("#tree").dynatree({
        clickFolderMode: 1,
        minExpandLevel: 2,
        fx: {height: "toggle", duration: 100},
        onActivate: function (node) {
            ShowFolder(node.data.key);
        },
        onDblClick: function (node) {
            node.expand(!node.isExpanded());
        },
    });

    // init treeview items
    var rootNode = $("#tree").dynatree("getRoot").addChild({
        title: getDirName(0),
        key: "0",
        isFolder: true,
    });
    rootNode.tree.enableUpdate(false);
    PopulateTreeviewNode(rootNode);
    rootNode.tree.enableUpdate(true);
    rootNode.activate();


    /* --- Helper Functions --- */

    function getDirName(id) {
        if (dirs.length <= id) return "";
        var tmp = dirs[id][0].split("*");
        var tmp2 = tmp[0].substring(tmp[0].lastIndexOf("\\") + 1);
        if (tmp2 === "") return tmp[0]; else return tmp2;
    }

    function getDirNameAndPath(id) {
        if (dirs.length <= id) return "";
        var tmp = dirs[id][0].split("*");
        return tmp[0];
    }

    function getSubdirs(id) {
        if (dirs.length <= id) return "";
        return dirs[id][dirs[id].length - 1].split("*");
    }

    function getDirSize(id) {
        if (dirs.length <= id) return "0";
        return dirs[id][dirs[id].length - 2];
    }

    function getDirTreeSize(id) {
        if (dirs.length <= id) return "0";
        var totSize = getDirSize(id);
        var subdirs = getSubdirs(id);
        if (subdirs != "") {
            for (var c = 0; c < subdirs.length; c++) {
                totSize += getDirTreeSize(subdirs[c]);
            }
        }
        return totSize;
    }

    function bytesToSize(bytes) {
        var kilobyte = 1024;
        var megabyte = kilobyte * 1024;
        var gigabyte = megabyte * 1024;
        var terabyte = gigabyte * 1024;

        if ((bytes >= 0) && (bytes < kilobyte)) {
            return bytes + ' bytes';

        } else if ((bytes >= kilobyte) && (bytes < megabyte)) {
            return (bytes / kilobyte).toFixed(0) + ' KB';

        } else if ((bytes >= megabyte) && (bytes < gigabyte)) {
            return (bytes / megabyte).toFixed(1) + ' MB';

        } else if ((bytes >= gigabyte) && (bytes < terabyte)) {
            return (bytes / gigabyte).toFixed(2) + ' GB';

        } else if (bytes >= terabyte) {
            return (bytes / terabyte).toFixed(2) + ' TB';

        } else {
            return bytes + ' bytes';
        }
    }

}

export default f;

// setTimeout(function () {
//     f()
//     // $(window).on('resize', f);
// }, 6000)
//
//

