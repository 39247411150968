import 'font-awesome/css/font-awesome.min.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

export default new Vuetify({
    breakpoint: {
        thresholds: {
            xs: 400,
            sm: 650,
            md: 960 + 16,
            lg: 1024,
        },
    },
    icons: {
        iconfont: 'fa4',
    },
})
