<template>
    <text-card-type-two :trazado-src-sm="trazadoSrcSm"
                        :trazado-src-md="trazadoSrcMd"
                        :image-src="imgSrc"
                        read-more-link="text4">
        <template v-slot:title>
            El paquete semanal llega a cada poblado cubano en camión, guagua o lo que aparezca
        </template>
        <template #text>
            En San Antonio de Cabezas, Guanajay, Amancio Rodríguez, Gibara, Santo Domingo el paquete semanal es una
            importante fuente de entretenimiento. Hay quienes han encontrado el negocio perfecto o una vía para matar el
            tedio que abunda en cada poblado del interior de la Isla.
        </template>
        <template #outlineMd>
            <div style="display: flex; padding-left: 50%; width: 100%">
                <m-image :src-sm="require(`@/assets/home/trazado4.webp`)"
                         :src-md="require(`@/assets/home/trazado4.webp`)"
                         :src-lg="require(`@/assets/home/trazado4.webp`)"></m-image>
            </div>
        </template>
    </text-card-type-two>
</template>

<script>
    import TextCardTypeTwo from "./TextCardTypeTwo";
    import MImage from "../../core/MImage";

    export default {
        name: "TextCardFour",
        components: {MImage, TextCardTypeTwo},
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
            }
        },
        computed: {
            trazadoSrcSm() {
                return this.supportsWebp? require(`@/assets/home/mobile/trazado4sm.webp`): require(`@/assets/home/mobile/trazado4sm.png`)
            },
            trazadoSrcMd() {
                return this.supportsWebp? require(`@/assets/home/trazado4.webp`): require(`@/assets/home/trazado4.png`)
            },
            imgSrc() {
                return this.supportsWebp? require(`@/assets/home/text4.webp`): require(`@/assets/home/text4.png`)
            },
        }
    }
</script>

<style scoped>

</style>
