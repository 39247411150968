<template>
    <v-btn outlined large tile color="#f86443" style="font-family: Lato-Bold" link href="https://eltoque.com/especial-multimedia-sobre-el-paquete-semanal-de-cuba-el-toque/" target="_blank">Metodología</v-btn>
</template>

<script>
    export default {
        name: "MetodologyBtn"
    }
</script>

<style scoped>

</style>
