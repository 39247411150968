<template>
    <general-text :banner-image-src-md="bannerImageSrcMd"
                  :banner-image-src-sm="bannerImageSrcSm"
                  lazy-src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAMAAADTRh9nAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAB+1BMVEUAAAAJCQsAR0kKCgwFBggODQ9KP0BJSk0AUFYKCgwKCgwKCgwLCw0BCA1zQDI2NiB3V2MAAACCVlkRTm8APZUMBAAnVH4kTm8AQooXCg0HBgK8KSCZLieKNj0qCgWKPV5nVHisSkoQCxgKCgwBXIsDRZ8CDR4KCgwDAAAFW0zYYyo1SGcZCQkKCgwKCgwKCgwABAUsFRiwSEKLMUocqMcGMS0KCgwKCgwLCw0FBAg+X1EAPVEAvdQAwNgAv78Ak48KCgwKCgwKCgw4My9yWDiRbUczBwAAbmoABQAABQIKCgwKBwkKAwUKCAoKCw0KDA4LAQILBQYKBgcKDQ4KEBILAAAHOTwEY2YEZWgBpq0BFRYIAAAHBgoAAAUICAsHLjECcHUEh44aWlsXBwkHDBEQDwxQIQwdDAcAjpU0mI5UOySKcWMsIiwMAAAFT1EA1eBsYmo/co9IeY4GGTMLCgwIAwYAHB40Pzc9QVkTRHMqWZIICg4LBgMMBQNhEQR3LRR9LSqbO1kJCQsAAABDJzVjaI4Adb2BY2DJQEFpSX4MCw0FCAgICx8LaZ4AnuAlhqoxaHoAdqwAiLkBjrMABARRISh/Mjcqp5I5dW0KgIEAcnB2aE5/Q23GNUZiSkZkdm8ccqoAY7s4drF8c4sVm928QU3zSkbKZVMA1OyXOnMTAAAASnRSTlMAAAAAAAAAAADk8fLy8u3j/fLUkd7Q49TVXhHY0d8f9/z+Muv56yHP/P39+iOU/XgaT+P9/h0p4gI6xYny6uSkQuegWrnLORgYBDqKM1UAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAAB3RJTUUH5AoTAyQFytm4lAAAAOVJREFUGNNj4PTiQgNenAzcXnDg7e3j6wekuRGC/gGBQcEhvqiCoWHhEZFR0TGxcTxIgvEJiUnJKalp6bwIQe/QjMysbD5+gRxBhGBuXn5BYZGQsEixKFywpLSsvKJSTLxKQlIKLlhdU1tX3yAt0ygrxwATbGpuaW1r75BX6FRUYmBQBot1dTf39Pb1T5g4abKKqhqDOlhwytRpGprTZ8ycNVtLe44Ogy5QSE/fwNBorvG8+QsWLlq8xMSUwQwoaG7ByGC5dNlyK2ubFbZ29kwMzA6OXk4srGzszi6ubhzuHp4MDAwASB5Vvrgd+XcAAAAASUVORK5CYII="
                  :links="links">
        <template #title><span v-html="title"></span></template>
        <template #author>{{author}}</template>
        <template #body>
            <text-block :links="links">
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(2, 38)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>
            <v-row class="ma-0" justify="end">
                <v-col cols="12" class="text-block-container">
                    <infography></infography>
                </v-col>
                <v-col cols="1" md="2"></v-col>
            </v-row>
            <text-block>
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(38, 53)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>
            <v-row class="ma-0" justify="end">
                <v-col cols="11" class="text-block-container">
                    <v-container fluid class="pa-0">
                        <!--                        explorador de archivos-->
                        <file-explorer :key="fileExplorerKey"></file-explorer>
                    </v-container>
                </v-col>
                <v-col cols="1" md="2"></v-col>
            </v-row>
            <text-block>
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(53, 71)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>
            <v-row class="ma-0">
                <v-col cols="12" class="pa-0">
                    <v-img eager contain :src="crossMiddleImageSrc" width="100%"/>
                </v-col>
            </v-row>
            <text-block>
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(71, 84)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>
        </template>
    </general-text>
</template>

<script>
    import GeneralText from "../components/GeneralText";
    import TextBlock from "../components/texts/TextBlock";
    import {get_text} from "../utils";
    import store from "../store/store"
    import Infography from "../components/home/Infography";
    import FileExplorer from "../components/file-explorer/FileExplorer";
    import _ from 'lodash'

    export default {
        name: "Text1",
        metaInfo: {
            // Children can override the title.
            title: 'EL PAQUETE SEMANAL',
            titleTemplate: '%s ← LA RED OFFLINE QUE CONECTA TODA CUBA',
            // Define meta tags here.
            meta: [
                {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
                {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                {
                    name: 'description',
                    content: 'El modelo de negocio del paquete semanal ha demostrado ser sostenible y escalable. A su vez, las soluciones tecnológicas y logísticas aplicadas por sus actores lo convierten en un mecanismo que, aunque con fallas, sigue funcionando para clientes y paqueteros.'
                },
                {name: 'tags', content: 'paquetesemanal, paquetesemanalcuba, paquetecuba'},
                {name: 'author', content: 'Datalis <info@datalis.dev>'},
                // OpenGraph data (Most widely used)
                {property: 'og:title', content: 'LA RED OFFLINE QUE CONECTA TODA CUBA'},
                {property: 'og:site_name', content: 'EL PAQUETE SEMANAL'},
                // The list of types is available here: http://ogp.me/#types
                {property: 'og:type', content: 'website'},
                // Should the the same as your canonical link, see below.
                {property: 'og:url', content: 'https://paquetesemanal.eltoque.com/text1'},
                {property: 'og:image', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'},
                // Often the same as your meta description, but not always.
                {property: 'og:description', content: 'El modelo de negocio del paquete semanal ha demostrado ser sostenible y escalable. A su vez, las soluciones tecnológicas y logísticas aplicadas por sus actores lo convierten en un mecanismo que, aunque con fallas, sigue funcionando para clientes y paqueteros.'},

                // Twitter card
                {name: 'twitter:card', content: 'summary'},
                {name: 'twitter:site', content: 'https://paquetesemanal.eltoque.com/text1'},
                {name: 'twitter:title', content: 'LA RED OFFLINE QUE CONECTA TODA CUBA'},
                {name: 'twitter:description', content: 'El modelo de negocio del paquete semanal ha demostrado ser sostenible y escalable. A su vez, las soluciones tecnológicas y logísticas aplicadas por sus actores lo convierten en un mecanismo que, aunque con fallas, sigue funcionando para clientes y paqueteros.'},
                // Your twitter handle, if you have one.
                {name: 'twitter:creator', content: '@eltoquecom'},
                {name: 'twitter:image:src', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'},

                // Google / Schema.org markup:
                {itemprop: 'name', content: 'LA RED OFFLINE QUE CONECTA TODA CUBA'},
                {itemprop: 'description', content: 'El modelo de negocio del paquete semanal ha demostrado ser sostenible y escalable. A su vez, las soluciones tecnológicas y logísticas aplicadas por sus actores lo convierten en un mecanismo que, aunque con fallas, sigue funcionando para clientes y paqueteros.'},
                {itemprop: 'image', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'}
            ]
        },
        components: {Infography, GeneralText, TextBlock, FileExplorer},
        mounted() {
            if (store.state.supportsWebp) {
                console.log('si')
            } else {
                console.log('no')
            }
            const me = this
            this.$nextTick(function () {
                window.addEventListener('resize', _.debounce(( ) => {
                    // me.$refs.filexplorer.$forceUpdate()
                    me.fileExplorerKey = me.fileExplorerKey + 1
                    console.log('resizing', me.fileExplorerKey)
                }, 1000))
            })
        },
        data() {
            return {
                fileExplorerKey: 1,
                supportsWebp: require('@/store/store').default.state.supportsWebp,
                text: require('@/data/text1.json'),
                links: [
                    {
                        path: 'text2',
                        name: 'COMPRAR SERIES, PELÍCULAS Y NOVELAS, MÁS ALLÁ DEL PAQUETE SEMANAL',
                    },
                    {
                        path: 'text3',
                        name: 'Santiago de Cuba: la ciudad clandestina del disco duro',
                    },
                    {
                        path: 'text4',
                        name: 'El paquete semanal llega a cada poblado cubano en camión, guagua o lo que aparezca',
                    },
                    {
                        path: 'text5',
                        name: '¿QUÉ TRAE EL PAQUETE SEMANAL?',
                    },
                ],
            }
        },
        computed: {
            title: function () {
                return get_text(this.text.doc[0])
            },
            author: function () {
                return this.text.doc[1][0].text
            },
            bannerImageSrcMd() {
                return this.supportsWebp ? require('@/assets/text1/banner-lg.webp'): require('@/assets/text1/banner-lg.png')
            },
            bannerImageSrcSm() {
                return this.supportsWebp ? require('@/assets/text1/banner-md.webp'): require('@/assets/text1/banner-md.png')
            },
            crossMiddleImageSrc(){
                return this.supportsWebp ? require('@/assets/text1/cross-image.webp') : require('@/assets/text1/cross-image.png')
            }
        },
        methods: {
            get_text
        }
    }
</script>

<style scoped>

</style>
