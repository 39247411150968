<template>
    <general-text :banner-image-src-md="bannerImageSrcMd"
                  :banner-image-src-sm="bannerImageSrcSm"
                  lazy-src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAATCAYAAABlcqYFAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAAAB3RJTUUH5AoTBBQoVQ/ElwAABD9JREFUOMutlUtsVGUUx3/ffczce+dO36VDp9LSgJVHAhHbSgkg4DNoiKImJhoWbEzQxEjQjSyMG92amJiokUTEjQtjNC4wooakIlSo2JICpS1MKZ1OmXZe9zX3fi6oDQokJPJtzrc4Ob/zP+d85xOWZWeANFDg3p8aYFJbuHCTvecg5f9GkFIiIwlSIgAJhBLETT7aXUcTgJRUvSqKqiCEwPUcJHLRxQDigIxpBLqFkNHdQ4SiUA18PM9FV3Q8P0AS8UDvOjoeXkPC1MiPZzl+bhrNqqXlyhTDmRFMQDHMu4MEro8kpO+57XQ9tpFcJsvcbIn1z2wmmTTA8TkX1uEv66G1WGHDzp2cq6tDab2PKJNBWJZdAJK3lyAgDKm4Fbof7aXpwH7aYh6rtCLHLgdczZXpqLEYiHciSjPI4gzFeCsMjtDe20FufQ+XX9tXvLMSoSDdCoRVFKChPc35yQr1epYZKoxfVrlgL2FSSTLtlmmaGCM1fIaooYXg+RcZ7eyiUi4Ry83IO09XuYQUCu7B94guXeXU03sIprKMGM2cKCVw1STeshTZ6hwycLA8Bz2VptL3CA256wQJg0CU0SbHv7qtkqgaIojo2LcHuesppkolpG6BrjLg12JYJrVyBm28QM35WdLhMDWqQvzBTfz15DZys2Ooc1dgUOCK9h9uCxG+g0x3U7E2k/7pR1rVMr4fYwSTxHyWmvpapvq2ov08T8sFh/bSIIVmm/zWRvSxSfwRi2i4DuxOotaOxK2NlxGRUyG5+yNo2061dByrWdKWyDH00AqsUOPxw4coavOMhTsY1Z9AVcroUZZCehWBm4JCI3hjiMGPvxe7al/RFh7pvxoOEJ79BLF8NX68B3f0In5NRMWsIZmJGMptxb90lrm2esprW6GzCWwDTl+FcPgUA58d4szhI0BefHAUDSizuLcEyCoaUHRqCX4fAAxSaZNYSy9Np7M4cznOLN+J3PIm6EC5gJj4FfXEt+hnv0Y0y7f8gnOsupi0hrAs+zeg9x+GUi7hrdtLuLQb49h+pFdG2A2Ya3ZR2/MsgdrIlVgXuBm0oW9Qx75DGz+FiG6kKOF+V1EuVuXCPtu2A2FZ9ofA6wDCLVFN9xKsfAHzl7enFcIvpZmYiBxHhmEkjfpUUO3Y0e5JJ29eODorykVdgpC6CGUgy1UldtJv3VQvVy95iZHjXzAx+QcAlmVvsSxbWpYtbZDaxjdkfMOrMgldMSC2sPTi3KgOC9YA9EQLmhpD1QyUhhWw+uX32TspOdgvWZnqW6yYZdkAnwJ7hVcibF5LdeVu5PSf74TFqZOEnklU9YkCCUKgmwpCNVHjjaS6m4n8Rpy8Scz2uD7UT340Q+BdxM9f+y8E4F2EckC4BVNaSz53mtYfkYqxFC9voOoamqWTbItRvqYQ+gI1HonQKcj58THyo/0IUUQzwLv1g/0bmEDYS0u+nmMAAAAASUVORK5CYII="
                  :links="links">
        <template #title>{{title}}</template>
        <template #author>{{author}}</template>

        <template #body>
            <text-block :links="links">
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(2, 43)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>
            <v-row class="ma-0">
                <v-col cols="12" class="pa-0">
                    <v-img eager contain :src="crossMiddleImageSrc" width="100%"/>
                </v-col>
            </v-row>
            <text-block>
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(43)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>
        </template>
    </general-text>
</template>

<script>
    import GeneralText from "../components/GeneralText";
    import TextBlock from "../components/texts/TextBlock";
    import {get_text} from "../utils";

    export default {
        name: "Text3",
        metaInfo: {
            // Children can override the title.
            title: 'EL PAQUETE SEMANAL',
            titleTemplate: '%s ← SANTIAGO DE CUBA: LA CIUDAD CLANDESTINA DEL DISCO DURO',
            // Define meta tags here.
            meta: [
                {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
                {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                {
                    name: 'description',
                    content: 'En Santiago de Cuba, la segunda ciudad en importancia en la Isla, el paquete semanal mantiene patrones de funcionamiento y sostenibilidad similares a los de otros territorios, pero posee características distintivas. Los contenidos locales y las soluciones de transportación entre las localidades son algunas de sus singularidades.'
                },
                {name: 'tags', content: 'paquetesemanal, paquetesemanalcuba, paquetecuba'},
                {name: 'author', content: 'Datalis <info@datalis.dev>'},
                // OpenGraph data (Most widely used)
                {property: 'og:title', content: 'SANTIAGO DE CUBA: LA CIUDAD CLANDESTINA DEL DISCO DURO'},
                {property: 'og:site_name', content: 'EL PAQUETE SEMANAL'},
                // The list of types is available here: http://ogp.me/#types
                {property: 'og:type', content: 'website'},
                // Should the the same as your canonical link, see below.
                {property: 'og:url', content: 'https://paquetesemanal.eltoque.com/text3'},
                {property: 'og:image', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'},
                // Often the same as your meta description, but not always.
                {property: 'og:description', content: 'En Santiago de Cuba, la segunda ciudad en importancia en la Isla, el paquete semanal mantiene patrones de funcionamiento y sostenibilidad similares a los de otros territorios, pero posee características distintivas. Los contenidos locales y las soluciones de transportación entre las localidades son algunas de sus singularidades.'},

                // Twitter card
                {name: 'twitter:card', content: 'summary'},
                {name: 'twitter:site', content: 'https://paquetesemanal.eltoque.com/text3'},
                {name: 'twitter:title', content: 'SANTIAGO DE CUBA: LA CIUDAD CLANDESTINA DEL DISCO DURO'},
                {name: 'twitter:description', content: 'En Santiago de Cuba, la segunda ciudad en importancia en la Isla, el paquete semanal mantiene patrones de funcionamiento y sostenibilidad similares a los de otros territorios, pero posee características distintivas. Los contenidos locales y las soluciones de transportación entre las localidades son algunas de sus singularidades.'},
                // Your twitter handle, if you have one.
                {name: 'twitter:creator', content: '@eltoquecom'},
                {name: 'twitter:image:src', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'},

                // Google / Schema.org markup:
                {itemprop: 'name', content: 'SANTIAGO DE CUBA: LA CIUDAD CLANDESTINA DEL DISCO DURO'},
                {itemprop: 'description', content: 'En Santiago de Cuba, la segunda ciudad en importancia en la Isla, el paquete semanal mantiene patrones de funcionamiento y sostenibilidad similares a los de otros territorios, pero posee características distintivas. Los contenidos locales y las soluciones de transportación entre las localidades son algunas de sus singularidades.'},
                {itemprop: 'image', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'}
            ]
        },
        components: {TextBlock, GeneralText},
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
                text: require('@/data/text3.json'),
                links: [
                    {
                        path: 'text1',
                        name: 'La red offline que conecta toda Cuba',
                    },
                    {
                        path: 'text2',
                        name: 'COMPRAR SERIES, PELÍCULAS Y NOVELAS, MÁS ALLÁ DEL PAQUETE SEMANAL',
                    },
                    {
                        path: 'text4',
                        name: 'El paquete semanal llega a cada poblado cubano en camión, guagua o lo que aparezca',
                    },
                    {
                        path: 'text5',
                        name: '¿QUÉ TRAE EL PAQUETE SEMANAL?',
                    },
                ],
            }
        },
        computed: {
            title: function () {
                return this.text.doc[0][0].text
            },
            author: function () {
                return this.text.doc[1][0].text
            },
            bannerImageSrcMd() {
                return this.supportsWebp ? require('@/assets/text3/banner-md.webp'): require('@/assets/text3/banner-md.png')
            },
            bannerImageSrcSm() {
                return this.supportsWebp ? require('@/assets/text3/banner-sm.webp'): require('@/assets/text3/banner-sm.png')
            },
            crossMiddleImageSrc(){
                return this.supportsWebp ? require('@/assets/text3/cross-middle-image.webp') : require('@/assets/text3/cross-middle-image.png')
            }
        },
        methods: {
            get_text
        }
    }
</script>

<style scoped>

</style>
