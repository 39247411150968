<template>
    <general-text :banner-image-src-md="bannerImageSrcMd"
                  :banner-image-src-sm="bannerImageSrcSm"
                  lazy-src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAMAAAD3JJ6EAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA4VBMVEXHaEnKTjEKCgwKCgwKCgwKCQsKCAoGAQMAAAAAAQcJCgzFqZodLy4KCgzqjXKtJwsKCgzscEXuXz4KCgxiMyX/j1nhYTvJWD4KCgwCBQgSBwv/g1j/c1DxcU3uckr/dkoKCgwKCw0LCgwKAAAAAAAAAAECCg8mJiMjCQQGAAAKBggGCw0IT1ImaW1aZWctm5lPuqmjim8XRkwKAgQDDQ4Akpsxl6HDkH7cjV68mIELCw0UEA6VgH7Sj37/UhPAhGsDBgkdEA7XTiDZf2mueXP6aTaiXDzQXDfhcUTtaTz/dEXLfnAqAAAAIHRSTlMAAOXy8fHx8fHy5f679PgF+PoQ4tnF/Q9P51IdP2GAcKodKe0AAAABYktHRAiG3pV6AAAACXBIWXMAACE4AAAhOAFFljFgAAAAB3RJTUUH5AoSEDMsKzTeIgAAAGBJREFUCNdjYGJmYWFlY+fg5GJQUFRSVlFVU9fQZGDW0tbR1dM3MDRiYDY2MTUzt7Dk5mHgtVK1trG1s+fjZxBwcHRydnF1ExRiEFYRERVz9/D0EmeQkJRikJaRlZNnBADCuwsrimeVqgAAAABJRU5ErkJggg=="
                  :links="links">
        <template #title>{{title}}</template>
        <template #author>{{author}}</template>
        <template #body>
            <text-block :links="links.slice(0,3)">
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(2, 7)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>

            <v-row class="ma-0" justify="end">
                <v-col cols="11" sm="8" md="8" class="text-block-container">
                    <v-container fluid class="pa-0">
<!--                        grafico de barras-->
                        <sizes-chart></sizes-chart>
                    </v-container>
                </v-col>
                <v-col cols="1" md="2"></v-col>
            </v-row>

            <text-block :links="links.slice(-1)">
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(7, 8)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>
            <v-row class="ma-0" justify="end">
                <v-col cols="11" sm="8" md="8" class="text-block-container">
                    <v-container fluid class="pa-0">
<!--                        grafico de contenido-->
                        <counter-chart></counter-chart>
                    </v-container>
                </v-col>
                <v-col cols="1" md="2"></v-col>
            </v-row>

            <text-block>
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(8, 10)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>
            <v-row class="ma-0" justify="end">
                <v-col cols="11" sm="8" md="8" class="text-block-container">
                    <v-container fluid class="pa-0">
                        <tree-map-chart :centered="true">
                            <template #title>
                                <chart-title>Tipos de contenido dentro del paquete semanal</chart-title>
                            </template>
                        </tree-map-chart>
                    </v-container>
                </v-col>
                <v-col cols="1" md="2"></v-col>
            </v-row>

            <text-block>
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(10, 16)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>

            <v-row class="ma-0">
                <v-col cols="12" class="pa-0">
                    <v-img eager contain :src="crossMiddleImageSrc" width="100%"/>
                </v-col>
            </v-row>
            <text-block>
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(16, 21)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>
            <v-row class="ma-0" justify="end">
                <v-col cols="11" sm="8" md="8" class="text-block-container">
                    <v-container fluid class="pa-0">
                        <top-table/>
                    </v-container>
                </v-col>
                <v-col cols="1" md="2"></v-col>
            </v-row>

            <text-block>
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(21)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>
        </template>
    </general-text>
</template>

<script>
    import GeneralText from "../components/GeneralText";
    import TextBlock from "../components/texts/TextBlock";
    import {get_text} from "../utils";
    import TopTable from '../components/text5/TopTable'
    import TreeMapChart from "../components/text5/TreeMapChart";
    import SizesChart from "../components/text5/SizesChart";
    import CounterChart from "../components/text5/CounterChart";
    import ChartTitle from "../components/text5/ChartTitle";

    export default {
        name: "Text5",
        metaInfo: {
            // Children can override the title.
            title: 'EL PAQUETE SEMANAL',
            titleTemplate: '%s ← ¿QUÉ TRAE EL PAQUETE SEMANAL?',
            // Define meta tags here.
            meta: [
                {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
                {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                {
                    name: 'description',
                    content: 'Las principales opciones de consumo cultural y entretenimiento llegan, servidas a la carta, en el paquete semanal. Por eso, miramos dentro de 246 paquetes entre 2017 y 2020 para saber cuál es su contenido; que incluye, entre otros, lo más reciente de la industria local e internacional en materia audiovisual y musical.'
                },
                {name: 'tags', content: 'paquetesemanal, paquetesemanalcuba, paquetecuba'},
                {name: 'author', content: 'Datalis <info@datalis.dev>'},
                // OpenGraph data (Most widely used)
                {property: 'og:title', content: '¿QUÉ TRAE EL PAQUETE SEMANAL?'},
                {property: 'og:site_name', content: 'EL PAQUETE SEMANAL'},
                // The list of types is available here: http://ogp.me/#types
                {property: 'og:type', content: 'website'},
                // Should the the same as your canonical link, see below.
                {property: 'og:url', content: 'https://paquetesemanal.eltoque.com/text5'},
                {property: 'og:image', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'},
                // Often the same as your meta description, but not always.
                {property: 'og:description', content: 'Las principales opciones de consumo cultural y entretenimiento llegan, servidas a la carta, en el paquete semanal. Por eso, miramos dentro de 246 paquetes entre 2017 y 2020 para saber cuál es su contenido; que incluye, entre otros, lo más reciente de la industria local e internacional en materia audiovisual y musical.'},

                // Twitter card
                {name: 'twitter:card', content: 'summary'},
                {name: 'twitter:site', content: 'https://paquetesemanal.eltoque.com/text5'},
                {name: 'twitter:title', content: '¿QUÉ TRAE EL PAQUETE SEMANAL?'},
                {name: 'twitter:description', content: 'Las principales opciones de consumo cultural y entretenimiento llegan, servidas a la carta, en el paquete semanal. Por eso, miramos dentro de 246 paquetes entre 2017 y 2020 para saber cuál es su contenido; que incluye, entre otros, lo más reciente de la industria local e internacional en materia audiovisual y musical.'},
                // Your twitter handle, if you have one.
                {name: 'twitter:creator', content: '@eltoquecom'},
                {name: 'twitter:image:src', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'},

                // Google / Schema.org markup:
                {itemprop: 'name', content: '¿QUÉ TRAE EL PAQUETE SEMANAL?'},
                {itemprop: 'description', content: 'Las principales opciones de consumo cultural y entretenimiento llegan, servidas a la carta, en el paquete semanal. Por eso, miramos dentro de 246 paquetes entre 2017 y 2020 para saber cuál es su contenido; que incluye, entre otros, lo más reciente de la industria local e internacional en materia audiovisual y musical.'},
                {itemprop: 'image', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'}
            ]
        },
        components: {ChartTitle, CounterChart, SizesChart, TreeMapChart, TextBlock, GeneralText, TopTable },
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
                text: require('@/data/text5.json'),
                links: [
                    {
                        path: 'text1',
                        name: 'La red offline que conecta toda Cuba',
                    },
                    {
                        path: 'text2',
                        name: 'COMPRAR SERIES, PELÍCULAS Y NOVELAS, MÁS ALLÁ DEL PAQUETE SEMANAL',
                    },
                    {
                        path: 'text3',
                        name: 'Santiago de Cuba: la ciudad clandestina del disco duro',
                    },
                    {
                        path: 'text4',
                        name: 'El paquete semanal llega a cada poblado cubano en camión, guagua o lo que aparezca',
                    },
                ],
            }
        },
        computed: {
            title: function () {
                return this.text.doc[0][0].text
            },
            author: function () {
                return this.text.doc[1][0].text
            },
            bannerImageSrcMd() {
                return this.supportsWebp ? require('@/assets/text5/banner-image-md.webp'): require('@/assets/text5/banner-image-md.png')
            },
            bannerImageSrcSm() {
                return this.supportsWebp ? require('@/assets/text5/banner-image-sm.webp'): require('@/assets/text5/banner-image-sm.png')
            },
            crossMiddleImageSrc(){
                return this.supportsWebp ? require('@/assets/text5/cross-middle-image.webp') : require('@/assets/text5/cross-middle-image.png')
            }
        },
        methods: {
            get_text
        }
    }
</script>

<style scoped>
    .echarts {
        width: 1000px;
        height: 500px;
    }
</style>
