<template>
    <v-row justify="center" no-gutters>
        <v-col cols="12">
            <v-img :src="footerUpSrc" height="102%" ></v-img>
        </v-col>
        <v-col cols="12">
            <v-row no-gutters style="background-color: #0a0a0c" justify="center">
                <v-col cols="12" class="pb-5 pt-16" align="center">
                    <metodology-btn></metodology-btn>
                </v-col>
                <v-col cols="10" >
                    <v-img :src="footerImgSrc" ></v-img>
                </v-col>
                <v-col cols="8" class="text-center">
                    <credits></credits>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import Credits from "./footer/Credits";
    import MetodologyBtn from "./footer/MetodologyBtn";
    export default {
        name: "MobileFooter",
        components: {MetodologyBtn, Credits},
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
            }
        },
        computed: {
            footerUpSrc() {
                return this.supportsWebp ? require('@/assets/footer-up.webp'): require('@/assets/footer-up.png')
            },
            footerImgSrc() {
                return this.supportsWebp ? require('@/assets/footer-img.webp'): require('@/assets/footer-img.png')
            },
        }
    }
</script>

<style scoped>

</style>
