<template>
    <text-card-type-one :trazado-src-sm="trazadoSrcSm"
                        :trazado-src-md="trazadoSrcMd"
                        :image-src="imgSrc"
                        read-more-link="text5">
        <template v-slot:title>
            <p class="mytitle">¿Qué trae el paquete semanal?</p>
        </template>
        <template #summary>
            Las principales opciones de consumo cultural y entretenimiento llegan, servidas a la carta, en el paquete semanal.
            Por eso, miramos dentro de 246 paquetes entre 2017 y 2020 para saber cuál
            es su contenido; que incluye, entre otros, lo más reciente de la industria local e internacional en materia audiovisual y musical.
        </template>
    </text-card-type-one>
</template>

<script>
    import TextCardTypeOne from "./TextCardTypeOne";

    export default {
        name: "TextCardFive",
        components: {TextCardTypeOne},
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
            }
        },
        computed: {
            trazadoSrcSm() {
                return this.supportsWebp? require(`@/assets/home/mobile/trazado5sm.webp`): require(`@/assets/home/mobile/trazado5sm.png`)
            },
            trazadoSrcMd() {
                return this.supportsWebp? require(`@/assets/home/trazado5.webp`):require(`@/assets/home/trazado5.png`)
            },
            imgSrc() {
                return this.supportsWebp? require(`@/assets/home/text5.webp`): require(`@/assets/home/text5.png`)
            },

        }
    }
</script>

<style scoped>

</style>
