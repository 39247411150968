<template>
    <div class="version-desk">
        <div style="height: 50px; background-color: #0a0a0c"></div>
        <v-img contain eager :src="bannerImgSrc" style="justify-content: flex-end; align-items: flex-end"
        lazy-src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAMAAACuAq9NAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABvFBMVEUsVK4lRIw/fv84becuV7UyYMkSAAAIBQIGAAASGzIHAQARGS0BAAAIBgYGAQA1Ztc1Z9kJBgYICAkKCgwKCgwKCgwKCQoKDBAGDRUIBQQFEiMLCw8LDBAHBAAJCAkDGjIIBgQJCAcwXMAsVbAACSAEAAAAAAkHBQU4beUIHjkAXmMWAAAZEhMAJ1kHFywLBwMGAQAOEh02ad4LJUoDBgQAAgoAIkoAFS42adw0ZtcAAAAiCwcNEhwWM1EADysAHkIADh81Ztc1Z9kGBgcGCRAAECcBH0IAJ1QLCw0CAQMMDA8BCBACDh0KCgwKCQsKCQoKCAcKCgsKCg0ICAoICg4DCRUFCA0MDA4HBwoJDRMKDRIQEBUNHSdVKS85Dw0ADiEgGyAQGSoGEBsLCAYIBQMIBgMMP3oNJT1JIA45HR8AAicaEx0QDAgJCQoHFkISISwQgH4IgogKg5QCJS8LAAAKCw0PBw08i4cirrQE5OYEwcolM0MRAgUJBQUJCQkcen82yM0LxcoA3ecgUlssFSMFGzMKCQkHBAAFWI0AcnUB2N0wHChfgHI4lJJufHSptaglJSYdHR0AAAABAQEB8zsNAAAATXRSTlMAAAAAAAAAAAAAAAAAAAAAAAAA4vLl7+/w8vBVDM/a8ekHBQfxk/FNA/HIV5O7dlRhDwPyUgZdIgQE8OuwtUJnPgEDzXtOlaI27p4jPuUpXPcAAAABYktHRDpOCcT6AAAACXBIWXMAABcRAAAXEQHKJvM/AAAAB3RJTUUH5AoJDzAaX8EsqwAAAL5JREFUCNdjEPYVgQNfUQYxP/+AQP9Af78gX19fEQbx4JDQsPCIyCh/MFciOiY2Lj4hMSnZNyVVkkEqLT0jMys7J9dXWkZWjkE+L7+gsKi4pFRBkUFJmUGlrLyisqq6prZOlZGJmUGtvqGxqbml1a+tXZ1Fg0Gzo7NLS1tHV0/fwJDViMG4u6fXhM3UzJydg9PCksGqr9/axtbO3oGLi9vRicF5wsRJLq5u7jy8fNz8AgwenpO9BHm8fXiEQFwA2AIwT8iPA4UAAAAASUVORK5CYII=">
            <v-row justify="end">
                <v-col cols="3" class="banner-text-container">
                    <p class="text-justify banner-text">
                        El paquete semanal, un compendio de información digital, permite a los cubanos disfrutar sin suscripciones de las últimas producciones de servicios internacionales de transmisión de audiovisuales como Netflix.
                        Este especial multimedia describe su proceso de conformación y distribución, así como el entramado comercial que lo ha hecho perdurable por más de una década. La investigación ha seguido las líneas del periodismo de soluciones, por lo que describe el fenómeno, a la vez que muestra cómo ha evolucionado y cuáles han sido las alternativas de adaptación de sus actores.
                    </p>
                </v-col>
                <v-col cols="1">
                </v-col>
            </v-row>
        </v-img>
    </div>
</template>

<script>
    import store from "@/store/store"
    export default {
        name: "DeskHomeBanner",
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
            }
        },
        computed: {
            bannerImgSrc() {
                console.log('support webp', store.state.supportsWebp);
                return this.supportsWebp ? require('@/assets/home/banner-image.webp'): require('@/assets/home/banner-image.png')
            }
        }
    }
</script>

<style scoped>
    .banner-text-container {
        margin-right: 5%;
        /*padding-bottom: 5%*/
    }

    @media screen and (max-width: 1080px) {
        .banner-text-container {
            margin-right: 5%;
            padding-bottom: 0;
        }
    }
</style>
