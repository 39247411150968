<template>
    <div>
        <m-image :src-lg="imageMd" :src-md="imageMd" :src-sm="imageSm"></m-image>
        <div class="mt-12 banner-text-container">
            <p class="text-justify banner-text">
                El paquete semanal, un compendio de información digital, permite a los cubanos disfrutar sin suscripciones de las últimas producciones de servicios internacionales de transmisión de audiovisuales como Netflix.
                Este especial multimedia describe su proceso de conformación y distribución, así como el entramado comercial que lo ha hecho perdurable por más de una década. La investigación ha seguido las líneas del periodismo de soluciones, por lo que describe el fenómeno, a la vez que muestra cómo ha evolucionado y cuáles han sido las alternativas de adaptación de sus actores.
            </p>
            <divider style="margin-top: 40px"/>
        </div>
    </div>
</template>

<script>
    import Divider from "../../divider";
    import MImage from "../../core/MImage";
    export default {
        name: "MobileHomeBanner",
        components: {MImage, Divider},
        props: ['imageSm', 'imageMd'],
    }
</script>

<style scoped>
    .banner-text-container {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
</style>
