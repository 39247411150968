<template>
    <div class="main-screen">
        <AppBar ref="appbar"/>
        <!-- Sizes your content based upon application components -->
        <v-main ref="main">
            <!-- Provides the application the proper gutter -->
            <v-container fluid class="pa-0">
                <!-- If using vue-router -->
                <div ref="sharedial">
                    <v-fab-transition>
                        <v-speed-dial
                                v-model="fab"
                                v-show="!isVisibleHeader"
                                fixed
                                bottom
                                right
                                direction="top"
                                transition="slide-y-reverse-transition">
                            <template v-slot:activator>
                                <v-fab-transition>
                                    <v-btn
                                            v-show="!isVisibleHeader"
                                            v-model="fab"
                                            color="black"
                                            fab
                                    >
                                        <v-icon v-if="fab" color="rgb(87, 208, 215)">
                                            fa fa-times
                                        </v-icon>
                                        <v-icon v-else color="rgb(87, 208, 215)">
                                            fa fa-share-alt
                                        </v-icon>
                                    </v-btn>
                                </v-fab-transition>
                            </template>
                            <v-btn class="ma-1" rounded icon elevation="2" style="background-color: #0002">
                                <twitter></twitter>
                            </v-btn>
                            <v-btn class="ma-1" rounded icon elevation="2" style="background-color: #0002">
                                <instagram></instagram>
                            </v-btn>
                            <v-btn class="ma-1" rounded icon elevation="2" style="background-color: #0002">
                                <facebook></facebook>
                            </v-btn>
                        </v-speed-dial>
                    </v-fab-transition>
                </div>

                <router-view></router-view>
            </v-container>
        </v-main>
        <v-footer padless style="background-color: transparent">
            <my-footer></my-footer>
        </v-footer>
    </div>
</template>

<script>
    import AppBar from "./core/AppBar";
    import MyFooter from "./core/MyFooter";
    import Twitter from "@/components/core/share-networks/twitter";
    import Instagram from "@/components/core/share-networks/instagram";
    import Facebook from "@/components/core/share-networks/facebook";

    export default {
        name: "Layout",
        components: {MyFooter, AppBar, Twitter, Instagram, Facebook},
        data: () => ({
            direction: 'top',
            fab: false,
            isVisibleHeader: true
        }),
        mounted() {
            const me = this
            window.addEventListener('scroll', function () {
                me.isVisibleHeader = !(window.scrollY > me.$refs.appbar.$el.offsetHeight + 16)
            })
        },
        computed: {
            showDial() {
                return this.fab && !this.isVisibleHeader

            }
        }
    }
</script>

<style>
    .main-screen {
        background-color: var(--background-color);
    }

    .banner-text {
        color: var(--primary-color);
    }

    p {
        color: white;
    }

    .mytitle { /*ver si se puede mover esto de aqui*/
        font-family: Lato-Bold, monospace;
        font-size: 12pt;
        line-height: 16pt;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    @media screen and (max-width: 400px) {
        .outline-container {
            padding-top: 41%;
        }

        .main-row-container {
        }
    }
</style>
