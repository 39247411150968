<template>
    <div>
        <MobileHomeBanner
                class="d-lg-none"
                :image-md="imageMd"
                :image-sm="imageSm">
        </MobileHomeBanner>
        <DeskHomeBanner class="d-none d-lg-block"></DeskHomeBanner>
    </div>
</template>

<script>
    import MobileHomeBanner from "./MobileHomeBanner";
    import DeskHomeBanner from "./DeskHomeBanner";

    export default {
        name: "HomeBanner",
        components: {DeskHomeBanner, MobileHomeBanner},
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
            }
        },
        computed: {
            imageMd() {
                return this.supportsWebp ? require('@/assets/home/mobile/mbanner-image-md.webp'): require('@/assets/home/mobile/mbanner-image-md.png')
            },
            imageSm() {
                return this.supportsWebp ? require('@/assets/home/mobile/mbanner-image-xs.webp'): require('@/assets/home/mobile/mbanner-image-xs.png')
            }
        }
    }
</script>

<style scoped>


</style>
