<template>
    <v-app-bar class="pl-0" color="transparent" prominent flat absolute>
        <home-mobile-app-bar :class="{'d-block': isHome, 'd-none': !isHome, 'd-md-none': true}"></home-mobile-app-bar>
        <home-desk-app-bar :class="{'d-none': true, 'd-md-block': isHome}"></home-desk-app-bar>
        <text-desk-app-bar :class="{'d-none': true, 'd-md-block': !isHome}"></text-desk-app-bar>
        <text-mobile-app-bar :class="{'d-block': !isHome, 'd-none': isHome, 'd-md-none': true}"></text-mobile-app-bar>
    </v-app-bar>
</template>

<script>
    import HomeDeskAppBar from "./AppBar/HomeDeskAppBar";
    import HomeMobileAppBar from "./AppBar/HomeMobileAppBar";
    import TextDeskAppBar from "./AppBar/TextDeskAppBar";
    import TextMobileAppBar from "./AppBar/TextMobileAppBar";
    export default {
        name: "AppBar",
        components: {TextMobileAppBar, TextDeskAppBar, HomeMobileAppBar, HomeDeskAppBar},
        // components: {TextDeskAppBar},
        computed: {
            isHome() {
                console.log('ishome');
                return this.$route.name === 'home';
            },
            logoColumnWidth() {
                if (this.$route.name !== 'home') {
                    return 4;
                } else {
                    return 2;
                }
            },
            headerTextColumnWidth() {
                if (this.$route.name !== 'home') {
                    return 7;
                } else {
                    return 8;
                }
            },
            homeLogoMaxWH() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return {height: '16px', witdth: 'inherit'};
                    case 'sm':
                        return {height: '16px', witdth: 'inherit'};
                    default:
                        return {height: '30px', witdth: '100%'};
                }
            },
            trazadoColumnWidth(){
                if (this.$route.name !== 'home') {
                    return 2;
                } else {
                    return 3;
                }
            }
        },
    }
</script>

<style scoped>
    .home-logo-container {
        display: flex;
        flex-direction: row-reverse;
    }

    .left-logo {
        margin-top: 10%;
        margin-left: 15%;
    }

    .header {
        margin-top: 30pt;
        /*padding-right: 30pt;*/
    }

    .header-subtitle {
        /*padding-right: 30pt;*/
    }

    .header-subtitle-text {
        font-family: Lato-Semibold;
        letter-spacing: 3px;
        font-size: 22pt;
        line-height: 26pt;
        text-transform: uppercase;
        margin-bottom: 0;
        color: #0063d6;
    }



    .inside-header-text {
        font-family: Lato-Light;
        letter-spacing: 6px;
        font-size: 36pt;
        line-height: 40pt;
        text-transform: uppercase;
    }


    @media screen and (max-width: 1080px) {
        .header {
            margin-top: 0;
            padding-right: 0;
        }

        .header-subtitle {
            padding-right: 0pt;
        }

        .home-header-text {
            font-family: Lato-Light;
            letter-spacing: 6px;
            font-size: 44pt;
            line-height: 44pt;
            text-transform: uppercase;
        }

        .inside-header-text {
            font-family: Lato-Light;
            letter-spacing: 6px;
            font-size: 32pt;
            line-height: 36pt;
            text-transform: uppercase;
        }
    }

    @media screen and (max-width: 900px) {
        .header {
            margin-top: 0;
            padding-right: 0;
        }

        .header-subtitle {
            padding-right: 0pt;
        }

        .inside-header-text {
            font-family: Lato-Light;
            letter-spacing: 6px;
            font-size: 30pt;
            line-height: 34pt;
            text-transform: uppercase;
        }
    }

    @media screen and (max-width: 600px) {
        .header {
            margin-top: 5px;
        }


        .home-header-text {
            font-family: Lato-Light;
            letter-spacing: 6px;
            font-size: 30pt;
            line-height: 30pt;
            text-transform: uppercase;
        }

        .header-subtitle-text {
            font-family: Lato-Semibold;
            letter-spacing: 3px;
            font-size: 10pt;
            line-height: 14pt;
            text-transform: uppercase;
            margin-bottom: 0;
            color: #0063d6;
        }

        .inside-header-text {
            font-family: Lato-Light;
            letter-spacing: 6px;
            font-size: 22pt;
            line-height: 22pt;
            text-transform: uppercase;
        }
    }

    @media screen and (max-width: 450px) {
        .header-text1 {
            padding-right: 18px;
        }

        .home-header-text {
            font-family: Lato-Light, sans-serif;
            margin: 0;
            letter-spacing: 6px;
            font-size: 25pt;
            line-height: 30pt;
            text-transform: uppercase;
        }


        .inside-header-text {
            font-family: Lato-Light, sans-serif;
            letter-spacing: 4px;
            font-size: 16pt;
            line-height: 16pt;
            text-transform: uppercase;
            margin-bottom: 8pt;
        }

        .home-logo-container {
            display: flex;
            flex-direction: row-reverse;
        }
    }
</style>
