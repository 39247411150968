<template>
    <v-container class="pa-0 ma-0" fluid>
        <v-row no-gutters justify="center">
            <v-col cols="10" lg="4" md="5" sm="6">
                <p class="mytitle text-center mb-14 map-title" style="color: var(--primary-color)">Tipos de contenido dentro del paquete semanal</p>
            </v-col>
            <v-col cols="10">
                <tree-map-chart :centered="true"></tree-map-chart>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
    import TreeMapChart from "../text5/TreeMapChart";
    export default {
        name: "TreeMapChartContainer",
        components: {TreeMapChart}
    }
</script>

<style scoped>

</style>
