<template>
    <v-container>
        <p class="footer-title">Créditos:</p>
        <div :key="index" v-for="(c, index) in credits">
            <p class="credit-title">{{c.title}}: <span class="credit-names">{{c.people.join(', ')}}.</span></p>
        </div>
        <div class="logos-container">
            <div class="logo-img-container" style="max-width: 100pt;">
                <v-img :src="require('@/assets/logos/eltoque.png')" contain max-height="100%" height="100%" ></v-img>
            </div>
            <div class="logo-img-container" style="max-width: 40pt">
                <v-img :src="require('@/assets/logos/icfb.png')" contain max-height="100%" height="100%"></v-img>
            </div>
        </div>
    </v-container>
</template>

<script>
    export default {
        name: "Credits",
        data() {
            return {
                credits: require('@/data/credits.json').items
            }
        }
    }
</script>

<style scoped>
    .footer-title {
        color: var(--secondary-color2);
        text-transform: uppercase;
        font-family: Lato-Bold;
    }

    .credit-title {
        color: var(--primary-color);
        font-family: Lato-Bold;
        text-transform: uppercase
    }

    .credit-names {
        color: var(--credits-color);
        text-transform: none;
        font-family: Lato-Medium;
    }

    .logos-container {
        display: flex; flex-direction: row; justify-content: flex-end;

    }
    .logo-img-container {
        flex: 1; height: 20pt !important;
    }
    @media screen and (max-width: 960px) {
        .logos-container {
            display: flex; flex-direction: column; align-items: center;
        }
        .logo-img-container {
            flex: 1; height: 20pt !important;
            margin-top: 10pt;
        }
    }

    @media screen and (max-width: 1160px) and (min-width: 1024px){
        .credit-title {
            color: var(--primary-color);
            font-family: Lato-Bold;
            text-transform: uppercase;
            font-size: 12pt;
            /*margin-bottom: 8px;*/
        }

        .credit-names {
            color: var(--credits-color);
            text-transform: none;
            font-family: Lato-Medium;
            font-size: 10pt;

        }
    }

    @media screen and (max-width: 1024px) and (min-width: 960px) {
        .credit-title {
            color: var(--primary-color);
            font-family: Lato-Bold;
            text-transform: uppercase;
            font-size: 10pt;
            margin-bottom: 8px;
        }

        .credit-names {
            color: var(--credits-color);
            text-transform: none;
            font-family: Lato-Medium;
            font-size: 8pt;

        }
    }
</style>
