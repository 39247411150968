<template>
    <div  class="d-flex flex-column" style="width: fit-content">
        <twitter></twitter>
        <instagram></instagram>
        <facebook></facebook>
    </div>
</template>

<script>
    import Twitter from "./share-networks/twitter";
    import Instagram from "./share-networks/instagram";
    import Facebook from "./share-networks/facebook";
    export default {
        name: "ShareSocialNetwork",
        components: {Facebook, Instagram, Twitter},
    }
</script>

<style scoped>


</style>
