<template>
    <div class="banner-image-container">
        <div class="banner-top-margin"></div>
        <m-image :src-lg="bannerImageSrcLg" :src-md="bannerImageSrcMd" :src-sm="bannerImageSrcSm" :lazy-src="lazySrc"
                 width="100%"/>
    </div>
</template>

<script>
    import MImage from "../core/MImage";

    export default {
        name: "Banner",
        components: {MImage},
        props: ['bannerImageSrcLg', 'bannerImageSrcMd', 'bannerImageSrcSm', 'lazySrc']
    }
</script>

<style scoped>


    .banner-top-margin {
        height: 100pt;
        background-color: var(--image-background-color);
        margin-bottom: -2px;
    }
</style>
