<template>
    <v-container class="pa-0" fluid>
        <mobile-footer class="d-flex d-md-none ma-0"></mobile-footer>
        <desk-footer class="d-none d-md-flex"></desk-footer>
    </v-container>
</template>

<script>

    import DeskFooter from "./DeskFooter";
    import MobileFooter from "./MobileFooter";
    export default {
        name: "MyFooter",
        components: {MobileFooter, DeskFooter},
    }
</script>

<style scoped>

</style>
