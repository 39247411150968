exports.get_text = function (paragraph) {
    /*
     {
"type": "p",
"text": "Comprar series, pel\u00edculas y novelas, m\u00e1s all\u00e1 del paquete semanal",
"properties": {}
}
{
"type": "a",
"text": "Conoce Cuba",
"properties": {
"target": "https://conocecuba.com/garajedigital",
"style": "InternetLink"
}
},
    * **/
    // console.log(paragraph)
    const get_style = (strStyle) => {
        let style = "";
        if (strStyle === 'Emphasis' || strStyle === 'StrongEmphasis') {
            style = style + 'font-style: italic; '
        }
        return style;
    };
    const reducer = (acc, cur) => {
        // console.log(cur)
        if (cur.type === 'title') {
            console.log(cur.text);
            return acc + ` <span class="section-title">${cur.text}</span>`
        } else if (cur.type === 'p') {
            // console.log(cur)
            const style = get_style(cur.properties.style);
            // console.log(style)
            if (style.trim() !== '') {
                return acc + `<span style="${style}">${cur.text}</span>`
                // return  acc + `<span style="font-style: italic">${cur.text}</span>`
            } else {
                return acc + cur.text
            }
        } else if (cur.type === 'a') {
            return acc + ' <a target="_blank"' + ' href="' + cur.properties.target + '">' +  cur.text + '</a>'
        } else if (cur.type === 'bookmark') {
            if (cur.properties.bookmark_type === 'bookmark_ref') {
                return acc + `<a href="#${cur.properties.bookmark_id}">${cur.properties.texts[0].text}</a>`
            } else if (cur.properties.bookmark_type === 'bookmark') {
                return acc + `<span id="${cur.properties.bookmark_id}">${cur.properties.texts[0].text}</span>`
            } else {
                return acc
            }
        } else {
            return acc
        }
    };
    const _text = paragraph.reduce(reducer, '');
    // console.log(_text)
    return _text
};
