import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/components/Layout'
import Home from './views/Home'
import Text1 from "./views/Text1";
import Text2 from "./views/Text2";
import Text3 from "./views/Text3";
import Text4 from "./views/Text4";
import Text5 from "./views/Text5";
import ChartsView from "./views/ChartsView";

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Layout,
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: Home
                },
                {
                    path: '/red-offline-conecta-cuba',
                    name: 'text1',
                    component: Text1
                },
                {
                    path: '/comprar-series-peliculas-cuba',
                    name: 'text2',
                    component: Text2
                },
                {
                    path: '/en-santiago-de-cuba',
                    name: 'text3',
                    component: Text3
                },
                {
                    path: '/en-poblados-cuba',
                    name: 'text4',
                    component: Text4
                },
                {
                    path: '/contenidos',
                    name: 'text5',
                    component: Text5
                }
            ]
        },
        {
            path: '/graficos-contenido-paquete',
            name: 'charts',
            component: ChartsView
        }
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})
