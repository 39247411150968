<template>
    <text-card-type-one :trazado-src-sm="trazadoSrcSm"
                        :trazado-src-md="trazadoSrcMd"
                        :image-src="imageSrc"
                        read-more-link="text1">
        <template v-slot:title>
            <p class="mytitle">La red <span style="font-style: italic">offline</span> que conecta toda Cuba</p>

        </template>
        <template #summary>
            El modelo de negocio del paquete semanal ha demostrado ser sostenible y escalable.
            A su vez, las soluciones tecnológicas y logísticas aplicadas por sus actores lo convierten en un mecanismo que,
            aunque con fallas, sigue funcionando para clientes y paqueteros.
        </template>
    </text-card-type-one>
</template>

<script>
    import TextCardTypeOne from "./TextCardTypeOne";

    export default {
        name: "TextCardOne",
        components: {TextCardTypeOne},
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
            }
        },
        computed: {
            trazadoSrcSm() {
                return this.supportsWebp ? require(`@/assets/home/mobile/trazado1.webp`): require(`@/assets/home/mobile/trazado1.png`)
            },
            trazadoSrcMd() {
                return this.supportsWebp ? require(`@/assets/home/trazado2.webp`): require(`@/assets/home/trazado2.png`)
            },
            imageSrc(){
                return this.supportsWebp? require(`@/assets/home/text1.webp`): require(`@/assets/home/text1.png`)
            }
        }
    }
</script>

<style scoped>

</style>
