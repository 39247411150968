<template>
<home-desk-app-bar>
    <template #right-col-content>
        <router-link to="/" style="text-decoration: none; width: fit-content">
            <div class="d-flex justify-center">
                <v-img contain eager :src="require('@/assets/HOME.png')">
                </v-img>
            </div>
        </router-link>
        <div class="d-flex justify-center">
            <share-social-network></share-social-network>
        </div>
    </template>
</home-desk-app-bar>
</template>

<script>
    import ShareSocialNetwork from "../ShareSocialNetwork";
    import HomeDeskAppBar from "./HomeDeskAppBar";
    export default {
        name: "TextDeskAppBar",
        components: {HomeDeskAppBar, ShareSocialNetwork}
    }
</script>

<style scoped>

</style>
