<template>
    <v-container fluid class="pa-0">
        <v-row justify="space-around" no-gutters>
            <v-col cols="8" sm="6" md="5" lg="5">
                <p class="charts-title text-center mb-14">Las siguientes gráficas fueron elaboradas gracias a la colaboración de 204 seguidores en nuestros diferentes canales.</p>
            </v-col>
            <v-col cols="10">
                <v-row justify="space-around">
                    <v-col cols="12" sm="6" class="px-md-6">
                        <v-img :src="chart1Src"></v-img>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-10 px-md-16">
                        <v-img :src="chart2Src"></v-img>
                    </v-col>
                </v-row>
                <v-row justify="space-around">
                    <v-col cols="12" sm="6" class="pl-6 px-md-12">
                        <v-img :src="chart3Src"></v-img>
                    </v-col>
                    <v-col cols="12" sm="6" class="pl-6 pl-md-16">
                        <v-img :src="chart4Src"></v-img>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
    export default {
        name: "HomeCharts",
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
            }
        },
        computed: {
            chart1Src() {
                return this.supportsWebp ? require('@/assets/charts/chart1.webp'): require('@/assets/charts/chart1.png')
            },
            chart2Src() {
                return this.supportsWebp ? require('@/assets/charts/chart2-md.webp'): require('@/assets/charts/chart2-md.png')
            },
            chart3Src() {
                return this.supportsWebp ? require('@/assets/charts/chart3.webp'): require('@/assets/charts/chart3.png')
            },
            chart4Src() {
                return this.supportsWebp ? require('@/assets/charts/chart4-md.webp'): require('@/assets/charts/chart4-md.png')
            },
        }
    }
</script>

<style scoped>
    .charts-title {
        color: var(--primary-color);
        font-family: Lato-Semibold;
        font-size: 20pt;
        line-height: 24pt;
    }

    @media screen and (max-width: 1300px) {
        .charts-title {
            font-size: 16pt;
            line-height: 20pt;
        }
    }

    @media screen and (max-width: 1080px) {
        .charts-title {
            font-size: 14pt;
            line-height: 20pt;
        }
    }

    @media screen and (max-width: 800px) {
        .charts-title {
            font-size: 13pt;
            line-height: 17pt;
        }
    }
</style>
