<template>
    <ShareNetwork
            :network="network"
            url="https://paquetesemanal.eltoque.com/"
            title="El Paquete Semanal: Alternativa ante la desconexión en Cuba"
            description="El paquete semanal de Cuba sigue funcionando tras más de una década. Conoce sus dinámicas y flujos de distribución. En este especial multimedia hablan sus protagonistas."
            quote="El paquete semanal de Cuba sigue funcionando tras más de una década. Conoce sus dinámicas y flujos de distribución. En este especial multimedia hablan sus protagonistas."
            hashtags="paquetesemanal, paquetesemanalcuba, paquetecuba"
    >
<!--        <v-btn class="ma-0" rounded icon elevation="2" style="background-color: #0002">-->
            <slot>

                <v-icon class="app-bar-social-icon" size="18">

                        fa fa-twitter

                </v-icon>
            </slot>
<!--        </v-btn>-->
    </ShareNetwork>
</template>

<script>
    export default {
        name: "GeneralNetwork",
        props: ['network', 'url', 'title', 'description', 'quote', 'hashtags']
    }
</script>

<style scoped>

</style>
