<template>
    <general-text :banner-image-src-md="bannerImageSrcMd"
                  :banner-image-src-sm="bannerImageSrcSm"
                  lazy-src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAMAAAD3JJ6EAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA4VBMVEXHaEnKTjEKCgwKCgwKCgwKCQsKCAoGAQMAAAAAAQcJCgzFqZodLy4KCgzqjXKtJwsKCgzscEXuXz4KCgxiMyX/j1nhYTvJWD4KCgwCBQgSBwv/g1j/c1DxcU3uckr/dkoKCgwKCw0LCgwKAAAAAAAAAAECCg8mJiMjCQQGAAAKBggGCw0IT1ImaW1aZWctm5lPuqmjim8XRkwKAgQDDQ4Akpsxl6HDkH7cjV68mIELCw0UEA6VgH7Sj37/UhPAhGsDBgkdEA7XTiDZf2mueXP6aTaiXDzQXDfhcUTtaTz/dEXLfnAqAAAAIHRSTlMAAOXy8fHx8fHy5f679PgF+PoQ4tnF/Q9P51IdP2GAcKodKe0AAAABYktHRAiG3pV6AAAACXBIWXMAACE4AAAhOAFFljFgAAAAB3RJTUUH5AoSEDMsKzTeIgAAAGBJREFUCNdjYGJmYWFlY+fg5GJQUFRSVlFVU9fQZGDW0tbR1dM3MDRiYDY2MTUzt7Dk5mHgtVK1trG1s+fjZxBwcHRydnF1ExRiEFYRERVz9/D0EmeQkJRikJaRlZNnBADCuwsrimeVqgAAAABJRU5ErkJggg=="
                    :links="links">
        <template #title>{{title}}</template>
        <template #author>{{author}}</template>
        <template #body>
            <text-block :links="links">
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(2, 37)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>
            <v-row class="ma-0">
                <v-col cols="12" class="pa-0">
                    <v-img eager contain :src="crossMiddleImageSrc" width="100%"/>
                </v-col>
            </v-row>
            <text-block>
                <template #text>
                    <p v-for="(paragraph, index) in text.doc.slice(37)" :key="index" class="text-left"
                       v-html="get_text(paragraph)">
                    </p>
                </template>
            </text-block>
        </template>
    </general-text>
</template>


<script>
    import GeneralText from "../components/GeneralText";
    import TextBlock from "../components/texts/TextBlock";
    import {get_text} from "../utils";

    export default {
        name: "Text4",
        metaInfo: {
            // Children can override the title.
            title: 'EL PAQUETE SEMANAL',
            titleTemplate: '%s ← EL PAQUETE SEMANAL LLEGA A CADA POBLADO EN CAMIÓN, GUAGUA O LO QUE APAREZCA',
            // Define meta tags here.
            meta: [
                {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
                {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                {
                    name: 'description',
                    content: 'En San Antonio de Cabezas, Guanajay, Amancio Rodríguez, Gibara, Santo Domingo el paquete semanal es una importante fuente de entretenimiento. Hay quienes han encontrado el negocio perfecto o una vía para matar el tedio que abunda en cada poblado del interior de la Isla.'
                },
                {name: 'tags', content: 'paquetesemanal, paquetesemanalcuba, paquetecuba'},
                {name: 'author', content: 'Datalis <info@datalis.dev>'},
                // OpenGraph data (Most widely used)
                {property: 'og:title', content: 'EL PAQUETE SEMANAL LLEGA A CADA POBLADO EN CAMIÓN, GUAGUA O LO QUE APAREZCA'},
                {property: 'og:site_name', content: 'EL PAQUETE SEMANAL'},
                // The list of types is available here: http://ogp.me/#types
                {property: 'og:type', content: 'website'},
                // Should the the same as your canonical link, see below.
                {property: 'og:url', content: 'https://paquetesemanal.eltoque.com/text4'},
                {property: 'og:image', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'},
                // Often the same as your meta description, but not always.
                {property: 'og:description', content: 'En San Antonio de Cabezas, Guanajay, Amancio Rodríguez, Gibara, Santo Domingo el paquete semanal es una importante fuente de entretenimiento. Hay quienes han encontrado el negocio perfecto o una vía para matar el tedio que abunda en cada poblado del interior de la Isla.'},

                // Twitter card
                {name: 'twitter:card', content: 'summary'},
                {name: 'twitter:site', content: 'https://paquetesemanal.eltoque.com/text4'},
                {name: 'twitter:title', content: 'EL PAQUETE SEMANAL LLEGA A CADA POBLADO EN CAMIÓN, GUAGUA O LO QUE APAREZCA'},
                {name: 'twitter:description', content: 'En San Antonio de Cabezas, Guanajay, Amancio Rodríguez, Gibara, Santo Domingo el paquete semanal es una importante fuente de entretenimiento. Hay quienes han encontrado el negocio perfecto o una vía para matar el tedio que abunda en cada poblado del interior de la Isla.'},
                // Your twitter handle, if you have one.
                {name: 'twitter:creator', content: '@eltoquecom'},
                {name: 'twitter:image:src', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'},

                // Google / Schema.org markup:
                {itemprop: 'name', content: 'EL PAQUETE SEMANAL LLEGA A CADA POBLADO EN CAMIÓN, GUAGUA O LO QUE APAREZCA'},
                {itemprop: 'description', content: 'En San Antonio de Cabezas, Guanajay, Amancio Rodríguez, Gibara, Santo Domingo el paquete semanal es una importante fuente de entretenimiento. Hay quienes han encontrado el negocio perfecto o una vía para matar el tedio que abunda en cada poblado del interior de la Isla.'},
                {itemprop: 'image', content: 'https://res.cloudinary.com/hmhkzirji/image/upload/v1612399291/IMG_8854_uerxdp.jpg'}
            ]
        },
        components: {TextBlock, GeneralText},
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
                text: require('@/data/text4.json'),
                links: [
                    {
                        path: 'text1',
                        name: 'La red offline que conecta toda Cuba',
                    },
                    {
                        path: 'text2',
                        name: 'COMPRAR SERIES, PELÍCULAS Y NOVELAS, MÁS ALLÁ DEL PAQUETE SEMANAL',
                    },
                    {
                        path: 'text3',
                        name: 'Santiago de Cuba: la ciudad clandestina del disco duro',
                    },
                    {
                        path: 'text5',
                        name: '¿QUÉ TRAE EL PAQUETE SEMANAL?',
                    },
                ],
            }
        },
        computed: {
            title: function () {
                return this.text.doc[0][0].text
            },
            author: function () {
                return this.text.doc[1][0].text
            },
            bannerImageSrcMd() {
                return this.supportsWebp ? require('@/assets/text4/banner-md.webp'): require('@/assets/text4/banner-md.png')
            },
            bannerImageSrcSm() {
                return this.supportsWebp ? require('@/assets/text4/banner-sm.webp'): require('@/assets/text4/banner-sm.png')
            },
            crossMiddleImageSrc(){
                return this.supportsWebp ? require('@/assets/text4/cross-middle-image.webp') : require('@/assets/text4/cross-middle-image.png')
            }
        },
        methods: {
            get_text
        }
    }
</script>

<style scoped>

</style>
