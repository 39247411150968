import { render, staticRenderFns } from "./TextCardThree.vue?vue&type=template&id=7066ef98&scoped=true&"
import script from "./TextCardThree.vue?vue&type=script&lang=js&"
export * from "./TextCardThree.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7066ef98",
  null
  
)

export default component.exports