<template>
    <v-container fluid class="pa-0 my-mobile-app-bar-container">
        <slot>
        <v-row justify="end">
                <v-col cols="9" md="10">
                    <header-col></header-col>
                </v-col>
                <v-col cols="2" class="d-flex align-content-center justify-center">
                    <slot name="middle-col-content">
                        <a href="https://eltoque.com/" target="_blank" style="text-decoration: none; width: min-content">
                            <v-img contain eager src="@/assets/logos/elToqueShort.png"
                                   max-width='25%' class="ml-10"></v-img>
                        </a>
                    </slot>
                </v-col>
                <v-col cols="2" class="d-flex justify-center social-share-container">
                    <slot name="right-col-content">
                        <share-social-network>  </share-social-network>
                    </slot>
                </v-col>
        </v-row>
        </slot>
    </v-container>
</template>

<script>
    import ShareSocialNetwork from "../ShareSocialNetwork";
    import HeaderCol from "./HeaderCol";
    export default {
        name: "HomeMobileAppBar",
        components: {HeaderCol, ShareSocialNetwork}
    }
</script>

<style scoped>
    .my-mobile-app-bar-container {
        margin-top: 44pt;
    }
    .social-share-container {
        /*display: flex;*/
        /*flex-direction: column;*/
        /*align-items: center;*/
        margin-top: 20%
    }

    @media screen and (max-width: 780px) {
        .social-share-container {
            margin-top: 15%
        }
    }

    @media screen and (max-width: 700px) {
       .my-mobile-app-bar-container {
             margin-top: 35pt;
         }
    }


    @media screen and (max-width: 520px) {
        .my-mobile-app-bar-container {
            margin-top: 20pt;
        }
        .social-share-container {
            margin-top: 14%
        }
    }

    @media screen and (max-width: 450px) {
        .social-share-container {
            margin-top: 6%
        }

        .my-mobile-app-bar-container {
            margin-top: 15pt;
        }
    }

</style>
