<template>
    <v-container fluid class="pa-0 home-container">
        <home-banner></home-banner>
        <v-divider class="general-divider cero-divider" role="presentation"></v-divider>
        <video-card-two></video-card-two>
        <v-divider class="general-divider first-divider" role="presentation" style="margin-top: 50pt"></v-divider>
        <text-card-one></text-card-one>
        <v-divider class="general-divider second-divider" role="presentation"></v-divider>
        <text-card-two></text-card-two>
        <v-divider class="general-divider third-divider" role="presentation"></v-divider>
        <text-card-three></text-card-three>
        <v-divider class="general-divider four-divider" role="presentation"></v-divider>
        <text-card-four></text-card-four>
        <v-divider class="general-divider third-divider" role="presentation"></v-divider>
        <text-card-five></text-card-five>
        <v-divider role="presentation" class="general-divider video-divider infography-divider"></v-divider>
        <tree-map-chart-container class="mb-16"></tree-map-chart-container>
        <v-divider role="presentation" class="general-divider video-divider" style="margin-top: 50pt"></v-divider>
        <video-card></video-card>
        <v-divider role="presentation" class="general-divider video-divider infography-divider"></v-divider>
        <infography></infography>
        <v-divider role="presentation" class="general-divider video-divider infography-divider"></v-divider>
        <home-charts></home-charts>
<!--        <Map></Map>-->
    </v-container>
</template>

<script>
    import HomeBanner from "../components/home/banner/HomeBanner";
    import TextCardOne from "../components/home/cards/TextCardOne";
    import TextCardTwo from "../components/home/cards/TextCardTwo";
    import VideoCard from "../components/home/cards/VideoCard";
    import VideoCardTwo from "../components/home/cards/VideoCardTwo";
    import TextCardThree from "../components/home/cards/TextCardThree";
    import TextCardFour from "../components/home/cards/TextCardFour";
    import TextCardFive from "../components/home/cards/TextCardFive";
    import HomeCharts from "../components/home/HomeCharts";
    import Infography from "../components/home/Infography";
    import TreeMapChartContainer from "../components/home/TreeMapChartContainer";

    export default {
        name: 'Home',
        components: {
            TreeMapChartContainer,
            Infography,
            HomeCharts,
            TextCardFive, TextCardFour, TextCardThree, TextCardOne, TextCardTwo, VideoCard, VideoCardTwo, HomeBanner
            },
    }
</script>

<style>
    .home-container {
        padding-top: 20px
    }

    .mytitle {
        font-family: Lato-Bold, monospace;
        font-size: 20pt;
        line-height: 24pt;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .card-text {
        font-family: Lato-Regular, monospace;
        font-size: 11pt !important;
        line-height: 13pt;
        letter-spacing: .5px;
        margin-bottom: 30px;
    }

    .general-divider {
        width: 50%;
        background-color: var(--primary-color);
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }


    .cero-divider {
        width: 50%;
        margin-left: 25%;
        margin-right: 40%;
        margin-bottom: -50pt;
    }
    
    .first-divider {
        width: 35%;
        margin-left: 25%;
        margin-right: 40%;
        margin-bottom: -50pt;
    }

    .second-divider {
        margin-top: -25pt;
        margin-bottom: -20pt
    }

    .third-divider {
        margin-top: 30pt;
        margin-bottom: 20pt;
    }

    .four-divider {
        margin-top: 0;
        margin-bottom: 20pt;
    }

    .video-divider {
        background-color: var(--secondary-color2);
    }

    .infography-divider {
        margin-top: 100px;
        margin-bottom: 80px;
    }

    @media screen and (max-width: 1300px) {
        .mytitle {
            font-family: Lato-Bold, monospace;
            font-size: 16pt;
            line-height: 18pt;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .card-text {
            font-family: Lato-Regular, monospace;
            font-size: 10pt !important;
            line-height: 11pt;
            letter-spacing: .5px;
            margin-bottom: 30px;
        }
    }

    @media screen and (max-width: 1263px) {

       .cero-divider {
            /*width: 35%;*/
            background-color: transparent;
            /*margin-bottom: 0;*/
        }
    }

    @media screen and (max-width: 1080px) {
        .mytitle {
            font-family: Lato-Bold, monospace;
            font-size: 14pt;
            line-height: 16pt;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .card-text {
            font-family: Lato-Regular, monospace;
            font-size: 10pt !important;
            line-height: 11pt;
            letter-spacing: .5px;
            margin-bottom: 30px;
        }
    }


    @media screen and (max-width: 1024px) {
        .first-divider {
            margin-bottom: -30pt;
        }
    }


    @media screen and (max-width: 960px) {
        .first-divider {
            width: 35%;
            background-color: transparent;
            margin-bottom: -20pt;
        }

        .second-divider {
            width: 68%;
            margin-top: 25pt;
            margin-bottom: 25pt
        }
        .third-divider {
            width: 68%;
        }

        .four-divider {
            margin-top: 20pt;
            width: 68%;
        }
        .video-divider {
            width: 68%;
        }
    }

    @media screen and (max-width: 800px) {
        .second-divider {
            margin-bottom: 40pt;
        }

        .third-divider {
            margin-bottom: 40pt;
            margin-top: 40pt;
        }

        .mytitle {
            font-family: Lato-Bold, monospace;
            font-size: 13pt;
            line-height: 15pt;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .card-text {
            font-family: Lato-Regular, monospace;
            font-size: 10pt !important;
            line-height: 11pt;
            letter-spacing: .5px;
            margin-bottom: 30px;
        }
    }

    @media screen and (max-width: 600px) {
        .general-divider {
            width: 68%;
        }
    }

</style>
