<template>
    <v-container class="pa-0" fluid>
        <v-row justify="center" no-gutters>
            <v-col cols="8" lg="4" md="5" sm="6">
                <p class="text-center mytitle mb-14" style="color: var(--primary-color)">Cadena de distribución del paquete semanal</p>
            </v-col>
            <v-col cols="12" class="pa-0">
                <v-img :src="infographySrc"></v-img>
            </v-col>
            <v-col cols="1" md="10" >
                <v-row no-gutters align="center" justify="center" style="height: 100%" class="py-16 pb-md-0 px-md-0">
                    <v-col cols="12" md="1" class="ml-md-12">
                       <p class="number-text text-center">1</p>
                    </v-col>
                    <v-col cols="12" md="1" align="center">
                        <component :is="pointComponent" :width="verticalPointsWidth"></component>
                    </v-col>
                    <v-col cols="12" md="1" align="center">
                        <p class="number-text text-center">2</p>
                    </v-col>
                    <v-col cols="12" md="1" align="center">
                        <component :is="pointComponent" :width="verticalPointsWidth"></component>
                    </v-col>
                    <v-col cols="12" md="1">
                        <p class="number-text text-center">3</p>
                    </v-col>
                    <v-col cols="12" md="1" align="center">
                        <component :is="pointComponent" :width="verticalPointsWidth"></component>
                    </v-col>
                    <v-col cols="12" md="1">
                        <p class="number-text text-center">4</p>
                    </v-col>
                    <v-col cols="12" md="1" align="center">
                        <component :is="pointComponent" :width="verticalPointsWidth"></component>
                    </v-col>
                    <v-col cols="12" md="1">
                        <p class="number-text text-center">5</p>
                    </v-col>
                    <v-col cols="12" md="1" align="center">
                        <component :is="pointComponent" :width="verticalPointsWidth"></component>
                    </v-col>
                    <v-col cols="12" md="1">
                        <p class="number-text text-center">6</p>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="8" md="10" class="pa-0">
                <v-row no-gutters align="center" align-md="start" style="height: 100%">
                    <v-col cols="12" sm="10" md="2" class="px-5 text-col-container-1">
                        <p class="lato-bold">Personas que se dedican a grabar y a descargar contenidos con satélites de TV y <span style="font-style: italic">software</span>
                            en Internet. </p>
                        <p class="lato-bold">Promotores musicales: recopilan la música cubana grabada en estudios independientes.</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="2" class="px-5 text-col-container-2">
                        <p class="lato-bold">Casas matrices</p>
                        <p class="lato-regular">Organizan la información proveída por promotores y quienes graban y descargan contenidos, para luego venderla. Agregan publicidad de elaboración propia o creada por agencias publicitarias. </p>
                    </v-col>
                    <v-col cols="12" sm="10" md="2" class="px-5 text-col-container-3">
                        <p class="lato-bold">Primeros compradores</p>
                        <p class="lato-regular">Lo adquieren en las casas matrices y lo revenden, muchas veces mediante mensajeros.</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="2" class="px-5 text-col-container-4">
                        <p class="lato-bold">Segundos compradores</p>
                        <p class="lato-regular">Lo adquieren de los primeros compradores. Muchas veces mezclan los paquetes de varias casas matrices. Venden a clientes finales o revenden a mensajeros, y con frecuencia añaden contenido (ofertas de recargas, venta de ropas…).</p>
                    </v-col>
                    <v-col cols="12" sm="10" md="2" class="px-5 text-col-container-5">
                        <p class="lato-bold">Mensajeros</p>
                        <p class="lato-regular">Lo adquieren de los segundos compradores y lo llevan a los clientes finales. Con frecuencia añaden nuevo contenido (ofertas de recargas, venta de ropas…). </p>
                    </v-col>
                    <v-col cols="12" sm="10" md="2" class="px-5 text-col-container-6">
                        <p class="lato-bold">Clientes finales</p>
                        <p class="lato-regular">Lo compran a segundos compradores o esperan por mensajeros que se lo llevan a sus casas. También lo obtienen gratis de otras personas que antes pagaron por este. Consumen el contenido en televisores y computadoras.</p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import HorizontalPoints from "./infography/HorizontalPoints";
    import VerticalPoints from "./infography/VerticalPoints";
    export default {
        name: "Infography",
        components: {VerticalPoints, HorizontalPoints},
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
            }
        },
        computed: {
            pointComponent(){
                console.log('inforgraphy ',this.$vuetify.breakpoint.width);
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return 'VerticalPoints';
                    case 'sm':
                        return 'VerticalPoints';
                    case 'md':
                        return 'VerticalPoints';
                    default:
                        return 'HorizontalPoints';
                }
            },
            verticalPointsWidth(){
                console.log('inforgraphy ',this.$vuetify.breakpoint.name);
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return '30%';
                    case 'sm':
                        return '30%';
                    case 'md':
                        return '20%';
                    default:
                        return '10%';
                }
            },
            infographySrc() {
                return this.supportsWebp ? require('@/assets/home/infography/infography-1.webp'): require('@/assets/home/infography/infography-1.png')
            },
        }
    }
</script>

<style scoped>
 .number-text {
     font-family: Lato-Bold;
     color: var(--primary-color);
     font-size: 40pt;
     margin: 0;
 }

 .text-col-container-1 {

 }

 .text-col-container-2 {

 }

 .text-col-container-3 {

 }

 .text-col-container-4 {

 }

 .text-col-container-5 {

 }

 .text-col-container-6 {

 }
</style>
