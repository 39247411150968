<template>
    <div class="video-container">

        <div class="keep-aspect video-wrapper">
            <iframe src="https://www.youtube.com/embed/pBBRAbuHYLU" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
        </div>
        <card-component class="text-container mt-2 px-md-3">
            <template v-slot:ctitle>
                <p class="mytitle text-center video-title">El paquete semanal, más de una década rodando por toda Cuba</p>
            </template>
            <template #ctext>
                <p class="card-text text-center px-5">
                  Conoce más en este video sobre el funcionamiento del paquete semanal: cómo se elabora y distribuye por todo el país. También hablamos sobre las perspectivas de esta red offline de distribución de contenidos digitales en un escenario de mayor acceso a Internet.
                </p>
            </template>
            <template #caction>
                <p></p>
            </template>
        </card-component>
    </div>
</template>

<script>
    import CardComponent from "../../core/CardComponent";
    export default {
        name: "VideoCard",
        components: {CardComponent}
    }
</script>

<style scoped>


    .video-title {
        color: var(--secondary-color2);
    }

    .video-container {
        /*margin-top: 50pt;*/
        /*margin-bottom: 50pt;*/
    }

    .video-wrapper {
        width: 45%;
        margin-left: auto;
        margin-right: auto;
    }

    .keep-aspect {
        position: relative;
        height: 0;
        /* height / width * 100% = 315 / 560 * 100% = 56.25% */
        padding-top: 25.25%;
    }

    .text-container {
        width: 40%;
        padding-top: 10%;
        margin-left: auto;
        margin-right: auto;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin-top: 50pt;
    }

    @media screen and (max-width: 960px) {
        .keep-aspect {
            /* height / width * 100% = 315 / 560 * 100% = 56.25% */
            padding-top: 30.25%;
        }
        .video-wrapper {
            width: 55%;
        }
    }

    @media screen and (max-width: 768px) {
        .text-container {
            padding-top: 12%;
            width: 50%;
        }
    }

    @media screen and (max-width: 600px) {
        .keep-aspect {
            /* height / width * 100% = 315 / 560 * 100% = 56.25% */
            padding-top: 45.25%;
        }

        .text-container {
            padding-top: 20%;
            width: 70%;
        }
        .video-wrapper {
            width: 80%;
        }
    }
    @media screen and (max-width: 400px) {
        .text-container {
            padding-top: 30%;
        }
    }
</style>
