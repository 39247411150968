<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script>
    import store from "./store/store"

    export default {
        name: 'App',
        beforeMount() {
            this.check_webp_feature('lossy', (feature, result) => {
                console.log('result', result)
                store.setSupportsWebpAction(result)
            })

        },
        methods: {
            support_format_webp() {
                var elem = document.createElement('canvas');

                if (elem.getContext && elem.getContext('2d')) {
                    console.log('soportas webp')
                    // was able or not to get WebP representation
                    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
                } else {
                    console.log('muy viejo')
                    // very old browser like IE 8, canvas not supported
                    return false;
                }
            },
            // check_webp_feature:
            //   'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
            //   'callback(feature, result)' will be passed back the detection result (in an asynchronous way!)
            check_webp_feature(feature, callback) {
                var kTestImages = {
                    lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
                    lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
                    alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
                    animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
                };
                var img = new Image();
                img.onload = function () {
                    var result = (img.width > 0) && (img.height > 0);
                    callback(feature, result);
                };
                img.onerror = function () {
                    callback(feature, false);
                };
                img.src = "data:image/webp;base64," + kTestImages[feature];
            }
        }
    };
</script>

<style>
    :root {
        --background-color: #17171e;
        --image-background-color: #0a0a0c;
        --primary-color: rgb(87, 208, 215);
        --secondary-color: #3363d0;
        --secondary-color2: #f86443;
        --credits-color: #7ea3a5;
        --bar-subtitle-color: #0063d6;
    }

    @font-face {
        font-family: "Lato-Medium";
        src: url("assets/fonts/Lato/Lato-Medium.ttf") format("opentype");
    }

    @font-face {
        font-family: "Lato-Black";
        src: url("assets/fonts/Lato/Lato-Black.ttf") format("opentype");
    }

    @font-face {
        font-family: "Lato-BlackItalic";
        src: url("assets/fonts/Lato/Lato-BlackItalic.ttf") format("opentype");
    }

    @font-face {
        font-family: "Lato-Bold";
        src: url("assets/fonts/Lato/Lato-Bold.ttf") format("opentype");
    }

    @font-face {
        font-family: "Lato-Semibold";
        src: url("assets/fonts/Lato/LatoSemibold.ttf") format("opentype");
    }

    @font-face {
        font-family: "Lato-BoldItalic";
        src: url("assets/fonts/Lato/Lato-BoldItalic.ttf") format("opentype");
    }

    @font-face {
        font-family: "Lato-Italic";
        src: url("assets/fonts/Lato/Lato-Italic.ttf") format("opentype");
    }

    @font-face {
        font-family: "Lato-Light";
        src: url("assets/fonts/Lato/Lato-Light.ttf") format("opentype");
    }

    @font-face {
        font-family: "Lato-LightItalic";
        src: url("assets/fonts/Lato/Lato-LightItalic.ttf") format("opentype");
    }

    @font-face {
        font-family: "Lato-Regular";
        src: url("assets/fonts/Lato/Lato-Regular.ttf") format("opentype");
    }

    @font-face {
        font-family: "Lato-Thin";
        src: url("assets/fonts/Lato/Lato-Thin.ttf") format("opentype");
    }

    @font-face {
        font-family: "Lato-ThinItalic";
        src: url("assets/fonts/Lato/Lato-ThinItalic.ttf") format("opentype");
    }

    .v-toolbar__content {
        padding-left: 0 !important;
        padding-top: 0 !important;
        height: min-content !important;
    }
    .v-toolbar {
        height: min-content !important;
    }

    .lato-bold {
        font-family: Lato-Bold;
    }

    .lato-regular {
        font-family: Lato-Regular;
    }

    .app-bar-social-icon {
        color: var(--primary-color) !important;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 3px;
        height: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background-color: var(--background-color);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--primary-color);
    }
</style>
