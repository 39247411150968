<template>
  <div class="video-container">
    <div class="keep-aspect video-wrapper">
      <iframe
        src="https://www.youtube.com/embed/bdD6RhU3Vkw"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <card-component class="text-container mt-2 px-md-3">
      <template v-slot:ctitle>
        <p class="mytitle text-center video-title">El Paquete de Dany</p>
      </template>
      <template #ctext>
        <p class="card-text text-center px-5">
          Dany Cabrera García es un joven habanero que se dedica a conformar el Paquete Semanal para enviarlo a lugares tan lejanos como la provincia de Guantánamo, ubicada a 965 kilómetros de La Habana.
        </p>
      </template>
      <template #caction>
        <p></p>
      </template>
    </card-component>
    <divider class="vid-divider" style="background-color: transparent" />
  </div>
</template>

<script>
import CardComponent from "../../core/CardComponent";
import Divider from "../../divider";
export default {
  name: "VideoCardTwo",
  components: { CardComponent, Divider },
};
</script>

<style scoped>
@media screen and (max-width: 1263px) {
  .vid-divider {
    width: 70% !important;
    margin: 40px auto !important;
    background-color: var(--primary-color) !important;
  }
}
.video-title {
  color: var(--secondary-color2);
}

.video-container {
  margin-top: 50pt;
  /*margin-bottom: 50pt;*/
}

.video-wrapper {
  width: 45%;
  margin-left: auto;
  margin-right: auto;
}

.keep-aspect {
  position: relative;
  height: 0;
  /* height / width * 100% = 315 / 560 * 100% = 56.25% */
  padding-top: 25.25%;
}

.text-container {
  width: 40%;
  padding-top: 10%;
  margin-left: auto;
  margin-right: auto;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 50pt;
}

@media screen and (max-width: 960px) {
  .keep-aspect {
    /* height / width * 100% = 315 / 560 * 100% = 56.25% */
    padding-top: 30.25%;
  }
  .video-wrapper {
    width: 55%;
  }
}

@media screen and (max-width: 768px) {
  .text-container {
    padding-top: 12%;
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .keep-aspect {
    /* height / width * 100% = 315 / 560 * 100% = 56.25% */
    padding-top: 45.25%;
  }

  .text-container {
    padding-top: 20%;
    width: 70%;
  }
  .video-wrapper {
    width: 80%;
  }
}
@media screen and (max-width: 400px) {
  .text-container {
    padding-top: 30%;
  }
}
</style>
