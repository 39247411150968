<template>
    <div style="background-color: var(--background-color)">
        <home-charts style="margin-top: 100px"></home-charts>
    </div>

</template>

<script>
    import HomeCharts from "../components/home/HomeCharts";
    export default {
        name: "ChartsView",
        components: {HomeCharts}
    }
</script>

<style scoped>

</style>
