<template>
    <v-container fluid class="pa-0">
        <Banner :banner-image-src-lg="bannerImageSrcMd" :banner-image-src-md="bannerImageSrcMd"
                :banner-image-src-sm="bannerImageSrcSm" :lazy-src="lazySrc"></Banner>

        <v-row justify="center" justify-md="end" class="ma-0">
            <v-col cols="10" sm="6" md="6" class="text-title-container pr-sm-12 pr-md-12">
                <p class="mytitle text-title">
                    <slot name="title"></slot>
                </p>
                <p class="text-author text-sm-right text-md-right">
                    <slot name="author"></slot>
                </p>
            </v-col>
            <v-col cols="1" sm="3" md="3" class="mt-n5">
                <share-social-network class="d-md-none"></share-social-network>
            </v-col>
        </v-row>

        <slot name="body">
            <v-row class="ma-0">
                <v-col cols="12" class="pa-0">
                    <!--                    <v-img eager contain src="@/assets/text2/desk/joyskit.png" width="100%"/>-->
                </v-col>
            </v-row>
            <text-block></text-block>
            <v-row class="ma-0" justify="end">
                <v-col cols="11" sm="8" md="8" class="text-block-container">
                    <!--                    <v-img eager src="@/assets/text2/desk/infography.png" width="100%"></v-img>-->
                </v-col>
                <v-col cols="1" md="2"></v-col>
            </v-row>
        </slot>

        <v-row class="d-sm-none d-md-none ma-0" justify="end">
            <v-col cols="11" sm="4" md="4" class="pl-3 mt-12" style="flex-direction: column">
                <navigation-link v-for="(link, index) in links" :key="index" :pathname="link.path" :name="link.name"
                                 :class="{'mt-10': index !== 0}"/>
            </v-col>
        </v-row>
        <v-row class="ma-0 video-container">
            <v-col cols="12">
                <video-card></video-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import Banner from "./texts/Banner";
    import VideoCard from "../components/home/cards/VideoCard";
    import NavigationLink from "./texts/NavigationLink";
    import ShareSocialNetwork from "../components/core/ShareSocialNetwork";
    import TextBlock from "./texts/TextBlock";

    export default {
        name: "GeneralText",
        components: {TextBlock, ShareSocialNetwork, NavigationLink, VideoCard, Banner},
        props: ['bannerImageSrcMd', 'bannerImageSrcSm', 'lazySrc', 'links'],
    }
</script>

<style>
    .section-title {
        font-size: 18px;
        color: var(--primary-color);
        font-style: italic;
    }
</style>
<style scoped>
    .text-title {
        color: var(--primary-color);
        font-size: 24pt;
        line-height: 28pt;
    }

    .text-author {
        color: var(--secondary-color);
        font-family: Lato-BoldItalic, monospace;
    }

    .text-title-container {
        margin-right: 10%;
    }

    .video-container {
        margin-top: 50pt;
    }

    .text-block-container {
        margin-right: 2%;
    }

    @media screen and (max-width: 960px) {
        .text-title {
            color: var(--primary-color);
            font-size: 16pt;
            line-height: 20pt;
        }

        .text-title-container {
            margin-right: 0;
        }

        .text-block-container {
            margin-right: 4%;
        }
    }

    @media screen and (max-width: 600px) {
        .text-title {
            color: var(--primary-color);
            font-size: 12pt;
            line-height: 16pt;
        }

        .text-title-container {
            margin-right: 0;
        }
    }
</style>
