<template>
    <text-card-type-one :image-src="imgSrc"
                        :trazado-src-sm="trazadoSrcSm"
                        :trazado-src-md="trazadoSrcMd"
                        read-more-link="text3">
        <template v-slot:title>
            <p class="mytitle">Santiago de Cuba: <br> la ciudad clandestina <br>del disco duro</p>
        </template>
        <template #summary>
            En Santiago de Cuba, la segunda ciudad en importancia en la Isla, el paquete semanal mantiene patrones de funcionamiento y sostenibilidad similares a los de otros territorios, pero posee características distintivas. Los contenidos locales y las soluciones de transportación entre las localidades son algunas de sus singularidades.
        </template>
    </text-card-type-one>
</template>

<script>
    import TextCardTypeOne from "./TextCardTypeOne";

    export default {
        name: "TextCardThree",
        components: {TextCardTypeOne},
        data() {
            return {
                supportsWebp: require('@/store/store').default.state.supportsWebp,
            }
        },
        computed: {
            imgSrc() {
                return this.supportsWebp ? require('@/assets/home/text3.webp'): require('@/assets/home/text3.png')
            },
            trazadoSrcSm() {
                return this.supportsWebp ? require('@/assets/home/mobile/trazado3.webp'): require('@/assets/home/mobile/trazado3.png')
            },
            trazadoSrcMd() {
                return this.supportsWebp ? require('@/assets/home/trazado3.webp'): require('@/assets/home/trazado3.png')
            }
        },
    }
</script>

<style scoped>

</style>
