<template>
    <v-row align="center" align-sm="start" align-md="start" justify-sm="space-between" justify-md="space-between" no-gutters>
            <v-col class="outline-container" cols="1" md="2">
                <v-img :src="outlineSRC" eager contain position="0,0"/>
            </v-col>
            <v-col cols="11" sm="11" md="10">
                <v-row align="center" justify-sm="end" justify-md="end" no-gutters>
                    <v-col class="ml-7 ml-sm-0 ml-md-0 pl-sm-8 pl-md-8 pr-4" cols="10" order="2"  sm="5" md="4" order-sm="1" order-md="1">
                        <card-component class="mt-12 mt-md-2" is-even>
                            <template v-slot:ctitle>
                                <slot name="title">
                                    <p class="mytitle">Reporte General</p>
                                </slot>
                            </template>
                            <template #ctext>
                                <p class="card-text">
                                    <slot name="summary">
                                    </slot>
                                </p>
                            </template>
                            <template #caction>
                                <router-link  :to="{name: readMoreLink}" style="text-decoration: none">
                                    leer más
                                </router-link>
                            </template>
                        </card-component>
                    </v-col>
                    <v-col cols="12" order="1" sm="7" md="7" order-sm="2" order-md="2">
                        <v-img :src="imageSrc" eager contain max-width="95%" style="margin-left: auto"></v-img>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
</template>

<script>
    import CardComponent from "@/components/core/CardComponent";

    export default {
        name: "TextCardTypeOne",
        components: {CardComponent},
        props: ['trazadoSrcSm', 'trazadoSrcMd', 'imageSrc', 'readMoreLink'],
        computed: {
            outlineSRC() {
                console.log(this.$vuetify.breakpoint.name);
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return this.trazadoSrcSm;
                    case 'sm':
                        return this.trazadoSrcSm;
                    default:
                        return this.trazadoSrcMd;
                }
            },
        },
    }
</script>


<style scoped>
    .top-divider {
        width: 35%;
        align-self: flex-start;
        margin-left: 20.66%;
    }
    .outline-container {
        padding-top: 5%;
        padding-right: 5%
    }

    @media screen and (max-width: 960px) {
        .outline-container {
            padding-right: 0;
            padding-top: 10%;
        }
    }

    @media screen and (max-width: 649px) {
        .outline-container {
            padding-top: 41%;
            padding-right: 0;
        }
    }

    @media screen and (max-width: 400px) {
        .outline-container {
            padding-top: 41%;
        }
    }
</style>
