<template>
    <div>
        <chart-title>Cantidad de paquetes por semana, <br> según diferentes fuentes</chart-title>
        <v-chart :options="counter" style="width: 100%;" autoresize/>
    </div>
</template>

<script>
    import ECharts from "vue-echarts";
    import "echarts/lib/component/tooltip";
    import "echarts/lib/component/dataZoom"
    import ChartTitle from "./ChartTitle";
    import {getRangeOfWeek} from "../../utils/date";

    export default {
        name: "CounterChart",
        components: {ChartTitle, "v-chart": ECharts},
        data() {
            const counter = require("@/data/counter2.json");
            const idx2Show = [0, 1, 53, 104]

            return {
                counter: {
                    dataZoom: {
                        type: 'inside'
                    },
                    legend: {
                        data: ["Punch Card"],
                        left: "right",
                    },
                    tooltip: {
                        position: "top",
                        formatter: function (params) {
                            console.log(counter.xAxisValues[params.value[0]])
                            return (
                                `<p>${params.value[2]}
                                paquetes de
                                ${counter.yAxis[params.value[1]].toUpperCase()} <br>
                                en la semana del
                                ${getRangeOfWeek(counter.xAxisValues[params.value[0]])}</p>`
                            );
                        },
                    },
                    grid: {
                        width: '98%',
                        // height: '100%',
                        left: 2,
                        bottom: 40,
                        top: 40,
                        show: true,
                        containLabel: true
                    },
                    xAxis: {
                        name: 'Semanas',
                        nameLocation: 'middle',
                        nameGap: 25,
                        type: 'category',
                        data: counter.xAxis,
                        boundaryGap: false,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "#999",
                                type: "dashed",
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: true,
                            interval: (index, val) => {
                                console.log(val)
                                return idx2Show.includes(index)
                            }
                        },
                    },
                    yAxis: {
                        name: 'Matrices',
                        nameLocation: 'end',
                        nameGap: 15,
                        type: 'category',
                        data: counter.yAxis,
                        axisLine: {
                            show: false,
                        },
                    },
                    series: [
                        {
                            name: 'Paquetes por semana ',
                            type: 'scatter',
                            symbolSize: function (val) {
                                return (val[2] + 2)*4;
                            },
                            data: counter.series,
                            animationDelay: function (idx) {
                                return idx * 5;
                            },
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 20
                        },
                    ],
                    color: [
                        '#0062d5'
                    ],
                    textStyle: {
                        color: 'white'
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
