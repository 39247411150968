<template>
    <p class="chart-title">
        <slot></slot>
    </p>
</template>

<script>
    export default {
        name: "ChartTitle",
    }
</script>

<style scoped>
    .chart-title {
        color: white;
        font-size: 20pt;
        font-family: Lato-Thin;
        text-align: center;
        text-transform: uppercase;
    }

    @media screen and (max-width: 960px) {
        .chart-title {
            font-size: 16pt;
        }
    }
</style>
