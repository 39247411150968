<template>
    <div>
        <div class="header">
            <p class="home-header-text text-right">El Paquete Semanal</p>
        </div>
        <div class="header-subtitle-container">
            <p class="text-right header-subtitle">Alternativa ante la</p>
            <p class="text-right header-subtitle">desconexión en Cuba</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Header"
    }
</script>

<style scoped>
    .home-header-text {
        font-family: Lato-Light;
        letter-spacing: 6px;
        font-size: 44pt;
        line-height: 44pt;
        text-transform: uppercase;
    }

    .header-subtitle {
        font-family: Lato-Semibold;
        letter-spacing: 3px;
        font-size: 22pt;
        line-height: 26pt;
        text-transform: uppercase;
        margin-bottom: 0;
        color: var(--bar-subtitle-color);
    }



    @media screen and (max-width: 959px) {
        .home-header-text {
            font-family: Lato-Light;
            letter-spacing: 6px;
            font-size: 36pt;
            line-height: 36pt;
        }

        .header-subtitle {
            font-family: Lato-Semibold;
            letter-spacing: 3px;
            font-size: 14pt;
            line-height: 18pt;
        }
    }

    @media screen and (max-width: 650px) {
        .home-header-text {
            font-size: 28pt;
            line-height: 28pt;
            margin-bottom: 5px;
        }

        .header-subtitle {
            font-size: 8pt;
            line-height: 12pt;
        }
    }

    @media screen and (max-width: 550px) {
        .home-header-text {
            font-size: 22pt;
            line-height: 22pt;
            margin-bottom: 5px;
        }

        .header-subtitle {
            font-size: 8pt;
            line-height: 12pt;
        }
    }

    @media screen and (max-width: 400px) {
        .home-header-text {
            font-size: 18pt;
            line-height: 18pt;
            margin-bottom: 5px;
        }

        .header-subtitle {
            font-size: 8pt;
            line-height: 12pt;
        }
    }

</style>
